import { Component, OnInit } from '@angular/core';
import { AuthService, ConfigService } from '../../services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  //ftxt: string = '';
  year : any;
  constructor(
    public auth: AuthService,
    private config: ConfigService
  ) { }

  ngOnInit() {
    //this.ftxt = this.auth.getVersionDetails();
    this.year = new Date().getFullYear() 
  }

  getVersion() {
    return this.auth.getVersionDetails();
  }

  checkSidebarStyle() {
    return this.config.getMySidebar()
  }

  getFooterStyle() {
    return {
      'margin-left' : (this.checkSidebarStyle() && Boolean(this.config.getSideBarNode()))? '250px' : '0px',
      'transition' : "margin-left .5s"
    }
  }

}
