import { Component, OnInit } from '@angular/core';
import { OrganizationService, ConfigService, UsermessageService, StateService } from '../services';
import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PreloaderService } from '../services/preloader/preloader.service';
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from '../dialog/confirm/confirm.component';
import { AddExceptionComponent } from '../dialog/add-exception/add-exception.component';
import { EditExceptionComponent } from '../dialog/edit-exception/edit-exception.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-excluded-domains',
  templateUrl: './excluded-domains.component.html',
  styleUrls: ['./excluded-domains.component.scss']
})
export class ExcludedDomainsComponent implements OnInit {
  allExcludedDomains = [];
  searchString: string;
  sortField: string;
  orderBy: string;
  searchQuery = new Subject<string>();
  searchInProgress: boolean = false;

  constructor(
    private organizationService: OrganizationService,
    private config: ConfigService,
    private preloaderService: PreloaderService,
    private userMsg: UsermessageService,
    private matDialog:MatDialog,
    private stateService: StateService,

  ) {
    this.orderBy = 'ASC';
    this.sortField = 'Excluded_Domain_Name';
    this.searchString = '';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    if (this.stateService.getExcludedDomains()) {
      this.initializeComponentData(this.stateService.getExcludedDomains())
    } else this.getAllExcludedDomainList();
  }

  getAllExcludedDomainList() {
    this.stateService.setExcludedDomains(this);
    this.organizationService.getAllExcludedDomainList(this.searchString, this.sortField, this.orderBy)
      .subscribe((result) => {
        this.searchInProgress = false;
        if (result) {
          this.allExcludedDomains = result;
        }
      }, (err) => {
        this.searchInProgress = false;
        console.log("err", err);
      })
  }

  deleteExludedDomain(data) {
    this.openConfirm('Delete', 'Would you like to delete ' + data.Excluded_Domain_Name + ' ?').afterClosed()
      .subscribe((res) => {
        if (res == true) {
          this.organizationService.deleteExludedDomain(data.Excluded_Domain_ID)
            .subscribe((result) => {
              this.userMsg.setSuccessMsg('Deleted successfully.');
              this.getAllExcludedDomainList();
            }, (err) => {
            })
        }
        else {
          console.log("Cancelled")
        }
      });
  }

  searchField(search) {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if (!format.test(this.searchString) && this.searchString == " ") {
      this.searchString = '';
      this.getAllExcludedDomainList();
    } else this.findExcludeddomainList();
  }

  findExcludeddomainList() {
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getAllExcludedDomainList();
  }

  setSort(field: string) {
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getAllExcludedDomainList();
  }

  openConfirm(title, msg) {
    return this.matDialog.open(ConfirmComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  openExceptionPopup() {
    this.matDialog.open(AddExceptionComponent, {data:{
      title: 'Add Exception',
      message: ''
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.userMsg.setSuccessMsg('Exception created successfully.');
          this.getAllExcludedDomainList();
        }
      })
  }

  openEditExceptionPopup(data) {
    this.matDialog.open(EditExceptionComponent, {data:{
      title: 'Edit Exception',
      message: data
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.userMsg.setSuccessMsg('Updated successfully.');
          this.getAllExcludedDomainList();
        }
      })
  }

  initializeComponentData(retainData) {
    this.searchString = retainData.searchString;
    this.sortField = retainData.sortField;
    this.orderBy = retainData.orderBy;
    this.getAllExcludedDomainList();
  }
}
