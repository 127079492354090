import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';  


// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class SchedulingService {

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) { }

  getAllHolidays() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/scheduling/holidays';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)), );
  }

  createNew(data) {
    data.createdBy = this.auth.getUserId();
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/scheduling/create';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  deletedate(data){
    data.ModifedBy = this.auth.getUserId();
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/scheduling/delete';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  editholiday(data){
    data.ModifedBy = this.auth.getUserId();
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/scheduling/edit';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

}
