import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import * as momentTimeZone from 'moment-timezone';
import { OrdersService, AuthService, TagsService, ConfigService } from '../../services';
import { CONSTANTS } from '../../app.constants';
import { environment } from 'environments/environment';
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { NewTagComponent } from '../../dialog/new-tag/new-tag.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-order-listing-table',
  templateUrl: './order-listing-table.component.html',
  styleUrls: ['./order-listing-table.component.scss']
})
export class OrderListingTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @Input() orders: any;
  @Input() sortField: any;
  @Input() orderBy: any;
  @Input() allActiveTags: any;
  @Input() selectedTags: any;
  @Input() showStatus: boolean;
  @Input() isFullName: boolean;
  @Input() currTime: Date;
  @Output() emitSortBy: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitDescSortBy: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitHandleBy: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitChangeAsignee: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOrdSelectEvt: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOrdTagChangeEvt: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOrdTagCreateEvt: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToOrdDetailsEvt: EventEmitter<any> = new EventEmitter<any>();
  hidePippinDetails: boolean = false
  orderStatus: any = {};
  constants = CONSTANTS;
  // currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();
  searchTag: string = '';
  associatedTags = [];
  newClientTagId = '';
  priorityClientTagId = '';
  firstOrdTagId = '';
  prePaidTagId = '';
  assignedDurations: any={};
  Interval: any={};
  constructor(
    public orderService: OrdersService,
    private auth: AuthService,
    private tagService: TagsService,
    private config: ConfigService,
    private matDialog:MatDialog,
  ) { }

  ngOnInit() {
    console.log("0th Run")
    if(this.orders) this.Interval=this.orderService.calcAssignedDuration(this.orders)
    this.orderService.assignedDurations$.subscribe(durations=>{
      this.assignedDurations=durations;
    })
    this.loadHideDetails()
    this.orderStatus = this.orderService.getOrderStatus();
    if (this.config.getNewClientTagId()) this.newClientTagId = this.config.getNewClientTagId();
    if (this.config.getPriorityClientTagId()) this.priorityClientTagId = this.config.getPriorityClientTagId();
    if (this.config.getFirstOrdTagId()) this.firstOrdTagId = this.config.getFirstOrdTagId();
    if (this.config.getPrePaidTagId()) this.prePaidTagId = this.config.getPrePaidTagId();
  }
ngOnDestroy(): void {
  this.Interval?.unsubscribe();
}
  goToOrderDetails(orderId, customerId) {
    this.goToOrdDetailsEvt.emit(orderId)
  }

  trackOrders(order) {
    return order.Order_ID;
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }

  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return true;
    else return false;
  }

  isSearchManager() {
    if(this.auth.isSearchManager()) return true
    else return false;
  }

  getAdminFullName(order) {
    if(order.Admin_User_Full_Name && order.Admin_User_Full_Name !== undefined) return this.firstLetterCaps(order.Admin_User_Full_Name);
    return this.firstLetterCaps(order.Admin_User_First_Name) + " "+ this.firstLetterCaps(order.Admin_User_Last_Name)
  }

  firstLetterCaps(data){
    if (typeof data !== 'string') return ''
    return data.charAt(0).toUpperCase() + data.slice(1)
  }

  getClientFullName(order) {
    if(order.Organization_Name) return order.User_First_Name +" "+ order.User_Last_Name + " - "  +order.Organization_Name;
    else {
      if(order.User_First_Name && order.User_First_Name !== undefined)
        return order.User_First_Name +" "+ order.User_Last_Name;
      else if(order.Client_User_Full_Name && order.Client_User_Full_Name !== undefined) 
        return order.Client_User_Full_Name;
      else return "";
    } 
  }

  getAbstractorFullName(order) {
    return order.Abstractor_User_First_Name;
  }

  getFullAddress(order){
    let completeAddress = '';
    if (order.Property_Address_1) completeAddress += order.Property_Address_1
    if (order.Property_Address_2) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += order.Property_Address_2
    }
    if (order.Property_City) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += order.Property_City
    }
    if (order.Property_State_Abbr) {
      if (completeAddress) completeAddress += ', '
      completeAddress += order.Property_State_Abbr
    }
    if (order.Property_ZipCode) {
      if (completeAddress) completeAddress += ' '
      return completeAddress += order.Property_ZipCode
    }
  }

  getFontColor(hexColor) {
    return this.tagService.getFontColor(hexColor)
  }

  composeStatusClasses(order) {
    return {
      'due_in_2days': this.checkNextDays(order),
      'due_today': this.checkIfDue(order),
      'past_due': this.checkIfMissed(order)
    }
  }

  checkNextDays(order) {
    let currDay = momentTimeZone.tz(new Date(), 'America/New_York').format('YYYY-MM-DD');
    let nextDay = momentTimeZone.tz(new Date(), 'America/New_York').add(1, 'days').format('YYYY-MM-DD');
    let etaTimeStamp = momentTimeZone.tz(new Date(), 'Etc/UTC').format('HH:mm:ss');
    let postESTCutOff = momentTimeZone.tz(new Date(), 'America/New_York').isDST() ? '21:00:00' : '22:00:00';
    let preESTMidNight = momentTimeZone.tz(new Date(), 'America/New_York').isDST() ? '04:00:00' : '05:00:00';
    if(etaTimeStamp > postESTCutOff || etaTimeStamp <= preESTMidNight){
      currDay = momentTimeZone.tz(new Date(), 'America/New_York').add(1, 'days').format('YYYY-MM-DD');
      nextDay = momentTimeZone.tz(new Date(), 'America/New_York').add(2, 'days').format('YYYY-MM-DD');
    }
    let startofTomorrow = currDay + "T" +postESTCutOff + "Z";
    let endofTomorrow = nextDay + "T" +postESTCutOff + "Z";
    let orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'Etc/UTC');
    if (orderETAMoment.isAfter(startofTomorrow) && orderETAMoment.isSameOrBefore(endofTomorrow) && order.Order_Status != this.constants.orderStatus.completed) return true;
    else return false;


    // var momentAfter24hr = momentTimeZone.tz('America/New_York').add(24, 'hours').utc().format();
    // var momentAfter2Days = momentTimeZone.tz('America/New_York').add(24, 'hours').add(2, 'days').utc().format();
    // var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');
    // if (orderETAMoment.isSameOrAfter(momentAfter24hr) && orderETAMoment.isSameOrBefore(momentAfter2Days) && order.Order_Status != this.constants.orderStatus.completed) return true;
    // else return false;
  }

  checkIfMissed(order) {
    var momentToday = momentTimeZone.tz('America/New_York').format();
    var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');
    if (orderETAMoment.isBefore(momentToday) && order.Order_Status != this.constants.orderStatus.completed) return true;
    else return false;
  }

  checkIfDue(order) {
    let currDay = momentTimeZone.tz(new Date(), 'America/New_York').format('YYYY-MM-DD');
    let yestDay = momentTimeZone.tz(new Date(), 'America/New_York').subtract(1, 'days').format('YYYY-MM-DD');
    let etaTimeStamp = momentTimeZone.tz(new Date(), 'Etc/UTC').format('HH:mm:ss');
    let postESTCutOff = momentTimeZone.tz(new Date(), 'America/New_York').isDST() ? '21:00:00' : '22:00:00';
    let preESTMidNight = momentTimeZone.tz(new Date(), 'America/New_York').isDST() ? '04:00:00' : '05:00:00';
    if(etaTimeStamp > postESTCutOff || etaTimeStamp <= preESTMidNight){
      currDay = momentTimeZone.tz(new Date(), 'America/New_York').add(1, 'days').format('YYYY-MM-DD');
      yestDay = momentTimeZone.tz(new Date(), 'America/New_York').format('YYYY-MM-DD');
    }
    let startofToday = yestDay + "T" +postESTCutOff + "Z";
    let endofToday = currDay + "T" +postESTCutOff + "Z";
    let orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'Etc/UTC');
    if (orderETAMoment.isAfter(startofToday) && orderETAMoment.isSameOrBefore(endofToday) && order.Order_Status != this.constants.orderStatus.completed) return true;
    else return false;


    // var momentToday = momentTimeZone.tz('America/New_York').format();
    // var momentAfter24hr = momentTimeZone.tz('America/New_York').add(24, 'hours').utc().format();
    // var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');
    // if (orderETAMoment.isSameOrAfter(momentToday) && orderETAMoment.isSameOrBefore(momentAfter24hr) && order.Order_Status != this.constants.orderStatus.completed) return true;
    // else return false;
  }

  orderAssignee(ordDetails) {
    this.emitHandleBy.emit(ordDetails);
  }

  changeAssignee(ordDetails) {
    this.emitChangeAsignee.emit(ordDetails);
  }

  onOrdSelect(event, ordId, order, checked) {
    let obj = { event: event, ordId: ordId, order: order, checked: checked }
    this.emitOrdSelectEvt.emit(obj);
  }

  setSort(sortField) {
    this.emitSortBy.emit(sortField);
  }

  setDescSort(sortField) {
    this.emitDescSortBy.emit(sortField);
  }

  isSuperAdmin() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else if (this.auth.getUserId() == environment.superAdminId) return true;
    else if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
    else return false;
  }

  menuClosed(order) {
    this.searchTag = '';
  }

  menuOpened(order) {
    this.searchTag = '';
    // this.associatedTags = []; //not using any whaere confirm once
    this.getAvailableTags(order).map((tag) => tag.on = false)
  }

  clearSearchTag() {
    this.searchTag = '';
  }

  openTagMenu(event, order) {
    event.stopPropagation();
    this.assignPreselectedTags(order);
  }

  assignPreselectedTags(order) {
    if (order.Tag_Names) this.associatedTags = order.Tag_Names
  }

  getAvailableTags(order) {
    let preselectedTags = [];
    let availableTags = [];
    if (order.Tag_Names) preselectedTags = order.Tag_Names;
    for (var i = 0, len = this.allActiveTags.length; i < len; i++) {
      if (preselectedTags.findIndex((select => this.allActiveTags[i].Tag_ID == select.Tag_ID)) == -1) {
        if (this.allActiveTags[i].Tag_ID != this.newClientTagId && this.allActiveTags[i].Tag_ID != this.priorityClientTagId 
          && this.allActiveTags[i].Tag_ID != this.firstOrdTagId && this.allActiveTags[i].Tag_ID != this.prePaidTagId
          && this.allActiveTags[i].Tag_ID != this.config.getAwaitDlvryTagId() && this.allActiveTags[i].Tag_ID != this.config.getAwaitPartDlvryTagId()) 
          availableTags.push(this.allActiveTags[i]);
      }
    }
    return availableTags;
  }

  checkIfAlreadyCompleted(orderStatusDet) {
    let completedOrdIndex = orderStatusDet.findIndex((data) => data.Status_New === CONSTANTS.orderStatus.completed)
    if (completedOrdIndex > -1) return true;
    else return false;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  modifyOrderTags(event, tag, order) {
    if (tag && tag.Tag_ID == this.config.getPartiallyDeliveredTagId()) {
      this.orderService.getAllOrderStatus(order.Order_ID)
        .subscribe((orderStatusDet) => {
          if (this.checkIfAlreadyCompleted(orderStatusDet)) this.modifyOrderTagsRequest(event, tag, order);
          else {
            this.openErrorPopup('This tag can only be assigned after an order has been completed at least once.');
            event.source.checked = false;
            this.matMenuTrigger.closeMenu();
          }
        })
    }
    else this.modifyOrderTagsRequest(event, tag, order);
  }

  modifyOrderTagsRequest(event, tag, order) {
    var selectedTagsCopy = Object.assign([], this.selectedTags);
    var isTagToAdd: boolean;
    if (event.checked) isTagToAdd = true;
    else isTagToAdd = false;
    this.orderService.modifyOrderTags({ Order_ID: order.Order_ID, Order_Tag: tag, isTagToAdd: isTagToAdd })
      .subscribe((orderTagdata) => {
        order.Tag_Names = orderTagdata.Tag_Names;
        order.Order_Tags = orderTagdata.Order_Tags;
        this.emitOrdTagChangeEvt.emit();
        if (selectedTagsCopy.length > 0) {
          // get orders 
          this.matMenuTrigger.closeMenu();
        } else {
          //get only count
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderTagging);
        this.matMenuTrigger.closeMenu();
      })
  }

  openNewTagModal(order) {
    this.matMenuTrigger.closeMenu();
    this.matDialog.open(NewTagComponent, {data:{
      message: { Order_ID: order.Order_ID }
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if (res) {
        this.emitOrdTagCreateEvt.emit();
      }
    });
  }

  loadHideDetails(){
    this.hidePippinDetails = Number(this.auth.getUserRole()) == this.constants.userRole.searchMaster && 
    Number(this.auth.getSearchMasterType()) == this.constants.userType.externalSM;
  }

}
