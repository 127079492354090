// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
}
.notification .mat-card-header-text {
  margin: 0px !important;
}

.card-container-right {
  display: inline;
  float: right;
}

.card-container-left {
  display: inline;
}

mat-card-actions {
  display: flex;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/notifications/notifications.component.scss"],"names":[],"mappings":"AAAA;EACI,2HAAA;AACJ;AAAI;EACI,sBAAA;AAER;;AAEA;EACI,eAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,aAAA;EACA,SAAA;AACJ","sourcesContent":[".notification{\n    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */\n    .mat-card-header-text{\n        margin: 0px !important\n    }\n}\n\n.card-container-right{\n    display: inline;\n    float: right;\n  }\n  \n.card-container-left{\n    display: inline;\n}\n\nmat-card-actions{\n    display: flex;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
