import { Component, OnInit, ChangeDetectorRef , ViewChild} from '@angular/core';
import { UntypedFormGroup, FormsModule, UntypedFormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, EqualValidator, PasswordValidator, ConfigService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../../dialog/success/success.component';
import { CONSTANTS } from '../../app.constants';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {

  changeFrm: UntypedFormGroup;
  changeModel: any = {};
  error: string = '';
  errorMsg: any = {};
  verification_ID: string;
  timer: any = [];
  @ViewChild('myfocus') myfocus:any;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog:MatDialog,
    private config: ConfigService
  ) {
    this.changeFrm = fb.group({
      'password': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      'confirmpassword': [null, Validators.required],
    })
    this.changeFrm.valueChanges.subscribe(data => this.error = '');
  }

  ngOnInit() {
    this.auth.logout();
    this.route.params.subscribe((params) => {
      this.verification_ID = params['verificationId'];
    });
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
    this.myfocus.nativeElement.focus(); 
  }
  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  setPassword() {
    this.errorMsg.msg = '';
    this.changeModel['Verification_ID'] = this.verification_ID;
    this.changeModel['User_Password'] = this.changeModel.password;
    console.log("setPassword function changeModel=>:", this.changeModel);

    if (this.changeFrm.valid) {
      this.auth.setpassword(this.changeModel)
        .subscribe((res) => {
          console.log("set password :", res);
          console.log("set password :", res.body);
          // this.router.navigate(['login/changePassword']);
          //  this.router.navigate(['/base']);
          var loginModel = {};
          loginModel['Email_Address'] = res.body.replace(/['"]+/g, '')
          loginModel['User_Password'] = this.changeModel.password;
          console.log("loginModel :", loginModel);
          this.login(loginModel)
          // let disposable = this.dialogService.addDialog(SuccessComponent, {
          //   title:'Success', 
          //   message: 'Please login using your new password.'
          // }).subscribe((res)=>{
          //   console.log("Hello:",res);          
          // });
          // this.router.navigate(['login']);
        }, (error) => {
          console.log("Error:", error);
          this.errorMsg = error;
        });
    } else {
      // this.errorType = 'general';
      this.errorMsg.msg = 'Please enter correct details in all required fields';
    }
}

login(loginModel) {
  this.auth.login(loginModel)
    .subscribe(
    (response) => {
      if (!response['token'] && response['User_ID']) this.router.navigate(['termsAndConditions/' + response['User_ID']]);
      else {
        if(response['user'] && (response['user']['User_Role'] == CONSTANTS.userRole.searchMaster)) this.router.navigate(['/orders/inprocess']);
        else this.router.navigate(['/orders/new']);
      }      
    },
    (error) => {
      //console.log("In login",error)
      this.errorMsg = error;
      //console.log("In login",this.errorMsg)
    }
    );
}

}
