// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .rb-modified {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
}
::ng-deep .rb-modified .mat-select-value-text {
  color: #4FB2D9 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/common/mat-select-filter/mat-select-filter.component.scss"],"names":[],"mappings":"AACE;EACE,6HAAA;AAAJ;AACI;EACE,yBAAA;AACN","sourcesContent":["::ng-deep {\n  .rb-modified {\n    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */\n    .mat-select-value-text {\n      color :#4FB2D9 !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
