import { Component, OnDestroy, OnInit } from "@angular/core";
import { OrdersService } from "app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as momentTimeZone from 'moment-timezone';
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
const moment = require('moment-timezone');

@Component({
  selector: "app-chk-proceedings",
  templateUrl: "./chk-proceedings.component.html",
  styleUrls: ["./chk-proceedings.component.scss"],
})
export class ChkProceedingsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  titleComments: any = [];
  orderId: any;
  probateURL = null;
  recorderURL = null;

  constructor(
    private builder: RptBuilderService,
    private ordersService: OrdersService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.vesting$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) this.getTitleComments(data.curData);
      });
    this.builder.cot$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (data && data.curData) this.getTitleComments(data.curData);
    });
    this.ordersService.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderId = data.Order_ID));
    this.ordersService.searchInfo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.getButtonLinks(data));
  }

  getTitleComments(data) {
    data.forEach((deed) => {
      if (
        deed.Comments &&
        deed.Comments.trim() !== ""
      ) {
        let titleData = {
          recorded: ":",
          comment: deed.Comments,
        };
        if (deed.Rec_Date)
          titleData.recorded = `${moment.tz(deed.Rec_Date, 'America/New_York').format('MMMM D, YYYY')}:`;
        this.titleComments.push(titleData);
      }
    });
  }

  getButtonLinks(data) {
    this.probateURL = null;
    this.recorderURL = null;
    if (data.length) {
      if (data[0]["Probate Link"] && data[0]["Probate Link"].length)
        this.probateURL = data[0]["Probate Link"][0];
      if (data[0]["Recorder Site"] && data[0]["Recorder Site"].length)
        this.recorderURL = data[0]["Recorder Site"][0];
    }
  }
}
