import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AuthService, ConfigService, OrganizationService } from "app/services";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { EditCommitmentsComponent } from "../edit-commitments/edit-commitments.component";
import { CONSTANTS } from "app/app.constants";

@Component({
  selector: "app-org-commitments",
  templateUrl: "./org-commitments.component.html",
  styleUrl: "./org-commitments.component.scss",
})
export class OrgCommitmentsComponent implements OnInit {
  @Input() orgId: any;
  @Input() orgDetails:any;
  private ngUnsubscribe = new Subject();
  searchReqString: string;
  searchExcpString: string;
  searchReqQuery = new Subject<string>();
  searchExcpQuery = new Subject<string>();
  requirementItems: any;
  exceptionItems: any;
  filteredReq: any;
  filteredExp: any;
  constants = CONSTANTS
  constructor(
    private orgService: OrganizationService,
    private matDialog: MatDialog,
    private config: ConfigService,
    private auth: AuthService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.orgService.commtmntItems$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((items) => {
        if (items) {
          this.requirementItems = items.filter(
            (itm) => itm.Commitment_Type == 1
          );
          this.exceptionItems = items.filter((itm) => itm.Commitment_Type == 2);
          this.filterReq("");
          this.filterExp("");
        }
      });
    this.orgService.getCommitmentItems(this.orgId).subscribe();
    this.searchReqQuery
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value) => this.filterReq(value));
    this.searchExcpQuery
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value) => this.filterExp(value));
  }

  filterReq(search) {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    if (!format.test(search) && search == " ") search = "";
    this.filteredReq = this.requirementItems;
    search = search.toLowerCase();
    if (search !== "")
      this.filteredReq = this.requirementItems.filter(
        (itm) =>
          itm.Cust_Commit_Type_Id.toLowerCase().includes(search) ||
          itm.Type_Name.toLowerCase().includes(search) ||
          itm.Language.toLowerCase().includes(search)
      );
  }

  filterExp(search) {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    if (!format.test(search) && search == " ") search = "";
    this.filteredExp = this.exceptionItems;
    search = search.toLowerCase();
    if (search !== "")
      this.filteredExp = this.exceptionItems.filter(
        (itm) =>
          itm.Cust_Commit_Type_Id.toLowerCase().includes(search) ||
          itm.Type_Name.toLowerCase().includes(search) ||
          itm.Language.toLowerCase().includes(search)
      );
  }

  createCommitment() {
    this.matDialog
      .open(EditCommitmentsComponent, {
        data: {
          newItem: true,
          orgId: this.orgId,
        },
        ...this.config.getDialogOptions(),
      })
      .afterClosed()
      .subscribe((res) => {});
  }

  editCommitment(item) {
    this.matDialog
      .open(EditCommitmentsComponent, {
        data: {
          newItem: false,
          commtmntItem: item,
        },
        ...this.config.getDialogOptions(),
      })
      .afterClosed()
      .subscribe((res) => {});
  }


  isSuperAdmin(){
    return (Number(this.auth.getUserRole())  == this.constants.userRole.superAdmin) ||( Number(this.auth.getUserRole())  == this.constants.userRole.compAdmin)
  }

  isReswareOrQualiaCpny(){
    if(this.orgDetails.Mappings && this.orgDetails.Mappings.length > 0){
      this.orgDetails.Mappings.forEach((mapping)=>{
        if(mapping.Mapping_Type == 1 || mapping.Mapping_Type == 8){
          return true;
        }
      })
    }
    return false;
  }
}
