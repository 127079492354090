// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  overflow-y: auto;
  max-height: 400px;
}

.modal-dialog {
  margin-top: 2rem;
  max-width: 1500px;
}

.doc-section {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem;
  margin-top: 3px;
}

.btn-padding {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/abstractor-message/abstractor-message.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,oCAAA;EACA,aAAA;EACA,eAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".modal-body{\n    overflow-y: auto;\n    max-height: 400px;\n}\n\n.modal-dialog {\n    margin-top: 2rem;\n    max-width: 1500px;\n}\n\n.doc-section{\n    border : 1px solid rgba(0,0,0,0.2);\n    padding: 1rem;\n    margin-top:3px;\n}\n\n.btn-padding {\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
