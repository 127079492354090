import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";

@Component({
  selector: "app-chk-docs",
  templateUrl: "./chk-docs.component.html",
  styleUrls: ["./chk-docs.component.scss"],
})
export class ChkDocsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  propertyDocs: any;
  secInstsDocs: any;
  liensDocs: any;
  judgementsDocs: any;
  completeDocSet = [];
  constructor(private builder: RptBuilderService) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.documents$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data) this.completeDocSet = data;
      });
    this.builder.basic$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data) =>{
        if (data && data.curData){    
          this.propertyDocs = 0      
          this.completeDocSet.forEach((doc) => {
            if(doc.Entity_ID == 0 && doc.Type_ID == 8) this.propertyDocs++;
          })
        }
      }
        // (this.propertyDocs =
        //   data.curData && data.curData.Documents
        //     ? data.curData.Documents.length
        //     : 0) // ==========================================TODO: Documents need to be handled seperatly
    );
    this.builder.liens$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
          if (data && data.curData) this.updateLienJudgCnt(data.curData)
      });
    this.builder.secInst$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) this.updateSecInsDocCnt(data.curData);
      });
      
  }

  
  updateLienJudgCnt(data) {
    this.liensDocs = {
      total: 0,
      present: 0,
    };
    this.judgementsDocs = {
      total: 0,
      present: 0,
    };
    data.forEach((itm) => {
      if (itm.Entity_ID == 1) {
        this.liensDocs.total += 1;
        if (this.checkIfDocPresent(6, itm.Id)) this.liensDocs.present += 1;
      } else {
        this.judgementsDocs.total += 1;
        if (this.checkIfDocPresent(6, itm.Id)) this.judgementsDocs.present += 1;
      }
    });
  }

  checkIfDocPresent(typeId, recId){
    return this.completeDocSet.some((doc) => doc.Type_ID == typeId && doc.Entity_ID == recId)
  }

  updateSecInsDocCnt(data) {
    this.secInstsDocs = {
      total: 0,
      present: 0,
    };
    data.forEach((itm) => {
      this.secInstsDocs.total += 1;
      if (this.checkIfDocPresent(5, itm.Id)) this.secInstsDocs.present += 1;
    });
  }
}
