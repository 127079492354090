// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chkidnt-col1 {
  max-width: 65%;
  min-width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/order-checklist/chk-identification/chk-identification.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;AACF","sourcesContent":[".chkidnt-col1{\n  max-width: 65%;\n  min-width: 50%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
