// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .material-tooltip {
  white-space: pre-line;
  max-width: 500px !important;
  font-size: 12px;
}

::ng-deep .mat-form-field-empty {
  text-align: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
::ng-deep .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-empty {
  text-align: left !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
::ng-deep .mat-form-field-ripple {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/common/timecomplete/timecomplete.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,2BAAA;EACA,eAAA;AACJ;;AAEE;EACI,kBAAA;AACN;;AAEE,iIAAA;AACA;EACI,2BAAA;AACN;;AAEE,iIAAA;AACA;EACI,6BAAA;AACN","sourcesContent":["::ng-deep .material-tooltip {\n    white-space: pre-line;\n    max-width: 500px !important;\n    font-size: 12px;\n  }\n\n  ::ng-deep .mat-form-field-empty {\n      text-align: center;\n  }\n\n  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\n  ::ng-deep .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-empty {\n      text-align: left !important\n  }\n\n  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\n  ::ng-deep .mat-form-field-ripple{\n      background-color: transparent;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
