
import {onErrorResumeNext as observableOnErrorResumeNext,  forkJoin as observableForkJoin} from 'rxjs';
import { Component, ViewChild, ElementRef, OnInit, ViewChildren, QueryList, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { ProgressBarService, NewOrderService, AuthService, PricingService, OrdersService, ConfigService, 
  ProductService, AwsS3Service, TagsService, SchedulingService } from '../../../services'
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../../../dialog/success/success.component';
import { CONSTANTS } from '../../../app.constants'
import * as moment from 'moment';
import { FileService } from '../../../services';
import { ConfirmComponent } from '../../../dialog/confirm/confirm.component';
import { EtaAlertComponent } from '../../../dialog/eta-alert/eta-alert.component';
import { ErrorDialogComponent } from '../../../dialog/error-dialog/error-dialog.component';
import * as momentTimeZone from 'moment-timezone';
import { NewTagComponent } from '../../../dialog/new-tag/new-tag.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ignoreElements } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-new-order3',
  templateUrl: './new-order3.component.html',
  styleUrls: ['./new-order3.component.scss']
})
export class NewOrder3Component implements OnInit {
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('firstFocus') firstFocus: ElementRef;   
  @ViewChildren('subProductFocus') subProductFocus: QueryList<ElementRef>
  productDetails: any = {}
  orderDetails: any = {}
  requestedPrice: any;
  primaryColor = '#0F9D58';
  completeAddress: string = '';
  states: any;
  counties: any;
  products: any;
  allProducts=[];
  subProductList = [];
  baseProduct = [];
  subProducts = [];
  defaultproducts = [];
  selectedState = '';
  selectedCounty = '';
  suggTotPrice = 0;
  totalProductPrice = 0;
  totalTaxPrice = 0;
  grandTotPrice = 0;
  getUSDateString = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
  minDate = new Date(this.getUSDateString);
  error: any;
  errors: any = {};
  showErrMsg = '';
  dotEvent = [];
  input = ""; 
  newDocs = [];
  isRushOrder: boolean = false;   
  isEscalated: boolean = false;   
  subProductFocusFlag:boolean = false;
  constants = CONSTANTS;
  order_ETA_Time :string = "17:00" ; 
  requested_Time :string = "17:00" ;
  orderETAtime = new UntypedFormControl({value: '', disabled: true}, [Validators.required]);
  searchTag: string = '';
  availableTags: any = [];
  isDueRangeErr: boolean = false;
  dueDateFrmObj: any;
  dueDateTimeObj: any;
  dueDate_Range: any;
  dueDateRangeOption: Number
  order_ETA_Date: any;
  order_Requested_Date: any;
  timer: any = [];
  isPricingFlag: boolean = false;
  holidayList: any;
  weekendHolidayName: String;
  newOrderForm: UntypedFormGroup;
  constructor(
    private progressServ: ProgressBarService,
    private newOrder: NewOrderService,
    private router: Router,
    private auth: AuthService,
    private route: ActivatedRoute,
    private pricingService: PricingService,
    private matDialog:MatDialog,
    private ordersService: OrdersService,
    private fileService: FileService,
    private config: ConfigService,
    private productsService: ProductService,
    private s3Service: AwsS3Service,    
    private tagService: TagsService,
    private orderService: OrdersService,
    private holidayScheduling: SchedulingService,
    private frmBuilder: UntypedFormBuilder,
    private ref: ChangeDetectorRef,

  ) {

    this.newOrderForm = frmBuilder.group({
      Order_Requested_Date: [{ disabled: false, value: '' }],
      Order_ETA: [{ disabled: false, value: '' }],

    })
    this.newOrderForm.valueChanges.subscribe((data) => {
      this.errors = '';
    });

  }

  ngOnInit() {
    this.setProgressbar();
    if (!this.newOrder.getStep1Data()) {
      this.router.navigate(['/customer/orders/new/step1']);
    }
    else {
      this.orderDetails = Object.assign({}, this.newOrder.getStep1Data());
      if (!this.orderDetails.orderDocId) this.orderDetails.orderDocId = [];
      if (!this.orderDetails.Order_Tags) this.orderDetails.Order_Tags = [];
      if (!(this.orderDetails.Order_Products && this.orderDetails.Order_Products[0].Order_Product_ID)) {
        this.orderDetails.Order_Products[0].Order_Product_ID = 'new' + UUID.UUID();
        this.orderDetails.Order_Products[0].Cost_Amount = 0;
        this.orderDetails.Order_Products[0].Final_Cost = 0;
        this.orderDetails.Order_Products.forEach((product) => {
          this.resetPriceFields(product.Order_Product_ID)
        })
      }
      this.getOrderProductsForClient(this.orderDetails)
      // this.ordersService.getOrderProducts().subscribe((products) => { if (products) this.products = products });
      this.getSubProductList();
      this.getAllsubNBaseProd();
      this.buildAddress(this.orderDetails);
      if (this.orderDetails.Property_State_Abbr) {
        this.selectedState = this.orderDetails.Property_State_Abbr;
        //this.pricingService.getAvailableCountyList(this.selectedState).subscribe((counties) => { if (counties) this.counties = counties })
        this.pricingService.getCountyList(this.selectedState).subscribe((counties) => { if (counties) this.counties = counties })
      }
      // if (this.newOrder.getStep1Data().Property_County) {
      if (this.orderDetails && this.orderDetails.Property_County) {
        this.selectedCounty = this.orderDetails.Property_County.replace(' County', '');
        this.baseProduct = this.getBaseProduct();
        this.subProducts = this.getSubProducts();
        this.orderDetails.Order_Products.map((newProd) => newProd.currInput = '');
        this.orderDetails.Order_Products.map((newProd) => newProd.currBaseInput = '');
        this.addDefaultsToOrderProducts();
        this.getProductPricing(this.baseProduct[0].Product_ID, this.selectedState, this.selectedCounty, 'baseProductCost', 'baseProductCost', null, null);
        //this.addCopyCharges();

      } else {
        //this.pricingService.getAvailableStateList().subscribe((states) => { if (states) this.states = states });
        this.pricingService.getStateList().subscribe((states) => { if (states) this.states = states });
        // this.baseProduct[0].Product_ID=0;     
      }
      if(this.orderDetails.Order_ETA) this.getAvlOrderETAnTime();
      // this.getholidaylist();
    this.getholidaylistFromLocal();
    }
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.firstFocus.nativeElement.focus();
    }, 0));
    this.fetchUploadProgress();
    this.ref.detectChanges()
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getOrderProductsForClient(orderDetails) {
    let userId; 
    if(orderDetails.Organization_ID) userId = orderDetails.Organization_ID
    else userId = orderDetails.Customer_ID;
    this.orderService.getOrderProductsForClient(userId)
    .subscribe((products) => {
      this.checkForAvailProduct(orderDetails, products);
    })
}

checkForAvailProduct(orderDetails, products){
  let productFindIndex = orderDetails.Order_Products.findIndex((productData) => productData.Order_Product_Status == CONSTANTS.selectProductStatus.baseProduct)
  let acutalProductFindIndex = products.findIndex((prod) => prod.Product_ID === orderDetails.Order_Products[productFindIndex].Product_ID)
  if(products[acutalProductFindIndex].Product_Description !== orderDetails.Order_Products[productFindIndex].Product_Description) {
    orderDetails.Order_Products[productFindIndex].Product_Description = products[acutalProductFindIndex].Product_Description
  }
  this.products = products;
}

  getAvlOrderETAnTime(){
    let etaFullDate = new Date(moment(this.orderDetails.Order_ETA).format("MM/DD/YYYY HH:mm"));
    let date = new Date(etaFullDate.toDateString());
    this.orderDetails.Order_ETA = date;
    let etaHours = etaFullDate.getHours().toString();
    let etaMinutes= etaFullDate.getMinutes().toString();
    if(etaHours.length == 1) etaHours = "0"+etaHours;
    if(etaMinutes.length == 1) etaMinutes = "0"+etaMinutes;
    this.order_ETA_Time = etaHours+ ":"+etaMinutes;
    if(this.orderDetails.Order_ETA) this.orderETAtime.enable();
  }

  ngAfterViewInit(){      
    this.subProductFocus.changes.subscribe((input) => {
      if(input.last && !this.subProductFocusFlag) {
        this.subProductFocusFlag = true;
        this.timer.push(setTimeout(() => { input.last.nativeElement.focus() }, 0));
      }
    });    
  }

  setProgressbar() {
    this.progressServ.setPrimaryColor(this.primaryColor);
    this.progressServ.setHeight(2);
    this.progressServ.setStage(2);
    this.progressServ.setTotalStages(2);
    this.progressServ.setProgressState((1) * 100);
    this.progressServ.setBarType(1);
  }

  getCountyList(event) {
    console.log("Event", event);
    if (event.length != 0) {
      this.selectedCounty = '';
      // this.baseProduct[0].Product_ID=0;
      //this.pricingService.getAvailableCountyList(this.selectedState).subscribe((counties) => { if (counties) this.counties = counties })
      this.pricingService.getCountyList(this.selectedState).subscribe((counties) => { if (counties) this.counties = counties })
    }
  }

  getProductPricingDetails(event, productId, county, id, modelname, index,subProductIndex=null) {
    if (event && county) {
      this.orderDetails.BaseProduct = productId;
      this.getProductPricing(productId, this.selectedState, county, id, modelname, index,subProductIndex)
    }

  }

  getBaseProduct() {
    if (this.orderDetails.Order_Products && this.orderDetails.Order_Products.length)
      return this.orderDetails.Order_Products.filter((product) => product.Order_Product_Status == CONSTANTS.selectProductStatus.baseProduct)
    else return [];
  }

  getSubProducts() {
    if (this.orderDetails.Order_Products && this.orderDetails.Order_Products.length)
      return this.orderDetails.Order_Products.filter((product) => product.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct)
    else return [];
  }

  getDefaultProducts() {
    if (this.orderDetails.Order_Products && this.orderDetails.Order_Products.length)
      return this.orderDetails.Order_Products.filter((product) =>  product.Product_ID == environment.copyChargeProductId)
    else return [];
  }

  createOrderWithActivePrice() {
      if(this.isDueRangeErr) {
        this.order_ETA_Time = "17:00";
        this.requested_Time = "17:00";
        if(this.order_ETA_Date) this.order_ETA_Date = null;
        if(this.orderDetails.Order_ETA )this.orderDetails.Order_ETA = null;
        if(this.dueDate_Range) this.order_ETA_Date = this.setOrderETATime(this.dueDate_Range);
      }
      else {
        if(this.orderDetails.Order_ETA_Range) this.orderDetails.Order_ETA_Range = null;
        if(this.order_ETA_Date)this.order_ETA_Date = null;
        if(this.orderDetails.Order_ETA && this.order_ETA_Time) this.order_ETA_Date = this.setOrderETATime(this.orderDetails.Order_ETA);
      }
      if(this.orderDetails.Order_Requested_Date){
      if(this.orderDetails.Order_Requested_Date && this.requested_Time) this.order_Requested_Date =  this.setOrderRequestDate(this.orderDetails.Order_Requested_Date)
    }
      var data = {
        Order_Status: CONSTANTS.orderStatus.received,
        Order_Final_Price: this.suggTotPrice,
        Order_Custom_Price_Tax: this.totalTaxPrice,
        Order_Min_Tat: this.productDetails.Cost_Min_Tat,
        Order_Max_Tat: this.productDetails.Cost_Max_Tat,
        Order_Custom_Price: this.totalProductPrice,        
        Rush_Order: this.isRushOrder,
        Escalated_Order: this.isEscalated
      }
      this.orderDetails.selectedCriteria = this.newOrder.getSelectedCriteria();
      if(this.newOrder.getselectedOption() == 'Manual Entry' && this.newOrder.getSelectedCriteria() == 1) this.orderDetails.manualEntryFlag = true;
      else this.orderDetails.manualEntryFlag = false;
      this.orderDetails.Property_State_Abbr = this.selectedState;
      this.orderDetails.Property_County = this.selectedCounty;
      this.newOrder.setStep3data(data);
      if(!this.checkIfSearchMaster()) this.createOrder(this.order_ETA_Date,this.order_Requested_Date);
      else {
      if (this.alertMsgForPlaceOrder()) this.createOrder(this.order_ETA_Date,this.order_Requested_Date);
      else {
        this.openETAAlert().afterClosed()
          .subscribe((res) => {
            if (res) {
              this.createOrder(this.order_ETA_Date,this.order_Requested_Date);
            }
          })
      }
      }
   // }
  }

  setOrderETATime(orderETADate) {
    if(orderETADate && orderETADate._d){
      let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
      let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
      return orderETADate._d.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    } else if(orderETADate && !orderETADate._d){
      let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
      let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
      return orderETADate.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    } else return null;
  }
  
  setOrderRequestDate(RequestDate){
    if(RequestDate && RequestDate._d){
      let getOrderEtaHours = this.requested_Time.split(":")[0];
      let getOrderEtaMinutes = this.requested_Time.split(":")[1];
      return RequestDate._d.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    } else if(RequestDate && !RequestDate._d){
      let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
      let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
      return RequestDate.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    } else return null;
  }
  
  addCopyCharges() {
    if (this.orderDetails.Order_Products.findIndex(x => x.Product_ID == 7) == -1) {
      var newProd: any = {};
      newProd.Order_Product_ID = 'new' + UUID.UUID();
      newProd.Order_ID = this.orderDetails.Order_ID;
      // hardcoded for copy charges
      newProd.Product_ID = 7;
      newProd.Order_Product_Custom_Name = '';
      newProd.Order_Product_Status = CONSTANTS.selectProductStatus.subproduct;
      newProd.Cost_Amount = 0;
      newProd.Final_Cost = 0;
      this.orderDetails.Order_Products.push(newProd);
      this.calculateTotal();
    } else {
      console.log("Not adding");
    }
  }

  createOrder(finalDueDate,finalReqDate) {
    if(this.orderDetails.Order_ETA) this.checkForSelectedETA(this.orderDetails.Order_ETA, finalDueDate,finalReqDate);
    else this.createOrderSteps(finalDueDate,finalReqDate)
  }

  createOrderSteps(finalDueDate,finalReqDate){
    if(finalDueDate){
      var estShift = momentTimeZone.tz(finalDueDate, 'America/New_York').isDST() ? 4 : 5;
      var etaInEstTimezone = moment(finalDueDate).utcOffset(moment(finalDueDate).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
      this.orderDetails.Order_ETA = etaInEstTimezone;
    }  
    if(finalReqDate) {
      var estShift = momentTimeZone.tz(finalReqDate, 'America/New_York').isDST() ? 4 : 5;
      var etaInEstTimezone = moment(finalReqDate).utcOffset(moment(finalReqDate).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
      this.orderDetails.Order_Requested_Date = etaInEstTimezone;
    }
    // if(this.orderDetails.Order_Mortgage_Date) {
    //   let mortgageShift = momentTimeZone.tz(this.orderDetails.Order_Mortgage_Date, 'America/New_York').isDST() ? 4 : 5;
    //   let mortgageEstTimezone = moment(this.orderDetails.Order_Mortgage_Date).utcOffset(moment(this.orderDetails.Order_Mortgage_Date).utcOffset()).add(mortgageShift, 'hours').add(17, 'hours').format('YYYY-MM-DDTHH:mm');
    //   this.orderDetails.Order_Mortgage_Date = mortgageEstTimezone;
    //   }
    this.newOrder.setStep1data(this.orderDetails);
    this.orderDetails.Order_Products.map((orderProduct) => {
      delete orderProduct.currInput; 
      delete orderProduct.currBaseInput;
    });
    this.removeUnusedProductPricings();
    this.addCustomInputDescription();
    if(this.orderDetails.Order_ETA)this.orderDetails.Order_ETA='';
    if(this.orderDetails.Order_Requested_Date) this.orderDetails.Order_Requested_Date='';
    this.newOrder.createNewOrder(this.orderDetails.Customer_ID)
      .subscribe((data) => {
        if (this.orderDetails.orderDocId.length > 0) this.updateOrderDocs(data);
        this.router.navigate(['/orders/' + data.Order_ID + '/view']);
        this.newOrder.resetAll();
      }, (err) => {
        console.log(err);
        if (err.msg) {
          this.error = err.msg
        }
      })
  }

  goToStep1() {
    console.log("Checking step 1 data before going", this.orderDetails);
    if(this.orderDetails.Order_ETA) this.orderDetails.Order_ETA = this.setOrderETATime(this.orderDetails.Order_ETA);
    this.orderDetails.BaseProduct = this.orderDetails.Order_Products[0].Product_ID;
    this.newOrder.setStep1data(this.orderDetails);
    // this.router.navigate(['/customer/orders/new/step1/' + 1]);
    this.router.navigate(['/customer/orders/new/step2']);
  }

  uploadDocument(event) {
    if (this.validateFilenames(event)) {
      var fileUploads = [];
      var propertyUid = [];
      if (this.checksize(event.target.files)) {
        for (var i = 0; i < event.target.files.length; i++) { // 
          var uid = UUID.UUID();
          this.newDocs.push({docId:uid, name: event.target.files[i].name, completed:0});
          fileUploads.push(this.uploadToS3AndUpdatePath(event.target.files[i], uid))
        }
        observableForkJoin(fileUploads)
        .subscribe((files) =>{
          this.removeCompletedDocs(files)
          var succesFiles = files.filter((file) => file !== undefined);
          if(succesFiles.length > 0) this.orderDetails.orderDocId = this.orderDetails.orderDocId.concat(succesFiles); 
        })
        Promise.all(fileUploads)
        .then((files) => {
                                 
        })
        .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload) })
      }
      else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    }

    else {
      this.openErrorPopup(CONSTANTS.apiErrors.validateFile)
    }
  }

  removeCompletedDocs(files) {
    for (var i = 0, len = files.length; i < len; i++) {
      for (var j = 0, len2 = this.newDocs.length; j < len2; j++) {
        if (files[i] && files[i].docId === this.newDocs[j].docId) {
          this.newDocs.splice(j, 1);
          len2 = this.newDocs.length;
        }
      }
    }
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(files, (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024);
    if (largeFiles.length > 0) return false;
    else return true;
  }

  validateFilenames(event) {
    console.log("selected files", event.target.files);
    var flag = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      var filename = event.target.files[i].name;
      if (filename.indexOf(',') > -1) flag++;
    }
    if (flag == 0) return true;
    else return false;
  }

  openFileSelector() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
    //this.fileInput.nativeElement.value = null;
  }

  updateOrderDocs(data) {
    var docUpdate = [];
    console.log("orderDocs", this.orderDetails.orderDocId);
    this.orderDetails.orderDocId.forEach(docId => {
      docUpdate.push(this.newOrder.updateOrderDocument(data.Order_ID, data.Property_ID, docId.Order_Document_ID));
    })
    observableForkJoin(docUpdate)
    .subscribe(() => {})
    observableOnErrorResumeNext(docUpdate)
      .subscribe(result => {
        console.log("Data from ForkJoin: ", result);
      },
        err => {
          console.log("Error in Upload: ", err)
        })
  }

  deleteFile(documents) {
    this.matDialog.open(ConfirmComponent, {data:{
      title: 'Delete Document',
      message: 'Are you sure you want to delete ' + documents.Document_Original_Name + ' ?'
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.fileService.delete(documents.Order_Document_ID, this.auth.getUserId())
            .subscribe(
              (data) => {
                console.log("Data", data);
                this.orderDetails.orderDocId.splice(this.orderDetails.orderDocId.indexOf(documents), 1);
              },
              (err) => {
                console.log(err);
                this.openErrorPopup(CONSTANTS.apiErrors.docdelete)
              })
        } else {
          console.log("Closed", res)
        }
      });
  }

  openDialog() {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: 'Success',
      message: 'Your order has been placed.'
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      console.log("Hello:", res);
      this.router.navigate(['/orders/new'])
    });
  }

  buildAddress(model) {
    this.completeAddress = '';
    if (model.Property_Address_1) this.completeAddress += model.Property_Address_1
    if (model.Property_Address_2) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += model.Property_Address_2
    }
    if (model.Property_City) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += model.Property_City
    }
    if (model.Property_State_Abbr) {
      if (this.completeAddress) this.completeAddress += ', '
      this.completeAddress += model.Property_State_Abbr
    }
    if (model.Property_ZipCode) {
      if (this.completeAddress) this.completeAddress += ' '
      this.completeAddress += model.Property_ZipCode
    }
  }

  validateFinalPricingFields() {
    if (!this.grandTotPrice || (this.totalTaxPrice == null || this.totalTaxPrice == undefined || this.totalTaxPrice.toString() == '') || !this.selectedState || !this.selectedCounty
      || !this.validateProductPricingFields()) return false;
    else return true;
  }

  validateOrderETADateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy
    if(event.value) this.orderETAtime.enable();
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors.Order_ETA = true;
    else delete this.errors.Order_ETA;
  }

  getIfErrors() {
    if (Object.keys(this.errors).length > 0) {
      return true
    }
    else return false;
  }

  getOrderEta() {
    if (this.orderDetails.Order_ETA) return 1;
    else return 0;
  }

  validateProductPricingFields() {
    // var flag = 0;
    // this.orderDetails.Order_Products.forEach((orderProduct) => {
    //   if (orderProduct.Final_Cost == null || orderProduct.Final_Cost == undefined) flag++;
    // })
    // console.log("flag", flag);
    // if (flag == 0) return true;
    // else return false;
    if (this.orderDetails.Order_Products) {
      if (this.orderDetails.Order_Products.findIndex(orderProduct => isNaN(parseInt(orderProduct.Final_Cost)) && orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.baseProduct) > -1) return false;
      else return true;
    }

  }

  getProductPricing(productId, state, county, id, modelname, index,subProductIndex) {
    if (productId && state && county) {
      let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID:  this.orderDetails.Customer_ID;
      this.pricingService.getCustomAndOrginalPriceDetails(state, county, productId, customerId)
        .subscribe((data) => {
          if (data != null) {
            this.productDetails = data;
            if (index == null) {
              if (data.Original_Cost) {
                this.productDetails.Cost_Min_Tat = data.Original_Cost.Cost_Min_Tat;
                this.productDetails.Cost_Max_Tat = data.Original_Cost.Cost_Max_Tat;
              }
            }
            this.loadPricing(id, data, modelname, index,subProductIndex);
            this.resetProductPrice(productId)
            this.calculateTotal();
          }
          else {
            this.loadPricing(id, null, modelname, index,subProductIndex);
            this.resetProductPrice(productId)
            this.calculateTotal();
            this.productDetails.Cost_Min_Tat = null;
            this.productDetails.Cost_Max_Tat = null;
          }

        }, (err) => {
          console.log(err);
        })
    }
  }

  resetProductPrice(productId){
    var prodChangedIndex = this.orderDetails.Order_Products.findIndex((orderProduct) => orderProduct.Product_ID == productId)
    if(this.orderDetails.Order_Products[prodChangedIndex].currInput)
    this.orderDetails.Order_Products[prodChangedIndex].currInput = '';
  }

  loadPricing(id, pricing, modelname, index,subProductIndex) {
    var CustomPrice = null;
    var OriginalPrice = null;

    if (pricing.Original_Cost != null) OriginalPrice = pricing.Original_Cost.Cost_Amount;

    if (pricing.Custom_Cost != null) {
      console.log("custom cost present")
      CustomPrice = pricing.Custom_Cost.Custom_Cost_Amount
    }
    else CustomPrice = OriginalPrice;

    if (pricing.Original_Cost == null && pricing.Custom_Cost == null) CustomPrice = OriginalPrice = 0;

if(subProductIndex != null){
  this.orderDetails.Order_Products[subProductIndex + 2].Cost_Amount = OriginalPrice;
  this.orderDetails.Order_Products[subProductIndex + 2].Final_Cost = CustomPrice;
}else{
  if (index != null) {
      this.getSubProducts()[index].Cost_Amount = OriginalPrice;
      this.getSubProducts()[index].Final_Cost = CustomPrice;
    } else {
      this.orderDetails.Order_Products[0].Cost_Amount = OriginalPrice;
      this.orderDetails.Order_Products[0].Final_Cost = CustomPrice;
    }
}

    if (id && document.getElementById(id)) document.getElementById(id).parentElement.classList.remove('is-empty');
  }

  resetPriceFields(fieldId) {
    if (document.getElementById('Product_Price_' + fieldId)) {
      document.getElementById('Product_Price_' + fieldId).parentElement.classList.remove('is-empty');
      document.getElementById('Final_Price_' + fieldId).parentElement.classList.remove('is-empty');
    }
  }

  addproduct() {
    var newProd: any = {};
    newProd.Order_Product_ID = 'new' + UUID.UUID();
    newProd.Order_ID = this.orderDetails.Order_ID;
    newProd.Order_Product_Custom_Input_Description = '';
    newProd.Order_Product_Custom_Name = '';
    newProd.Product_Description = '';
    newProd.Order_Product_Flag = CONSTANTS.orderProductFlag.active;
    newProd.Order_Product_Status = CONSTANTS.selectProductStatus.subproduct;
    newProd.Final_Cost = "0.00";
    newProd.currInput = '';
    newProd.currBaseInput = '';
    newProd.Cost_Amount = '';
    this.orderDetails.Order_Products.push(newProd);
    this.calculateTotal();
    this.assignSubProductFocus();
  }

  getSubProductList() {
    this.newOrder.getSubProductList()
      .subscribe((productList) => {
        if (productList) {
          this.subProductList = productList;
        }
      }, (err) => {
        console.log(err)
      });
  }

  calculateTotal() {
    var total = 0;
    if (this.orderDetails.Order_Products) {
      this.orderDetails.Order_Products.forEach((prod) => {
        // prod.Final_Cost = 0;
        // prod.Cost_Amount = 0;
        if (prod.Final_Cost) total = Number(total) + Number(prod.Final_Cost);
      })
      this.totalProductPrice = Number(total.toFixed(2));
      this.grandTotPrice = Number((Number(this.totalTaxPrice) + Number(this.totalProductPrice)).toFixed(2));
    }
  }

  validateGrandTotal(){
    if(this.grandTotPrice<0) return false;
    return true;
  }


  removeSubProduct(orderProduct) {
    this.removeByOrderProductId(orderProduct.Order_Product_ID);
    this.removeDeletedfieldEvent(orderProduct);
    this.assignSubProductFocus();
  }

  removeByOrderProductId(orderProductId) {
    var i = this.orderDetails.Order_Products.length;
    while (i--) {
      if (this.orderDetails.Order_Products[i]
        && this.orderDetails.Order_Products[i].hasOwnProperty('Order_Product_ID')
        && (this.orderDetails.Order_Products[i]['Order_Product_ID'] === orderProductId)) {
        this.orderDetails.Order_Products.splice(i, 1);
      }
    }
    this.calculateTotal();
    return this.orderDetails.Order_Products;
  }

  removeDeletedfieldEvent(orderProduct) {
    var orderProductId = orderProduct.Order_Product_ID
    var finalpriceFieldId = "Final_Price_" + orderProductId;
    var matchedFinalEventIndex = this.dotEvent.findIndex((eventItem) => eventItem.ID == finalpriceFieldId);
    if (matchedFinalEventIndex > -1) this.dotEvent.splice(matchedFinalEventIndex, 1);
    if (orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
      var basepriceFieldId = "newOrder3InpBasePrc" + orderProductId;
      var matchedBaseEventIndex = this.dotEvent.findIndex((eventItem) => eventItem.ID == basepriceFieldId);
      if (matchedBaseEventIndex > -1) this.dotEvent.splice(matchedBaseEventIndex, 1);
    }
  }

  totalChange(event) {
    this.grandTotPrice = Number(event) + Number(this.totalTaxPrice);
  }

  isNumber(value) {
    return isNaN(value);
  }

  totalSubProductPrice() {
    var total = 0;
    if (this.orderDetails.Order_Products) {
      this.orderDetails.Order_Products.forEach((prod) => {
        if (prod.Cost_Amount) total = Number(total) + Number(prod.Cost_Amount);
      })
    }
    this.suggTotPrice = Number(total);
    return Number(total);
  }

  alertMsgForPlaceOrder() {
    if (this.grandTotPrice) return 1;
    else return 0;
  }

  openETAAlert() {
    return this.matDialog.open(EtaAlertComponent, {data:{
      title: 'Place order',
      message: 'Grand total is $0. Do you want to proceed?',
      value:'Proceed'
    }, ...this.config.getDialogOptions()})
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  removeUnusedProductPricings() {
    this.orderDetails.Order_Products.forEach((orderProduct) => {
      if (isNaN(parseInt(orderProduct.Final_Cost))) orderProduct.Final_Cost = 0;
    })
  }

  addDefaultsToOrderProducts() {
    this.productsService.getProduct(environment.copyChargeProductId)
      .subscribe((defaults) => {
        if (defaults) {
          let defProductData = [];
          defProductData.push(defaults);
          this.defaultproducts = defProductData;
          defProductData.forEach((item, index) => {
            if (this.orderDetails.Order_Products.findIndex(x => x.Product_ID == item.Product_ID) == -1)
            this.orderDetails.Order_Products.push(this.prepareOrderProduct(item));
            this.getDefaultProdPricing(item.Product_ID, this.selectedState, this.selectedCounty, index)
          });
          this.calculateTotal();
        }         
        //PT-3613
        this.addAdditionalProducts();
      })
  }

  getDefaultProdPricing(productId, state, county, index){
    if (productId && state && county) {
      let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID:  this.orderDetails.Customer_ID;
      this.pricingService.getCustomAndOrginalPriceDetails(state, county, productId, customerId)
        .subscribe((data) => {
          if (data.Original_Cost) {
            this.isPricingFlag = true;
            this.getDefaultProducts()[index].Cost_Amount = data.Original_Cost.Cost_Amount;
            // if (data.Custom_Cost) this.getDefaultProducts()[index].Final_Cost = data.Custom_Cost.Custom_Cost_Amount;
            // else this.getDefaultProducts()[index].Final_Cost = data.Original_Cost.Cost_Amount;
            this.getDefaultProducts()[index].Final_Cost = 0;
          }else{ 
            this.isPricingFlag = false;
            this.getDefaultProducts()[index].Final_Cost = 0;
          }
          this.calculateTotal();
        }, (err) => {
          console.log(err);
        })
    }
}

// PT-3613
addAdditionalProducts() {
  if(this.orderDetails.Organization_ID){
    let newIdx = this.orderDetails.Order_Products.length;
    this.pricingService.getAdditionalCompanyProducts(this.orderDetails.Organization_ID)
      .subscribe((data) => data.forEach((prod=>{
        if (prod.Mapping_Type == 1 && this.orderDetails.Order_Products.findIndex(x => x.Product_ID == prod.Product_ID) == -1){
          this.addproduct();
          this.orderDetails.Order_Products[newIdx].Product_ID = prod.Product_ID;
          this.orderDetails.Order_Products[newIdx].Product_Name = prod.Product_Name;
          this.orderDetails.Order_Products[newIdx].Product_Description = prod.Product_Description;
          this.getAdditionalProdPricing(prod.Product_ID, this.selectedState, this.selectedCounty, newIdx);
          this.calculateTotal();
          newIdx++;
        }
      })));
  }
 }

 // PT-3613
 getAdditionalProdPricing(productId, state, county, index){
   if (productId && state && county) {
     let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID:  this.orderDetails.Customer_ID;
     this.pricingService.getCustomAndOrginalPriceDetails(state, county, productId, customerId)
       .subscribe((data) => {
         if (data.Original_Cost) {
           this.isPricingFlag = true;
           this.orderDetails.Order_Products[index].Cost_Amount = data.Original_Cost.Cost_Amount;
           this.orderDetails.Order_Products[index].Final_Cost = 0;
         }else{ 
           this.isPricingFlag = false;
           this.orderDetails.Order_Products[index].Final_Cost = 0;
         }
         this.calculateTotal();
       }, (err) => {
         console.log(err);
       })
   }
 }

  prepareOrderProduct(product) {
    return {
      Order_Product_ID: 'new' + UUID.UUID(),
      Order_ID: this.orderDetails.Order_ID,
      Product_ID: product.Product_ID,
      Order_Product_Status: CONSTANTS.selectProductStatus.defaultProduct,
      Cost_Amount: 0,
      Final_Cost: 0,
      Product_Name: product.Product_Name,
      Product_Description: product.Product_Description,
      currInput: '',
      currBaseInput: ''
    }
  }

  validatePrice() {
    if (this.orderDetails.Order_Products) {
      if (this.orderDetails.Order_Products.findIndex(orderProduct => (isNaN(parseInt(orderProduct.Final_Cost)) && ((orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) || (orderProduct.Product_ID == environment.copyChargeProductId)))) > -1) return true;
      else return false;
    }
  }
  validateSearchDesc() {
    if (this.orderDetails.Order_Products) {
      if (this.orderDetails.Order_Products.findIndex(orderProduct =>{
        return (orderProduct.Product_ID == 999) && ((orderProduct.Order_Product_Custom_Name == undefined) || (orderProduct.Order_Product_Custom_Name == ''))})) return true;
      else return false;
    }
  }


  checkAddProdDesc(){
    if (this.orderDetails.Order_Products){
    var flag = 0;
    this.orderDetails.Order_Products.forEach(orderProduct => {
            if (orderProduct.Order_Product_Flag == CONSTANTS.orderProductFlag.active && orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
              if(!orderProduct.hasOwnProperty('Product_ID')){
                flag++;
              }
              else if(orderProduct.hasOwnProperty('Product_ID')){
                if(orderProduct.Product_ID === 999){
                  if((orderProduct.Order_Product_Custom_Name == undefined) || (orderProduct.Order_Product_Custom_Name == '')){
                    flag++;
                  }
                }
              }
            }
          })
          if (flag == 0) return false;
              else return true;
        }
        else return false;
  }

  // getNumberFormat(e, orderProduct) {  
  //   if(e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
  //   if ((orderProduct.currInput.length == 0 && e.key == "0")) e.preventDefault();
  //   else {
  //     if ((e.keyCode == 8 || e.keyCode == 46) && (orderProduct.currInput.length > 0 || orderProduct.currInput.length < this.config.getLimitPriceMaxLength())) {
  //       orderProduct.currInput = orderProduct.currInput.slice(0, orderProduct.currInput.length - 1);
  //       e.target.value = this.numberFormat(orderProduct.currInput);
  //       orderProduct.Final_Cost = e.target.value;
  //     }
  //     else if (orderProduct.currInput.length < this.config.getLimitPriceMaxLength()) {
  //       var key = this.keyValue(e.keyCode);
  //       if (key) {
  //         orderProduct.currInput = orderProduct.currInput + key;
  //         e.target.value = this.numberFormat(orderProduct.currInput);
  //         orderProduct.Final_Cost = e.target.value;
  //       }
  //     }
  //     this.calculateTotal();
  //     return false;
  //   } 
  // }

  getNumberFormat(e, orderProduct) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true; // Allow left/right arrow keys and tab key
  
    // Allow negative sign only if it's at the start and there are no other negative signs
    if (e.key === "-" && orderProduct.currInput.length === 0) {
      orderProduct.currInput = e.key + orderProduct.currInput;
      e.target.value = this.numberFormat(orderProduct.currInput);
      orderProduct.Final_Cost = e.target.value;
      this.calculateTotal();
      return false;
    }
  
    // Prevent leading zeros
    if (orderProduct.currInput.length == 0 && e.key == "0") e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (orderProduct.currInput.length > 0 || orderProduct.currInput.length < this.config.getLimitPriceMaxLength())) {
        orderProduct.currInput = orderProduct.currInput.slice(0, orderProduct.currInput.length - 1);
        e.target.value = this.numberFormat(orderProduct.currInput);
        orderProduct.Final_Cost = e.target.value;
      }
      else if (orderProduct.currInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          orderProduct.currInput = orderProduct.currInput + key;
          e.target.value = this.numberFormat(orderProduct.currInput);
          orderProduct.Final_Cost = e.target.value;
        }
      }
      this.calculateTotal();
      return false;
    }
  }
  


  getBasePriceFormat(e, orderProduct) {   
    if(e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((orderProduct.currBaseInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (orderProduct.currBaseInput.length > 0 || orderProduct.currBaseInput.length < this.config.getLimitPriceMaxLength())) {
        orderProduct.currBaseInput = orderProduct.currBaseInput.slice(0, orderProduct.currBaseInput.length - 1);
        e.target.value = this.numberBaseFormat(orderProduct.currBaseInput);
        if(e.target.value == '')
        orderProduct.Cost_Amount = null;
        else
        orderProduct.Cost_Amount = e.target.value;
      }
      else if (orderProduct.currBaseInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          orderProduct.currBaseInput = orderProduct.currBaseInput + key;
          e.target.value = this.numberBaseFormat(orderProduct.currBaseInput);
          orderProduct.Cost_Amount = e.target.value;
        }
      }
      this.calculateTotal();
      return e.preventDefault();
    } 
  }

  getTaxFormat(e) {
    if(e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((this.input.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.input.length > 0 || this.input.length < this.config.getLimitPriceMaxLength())) {
        this.input = this.input.slice(0, this.input.length - 1);
        e.target.value = this.numberFormat(this.input);
        this.totalTaxPrice = e.target.value;
      }
      else if (this.input.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.input = this.input + key;
          e.target.value = this.numberFormat(this.input);
          this.totalTaxPrice = e.target.value;
        }
      }
      this.calculateTotal()
      return false;
    } 
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return "0.00";
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  numberBaseFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  fetchUploadProgress() {
    this.s3Service.progress
      .subscribe((res) => {
        var matchedIndex = this.newDocs.findIndex((doc) => doc.name == res.name);
        if(this.newDocs[matchedIndex]){
          this.newDocs[matchedIndex].completed = Math.round(((res.progress.loaded / res.progress.total) * 100) * 100) / 100;
          this.newDocs[matchedIndex].completed = Math.round(this.newDocs[matchedIndex].completed);
        }
      });
  }

  extractFilename(filePath) {
    var parts = filePath.split('/');
    var filename = parts[parts.length - 1];
    return filename;
  }

  uploadToS3AndUpdatePath(file, propertyId) {
    return this.s3Service.onManagedUpload(file, '0')
      .then((data) => {
        if(data){
          return this.s3Service.updateDocumentPath('0',
          propertyId,
          file,
          data.Key,
          this.extractFilename(data.Key),
          CONSTANTS.orderDocumentType.internalDocs
        )
        .then((doc) => {
          doc['docId'] = propertyId;
          doc['completed'] = 100;
          return doc; 
        })
          .catch((err) => { throw err; })
        }
      })
      .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload); })
  }

  assignSubProductFocus(){    
    this.subProductFocusFlag = false;
  }

  checkStartDate(etaDate){
    if(!etaDate){
      let newDate = new Date(this.getUSDateString);
      if(newDate.getHours() > 17){
        newDate.setDate(newDate.getDate() + 1)
        newDate.setHours(17);
        newDate.setMinutes(0);
      } else {
        newDate.setHours(17);
        newDate.setMinutes(0);
      }
      return newDate;
    }
  }
  
  getFontColor(hexColor) {
    return this.tagService.getFontColor(hexColor)
  }

  openNewTagModal(orderId) {
    this.matMenuTrigger.closeMenu();
    this.matDialog.open(NewTagComponent, {data:{
      message: { Order_ID: null }
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if (res) {
    // sessionStorage.removeItem('AllTagsAvailable');
        if(typeof res == 'object') this.orderDetails.Order_Tags.push(res);
      }
    });
  }

  clearSearchTag() {
    this.searchTag = '';
  }

  menuClosed() {
    this.clearSearchTag();
    this.availableTags = [];
  }

//   getAvailableTagLocal(){
//     let AllTag = JSON.parse(sessionStorage.getItem('AllTagsAvailable'));
//     if(AllTag) this.openTagMenu(AllTag)         
//     else this.getAvailableTagList();
// }
  
//   getAvailableTagList(){
//     this.tagService.getAllActiveTagsForListing().subscribe((tags) => { 
//       if(tags){
//         this.openTagMenu(tags)         
//         sessionStorage.setItem('AllTagsAvailable',JSON.stringify(tags)); 
//        setTimeout(function(){sessionStorage.removeItem("AllTagsAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     }, (err) => {
//         this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
//     })
//   }


  openTagMenu() {
    this.tagService.getAllActiveTagsForListing()
      .subscribe((activeTags) => {
        for (var i = 0, len = activeTags.rows.length; i < len; i++) {
          if (this.orderDetails.Order_Tags.findIndex((select => activeTags.rows[i].Tag_ID == select.Tag_ID)) == -1) {
            if(activeTags.rows[i].Tag_ID != this.config.getNewClientTagId() && activeTags.rows[i].Tag_ID != this.config.getPriorityClientTagId() 
            && activeTags.rows[i].Tag_ID != this.config.getFirstOrdTagId() && activeTags.rows[i].Tag_ID != this.config.getPrePaidTagId() 
          && activeTags.rows[i].Tag_ID != this.config.getPartiallyDeliveredTagId()
          && activeTags.rows[i].Tag_ID != this.config.getAwaitDlvryTagId() && activeTags.rows[i].Tag_ID != this.config.getAwaitPartDlvryTagId()) 
          this.availableTags.push(activeTags.rows[i]);
          }
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }   

  // openTagMenu(activeTags) {
  //       for (var i = 0, len = activeTags.rows.length; i < len; i++) {
  //         if (this.orderDetails.Order_Tags.findIndex((select => activeTags.rows[i].Tag_ID == select.Tag_ID)) == -1)
  //           this.availableTags.push(activeTags.rows[i]);
  //       }
  // }   
  
  


  removeTag(selectedTag) {   
    var matchedIndex = this.orderDetails.Order_Tags.findIndex((tag) => tag.Tag_ID == selectedTag.Tag_ID)
    if(matchedIndex > -1) this.orderDetails.Order_Tags.splice(matchedIndex, 1); 
  }

  identify(index, tag) {
    return tag.Tag_ID;
  }

  modifyOrderTags(event, tag) {    
    if(event.checked) {
      this.orderDetails.Order_Tags.push(tag);
      this.availableTags = this.availableTags.filter(item => (item.Tag_ID !== tag.Tag_ID))
    }else this.removeTag(tag);
  }

  getDueDateData(event) {
    this.dueDateRangeOption = Number(event.dueDateType);
    if (Number(event.dueDateType)) {
      if (event.dueDateFrmCtrl) {
        this.isDueRangeErr = true;
        this.dueDateFrmObj = event.dueDateFrmCtrl;
        if(this.dueDateFrmObj) {
          if (this.dueDateFrmObj.value.fromDayCtrl && this.dueDateFrmObj.value.toDayCtrl) {
            let getDueDateRange = this.dueDateFrmObj.value.fromDayCtrl + "-" + this.dueDateFrmObj.value.toDayCtrl;
            let addDays = momentTimeZone.tz('America/New_York').add(Number(this.dueDateFrmObj.value.toDayCtrl), 'days').format()
            let getDateObj =  this.Convert_UTCDate_To_LocalDate(addDays, 'America/New_York');
            this.dueDate_Range = getDateObj; 
            this.orderDetails.Order_ETA_Range = getDueDateRange;
          }else {
            this.dueDate_Range =null;
            this.orderDetails.Order_ETA_Range = null;        
          }
        }
      }
    } else {
      this.isDueRangeErr = false;
      if (event.dueDateTimeCtrl) {
        this.dueDateTimeObj = event.dueDateTimeCtrl;
        if (event.dueDateTimeCtrl.valid) this.checkForDueDateEST(event);
      }
      else this.checkForDueDateEST(event);
    }
  }

  Convert_UTCDate_To_LocalDate(utcTime: any, localTimeZone: string) {
    var utcMoment = momentTimeZone(utcTime);
    var localDateTime = utcMoment.tz(localTimeZone);
    return new Date(localDateTime.format('lll'));
  }

  checkForDueDateValid() {
    if (this.dueDateRangeOption) {
          if(this.dueDateFrmObj && this.dueDateFrmObj.valid) return false;
          else return true;
    } else {
      if (this.dueDateTimeObj) {
        if (this.dueDateTimeObj.valid) return false;
        else if (!this.dueDateTimeObj.valid) return true
      } else return false;
    }
  }

  checkForDueDateEST(event) {
    if (event.Due_Date_EST || event.Due_TIme_EST) {
      this.orderDetails.Order_ETA = event.Due_Date_EST;
      this.order_ETA_Time = event.Due_TIme_EST;
    }
    if(event.req_Date_EST || event.req_Time_EST){
      this.orderDetails.Order_Requested_Date = event.req_Date_EST;
      this.requested_Time = event.req_Time_EST;
    }
  }

  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return false;
    else return true;
  }

  getholidaylistFromLocal(){
    let allHolidays = JSON.parse(sessionStorage.getItem('holidays'))
    if(allHolidays) this.setHoliday(allHolidays)
    else this.getholidaylist();
  }

  setHoliday(holiday){
    this.holidayList = holiday
  }

  getholidaylist(){
    this.holidayScheduling.getAllHolidays()
    .subscribe((holidays)=>{
      if(holidays){
        this.setHoliday(holidays)
        sessionStorage.setItem('holidays', JSON.stringify(holidays));
     setTimeout(function(){sessionStorage.removeItem("holidays");}, CONSTANTS.sessiontimeOut.timeOut);
      }
      // this.holidayList = holidays;
    }, (err) =>{
      console.log("Error",err)
    })
  }

  checkForSelectedETA(orderETA, finalDueDate,finalReqDate){
    let orderETADate = moment(orderETA).format("YYYY-MM-DD");
    let checkETAIsHoliday = this.holidayList.findIndex((holiday) => {
      if(holiday && moment(holiday.Holiday_Date, "YYYY-MM-DD").isSame(orderETADate)) return true;
      else return false
    })
    if(checkETAIsHoliday > -1 ) this.showWarningPopup(this.holidayList[checkETAIsHoliday].Holiday_Name, finalDueDate,finalReqDate);
    else if(this.checkETALieOnWeekend(this.orderDetails.Order_ETA)) this.showWarningPopup(this.weekendHolidayName, finalDueDate,finalReqDate)
    else this.createOrderSteps(finalDueDate,finalReqDate);
  }

  showWarningPopup(holidayName, finalDueDate,finalReqDate){
    let disposable = this.matDialog.open(ConfirmComponent, {data:{
      title:'ETA Note',
      message:"The ETA on this order is a US holiday ("+holidayName+"). Would you like to continue?",
    }, ...this.config.getDialogOptions()}).afterClosed()
    .subscribe((res) => { 
      if(res) this.createOrderSteps(finalDueDate,finalReqDate);
    });
  }

  checkETALieOnWeekend(orderETA) {
    if(moment(orderETA).isoWeekday() == 7){ // iso Weekday for sunday is 7
      this.weekendHolidayName = "Sunday";
      return true;
    }
    else if(moment(orderETA).isoWeekday() == 6){ // iso Weekday for saturday is 6
      this.weekendHolidayName = "Saturday";
      return true;
    }else return false;
  }

  isSuperAdmin(){
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else if(this.auth.getUserId() == environment.superAdminId) return true;
    else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
    else return false;
  }

  getAllsubNBaseProd(){
    let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID:  this.orderDetails.Customer_ID;
    this.ordersService.getSubNBaseProduct(customerId)
   .subscribe((res)=>{
     this.allProducts = res;
   })
  }

  validSelectedProdDescription() {
    if (this.orderDetails.Order_Products) {
      var flag = 0;
      this.orderDetails.Order_Products.forEach(orderProduct => {
        if (orderProduct.Order_Product_Flag == CONSTANTS.orderProductFlag.active && orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
          if (orderProduct.Product_ID == '' || orderProduct.Product_ID == undefined) {
            flag++;
          }
        }
      })
      if (flag == 0) return false;
      else return true;
    }
    else return false;
  }

  addCustomInputDescription(){
    this.orderDetails.Order_Products.forEach((prodDesc,index)=>{
      if(prodDesc.Order_Product_Status == 1){
        let prodDescIndex = this.products.findIndex((prod)=>prod.Product_ID == prodDesc.Product_ID)
        prodDesc.Order_Product_Custom_Input_Description = this.products[prodDescIndex].Product_Description;
       }
          if(prodDesc.Order_Product_Status == 2){
           let prodDescIndex = this.allProducts.findIndex((prod)=>prod.Product_ID == prodDesc.Product_ID)
           prodDesc.Order_Product_Custom_Input_Description = this.allProducts[prodDescIndex].Product_Description;
           prodDesc.Order_Product_Description = this.allProducts[prodDescIndex].Product_Description;
          }
         })
  }

  validateReqDateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors.Order_Requested_Date = true;
    else this.errors = {};
  }
  clearForms(event){
    this.errors = {}
  }

}