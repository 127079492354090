import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";

@Component({
  selector: 'app-order-note',
  templateUrl: './order-note.component.html',
  styleUrls: ['./order-note.component.scss']
})
export class OrderNoteComponent  {
  // @ViewChild('okBtn') focusedElement: ElementRef;
  title: any;
  messageText: any = '';
  allowEmtyMsg : any = false;
  constructor(public dialogRef: MatDialogRef<OrderNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    
  }

  ngOnInit() {
    this.initDialog();
  }

  initDialog(){
    this.title = this.data.title;
    this.messageText = this.data.messageText;
    this.allowEmtyMsg = this.data.allowEmtyMsg;
  }

  confirm() {
    this.dialogRef.close({
      state: true,
      Message_Text: this.messageText.trim(),
    })
  }

  isMessageEmpty() {
    if(this.allowEmtyMsg) return false;
    else return this.messageText.trim().length == 0;
  }

  Close() {
    this.dialogRef.close(false);
  }

}
