// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chktaxes-col {
  width: 50%;
}

.clearfix {
  padding-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/order-checklist/chk-taxes/chk-taxes.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".chktaxes-col{\n  width: 50%;\n}\n\n.clearfix{\n  padding-top: 8px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
