// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  overflow-y: auto;
  max-height: 400px;
}

.modal-dialog {
  margin-top: 2rem;
  max-width: 1500px;
  min-width: 640px;
}

.emailFormField {
  width: 256px;
  margin-right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/abs-quote-message/abs-quote-message.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAGA;EACE,YAAA;EACA,kBAAA;AAAF","sourcesContent":[".modal-body {\n  overflow-y: auto;\n  max-height: 400px;\n}\n\n.modal-dialog {\n  margin-top: 2rem;\n  max-width: 1500px;\n  min-width: 640px;\n}\n\n\n.emailFormField{\n  width: 256px;\n  margin-right:16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
