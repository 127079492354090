import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService, ConfigService, QuickLinksService, StateService } from '../services';
import { CONSTANTS } from '../app.constants';
import { Router, ActivatedRoute  } from '@angular/router';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit {
  quickLinksForm: any = {};
  error: any = {};
  dataModel: any = {};
  quickLinksList = [];
  constants = CONSTANTS;
  isCreateBtnClicked: boolean = false;
  createLinkObj: any = {};
  timer: any = [];

  constructor(
    public quickLinksService: QuickLinksService,
    public auth: AuthService,
    private config: ConfigService,
    private router: Router,
    private stateService: StateService,
  ) {
  }

  ngOnInit() {
    this.getAllQuickLinks();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(21), 0));
  }
  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getAllQuickLinks() {
    this.quickLinksService.getAllQuickLinks()
      .subscribe((quickLinks) => {
        this.stateService.setQuickLinks(quickLinks);
        this.quickLinksList = quickLinks;
        this.quickLinksList.map((link) => {
          link.Create = false;
          link.Cancel = false;
          return link;
        })
      }, (err) => {
        console.log(err);
      })
  }



  deleteCallback() {
    this.getAllQuickLinks();
  }

  savedCallback() {
    this.getAllQuickLinks();
  }
  

  createQuickLink() {
    this.isCreateBtnClicked = true;
    this.createLinkObj.Quick_Links_Name = null;
    this.createLinkObj.Quick_Links_URL = null;
    this.createLinkObj.Create = true;
    this.createLinkObj.Cancel = false;
  }

  cancelCallback() {
    this.isCreateBtnClicked = false;
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  } 

}
