
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CONSTANTS } from '../../app.constants';
@Injectable()
export class EulaService {

  // nonNullTot=0
  // countTot=0;
  // decodedEmailTot=0;
  // havingEmailTot=0;

  // totalCount = 0
  // noEmail = 0
  // totalArray=[]

  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient,
    private cookies: CookieService
  ) { }

  getEULAS(pageNum) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/eula/';
    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getEULA(eulaId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/eula/' + eulaId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getActiveEULA() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/eula/active/adminEula';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }
  getEULAByUserId(userId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/admin/' + userId + '/eula';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createEULA(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/eula/new';
    return this.http.post(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateEULA(model, eulaId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/eula/' + eulaId;
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateUserEULA(userId, model) {
    model.access_token = this.config.getAccessToken();
    let url = this.config.getBasePath() + '/users/user/' + userId + '/updateAdminEula';
    return this.http.put(url, model).pipe(
      map((response: any) => {
        if (response && response.token) {
          // create a cookies for newuser 
          this.cookies.put('pippinTitleAdm', JSON.stringify(response));
        }
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  // getNestIO(page) {
  //   let url = 'https://nestiolistings.com/api/v2/listings/residential/rentals/?key=ece9859ad3854c8abffe60ea717c947a&page=' + page;
  //   return this.http.get(url).pipe(
  //     map((response: Response) => {
  //       return response.json();
  //     }),
  //     catchError((error: any) => observableThrowError(error.json())),);
  // }

  //getBrokers(properties) {
    // var nonNull=0
    // var count=0;
    // var decodedEmail=0;
    // var havingEmail=0;
    // var total = 0;
    //var nomail = 0
    
    // properties.forEach((property) => {
    //   total++;
      // if(property.access_info){
      //   nonNull++;
      //   var nestBrokers = property.access_info.split('\n\n');
      //   var brokerCollection = [];
      //   //console.log("Collect Broker details from Access Info..");
      //   nestBrokers.every(function (brokerInfo, brokerIdx) {
      //     // if(brokerInfo.indexOf('@') > -1 ) havingEmail++;
      //     var brokerDetails = brokerInfo.split('\n');
      //     if (brokerDetails.length >= 2) {
      //        decodedEmail++;
      //       //console.log("Found Broker.");
      //       var broker = {};
      //       broker['Provider_Access_Info'] = brokerInfo;
      //       brokerDetails.every(function (brokerItem, itemIdx) {
      //         var brokerDet = brokerItem.split(': ');
      //         if (brokerDet.length > 1) {
      //           if (brokerDet[0] == 'P') broker['phone'] = brokerDet[1].replace(/[()]/g, '');
      //           if (brokerDet[0] == 'M') broker['mobile'] = brokerDet[1].replace(/[()]/g, '');
      //         }
      //         else if (brokerItem.split('@').length > 1) {
      //           broker['email'] = brokerItem;
      //           // decodedEmail++;
      //           return false;                
      //         }
      //         else broker['name'] = brokerItem;
      //       })
      //       brokerCollection.push(broker);
      //     } else if(brokerInfo.indexOf('@') > -1 ) havingEmail++;
      //     else count++;
      //   })
      // }  
    //   if (property.viewing_contacts) {
    //     property.viewing_contacts.forEach((contact) => {
    //       if (!contact.email && !this.getEmailsFromString(property.access_info)) {
    //         nomail++;
    //         // console.log("contact",contact)
    //         //  console.log("accessInfo: " + property.access_info + ", id: " + property.id + ", No.: " + property.unit_number)
    //          this.totalArray.push("Property Id: " + property.id + ". Address: " + property.unit_number + ", " + property.street_address + ", " + property.building.city + "-" + property.building.postal_code)
            
    //          // if(this.getEmailsFromString(property.access_info)){
    //         //   console.log("parsed email", this.getEmailsFromString(property.access_info),"Source-Id",property.id);
    //         //   nomail--;
    //         // }
    //       }
    //     })
    //   }
    // })

  //   // this.nonNullTot= this.nonNullTot + 50 - nonNull;
  //   // this.countTot= this.countTot + count;
  //   // this.decodedEmailTot=this.decodedEmailTot + decodedEmail;
  //   // this.havingEmailTot= this.havingEmailTot + havingEmail;
  //   this.noEmail = this.noEmail + nomail;
  //   this.totalCount = this.totalCount + total;
  //    return {
  // //     // Null:this.nonNullTot,
  // //     // satisfied:this.decodedEmailTot,
  // //     // noEmail:this.countTot,
  // //     // havingEmail:this.havingEmailTot
  //     noEmail: this.noEmail,
  //     total: this.totalCount,
  //     props: this.totalArray
  //    };
  //  }
  // getEmailsFromString(input) {    
  //   var regex =  /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm
  //   var result = input.match(regex);
  //   return result;
  // }
}

