// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.removeHover tr:hover {
  background-color: inherit !important;
}

.cursorDefault tr:hover {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/bulk-upload/upload-types/upload-types.component.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".removeHover tr:hover {\n    background-color: inherit !important;\n}\n\n.cursorDefault tr:hover{    \n    cursor: default;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
