// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rb-section-valid {
  border: 2px dashed #0F9D58;
  border-top: hidden;
}

.rb-section-error {
  border: 2px dashed #C8454F;
  border-top: hidden;
}

.rb-section-notapply {
  border: 2px dashed #C8454F;
  border-top: hidden;
}

.rb-section-title {
  color: #0F9D58;
}

.rb-section-added {
  border: none;
}
.rb-section-added .rb-section-title {
  background: #0F9D58;
  color: white;
}
.rb-section-added .rb-section-title .slide-section {
  color: white;
}
.rb-section-added .mat-mdc-select-value-text {
  color: #4FB2D9 !important;
}

.rb-sec-hdr {
  border-radius: 2px 2px 0 0;
  border: 2px dashed #0F9D58;
  border-bottom: hidden;
  background-color: #ececec;
  height: auto;
}

.rb-sec-hdr-notapply {
  background-color: #FBE5E6;
  border: 2px dashed #C8454F;
  border-bottom: hidden !important;
  height: auto;
}

.rb-sec-hdr-error {
  border-color: #EE4751;
  border-width: 2px;
  border-bottom: hidden !important;
  border-style: dashed;
  border-radius: 3px;
}

.rb-commitment-removed {
  color: #EE4751;
  opacity: 0.25;
  pointer-events: none;
  text-decoration: line-through;
}

.rb-commitment-added {
  color: #0F9D58;
}

.rb-commitment-modified {
  color: #4FB2D9;
}`, "",{"version":3,"sources":["webpack://./src/app/report-builder/rb-global-commitments/rb-glob-comm-section/rb-glob-comm-section.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,kBAAA;AACF;;AAEA;EACE,0BAAA;EACA,kBAAA;AACF;;AAEA;EACE,0BAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,YAAA;AACF;AACE;EACE,mBAAA;EACA,YAAA;AACJ;AACI;EACE,YAAA;AACN;AAGE;EACE,yBAAA;AADJ;;AAMA;EACE,0BAAA;EACA,0BAAA;EACA,qBAAA;EACA,yBAAA;EACA,YAAA;AAHF;;AAMA;EACE,yBAAA;EACA,0BAAA;EACA,gCAAA;EACA,YAAA;AAHF;;AAMA;EACE,qBAAA;EACA,iBAAA;EACA,gCAAA;EACA,oBAAA;EACA,kBAAA;AAHF;;AAMA;EACE,cAAA;EACA,aAAA;EACA,oBAAA;EACA,6BAAA;AAHF;;AAMA;EACE,cAAA;AAHF;;AAMA;EACE,cAAA;AAHF","sourcesContent":[".rb-section-valid {\n  border: 2px dashed #0F9D58;\n  border-top: hidden;\n}\n\n.rb-section-error {\n  border: 2px dashed #C8454F;\n  border-top: hidden;\n}\n\n.rb-section-notapply {\n  border: 2px dashed #C8454F;\n  border-top: hidden;\n}\n\n.rb-section-title {\n  color: #0F9D58;\n}\n\n.rb-section-added {\n  border: none;\n\n  .rb-section-title {\n    background: #0F9D58;\n    color: white;\n\n    .slide-section {\n      color: white;\n    }\n  }\n \n  .mat-mdc-select-value-text{\n    color :#4FB2D9 !important;\n  }\n  \n}\n\n.rb-sec-hdr {\n  border-radius: 2px 2px 0 0;\n  border: 2px dashed #0F9D58;\n  border-bottom: hidden;\n  background-color: #ececec;\n  height: auto;\n}\n\n.rb-sec-hdr-notapply {\n  background-color: #FBE5E6;\n  border: 2px dashed #C8454F;\n  border-bottom: hidden !important;\n  height: auto;\n}\n\n.rb-sec-hdr-error {\n  border-color: #EE4751;\n  border-width: 2px;\n  border-bottom: hidden !important;\n  border-style: dashed;\n  border-radius: 3px;\n}\n\n.rb-commitment-removed {\n  color: #EE4751;\n  opacity: 0.25;\n  pointer-events: none;\n  text-decoration: line-through;\n}\n\n.rb-commitment-added {\n  color: #0F9D58;\n}\n\n.rb-commitment-modified {\n  color: #4FB2D9;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
