import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  AfterViewInit,
} from "@angular/core";
import { ConfigService } from "app/services/config/config.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'app-rb-glob-comm-input',
  templateUrl: './rb-glob-comm-input.component.html',
  styleUrls: ['./rb-glob-comm-input.component.scss']
})
export class RbGlobCommInputComponent implements OnInit, AfterViewInit {
  @ViewChild("invisibleText") invTextER: ElementRef;
  @ViewChild("inputText") inputText: ElementRef;

  @Input() value: any;
  @Input() prefix: any;
  @Output() fieldChange: EventEmitter<string> = new EventEmitter();
  width: number = 100;
  fieldValue = new Subject<string>();
  dateArray = ["_RECORDEDDATE_", "_MATURITYDATE_", "_DATE_"];
  numberArray = ["_AMOUNT_", "_INSTALLMENTAMOUNT_"];
  dataType = 1;
  getUSDateString: any;
  minDate: any;

  constructor(private cdr: ChangeDetectorRef, private config: ConfigService) {}

  ngAfterViewInit(): void {
    this.resizeInput(null);
  }

  ngOnInit() {
    this.getUSDateString = new Date("01/02/1500").toLocaleString("en-US", {
      timeZone: "America/New_York",
    });
    this.minDate = new Date(this.getUSDateString);
    if (this.dateArray.includes(this.prefix)){ 
      this.dataType = 2;
      if(this.value != "") this.value = new Date(this.value);
    }
    if (this.numberArray.includes(this.prefix)) this.dataType = 3;
    this.fieldValue
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe((value) => this.fieldChangeHandler(value));
  }

  resizeInput(event) {
    this.cdr.detectChanges();
    if (this.dataType == 1) {
      setTimeout(() => {
        const minWidth = 100;
        if (this.invTextER.nativeElement.offsetWidth + 50 > minWidth) {
          this.width = this.invTextER.nativeElement.offsetWidth + 55;
          this.cdr.detectChanges();
          this.inputText.nativeElement.style.height = "auto";
          this.inputText.nativeElement.style.height = `${this.inputText.nativeElement.scrollHeight}px`;
        } else {
          this.width = minWidth;
        }
      }, 0);
    }
  }

  fieldChangeHandler(val) {
    if(typeof val == 'string') val = val.trim();
    this.fieldChange.emit(val);
    this.resizeInput(null);
  }

  isFieldEmpty() {
    return this.dataType == 2
      ? !this.dateIsValid(this.value)
      : this.dataType == 3
      ? !this.value
      : this.value.trim().length === 0;
  }

  dateIsValid(inpDate: any) {
    return inpDate instanceof Date && !isNaN(inpDate.valueOf());
  }

  restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[///\d\s]/.test(input);
  }
}
