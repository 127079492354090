import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ConfigService, OrganizationService } from "../../services";
import { ConfirmComponent } from "../../dialog/confirm/confirm.component";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";

@Component({
  selector: "app-edit-commitments",
  templateUrl: "./edit-commitments.component.html",
  styleUrl: "./edit-commitments.component.scss",
})
export class EditCommitmentsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  commtmntItemTypes: any = [
    { key: 1, value: "Requirement" },
    { key: 2, value: "Exception" },
  ];
  commtmntItem: any = {
    Commitment_Type: 1,
    Cust_Commit_Type_Id: "",
    Id: "New",
    Language: "",
    Pippin_Company_Id: "",
    Type_Name: "",
    Status: 1,
  };
  title: any = "Commitment Item";
  isNewItem: any = true;

  constructor(
    public dialogRef: MatDialogRef<EditCommitmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog,
    private config: ConfigService,
    private frmBuilder: UntypedFormBuilder,
    private orgService: OrganizationService
  ) {
    this.dataForm = this.frmBuilder.group({
      Commitment_Type: [1, Validators.required],
      Cust_Commit_Type_Id: [null, Validators.required],
      Language: [null, Validators.required],
      Type_Name: [null, Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.initDialog();
  }

  initDialog() {
    this.isNewItem = this.data.newItem;
    if (this.data.newItem) {
      this.commtmntItem.Pippin_Company_Id = this.data.orgId;
      this.title = "Add Commitment Item";
    } else {
      this.commtmntItem = { ...this.data.commtmntItem };
      this.commtmntItem.Status = 1;
    }
  }

  isFormInvalid() {
    return this.dataForm.status !== "VALID";
  }

  confirm() {
    this.orgService.saveCommitmentItem(this.commtmntItem).subscribe();
    this.Close(true);
  }

  Close(stat) {
    this.dialogRef.close({ state: stat });
  }

  openDeletePopup() {
    this.openConfirm(
      "Delete Commitment Item",
      "Would you like to delete this Commitment Item ?"
    )
      .afterClosed()
      .subscribe((res) => {
        if (res == true) {
          this.commtmntItem.Status = 0;
          this.orgService.saveCommitmentItem(this.commtmntItem).subscribe();
          this.Close(true);
        }
      });
  }

  openConfirm(title, msg) {
    return this.matDialog.open(ConfirmComponent, {
      data: {
        title: title,
        message: msg,
      },
      ...this.config.getDialogOptions(),
    });
  }
}
