import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AboutService ,AuthService, ConfigService} from '../../services';
import { Router ,ActivatedRoute} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule,FormsModule,FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsComponent implements OnInit {
  //innerText:String = ''
  eula:any={}
  isAccept:boolean=false;
  errorMsg:any={};
  timer: any = [];
  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private aboutService: AboutService,
    private auth:AuthService,
    private config:ConfigService
  ) { }

  ngOnInit() {
    // this.pages.getWithToken('terms').subscribe((res)=>{
    //   this.innerText = res.Page_Text;
    // });
    if(this.route.snapshot.params['userId']){
      this.isAccept=true;         
      this.aboutService.getActiveEULA()
      .subscribe((data)=>{
        if(data){
          this.eula=data;
        }
      })
    }else{
      this.aboutService.getEULAByUserId(this.auth.getUserId())
      .subscribe((data)=>{
        if(data){
          this.eula=data;
        }
      })
    }
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  updateUserEULA(){
    var data={
      EULA_Version:this.eula.EULA_Version
    }
    this.aboutService.updateUserEULA(this.route.snapshot.params['userId'],data)
    .subscribe((data)=>{
      console.log(data);
      this.router.navigate(['orders/current']);
    },(err)=>{
      console.log(err);
      this.errorMsg=err;
    })
  }

}
