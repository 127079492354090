import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { RptBuilderService } from "../rpt-builder.service";

@Component({
  selector: "app-rb-comments",
  templateUrl: "./rb-comments.component.html",
  styleUrls: ["./rb-comments.component.scss"],
})
export class RbCommentsComponent implements OnInit, OnDestroy {
  // @Input() sectionComments: any;
  // @Output() formChanged: EventEmitter<any> = new EventEmitter();
  @Input() sectionTypeName: number;
  sectionComment: any = null;
  deletedComment: any = null;
  dbComment: any = null;
  private ngUnsubscribe = new Subject();
  commentsDataForm: UntypedFormGroup;
  reportDetails = {
    Derived_From: null,
  };
  Cmtmnt_Mappings = {
    1: "SRC_PT",
    2: "ASS_NS",
    3: "TAX_ES",
    4: "VES_DD",
    5: "CHN_TL",
    6: "SCR_IN",
    7: "LEN_JG",
    8: "ERA",
  };

  constructor(
    private frmBuilder: UntypedFormBuilder,
    private builder: RptBuilderService
  ) {
    this.commentsDataForm = this.frmBuilder.group({
      sectionComments: [null, [Validators.maxLength(65535)]],
    });
    this.commentsDataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe((res) => {
        // this.formChanged.emit(this.commentsDataForm);
        this.saveComment(this.commentsDataForm);
      });
  }

  ngOnInit() {
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData && data.dbData.Id !== undefined)
          this.reportDetails = data.dbData;
      });
    this.builder.generalComments$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) {
          this.initComments(data);
          setTimeout(() => {
            if (this.sectionComment && this.sectionComment["Status"] == 0)
              this.commentsDataForm.controls["sectionComments"].disable();
            else this.commentsDataForm.controls["sectionComments"].enable();
          }, 0);
        }
      });
    this.builder.deletedItems$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initDeletedComments(data));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  initComments(data) {
    if (data && data.curData) {
      let comment = data.curData.find(
        (gc) => gc.Comments_Type_ID == this.sectionTypeName
      );
      this.sectionComment = comment !== undefined ? comment : null;
      this.dbComment = { ...this.sectionComment };
    }
  }

  isCommentsModified() {
    if (this.sectionComment.Derived_From && this.sectionComment.Status)
      return this.builder.isFieldModified(
        this.reportDetails["Id"],
        this.Cmtmnt_Mappings[this.sectionTypeName],
        this.sectionComment.Id,
        ''
      );
    else return false;
  }

  saveComment(dataForm) {
    if (!dataForm.pristine) {
      this.builder
        .updateGeneralComment(
          this.sectionComment,
          this.dbComment,
          this.reportDetails["Id"],
          false
        )
        .subscribe();
      this.commentsDataForm.markAsPristine();
    }
  }

  initDeletedComments(data) {
    if (data) {
      if (data.hasOwnProperty("SP_General_Comments")) {
        let deletedComments = data["SP_General_Comments"].find(
          (comment) => comment.Comments_Type_ID == this.sectionTypeName
        );
        if (deletedComments != undefined) this.deletedComment = deletedComments;
      }
    }
  }
}
