// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
::ng-deep.mat-slide-toggle-content {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/product/edit-product/edit-product.component.scss"],"names":[],"mappings":"AACA,mIAAA;AACA;EACI,WAAA;AAAJ","sourcesContent":["\n/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */\n::ng-deep.mat-slide-toggle-content{\n    width: 100%;   \n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
