import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CONSTANTS } from '../../app.constants';
import { AuthService } from 'app/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-eta-alert',
  templateUrl: './eta-alert.component.html',
  styleUrls: ['./eta-alert.component.scss']
})
export class EtaAlertComponent  {
  @ViewChild('okBtn') focusedElement: ElementRef;
  message: any;
  title: any = ''
  value: any = '';
  timer: any = [];
  cancelValue: any;
  updateValue = '';
  constants = CONSTANTS;

    constructor(
    private auth: AuthService,   public dialogRef: MatDialogRef<EtaAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) 
   {
    
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => { this.focusedElement.nativeElement.focus() }, 0))
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
    this.value=this.data.value;
    this.cancelValue=this.data.cancelValue;
    this.updateValue=this.data.updateValue;
  }

  isSuperAdmin(){
    let role = Number(this.auth.getUserRole());
    if(role == CONSTANTS.userRole.superAdmin || role == CONSTANTS.userRole.compAdmin) return true;
    else return false;
  }


  proceed() {
     this.dialogRef.close(true);
    this.clearTime();
  }

  updateAnyway() {
     this.dialogRef.close('updateAnyway');
    this.clearTime();
  }

  Close() {
     this.dialogRef.close(false);
    this.clearTime();
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
}
