// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  overflow-y: auto;
  max-height: 800px;
}

.modal-dialog {
  margin-top: 2rem;
  max-width: 1500px;
}

.hlp-txt {
  font-size: 15px;
  color: #949494;
  text-align: right;
  margin-top: -16px;
}

.del-handle {
  color: red;
  text-decoration: underline;
  padding-top: 4px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/organizations/edit-commitments/edit-commitments.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,UAAA;EACA,0BAAA;EACA,gBAAA;EACA,eAAA;AACF","sourcesContent":[".modal-body {\n  overflow-y: auto;\n  max-height: 800px;\n}\n\n.modal-dialog {\n  margin-top: 2rem;\n  max-width: 1500px;\n}\n\n.hlp-txt {\n  font-size: 15px;\n  color: #949494;\n  text-align: right;\n  margin-top: -16px;\n}\n\n.del-handle {\n  color: red;\n  text-decoration: underline;\n  padding-top: 4px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
