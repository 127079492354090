// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.align {
  min-height: 30px !important;
  max-height: 75px !important;
  height: 30px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/error-report/error-report.component.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,2BAAA;EACA,uBAAA;AACJ","sourcesContent":[".align {\n    min-height: 30px !important;\n    max-height: 75px !important;\n    height: 30px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
