import { Injectable } from '@angular/core';

@Injectable()
export class ProgressBarService {

  primaryColor:String;
  progressState:Number;
  stage:String;
  height:String;
  totalStages:Number;
  barType:Number;
  timeline:any = [];

  constructor() { }

  resetAll(){
    this.primaryColor='';
    this.progressState=null;
    this.stage=null;
    this.height=null;
    this.totalStages=null;
    this.barType=null;
  }

  setPrimaryColor(color){
    this.primaryColor=color;
  }

  getPrimaryColor(){
    return this.primaryColor;
  }

  setTimeline(timeline){
    this.timeline = timeline;
  }

  getTimeline(){
    return this.timeline;
  }

  setProgressState(state){
    this.progressState=state;
  }

  getProgressState(){
    return this.progressState;
  }

  setStage(stage){
    this.stage=stage
  }

  getStage(){
    if(this.stage) return this.stage;
  }

  setHeight(height){
    this.height=height;
  }

  getHeight(){
    return this.height;
  }

  setTotalStages(stages){
    this.totalStages=stages;
  }

  getTotalStages(){
    return this.totalStages;
  }

  setBarType(type){
    this.barType=type
  }

  getBarType(){
    return this.barType
  }
}
