import { Component, OnInit } from '@angular/core';
import { ProgressBarService ,NewOrderService, PricingService} from '../../../../services'
import { Router } from '@angular/router';
import { DialogService } from 'ng2-bootstrap-modal';
import { SuccessComponent } from '../../../../dialog/success/success.component';
import { forkJoin as observableForkJoin, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-quote-request3',
  templateUrl: './quote-request3.component.html',
  styleUrls: ['./quote-request3.component.scss']
})
export class QuoteRequest3Component implements OnInit {
  primaryColor = '#0F9D58';
	quoteDetails: any = {};
	completeAddress: string = '';
	selectedState = '';
	selectedCounty = '';
	states: any;
	counties: any;
	error: any;
  constructor(
		private progressServ: ProgressBarService,
		private newOrder: NewOrderService,
		private router: Router,
		private pricingService: PricingService,
		private matDialog:MatDialog,

  ) { }

  ngOnInit() {
		this.progressServ.setPrimaryColor(this.primaryColor);
		this.progressServ.setHeight(2);
		this.progressServ.setStage(2);
		this.progressServ.setTotalStages(3);
		this.progressServ.setProgressState(1 * 100);
		this.progressServ.setBarType(1);
    if (!this.newOrder.getquoteData1())
    this.router.navigate(['/quote/request/step1']);
  else {
    this.quoteDetails = Object.assign({}, this.newOrder.getquoteData1());
    this.selectedState = this.quoteDetails.Property_State_Abbr
      ? this.quoteDetails.Property_State_Abbr
      : '';
    this.selectedCounty = this.quoteDetails.Property_County
      ? this.quoteDetails.Property_County.replace(' County', '')
      : '';
    this.buildAddress(this.quoteDetails);
    if (this.quoteDetails.Property_State_Abbr) {
      this.selectedState = this.quoteDetails.Property_State_Abbr;
      this.pricingService.getCountyList(this.selectedState).subscribe(counties => {
        if (counties) this.counties = counties;
      });
    }
    if (!this.quoteDetails.Property_State_Abbr) {
      this.pricingService.getStateList().subscribe(states => {
        if (states) this.states = states;
      });
    }
  }
  }

  buildAddress(model) {
		this.completeAddress = '';
		if (model.Property_Address_1) this.completeAddress += model.Property_Address_1;
		if (model.Property_Address_2) {
			if (this.completeAddress) this.completeAddress += '\n';
			this.completeAddress += model.Property_Address_2;
		}
		if (model.Property_City) {
			if (this.completeAddress) this.completeAddress += '\n';
			this.completeAddress += model.Property_City;
		}
		if (model.Property_State_Abbr) {
			if (this.completeAddress) this.completeAddress += ', ';
			this.completeAddress += model.Property_State_Abbr;
		}
		if (model.Property_ZipCode) {
			if (this.completeAddress) this.completeAddress += ' ';
			this.completeAddress += model.Property_ZipCode;
		}
  }
  
  validateFields() {
		if (!this.selectedState || !this.selectedCounty) return false;
		else return true;
  }
  
  goToQuoteStep2() {
		this.router.navigate(['/quote/request/step2']);
  }
  
  createRequestQuote() {
		this.quoteDetails.selectedCriteria = this.newOrder.getSelectedCriteriaQReq();
		if (this.newOrder.getselectedOption() == 2 && this.newOrder.getSelectedCriteriaQReq() == 1)
			this.quoteDetails.manualEntryFlag = true;
		else this.quoteDetails.manualEntryFlag = false;
		this.quoteDetails.Property_State_Abbr = this.selectedState;
    this.quoteDetails.Property_County = this.selectedCounty;
    this.quoteDetails.selectedOrderCriteria = this.quoteDetails.selectedCriteria
		this.newOrder.setquoteData1(this.quoteDetails);
		this.createNewRequestQuote();
  }
  
  createNewRequestQuote() {
		this.newOrder.createQuote().subscribe(
			data => {
				if (this.quoteDetails.orderDocId.length > 0) this.updateOrderDocs(data);
        this.newOrder.resetAllQReq();
        this.router.navigate(['/quotes/' + data.Order_ID + '/view']);
			},
			err => {
				if (err.code == 'PTOR02') this.openSuccessDialog('Success', err.msg);
				if (err.msg) this.error = err.msg;
			},
		);
  }
  
  openSuccessDialog(title, msg) {
		let disposable = this.matDialog
			.open(SuccessComponent, {data:{
				title: title,
				message: msg,
			}})
  }
  
  updateOrderDocs(data) {
		var docUpdate = [];
		this.quoteDetails.orderDocId.forEach(docId => {
			docUpdate.push(
				this.newOrder.updateOrderDocument(
					data.Order_ID,
					data.Property_ID,
					docId.Order_Document_ID,
				),
			);
		});
		observableForkJoin(docUpdate).subscribe(
			result => {},
			err => {
				console.log('Error in Upload: ', err);
			},
		);
	}

}
