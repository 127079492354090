import { Component, OnInit } from '@angular/core';
import { ConfigService, UsersService, OrdersService, StateService, PricingService, AwsS3Service, NewOrderService, UsermessageService, OrganizationService } from '../../../services';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSTANTS } from '../../../app.constants';
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../../dialog/error-dialog/error-dialog.component';
import { UUID } from 'angular2-uuid';
import { onErrorResumeNext as observableOnErrorResumeNext, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-edit-pdf-data',
  templateUrl: './edit-pdf-data.component.html',
  styleUrls: ['./edit-pdf-data.component.scss']
})
export class EditPdfDataComponent implements OnInit {
  newOrderForm: UntypedFormGroup;
  pdfData = {};
  newOrderModel: any = {};
  error: String = '';
  errorMsg: any = '';
  loanInput = "";
  states = [];
  selectedStates = [];
  selectedCounty =[];
  counties = [];
  constants = CONSTANTS;
  documents = [];
  isloading:boolean = false;
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private stateService: StateService,
    private router: Router,
    private config: ConfigService,
    private pricingService: PricingService,
    private orderService: OrdersService,
    private s3Service: AwsS3Service,
    private matDialog:MatDialog,
    private newOrder: NewOrderService,
    private userMsg:UsermessageService,
    private organizationService: OrganizationService

  ) {
    this.newOrderForm = frmBuilder.group({
      File_ID: [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      Prod_Description: [null, Validators.compose([Validators.required, Validators.maxLength(125)])],
      BorrowerName: [null, Validators.compose([Validators.maxLength(127)])],
      Order_Estimated_Time: [null, Validators.compose([Validators.maxLength(127)])],
      Short_Address: [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      Short_Address2: [null, Validators.compose([Validators.maxLength(127)])],
      City: [null, Validators.compose([Validators.maxLength(127)])],
      State_Address: [null, Validators.compose([Validators.required, Validators.maxLength(125)])],
      County: [null, Validators.compose([Validators.required, Validators.maxLength(125)])],
      ZipCode: [null, Validators.compose([Validators.required, Validators.maxLength(11)])],
      Loan: [null, Validators.compose([Validators.maxLength(127)])],
      Special_Instructions: [null],
    })
    this.newOrderForm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    if (this.stateService.getPDFData()) {
      this.newOrderModel = this.stateService.getPDFData();
    }
    this.pricingService.getStateList().subscribe((states) => { 
      if (states)

      var tempStates = [];
       states.forEach((state) => tempStates.push({ State_Short_Name : state[0].toUpperCase() }));
       this.states = tempStates;
       this.selectedStates = tempStates;
          // this.states = states
       });
    this.fetchUploadProgress();
  }

  goBack() {
    this.router.navigate(['upload-types']);
  }

  getLoanFormat(e, editOrder) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((this.loanInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.loanInput.length > 0 || this.loanInput.length < this.config.getLimitPriceMaxLength())) {
        this.loanInput = this.loanInput.slice(0, this.loanInput.length - 1);
        e.target.value = this.numberFormat(this.loanInput);
        if (e.target.value == '') editOrder.Order_Loan = null;
        else editOrder.Order_Loan = e.target.value;
      }
      else if (this.loanInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.loanInput = this.loanInput + key;
          e.target.value = this.numberFormat(this.loanInput);
          editOrder.Order_Loan = e.target.value;
        }
      }
      return false;
    }
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  getCountyList(event) {
    if (event) {
      this.pricingService.getCountyList(this.newOrderModel.State_Address).subscribe((counties) => {
        var tempCounties = [];
        counties.forEach((county) => tempCounties.push({ County_Name: county.County_Name.toUpperCase() }));
        this.counties = tempCounties;
        this.selectedCounty = tempCounties;
      });
    }
  }

  detectManualChange() {
    this.newOrderModel.County = null;
    this.newOrderModel.ZipCode = null;
  }

  detectManualChangeZipcode(county) {
    if(!county) this.newOrderModel.ZipCode = null;
  }

  detectZipcode(){
  this.newOrderModel.ZipCode = null;
}

  uploadToS3AndUpdatePath(file, propertyId) {
    return this.s3Service.onManagedUpload(file, '0')
      .then((data) => {
        return this.s3Service.updateDocumentPathForPDF('0',
          propertyId,
          file,
          data.Key,
          this.extractFilename(data.Key),
          CONSTANTS.orderDocumentType.internalDocs
        )
          .then((doc) => {
            doc['docId'] = propertyId;
            doc['completed'] = 100;
            return doc;
          })
          .catch((err) => { throw err; })
      })
      .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload); })
  }

  extractFilename(filePath) {
    var parts = filePath.split('/');
    var filename = parts[parts.length - 1];
    return filename;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  checkProdDescriptionAvlOrNot() {
   return this.newOrderModel.products.some((productDet) => {
     let dbProductName = productDet.Product_Description.toLowerCase();
     let pdfProductName = this.newOrderModel.Prod_Description.toLowerCase();
    if(dbProductName === pdfProductName) return true;
    else return false;
   })
  }

  createOrdFromPDF() {
    if(this.checkProdDescriptionAvlOrNot()){
      this.isloading = true;
      var fileUploads = [];
      var uid = UUID.UUID();
      this.documents.push({docId:uid, name: this.newOrderModel.selectedFile.name, completed:0});
      fileUploads.push(this.uploadToS3AndUpdatePath(this.newOrderModel.selectedFile, uid))
      Promise.all(fileUploads)
        .then((files) => {
          this.newOrderModel.orderDocs = files;
          if (this.newOrderModel.customerID) {
            this.newOrderModel.Customer_ID = this.newOrderModel.customerID;
          }          
          this.newOrder.checkIfQualaiUser(this.newOrderModel.Customer_ID)
          .subscribe((data) => {
            this.newOrderModel.Qualia_Fee_Add = data.status ? 1: 0;
            if(this.newOrderModel.Organization_ID) {
              this.organizationService
              .getOrganizationDetails(this.newOrderModel.Organization_ID)
              .subscribe((orgDetails) => {
                  this.orderService.createOrdFromPDF(this.newOrderModel)
                .subscribe((ordRes) => {
                  this.updateOrderDocs(ordRes);
                  this.userMsg.setSuccessMsg("Order created successfully");
                  this.isloading = false;
                  this.stateService.setUploadTypesData(null);
                  this.router.navigate(['/orders/new']);
                }, (err) => {
                  this.cleanUpS3Files(this.newOrderModel.orderDocs);
                  this.isloading = false;
                  this.openErrorPopup("Error while creating an order");
                });
              })
            }              
            })   
        })
        .catch((err) => { 
          this.isloading = false;
          this.openErrorPopup("Error while up loading file to S3 "); 
        })
    }else this.openErrorPopup('Selected "'+ this.newOrderModel.Prod_Description +'" is not available. Please select correct product description.'); 

  }

  updateOrderDocs(data) {
    var docUpdate = [];
    this.newOrderModel.orderDocs.forEach(docId => {
      docUpdate.push(this.newOrder.updateOrderDocument(data.Order_ID, data.Property_ID, docId.Order_Document_ID));
    })
    observableOnErrorResumeNext(docUpdate)
      .subscribe(result => {
      },
        (err) => {
          this.cleanUpS3Files(this.newOrderModel.orderDocs);
        })
  }

  cleanUpS3Files(files) {
    var collection = [];
    files.forEach((file) => collection.push(this.s3Service.deleteFromS3(file.Document_Path)))
    Promise.all(collection)
      .then(() => { })
      .catch((err) => { this.openErrorPopup("Error while while deleting S3 files") })
  }

  fetchUploadProgress() {
    this.s3Service.progress
      .subscribe((res) => {
        var matchedIndex = this.documents.findIndex((doc) => doc.name == res.name);
        if(this.documents[matchedIndex]){
          this.documents[matchedIndex].completed = Math.round(((res.progress.loaded / res.progress.total) * 100) * 100) / 100;
          this.documents[matchedIndex].completed = Math.round(this.documents[matchedIndex].completed);
        }
      });
  }

}
