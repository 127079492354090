
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AuthService, PagerService, ConfigService, EulaService,StateService, ScrollService } from '../../services';
import { CONSTANTS } from '../../app.constants';
import { Router } from '@angular/router';
import { PreloaderService } from '../../services/preloader/preloader.service';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { DialogService } from "ng2-bootstrap-modal";
import * as momentTimeZone from 'moment-timezone';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss'],

})
export class EulaComponent implements OnInit {
  constants = CONSTANTS
  eulas: any = [];
  eulasToDisplay: any;
  totalCount: number;
  pager: any = {};
  pageNum: number;
  scrollEnable: boolean = true;
  srollDistance=this.config.setScrollDistance();
  srollThrottle=this.config.setScrollThrottle();
  timer: any = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private pagerService: PagerService,
    private config: ConfigService,
    private eulaService: EulaService,
    private preloaderService: PreloaderService,
    private matDialog:MatDialog,
    private stateService: StateService,
    private scrollService: ScrollService
  ) {
    this.pager.pages = [];
    if(this.pageNum == undefined)
    this.pageNum = 1;
  }

  ngOnInit() {
    if(this.stateService.getEULA())
    this.initializeComponentData(this.stateService.getEULA())
    else this.getInitialEULAS();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(5), 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  // setPage(page: number) {
  //   console.log("Inside set page", page)
  //   if (page < 1 || page > this.pager.totalPages) {
  //     return;
  //   }
  //   this.pageNum = page;
  //   this.setDisplayEULAS();
  //   this.getEULAS();
  //   this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
  //   console.log("Pager", this.pager)
  // }


  setDisplayEULAS() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.eulasToDisplay = this.eulas.slice(start, this.totalCount);
    if (this.eulasToDisplay.length > this.config.getNumRecordsPerPage()) this.eulasToDisplay = this.eulasToDisplay.slice(0, this.config.getNumRecordsPerPage());
  }

  getEULAS() {
    this.pageNum = 1;
    this.eulaService.getEULAS(this.pageNum)
      .subscribe((result) => {
        if (result) {
          this.eulas = result.rows;
          this.settingType(this.eulas)
          this.totalCount = result.count;
          console.log("Total clients: ", this.totalCount)
          this.setDisplayEULAS();
          this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        // this.auth.logout();
        // this.router.navigate(['/login']);
      })
  }

  getInitialEULAS() {
    if(this.pageNum == 1){
      this.stateService.setEULA(this);
      this.eulaService.getEULAS(this.pageNum)
      .subscribe((result1) => {
        if (result1.count > this.config.getNumRecordsPerPage()) {
          this.pageNum++;
          this.eulaService.getEULAS(this.pageNum)
            .subscribe((result2) => {
              var result = {
                rows: result1.rows.concat(result2.rows),
                count: result1.count
              }
              this.getEULAData(result)
            })
        }
        else {
          this.getEULAData(result1)
        }
      } , (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        // this.auth.logout();
        // this.router.navigate(['/login']);
      })
    }else{
      this.getRetainData();
    }
  }

  getRetainData(){
    var ObsCollection = [];
    for(var i=1;i<=this.pageNum;i++){
      ObsCollection.push(this.eulaService.getEULAS(i));
    }

    observableForkJoin(ObsCollection)
    .subscribe((retainEULA) => {
      if(retainEULA){
        var totalOrders = [];
        for (var i = 0; i < retainEULA.length; i++) {
          this.stateService.setEULA(this)
          totalOrders = totalOrders.concat(retainEULA[i]['rows']);
        }
        this.eulas = totalOrders;
        this.totalCount = retainEULA[0]['count'];
        this.getEulaRecordsData(this.totalCount);
        this.getScrollPosData()
      }
    })
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.getInitialEULAS();
  }

  goToEULADetails(eulaId) {
    var getPos = document.documentElement.scrollTop
    this.scrollService.setScrollPosEULA(getPos);
    this.router.navigate(['/eula/' + eulaId])
  }

  settingType(eulas) {
    if (eulas) {
      for (var eula of eulas) {
        if ((new Date(eula.EULA_Effective_Date) < new Date()) && (!eula.EULA_Expiry_Date || new Date() < new Date(eula.EULA_Expiry_Date))) {
          eula.status = "current";
        }
        else {
          eula.status = "old";
        }
      }
    }
    return eulas;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }
  handleEvent(event){
    if(event ==false){
    if(this.totalCount > this.config.getNumRecordsPerPage()){
      if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
      else this.scrollEnable = true;
    }
  }else
    this.scrollEnable = true;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.eulaService.getEULAS(this.pageNum)
        .subscribe((result) => {
          if (result) {
            this.settingType(this.eulas)
            this.totalCount = result.count;
            this.config.resetShowFlag()
            if (this.totalCount > this.eulas.length) {
              this.eulas = this.eulas.concat(result.rows);
              this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }
  getEulaRecordsData(result){
      this.config.resetShowFlag();
      this.config.setClearTimeout();
      this.setDisplayEULAS();
      this.pager = this.pagerService.getPager(result, this.pageNum, this.config.getNumRecordsPerPage());
      this.config.setDelayRecords();
  }

  getScrollPosData(){
    if (this.scrollService.getScrollPosEULA()) {
      var x = this.scrollService.getScrollPosEULA()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }));
    }
  }

  getEULAData(result){
    this.eulas = result.rows;
    // this.loadEulaDate(this.eulas)
    this.totalCount = result.count;
    this.getEulaRecordsData(this.totalCount)
    this.getScrollPosData()
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  loadEulaDate(eulas){
    eulas.forEach((eula)=>{
      if(eula.EULA_Effective_Date){
        var estShift = momentTimeZone.tz(eula.EULA_Effective_Date, 'America/New_York').isDST() ? 4 : 5;
        var etaInEstTimezone = moment(eula.EULA_Effective_Date).utcOffset(moment(eula.EULA_Effective_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
        eula.EULA_Effective_Date = etaInEstTimezone;
      }
      if(eula.EULA_Expiry_Date){
        var estShift = momentTimeZone.tz(eula.EULA_Expiry_Date, 'America/New_York').isDST() ? 4 : 5;
        var etaInEstTimezone = moment(eula.EULA_Expiry_Date).utcOffset(moment(eula.EULA_Expiry_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
        eula.EULA_Expiry_Date = etaInEstTimezone;
      }
    })
  }
}
