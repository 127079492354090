// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBoxStyle.mat-mdc-menu-item {
  background-color: #fdf8fb !important;
  border: 5px solid #60cc70;
  border-radius: 1px !important;
  color: black !important;
}

.searchBoxStyle.mat-mdc-menu-item:hover {
  color: black !important;
}

#Search::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
  -webkit-appearance: auto;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  color: black !important;
  cursor: pointer !important;
}

.pos-icon {
  top: 16px;
  left: 12px;
}

.matMenuItem {
  background-color: #60cc70 !important;
  color: white !important;
  cursor: pointer !important;
  height: 48px !important;
}

.matMenuItem:hover {
  color: #60cc70 !important;
  background-color: white !important;
}

.mat-mdc-card {
  border-radius: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/common/mat-menu-search/mat-menu-search.component.scss"],"names":[],"mappings":"AAGA;EACE,oCAAA;EACA,yBAAA;EACA,6BAAA;EACA,uBAAA;AAFF;;AAIA;EACE,uBAAA;AADF;;AAGA;EACE,kBAAA;EACA,WAAA;EAEA,wBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;EACA,0BAAA;AADF;;AAIA;EACE,SAAA;EACA,UAAA;AADF;;AAQA;EACE,oCAAA;EACA,uBAAA;EACA,0BAAA;EACA,uBAAA;AALF;;AAQA;EACE,yBAAA;EACA,kCAAA;AALF;;AAOA;EACE,6BAAA;AAJF","sourcesContent":["@import \"../../../scss/variables\";\n@import \"../../../scss/mixins\";\n\n.searchBoxStyle.mat-mdc-menu-item {\n  background-color: #fdf8fb !important;\n  border: 5px solid #60cc70;\n  border-radius: 1px !important;\n  color: black !important;\n}\n.searchBoxStyle.mat-mdc-menu-item:hover {\n  color: black !important;\n}\n#Search::-webkit-search-cancel-button {\n  position: relative;\n  right: 20px;\n\n  -webkit-appearance: auto;\n  height: 20px;\n  width: 20px;\n  border-radius: 10px;\n  color: black !important;\n  cursor: pointer !important;\n}\n\n.pos-icon {\n  top: 16px;\n  left: 12px;\n}\n\n// button.mat-menu-item:focus {\n//   outline: none !important;\n// }\n\n.matMenuItem {\n  background-color: #60cc70 !important;\n  color: white !important;\n  cursor: pointer !important;\n  height: 48px !important;\n}\n\n.matMenuItem:hover {\n  color: #60cc70 !important;\n  background-color: white !important;\n}\n.mat-mdc-card {\n  border-radius: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
