
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { PricingService, PagerService, ConfigService, StateService, AuthService, ScrollService, OrdersService } from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '../services/preloader/preloader.service'
import { CONSTANTS } from '../app.constants';
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../dialog/error-dialog/error-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pricing-view',
  templateUrl: './pricing-view.component.html',
  styleUrls: ['./pricing-view.component.scss']
})
export class PricingViewComponent implements OnInit {
  constants = CONSTANTS;
  pricings = [];
  pricingsToDisplay: any;
  totalCount: number;
  pager: any = {};
  sortField: string;
  orderBy: string;
  states = [];
  selectedState = '';
  selectedCounty = '';
  selectedProduct = 0;
  filterBy: any = [];
  counties = [];
  products = [];
  searchString: string;
  searchInProgress: boolean = false;
  pageNum: number;
  scrollEnable: boolean = true;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  searchQuery = new Subject<string>();
  timer: any = [];
  constructor(
    private pricingService: PricingService,
    private pagerService: PagerService,
    private config: ConfigService,
    private preloaderService: PreloaderService,
    private router: Router,
    private stateService: StateService,
    public auth: AuthService,
    private matDialog:MatDialog,
    private scrollService: ScrollService,
    private orderService: OrdersService
  ) {
    this.pager.pages = [];
    if (this.pageNum == undefined)
    this.pageNum = 1;
    this.searchString = '';
    this.orderBy = 'ASC';
    this.sortField = 'State_Short_Name';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }



  ngOnInit() {
    // this.getPricings();
    // this.getAvailableStateLocal();
    this.pricingService.getAvailableStateList().subscribe((states) => { this.states = states });
    // this.getAvailableproductLocal();
    this.orderService.getAllOrderProducts().subscribe((products : any[]) => { this.products = products });
    if (this.stateService.getProductPricing()) {
      this.initializeComponentData(this.stateService.getProductPricing());
    }
    else this.getInitialPricings();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(7), 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

//   getAvailableproductLocal(){
//     let AllProd = JSON.parse(sessionStorage.getItem('AllProductAvailable'));
//     if(AllProd) this.setProd(AllProd)         
//     else this.getAvailableprodList();
// }
  
// getAvailableprodList(){
//   this.pricingService.getProductList().subscribe((Prod: any[]) => { 
//       if(Prod){
//         this.setProd(Prod)         
//         sessionStorage.setItem('AllProductAvailable',JSON.stringify(Prod)); 
//        setTimeout(function(){sessionStorage.removeItem("AllProductAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });

//   }

//   setProd(AllProdData){
//       if(AllProdData) this.products = AllProdData;
//   }

//   getAvailableStateLocal(){
//     let Allstates = JSON.parse(sessionStorage.getItem('AllStateAvailable'));
//     if(Allstates) this.setState(Allstates)         
//     else this.getAvailableStateList();
// }
  
//   getAvailableStateList(){
//     this.pricingService.getAvailableStateList().subscribe((states) => { 
//       if(states){
//         this.setState(states)         
//         sessionStorage.setItem('AllStateAvailable',JSON.stringify(states)); 
//        setTimeout(function(){sessionStorage.removeItem("AllStateAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });

//   }

//   setState(AllstatesData){
//     if(AllstatesData) this.states = AllstatesData;
//   }

  getInitialPricings() {
    if (this.pageNum == 1) {
      this.stateService.setProductPricing(this);
      this.pricingService.getAllPricings(this.getSearchString(), this.pageNum, this.sortField, this.orderBy, this.filterBy)
        .subscribe((result1) => {
          if (result1.count > this.config.getNumRecordsPerPage()) {
            this.pageNum++;
            this.pricingService.getAllPricings(this.getSearchString(), this.pageNum, this.sortField, this.orderBy, this.filterBy)
              .subscribe((result2) => {
                var result = {
                  rows: result1.rows.concat(result2.rows),
                  count: result1.count
                }
                this.pricings = result.rows;
                this.totalCount = result.count;
                this.getPricingsData(this.totalCount);
                this.getScrollPosData()
              })
          }
          else {
            this.pricings = result1.rows;
            this.totalCount = result1.count;
            this.getPricingsData(this.totalCount);
            this.getScrollPosData()
          }

        }, (err) => {
          console.log("err", err);
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    } else {
      this.getRetainData();
    }
  }

  getRetainData() {
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.pricingService.getAllPricings(this.getSearchString(), i, this.sortField, this.orderBy, this.filterBy))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedOrder) => {
        if (retainedOrder) {
          var totalOrders = [];
          for (var i = 0; i < retainedOrder.length; i++) {
            this.stateService.setProductPricing(this);
            totalOrders = totalOrders.concat(retainedOrder[i]['rows']);
          }
          this.pricings = totalOrders;
          this.totalCount = retainedOrder[0]['count'];
          this.getPricingsData(this.totalCount);
          this.getScrollPosData()
        }
      })
  }

  getSearchString() {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }


  goToPricingDetails(costId) {
    var getPos = document.documentElement.scrollTop;
    this.scrollService.setScrollPosProdPricng(getPos);
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin)
      this.router.navigate(['/product-pricing/' + costId])
  }

  getCountyList(event) {
    console.log("Event", event);
    if (event == 'all') {
      this.selectedCounty = 'all';
      this.applyFilter();
    }
    if (event.length != 0 && event != 'all') {
     // this.selectedCounty = '';
      this.pricingService.getAvailableCountyList(this.selectedState).subscribe((counties) => { this.counties = counties });
      this.applyFilter();
    }
  }

  // setPage(page: number) {
  //   console.log("Inside set page", page)
  //   if (page < 1 || page > this.pager.totalPages) {
  //     return;
  //   }
  //   this.pageNum = page;
  //   this.setDisplayPricings();
  //   this.getPricings();
  //   this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
  //   console.log("Pager", this.pager)
  // }

  applyFilter() {
    this.pageNum = 1;
    this.filterBy = [];
    if (this.selectedState && this.selectedState != 'all') this.filterBy.push({ State_Short_Name: this.selectedState })
    if (this.selectedCounty && this.selectedCounty != 'all') this.filterBy.push({ County_Name: this.selectedCounty })
    if (this.selectedProduct && this.selectedProduct != -1) this.filterBy.push({ Product_ID: this.selectedProduct })
    this.getInitialPricings();
    document.documentElement.scrollTop = 0;
    var x =document.documentElement.scrollTop;
    this.scrollService.setScrollPosProdPricng(x);
  }

  removeFilter() {
    this.pageNum = 1;
    this.filterBy = [];
    this.selectedState = '';
    this.selectedCounty = '';
    this.selectedProduct = 0;
    this.getInitialPricings();
  }

  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getInitialPricings();
  }



  setDisplayPricings() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.pricingsToDisplay = this.pricings.slice(start, this.totalCount);
    if (this.pricingsToDisplay.length > this.config.getNumRecordsPerPage()) {
      console.log("Slicing")
      this.pricingsToDisplay = this.pricingsToDisplay.slice(0, this.config.getNumRecordsPerPage());
    }
  }

  findPricings() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getInitialPricings();
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.selectedState = data.selectedState;
    this.selectedCounty = data.selectedCounty;
    this.selectedProduct = data.selectedProduct;
    this.filterBy = data.filterBy;
    this.counties = data.counties;
    this.products = data.products;
    this.totalCount = data.totalCount;
    this.pricingsToDisplay = data.pricingsToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.getInitialPricings();
  }

  getUserRole() {
    return Number(this.auth.getUserRole())
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.pricingService.getAllPricings(this.getSearchString(), this.pageNum, this.sortField, this.orderBy, this.filterBy)
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag()
            this.totalCount = result.count;
            this.pricings = this.pricings.concat(result.rows)
            this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
          }
        })
    }
  }

  getPricingsData(result) {
      this.config.resetShowFlag();
      this.config.setClearTimeout();
      this.setDisplayPricings();
      this.pager = this.pagerService.getPager(result, this.pageNum, this.config.getNumRecordsPerPage());
      this.searchInProgress = false;
      this.config.setDelayRecords();
  }

  getScrollPosData(){
    if (this.scrollService.getScrollPosProdPricng()) {
      var x = this.scrollService.getScrollPosProdPricng()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }))
    }
  }

  searchField(search){
    this.scrollService.setScrollPosProdPricng(0)
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search= search.trim();
    this.searchString = search;
    if(!format.test(this.searchString)&& this.searchString==" "){
      this.searchString='';
      this.getInitialPricings();
    }else this.findPricings();
  }
  resetAllValue(event){
    this.selectedCounty = '';    
 }  

}
