// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forget {
  position: absolute;
  font-size: 0.75rem;
  top: 8.5rem;
  right: 1rem;
  text-decoration: none;
  transition: color 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/app/authentication/login/login.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,qBAAA;EACA,sBAAA;AACJ","sourcesContent":[".forget {\n    position: absolute;\n    font-size: 0.75rem;\n    top:8.5rem;\n    right: 1rem;\n    text-decoration: none;\n    transition: color 0.2s;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
