import { Component, OnDestroy, OnInit } from "@angular/core";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { OrdersService } from "app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-chk-judgements",
  templateUrl: "./chk-judgements.component.html",
  styleUrls: ["./chk-judgements.component.scss"],
})
export class ChkJudgementsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  namesSearched: any = [];
  judgementsCnt: any = 0;
  orderId: any;
  judgementURL = null;

  constructor(
    private builder: RptBuilderService,
    private ordersService: OrdersService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.searchParties$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) this.getNamesSearched(data.curData);
      });
    this.builder.liens$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) this.getJudgementDetails(data.curData);
      });
    this.ordersService.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderId = data.Order_ID));
    this.ordersService.searchInfo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.getButtonLinks(data));
  }

  getNamesSearched(data) {
    this.namesSearched = [];
    data.forEach((sp) => {
      sp.Alias.forEach((al) => {
        if(sp.Entity_ID==1 || sp.Entity_ID==2){
          if (al.Alias !== "")  this.namesSearched.push(al.Alias);
        }
      });
    });
  }

  getJudgementDetails(data) {
    if (data && data.length)
      this.judgementsCnt = data.filter(
        (itm) => itm.Entity_ID == 2
      ).length;
  }

  getButtonLinks(data) {
    this.judgementURL = null;
    if (data.length) {
      if (data[0]["Judgement Link"] && data[0]["Judgement Link"].length)
        this.judgementURL = data[0]["Judgement Link"][0];
    }
  }
}
