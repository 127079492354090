import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService, OrganizationService, ConfigService, UsermessageService, GroupsService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../../dialog/success/success.component';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-new-organization',
  templateUrl: './new-organization.component.html',
  styleUrls: ['./new-organization.component.scss']
})
export class NewOrganizationComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  newOrgFrm: UntypedFormGroup;
  newOrgModel: any = {};
  error: string = '';
  errorMsg: any = {};
  errorType: string = '';
  timer: any = [];
  marketingGroupMembers = [];  
  selectedSalesPerson = null;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private organizationService: OrganizationService,
    private matDialog:MatDialog,
    private router: Router,
    private config: ConfigService,
    private userMsg: UsermessageService,
    public groupsService: GroupsService
  ) {
    this.newOrgFrm = fb.group({
      'Organization_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'Organization_Description': [null, Validators.compose([Validators.required, Validators.maxLength(225)])],
    })
    this.newOrgFrm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    this.getSalesMarketingGrpMembers();
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();
    }, 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  clearError() {
    this.errorMsg.msg = '';
  }


  createOrganization() {
    this.newOrgModel.Admin_User_ID = this.auth.getUserId();
    this.newOrgModel.Organization_Sales_Person_ID = this.selectedSalesPerson;
    this.organizationService.createOrganization(this.newOrgModel)
      .subscribe((res) => {
        let msg = "Company created successfully.";
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/organization/list']);
        // this.openModal();
      }, (err) => {
        console.log("err:", err);
        this.errorMsg = err;
      });
  }

  openModal() {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: "Success",
      message: "Company created successfully."
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      this.router.navigate(['/organization/list']);
    });
  }

  getSalesMarketingGrpMembers () {
    this.groupsService.getGroupMembers(environment.salesMarketingGrpId)
      .subscribe((users) => {
        this.marketingGroupMembers = users;
      }, (err) => {
        console.log("Error while fetching Group members data:",err);
      })
  }

}
