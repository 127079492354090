import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-rb-err-snackbar',
  templateUrl: './rb-err-snackbar.component.html',
  styleUrls: ['./rb-err-snackbar.component.scss']
})
export class RbErrSnackbarComponent implements OnInit {

  constructor(
    public bottomSheetRef: MatBottomSheetRef<RbErrSnackbarComponent>) { }

  ngOnInit() {
  }

  close(){
    this.bottomSheetRef.dismiss({ value : false });
  }

  proceed(){
    this.bottomSheetRef.dismiss({ value : true });
  }
}
