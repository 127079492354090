import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

@Component({
  selector: 'app-trackerpro-response-handler',
  templateUrl: './trackerpro-response-handler.component.html',
  styleUrls: ['./trackerpro-response-handler.component.scss']
})
export class TrackerproResponseHandlerComponent  {
  title = '';
  message: any;
  timer: any = [];
  constructor(public dialogRef: MatDialogRef<TrackerproResponseHandlerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
    
  }

  ngOnInit() {
    this.initDialog();

  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  confirm() {
    this.clearTime();
  }

  clearTime(){
    this.dialogRef.close();
    // this.close();
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  
  Close(){
    this.clearTime();
  }
}
