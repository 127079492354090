import { CdkDragDrop } from "@angular/cdk/drag-drop";
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UUID } from "angular2-uuid";
import { Subject, forkJoin } from "rxjs";
import { CONSTANTS } from "app/app.constants";
import { ErrorDialogMessageComponent } from "app/dialog/error-dialog-message/error-dialog-message.component";
import { ErrorDialogComponent } from "app/dialog/error-dialog/error-dialog.component";
import { ConfirmComponent } from "app/dialog/confirm/confirm.component";
import { AwsS3Service, ConfigService } from "app/services";
import { takeUntil } from "rxjs/operators";
import { RptBuilderService } from "../rpt-builder.service";
import { DialogService } from "ng2-bootstrap-modal";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-rb-document",
  templateUrl: "./rb-document.component.html",
  styleUrls: ["./rb-document.component.scss"],
})
export class RbDocumentComponent implements OnInit, OnDestroy {
  @Input() sectionData: any;
  @Input() sectionName: any;
  @Input() title: any = "Documents";
  @ViewChild("fileInput") fileInput: ElementRef;
  private ngUnsubscribe = new Subject();
  sectionDocs: any = [];
  orderDetails: any = {};
  reportDetails = {
    Derived_From: null,
  };
  sectionType: any = 0;
  documents: any = [];
  NewDocs: any = [];
  deletedDocs: any = [];
  constants = CONSTANTS;

  constructor(
    private builder: RptBuilderService,
    private config: ConfigService,
    private matDialog:MatDialog,
    private s3Service: AwsS3Service
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dataTypes) => {
        if (dataTypes && dataTypes.SP_Document_Types !== undefined) {
          let sectionBlock = dataTypes.SP_Document_Types.find(
            (docType) =>
              docType.Type.toLowerCase() == this.sectionName.toLowerCase()
          );
          if (sectionBlock !== undefined) {
            this.sectionType = sectionBlock.Id;
            this.collectDocuments();
            this.collectDeletedDocuments();
          }
        }
      });
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData && data.dbData.Id !== undefined)
          this.reportDetails = data.dbData;
      });
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderDetails = data));
  }

  collectDocuments() {
    this.builder.documents$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((docData) => {
        this.sectionDocs = docData.filter(
          (doc) =>
            doc.Type_ID == this.sectionType &&
            doc.Entity_ID == this.sectionData.Id
        );
      });
  }

  collectDeletedDocuments() {
    this.builder.deletedItems$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.hasOwnProperty("SP_Documents")) {
          if (this.sectionType) {
            this.deletedDocs = data["SP_Documents"].filter(
              (document) =>
                document.Type_ID == this.sectionType &&
                document.Entity_ID == this.sectionData.Id
            );
          }
        }
      });
  }

  deleteFile(document, index) {
    this.matDialog
      .open(
        ConfirmComponent,
        {data:{
          title: "Delete Document",
          message:
            "Are you sure you want to delete " + document.Original_Name + " ?",
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {
        if (res) {
          document.Status = 0;
          this.builder
            .updateDocuments([document], this.sectionData.Sp_Id)
            .subscribe();
        } else {
          console.log("Closed", res);
        }
      });
  }

  downloadFile(documentData) {
    this.s3Service
      .downloadFromPath(documentData.Path, documentData.Original_Name)
      .then((downloadLink) => {
        let link: any = document.createElement("a");
        document.body.appendChild(link);
        link.download = documentData.Original_Name;
        link["href"] = downloadLink;
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
      });
  }

  dropDocument(event: CdkDragDrop<string[]>) {
    this.sectionDocs.splice(
      event.container.data["index"],
      0,
      this.sectionDocs.splice(event.previousContainer.data["index"], 1)[0]
    );
    this.sectionDocs.forEach((item, index) => {
      item.Sort_Order = index;
    });
    this.builder
      .updateDocuments(this.sectionDocs, this.sectionData.Sp_Id)
      .subscribe();
  }

  uploadDocs() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  async uploadDocument(event) {
    this.NewDocs = [];
    if (this.validateFilenames(event)) {
      var fileUploads = [];
      var encryptedFiles = [];
      if (this.checksize(event.target.files)) {
        for (var i = 0; i < event.target.files.length; i++) {
          let isEncrypted = await this.handleEncryptedFiles(
            event.target.files[i]
          );
          if (isEncrypted && isEncrypted == true)
            encryptedFiles.push(event.target.files[i].name);
          else {
            var uid = UUID.UUID();
            this.documents.push({
              docId: uid,
              name: event.target.files[i].name,
              completed: 0,
            });
            fileUploads.push(
              this.uploadToS3AndUpdatePath(
                event.target.files[i],
                uid,
                this.sectionDocs.length + i
              )
            );
          }
        }
        if (encryptedFiles.length > 0)
          this.openErrorMsgPopup(
            CONSTANTS.apiErrors.encryptedFileError,
            encryptedFiles
          );
        forkJoin(fileUploads).subscribe((files) => {
          this.removeCompletedDocs(files);
          var succesFiles = files.filter((file) => file !== undefined);
          if (succesFiles.length > 0) {
            this.NewDocs = this.NewDocs.concat(succesFiles);
          }
        });
        Promise.all(fileUploads)
          .then((files) => {
            this.builder
              .addDocuments(this.NewDocs, this.sectionData.Sp_Id)
              .subscribe();
          })
          .catch((err) => {
            this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
          });
      } else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    } else {
      this.openErrorPopup(CONSTANTS.apiErrors.validateFile);
    }
  }

  async handleDrop(fileList: FileList) {
    if (this.checksize(fileList)) {
      var fileUploads = [];
      var encryptedFiles = [];
      for (var i = 0; i < fileList.length; i++) {
        let isEncrypted = await this.handleEncryptedFiles(fileList[i]);
        if (isEncrypted && isEncrypted == true)
          encryptedFiles.push(fileList[i].name);
        else {
          var tempDocId = UUID.UUID();
          this.documents.push({
            docId: tempDocId,
            name: fileList[i].name,
            completed: 0,
          });
          fileUploads.push(
            this.uploadToS3AndUpdatePath(
              fileList[i],
              tempDocId,
              this.sectionDocs.length + i
            )
          );
        }
      }
      if (encryptedFiles.length > 0)
        this.openErrorMsgPopup(
          CONSTANTS.apiErrors.encryptedFileError,
          encryptedFiles
        );
      Promise.all(fileUploads)
        .then((files) => {
          this.removeCompletedDocs(files);
          var succesFiles = files.filter((file) => file !== undefined);
          if (succesFiles.length > 0)
            this.builder
              .addDocuments(succesFiles, this.sectionData.Sp_Id)
              .subscribe();
        })
        .catch((err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
        });
    } else {
      this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
    }
  }

  uploadToS3AndUpdatePath(file, docId, sortOrder) {
    return this.s3Service
      .onManagedUpload(file, this.orderDetails.Order_ID)
      .then((data) => {
        if (data) {
          let doc = {
            Sp_Id: this.sectionData.Sp_Id,
            Path: data.Key,
            Name: this.extractFilename(data.Key),
            Original_Name: file.name,
            Type_ID: this.sectionType,
            Entity_ID: this.sectionData.Id,
            Status: 1,
            Sort_Order: sortOrder,
            docId: docId,
          };
          return doc;
        }
      })
      .catch((err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
      });
  }

  removeCompletedDocs(files) {
    for (var i = 0, len = files.length; i < len; i++) {
      for (var j = 0, len2 = this.documents.length; j < len2; j++) {
        if (files[i] && files[i].docId === this.documents[j].docId) {
          this.documents.splice(j, 1);
          len2 = this.documents.length;
        }
      }
    }
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog
      .open(
        ErrorDialogComponent,
        {data:{
          message: msg,
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {});
  }

  openErrorMsgPopup(msg, list) {
    let disposable = this.matDialog
      .open(
        ErrorDialogMessageComponent,
        {data:{
          message: msg,
          itemList: list,
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {});
  }

  validateFilenames(event) {
    var flag = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      var filename = event.target.files[i].name;
      if (filename.indexOf(",") > -1) flag++;
    }
    if (flag == 0) return true;
    else return false;
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(
      files,
      (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024
    );
    if (largeFiles.length > 0) return false;
    else return true;
  }

  handleEncryptedFiles(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      let encrypted = false;
      reader.onload = function (event: any) {
        let fileContents = event.target.result;
        if (fileContents.indexOf("/Encrypt") !== -1) {
          encrypted = true;
        }
        resolve(encrypted);
      };
      reader.readAsText(file);
      reader.onerror = function (event: any) {
        console.error("Error while reading file contents.");
      };
    });
  }

  extractFilename(filePath) {
    var parts = filePath.split("/");
    var filename = parts[parts.length - 1];
    return filename;
  }

  isDocAdded(item) {
    return this.builder.isDocAdded(
      this.sectionData.Sp_Id,
      this.sectionName && (this.sectionName == 'property'  || this.sectionName == 'Additional Document') ? this.sectionData.Sp_Id : this.sectionData.Id ,
      item
    );
  }

  isDocDeleted(item) {
    return this.builder.isDocDeleted(
      this.sectionData.Sp_Id,
      this.sectionData.Id,
      item
    );
  }
}
