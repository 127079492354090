import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../app.constants';
import { ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService, EmailValidator, EqualValidator, SearchMasterService, OrganizationService, ConfigService, UsermessageService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-new-search-master',
  templateUrl: './new-search-master.component.html',
  styleUrls: ['./new-search-master.component.scss']
})
export class NewSearchMasterComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  userForm: any = {}
  userModel: any = {}
  error: any = {}
  errorMsg: any;
  constants = CONSTANTS
  timer: any = [];
  constructor(
    private router: Router,
    private frmBuilder: UntypedFormBuilder,
    public auth: AuthService,
    private masterService: SearchMasterService,
    private matDialog:MatDialog,
    private config:ConfigService,
    private userMsg:UsermessageService
  ) { 
    this.userForm = frmBuilder.group({
      'Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      'Confirm_Email_Address': [null, Validators.required],
      'User_First_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'User_Last_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'searchMaster_Status': [null],
      'Is_Manager': [null]
    })
    this.userForm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    this.userModel.searchMaster_Status = false;
    this.userModel.Is_Manager = false;
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();
    }, 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  changeModelVal(modelVal) {
    this.error = {};
    this.errorMsg = '';
  }

  createSearchMaster() { 
    this.masterService.createNewMaster(this.userModel)
      .subscribe((res) => {
        let msg = "Password link has been sent to the registered email.";
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/searchmaster/list']);
        // this.openModal('Success', 'Password link has been sent to the registered email.');
      }, (err) => {
        this.errorMsg = err;
      });
  }

  openModal(title, msg) {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {      
        this.router.navigate(['/searchmaster/list']);
    });
  }
}
