import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) { }

  getAllGroups(sortField, sortOrder, searchStr) {
    var data = {
      sortField: sortField,
      sortOrder: sortOrder,
      searchStr: searchStr
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/notifyGroups/all';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createGroup(modelData) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/notifyGroups/create';
    return this.http.post(url, modelData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getGroupInfo(groupId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/notifyGroups/group/' + groupId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  editGroup(groupData) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/notifyGroups/edit';
    return this.http.post(url, groupData, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  activateGroup(data) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/notifyGroups/status/active';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  suspendGroup(data) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/notifyGroups/status/suspend';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getGroupMembers(groupId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/notifyGroups/group/' + groupId + '/members';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  removeGroupMember(data) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/notifyGroups/member/remove';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  modifyGroupMembers(data) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/notifyGroups/memebers/modify';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllEvents() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/groups/events/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllGroupsforEvents(events) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/groups/all/events';
    return this.http.post(url, { Events : events }, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }


  getGroupTags(groupId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/notifyGroups/group/tags/' + groupId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  modifyGroupTags(data) {
    data.Admin_ID = this.auth.getUserId();
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.auth.getToken()
      })
    }
    let url = this.config.getBasePath() + '/notifyGroups/group/tag/modify';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error))
    )
  }

}
