import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PreloaderService {

 public static loadingCount: number = 0;
  public static searchCount: number = 0;
  public static searchSpinner: boolean = false;
  public static directCount: number = 0;
  public static directRequest: boolean = false;
  private _onNetwork$: BehaviorSubject<any> = new BehaviorSubject(false);
  public onNetwork$: Observable<any> = this._onNetwork$.asObservable();

 getPreloaderCount(): number {
      return PreloaderService.loadingCount;    
 }

 showPreloader(): void {
      if(PreloaderService.searchSpinner) PreloaderService.searchCount++;
      else if(PreloaderService.directRequest) PreloaderService.directCount++;
      else PreloaderService.loadingCount++;
  }

 hidePreloader(): void {
      if(PreloaderService.searchSpinner) PreloaderService.searchCount--;
      else if(PreloaderService.directRequest) PreloaderService.directCount--;
      else PreloaderService.loadingCount--;
      if(PreloaderService.searchCount == 0) PreloaderService.searchSpinner = false;
      if(PreloaderService.directCount == 0) PreloaderService.directRequest = false;
      this._onNetwork$.next(PreloaderService.directRequest);
  }

 getSearchCount(): number {
      return PreloaderService.searchCount;    
 }

 setSearchSpin(): void{
    PreloaderService.searchSpinner = true;
  }

  setDirectRequest(): void{
     PreloaderService.directRequest = true;
     this._onNetwork$.next(PreloaderService.directRequest);
   }

 unsetSearchSpin(): void{
    PreloaderService.searchSpinner = false;
  }

}