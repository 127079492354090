import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { MatSelect } from '@angular/material/select';
import { AuthService, OrganizationService, UsersService } from '../../services';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-exception',
  templateUrl: './edit-exception.component.html',
  styleUrls: ['./edit-exception.component.scss']
})
export class EditExceptionComponent   {
  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('selectBtn') focusedElement1: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef


  message: any;
  title = '';
  timer: any = [];
  errorMsg: any = {};
  editExpForm: UntypedFormGroup
  changeModel: any = {};
  excludedDomainName = '';

  constructor(
    public dialogRef: MatDialogRef<EditExceptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private organizationService: OrganizationService,
    private auth: AuthService,
    private fb: UntypedFormBuilder
  ) {
    
    this.editExpForm = fb.group({
      'excludedDomainName': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
    })
  }

  ngOnInit() {
    this.initDialog();
    if (this.message && this.message.Excluded_Domain_Name && this.message.Excluded_Domain_Name.trim()) this.changeModel.excludedDomainName = this.message.Excluded_Domain_Name;
    this.timer.push(setTimeout(() => { this.myFirstFocus.nativeElement.focus() }, 0));
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  confirm() {
    this.errorMsg = '';
    let obj = {};
    obj['Excluded_Domain_Name'] = this.changeModel.excludedDomainName;
    obj['Excluded_Domain_ID'] = this.message['Excluded_Domain_ID'];
    obj['Admin_User_ID'] = this.auth.getUserId();
    this.organizationService.editException(obj)
      .subscribe(res => {
         this.dialogRef.close(true);
        this.clearTime();
      }, (err) => {
        this.errorMsg = err;
      });

  }

  Close() {
     this.dialogRef.close(false);
    this.clearTime();
  }

  clearTime() {
    this.timer.forEach((time) => {
      clearTimeout(time)
    });
  }

}
