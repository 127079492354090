import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { RptBuilderService } from "../rpt-builder.service";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-rb-property",
  templateUrl: "./rb-property.component.html",
  styleUrls: ["./rb-property.component.scss"],
})
export class RbPropertyComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  sectionObject: any = {};
  dataTypes: any;
  interestTypes: any = [];
  basicData: any = {};
  dataForm: UntypedFormGroup;
  subdivisionForm: UntypedFormGroup;
  orderDetails: any = {
    Property_County: "",
  };
  matchingRegistryStates = ["HI", "MA", "MN"];
  sectionName = "property";
  itemData = {
    Applies: true,
    Id: null,
    Sp_Id: 0,
  };
  registryOptions = [{value : 1, label : "Recorded Land"}, {value : 2, label : "Land Court"} , {value : 3, label : "Other"}];
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private builder: RptBuilderService,
    private cdr: ChangeDetectorRef
  ) {
    this.resetBasic();
    this.dataForm = frmBuilder.group({
      Parcel: [null, [Validators.maxLength(255)]],
      PUD: [null],
      Development_Name: [null, [Validators.maxLength(512)]],
      Registry: [null],
      Legal_Description: [null, [Validators.maxLength(65535)]],
      Comments: [null, [Validators.maxLength(65535)]],
      Mobile_Home: [null],
      Interest_Type_Id: [null],
      Report_Lat: [null],
      Report_Lng: [null],
    });

    this.subdivisionForm = frmBuilder.group({
      Order_Subdivision: [null, [Validators.maxLength(255)]],
    });

    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.syncReportDetails(this.dataForm));

      this.subdivisionForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.updateSubdivision(this.subdivisionForm));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.resetBasic();
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.dataTypes = data;
        if (data) this.interestTypes = data.Property_Interest_Types;
      });
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComponent(data));
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initOrderdata(data));
  }

  initComponent(data) {
    this.sectionObject = data;
    if (data && data.curData && data.curData.Parcel !== undefined) {
      this.basicData = data.curData;
      this.itemData.Sp_Id = this.basicData.Id;
      this.itemData.Id = null;
      if (
        this.orderDetails &&
        this.orderDetails.Order_ID !== undefined &&
        this.orderDetails.Order_ID !== ""
      )
        this.builder.manageError(
          `Property Data_${this.orderDetails.Order_ID}_0`,
          this.dataForm.status !== "VALID",
          this.dataForm.controls
        );
      this.cdr.detectChanges();     
    }
  }

  resetBasic () {
    this.basicData = {
      Parcel: null,
      PUD: null,
      Development_Name: null,
      Registry: null,
      Legal_Description: null,
      Comments: null,
      Mobile_Home: null,
      Interest_Type_Id: null,
      Report_Lat: null,
      Report_Lng: null
    };
  }

  initOrderdata(data) {
    if (data && data.Property_County !== undefined) this.orderDetails = data;
  }

  buildAddress(model) {
    let completeAddress = "";
    if (model && model.Property_Address_1 !== undefined) {
      if (model.Property_Address_1) completeAddress += model.Property_Address_1;
      if (model.Property_Address_2) {
        if (completeAddress) completeAddress += " ";
        completeAddress += model.Property_Address_2;
      }
      if (model.Property_City) {
        if (completeAddress) completeAddress += " ";
        completeAddress += model.Property_City;
      }
      if (model.Property_State_Abbr) {
        if (completeAddress) completeAddress += ", ";
        completeAddress += model.Property_State_Abbr;
      }
      if (model.Property_ZipCode) {
        if (completeAddress) completeAddress += " ";
        completeAddress += model.Property_ZipCode;
      }
    }
    return completeAddress;
  }

  checkIfPresent() {
    return this.matchingRegistryStates.some(
      (state) =>
        this.orderDetails.Property_State_Abbr &&
        this.orderDetails.Property_State_Abbr.toLowerCase() ==
          state.toLowerCase()
    );
  }

  syncReportDetails(event) {
    if (this.orderDetails && this.orderDetails.Order_ID !== undefined)
      this.builder.manageError(
        `Property Data_${this.orderDetails.Order_ID}_0`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine) {
      this.sectionObject.curData = this.builder.trimInput(event, this.basicData);
      this.sectionObject.Sp_Id = this.basicData.Id;
      this.sectionObject.Derived_From = this.basicData.Derived_From;
      this.builder.saveBasicData(this.sectionObject).pipe(take(1)).subscribe();
    }
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.basicData["Id"],
      "PRT_DT",
      this.basicData.Id,
      fieldName
    );
  }

  updateSubdivision(event) {
    if (!event.pristine) {
      if(this.orderDetails.Order_Subdivision != undefined) this.builder.updateSubdivisionData(this.orderDetails.Order_ID, this.orderDetails.Order_Subdivision).subscribe();
    }
  }
}
