import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { AbstractorService } from '../../services/abstractor/abstractor.service'
import { SearchPropsPipe } from '../../pipes';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-abstractor-send',
  templateUrl: './abstractor-send.component.html',
  styleUrls: ['./abstractor-send.component.scss'],
  providers: [ SearchPropsPipe ]
})
export class AbstractorSendComponent {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef
  message = {};
  activeAbstractor: any = {};
  title = '';
  error = '';
  searchQuery: string = '';
  Abstractor_ID = null;
  selectedAbstractor = {}
  Order_ID: number;
  listingType: string;
  checked: boolean = false;
  sendReminder:boolean = true;
  timer: any = [];
  filterBy: any = [];
  offersCommercial: boolean = false;
  valid_EnO: boolean = true;

  constructor(private abstractorService: AbstractorService,public dialogRef: MatDialogRef<AbstractorSendComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.title=this.data.title;
    this.message=this.data.message;
  }

  ngOnInit() {
    this.initDialog();
    this.listingType = 'cost';
    this.Order_ID = this.message['Order_ID'];
    this.activeAbstractor = this.message['Current_Abstractor'];
    if (this.activeAbstractor) {
      this.Abstractor_ID = this.activeAbstractor.Abstractor_ID;
      this.message['Abstractors'] = this.message['Abstractors'].filter((x) => x.Abstrator_ID != this.activeAbstractor.Abstrator_ID)
    }
   this.timer.push(setTimeout(() => { 
      this.myFirstFocus.nativeElement.focus()
    }, 0));
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  confirm() {
    this.dialogRef.close({ abstractor:this.selectedAbstractor, checked:this.checked, sendReminder: this.sendReminder });
    // this.result = { abstractor:this.selectedAbstractor, checked:this.checked, sendReminder: this.sendReminder };    
    this.clearTime();
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    this.clearTime();
  }

  createNew() {
    this.dialogRef.close(-1);
    // this.result = -1;
    this.clearTime();
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  selectItem(item) {
    this.Abstractor_ID = item.Abstrator_ID;
    this.selectedAbstractor = item;
  }

  detectTypeChange(event) {
    var order = [];
    if (event.value == 'cost') {
      order = [['Abstrator_Cost', 'ASC'], ['Abstrator_Rating', 'DESC']];
    }
    if (event.value == 'rating') {
      order = [['Abstrator_Rating', 'DESC'], ['Abstrator_Cost', 'ASC']];
    }

    let filterBy = [];
    filterBy.push({ offersCommercial: this.offersCommercial })
    filterBy.push({ valid_EnO: this.valid_EnO })

    this.abstractorService.getAbstractorList(this.Order_ID, order, filterBy)
      .subscribe((abstractors) => {
        this.message['Abstractors'] = abstractors;
        if (this.activeAbstractor) {
          this.Abstractor_ID = this.activeAbstractor.Abstractor_ID;
          this.message['Abstractors'] = this.message['Abstractors'].filter((x) => x.Abstrator_ID != this.activeAbstractor.Abstrator_ID)
        }
      })

  }

  applyFilter() {
    console.log("his.Order_ID============",this.Order_ID)
    let filterBy = [];
    filterBy.push({ offersCommercial: this.offersCommercial })
    filterBy.push({ valid_EnO: this.valid_EnO })

    var order = [];
    if (this.listingType == 'cost') {
      order = [['Abstrator_Cost', 'ASC'], ['Abstrator_Rating', 'DESC']];
    }
    if (this.listingType == 'rating') {
      order = [['Abstrator_Rating', 'DESC'], ['Abstrator_Cost', 'ASC']];
    }

    this.abstractorService.getAbstractorList(this.Order_ID, order, filterBy)
    .subscribe((abstractors) => {
      this.message['Abstractors'] = abstractors;
      if (this.activeAbstractor) {
        this.Abstractor_ID = this.activeAbstractor.Abstractor_ID;
        this.message['Abstractors'] = this.message['Abstractors'].filter((x) => x.Abstrator_ID != this.activeAbstractor.Abstrator_ID)
      }
    })
  }

  // Method to adjust date to 5 PM EST
adjustDateTo5PMEST(date: Date): Date {
  const adjustedDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  adjustedDate.setHours(17, 0, 0, 0); // Set time to 5 PM EST
  return adjustedDate;
}

// Method to calculate expiration text based on conditions
expirationText(obj) {
  if (!obj.User_Abstractor_Attribute_EnO) {
    return 'None';
  }
  if (!obj.User_Abstractor_Attribute_EnO_Expiry) {
    return 'Exp Known';
  }

  const expirationDate = this.adjustDateTo5PMEST(obj.User_Abstractor_Attribute_EnO_Expiry);
  const currentEST = this.adjustDateTo5PMEST(new Date());

  const daysDifference = Math.floor((expirationDate.getTime() - currentEST.getTime()) / (1000 * 3600 * 24));

  if (daysDifference > 30) {
    return 'Valid';
  } else if (daysDifference > 0) {
    if (daysDifference === 1) {
      return `Expires in ${daysDifference} day`;
    } else {
      return `Expires in ${daysDifference} days`;
    }
  } else if (daysDifference === 0) {
    return 'Expires today';
  } else {
    return 'Expired';
  }
}

// Method to calculate expiration style based on conditions
expirationStyle(obj) {
  if (!obj.User_Abstractor_Attribute_EnO) {
    return { color: 'gray' }; //none
  }

  if (!obj.User_Abstractor_Attribute_EnO_Expiry) {
    return { color: 'gray', 'font-weight': 'bold' }; //'Exp Known'
  }

  const expirationDate = this.adjustDateTo5PMEST(obj.User_Abstractor_Attribute_EnO_Expiry);
  const currentEST = this.adjustDateTo5PMEST(new Date());

  const daysDifference = Math.floor((expirationDate.getTime() - currentEST.getTime()) / (1000 * 3600 * 24));

  if (daysDifference > 30) {
    return { color: '#8fd14f', 'font-weight': 'bold' };
  } else if (daysDifference >= 0) {
    return { color: '#fac710', 'font-weight': 'bold' };
  } else {
    return { color: '#f24726', 'font-weight': 'bold' };
  }
}

  removeSpecialChar(num){
    if(num)  return  num.replace(/[^\d]/g, '');
    else return ;
  }

  clearFilter(){
    this.searchQuery = '';
  }

}
