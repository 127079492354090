import { Component, OnInit ,ViewChild, ElementRef, Inject  } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { AuthService} from '../../services';
import { CONSTANTS } from '../../app.constants';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent  {
  @ViewChild('okBtn') focusedElement: ElementRef;

  title: any;
  message: any;
  timer: any = [];
  bulkUplodErr: boolean = false;
  constructor(public auth: AuthService, public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
       
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => { this.focusedElement.nativeElement.focus(); }, 0))
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  Close() {
     this.dialogRef.close(false);
    this.clearTime()
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  
}
