// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pad-sides {
  padding-left: 15px;
  padding-right: 15px;
}

.top-margin {
  margin-top: 22px;
}

.wrapper {
  border: 1px solid;
  padding: 15px 0px;
}

.textWrap {
  word-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/invoice/invoice.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,iBAAA;AACJ;;AACA;EACI,qBAAA;AAEJ","sourcesContent":[".pad-sides{\n    padding-left: 15px;\n    padding-right: 15px;\n}\n\n.top-margin{\n    margin-top:22px\n}\n\n.wrapper{\n    border: 1px solid;\n    padding: 15px 0px;\n}\n.textWrap {\n    word-wrap: break-word;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
