import { Component, OnInit } from '@angular/core';
import { OrdersService, ConfigService, StateService, GroupsService, AuthService } from '../services';
import { CONSTANTS } from '../app.constants';
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../dialog/error-dialog/error-dialog.component';
import * as momentTimeZone from 'moment-timezone';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  selReportType = null;
  constants = CONSTANTS;
  filterModel: any = {};
  listingType = "1";
  errors: any = {};
  compDateErrors: any = {};
  createDateErrors:any={};
  compFromTime :string = "00:00" ; 
  compToTime :string = "23:59" ; 
  creationFromTime :string = "00:00" ; 
  creationToTime :string = "23:59" ; 
  compDateFromInvalid: boolean = false;
  compDateToInvalid: boolean = false;
  creationDateFromInvalid: boolean = false;
  creationDateToInvalid: boolean = false;
  valid: boolean = false;
  monthShortNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
  "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
];
  reportDescript='';
  reportName='';
  reportdata: any=[];
  marketingGroupMembers = [];  
  isSalesPerson: boolean = false;

  constructor(
    private orderService: OrdersService,
    private matDialog:MatDialog,
    private config: ConfigService,
    private stateService: StateService,
    public groupsService: GroupsService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    if (this.stateService.getReportTypesData()) {
      this.initializeComponentData(this.stateService.getReportTypesData());
    }
    setTimeout(() => this.config.setSideBarNode(25), 0);
    this.getReportDescription();
    this.getSalesMarketingGrpMembers();
  }

  generateReport() {
    this.stateService.setReportTypesData({ selReportType: this.selReportType })
    if (this.selReportType && this.selReportType == 'End_Of_Day') {
      this.createEndOfDayReport();
    } else if(this.selReportType && this.selReportType == 'NPS') {
      this.createNPSReport();
    } else if(this.selReportType && this.selReportType == 'Attendance_report') {
      this.createAttendanceReport();
    } else if(this.selReportType && this.selReportType == 'Volume'){
      this.createVolumeReport()
    }else if(this.selReportType && this.selReportType == 'Productivity') this.createProdReport()
    else if(this.selReportType && this.selReportType == 'Vendor_Management') this.createVendorReport()
    else if(this.selReportType && this.selReportType == 'Tax_Report') this.createTaxNAssessorReport()
    else if(this.selReportType && this.selReportType == 'Client_Report') this.createClientReport();
  }

  createEndOfDayReport() {
    this.orderService.createEndOfDayReport()
      .subscribe((blobData) => {
        if (blobData) this.composeAndDwnld(blobData);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  composeAndDwnld(blobData) {    
    let file = new Blob([blobData], { type: 'application/octet-stream' });
    var fileUrl = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("download", this.getFileName('EOD_Report'));
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getFileName(name) {
    var currentDate = new Date();
    var estDate = momentTimeZone.tz(currentDate, 'America/New_York').format('MMM D, YYYY, h:mm a');
    var nowDate = new Date(estDate);
    var fileFormat = name + '_' +nowDate.getDate() + '_' + this.monthShortNames[nowDate.getMonth()] + '_'+ nowDate.getHours() + '_' + nowDate.getMinutes()+'.xlsx';
    return fileFormat;
  }

  initializeComponentData(data) {
    this.selReportType = data.selReportType;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  createNPSReport() {
    this.orderService.createNPSReport()
      .subscribe((npsData) => {
        if(npsData.ExcelUrl) this.downloadVolumeReport(npsData.ExcelUrl);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  createClientReport() {
    this.orderService.createClientReport()
      .subscribe((clientData) => {
        if(clientData.ExcelUrl) this.downloadVolumeReport(clientData.ExcelUrl);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }
  
  createAttendanceReport() {
    this.orderService.createAttendanceReport()
      .subscribe((data) => {
        if(data.ExcelUrl) this.downloadVolumeReport(data.ExcelUrl);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  createVolumeReport(){
    this.orderService.createVolumeReport()
    .subscribe((volumeData)=>{
      if(volumeData.ExcelUrl) this.downloadVolumeReport(volumeData.ExcelUrl);
    },(err)=>{
      this.openErrorPopup(CONSTANTS.apiErrors.datafetch);

    })
  }

  downloadVolumeReport(volumeDataUrl){
    var link = document.createElement("a");
    link.setAttribute("href", volumeDataUrl);
    link.setAttribute("download", "VolumeReport.xlsx");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  createProdReport(){
    this.orderService.createProductivityReport()
    .subscribe((prodDate)=>{
      if(prodDate.ExcelUrl) this.downloadVolumeReport(prodDate.ExcelUrl)
    },(err)=>{
      this.openErrorPopup(CONSTANTS.apiErrors.datafetch)
    })
  }

  createVendorReport(){
    this.orderService.createVendorReport(this.filterModel)
    .subscribe((vendData)=>{
      if(vendData.ExcelUrl) this.downloadVolumeReport(vendData.ExcelUrl)
    },(err)=>{
      this.openErrorPopup(CONSTANTS.apiErrors.datafetch)
    })
  }

  cleardate(id){
    if(id == 'fromDate') {
      this.filterModel['fromDate'] = null;
     }
     if(id == 'toDate') {
       this.filterModel['toDate'] = null;
     }
     if(id == 'fromCompletedDate') {
       this.filterModel['fromCompletedDate'] = null;
     }
     if(id == 'toCompletedDate') {
       this.filterModel['toCompletedDate'] = null;
     }
   }

   resetPageNum() {
    this.filterModel.pageNum = 1;
  }

  validateDateRange() {
    if (this.filterModel['fromDate'] && this.filterModel['toDate'] && (this.filterModel['fromDate'] > this.filterModel['toDate'])) {
      this.errors['Date_Range'] = true;
    } else {
      delete this.errors['Date_Range'];
    }
  }

  validateDateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors['date'] = true;
    else {
      delete this.errors['date'];
      this.validateDateRange();
    }
  }

  vendorFilter(){
    if(this.listingType === "1") {
      this.filterModel.fromDate = null;
      this.creationFromTime = "00:00"; 
      this.filterModel.toDate = null;
      this.creationToTime = "23:59";
      this.filterModel.fromCompletedDate = null;
      this.compFromTime = "00:00";
      this.filterModel.toCompletedDate = null;
      this.compToTime = "23:59"; 
      
    }else if(this.listingType === "2"){
      this.filterModel.fromCompletedDate = null;
      this.compFromTime = "00:00";
      this.filterModel.toCompletedDate = null;
      this.compToTime = "23:59"; 
    }else{
      this.filterModel.fromDate = null;
      this.creationFromTime = "00:00"; 
      this.filterModel.toDate = null;
      this.creationToTime = "23:59"; 
    }
    if (this.compDateErrors['Date_Range']) delete this.compDateErrors['Date_Range'];
    if (this.createDateErrors['Date_Range']) delete this.createDateErrors['Date_Range'];
    
  }

  ifInvalidDateRange() {
    if(this.selReportType === 'Vendor_Management' && this.listingType != '1')
    if(this.errors['Date_Range'] ||  this.compDateErrors['Date_Range'] || this.createDateErrors['Date_Range']) return true;
    else return false;
   }
 
   ifInvalidTime() {
    if(this.selReportType === 'Vendor_Management'  && this.listingType != '1' )
     if(this.compDateFromInvalid || this.compDateToInvalid || this.creationDateFromInvalid || this.creationDateToInvalid) return true;
     else return false;
    }
 
  getCompletedDateFrom(event) {
    if(event && event.timeCtrlForm && !event.timeCtrlForm.valid) this.compDateFromInvalid = true;
    else this.compDateFromInvalid = false;
    if(event && event.Date_EST && event.Time_EST) {
      if(event.Date_EST._d.getFullYear() >= '1500'){
        this.filterModel['fromCompletedDate'] = event.Date_EST;
        this.compFromTime = event.Time_EST
        this.filterModel['fromCompleted_DateTime'] = this.setTimeToDate(event.Date_EST, event.Time_EST)
        this.valid= false;
      }else this.valid= true;
    }else this.valid= true;
    this.validateCompDateRangeFilter();
  }

  getCreationDateFrom(event) {
    if(event && event.timeCtrlForm && !event.timeCtrlForm.valid) this.creationDateFromInvalid = true;
    else this.creationDateFromInvalid = false;
    if(event && event.Date_EST && event.Time_EST) {
      if(event.Date_EST._d.getFullYear() >= '1500'){
      this.filterModel['fromDate'] = event.Date_EST;
      this.creationFromTime = event.Time_EST
      this.filterModel['fromDate_Time'] = this.setTimeToDate(event.Date_EST, event.Time_EST)
      this.valid= false;
      }else this.valid= true;
    }else this.valid= true;
    this.validateCreationRangeFilter();
  }

  getCreationDateTo(event) {
    if(event && event.timeCtrlForm && !event.timeCtrlForm.valid) this.creationDateToInvalid = true;
    else this.creationDateToInvalid = false;
    if(event && event.Date_EST && event.Time_EST) {
      if(event.Date_EST._d.getFullYear() >= '1500'){
      this.filterModel['toDate'] = event.Date_EST;
      this.creationToTime = event.Time_EST
      this.filterModel['toDate_Time'] = this.setTimeToDate(event.Date_EST, event.Time_EST)
      this.valid= false;
      }else this.valid= true;
    }else this.valid= true;
    this.validateCreationRangeFilter();
  }

  clearFilter(id) {
    if(id == 'fromDate') {
      this.filterModel['fromDate'] = null;
      this.creationFromTime = "00:00" ; 
      this.filterModel['fromCreationDate_Time'] = null;
    }
    if(id == 'toDate') {
      this.filterModel['toDate'] = null;
      this.creationToTime = "23:59" ; 
      this.filterModel['toDate_Time'] = null;
    }
    if(id == 'fromCompletedDate') {
      this.filterModel['fromCompletedDate'] = null;
      this.compFromTime = "00:00" ; 
      this.filterModel['fromCompleted_DateTime'] = null;
    }
    if(id == 'toCompletedDate') { 
      this.filterModel['toCompletedDate'] = null;
      this.compToTime = "23:59" ; 
      this.filterModel['toCompleted_DateTime'] = null; 
    }
    if (this.compDateErrors['Date_Range']) delete this.compDateErrors['Date_Range']; 
    if (this.createDateErrors['Date_Range']) delete this.createDateErrors['Date_Range'];  
  }


  validateCreationRangeFilter(){
    if (this.filterModel['fromDate'] && this.filterModel['toDate'] && (this.filterModel['fromDate'] > this.filterModel['toDate'])) {
      this.createDateErrors['Date_Range'] = true;
    } else {
      delete this.createDateErrors['Date_Range'];
    }

  }


  getCompletedDateTo(event) {
    if(event && event.timeCtrlForm && !event.timeCtrlForm.valid) this.compDateToInvalid = true;    
    else this.compDateToInvalid = false;
    if(event && event.Date_EST && event.Time_EST) {
      if(event.Date_EST._d.getFullYear() >= '1500'){
        this.filterModel['toCompletedDate'] = event.Date_EST;
        this.compToTime = event.Time_EST;
        this.filterModel['toCompleted_DateTime'] = this.setTimeToDate(event.Date_EST, event.Time_EST)
        this.valid=false;
      }else this.valid=true;
    }else this.valid=true;
    this.validateCompDateRangeFilter();    
  }




  validateCompDateRangeFilter() {
    if (this.filterModel['fromCompletedDate'] && this.filterModel['toCompletedDate'] && (this.filterModel['fromCompletedDate'] > this.filterModel['toCompletedDate'])) {
      this.compDateErrors['Date_Range'] = true;
    } else {
      delete this.compDateErrors['Date_Range'];
    }
  }

  setTimeToDate(date, time) {
    if(date && date._d){
      let hours = time.split(":")[0];
      let minutes = time.split(":")[1];
      return date._d.setHours(Number(hours), Number(minutes));
    }else if(date && !date._d){
    let hours = time.split(":")[0];
    let minutes = time.split(":")[1];
    return date.setHours(Number(hours), Number(minutes));
    }else return null;
  }


  checkForVendorDate(){
    if(this.selReportType == 'Vendor_Management'  && this.listingType != '1')
    if(this.listingType == '2'){
      if(this.filterModel.fromDate && this.filterModel.toDate) return false;
      else return true;
    }else if(this.listingType == '3'){
      if(this.filterModel.fromCompletedDate && this.filterModel.toCompletedDate) return false;
      else return true;
    }

  }

  createTaxNAssessorReport(){
    this.orderService.taxNassessorReport()
    .subscribe((blobData) => {
        this.composeNDownloadReport(blobData);
    }, (err) => {
      this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
    })
  }

  composeNDownloadReport(blobData) {
    let file = new Blob([blobData], { type: 'application/octet-stream' });
    var fileUrl = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("download", this.getFileName('Tax_&_Assessor_Report'));
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getReportDescription(){
    this.orderService.getReportDescriptin()
    .subscribe((reportdata)=>{
      if(reportdata){
        this.reportdata = reportdata;
      }
    },(err)=>{
      this.openErrorPopup(CONSTANTS.apiErrors.datafetch)
    })
  }

  detectTypeChange(){
    this.reportdata.forEach((val)=>{
      if(val.Reports_Name === this.selReportType) {
        this.reportDescript = val.Reports_Description;
        this.reportName = val.Reports_Name;
      }
      })
  }

  getSalesMarketingGrpMembers () {
    this.groupsService.getGroupMembers(environment.salesMarketingGrpId)
      .subscribe((users) => {
        this.marketingGroupMembers = users;
        this.marketingGroupMembers.forEach((member, i) => {
          if(member.User_ID) {
            if(this.auth.getUserId() == member.User_ID) this.isSalesPerson = true;
          }  
        });
      }, (err) => {
        console.log("Error while fetching Group members data:",err);
      })
  }

}

