import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

@Component({
  selector: 'app-internal-notes',
  templateUrl: './internal-notes.component.html',
  styleUrls: ['./internal-notes.component.scss']
})
export class InternalNotesComponent  {
  messageText = '';
  constructor( public dialogRef: MatDialogRef<InternalNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
     
  }

  ngOnInit() {
  }

  confirm(){
     this.dialogRef.close({
      state:true,
      msgText:this.messageText.trim()
    })
  }
  
  closeDialog() {
     this.dialogRef.close(false);
  }
}
