import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'app/services';

@Component({
  selector: 'app-notification-snackbar',
  templateUrl: './notification-snackbar.component.html',
  styleUrls: ['./notification-snackbar.component.scss']
})
export class NotificationSnackbarComponent  {
  contentText : string = '';
  constructor(public snackBarRef: MatSnackBarRef<NotificationSnackbarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any, private router: Router, private auth: AuthService){}

  close(){
    this.snackBarRef.dismiss();
  }

  navigate(){
    if(this.data.orderId) this.router.navigate(['/orders/' + this.data.orderId + '/view']);
    this.close();
  }

}
