
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
// import { HttpService } from '../interceptor/http.service';
import { CONSTANTS } from '../../app.constants';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http'
@Injectable()
export class UsersService {
  direct: HttpClient;
  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private handler: HttpBackend,
    private http: HttpClient
  ) {
    this.direct = new HttpClient(this.handler);
   }

  getAllUsers(searchStr, page, filterBy, orderBy, sortBy) {
    var data = {
      filterBy: filterBy,
      sort:sortBy,
      order:orderBy
    }
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/admins';
    url = url + '/page/' + page;
    if (searchStr) url = url + '/search/' + searchStr
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getDomainName(emailAddress) {
    return new Promise(function(resolve, reject) {
        // do a thing, possibly async, then…
        var emailId = emailAddress.split("@");
        var domainName = emailId[1];
        // var domainName = firstItem.substring(0, firstItem.lastIndexOf("."));
        // var domainName = firstItem.split(".");
        resolve(domainName);
    });
    
  }

  getAllCustomers() {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/customer/all';
    return this.http.post(url, {Admin_ID:this.auth.getUserId()},httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllCustomerForSearcher() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/search/customer/all';
    return this.http.post(url, {Admin_ID:this.auth.getUserId()},httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }  

  getAllAdminsToOrderAssign() {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/admins/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllOrgAdminsToOrderAssign(data) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/org/admins/all';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  } 

  getAdmins(data) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/org/admins/all';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  } 

  getAllavailableClients(inputString) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/clients/all/available';
    return this.direct.post(url,{ searchString: inputString }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getUserInfo(userId) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/profile/admin/' + userId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createUser(model) {
    let dataModel = Object.assign({}, model);
    delete dataModel.Confirm_Email_Address;
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body' //to get full response
    }
    let url = this.config.getBasePath() + '/users/register/admin';
    return this.http.post(url, dataModel, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateUser(model) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/update/admin/info';
    return this.http.put(url, {User:model, User_Modified_By:this.auth.getUserId()}, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  approveUser(model) {
    model.User_Modified_By = this.auth.getUserId();
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/approve/admin/info';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }



  deactivateUser(model) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/user/disable';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  activateUser(model) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/user/enable';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }
  
  readOnlyUsr(model){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/user/readonly';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }
  changeToCompany(model) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/change/tocompany';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  changeToIndividual(model) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/change/toindividual';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }
  
  
  changeCompany(model) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/change/company';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAdminLoginInfo(id){
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/loginAdminHistory/' + id;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

    getAllOrgSearchMasterToOrderAsign(data){
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/org/search/all';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllOrgBackOfficeAgentsToOrderAssign(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/org/backofficeusers/all';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getSearchManagerAndItsTeam(managerId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/manager/'+ managerId+'/team/members';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getSearchMasters(data) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/org/search/all';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getCommonAdminsNSearchers(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/common/admins/searchers';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  } 

  getAdminPanelUsers() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/adminPanel/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOperUserStatus(searchStr, filterBy, orderBy, sortBy) {
    var data = {
      filterBy: filterBy,
      sort:sortBy,
      order:orderBy,
      Is_Manager: this.auth.isSearchManager(),
      Mngr_User_Id: this.auth.isSearchManager() ? this.auth.getUserId(): null
    }
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/oper/status';
    if (searchStr) url = url + '/search/' + searchStr
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllCompanyClientsById(orgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/get/all/clients/organization/' + orgId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  addUserToCompany(data) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/add/to/company';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  } 

  RemoveUserFromCompany(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/remove/from/company';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  
  getInfoToApprove(userId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/approve/info/user/' + userId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }
}
