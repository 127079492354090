// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#message-box textarea {
  text-align: left;
}

.modal-body {
  position: relative;
  overflow-y: auto;
  height: 220;
  padding: 15px;
  text-align: justify;
  font-size: 16px !important;
  background-color: white;
}

.modal-footer {
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
}

.modal-dialog {
  width: 50rem;
}

.modal-content {
  width: 100%;
}

.btn-width {
  width: 148px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/eta-change-reason/eta-change-reason.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,0BAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EAEE,WAAA;AAAF;;AAIA;EACE,YAAA;AADF","sourcesContent":["#message-box textarea {\n  text-align: left;\n}\n\n.modal-body {\n  position: relative;\n  overflow-y: auto;\n  height: 220;\n  padding: 15px;\n  text-align: justify;\n  font-size: 16px !important;\n  background-color: white;\n}\n\n.modal-footer {\n  padding-left: 15px;\n  padding-right: 15px;\n  background-color: white;\n}\n\n.modal-dialog {\n  width: 50rem;\n}\n\n.modal-content {\n \n  width: 100%;\n  \n}\n\n.btn-width {\n  width: 148px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
