import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { PreloaderService } from "../preloader/preloader.service";
import { catchError, finalize, tap } from "rxjs/operators";
import { CookieService } from "ngx-cookie";
import { Router } from "@angular/router";
import { throwError as observableThrowError, Observable } from "rxjs";
import { AuthService } from "../authentication/auth.service";
@Injectable()
export class HttpService implements HttpInterceptor {
  constructor(
    private preloaderService: PreloaderService,
    private cookieService: CookieService,
    private router: Router,
    private auth: AuthService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requestInterceptor();
    return next.handle(request).pipe(
      catchError(this.onCatch),
      tap(
        (ev: HttpEvent<any>) => {
          if (ev instanceof HttpResponse) {
            this.onSubscribeSuccess(ev);
          }
        },
        (error) => {
          this.onSubscribeError(error);
        }
      ),
      finalize(() => this.onFinally())
    );
  }

  /**
   * Request interceptor.
   */
  //private requestInterceptor(preloaderType = 'full'): void {
  private requestInterceptor(): void {
    this.preloaderService.showPreloader();
  }

  /**
   * Error handler.
   * @param error
   * @param caught
   * @returns {ErrorObservable}
   */
  private onCatch(error: any): Observable<any> {
    // console.log('onCatch');
    return observableThrowError(error);
  }

  /**
   * onSubscribeSuccess
   * @param res
   */
  private onSubscribeSuccess(res: any): void {
    // console.log('processing response', res);
  }

  /**
   * onFinally
   */
  // private onFinally(preloaderType = 'full'): void {
  private onFinally(): void {
    this.responseInterceptor();
  }

  /**
   * Response interceptor.
   */
  //private responseInterceptor(preloaderType = 'full'): void {
  private responseInterceptor(): void {
    this.preloaderService.hidePreloader();
  }

  private onSubscribeError(error) {
    if (error.status == 401) {
      this.auth.logout();
      this.router.navigate(["/login"]);
    } else if (error.status > 500 || error.status == 0) {
      if(!this.router.url.match('/serverdown'))
        this.auth.setRedirectURL(this.router.url);
      this.auth.setServerStatus(false);
      this.router.navigate(["/serverdown"]);
    }
  }
}
