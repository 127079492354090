import { Component, OnInit, Input } from '@angular/core';
import { UsermessageService } from '../../services';

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss']
})
export class CopyToClipboardComponent implements OnInit {
  @Input() content: string;

  constructor(
    private userMsg: UsermessageService,
  ) { }

  ngOnInit() {
  }

  afterTextCopy() {
    this.userMsg.setSuccessCopyMsg("Text copied successfully");
  }
}
