import { Component, OnInit } from '@angular/core';
import { ConfigService, GroupsService} from '../../../services'

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
  timer: any = [];
  eventSubscribers: any =[];
  constructor(
    private groupService: GroupsService,
    private config: ConfigService
  ) {}

  ngOnInit() {
    this.getSubscriberlist()
    this.timer.push(setTimeout(() => this.config.setSideBarNode(30), 0))
  }

  getSubscriberlist(){
    this.groupService.getAllEvents()
    .subscribe((events) => {
      if(events && events.length > 0){
        this.groupService.getAllGroupsforEvents(events)
        .subscribe((sub) => {
          events.forEach((event, index) => {
            event.Groups = sub[index]
            this.eventSubscribers.push(event);
          })
        })
      }
    })
  }

}
