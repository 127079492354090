
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../authentication/auth.service';
import { ConfigService } from '../config/config.service';
import { CookieService } from 'ngx-cookie';
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable()
export class AboutService {

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private auth: AuthService,
    private cookies:CookieService
  ) { }


  // getWithMaster(page){
  //   // let headers = new Headers({ 'Authorization': 'Bearer ' + this.config.getAccessToken() });
  //   // let options = new RequestOptions({ headers: headers });
  //   let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.config.getAccessToken() });
  //   return this.http.get(this.config.getBasePath() + '/pages/show/' + page).pipe(
  //   map((response) => response.json(),
  //   (error) => observableThrowError(error)));
  // }

  // getWithToken(page){
  //   let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
  //   let options = new RequestOptions({ headers: headers });

  //   return this.http.get(this.config.getBasePath() + '/pages/get/' + page, options).pipe(
  //   map((response) => response.json(),
  //   (error) => observableThrowError(error)));
  // }

  // getInfoList(){
  //   let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
  //   let options = new RequestOptions({ headers: headers });

  //   return this.http.get(this.config.getBasePath() + '/pages/get/list/all', options).pipe(
  //   map((response) => response.json(),
  //   (error) => observableThrowError(error)));
  // }

  // getPageText(pageId){
  //   let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
  //   let options = new RequestOptions({ headers: headers });

  //   return this.http.get(this.config.getBasePath() + '/pages/getpage/' + pageId, options).pipe(
  //   map((response) => response.json(),
  //   (error) => observableThrowError(error)));
  // }

  // updatePageText(data){
  //   let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
  //   let options = new RequestOptions({ headers: headers });

  //   return this.http.put(this.config.getBasePath() + '/pages/update', data,options).pipe(
  //   map((response) => response,
  //   (error) => observableThrowError(error)));
  // }

  getActiveEULA() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/eula/active/eula';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getEULAByUserId(userId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/client/' + userId + '/eula';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  updateUserEULA(userId, model) {//Pending
    model.access_token = this.config.getAccessToken();
    let url = this.config.getBasePath() + '/users/user/' + userId + '/updateEula';
    return this.http.put(url, model).pipe(
      map((response: any) => {
        if (response && response.token) {
          // create a cookies for newuser 
          this.cookies.put('pippinTitleUsr', JSON.stringify(response));
        }
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

}
