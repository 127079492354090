import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { ViewChild } from '@angular/core';
import * as XLSX from 'xlsx/xlsx';
import { SuccessComponent } from '../../dialog/success/success.component';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { CONSTANTS } from '../../app.constants';
import {  OrdersService, ConfigService, UsermessageService } from '../../services';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelect } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
type AOA = Array<Array<any>>;

@Component({
  selector: 'app-bulk-order',
  templateUrl: './bulk-order.component.html',
  styleUrls: ['./bulk-order.component.scss']
})
export class BulkOrderComponent{
  @ViewChild('myFileInput') selectedFileName: any;  
  message: any = [];
  selectedFile:File;
  selectedCustomer = '';
  xlData = null;
  fileName='';
  inProgress:boolean = false;
  completed: number = 0;

  constructor(
    private orderService: OrdersService,
    private config: ConfigService,
    private userMsg: UsermessageService,
    public dialogRef: MatDialogRef<BulkOrderComponent>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    this.initDialog();
  
  }

  initDialog(){
    this.message = this.data.message;
  }

  fetchDataFromXL(evt) {
    var fileList: FileList = evt.target.files;
    this.selectedFile = evt.target.files[0];
    /* wire up file reader */
    console.log("file",evt.target.files[0])
    if (this.checksize(fileList)) {
      this.fileName=evt.target.files[0].name;
      const target: DataTransfer = (<DataTransfer>(evt.target));
      if (target.files.length != 1) throw new Error("Cannot upload multiple files on the entry");
      const reader = new FileReader();
      var sbComponent = this;
      reader.onload = function (e: any) {
        /* read workbook */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });

        /* get first sheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        /* save data to scope */
        var data:AOA = [['Excel Sheet']];
        data = (<AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 })));
        sbComponent.xlData = sbComponent.pruneArray(data);
      };
      reader.readAsBinaryString(target.files[0]);
    }
    else {
      this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
    }
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(files, (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024);
    if (largeFiles.length > 0) return false;
    else return true;
  }

  pruneArray(dataArray){
    var prunedArray=[];
    dataArray.forEach((item)=>{
      if(item.length) prunedArray.push(item);
    })
    return prunedArray;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  openSuccessPopup(msg) {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: "SUCCESS",
      message: msg
    }, ...this.config.getDialogOptions()});
  }



  uploadData() {    
    if ( this.validateHeader() && this.xlData.length > 1) {
      this.fetchUploadProgress();
      this.inProgress= true;
      this.orderService.createBulk(this.selectedFile,this.selectedCustomer)
        .subscribe((respData) => {
          this.inProgress= false;
          var noOfOrders = respData.orders;
          this.dialogRef.close()
          let msg = noOfOrders + " orders uploaded successfully."
          this.userMsg.setSuccessMsg(msg);
          // this.openSuccessPopup(noOfOrders + " orders uploaded successfully");
        }, (err) => {
          this.inProgress= false;
          this.dialogRef.close();
          this.openErrorPopup("Order upload failed..")
          console.log(err);
        })
    }
    else this.openErrorPopup("File not selected or file has no valid orders");
  }

  validateHeader() {
    return ((this.xlData[0].indexOf("Sl.No") > -1) && (this.xlData[0].indexOf("File #") > -1)
      && (this.xlData[0].indexOf("Names") > -1) && (this.xlData[0].indexOf("Property Address") > -1)
      && (this.xlData[0].indexOf("State") > -1) && (this.xlData[0].indexOf("County") > -1)
      && (this.xlData[0].indexOf("Product Ordered") > -1))
  }

  fetchUploadProgress() {
    this.orderService.bulkProgress
      .subscribe((res) => {
        this.completed = Math.round(((res.loaded / res.total) * 100) * 100) / 100;
      });
  }

}
