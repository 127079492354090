import {
  Component, ContentChild,
  ElementRef,
  EventEmitter, forwardRef,
  Input, OnChanges,
  OnInit,
  Output, Renderer2,
  SimpleChanges, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Keyboard events
 */
const isArrowUp = keyCode => keyCode === 38;
const isArrowDown = keyCode => keyCode === 40;
const isArrowUpDown = keyCode => isArrowUp(keyCode) || isArrowDown(keyCode);
const isEnter = keyCode => keyCode === 13;
const isBackspace = keyCode => keyCode === 8;
const isDelete = keyCode => keyCode === 46;
const isESC = keyCode => keyCode === 27;
const isTab = keyCode => keyCode === 9;


@Component({
  selector: 'ng-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(document:click)': 'handleClick($event)',
    'class': 'ng-autocomplete'
  },
})

export class AutocompleteComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('searchInput') searchInput: ElementRef; // input element
  @ViewChild('filteredListElement') filteredListElement: ElementRef; // element of items

  inputKeyUp$: Observable<any>; // input events
  inputKeyDown$: Observable<any>; // input events

  public query = ''; // search query
  public filteredList = []; // list of items
  public elementRef;
  public selectedIdx = -1;
  public notFound = false;
  public isFocused = false;
  public isOpen = false;
  public isScrollToEnd = false;
  private manualOpen = undefined;
  private manualClose = undefined;
  timer: any = [];


  // inputs
  /**
   * Data of items list.
   * It can be array of strings or array of objects.
   */
  @Input() public data = [];
  @Input() public searchKeywords: string[]; // keyword to filter the list
  @Input() public placeHolder = ''; // input 
  @Input() public initialValue: any; // set initial value  
  @Input() public notFoundText = 'Not found'; // set custom text when filter returns empty result
  @Input() public debounceTime: 400; // delay time while typing
  @Input() public isFirstElement: boolean; // to autofocus
  /**
   * The minimum number of characters the user must type before a search is performed.
   */
  @Input() public minQueryLength = 1;


  // output events
  /** Event that is emitted whenever an item from the list is selected. */
  @Output() selected = new EventEmitter<any>();

  /** Event that is emitted whenever an input is changed. */
  @Output() inputChanged = new EventEmitter<any>();

  /** Event that is emitted whenever an input is focused. */
  @Output() readonly inputFocused: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted whenever an input is cleared. */
  @Output() readonly inputCleared: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted when the autocomplete panel is opened. */
  @Output() readonly opened: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted when the autocomplete panel is closed. */
  @Output() readonly closed: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted when scrolled to the end of items. */
  @Output() readonly scrolledToEnd: EventEmitter<void> = new EventEmitter<void>();


  // custom templates
  // @ContentChild(TemplateRef)
  // @Input() itemTemplate: TemplateRef<any>;
  // @Input() notFoundTemplate: TemplateRef<any>;

  @Input() itemTemplate: TemplateRef<any>;
  @Input() notFoundTemplate: TemplateRef<any>;

  /**
   * Propagates new value when model changes
   */
  propagateChange: any = () => {
  };


  /**
   * Writes a new value from the form model into the view,
   * Updates model
   */
  writeValue(value: any) {
    if (value) {
      this.query = value;
    }
  }

  /**
   * Registers a handler that is called when something in the view has changed
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  /**
   * Registers a handler specifically for when a control receives a touch event
   */
  registerOnTouched(fn: () => void): void {
  }

  /**
   * Event that is called when the value of an input element is changed
   */
  onChange(event) {
    this.propagateChange(event.target.value);
  }

  constructor(elementRef: ElementRef, private renderer: Renderer2) {
    this.elementRef = elementRef;
  }

  ngOnInit() {
  
   
    this.setInitialValue(this.initialValue);
    if(this.isFirstElement) { this.timer.push(setTimeout(()=> { 
      this.searchInput.nativeElement.focus();
      this.isFocused = false;
    }, 0))}

  }

  ngAfterViewInit(): void {
    this.handleScroll();
    this.initEventStream();
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  /**
   * Set initial value
   * @param value
   */
  public setInitialValue(value: any) {
    if (this.initialValue) {
      this.select(value);
    }
  }

  /**
   * Update search results
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.data &&
      Array.isArray(changes.data.currentValue)
    ) {
      this.handleItemsChange();
      if (!changes.data.firstChange && this.isFocused) {
        this.handleOpen();
      }
    }
  }

  /**
   * Items change
   */
  public handleItemsChange() {
    this.isScrollToEnd = false;
    if (!this.isOpen) {
      return;
    }

    this.filteredList = this.data;
  }

  /**
   * Filter data
   */
  public filterList() {
    this.selectedIdx = -1;
    if (this.query != null && this.data) {
      this.filteredList = this.data.filter((item: any) => {
        if (typeof item === 'string') {
          return item.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
        } else if (typeof item === 'object' && item.constructor === Object) {          
          return this.checkItem(item)         
        }
      });
    } else {
      this.notFound = false;
    }
  }

  checkItem(item){
    var matched = false;    
    for(var key in this.searchKeywords){
      var currentKey = this.searchKeywords[key];
      if((item[currentKey] && (item[currentKey].toLowerCase().indexOf(this.query.toLowerCase()) > -1) )){
        matched = true;
        break;
      }
    }
    return matched;
  }


  /**
   * Check type of item in the list.
   * @param item
   */
  isType(item) {
    return typeof item === 'string';
  }

  /**
   * Select item in the list.
   * @param item
   */
  public select(item) {
    this.query = !this.isType(item) ? item[this.searchKeywords[0]] : item;                 //set query ngModel as first key word value in array
    this.isOpen = true;
    this.selected.emit(item);
    this.propagateChange(item);    
    this.handleClose();
  }

  /**
   * Document click
   * @param e event
   */
  public handleClick(e) {
    let clickedComponent = e.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
        if (this.filteredList.length) {
          this.handleOpen();
        }
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (!inside) {
      this.handleClose();
    }
  }

  /**
   * Scroll items
   */
  public handleScroll() {
    this.renderer.listen(this.filteredListElement.nativeElement, 'scroll', () => {
      this.scrollToEnd();
    });
  }

  /**
   * Define panel state
   */
  setPanelState(event) {
    if (event) {
      event.stopPropagation();
    }
    // If controls are untouched
    if (typeof this.manualOpen === 'undefined'
      && typeof this.manualClose === 'undefined') {
      this.isOpen = false;
      this.handleOpen();
    }

    // If one of the controls is untouched and other is deactivated
    if (typeof this.manualOpen === 'undefined'
      && this.manualClose === false
      || typeof this.manualClose === 'undefined'
      && this.manualOpen === false) {
      this.isOpen = false;
      this.handleOpen();
    }

    // if controls are touched but both are deactivated
    if (this.manualOpen === false && this.manualClose === false) {
      this.isOpen = false;
      this.handleOpen();
    }

    // if open control is touched and activated
    if (this.manualOpen) {
      this.isOpen = false;
      this.handleOpen();
      this.manualOpen = false;
    }

    // if close control is touched and activated
    if (this.manualClose) {
      this.isOpen = true;
      this.handleClose();
      this.manualClose = false;
    }
  }

  /**
   * Manual controls
   */
  open() {
    this.manualOpen = true;
    this.isOpen = false;
    this.handleOpen();
  }

  close() {
    this.manualClose = true;
    this.isOpen = true;
    this.handleClose();
  }

  focus() {
    this.handleFocus(event);
  }

  public remove(e) {
    e.stopPropagation();
    this.query = '';
    this.inputCleared.emit();
    this.propagateChange(this.query);
    this.setPanelState(e);
  }

  handleOpen() {
    if (this.isOpen || this.isOpen) {
      return;
    }
    // If data exists
    if (this.data && this.data.length) {
      this.isOpen = true;
      this.filterList();
      this.opened.emit();
    }
  }

  handleClose() {
    if (!this.isOpen) {
      this.isFocused = false;
      return;
    }
    this.isOpen = false;
    this.filteredList = [];
    this.selectedIdx = -1;
    this.notFound = false;
    this.isFocused = false;
    this.closed.emit();
  }

  handleFocus(e) {
    //this.searchInput.nativeElement.focus();
    if (this.isFocused) {
      return;
    }
    this.inputFocused.emit(e);    
    // if data exists then open    
    if (this.data && this.data.length) {
      this.setPanelState(event);
    }
    this.isFocused = true;
  }

  scrollToEnd(): void {
    if (this.isScrollToEnd) {
      return;
    }

    const scrollTop = this.filteredListElement.nativeElement
      .scrollTop;
    const scrollHeight = this.filteredListElement.nativeElement
      .scrollHeight;
    const elementHeight = this.filteredListElement.nativeElement
      .clientHeight;
    const atBottom = scrollHeight === scrollTop + elementHeight;
    if (atBottom) {
      this.scrolledToEnd.emit();
      this.isScrollToEnd = true;
    }
  }

  /**
   * Initialize keyboard events
   */
  initEventStream() {
    this.inputKeyUp$ = fromEvent(
      this.searchInput.nativeElement, 'keyup'
    ).pipe(map(
      (e: any) => e
    ));

    this.inputKeyDown$ = fromEvent(
      this.searchInput.nativeElement,
      'keydown'
    ).pipe(map(
      (e: any) => e
    ));

    this.listenEventStream();
  }

  /**
   * Listen keyboard events
   */
  listenEventStream() {
    // key up event
    this.inputKeyUp$
      .pipe(
        filter(e =>
          !isArrowUpDown(e.keyCode) &&
          !isEnter(e.keyCode) &&
          !isESC(e.keyCode) &&
          !isTab(e.keyCode)),
        debounceTime(this.debounceTime)
      ).subscribe(e => {
        this.onKeyUp(e);
      });

    // cursor up & down
    this.inputKeyDown$.pipe(filter(
      e => isArrowUpDown(e.keyCode)
    )).subscribe(e => {
      e.preventDefault();
      this.onFocusItem(e);
    });

    // enter keyup
    this.inputKeyUp$.pipe(filter(e => isEnter(e.keyCode))).subscribe(e => {
      //this.onHandleEnter();
    });

    // enter keydown
    this.inputKeyDown$.pipe(filter(e => isEnter(e.keyCode))).subscribe(e => {
      this.onHandleEnter();   
    });

    // ESC
    this.inputKeyUp$.pipe(
      filter(e => isESC(e.keyCode),
        debounceTime(100))
    ).subscribe(e => {
      this.onEsc();
    });

    // delete
    this.inputKeyDown$.pipe(
      filter(e => isBackspace(e.keyCode) || isDelete(e.keyCode))
    ).subscribe(e => {
      this.onDelete();
    });
  }

  /**
   * on keyup == when input changed
   * @param e event
   */
  onKeyUp(e) {
    this.notFound = false; // search results are unknown while typing
    // if input is empty
    if (!this.query) {
      this.notFound = false;
      this.inputChanged.emit(e.target.value);
      this.inputCleared.emit();
      //this.filterList();
      this.setPanelState(e);
    }
    // if query >= to minQueryLength
    if (this.query.length >= this.minQueryLength) {
      this.inputChanged.emit(e.target.value);
      this.filterList();

      // If no results found
      if (!this.filteredList.length) {
        this.notFoundText ? this.notFound = true : this.notFound = false;
      }
    }
  }


  /**
   * Keyboard arrow top and arrow bottom
   * @param e event
   */
  onFocusItem(e) {
    // move arrow up and down on filteredList
    const totalNumItem = this.filteredList.length;
    if (e.code === 'ArrowDown') {
      let sum = this.selectedIdx;
      sum = (this.selectedIdx === null) ? 0 : sum + 1;
      this.selectedIdx = (totalNumItem + sum) % totalNumItem;
      this.scrollToFocusedItem(this.selectedIdx);
    } else if (e.code === 'ArrowUp') {
      if (this.selectedIdx == -1) {
        this.selectedIdx = 0;
      }
      this.selectedIdx = (totalNumItem + this.selectedIdx - 1) % totalNumItem;
      this.scrollToFocusedItem(this.selectedIdx);
    }
  }

  /**
   * Scroll to focused item
   * * @param index
   */
  scrollToFocusedItem(index) {
    let listElement = null;
    // Define list element
    listElement = this.filteredListElement.nativeElement;

    const items = Array.prototype.slice.call(listElement.childNodes).filter((node: any) => {
      if (node.nodeType === 1) {
        // if node is element
        return node.className.includes('item');
      } else {
        return false;
      }
    });

    if (!items.length) {
      return;
    }

    const listHeight = listElement.offsetHeight;
    const itemHeight = items[index].offsetHeight;
    const visibleTop = listElement.scrollTop;
    const visibleBottom = listElement.scrollTop + listHeight - itemHeight;
    const targetPosition = items[index].offsetTop;

    if (targetPosition < visibleTop) {
      listElement.scrollTop = targetPosition;
    }

    if (targetPosition > visibleBottom) {
      listElement.scrollTop = targetPosition - listHeight + itemHeight;
    }
  }

  /**
   * Select item on enter click
   */
  onHandleEnter() {
    // click enter to choose item from filteredList
    if (this.selectedIdx > -1) {
      this.query = !this.isType(this.filteredList[this.selectedIdx])
        ? this.filteredList[this.selectedIdx][this.searchKeywords[0]]                          //set query ngModel as first key word value in array
        : this.filteredList[this.selectedIdx];
      this.select(this.filteredList[this.selectedIdx]);
    }
    this.handleClose();
  }

  /**
   * Esc click
   */
  onEsc() {
    this.searchInput.nativeElement.blur();
    this.handleClose();
  }

  /**
   * Delete click
   */
  onDelete() {
    // panel is open on delete
    this.isOpen = true;
  }
}

