import { Component, Inject, ViewChild } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { OrdersService, QuickbooksService, FileService, ConfigService } from '../../services';
import { CONSTANTS } from 'app/app.constants';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: "app-review-complete-package",
  templateUrl: "./review-complete-package.component.html",
  styleUrls: ["./review-complete-package.component.scss"],
})
export class ReviewCompletePackageComponent  {
  title = '';
  message: any;
  adminDocs: any = [];
  client_mail_text: string = "";
  errorMsg: string = "";
  fileURL: SafeResourceUrl;
  isDisabled: boolean = true;
  resourceUrls = [];
  orderCheckList = [];
  checkListObj = {};
  openBillingPopup: boolean = false;
  timer: any = [];
  zipCodeCtrl = new UntypedFormControl(null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern(/^[0-9]*$/)]));
  noOfCommentsCtrl = new UntypedFormControl(null, Validators.compose([Validators.required, Validators.minLength(2),Validators.maxLength(2), Validators.pattern(/^[0-9]*$/)]));
  textCtrl = new UntypedFormControl(null, Validators.compose([Validators.required]));
  noOfReportsCtrl = new UntypedFormControl(null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(2), Validators.pattern(/^[0-9]*$/)]));
  constants = CONSTANTS;
  tinyOptions: any = {
    auto_focus: false,
    branding:false,
    promotion :false,
    skin:'oxide',
    menubar:false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"],
    toolbar:
      "undo redo | styleselect | forecolor backcolor |  bold italic |  alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
  };
  @ViewChild('tinymce') tinymce;
  constructor(
    private ordersService: OrdersService,
    private quickbooks: QuickbooksService,
    private fileService: FileService,
    private config: ConfigService,
    private matDialog:MatDialog,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ReviewCompletePackageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    
  }
  ngOnInit() {
    this.initDialog();
    this.timer.push(
      setTimeout(() => {
        this.ordersService
          .getOrderDocuments(this["message"].Order.Order_ID)
          .subscribe((docs) => {
            this.adminDocs = docs.filter(
              (doc) =>
                doc.Document_Type == CONSTANTS.orderDocumentType.adminDocs
            );
          });
        if (
          this.ordersService.getCompleteOrderData() &&
          this.ordersService.getCompleteOrderData().Invoice_Update
        )
          this.showInvoice();
        this.ordersService
          .getOrderDocumentUrls(this["message"].Order.Order_ID)
          .subscribe((urls) => {
            this.resourceUrls = urls;
            this.constructMessage();
          });
        // this.tinymce.editor.readonly = true;
      }, 0)
    );
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  showInvoice() {
    this.quickbooks
      .downloadOrderInvoice(
        this["message"].Order.Order_ID,
        this.ordersService.getCompleteOrderData(),
        "review"
      )
      .subscribe(
        (blobParts: any) => {
          if (blobParts) {
            var blobData = new Blob([blobParts], { type: "application/pdf" });
            this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(
              URL.createObjectURL(blobData)
            );
          }
        },
        (err) => {
          if (err.code) this.errorMsg = err.msg;
          else this.errorMsg = CONSTANTS.apiErrors.quickbookAuth;
        }
      );
  }
  constructMessage() {
    this.client_mail_text =
      "<br>" +
      "<p>Your order has been fulfilled. Relevant document links are attached. Please click to download.</p>";
    this.resourceUrls.forEach((url) => {
      if (
        (this.ordersService.getCompleteOrderData() &&
          !this.ordersService.getCompleteOrderData().Invoice_Details) ||
        url.Order_Document_ID !=
          this.ordersService.getCompleteOrderData().Invoice_Details
            .Quickbooks_Invoice_Order_Document_ID
      )
        this.client_mail_text +=
          '<a href="' +
          url.Link +
          '">' +
          url.Document_Original_Name +
          "</a>" +
          "<br>";
    });
    if (
      this.ordersService.getCompleteOrderData() &&
      this.ordersService.getCompleteOrderData().comment.length > 0
    ) {
      this.client_mail_text +=
        "<p>" + this.ordersService.getCompleteOrderData().comment + "</p><br>";
    }
    this.client_mail_text +=
      "<p>You can also find your order on the Pippin Title Services website after logging in.<br>Thank you for your business! We are happy to assist you with your additional title needs.</p><br>";
  }
  goBack() {
    this.dialogRef.close({
      state: false
    })
    this.clearTime();
  }
  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    this.clearTime();
  }
  completeOrder() {
    this.errorMsg = "";
    if (this.ordersService.getCompleteOrderData()) {
      this.dialogRef.close({
        state: true,
        mail_text: this.client_mail_text,
        invoice: this.ordersService.getCompleteOrderData().Invoice_Details,
        comment: this.ordersService.getCompleteOrderData().comment,
        Invoice_Update:
          this.ordersService.getCompleteOrderData().Invoice_Update,
        Open_Billing_Popup: this.openBillingPopup,
        checkListData: this.orderCheckList
      });
    }
    this.clearTime();
    this.ordersService.resetCompleteOrderData();
  }

  clearTime(){
    // this.close();
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  downloadFile(filename, orderDocId) {
    this.fileService.download(orderDocId).subscribe(
      (userFile) => {
        if (userFile) {
          var link = document.createElement("a");
          document.body.appendChild(link);
          link.download = filename;
          link.href = userFile.Link;
          link.click();
          document.body.removeChild(link);
        }
      },
      (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
      }
    );
  }
  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }
}
