import { Directive, HostListener,Output, EventEmitter} from '@angular/core';
import { ConfigService } from '../../services';

@Directive({
  selector: '[appScrollTop]'
})
export class ScrollTopDirective { 
  @Output() private topFlag = new EventEmitter<boolean>();
  constructor(
    private config: ConfigService,
  ) { }

  @HostListener('window:scroll')
  setTopFlag(){  
    if (document.documentElement.scrollTop < this.config.getScrollYThreshold()) this.topFlag.emit(true);
    else this.topFlag.emit(false);

  }
}
