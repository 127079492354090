import { Component, OnInit } from '@angular/core';
import { OrdersService, StateService, UsermessageService, ConfigService, ClientsService } from '../../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorDialogComponent } from '../../../dialog/error-dialog/error-dialog.component';
import { SuccessComponent } from '../../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-table-editable',
  templateUrl: './table-editable.component.html',
  styleUrls: ['./table-editable.component.scss']
})
export class TableEditableComponent implements OnInit {
  orders = [];
  editField: string;
  headers = [];
  products: any;
  Order_Source_Type_ID = null;
  Order_Mode_Type_ID = 1;
  Order_Qualia_Internal_ID = null;
  constructor(
    private stateService: StateService,
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrdersService,
    private userMsg: UsermessageService,
    private matDialog:MatDialog,
    private config: ConfigService,
    private clientsService: ClientsService,
  ) { }

  ngOnInit() {
    if (this.stateService.getBulkOrdData()) {
      let stateData = this.stateService.getBulkOrdData();
      this.orders = stateData.Orders;
      this.headers = stateData.Headers;
      this.Order_Source_Type_ID = stateData.Order_Source_Type_ID;
      this.Order_Mode_Type_ID = stateData.Order_Mode_Type_ID;
      this.Order_Qualia_Internal_ID = stateData.Order_Qualia_Internal_ID;
    }

  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    this.orders[id][property] = editField;
  }

  remove(id: any) {
    this.orders.splice(id, 1);
  }

  goBack() {
    this.router.navigate(['upload-types']);
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      title: 'Failed orders List',
      message: msg,
      bulkUplodErr: true
    }, ...this.config.getDialogOptions()});
  }

  openSuccessPopup(msg) {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: "Success",
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  createBulkOrders() {
      this.orderService.uploadBulkOrders(this.orders, this.route.snapshot.params['id'], this.route.snapshot.params['orgId'], this.Order_Source_Type_ID,this.Order_Mode_Type_ID,this.Order_Qualia_Internal_ID )
      .subscribe((respData) => {
        if (respData.failedOrdsLen > 0) {
          this.openErrorPopup(respData.failedOrdsMsg);
          this.router.navigate(['upload-types']);
        } else {
          let msg = "All " + respData.successOrdsLen + " order(s) uploaded successfully."
          this.openSuccessPopup(msg);
          this.stateService.setUploadTypesData(null);
          this.router.navigate(['orders/new']);
        }
      }, (err) => {
        this.openErrorPopup("Order upload failed..")
      })
  }

}
