import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services';

@Component({
  selector: 'app-hubspot-oauth',
  templateUrl: './hubspot-oauth.component.html',
  styleUrls: ['./hubspot-oauth.component.scss']
})
export class HubspotOauthComponent implements OnInit {

  constructor(
    private config: ConfigService,
  ) { }

  ngOnInit() {
    console.log("Console on ngOnit===========");
    this.config.setSideBarNode(0);
  }

}
