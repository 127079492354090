import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService, OrganizationService, ConfigService, UsermessageService, GroupsService, UsersService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { Router } from '@angular/router';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  newGrpFrm: UntypedFormGroup;
  error: string = '';
  errorMsg: any = {};
  newGrpModel: any = {};
  users: any = [];
  timer: any = [];

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private matDialog:MatDialog,
    private router: Router,
    private config: ConfigService,
    private userMsg: UsermessageService,
    public groupsService: GroupsService,
    private userService: UsersService,
  ) {
    this.newGrpFrm = fb.group({
      'Group_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'Group_Description': [null, Validators.compose([Validators.maxLength(225)])],
      'Group_Members': [null],
    })
    this.newGrpFrm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    this.getAdminPanelUsers();
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();
    }, 0))
  }
  
  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  getAdminPanelUsers() {
    this.userService.getAdminPanelUsers()
      .subscribe((allUsers) => {
        this.users = allUsers;
      }, (err) => {
        this.openErrorPopup("Error while fetching data.");
      })
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => { });
  }

  selectAllUsers(ev) {
    if (ev._selected) {
      let selectAllUsers = [];
      this.users.filter(x => {
        selectAllUsers.push(x.User_ID);
      });
      this.newGrpFrm.controls['Group_Members'].setValue(selectAllUsers)
      ev._selected = true;
    }
    if (ev._selected == false) {
      this.newGrpFrm.controls['Group_Members'].setValue([]);
    }
  }

  createGroup() {
    this.errorMsg.msg = '';
    this.newGrpModel.Admin_ID = this.auth.getUserId();
    this.groupsService.createGroup(this.newGrpModel)
      .subscribe((res) => {
        let msg = "Group created successfully.";
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/groups/list']);
      }, (err) => {
        this.errorMsg = err;
      });
  }

}
