import { Injectable } from '@angular/core';

@Injectable()

export class NotificationsService {
  private count:number = 0;
  private notifications :any = [];
  constructor(
  ) { }

  setCount(count){
    this.count = count? count: 0;
  }

  getCount(){
    return this.count;
  }

  setNotifications(notifications){
    this.notifications = notifications? notifications: [];
  }

  getNotifications(){
    return this.notifications;
  }

  resetNotificationsAndCount(){
    this.notifications = [];
    this.count = 0;
  }
}
