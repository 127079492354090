import { Component, OnDestroy, OnInit } from "@angular/core";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-chk-identification",
  templateUrl: "./chk-identification.component.html",
  styleUrls: ["./chk-identification.component.scss"],
})
export class ChkIdentificationComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  orderDetails: any;
  basicReportData: any;
  searchParties: any;
  seller: any;
  borrower: any;
  errorMsg: any;

  constructor(private builder: RptBuilderService) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) this.basicReportData = data.curData;
      });
    this.builder.searchParties$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) this.populateDetails(data.curData);
      });
  }

  populateDetails(data) {
    this.searchParties = data;
    this.borrower = "";
    this.seller = "";
    let foundCurrentOwner = false;
    this.searchParties.forEach((party) => {
      // Sellers
      if ([1, 3].includes(party.Entity_ID)) {
        if (!foundCurrentOwner) {
          if (party.Is_Current_Owner) {
            foundCurrentOwner = true;
            this.seller = "";
          }
          if (this.seller == "") this.seller += this.getPartyName(party);
        } else if (party.Is_Current_Owner) {
          if (this.seller !== "") this.seller += ", ";
          this.seller += this.getPartyName(party);
        }
      }
      // Buyers
      if ([2, 4].includes(party.Entity_ID)) {
        if (this.borrower !== "") this.borrower += ", ";
        this.borrower += this.getPartyName(party);
      }
    });
  }

  getPartyName(party) {
    let fullName = "";
    if (party.First_Business_Name)
      fullName += party.First_Business_Name;
    if (party.Middle_Name)
      fullName += ` ${party.Middle_Name}`;
    if (party.Last_Name) fullName += ` ${party.Last_Name}`;
    return fullName;
  }
}
