import { Component, OnInit, ViewChildren, QueryList, ElementRef, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { Location, DatePipe } from '@angular/common';
import { DialogService } from "ng2-bootstrap-modal";
import { CONSTANTS } from '../../app.constants'
import { AuthService, NewOrderService, OrdersService, PricingService, ConfigService, ProductService, SchedulingService, UsermessageService } from '../../services';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { constants } from 'fs';
import { EtaAlertComponent } from '../../dialog/eta-alert/eta-alert.component';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import { MatSelect } from '@angular/material/select';
import { FormControl, Validators , UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import { DuedateComponent } from '../../common/duedate/duedate.component';
import { ConfirmComponent } from 'app/dialog/confirm/confirm.component';
import { EtaChangeReasonComponent } from 'app/dialog/eta-change-reason/eta-change-reason.component';
import { ErrorReportComponent } from '../../dialog/error-report/error-report.component';
import { environment } from 'environments/environment';
import * as _ from 'underscore';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-order-accept',
  templateUrl: './order-accept.component.html',
  styleUrls: ['./order-accept.component.scss'],
  providers: [DatePipe]
})
export class OrderAcceptComponent implements OnInit {
  @ViewChildren('myFirstFocus') myFirstFocus: QueryList<MatSelect>
  @ViewChildren('subProductFocus') subProductFocus: QueryList<ElementRef>
  @ViewChild('dueDatePicker') dueDatePicker: DuedateComponent;
  orderDetails: any = {}
  subOrderDetails:any = {};
  orderProducts: any = [];
  products: any = [];
  allProducts: any = [];
  allsubNBaseProducts=[];
  updateProducts=[];
  counties: any = [];
  selectedCounty = '';
  states: any = [];
  selectedState = '';
  defaultproducts = [];
  getUSDateString = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
  minDate = new Date(this.getUSDateString);
  completeAddress: string = '';
  grandTotPrice = 0;
  orderIns: any = {};
  errors: any = {};
  constants = CONSTANTS;
  showErrMsg = '';
  dotEvent = [];
  focused: boolean = false;
  subProductFocusFlag: boolean = false;
  input = '';
  order_ETA_Time: string = "17:00";
  isDueRangeErr: boolean = false;
  dueDateFrmObj: any;
  dueDateTimeObj: any;
  dueDate_Range: any;
  dueDateRangeOption: Number
  order_ETA_Date: any;
  isDueDateRange:boolean = false;
  timer: any = [];
  isPricingFlag: boolean = false;
  holidayList: any;
  copyOrderDetails: any;
  weekendHolidayName: String;
  allOrderStatus: any;
  getOnLoadTotalPrice = 0;
  oldProds: any = [];
  reason ='';
  additionalProduct =[];
  additionProdArr =[];
  newOrderForm: UntypedFormGroup;
  feeApproval: boolean = false;
  yearsRequired : any;
  dataDepthYears:any;
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private orderService: OrdersService,
    private newOrder: NewOrderService,
    private pricingService: PricingService,
    private matDialog:MatDialog,
    private _datePipe: DatePipe,
    private config: ConfigService,
    private productService: ProductService,
    private holidayScheduling:SchedulingService,
    private frmBuilder: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    private userMsg: UsermessageService,
  ) {
    this.newOrderForm = frmBuilder.group({
      Order_ETA: [{ disabled: false, value: '' }],
    })

   }

  ngOnInit() {
    this.getOrderDetails(this.route.snapshot.params['orderId']);
    this.getAllOrderStatus(this.route.snapshot.params['orderId'])
    this.getholidaylistFromLocal();
    // this.getholidaylist();
    // this.getAvailableStateLocal();
    this.pricingService.getAvailableStateList().subscribe((states) => this.states = states);
    this.newOrder.getSubProductList().subscribe((res) => this.allProducts = res);
    this.orderService.getOrderNotes(this.route.snapshot.params['orderId'])
      .subscribe((notes) => {
        notes.forEach(note => {
          if (note.Instruction_Type == CONSTANTS.instructionType.orderIns) this.orderIns = note;
        })
      }, (err) => {
        console.log(err);
      })
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
//   getAvailableStateLocal(){
//     let Allstates = JSON.parse(sessionStorage.getItem('AllStateAvailable'));
//     if(Allstates) this.setState(Allstates)         
//     else this.getAvailableStateList();
// }
  
//   getAvailableStateList(){
//     this.pricingService.getAvailableStateList().subscribe((states) => { 
//       if(states){
//         this.setState(states)         
//         sessionStorage.setItem('AllStateAvailable',JSON.stringify(states)); 
//        setTimeout(function(){sessionStorage.removeItem("AllStateAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });

//   }

//   setState(AllstatesData){
//     if(AllstatesData) this.states = AllstatesData;
//   }
  ngAfterViewInit() {
    this.myFirstFocus.changes.subscribe((select) => {
      if (select.first && !this.focused) {
        this.focused = true;
        this.timer.push(setTimeout(() => { select.first.focus() }, 0));
      }
    });
    this.subProductFocus.changes.subscribe((input) => {
      if (input.last && !this.subProductFocusFlag) {
        this.subProductFocusFlag = true;
        this.timer.push(setTimeout(() => { input.last.nativeElement.focus() }, 0));
      }
    });
  }

  getOrderDetails(orderId) {
    this.orderService.getOrderDetails(orderId)
      .subscribe((details) => {
        this.orderDetails = details;
        this.copyOrderDetails = Object.assign({}, details); 
        if (this.orderDetails.Order_Status != CONSTANTS.orderStatus.assigned) {
          // this.router.navigate(['/orders/' + this.route.snapshot.params['orderId'] + '/view'])
          this.router.navigate(['/orders/new'])
        }
        if (!this.orderDetails.Property_State_Abbr) this.orderDetails.Property_State_Abbr = '';
        if (!this.orderDetails.Property_County) this.orderDetails.Property_County = '';
        if (details.Order_ETA) this.getAvlOrderETAnTime();
        // this.orderDetails.Order_ETA = new Date(momentTimeZone.tz(this.orderDetails.Order_ETA, 'America/New_York').format('MM/DD/YYYY HH:mm'));
        this.getOrderProducts(orderId);
        this.buildAddress();
        this.getDataDepthInfo();
        //this.resetMDFields();
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        // this.auth.logout();
        // this.router.navigate(['/login']);
      });
  }

    getOrderProductsForClient(orderDetails , orderProducts) {
      let userId; 
      if(orderDetails.Organization_ID) userId = orderDetails.Organization_ID
      else userId = orderDetails.Customer_ID;
      this.orderService.getOrderProductsForClient(userId)
      .subscribe((products) => {
        if(products.some((product) => product.Product_ID == orderDetails.Product_ID && product.Product_Description === orderDetails.Product_Description)) this.products = products;
        else if(products.some((product) => product.Product_ID == orderProducts[0].Product_ID && product.Product_Description !==  orderProducts[0].Product_Description)){
          // let clonedArray = JSON.parse(JSON.stringify(products));
          // let productFindIndex = products.findIndex((product) => product.Product_ID == orderProducts[0].Product_ID)
          // products[productFindIndex].Product_Description = orderProducts[0].Product_Description;
          this.products = products;
          // let clonedFindIndex = clonedArray.findIndex((product) => product.Product_ID === orderProducts[0].Product_ID);
          // this.products.splice(clonedFindIndex+1, 0, clonedArray[clonedFindIndex]);
        } else {
          this.productService.getProduct(orderDetails.Product_ID)
          .subscribe((missedProducts) => {
            // products.push(missedProducts);
            this.products = products;
          })
        }
        this.getBaseProduct().forEach((orderProduct) => {
          orderProduct.isUpdated = this.checkIfOrderProductUpdatedBase(orderProduct);
          if(orderProduct.isUpdated){
            let randomID = this.randomIntFromInterval(6666, 7777);
            orderProduct.Old_Product_ID = orderProduct.Product_ID;
            orderProduct.Product_ID = randomID;
            orderProduct.Copy_Product_ID = randomID;
          }        
        })
      })
  }

  getAvlOrderETAnTime() {
    let etaFullDate = new Date(momentTimeZone.tz(this.orderDetails.Order_ETA, 'America/New_York').format('MM/DD/YYYY HH:mm'));
    let date = new Date(etaFullDate.toDateString());
    this.orderDetails.Order_ETA = date;
    let etaHours = etaFullDate.getHours().toString();
    let etaMinutes = etaFullDate.getMinutes().toString();
    if (etaHours.length == 1) etaHours = "0" + etaHours;
    if (etaMinutes.length == 1) etaMinutes = "0" + etaMinutes;
    this.order_ETA_Time = etaHours + ":" + etaMinutes;
    // if (this.orderDetails.Order_ETA) this.orderETAtime.enable();
  }

  getOrderProducts(orderId) {
    if (!this.orderDetails.Order_Custom_Price_Tax) this.orderDetails.Order_Custom_Price_Tax = 0;
    this.orderService.getCustomerOrderProducts(orderId)
      .subscribe((products) => {
        this.orderProducts = products;
        this.oldProds = products.map((_arrayElement) => Object.assign({}, _arrayElement));  
        this.getTotalPriceOnLoad(products,this.orderDetails)
        this.getOrderProductsForClient(this.orderDetails, this.getBaseProduct());        
        this.getAllsubNBaseProd(this.route.snapshot.params['orderId']);
        this.orderProducts.map((newProd) => newProd.currInput = '');
        this.orderProducts.map((newProd) => newProd.currBaseInput = '');
        //this.addCopyCharges();
        this.addDefaultsToOrderProducts(this.getDefaultProducts());
        this.calculateTotal();
      }, (err) => {
        console.log(err);
      })
  }

  buildAddress() {
    this.completeAddress = '';
    if (this.orderDetails.Property_Address_1) this.completeAddress += this.orderDetails.Property_Address_1
    if (this.orderDetails.Property_Address_2) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += this.orderDetails.Property_Address_2
    }
    if (this.orderDetails.Property_City) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += this.orderDetails.Property_City
    }
    if (this.orderDetails.Property_State_Abbr) {
      if (this.completeAddress) this.completeAddress += ', '
      this.completeAddress += this.orderDetails.Property_State_Abbr
    }
    if (this.orderDetails.Property_ZipCode) {
      if (this.completeAddress) this.completeAddress += ' '
      this.completeAddress += this.orderDetails.Property_ZipCode
    }
  }

  resetMDFields() {
    document.getElementById('Order_Number').parentElement.classList.remove('is-empty');
    document.getElementById('Orig_Total_Price').parentElement.classList.remove('is-empty');
    document.getElementById('Total_Price').parentElement.classList.remove('is-empty');
    document.getElementById('Total_Tax_Price').parentElement.classList.remove('is-empty');
    // if (this.orderDetails.Order_ETA) document.getElementById('Order_ETA').parentElement.classList.remove('is-empty');
  }

  resetPriceFields(fieldId) {
    if (document.getElementById('Product_Price_' + fieldId)) {
      document.getElementById('Product_Price_' + fieldId).parentElement.classList.remove('is-empty');
      document.getElementById('Final_Price_' + fieldId).parentElement.classList.remove('is-empty');
    }
  }

  getCountyList(event) {
    if (event.length != 0) {
      this.orderDetails.Property_County = '';
      this.orderDetails.BaseProduct = 0;
      this.pricingService.getAvailableCountyList(this.orderDetails.Property_State_Abbr).subscribe((res) => this.counties = res)
    }
  }

  getBaseProduct() {
    if (this.orderProducts.length) return this.orderProducts.filter((product) => product.Order_Product_Status == CONSTANTS.selectProductStatus.baseProduct)
    return [];
  }

  getSubProducts() {
    if (this.orderProducts.length) return this.orderProducts.filter((product) => product.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct);
    return [];
  }

  getDefaultProducts() {
    if (this.orderProducts.length) return this.orderProducts.filter((product) => product.Product_ID == environment.copyChargeProductId);
    return [];
  }

  handleManualProductChange(event, orderProduct){
    if(event) {
      orderProduct.Product_ID = event.value.Product_ID;
      orderProduct.Order_Product_Custom_Input_Description = event.value.Product_Description;
      this.getProductPricingDetails(event.value.Product_ID, orderProduct, event.value.Product_Description)
    }
  }

  getProductPricingDetails(event, product, productDescription = null) {
    if (!product) var product = this.orderProducts[0];
    else product.Product_ID = event;
    if (this.orderDetails.Property_County && this.orderDetails.Property_State_Abbr && product.Product_ID) {
      let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID : this.orderDetails.Customer_ID;
      this.pricingService.getCustomAndOrginalPriceDetails(this.orderDetails.Property_State_Abbr,
        this.orderDetails.Property_County, product.Product_ID, customerId)
        .subscribe((data) => {
          product.Order_Product_Original_Price = 0;
          product.Order_Product_Price = 0;
          if (data.Original_Cost) {
            product.Order_Product_Original_Price = data.Original_Cost.Cost_Amount;
            product.Order_Product_Price = data.Original_Cost.Cost_Amount;
            if (data.Custom_Cost) product.Order_Product_Price = data.Custom_Cost.Custom_Cost_Amount;
          }
          this.resetProductPrice(product)
          this.calculateTotal();
          if(productDescription) this.updateProductDescription(product, productDescription)
          this.resetPriceFields(product.Order_Product_ID);
        })
    }
  }

  updateProductDescription(orderProduct, productDescription) {
    if(this.copyOrderDetails.Product_Description !== productDescription) {
      let productFindIndex = this.products.findIndex((product) => product.Product_ID === orderProduct.Product_ID);
      orderProduct.Product_Description = productDescription;
      orderProduct.Product_Name = this.products[productFindIndex].Product_Name;
    } else {
      let productFindIndex = this.products.findIndex((product) => product.Product_ID === orderProduct.Product_ID);
      orderProduct.Product_Description = this.copyOrderDetails.Product_Description;
      orderProduct.Product_Name = this.products[productFindIndex].Product_Name;
    }
  }

  resetProductPrice(product) {
    if (product.currInput)
      product.currInput = ''
  }
  // getOrderNotes(orderId) {
  //   this.orderService.getOrderNotes(orderId)
  //     .subscribe((notes) => {
  //       console.log("notes", notes);
  //       notes.forEach(note => {
  //         // if (note.Instruction_Type == CONSTANTS.instructionType.orderNotes) this.orderNotes = note;
  //         if (note.Instruction_Type == CONSTANTS.instructionType.orderIns) this.orderIns = note;
  //       })
  //     }, (err) => {
  //       console.log(err);
  //     })
  // }

  removeSubProduct(product) {
    //this.orderProducts.splice(this.orderProducts.indexOf(product), 1);
    product.Order_Product_Flag = CONSTANTS.orderProductFlag.inActive;
    product.Order_Product_Price = 0;
    this.removeByOrderProductId(product.Order_Product_ID);
    this.calculateTotal();
    this.removeDeletedfieldEvent(product);
    this.assignSubProductFocus();
  }



  removeByOrderProductId(orderProductId) {
    var i = this.orderProducts.length;
    while (i--) {
      if (this.orderProducts[i]
        && this.orderProducts[i].hasOwnProperty('Order_Product_ID')
        && (this.orderProducts[i]['Order_Product_ID'] === orderProductId)) {
          this.additionalProduct.push(this.orderProducts[i])
          this.orderProducts.splice(i, 1);
      }
    }
    this.calculateTotal();
    return this.orderProducts;
  }
  removeDeletedfieldEvent(orderProduct) {
    var orderProductId = orderProduct.Order_Product_ID
    var finalpriceFieldId = "Final_Price_" + orderProductId;
    var matchedFinalEventIndex = this.dotEvent.findIndex((eventItem) => eventItem.ID == finalpriceFieldId);
    if (matchedFinalEventIndex > -1) this.dotEvent.splice(matchedFinalEventIndex, 1);
    if (orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
      var basepriceFieldId = "OrdAcceptInpBasePrc" + orderProductId;
      var matchedBaseEventIndex = this.dotEvent.findIndex((eventItem) => eventItem.ID == basepriceFieldId);
      if (matchedBaseEventIndex > -1) this.dotEvent.splice(matchedBaseEventIndex, 1);
    }
  }

  calculateTotal() {
    this.orderDetails.Order_Final_Price = 0;
    this.orderDetails.Order_Custom_Price = 0;
    this.orderProducts.forEach((product) => {
      if (product.Order_Product_Flag == CONSTANTS.orderProductFlag.active) {
        if (!isNaN(Number(product.Order_Product_Original_Price)))
          this.orderDetails.Order_Final_Price = Number((Number(this.orderDetails.Order_Final_Price) + Number(product.Order_Product_Original_Price)).toFixed(2));
        else this.orderDetails.Order_Final_Price = Number(this.orderDetails.Order_Final_Price.toFixed(2));
        if (!isNaN(Number(product.Order_Product_Price)))
          this.orderDetails.Order_Custom_Price = Number((Number(this.orderDetails.Order_Custom_Price) + Number(product.Order_Product_Price)).toFixed(2));
        else this.orderDetails.Order_Custom_Price = Number(this.orderDetails.Order_Custom_Price.toFixed(2));
      }
    })
    this.calculateGrantTotal();
  }

  validatePrice() {
    if (this.orderProducts) {
      if (this.orderProducts.findIndex(orderProduct => (((orderProduct.Product_ID == '') ||
        isNaN(parseInt(orderProduct.Order_Product_Price)))
        && ((orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct)
          || (orderProduct.Product_ID == environment.copyChargeProductId))
        && (orderProduct.Order_Product_Flag == CONSTANTS.orderProductFlag.active))) > -1) return true;
      else return false;
    }
    this.ref.detectChanges();
  }

  validateSearchDesc() {
    if (this.orderProducts) {
      if (this.orderProducts.findIndex(orderProduct => (orderProduct.Product_ID == 999) && ((orderProduct.Order_Product_Custom_Name == undefined) || (orderProduct.Order_Product_Custom_Name == '')))) return true;
      else return false;
    }
    this.ref.detectChanges();
  }

  validateFinalPricingFields() {
    if (!this.grandTotPrice || (this.orderDetails.Order_Custom_Price_Tax != 0 && (this.orderDetails.Order_Custom_Price_Tax == null ||
      this.orderDetails.Order_Custom_Price_Tax == undefined
      || this.orderDetails.Order_Custom_Price_Tax == ''))
      || !this.validateProductPricingFields())
      return false;
    else return true;
  }

  validateOrderETADateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy
    // if (event.value) this.orderETAtime.enable();
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors.Order_ETA = true;
    else delete this.errors.Order_ETA;
  }

  getIfErrors() {
    return Object.keys(this.errors).length;
  }

  getOrderEta() {
    if (this.orderDetails.Order_ETA) return 1;
    else return 0;
  }

  alertMsgForAcceptOrder() {
    // if (this.grandTotPrice || this.orderDetails.Order_ETA && (this.grandTotPrice && this.orderDetails.Order_ETA)) return 1;
    if (this.grandTotPrice != undefined && this.orderDetails.Order_ETA) return 1;
    else return 0;
  }

  validateProductPricingFields() {
    // var flag = 0;
    // if (this.orderProducts.length) {
    //   this.orderProducts.forEach((orderProduct) => {
    //     if (orderProduct.Order_Product_Price != 0 && (orderProduct.Order_Product_Price == null ||
    //       orderProduct.Order_Product_Price == undefined || orderProduct.Order_Product_Price == "")) flag++;
    //   })
    // }    
    // if (flag == 0) return true;
    // else return false;    
    if (this.orderProducts.findIndex(orderProduct => isNaN(parseInt(orderProduct.Order_Product_Price)) && orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.baseProduct) > -1) return false;
    else return true;
  }

  calculateGrantTotal() {
    if (!isNaN(Number(this.orderDetails.Order_Custom_Price_Tax)))
      this.grandTotPrice = Number((Number(this.orderDetails.Order_Custom_Price) + Number(this.orderDetails.Order_Custom_Price_Tax)).toFixed(2));
    else this.grandTotPrice = Number(this.orderDetails.Order_Custom_Price.toFixed(2));

  }

  validateGrandTotal(){
    if(this.grandTotPrice<0) return false;
    return true;
  }

  addproduct() {
    var newProd: any = {};
    newProd.Order_Product_ID = 'new' + UUID.UUID();
    newProd.Order_ID = this.orderDetails.Order_ID;
    newProd.Order_Product_Custom_Input_Description = '';
    newProd.Order_Product_Custom_Name = '';
    newProd.Order_Product_Original_Price = null;
    newProd.Order_Product_Price = "0.00";
    newProd.currInput = '';
    newProd.currBaseInput = '';
    newProd.Order_Product_Flag = CONSTANTS.orderProductFlag.active;
    newProd.Order_Product_Status = CONSTANTS.selectProductStatus.subproduct;
    this.orderProducts.push(newProd);
    this.calculateTotal();
    this.assignSubProductFocus();
  }

  addCopyCharges() {
    if (this.orderProducts.findIndex(x => x.Product_ID == 7) == -1) {
      var newProd: any = {};
      newProd.Order_Product_ID = 'new' + UUID.UUID();
      newProd.Order_ID = this.orderDetails.Order_ID;
      // hardcoded for copy charges
      newProd.Product_ID = 7;
      newProd.Order_Product_Custom_Name = '';
      newProd.Order_Product_Status = 2;
      newProd.Order_Product_Price = 0;
      this.orderProducts.push(newProd);
      this.calculateTotal();
    } else {
      console.log("Not adding");
    }
  }

  backToListing() {
    this.location.back();
  }

  // checkWestFieldBankPriceCap(){
  //   if(this.orderDetails.Organization_ID == environment.westFieldBank){
  //     if(Number(this.auth.getUserRole()) == this.constants.userRole.compAdmin || Number(this.auth.getUserRole()) == this.constants.userRole.superAdmin){
  //       return false;
  //     }
  //     else {
  //       if((this.orderDetails.Order_Custom_Price + this.orderDetails.Order_Custom_Price_Tax) > 190) return true;
  //       else return false;
  //     }
  //   }
  //   else return false;
  // }


  acceptOrder() {
    // if(this.checkWestFieldBankPriceCap()){
    //   this.openErrorPopup("Grand total price of the Westfield Bank order cannot be more than $190.");
    // }
    // else{
      if(this.isDueDateRange) this.openETADatepicker();
      else {
        if(this.dueDate_Range) this.acceptOrderWithETA();
        else {
          if (!this.orderDetails.Order_ETA ) this.openETADatepicker();
          else this.acceptOrderWithETA();
        }
      }
    // }
  }

  acceptOrderWithETA() {
    if(this.isDueRangeErr) {
      if(this.orderDetails.Order_ETA) this.orderDetails.Order_ETA = null;
      if(this.order_ETA_Date) this.order_ETA_Date = null;
      this.order_ETA_Time = "17:00";
      if(this.dueDate_Range) this.order_ETA_Date = this.setOrderETATime(this.dueDate_Range);
    }
    else {
    if(this.orderDetails.Order_ETA_Range) this.orderDetails.Order_ETA_Range = null;
    if(this.order_ETA_Date) this.order_ETA_Date = null;
    if (this.orderDetails.Order_ETA && this.order_ETA_Time) this.order_ETA_Date =this.setOrderETATime(this.orderDetails.Order_ETA);
    }
    if(this.checkIfETAChanged()) this.checkForETAChanged()
    else this.acceptOrderSteps()
  }

  checkIfETAChanged() {
    if(this.copyOrderDetails.Order_ETA){
      let finalDueDate = this.orderDetails.Order_ETA;
      var estShift = momentTimeZone.tz(finalDueDate, 'America/New_York').isDST() ? 4 : 5;
      var etaInEstTimezone = moment(finalDueDate).utcOffset(moment(finalDueDate).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
      let newETADate = etaInEstTimezone
      if(moment(this.copyOrderDetails.Order_ETA, "YYYY-MM-DDTHH:mm").isSame(newETADate)) return false;
      else return true;
    }else return false;
  }

  checkForSelectedETA(orderETA, assigneeDetails, finalDueDate){
    let orderETADate = moment(orderETA).format("YYYY-MM-DD");
    let checkETAIsHoliday = this.holidayList.findIndex((holiday) => {
      if(holiday && moment(holiday.Holiday_Date, "YYYY-MM-DD").isSame(orderETADate)) return true;
      else return false
    })
    if(checkETAIsHoliday > -1 ) this.showWarningPopup(this.holidayList[checkETAIsHoliday].Holiday_Name, assigneeDetails, finalDueDate)
    else if(this.checkETALieOnWeekend(orderETA)) this.showWarningPopup(this.weekendHolidayName, assigneeDetails, finalDueDate)
    else this.acceptedOrder(assigneeDetails, finalDueDate);
  }

  checkETALieOnWeekend(orderETA) {
    if(moment(orderETA).isoWeekday() == 7){ // iso Weekday for sunday is 7
      this.weekendHolidayName = "Sunday";
      return true;
    }
    else if(moment(orderETA).isoWeekday() == 6){ // iso Weekday for saturday is 6
      this.weekendHolidayName = "Saturday";
      return true;
    }else return false;
  }

  showWarningPopup(holidayName, assigneeDetails, finalDueDate){
    let disposable = this.matDialog.open(ConfirmComponent, {data:{
      title:'ETA Note',
      message:"The ETA on this order is a US holiday ("+holidayName+"). Would you like to continue?"
    }, ...this.config.getDialogOptions()}).afterClosed()
    .subscribe((res) => { 
      if(res) this.acceptedOrder(assigneeDetails, finalDueDate);
    });
  }

  acceptOrderSteps() {
    if (this.orderDetails.Order_Custom_Price) {
      if(this.orderDetails.Order_ETA) this.checkForSelectedETA(this.orderDetails.Order_ETA, true, this.order_ETA_Date);
      else this.acceptedOrder(true, this.order_ETA_Date);
    }
    else {
      let disposable = this.matDialog.open(EtaAlertComponent, {data:{
        title: 'Confirm order',
        message: 'Grand total is $0. Do you want to confirm order?',
        value: 'Confirm Order'
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res) => { 
          if (res) {
            if(this.orderDetails.Order_ETA) this.checkForSelectedETA(this.orderDetails.Order_ETA, res, this.order_ETA_Date);
            else this.acceptedOrder(res, this.order_ETA_Date);
          }
        });
    }
    this.ref.detectChanges();
  }

  setOrderETATime(orderETA) {
    if(orderETA && orderETA._d){
      let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
      let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
      return orderETA._d.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    }else if(orderETA && !orderETA._d){
    let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
    let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
    return orderETA.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    }
    }

  openETADatepicker() {
    if (this.isDueDateRange) this.dueDatePicker.dueDateRange.nativeElement.focus();
    else this.dueDatePicker.etaDatePicker.nativeElement.click();
  }

  getTotalPriceOnLoad(products,orderDetails){
    products.forEach((prod)=>{
      if(prod.Order_Product_Price) this.getOnLoadTotalPrice += prod.Order_Product_Price;
    })

    if(orderDetails.Order_Custom_Price_Tax) this.getOnLoadTotalPrice +=orderDetails.Order_Custom_Price_Tax
    this.getOnLoadTotalPrice = Number(this.getOnLoadTotalPrice.toFixed(2))
  }

  acceptedOrder(assigneeDetails, finalDueDate) {
    if(this.grandTotPrice < this.getOnLoadTotalPrice && this.grandTotPrice > this.orderProducts[0].Order_Product_Original_Price) this.acceptOrderMapping(assigneeDetails, finalDueDate)
   else if(this.grandTotPrice == this.getOnLoadTotalPrice || this.grandTotPrice == this.orderProducts[0].Order_Product_Original_Price)this.acceptOrderMapping(assigneeDetails, finalDueDate)
   else if(this.grandTotPrice < this.getOnLoadTotalPrice){
        let disposable = this.matDialog.open(EtaAlertComponent, {data:{
          title: 'Edit Order Pricing',
          message: 'Would you like to confirm this change in price from ' + '$'+this.getOnLoadTotalPrice + ' to '+ '$'+this.grandTotPrice +' ?',
          value: 'Yes',
          cancelValue: 'No'
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if(res) this.acceptOrderMapping(assigneeDetails, finalDueDate)
          });

    }else if(this.grandTotPrice > this.getOnLoadTotalPrice){
      let disposable = this.matDialog.open(EtaAlertComponent, {data:{
        title: 'Edit Order Pricing',
        message: 'Would you like to request fee approval for increased price from ' + '$'+this.getOnLoadTotalPrice + ' to '+ '$'+this.grandTotPrice +' ?',
        value: 'Yes',
        cancelValue: 'Cancel',
        updateValue : 'Update Anyway'
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res) => {
          console.log("checking confirm price res",res)
          if(res && res == 'updateAnyway') {
            this.feeApproval = false;
            this.acceptOrderMapping(assigneeDetails, finalDueDate)
          }
          if(res && res == true) this.feeChangeReason(assigneeDetails, finalDueDate, res)
        });

    }
  }

  feeChangeReason(assigneeDetails, finalDueDate, res){
    this.feeApproval = res;
    this.openErrorReportDialog().afterClosed()
    .subscribe((res)=>{
      this.reason = res.comment
      if(res && res.state) this.processFeeApproch(res, assigneeDetails, finalDueDate);
    })
  }

  processFeeApproch(res, assigneeDetails, finalDueDate){
    this.orderService.storeFeeResonOnHistory(res, this.orderDetails, this.feeApproval)
    .subscribe((resData)=>{
      this.userMsg.setSuccessMsg("Fee approval request sent to slack.");
      if(res) this.acceptOrderMapping(assigneeDetails, finalDueDate) 
    })
    }

  openErrorReportDialog() {
    return this.matDialog.open(ErrorReportComponent, {data:{ message: { Order_ID: this.route.snapshot.params['orderId'] }, value: 'Yes', }, ...this.config.getDialogOptions()})
  }

  acceptOrderMapping(assigneeDetails, finalDueDate){
    var estShift = momentTimeZone.tz(finalDueDate, 'America/New_York').isDST() ? 4 : 5;
    var etaInEstTimezone = moment(finalDueDate).utcOffset(moment(finalDueDate).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
    this.orderDetails.Order_ETA = etaInEstTimezone;
    this.orderProducts.map((orderProduct) => {
      delete orderProduct.currInput;
      delete orderProduct.currBaseInput;
    });
    if(this.additionalProduct.length > 0){
      this.additionProdArr = this.additionalProduct.filter((prod)=> prod.hasOwnProperty('Invoice_ID'))

    }
    var data = {
      Order_Details: this.orderDetails,
      Order_Products: this.orderProducts,
      Admin_User_ID: this.auth.getUserId(),
      Order_ETA: this.orderDetails.Order_ETA,
      Order_OldProducts: this.oldProds,
      Reason: this.reason,
      OldOrder_Details: this.copyOrderDetails,
      Is_FeeApproval: this.feeApproval,
      additionalProduct : this.additionProdArr,
      yearsRequired : this.orderDetails.Years_Required
    }
    this.removeUnusedProductPricings();
    this.removeTempProdIds();
    if(data.additionalProduct.length > 0){
      data.Order_Products.push(data.additionalProduct)
      data.Order_Products = _.flatten(data.Order_Products);
    }
    if(this.orderDetails.Order_ETA){
    this.orderDetails.Order_ETA = null;
    }
    this.orderService.acceptOrder(data, this.route.snapshot.params['orderId'])
      .subscribe((res) => {
        console.log(res);
        if (this.orderDetails.Order_Internal_Due_Time) this.updateCurrentTaskDueDate(this.route.snapshot.params['orderId'])
        else this.router.navigate(['/orders/' + this.route.snapshot.params['orderId'] + '/view'])
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.orderaccept);
      })
  }

  updateCurrentTaskDueDate(orderId) {
    this.orderService.updateCurrentTaskDue(orderId)
      .subscribe(() => {
        this.router.navigate(['/orders/' + this.route.snapshot.params['orderId'] + '/view'])
      })
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  removeUnusedProductPricings() {
    this.orderProducts.forEach((orderProduct) => {
      if (isNaN(parseInt(orderProduct.Order_Product_Price))) orderProduct.Order_Product_Price = 0;
    })
  }

  getCopyChargesPricing(productId, orderProduct){
    if (this.orderDetails.Property_County && this.orderDetails.Property_State_Abbr && productId) {
      let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID : this.orderDetails.Customer_ID;
      this.pricingService.getCustomAndOrginalPriceDetails(this.orderDetails.Property_State_Abbr,
        this.orderDetails.Property_County, productId, customerId)
        .subscribe((copyResponse) => {
        if (copyResponse.Original_Cost) {
          this.isPricingFlag = true;
          orderProduct.Order_Product_Original_Price = copyResponse.Original_Cost.Cost_Amount;
          // orderProduct.Order_Product_Price = copyResponse.Original_Cost.Cost_Amount;
          // if (copyResponse.Custom_Cost) orderProduct.Order_Product_Price = copyResponse.Custom_Cost.Custom_Cost_Amount;
        }else {
          this.isPricingFlag = false;
          orderProduct.Order_Product_Price = 0
        }
        this.calculateTotal();
        })
      }       
      // PT-3613
      this.addAdditionalProducts();
  }
  
    addDefaultsToOrderProducts(defaultOrdProducts) {
    this.productService.getProduct(environment.copyChargeProductId)
      .subscribe((defaults) => {
        if (defaults) {
          let defProductData = [];
          defProductData.push(defaults);
          if(defaultOrdProducts && defaultOrdProducts.length > 0) defProductData[0].Product_Description = defaultOrdProducts[0].Product_Description;
          this.defaultproducts = defProductData;
          defProductData.forEach((item) => {
            if (this.orderProducts.findIndex(x => x.Product_ID == item.Product_ID) == -1)
              this.orderProducts.push(this.prepareOrderProduct(item));
          });
          this.orderProducts.forEach((product) => {
            if (!product.Order_Product_Price && (product.Product_ID != 7)) this.getProductPricingDetails(product.Product_ID, product)
            if (!product.Order_Product_Price && (product.Product_ID == 7)) this.getCopyChargesPricing(product.Product_ID, product)
          })
          this.calculateTotal();
        }
      })
  }

  // PT-3613
  addAdditionalProducts() {
    if(this.orderDetails.Organization_ID){
      let newIdx = this.orderProducts.length;
      this.pricingService.getAdditionalCompanyProducts(this.orderDetails.Organization_ID)
        .subscribe((data) => data.forEach((prod=>{
          if (prod.Mapping_Type == 1 && this.orderProducts.findIndex(x => x.Product_ID == prod.Product_ID) == -1){
            this.addproduct();
            this.orderProducts[newIdx].Product_ID = prod.Product_ID;
            this.orderProducts[newIdx].Product_Name = prod.Product_Name;
            this.orderProducts[newIdx].Product_Description = prod.Product_Description;
            this.orderProducts[newIdx].Order_Product_Custom_Input_Description = prod.Product_Description;
            this.getSubProductPricingDetails(prod.Product_ID, this.orderProducts[newIdx], prod.Product_Description);
            newIdx++;
          }
        })));
    }
  }
  
  prepareOrderProduct(product) {
    return {
      Order_Product_ID: 'new' + UUID.UUID(),
      Order_ID: this.orderDetails.Order_ID,
      Product_ID: product.Product_ID,
      Order_Product_Status: CONSTANTS.selectProductStatus.defaultProduct,
      Order_Product_Flag: CONSTANTS.orderProductFlag.active,
      Order_Product_Price: 0,
      Order_Product_Original_Price: 0,
      Product_Name: product.Product_Name,
      Product_Description: product.Product_Description,
      currInput: '',
      currBaseInput: ''
    }
  }

  checkForActiveProducts() {
    var activeProducts = this.getSubProducts().filter((product) => product.Order_Product_Flag == CONSTANTS.orderProductFlag.active);
    if (activeProducts.length > 0) return true;
    return false;
  }

  isSubProductsAvailable() {
    if (this.getSubProducts().length != 0 && this.checkForActiveProducts()) return true;
    else return false;
  }

  validateCustomInputDesc() {
    if (this.orderProducts) {
      var flag = 0;
      this.orderProducts.forEach(orderProduct => {
        if (orderProduct.Order_Product_Flag == CONSTANTS.orderProductFlag.active && orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
          if((orderProduct.Product_ID == '' || orderProduct.Product_ID == undefined) || (orderProduct.Order_Product_Custom_Name == '' || orderProduct.Order_Product_Custom_Name == undefined)){
            flag++;
          }
          if (orderProduct.Order_Product_Custom_Name) {
            if (orderProduct.Order_Product_Custom_Name.indexOf(' ') >= 0) {
              if (orderProduct.Order_Product_Custom_Name.trim().length == 0) flag++;
            }
          }
        }
      })
      if (flag == 0) return false;
      else return true;
    }
    else return false;
  }

  // getNumberFormat(e, orderProduct) {
  //   if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
  //   if ((orderProduct.currInput.length == 0 && e.key == "0")) e.preventDefault();
  //   else {
  //     if ((e.keyCode == 8 || e.keyCode == 46) && (orderProduct.currInput.length > 0 || orderProduct.currInput.length < this.config.getLimitPriceMaxLength())) {
  //       orderProduct.currInput = orderProduct.currInput.slice(0, orderProduct.currInput.length - 1);
  //       e.target.value = this.numberFormat(orderProduct.currInput);
  //       orderProduct.Order_Product_Price = e.target.value;
  //     }
  //     else if (orderProduct.currInput.length < this.config.getLimitPriceMaxLength()) {
  //       var key = this.keyValue(e.keyCode);
  //       if (key) {
  //         orderProduct.currInput = orderProduct.currInput + key;
  //         e.target.value = this.numberFormat(orderProduct.currInput);
  //         orderProduct.Order_Product_Price = e.target.value;
  //       }
  //     }
  //     this.calculateTotal();
  //     return false;
  //   }
  // }

  
  getNumberFormat(e, orderProduct) {
    const key = e.key;
    const isArrowOrTab = ['ArrowLeft', 'ArrowRight', 'Tab'].includes(key);
    const isBackspaceOrDelete = key === 'Backspace' || key === 'Delete';
    const isMinus = key === '-';
    const maxLength = this.config.getLimitPriceMaxLength();
    
    if (isArrowOrTab) return true;
    
    if (orderProduct.currInput.length === 0 && (key === '0' || isMinus)) {
        if (key === '0') {
            e.preventDefault();
        } else if (isMinus) {
            orderProduct.currInput = '-';
            e.target.value = this.numberFormat(orderProduct.currInput);
            orderProduct.Order_Product_Price = e.target.value;
        }
        return;
    }

    const updateInput = (newValue) => {
        orderProduct.currInput = newValue;
        e.target.value = this.numberFormat(orderProduct.currInput);
        orderProduct.Order_Product_Price = e.target.value;
    };
    
    if (isBackspaceOrDelete && orderProduct.currInput.length > 0 && orderProduct.currInput.length < maxLength) {
        updateInput(orderProduct.currInput.slice(0, -1));
    } else if (orderProduct.currInput.length < maxLength) {
        const keyValue = this.keyValue(e.keyCode);
        if (keyValue) {
            if (orderProduct.currInput.includes('-') && orderProduct.currInput.length === 1 && keyValue === '-') {
                e.preventDefault();
                return;
            }
            updateInput(orderProduct.currInput + keyValue);
        }
    }
    
    this.calculateTotal();
    return false;
}

  getBasePriceFormat(e, orderProduct) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((orderProduct.currBaseInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (orderProduct.currBaseInput.length > 0 || orderProduct.currBaseInput.length < this.config.getLimitPriceMaxLength())) {
        orderProduct.currBaseInput = orderProduct.currBaseInput.slice(0, orderProduct.currBaseInput.length - 1);
        e.target.value = this.numberBaseFormat(orderProduct.currBaseInput);
        if (e.target.value == '')
          orderProduct.Order_Product_Original_Price = null;
        else
          orderProduct.Order_Product_Original_Price = e.target.value;
      }
      else if (orderProduct.currBaseInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          orderProduct.currBaseInput = orderProduct.currBaseInput + key;
          e.target.value = this.numberBaseFormat(orderProduct.currBaseInput);
          orderProduct.Order_Product_Original_Price = e.target.value;
        }
      }
      this.calculateTotal();
      return e.preventDefault();
    }
  }

  getTaxFormat(e, orderDetails) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((this.input.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.input.length > 0 || this.input.length < this.config.getLimitPriceMaxLength())) {
        this.input = this.input.slice(0, this.input.length - 1);
        e.target.value = this.numberFormat(this.input);
        orderDetails.Order_Custom_Price_Tax = e.target.value;
      }
      else if (this.input.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.input = this.input + key;
          e.target.value = this.numberFormat(this.input);
          orderDetails.Order_Custom_Price_Tax = e.target.value;
        }
      }
      this.calculateGrantTotal()
      return false;
    }
  }



  numberFormat(val) {
    if (isNaN(parseFloat(val))) return "0.00";
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  numberBaseFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  assignSubProductFocus() {
    this.subProductFocusFlag = false;
  }

  checkStartDate(etaDate) {
    if (!etaDate) {
      let newDate = new Date(this.getUSDateString);
      if (newDate.getHours() > 17) {
        newDate.setDate(newDate.getDate() + 1)
        newDate.setHours(17);
        newDate.setMinutes(0);
      } else {
        newDate.setHours(17);
        newDate.setMinutes(0);
      }
      return newDate;
    }
  }

  getDueDateData(event) {
    this.dueDateRangeOption = Number(event.dueDateType);
    if (Number(event.dueDateType)) {
      if (event.dueDateFrmCtrl) {
        this.isDueRangeErr = true;
        this.dueDateFrmObj = event.dueDateFrmCtrl;
        if(!(event.dueDateFrmCtrl.value.fromDayCtrl) || !(event.dueDateFrmCtrl.value.toDayCtrl)) this.isDueDateRange = true;
        else this.isDueDateRange = false;
        if(this.dueDateFrmObj) {
          if (this.dueDateFrmObj.value.fromDayCtrl && this.dueDateFrmObj.value.toDayCtrl) {
            let getDueDateRange = this.dueDateFrmObj.value.fromDayCtrl + "-" + this.dueDateFrmObj.value.toDayCtrl;
            let addDays = momentTimeZone.tz('America/New_York').add(Number(this.dueDateFrmObj.value.toDayCtrl), 'days').format()
            let getDateObj =  this.Convert_UTCDate_To_LocalDate(addDays, 'America/New_York');
            this.dueDate_Range = getDateObj; 
            this.orderDetails.Order_ETA_Range = getDueDateRange;
          }
        }
      }
    } else {
      this.isDueDateRange = false;
      this.isDueRangeErr = false;
      if(this.dueDate_Range) this.dueDate_Range =null;
      if(this.orderDetails.Order_ETA_Range) this.orderDetails.Order_ETA_Range = null;   
      if (event.dueDateTimeCtrl) {
        this.dueDateTimeObj = event.dueDateTimeCtrl;
        if (event.dueDateTimeCtrl.valid) this.checkForDueDateEST(event);
      }
      else this.checkForDueDateEST(event);
    }
  }

  Convert_UTCDate_To_LocalDate(utcTime: any, localTimeZone: string) {
    var utcMoment = momentTimeZone(utcTime);
    var localDateTime = utcMoment.tz(localTimeZone);
    return new Date(localDateTime.format('lll'));
  }

  checkForDueDateValid() {
    if (this.dueDateRangeOption) {
          if(this.dueDateFrmObj && this.dueDateFrmObj.valid) return false;
          else return true;
    } else {
      if (this.dueDateTimeObj) {
        if (this.dueDateTimeObj.valid) return false;
        else if (!this.dueDateTimeObj.valid) return true
      } else return false;
    }
  }

  checkForDueDateEST(event) {
    if (event.Due_Date_EST || event.Due_TIme_EST) {
      this.orderDetails.Order_ETA = event.Due_Date_EST;
      this.order_ETA_Time = event.Due_TIme_EST;
    }
    this.ref.detectChanges();
  }

  checkForDueDate(){
    let addDays = momentTimeZone.tz('America/New_York').add(-1,'days').format();
    let getDateObj =  this.Convert_UTCDate_To_LocalDate(addDays, 'America/New_York');    
    if(moment(this.orderDetails.Order_ETA).isBefore(getDateObj)) return true
    else return false
  }

  showBasePriceFlag(orderProduct) {
    if(this.isPricingFlag) return true;
    else {
      if(orderProduct && orderProduct.Order_Product_Original_Price) return true;
      else return false;
    }
  }

  getholidaylistFromLocal(){
    let allHolidays = JSON.parse(sessionStorage.getItem('holidays'))
    if(allHolidays) this.setHoliday(allHolidays)
    else this.getholidaylist();
  }

  setHoliday(holiday){
    this.holidayList = holiday
  }

  getholidaylist(){
    this.holidayScheduling.getAllHolidays()
    .subscribe((holidays)=>{
      if(holidays){
        this.setHoliday(holidays)
        sessionStorage.setItem('holidays', JSON.stringify(holidays));
     setTimeout(function(){sessionStorage.removeItem("holidays");}, CONSTANTS.sessiontimeOut.timeOut);
      }
      // this.holidayList = holidays;
    }, (err) =>{
      console.log("Error",err)
    })
  }

  checkForETAChanged() {
    if(this.checkIfETAChanged() && this.checkOrderIsConfirmed()) this.openETAChangeMsgInterface();
    else {
      this.orderDetails.orderETAChangeReason ='';
      this.orderDetails.Order_ETA_Change_Reason_Type_ID = ''
      this.acceptOrderSteps();
    }
  }


  openETAChangeMsgInterface() {
    let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if(res.state) { 
          this.orderDetails.orderETAChangeReason =res.Message_Text;
          this.orderDetails.Order_ETA_Change_Reason_Type_ID = res.Order_ETA_Change_Reason_Type_ID;
          this.acceptOrderSteps(); 
        }
      });
  }

  getAllOrderStatus(orderId){
    this.orderService.getAllOrderStatus(orderId)
    .subscribe((orderStatusDet) => {
      this.allOrderStatus = orderStatusDet;
    })
  }
  
  checkOrderIsConfirmed() {
    let confirmedOrdIndex = this.allOrderStatus.findIndex((data) => data.Status_New === CONSTANTS.orderStatus.confirmed)
    if(confirmedOrdIndex > -1) return true;
    else return false;
  }

   getAllsubNBaseProd(orderId){
    this.orderService.getOrderDetails(orderId)
    .subscribe((details) => {
    this.subOrderDetails = details;
    let customerId = this.subOrderDetails.Organization_ID ? this.subOrderDetails.Organization_ID:  this.subOrderDetails.Customer_ID;
    this.orderService.getSubNBaseProduct(customerId)
      .subscribe((res)=>{
        this.allsubNBaseProducts = res;
        this.getSubProducts().forEach((orderProduct) => {
          orderProduct.isUpdated = this.checkIfOrderProductUpdatedSub(orderProduct);
          if(orderProduct.isUpdated) {
            let randomID = this.randomIntFromInterval(8888, 9999);
            orderProduct.Old_Product_ID = orderProduct.Product_ID;
            orderProduct.Product_ID = randomID;
            orderProduct.Copy_Product_ID = randomID;
          }
        })
      })
    })
   }


  checkAddProdDesc(){
    if (this.orderProducts){
    var flag = 0;
    this.orderProducts.forEach(orderProduct => {
      if (orderProduct.Order_Product_Flag == CONSTANTS.orderProductFlag.active && orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
        if(!orderProduct.hasOwnProperty('Product_ID')){
          flag++;
        }
        else if(orderProduct.hasOwnProperty('Product_ID')){
          if(orderProduct.Product_ID === 999){
            if((orderProduct.Order_Product_Custom_Name == undefined) || (orderProduct.Order_Product_Custom_Name == '')){
              flag++;
            }
          }
        }
      }
    })
      if (flag == 0) return false;
          else return true;
    }
    else return false;
  }

  handleManualSubProductChange(event, orderProduct){
    if(event) {
      orderProduct.Product_ID = event.value.Product_ID;
      orderProduct.Order_Product_Custom_Input_Description = event.value.Product_Description;
      this.getSubProductPricingDetails(event.value.Product_ID, orderProduct, event.value.Product_Description)
    }
  }


  getSubProductPricingDetails(event, product, productDescription = null) {
    if (!product) var product = this.orderProducts[0];
    else product.Product_ID = event;
    if (this.orderDetails.Property_County && this.orderDetails.Property_State_Abbr && product.Product_ID) {
      let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID : this.orderDetails.Customer_ID;
      this.pricingService.getCustomAndOrginalPriceDetails(this.orderDetails.Property_State_Abbr,
        this.orderDetails.Property_County, product.Product_ID, customerId)
        .subscribe((data) => {
          product.Order_Product_Original_Price = 0;
          product.Order_Product_Price = 0;
          if (data.Original_Cost) {
            product.Order_Product_Original_Price = data.Original_Cost.Cost_Amount;
            product.Order_Product_Price = data.Original_Cost.Cost_Amount;
            if (data.Custom_Cost) product.Order_Product_Price = data.Custom_Cost.Custom_Cost_Amount;
          }
          this.resetProductPrice(product)
          this.calculateTotal();
          if(productDescription) this.updateSubProductDescription(product, productDescription)
          this.resetPriceFields(product.Order_Product_ID);
        })
    }
  }


  updateSubProductDescription(orderProduct, productDesciption) {
    this.orderService.getAllOrderProducts()
    .subscribe((res)=>{
      this.updateProducts = res;
      if(this.copyOrderDetails.Product_Description !== productDesciption) {
        let productFindIndex = this.updateProducts.findIndex((product) =>{
          return product.Product_ID === orderProduct.Product_ID
        });
        orderProduct.Product_Description = productDesciption
        orderProduct.Product_Name = this.updateProducts[productFindIndex].Product_Name;
      } else {
        let productFindIndex = this.updateProducts.findIndex((product) => product.Product_ID === orderProduct.Product_ID);
        orderProduct.Product_Description = this.copyOrderDetails.Product_Description;
        orderProduct.Product_Name = this.updateProducts[productFindIndex].Product_Name;
      }
    })
  }

  checkIfOrderProductUpdatedBase(orderProduct) {
    let isUpdated = false;
    if (orderProduct.Product_ID) {
      if (
        orderProduct.Order_Product_Status ==
        CONSTANTS.selectProductStatus.baseProduct
      ) {
        let matchIndex = this.products.findIndex(
          (product) => product.Product_ID == orderProduct.Product_ID
        );
        if(matchIndex > -1){          
          let matchedProduct = this.products[matchIndex];
          if (
            (orderProduct.Order_Product_Custom_Input_Description && matchedProduct.Product_Description.toLowerCase() ==
              orderProduct.Order_Product_Custom_Input_Description.toLowerCase()) ||
            (orderProduct.Order_Product_Custom_Name && matchedProduct.Product_Description.toLowerCase() ==
              orderProduct.Order_Product_Custom_Name.toLowerCase())
          )
            isUpdated = false;
          else isUpdated = true;
        }
        else isUpdated = true;         
      }
    }
    return isUpdated;
  }

  checkIfOrderProductUpdatedSub(orderProduct) {
    let isUpdated = false;
    if (orderProduct.Product_ID) {
      if (
        orderProduct.Order_Product_Status ==
        CONSTANTS.selectProductStatus.subproduct
      ) {
        let matchIndex = this.allsubNBaseProducts.findIndex(
          (product) => product.Product_ID == orderProduct.Product_ID
        );
        if(matchIndex > -1) {
          let matchedProduct = this.allsubNBaseProducts[matchIndex];
          if (
            (orderProduct.Order_Product_Custom_Input_Description && matchedProduct.Product_Description.toLowerCase() ==
              orderProduct.Order_Product_Custom_Input_Description.toLowerCase() )||
            (orderProduct.Order_Product_Custom_Name && matchedProduct.Product_Description.toLowerCase() ==
              orderProduct.Order_Product_Custom_Name.toLowerCase())
          )
            isUpdated = false;
          else isUpdated = true;
        }   
        else isUpdated = true;     
      }
    }
    return isUpdated;
  }

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  removeTempProdIds(){
    this.orderProducts.forEach((orderProduct) => {
      if(orderProduct.isUpdated){
        if(orderProduct.Product_ID == orderProduct.Copy_Product_ID) {
          orderProduct.Product_ID = orderProduct.Old_Product_ID;         
        }
        delete orderProduct.Copy_Product_ID;
        delete orderProduct.Old_Product_ID;
      }
    })
  }

  getProductDisplayLabelBase(orderProduct){
    if(orderProduct.Order_Product_Custom_Input_Description){
      if(this.products.some((product) => 
        product.Product_Description.toLowerCase() == orderProduct.Order_Product_Custom_Input_Description.toLowerCase()
      )) return 'Item Product';
      else return orderProduct.Order_Product_Custom_Input_Description; 
    }
    else return 'Item Product'
  }

  getProductDisplayLabelSub(orderProduct){
    if(orderProduct.Order_Product_Custom_Input_Description){
      if(this.allsubNBaseProducts.some((product) => 
        product.Product_Description.toLowerCase() == orderProduct.Order_Product_Custom_Input_Description.toLowerCase()
      )) return 'Item Product'; 
      else return orderProduct.Order_Product_Custom_Input_Description;
    }
    else return 'Item Product'
  }

  yearsRequiredValidation(){
   if(this.orderDetails && this.orderDetails && this.orderDetails.Years_Required == '' 
    || this.orderDetails && this.orderDetails.Years_Required == undefined ||
    this.orderDetails && this.orderDetails.Years_Required == null) return true;
   else return false;
  }

  getDataDepthInfo() {
    this.orderService.getDataDepthInfo({Property_State_Abbr: this.orderDetails.Property_State_Abbr })
      .subscribe((info) => {
        this.dataDepthYears = info[0]["State Statute"][0]
        // if(info.length > 0) this.dataDepth = info[0];
        // else this.gsheetSearchInfo = {};
      }, (err) => {
        throw err;
      })
  }

}
