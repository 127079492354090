import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteLibComponent } from './autocomplete-lib.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { FormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule
  ],
  declarations: [AutocompleteLibComponent, AutocompleteComponent],
  exports: [ AutocompleteLibComponent, AutocompleteComponent ]
})
export class AutocompleteLibModule { }
