import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

@Component({
  selector: 'app-change-product-type',
  templateUrl: './change-product-type.component.html',
  styleUrls: ['./change-product-type.component.scss']
})
export class ChangeProductTypeComponent{
  pricingObj: any;
  oldProduct: any;
  newProduct: any;
  title: any;
  constructor(public dialogRef: MatDialogRef<ChangeProductTypeComponent>,
    private matDialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
   
   }

  ngOnInit() {
    this.initDialog();
  }

  initDialog(){
    this.title = this.data.title;
    this.pricingObj = this.data.pricingObj;
    this.oldProduct = this.data.oldProduct;
    this.newProduct = this.data.newProduct;
  }

  Close() {
    this.dialogRef.close(false);
  }

  updatePrice() {
   
     this.dialogRef.close({
      updatePriceFlag: true,
      pricingData: this.pricingObj
    })
  }

  oldPrice() {
     this.dialogRef.close({
      updatePriceFlag: false,
    })
  }
}
