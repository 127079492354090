// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rb-commit-toggle {
  flex: 0 0 13.3%;
  max-width: 13.3%;
  margin-left: 1%;
}

.rb-commit-select {
  flex: 0 0 84.1%;
  max-width: 84.1%;
  padding-top: 0.6%;
  margin-left: 0.5%;
}

.green-wrapper {
  border: 2px dashed #60CC70;
  background-color: rgba(96, 204, 112, 0.05);
  padding: 8px;
}

.rb-section-error {
  border-color: #EE4751;
  border-width: 2px;
  border-style: dashed;
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/report-builder/rb-report/rb-report.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,0CAAA;EACA,YAAA;AACJ;;AAEA;EACE,qBAAA;EACA,iBAAA;EACA,oBAAA;EACA,kBAAA;AACF","sourcesContent":[".rb-commit-toggle{\n  flex: 0 0 13.3%;\n  max-width: 13.3%;\n  margin-left: 1%;\n}\n\n.rb-commit-select{\n    flex: 0 0 84.1%;\n    max-width: 84.1%;\n    padding-top: 0.6%;\n    margin-left: 0.5%;\n}\n\n.green-wrapper{\n    border: 2px dashed #60CC70;\n    background-color:  rgba(96, 204, 112, 0.05);    \n    padding: 8px;\n}\n\n.rb-section-error {\n  border-color: #EE4751;\n  border-width: 2px;\n  border-style: dashed;\n  border-radius: 3px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
