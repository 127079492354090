import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { AuthService, MessagesService, SocketService, OrdersService, ClientsService, ConfigService, UsersService, QuickLinksService, StateService,QuoterequestService } from '../../services';
import { CONSTANTS } from '../../app.constants';
import { DialogService } from 'ng2-bootstrap-modal';
import { BulkOrderComponent } from '../../dialog/bulk-order/bulk-order.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  quickLinks = [];
  timer: any = [];

  constants = CONSTANTS
  @ViewChild('createOrder') createOrder: ElementRef

  constructor(
    public auth: AuthService,
    public messagesService: MessagesService,
    private socket: SocketService,
    public ordersService: OrdersService,
    public clientsService: ClientsService,
    public quickLinksService: QuickLinksService,
    private config: ConfigService,
    private matDialog:MatDialog,
    private userService: UsersService,
    private router: Router,
    private stateService: StateService,
    private quoteService:QuoterequestService,

  ) { }

  ngOnInit() {
    this.getAllQuickLinks();
    this.getUnreadCount();
    if (this.checkIfAdmin()){ 
      this.getRecivedOrderCount();
      this.getNewClientCount();
      this.getReceivedQuoteCount();
      this.getEscalationCount();
    }
    this.socket.syncMessage("sidebar").subscribe((result) => {
      this.getUnreadCountBg();
    })
    this.socket.syncMessage("order").subscribe((result) => {
      if (this.checkIfAdmin()) this.getRecivedOrderCountOnBg();
    })
    this.socket.syncMessage("client").subscribe((result) => {
      if (this.checkIfAdmin()) this.getNewClientCountOnBg();
    })
    this.socket.syncMessage("quoteReq").subscribe((result) => {
      if (this.checkIfAdmin()) this.getReceivedQuoteCount();
    })
    this.timer.push(setTimeout(() => { if (this.createOrder) this.createOrder.nativeElement.focus() }, 0));
  }

  getActiveNode() {
    return this.config.getSideBarNode();
  }

  ngOnDestroy(): void {
    this.socket.unsubscribeMessage("sidebar");
    this.socket.unsubscribeMessage("order");
    this.socket.unsubscribeMessage("client");
    this.socket.unsubscribeMessage("quoteReq");
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getUnreadCount() {
    var data = {}
    data['Admin_ID'] = this.auth.getUserId();
    this.messagesService.getAllUnreadCountForAdmin(data)
      .subscribe((data) => {
      }, (err) => {
        console.log(err);
      })
  }

  getRecivedOrderCount() {
    this.ordersService.getAllReceivedOrderCount()
      .subscribe((data) => {
      }, (err) => {
        console.log(err);
      })
  }

  getRecivedOrderCountOnBg() {
    this.ordersService.getAllBackgroundReceivedOrderCount()
      .subscribe((data) => {
        console.log("data", data);
      }, (err) => {
        console.log(err);
      })
  }

  getUnreadCountBg() {
    var data = {}
    data['Admin_ID'] = this.auth.getUserId();
    this.messagesService.getBackgroundUnreadCount(data)
      .subscribe((data) => {
        console.log("data", data);
      }, (err) => {
        console.log(err);
      })
  }

  
  getReceivedQuoteCount() {
    this.quoteService.getAllReceivedQuoteCount()
      .subscribe((data) => {
      }, (err) => {
        console.log(err);
      })
  }




  getNewClientCount() {
    this.clientsService.getNewClientCount()
      .subscribe((data) => {
      }, (err) => {
        console.log(err);
      })
  }
  getNewClientCountOnBg() {
    this.clientsService.getNewClientCountOnBg()
      .subscribe((data) => {
        console.log("data", data);
      }, (err) => {
        console.log(err);
      })
  }

  getUserRole() {
    return Number(this.auth.getUserRole())
  }

  isSearchManager() {
    return Number(this.auth.isSearchManager())
  }
  
  openDialog() {
    this.userService.getAllCustomers()
      .subscribe((result) => {
        if (result) {
          let disposable = this.matDialog.open(BulkOrderComponent, {data:{
            message: result
          }, ...this.config.getDialogOptions()});
        }
      }, (err) => {
        console.log(err);
      })

  }


  checkIfAdmin() {
    if (Number(this.auth.getUserRole()) == this.constants.userRole.admin ||
      Number(this.auth.getUserRole()) == this.constants.userRole.compAdmin ||
      Number(this.auth.getUserRole()) == this.constants.userRole.superAdmin) return true;
    else return false;
  }

  getAllQuickLinks() {
    this.quickLinksService.getAllQuickLinks()
    .subscribe((quickLinks) => {
      this.quickLinks = quickLinks;
    }, (err) => {
      console.log(err);
    })
  }

  checkIfNotExternalSM() {
    if(Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.externalSM) return false;
    else return true;
  }

  getQuickLinks() {
    if (this.stateService.getQuickLinks()) {
      this.quickLinks = this.stateService.getQuickLinks();
    }
  }

  checkAdminType(){
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }

  getEscalationCount() {
    this.ordersService.getEscalatedOrderCount()
      .subscribe((data) => {
      }, (err) => {
        console.log(err);
      })
  }


}
