import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import {
  AuthService,
  EmailValidator,
  EqualValidator,
  EulaService,
  ConfigService,
  UsermessageService,
} from "../../services";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from '../../dialog/confirm/confirm.component'
import { SuccessComponent } from '../../dialog/success/success.component';
import { CONSTANTS } from '../../app.constants';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import * as momentTimeZone from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: "app-eula-edit",
  templateUrl: "./eula-edit.component.html",
  styleUrls: ["./eula-edit.component.scss"],
  providers: [DatePipe],
})
export class EulaEditComponent implements OnInit, AfterViewInit {
  eulaForm: any = {};
  eulaModel: any = {};
  eulaId: string = "";
  error: any = {};
  errors: any = {};
  errorMsg: any;
  constants = CONSTANTS;
  txtDisable: boolean = false;
  btnDisable: boolean = false;
  EULA_Effective_Date: any;
  //today = new Date().toJSON().split('T')[0];
  today = new Date();
  timer: any = [];
  tinyOptions: any = {
    auto_focus: false,
    branding:false,
    promotion :false,
    skin:'oxide',
    menubar:false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"],
    toolbar:
      "undo redo | styleselect | forecolor backcolor |  bold italic |  alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private frmBuilder: UntypedFormBuilder,
    public auth: AuthService,
    private config:ConfigService,
    private matDialog:MatDialog ,
    private eulaService:EulaService ,
    private _datePipe:DatePipe,
    private userMsg: UsermessageService
  ) {
    this.eulaForm = frmBuilder.group({
      EULA_Effective_Date: { disabled: false, value: "" },
      EULA_Expiry_Date: [null],
      EULA_Text: [null, Validators.required],
    });
    this.eulaForm.valueChanges.subscribe((data) => {
      this.error = "";
    });
  }

  ngOnInit() {
    if (this.route.snapshot.params["eulaId"]) {
      this.eulaId = this.route.snapshot.params["eulaId"];
      this.getEULADetails();
    }
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
  }

  ngAfterViewInit() {
    if(document.querySelector("iframe")) document.querySelector("iframe").setAttribute("data-gramm", "false");
  }

  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  // focusFunction(event){
  //   if(!this.eulaId){
  //     console.log("Focused",event.srcElement);
  //     event.srcElement.type='date' ;
  //   }
  // }

  // keyPresshandler(event){
  //   console.log("key press event fired",event);
  // }

  // focusOutFunction(event){
  //   if(!this.eulaId){
  //     console.log("Out focused",event.srcElement)
  //     if(!event.srcElement.value)event.srcElement.type='text'
  //   }
  // }

  display(event) {
    if (this.EULA_Effective_Date) console.log("ngmodelchange", event);
  }

  getEULADetails() {
    this.eulaService.getEULA(this.eulaId).subscribe(
      (data) => {
        console.log(data);
        if (data) {
          this.loadData(data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getEulaEffectiveDate() {
    if (this.EULA_Effective_Date && this.eulaModel.EULA_Text) return 1;
    else return 0;
  }

  loadData(data) {
    this.EULA_Effective_Date = new Date(data.EULA_Effective_Date);
    if (this.EULA_Effective_Date && !this.isPostdatedEULA()) {
      this.EULA_Effective_Date = this._datePipe.transform(
        this.EULA_Effective_Date,
        "MM/dd/yyyy"
      );
      document
        .getElementById("EULA_Effective_Date")
        .parentElement.classList.remove("is-empty");
    }
    this.eulaModel.EULA_Expiry_Date = data.EULA_Expiry_Date;
    if (this.eulaModel.EULA_Expiry_Date) {
      if (document.getElementById("EULA_Expiry_Date"))
        document
          .getElementById("EULA_Expiry_Date")
          .parentElement.classList.remove("is-empty");
      this.eulaModel.EULA_Expiry_Date = this._datePipe.transform(
        this.eulaModel.EULA_Expiry_Date,
        "MM/dd/yyyy"
      );
    }
    this.eulaModel.EULA_Text = data.EULA_Text;
    if (this.eulaModel.EULA_Text) {
    }
    // console.log(data.EULA_Expiry_Date);
    // console.log(new Date().toISOString());
    // console.log("Comparing for disabling",data.EULA_Expiry_Date < new Date().toISOString())
    // if(data.EULA_Expiry_Date && data.EULA_Expiry_Date < new Date().toISOString()){
    if (data.EULA_Expiry_Date && new Date(data.EULA_Expiry_Date) < new Date()) {
      this.txtDisable = true;
      this.btnDisable = true;
    }
  }

  confirm() {
    // if (this.EULA_Effective_Date) {
      // var estShift = momentTimeZone
      //   .tz(this.EULA_Effective_Date, "America/New_York")
      //   .isDST()
      //   ? 4
      //   : 5;
      // var etaInEstTimezone = moment(this.EULA_Effective_Date)
      //   .utcOffset(moment(this.EULA_Effective_Date).utcOffset())
      //   .add(estShift, "hours")
      //   .format("YYYY-MM-DDTHH:mm");
      // this.EULA_Effective_Date = etaInEstTimezone;
    // }
    if (this.route.snapshot.params["eulaId"]) {
      this.updateEULA();
    } else {
      this.createEULA();
    }
  }

  isPostdatedEULA() {
    return (
      !this.eulaId ||
      (this.eulaId && momentTimeZone(this.EULA_Effective_Date).utc().isSameOrAfter(momentTimeZone().utc(), 'day'))
    );
  }

  validateDateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP =
      /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/; //for m/d/yyyy and mm/dd/yyyy
    if (
      event.targetElement.value &&
      !DATE_REGEXP.test(event.targetElement.value)
    )
      this.errors["EULA_Effective_Date"] = true;
    else this.errors = {};
  }

  getIfErrors() {
    return Object.keys(this.errors).length;
  }

  createEULA() {
    this.eulaModel.User_ID = this.auth.getUserId();
    this.eulaModel.EULA_Effective_Date = moment(
      this.EULA_Effective_Date
    ).format("YYYY-MM-DD");
    console.log("formattedDate", this.eulaModel.EULA_Effective_Date);
    if (this.EULA_Effective_Date) this.EULA_Effective_Date = "";
    this.eulaService.createEULA(this.eulaModel).subscribe(
      (data) => {
        this.successMsgFunc("EULA created successfully");
        // console.log(data);
        // this.openDialog('EULA created successfully');
      },
      (err) => {
        console.log(err);
        this.errorMsg = err;
      }
    );
  }

  updateEULA() {
    this.eulaModel.User_ID = this.auth.getUserId();
    this.eulaModel.EULA_Effective_Date = moment(
      this.EULA_Effective_Date
    ).format("YYYY-MM-DD");
    if (this.EULA_Effective_Date) this.EULA_Effective_Date = "";
    this.eulaService
      .updateEULA(this.eulaModel, this.route.snapshot.params["eulaId"])
      .subscribe(
        (data) => {
          this.successMsgFunc("EULA updated successfully");
          // console.log(data);
          // this.openDialog('EULA updated successfully');
        },
        (err) => {
          console.log(err);
          this.errorMsg = err;
        }
      );
  }

  openDialog(msg){
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title:'Success', 
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res)=>{      
      this.router.navigate(['/eula']);
    });
  }

  successMsgFunc(msg) {
    this.userMsg.setSuccessMsg(msg);
    this.router.navigate(["/eula"]);
  }

  getPippinDate(event) {
    if (event && event.Pippin_Date) {
      this.EULA_Effective_Date = event.Pippin_Date;
    } else this.EULA_Effective_Date = null;
  }
}
