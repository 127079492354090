// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-section {
  color: #0F9D58;
}`, "",{"version":3,"sources":["webpack://./src/app/common/slide/slide.component.scss"],"names":[],"mappings":"AACA;EAKE,cAAA;AAJF","sourcesContent":["\n.slide-section {\n  // display: flex;\n  // align-content: center;\n  // align-items: center;\n  // height: 60px;\n  color: #0F9D58 ;\n}\n\n// .slide-margin {\n//   margin: 10px;\n// }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
