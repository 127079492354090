import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService, OrganizationService, ConfigService, UsermessageService, GroupsService, UsersService, TagsService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { Router, ActivatedRoute } from '@angular/router';
import { AddUser2groupComponent } from '../../dialog/add-user2group/add-user2group.component';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit {
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  editGrpFrm: UntypedFormGroup;
  error: string = '';
  errorMsg: any = {};
  editGrpModel: any = {};
  groupMembers: any = [];
  availableTags: any = [];
  groupTags: any = [];
  searchTag: string = '';
  timer: any = [];

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private organizationService: OrganizationService,
    private matDialog:MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private config: ConfigService,
    private userMsg: UsermessageService,
    public groupsService: GroupsService,
    private userService: UsersService,
    private tagService: TagsService,

  ) {
    this.editGrpFrm = fb.group({
      'Group_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'Group_Description': [null, Validators.compose([Validators.maxLength(225)])],
      'Group_Status': [null]
    })
    this.editGrpFrm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    this.getGroupInfo();
    this.getGroupMembers();
    this.getGroupTags();
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
    }, 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getGroupInfo() {
    this.groupsService.getGroupInfo(this.route.snapshot.params['groupId'])
      .subscribe((groupData) => {
        this.editGrpModel = groupData;
      }, (err) => {
        this.openErrorPopup("Error while fetching data.");
      })
  }

  getGroupMembers() {
    this.groupsService.getGroupMembers(this.route.snapshot.params['groupId'])
      .subscribe((users) => {
        this.groupMembers = users;
      }, (err) => {
        this.openErrorPopup("Error while fetching data.");
      })
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  editGroup() {
    this.errorMsg = {};
    this.editGrpModel.Admin_ID = this.auth.getUserId();
    this.editGrpModel.Group_ID = this.route.snapshot.params['groupId'];
    this.groupsService.editGroup(this.editGrpModel)
      .subscribe((res) => {
        let msg = "Group updated successfully.";
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/groups/list']);
      }, (err) => {
        this.errorMsg = err;
      });
  }

  userAction(event) {
    this.errorMsg = {};
    if (event.checked == true) {
      this.groupsService.activateGroup({ Group_ID: this.route.snapshot.params['groupId'], Admin_ID: this.auth.getUserId() })
        .subscribe((res) => {
          let msg = "Group successfully activated.";
          this.userMsg.setSuccessMsg(msg);
        }, (err) => {
          // this.editGrpModel.Group_Status = !this.editGrpModel.Group_Status;
          this.openErrorPopup("Unable to update group status.");
        })
    } else {
      this.groupsService.suspendGroup({ Group_ID: this.route.snapshot.params['groupId'], Admin_ID: this.auth.getUserId() })
        .subscribe((res) => {
          let msg = "Group successfully suspended.";
          this.userMsg.setSuccessMsg(msg);
        }, (err) => {
          // this.editGrpModel.Group_Status = !this.editGrpModel.Group_Status;
          this.openErrorPopup("Unable to update group status.");
        })
    }
  }

  removeGroupMember(member) {
    this.groupsService.removeGroupMember({ Group_ID: member.Group_ID, Group_Member_Mapping_ID: member.Group_Member_Mapping_ID })
      .subscribe((res) => {
        this.getGroupMembers();
        let msg = "Removed successfully.";
        this.userMsg.setSuccessMsg(msg);
      }, (err) => {
        this.openErrorPopup("Error while removing group member.");
      });
  }

  addUsers() {
    this.userService.getAdminPanelUsers()
      .subscribe((userList) => {
        let activeUsers = [];
        this.groupMembers.filter(x => {
          activeUsers.push(x.User_ID);
        });
        let disposable = this.matDialog.open(AddUser2groupComponent, {data:{
          message: {
            userList: userList,
            activeUsers: activeUsers
          }
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if(res) {
              this.groupsService.modifyGroupMembers({ Group_ID: this.route.snapshot.params['groupId'], Admin_ID: this.auth.getUserId(), Group_Members: res.Group_Members })
              .subscribe((res) => {
                this.getGroupMembers();
                let msg = "Updated successfully.";
                this.userMsg.setSuccessMsg(msg);
              }, (err) => {
                this.openErrorPopup("Error while modify group members.");
              });
            }
          });
      })
  }

  clearSearchTag() {
    this.searchTag = '';
  }

  menuClosed() {
    this.clearSearchTag();
    this.availableTags = [];
  }

  openTagMenu() {
    this.tagService.getAllActiveTagsForListing()
      .subscribe((activeTags) => {
        for (var i = 0, len = activeTags.rows.length; i < len; i++) {
          if (this.groupTags.findIndex((select => activeTags.rows[i].Tag_ID == select.Tag_ID)) == -1) {
           this.availableTags.push(activeTags.rows[i]);
          }
        }
      }, (err) => {
        this.openErrorPopup("Error while fetching data.");
      })
  }

  getFontColor(hexColor) {
    return this.tagService.getFontColor(hexColor)
  }

  getGroupTags() {
    this.groupsService.getGroupTags(this.route.snapshot.params['groupId'])
      .subscribe((tags) => {
        this.groupTags = tags;
      }, (err) => {
        this.openErrorPopup("Error while fetching Group tags.");
      })
  }

  modifyGroupTags(event, tag){
    var isTagToAdd: boolean;
    if (event.checked) isTagToAdd = true;
    else isTagToAdd = false;
    this.groupsService.modifyGroupTags({ Group_ID: this.route.snapshot.params['groupId'], Group_Tag: tag, isTagToAdd: isTagToAdd })
      .subscribe((res) => {
        this.getGroupTags();
        let msg = "Updated successfully.";
        this.userMsg.setSuccessMsg(msg);
        if(isTagToAdd) this.availableTags = this.availableTags.filter(item => (item.Tag_ID !== tag.Tag_ID))
      }, (err) => {
        this.openErrorPopup("Error while modifing tag.");
        this.matMenuTrigger.closeMenu();
      })
  }

  removeGroupTag(tag){
    this.matDialog.open(ConfirmComponent, {data:{
      title: 'Remove Tag',
      message: 'Are you sure want to remove ' + tag.Tag_Name + ' tag?',
      breakWordFlag: true
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if (res) {
        var isTagToAdd: boolean = false;
        this.groupsService.modifyGroupTags({ Group_ID: this.route.snapshot.params['groupId'], Group_Tag: tag, isTagToAdd: isTagToAdd })
          .subscribe(() => {
            this.getGroupTags();
          }, (err) => {
            this.openErrorPopup("Error while deleting tag.");
            this.matMenuTrigger.closeMenu();
          })
      }
    });
    
  }


}
