import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { AuthService } from '../authentication/auth.service';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscribeService {

  constructor(
    private http:HttpClient,
    private config:ConfigService,
    private auth:AuthService
  ) { }

  subscribeOrderToAdmin(orderId) {
    var data = {
      Order_ID:orderId,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }

    let url = this.config.getBasePath() +'/orders/subscribe'
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)))
  }

  unSubscribeOrderToAdmin(subscriptionId) {
    var data = {
      Order_Subscription_ID:subscriptionId,
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }

    let url = this.config.getBasePath() +'/orders/unsubscribe'
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)))
  }

  getSubscriptionDetails(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/subscribe/' + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }
}