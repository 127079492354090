// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-label-bold {
  font-weight: bold;
}

.font-auto {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/app/report-builder/rb-liens/rb-liens.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AACA;EACI,iDAAA;AAEJ","sourcesContent":[".toggle-label-bold{\n  font-weight: bold;\n}\n.font-auto{\n    font-family: Roboto, \"Helvetica Neue\", sans-serif;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
