
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
// import { Socket  } from 'ngx-socket-io'
// import { map } from 'rxjs/operators';

@Injectable()
export class SocketService {

  // constructor(private socket: Socket) { }
  constructor() { }
  
//   emitMessageChange(msg: string){
//       this.socket.emit(msg, null);
//   }

  // syncMessage(msg: string) {
  //     return this.socket
  //         .fromEvent<any>(msg).pipe(
  //         map((data) => data));
  // }

  // unsubscribeMessage(msg: string){
  //     this.socket.removeAllListeners(msg);
  // }

  syncMessage(msg: string) {
    return from(new Promise(resolve => {
      resolve(true)}
      )).pipe(map((data) => data));
  }
  
 unsubscribeMessage(msg: string){
      // this.socket.removeAllListeners(msg);
  }
  
  close() {
    //this.socket.disconnect();
  }

}
