// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  box-shadow: 2px 0px 12px 2px rgb(202, 94, 87);
}

.modal-body {
  overflow-y: auto;
  max-height: 33vh;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/error-dialog-message/error-dialog-message.component.scss"],"names":[],"mappings":"AAAA;EACE,6CAAA;AACF;;AAEA;EACI,gBAAA;EACA,gBAAA;AACJ","sourcesContent":[".modal-content {\n  box-shadow: 2px 0px 12px 2px rgb(202, 94, 87);\n}\n\n.modal-body {\n    overflow-y: auto;\n    max-height: 33vh;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
