// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkboxColSize {
  flex: 0 0 6%;
  max-width: 6%;
}

.iconColSize {
  flex: 0 0 4%;
  max-width: 4%;
}

.activeCSS {
  color: #0f9d58;
  font-weight: bold;
}

.inActiveCSS {
  color: rgb(212, 63, 58);
  font-weight: bold;
}

.faIconBottom {
  margin-bottom: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/search-master/list-search-master/list-search-master.component.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,aAAA;AADJ;;AAIA;EACI,YAAA;EACA,aAAA;AADJ;;AAIA;EACI,cAAA;EACA,iBAAA;AADJ;;AAIA;EACI,uBAAA;EACA,iBAAA;AADJ;;AAKA;EACI,kBAAA;AAFJ","sourcesContent":["@import '../../../scss/variables';\n\n.checkboxColSize{\n    flex: 0 0 6%;\n    max-width: 6%;\n}\n\n.iconColSize{\n    flex: 0 0 4%;\n    max-width: 4%;\n}\n\n.activeCSS{\n    color: #0f9d58;\n    font-weight: bold\n}\n\n.inActiveCSS {\n    color: rgb(212, 63, 58);\n    font-weight: bold\n\n}\n\n.faIconBottom {\n    margin-bottom: 6px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
