import { Component, ViewChild, ElementRef, ViewEncapsulation, Inject  } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { AuthService } from '../../services/authentication/auth.service';
import { CONSTANTS } from 'app/app.constants';
import { MatSelect } from '@angular/material/select';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-screen-order',
  templateUrl: './screen-order.component.html',
  styleUrls: ['./screen-order.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScreenOrderComponent  {
  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('myInput') myInput: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: MatSelect;
  selectedAssignees:any = [];
  selectedAdmin: string = '';
  message:any={};
  title='';
  errors: any = {};
  activeAdmin: any = '';
  messageText = '';
  checkAdmin :boolean
  assignType:number;
  dueDateTimeCtrl =new UntypedFormControl(null);
  timeToComplete:string;
  timer: any = [];
  constructor( private auth: AuthService, public dialogRef: MatDialogRef<ScreenOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     
  }

  ngOnInit() {
    this.initDialog();
    this.activeAdmin = this.message['Current_Admin'];
    this.checkAdmin = (this.activeAdmin != this.auth.getUserId())
    this.message['admins'].map((admin) => admin.Type = 1);
    if(this.message['Search_Masters']) this.message['Search_Masters'].map((admin) => admin.Type = 2)
    this.selectedAssignees = this.getAllAssignee();
    if(this.activeAdmin) this.selectedAdmin = this.activeAdmin;
    this.timer.push(setTimeout(() => { this.myFirstFocus.focus() }, 0))
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  getSearchMasterRole() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return false
    else return true;
  }

  confirm() {
    this.dialogRef.close({ admin:this.selectedAdmin, msgText:this.messageText.trim(), assignType: this.message['assignType'], getTimeToCompl:this.timeToComplete});
    this.clearTime();
  }

  assignOrderToMe() {   
    // this.result ={ admin:this.auth.getUserId(), msgText:this.messageText.trim(), getTimeToCompl:this.timeToComplete}
    // this.clearTime();
    this.selectedAdmin = this.auth.getUserId();
  }

  unassign(){
    this.selectedAdmin="";
    this.timeToComplete="";
    this.confirm();
 }


  Close() {
    this.clearTime();
    this.dialogRef.close(null);
  }

  validateOrderETADateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors.Order_ETA = true;
    else delete this.errors.Order_ETA;
  }

  searchOptions(query){
    if(query.keyCode == 32 && query.type == "keydown") query.stopPropagation();
    let result = this.select(query.target.value);
    this.selectedAssignees = result;
  }

  select(query: string):string[]{
    let result: string[] = [];
    var assignees = this.getAllAssignee()
    for(let a of assignees){
      if(a['User_First_Name'].toLowerCase().indexOf(query.toLowerCase().trim()) > -1 || a['Email_Address'].toLowerCase().indexOf(query.toLowerCase().trim()) > -1){
        result.push(a)
      }
    }
    return result;
  }

  getAdmins(){
    return this.selectedAssignees.filter((user) => user.Type == 1);
  }

  getSearchers(){
    return this.selectedAssignees.filter((user) => user.Type == 2);
  }

  clearValue(myInput){
    if(myInput.nativeElement) myInput.nativeElement.value = '';
    else myInput.value = '';
    this.selectedAssignees = this.getAllAssignee()
  }

  getAllAssignee() {
    if (this.message['Search_Masters']) return this.message['admins'].concat(this.message['Search_Masters']);
    else if (!this.message['Search_Masters']) return this.message['admins']
  }

  clicked(event){
    if(event) this.timer.push(setTimeout(() => this.myInput.nativeElement.focus(), 0));
    else this.clearValue(this.myInput);
  } 

  getFormObject(event) {
    this.dueDateTimeCtrl = event;
    if(!this.dueDateTimeCtrl.value.taskDueDay && !this.dueDateTimeCtrl.value.taskDueHour) this.timeToComplete =null;
    if(this.dueDateTimeCtrl.value.taskDueDay) {
     this.timeToComplete = this.dueDateTimeCtrl.value.taskDueDay + "d";
      if(Boolean(this.dueDateTimeCtrl.value.taskDueHour))this.timeToComplete += " "+this.dueDateTimeCtrl.value.taskDueHour +"h";
    }else if(this.dueDateTimeCtrl.value.taskDueHour) this.timeToComplete = this.dueDateTimeCtrl.value.taskDueHour +"h";
  }

  clearTime(){
    // this.close();
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}