
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ProductService {

  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) { }

   createProduct(data) {
     data['Admin_User_ID'] = this.auth.getUserId();
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/product/add/' + this.auth.getUserId();
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  editProduct(data, productId) {
    data['Admin_User_ID'] = this.auth.getUserId();
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/product/edit/' + productId + "/"+ this.auth.getUserId();
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getProduct(id) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/admin/product/' + id
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getDefaultsProducts() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/products/default';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {        
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }


  getAllUsersInProducts(productId, mapType) {
    let data = {};
    data['Product_ID'] = productId;
    if(mapType == "Client") data['mapType'] = 2;
    else if(mapType == "Company") data['mapType'] =1;
    else if(mapType == "Organization") data['mapType'] =3;
    data['Admin_User_ID'] = this.auth.getUserId()
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/products/get/users'
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {        
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  deleteUsersFromProd(prodMappingId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/products/delete/'+prodMappingId
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {        
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  deleteAllUsersFromProd(deleteProductObj) {
    deleteProductObj['Admin_User_ID'] = this.auth.getUserId()
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/products/delete/all'
    return this.http.post(url, deleteProductObj, httpOptions).pipe(
      map((response: any) => {        
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllProductsData(pageNo, searchStr, filterBy, orderBy, sortBy){
    var data = {
      filterBy:filterBy,
      sort:sortBy,
      order:orderBy
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/products/prod/page/'+ pageNo +'/size/' + this.config.getNumRecordsPerPage();;
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data,  httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getProductForTemplateMapping(){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/products/email-templates/list';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {        
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getQBProdCategory(){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/products/quickbooks/category/list';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {        
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getQBItem(prodId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/products/quickbooks/product/' + prodId
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

}
