import { Component, OnInit, ViewChildren, QueryList, ElementRef, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { OrdersService, ConfigService, AuthService, ExpenseService, AwsS3Service, AbstractorService, UsermessageService, QuickbooksService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { Router, ActivatedRoute } from '@angular/router'
import { CONSTANTS } from '../../app.constants';
import { UUID } from 'angular2-uuid';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { RequireMatchValidator, ObjectValidator } from '../../services/validator/validator.service';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component'
import * as momentTimeZone from 'moment-timezone';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';

export class Expense {
  constructor(
    public Expense_Creation_Date: string,
    public Expense_Description: string,
    public Expense_ID: number,
    public Expense_Modification_Date: string,
    public Expense_Modified_By: string,
    public Expense_Name: string,
    public Expense_Status: number
    ) { }
  }
export class Abstractor {
  constructor(
    public Abstractor_Completed_Count: number,
    public Abstractor_InProgress_Count: number,
    public Abstrator_Full_Name: string,
    public Address_1: any,
    public Address_2: any,
    public Address_3: any,
    public Address_City: any,
    public Address_Country: any,
    public Address_Country_Abbr: any,
    public Address_Creation_Date: any,
    public Address_ID: any,
    public Address_Modification_Date: any,
    public Address_Modified_By: any,
    public Address_State: any,
    public Address_State_Abbr: any,
    public Address_Valid_From: any,
    public Address_Valid_To: any,
    public Address_ZipCode: any,
    public Company_ID: any,
    public Company_Name: any,
    public Contact_ID: string,
    public Contact_Num: string,
    public Email_Address: string,
    public Email_ID: string,
    public Organization_ID: any,
    public User_2FA: any,
    public User_Abstractor_Rating: number,
    public User_Abstractor_Rating_Count: number,
    public User_Created_By: string,
    public User_Creation_Date: string,
    public User_DOB: any,
    public User_Deactivated_By: any,
    public User_EIN: any,
    public User_Fax_Contact_ID: any,
    public User_Fax_Contact_Number: any,
    public User_First_Name: string,
    public User_Full_Name: string,
    public User_ID: string,
    public User_Last_Name: string,
    public User_Login_Name: string,
    public User_Modification_date: string,
    public User_Modified_by: string,
    public User_Notifications: any,
    public User_Role: number,
    public User_SSN: any,
    public User_Status: number,
    public User_TOSA: any,
    public User_Type: any
    ) { }
  }

@Component({
  selector: 'app-order-expense-edit',
  templateUrl: './order-expense-edit.component.html',
  styleUrls: ['./order-expense-edit.component.scss']
})
export class OrderExpenseEditComponent implements OnInit {
  @ViewChildren('myFirstFocus') myFirstFocus: QueryList<ElementRef>
  @ViewChildren('myFirstButtonFocus') myFirstButtonFocus: QueryList<ElementRef>;
  @ViewChild('file') fileInput: ElementRef;
  @ViewChild('qbBillDate') qbBillDate: ElementRef;
  showAddButton: boolean = false;
  constants = CONSTANTS;
  prompt = 'Add: ';
  orderDetails: any = {};
  orderExpenses: any = [];
  expenses: any = [];
  expensePaymentTypes: any = [];
  completeAddress: string = '';
  focused: boolean = false;
  error: any = {};
  bills = [];
  activeTab: number = 0;
  users = [];
  orderAbstractors = [];
  assignedAbstractors: Abstractor[] = [];
  availableAbstractors: Abstractor[] = [];
  timer: any = [];
  minDate: any;
  total: any = 0;
  totalbill: any = 0;
  validDueDate: boolean = false;
  date="01/02/1500"
  getUSDate = new Date(this.date).toLocaleString("en-US", { timeZone: "America/New_York" });
  minimum_Date = new Date(this.getUSDate); 
  hidePippinDetails: boolean = false
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private orderService: OrdersService,
    private matDialog:MatDialog,
    private config: ConfigService,
    private expenseService: ExpenseService,
    private s3Service: AwsS3Service,
    private ref: ChangeDetectorRef,
    private abstractorService: AbstractorService,
    private userMsg: UsermessageService,
    private quickbooks: QuickbooksService,
    private frmBuilder: UntypedFormBuilder,
    ) { }

  ngOnInit() {
    this.checkQuickBookSession();
    this.loadHideDetails();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
    this.getOrderDetails(this.route.snapshot.params['orderId']);
    this.route.queryParams.subscribe((params) => {
      if (params) {
        if (params && params.tab == 'expenses') { this.activeTab = 1; }
      }
    })
  }

  getActiveOrderExpenses() {
    return this.orderExpenses.filter((orderExp) => orderExp.Order_Expense_Status == CONSTANTS.orderExpenseStatus.active);
  }

  getOrderDetails(orderId) {
    this.orderService.getOrderDetails(orderId)
      .subscribe((details) => {
        this.orderDetails = details;
        let orderCreationDate = new Date(this.orderDetails.Order_Creation_Date).toLocaleString("en-US", { timeZone: "America/New_York" });
        this.minDate = new Date(orderCreationDate);
        if (!this.orderDetails.Property_State_Abbr) this.orderDetails.Property_State_Abbr = '';
        if (!this.orderDetails.Property_County) this.orderDetails.Property_County = '';
        this.getUserList();
        // this.getAvailableAbstractorsLocal();
        this.getExpenses();
        this.getOrderExpenseTypes();
        this.buildAddress();
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        // this.auth.logout();
        // this.router.navigate(['/login']);
      });
  }

  buildAddress() {
    this.completeAddress = '';
    if (this.orderDetails.Property_Address_1) this.completeAddress += this.orderDetails.Property_Address_1
    if (this.orderDetails.Property_Address_2) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += this.orderDetails.Property_Address_2
    }
    if (this.orderDetails.Property_City) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += this.orderDetails.Property_City
    }
    if (this.orderDetails.Property_State_Abbr) {
      if (this.completeAddress) this.completeAddress += ', '
      this.completeAddress += this.orderDetails.Property_State_Abbr
    }
    if (this.orderDetails.Property_ZipCode) {
      if (this.completeAddress) this.completeAddress += ' '
      this.completeAddress += this.orderDetails.Property_ZipCode
    }
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  ngAfterViewInit() {
    this.myFirstFocus.changes.subscribe((input) => {
      if (!this.focused) {
        if (input.first) {
          this.timer.push(setTimeout(() => { input.first.nativeElement.focus() }, 0));
          this.focused = true;
        }
      }
      else {
        if (document.activeElement && document.activeElement.getAttribute('type') != 'text') {
          if (input.last) {
            this.timer.push(setTimeout(() => { input.last.nativeElement.focus() }, 0));
            this.focused = true;
          }
        }
      }
    });
    this.myFirstButtonFocus.changes.subscribe((btn) => {
      if (btn.first) {
        btn.first.nativeElement.focus();
      }
    })
  }

  getExpenses() {
    this.orderService.getAllExpenses()
      .subscribe((expenses) => {
        this.expenses = expenses;
        this.getOrderExpenses();
      })
  }

  getNumberFormat(e, orderExpense) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((orderExpense.currInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (orderExpense.currInput.length > 0 || orderExpense.currInput.length < this.config.getLimitPriceMaxLength())) {
        orderExpense.currInput = orderExpense.currInput.slice(0, orderExpense.currInput.length - 1);
        e.target.value = this.numberFormat(orderExpense.currInput);
        orderExpense.Order_Expense_Amount = e.target.value;
      }
      else if (orderExpense.currInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          orderExpense.currInput = orderExpense.currInput + key;
          e.target.value = this.numberFormat(orderExpense.currInput);
          orderExpense.Order_Expense_Amount = e.target.value;
        }
      }
      return false;
    }
  }

  getNumberFormatForBill(e, billItem) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((billItem.currInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (billItem.currInput.length > 0 || billItem.currInput.length < this.config.getPriceMaxLength())) {
        billItem.currInput = billItem.currInput.slice(0, billItem.currInput.length - 1);
        e.target.value = this.numberFormat(billItem.currInput);
        billItem.Order_Bill_Item_Amount = e.target.value;
        this.setDirty(billItem);
      }
      else if (billItem.currInput.length < this.config.getPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          billItem.currInput = billItem.currInput + key;
          e.target.value = this.numberFormat(billItem.currInput);
          billItem.Order_Bill_Item_Amount = e.target.value;
          this.setDirty(billItem);
        }
      }
      return false;
    }
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return "0.00";
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  getOrderExpenses() {
    this.orderService.getOrderExpenseDetails(this.route.snapshot.params['orderId'])
      .subscribe((orderExpenses) => {
        this.orderExpenses = orderExpenses;
        this.orderExpenses.forEach((orderExpense) => {
          orderExpense.isExpenseAvailable = this.checkExpenseInList(orderExpense.Expense_ID)
          orderExpense.currInput = '';
        })
      })
  }

  checkExpenseInList(expenseId) {
    return this.expenses.some((expense) => expense.Expense_ID == expenseId)
  }

  getOrderBills() {
    this.expenseService.getOrderBillDetails(this.route.snapshot.params['orderId'])
      .subscribe((orderBills) => {
        this.bills = orderBills;
        this.bills.map((bill) => {
          bill.New_Bill_Documents = [];
          if (bill.Quickbooks_Bill_Date) bill.duplicateBillDate = bill.Quickbooks_Bill_Date;
          if (bill.Quickbooks_Bill_Total_Amount) {
            this.totalbill += Number(bill.Quickbooks_Bill_Total_Amount);
            this.total = this.totalbill.toFixed(2);
          }
          bill.Items.forEach((newItem, index) => {
            newItem.Is_Primary = false;
            newItem.currInput = '';
            newItem.dirty = false;
            this.setPrimaryFlag(bill);
            newItem.isExpenseAvailable = this.checkExpenseInList(newItem.Order_Bill_Expense_ID)
          })
        });
        this.syncOrderAbstractors();
        this.ref.detectChanges();
      })
  }

  getDefaultExpense(newExp, matchingKey) {
    var selectedValue = this.expenses.findIndex((expense) => expense.Expense_ID == newExp[matchingKey]);
    if (selectedValue > -1) return (this.expenses[selectedValue]);
    else return null;
  }

  setPrimaryFlag(bill) {
    var primaryIndex = -1;
    bill.Items.forEach((newItem, index) => {
      if (index == -1 && newItem.Expense_Name == this.orderDetails.Product_Description) primaryIndex = index;
    });
    if (primaryIndex == -1) primaryIndex = 0;
    bill.Items[primaryIndex].Is_Primary = true;
  }

  getOrderExpenseTypes() {
    this.orderService.getAllExpenseTypes()
      .subscribe((expensePaymentTypes) => {
        this.expensePaymentTypes = expensePaymentTypes;
      })
  }

  addExpense() {
    var newExpense: any = {};
    newExpense.Order_Expense_ID = 'new' + UUID.UUID();
    newExpense.Order_ID = this.route.snapshot.params['orderId'];
    newExpense.Expense_ID = null;
    newExpense.Order_Expense_Amount = 0;
    newExpense.Order_Expense_Status = CONSTANTS.orderExpenseStatus.active;
    newExpense.currInput = '';
    newExpense.isExpenseAvailable = true;
    this.orderExpenses = this.updatedList(this.orderExpenses, newExpense);
    this.ref.detectChanges();
  }

  updatedList(arr, newEntry) {
    return [...arr, newEntry]
  }

  removeExpense(expense) {
    if (isNaN(expense.Order_Expense_ID)) this.removeLocally(expense);
    else expense.Order_Expense_Status = 0;
  }

  validateListValues() {
    var flag = false;
    this.getActiveOrderExpenses().forEach((orderExpense) => {
      if (
        isNaN(orderExpense.Order_Expense_Amount) ||
        ((orderExpense.Expense_ID == null) || (orderExpense.Expense_ID == undefined)) ||
        ((orderExpense.Order_Expense_Payment_Type == null) || (orderExpense.Order_Expense_Payment_Type == undefined)) ||
        ((orderExpense.Order_Expense_Payment_Type == 99) && ((orderExpense.Order_Expense_Payment_Type_Other == '') || (orderExpense.Order_Expense_Payment_Type_Other == null)))
      ) {
        flag = true;
      }
    })
    return flag;
  }

  removeLocally(expense) {
    this.orderExpenses = this.orderExpenses.filter((exp) => exp.Order_Expense_ID !== expense.Order_Expense_ID);
  }

  getTotalExpense() {
    var total = 0;
    this.orderExpenses.forEach((expense) => {
      if (expense.Order_Expense_Status == CONSTANTS.orderExpenseStatus.active) {
        total += Number(expense.Order_Expense_Amount);
      }
    })
    return total.toFixed(2);
  }

  backToListing() {
    this.location.back();
  }

  editOrderExpenses() {
    if (this.checkIfMultipleQualiaFeeAdded()) this.openErrorPopup("We cannot have multiple line items attributed to Qualia fee.");
    else this.orderService.manageOrderExpense(this.route.snapshot.params['orderId'], this.orderExpenses)
      .subscribe(() => {
        this.userMsg.setSuccessMsg("Expense(s) recorded succesfully");
        this.router.navigate(['/orders/' + this.route.snapshot.params['orderId'] + '/view'])
      }, (err) => {
        this.error = err;
      })
  }

  checkIfMultipleQualiaFeeAdded() {
    let count = 0;
    this.orderExpenses.forEach((orderExp) => {
      if (orderExp.Expense_ID == environment.qualiaExpenseId) count++;
    })
    if (count > 1) return true;
    else return false;
  }


  getTotalBill(bill) {
    var total = 0;
    bill.Items.forEach((item) => {
      if (item.Order_Bill_Item_Status == CONSTANTS.orderBillItemStatus.active) {
        total += Number(item.Order_Bill_Item_Amount);
      }
    })
    return total.toFixed(2);
  }

  getGrandTotalBill() {
    var total = 0;
    this.getActiveBills().forEach((bill) => {
      bill.Items.forEach((item) => {
        if (item.Order_Bill_Item_Status == CONSTANTS.orderBillItemStatus.active) {
          total += Number(item.Order_Bill_Item_Amount);
        }
      })
    })
    return total.toFixed(2);
  }


  trackExpense(expense) {
    return expense.Order_Expense_ID;
  }

  clearError() {
    this.error = {};
  }

  buildBillAddress(user) {
    var address = '';
    if (user.Address_1) address += user.Address_1;
    if (user.Address_City) {
      if (address != '') address += ', '
      address += user.Address_City
    }
    if (user.Address_State_Abbr) {
      if (address != '') address += ', '
      address += user.Address_State_Abbr
    }
    if (user.Address_ZipCode) {
      if (address != '') address += ' - '
      address += user.Address_ZipCode
    }
    return address;
  }

  addNewItem(bill, expenseId = null) {
    var newItem: any = {};
    newItem.Order_Bill_Item_ID = 'new' + UUID.UUID();
    newItem.Order_ID = this.route.snapshot.params['orderId'];
    newItem.Order_Bill_Expense_ID = expenseId;
    newItem.Order_Bill_Item_Amount = 0;
    newItem.Order_Bill_Item_Status = CONSTANTS.orderBillItemStatus.active;
    newItem.currInput = '';
    newItem.isExpenseAvailable = true;
    bill.Items.push(newItem);
    this.ref.detectChanges();
  }

  removeBillItem(item, qboBillMappingId) {
    if (!item.Is_Primary) {
      if (isNaN(Number(item.Order_Bill_Item_ID))) {
        var mappingindex = this.bills.findIndex((bill) => bill.Quickbooks_Bill_Mapping_ID == qboBillMappingId)
        if (mappingindex > -1) {
          var itemIndex = this.bills[mappingindex].Items.findIndex((entry) => entry.Order_Bill_Item_ID == item.Order_Bill_Item_ID);
          this.bills[mappingindex].Items.splice(itemIndex, 1)
        }
      }
      else item.Order_Bill_Item_Status = this.constants.orderBillItemStatus.inActive;
    }
  }

  getActiveBillItems(bill) {
    return bill.Items.filter((item) => item.Order_Bill_Item_Status == this.constants.orderBillItemStatus.active)
  }

  uploadDocument(event, bill) {
    if (this.validateFilenames(event)) {
      this.fetchUploadProgress(bill);
      var fileUploads = [];
      if (this.checksize(event.target.files)) {
        if (this.checkFileType(event.target.files)) {
          for (var i = 0; i < event.target.files.length; i++) {
            var docId = UUID.UUID();
            bill.New_Bill_Documents.push({ docId: docId, Quickbooks_Bill_Mapping_Document_Original_Name: event.target.files[i].name, completed: 0 })
            fileUploads.push(this.uploadToS3AndUpdatePath(docId, event.target.files[i]))
          }
          Promise.all(fileUploads)
            .then((files) => {
              this.removeCompletedDocs(files, bill.New_Bill_Documents);
              var succesFiles = files.filter((file) => file !== undefined);
              if (succesFiles.length > 0) bill.Documents = bill.Documents.concat(succesFiles);
            })
            .catch((err) => {
              this.error = err;
            })
        }
        else this.openErrorPopup("Your selection contains prohibited file types.");
      }
      else this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
    }
    else this.openErrorPopup(CONSTANTS.apiErrors.validateFile);
    this.fileInput.nativeElement.value = null;
  }

  removeCompletedDocs(files, newFiles) {
    for (var i = 0, len = files.length; i < len; i++) {
      for (var j = 0, len2 = newFiles.length; j < len2; j++) {
        if (files[i] && files[i].docId === newFiles[j].docId) {
          newFiles.splice(j, 1);
          len2 = newFiles.length;
        }
      }
    }
  }

  validateFilenames(event) {
    var flag = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      var filename = event.target.files[i].name;
      if (filename.indexOf(',') > -1) flag++;
    }
    if (flag == 0) return true;
    else return false;
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(files, (file) => file.size > this.config.getIndividualMaxQBOFileSize() * 1024 * 1024);
    if (largeFiles.length > 0) return false;
    else return true;
  }

  checkFileType(files) {
    var inValidFiles = Array.prototype.filter.call(files, (file) => (this.config.getAllowedFileTypes().indexOf(this.getFileExt(file.name).toLowerCase()) == -1));
    if (inValidFiles.length > 0) return false;
    else return true;
  }

  uploadToS3AndUpdatePath(docId, file) {
    var filename = UUID.UUID()
    return this.s3Service.onManagedBillUpload(file, filename)
      .then((data) => {
        return {
          Quickbooks_Bill_Mapping_Document_Path: data.Key,
          Quickbooks_Bill_Mapping_Document_Original_Name: file.name,
          Quickbooks_Bill_Mapping_Document_Name: filename + '.' + this.getFileExt(file.name),
          Quickbooks_Bill_Mapping_Document_ID: docId,
          Quickbooks_Bill_Mapping_Document_Status: this.constants.orderBillDocumentStatus.active,
          docId: docId,
          size: file.size,
          Link: this.s3Service.getPreviewSignedUrl(data.Key, file.name)
        }
      })
      .catch((err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
      })
  }

  deleteDocument(bill, docMappingId) {
    if (isNaN(Number(docMappingId))) {
      var docIndex = bill.Documents.findIndex((document) => document.Quickbooks_Bill_Mapping_Document_ID == docMappingId);
      if (docIndex > -1) {
        this.s3Service.deleteFromS3(bill.Documents[docIndex].Quickbooks_Bill_Mapping_Document_Path)
          .then(() => {
            bill.Documents.splice(docIndex, 1);
          })
      }
    }
    else {
      var docIndex = bill.Documents.findIndex((document) => document.Quickbooks_Bill_Mapping_Document_ID == docMappingId);
      if (docIndex > -1) bill.Documents[docIndex].Quickbooks_Bill_Mapping_Document_Status = this.constants.orderBillDocumentStatus.inActive;
    }
  }

  extractFilename(filePath) {
    var parts = filePath.split('/');
    var filename = parts[parts.length - 1];
    return filename;
  }

  fetchUploadProgress(bill) {
    this.s3Service.progress
      .subscribe((res) => {
        var matchedIndex = bill.New_Bill_Documents.findIndex((doc) => doc.Quickbooks_Bill_Mapping_Document_Original_Name == res.name);
        if (bill.New_Bill_Documents[matchedIndex]) {
          bill.New_Bill_Documents[matchedIndex].completed = Math.round(((res.progress.loaded / res.progress.total) * 100) * 100) / 100;
          bill.New_Bill_Documents[matchedIndex].completed = Math.round(bill.New_Bill_Documents[matchedIndex].completed);
        }
      });
  }

  getFileExt(fileName) {
    var temp = fileName.split(".");
    return temp[(temp.length) - 1];
  }

  previewFile(Link) {
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.target = '_blank';
    link.href = Link;
    link.click();
    document.body.removeChild(link);
  }

  manageOrderBill(bill) {
    if(this.promptForPastDate(bill)){
    this.getActiveBillItems(bill).map((items, index) => items.Index = index + 1);
    // var estShift = momentTimeZone.tz(bill.Quickbooks_Bill_Date, 'America/New_York').isDST() ? 4 : 5;
    //   bill.Quickbooks_Bill_Date = moment(bill.Quickbooks_Bill_Date).utcOffset(moment(bill.Quickbooks_Bill_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
      bill.Total = this.calculateBillTot(bill);
      this.manageOrderBillRequest(bill);
      if (bill.Quickbooks_Bill_Date) bill.Quickbooks_Bill_Date = ''
    }
    
  }

  getActiveBills() {
    return this.bills.filter((bill) => bill.Quickbooks_Bill_Status == CONSTANTS.QBOBillStatus.active);
  }

  manageOrderBillRequest(data) {
    this.abstractorService.manageOrderBill(data, this.route.snapshot.params['orderId'])
      .subscribe((res) => {
        if (res != 0) {
          let msg = "";
          if (isNaN(data.Quickbooks_Bill_Mapping_ID)) {
            msg = "Bill created successfully";
          }
          else msg = "Bill updated successfully"
          this.totalbill = 0;
          this.userMsg.setSuccessMsg(msg);
          this.getOrderBills();
          if (this.error) this.clearError();
        }
      }, (err) => {
        this.error = err;
      })
  }

  getActiveBillDocuments(bill) {
    return bill.Documents.filter((doc) => doc.Quickbooks_Bill_Mapping_Document_Status == CONSTANTS.orderBillDocumentStatus.active)
  }

  calculateBillTot(bill) {
    var tot = 0;
    bill.Items.forEach((item) => {
      if (item.Order_Bill_Item_Status == CONSTANTS.orderBillItemStatus.active) {
        tot += Number(item.Order_Bill_Item_Amount);
      }
    })
    return tot.toFixed(2);
  }

  validateBillListValues(bill): boolean {
    var flag = false;
    var items = bill.Items;
    if (!isNaN(bill.Quickbooks_Bill_Mapping_ID)) flag = !bill.Quickbooks_Bill_Abstractor_User_ID ? true : false;
    else flag = ((!bill.Quickbooks_Bill_Abstractor_User_ID ? true : false) || bill.abstractorControl.errors != null);
    if (!flag) {
      items.forEach((billItem) => {
        if (
          isNaN(billItem.Order_Bill_Item_Amount) ||
          ((billItem.Order_Bill_Expense_ID == null) || (billItem.Order_Bill_Expense_ID == undefined))
        ) {
          flag = true;
        }
      })
    }
    return flag;
  }

  checkQuickBookSession() {
    if (this.quickbooks.getSharedSessionData()) {
      if (this.quickbooks.getSharedSessionData().url == '/edit-order-expense/' + this.route.snapshot.params['orderId']) {
        switch (this.quickbooks.getSharedSessionData().continue) {
          case 'manage_bill': this.manageOrderBillRequest(this.quickbooks.getSharedSessionData().data); break;
          default: break;
          case 'delete_bill': this.deleteQBOBillRequest(this.quickbooks.getSharedSessionData().data); break;
        }
      }
    }
  }

  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return true;
    else return false;
  }

  addNewBill() {
    var newBill: any = {};
    newBill.User_Status = null;
    newBill.Abstrator_Full_Name = null;
    newBill.Address_ID = null;
    newBill.Address_1 = null;
    newBill.Address_City = null;
    newBill.Address_State = null;
    newBill.Address_State_Abbr = null;
    newBill.Address_ZipCode = null;
    newBill.Address_Country = null;
    newBill.Address_Country_Abbr = null;
    newBill.Address_Valid_From = null;
    newBill.Address_Valid_To = null;
    newBill.Address_2 = null;
    newBill.Quickbooks_Bill_Mapping_ID = UUID.UUID()
    newBill.Pippin_Order_ID = this.route.snapshot.params['orderId']
    newBill.Quickbooks_Bill_ID = null;
    newBill.Quickbooks_Bill_Doc_Number = null;
    newBill.Quickbooks_Bill_Sync_Token = null;
    newBill.Quickbooks_Bill_Abstractor_User_ID = null;
    newBill.Quickbooks_Bill_Total_Amount = 0;
    newBill.Quickbooks_Bill_Status = CONSTANTS.QBOBillStatus.active;
    newBill.New_Bill_Documents = [];
    newBill.Items = [];
    newBill.Documents = [];
    newBill.Edit_Abs = true;
    newBill.abstractorControl = new UntypedFormControl(null, [Validators.required, ObjectValidator]);
    newBill.Quickbooks_Bill_Date = null;
    if (this.error) this.clearError();
    newBill.userGroupOptions = [
      {
        section: 'Assigned',
        users: newBill.abstractorControl.valueChanges
          .pipe(startWith({} as Abstractor),
            map(user => user && typeof user === 'object' ? user['Abstrator_Full_Name'] : user),
            map(Abstrator_Full_Name => Abstrator_Full_Name ? this._filter(this.assignedAbstractors, Abstrator_Full_Name) : this.assignedAbstractors.slice())
          )
      },
      {
        section: 'Available',
        users: newBill.abstractorControl.valueChanges
          .pipe(startWith({} as Abstractor),
            map(user => user && typeof user === 'object' ? user['Abstrator_Full_Name'] : user),
            map(Abstrator_Full_Name => Abstrator_Full_Name ? this._filter(this.availableAbstractors, Abstrator_Full_Name) : this.availableAbstractors.slice())
          )
      }
    ]
    this.addNewItem(newBill, environment.baseProductExpenseId);
    newBill.Items.map((item) => item.Is_Primary = true);
    this.bills.push(newBill);
    return newBill;
  }


  //   getAvailableAbstractorsLocal(){
  //     let AllAbstractors = JSON.parse(sessionStorage.getItem('AllAbstractors'));
  //     if(AllAbstractors) this.getUserList(AllAbstractors)         
  //     else this.getAvailableAbstractorList();
  // }

  //   getAvailableAbstractorList(){
  //     this.abstractorService.getAllAbstractorsList().subscribe((abstractors) => { 
  //       if(abstractors){
  //         this.getUserList(abstractors)         
  //         sessionStorage.setItem('AllAbstractors',JSON.stringify(abstractors)); 
  //        setTimeout(function(){sessionStorage.removeItem("AllAbstractors");}, CONSTANTS.sessiontimeOut.timeOut);
  //       }
  //     },
  //     (err) => {
  //       this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
  //     })
  //   }

  getUserList() {
    this.abstractorService.getAllEnabledDisabledAbs()
      .subscribe((users) => {
        this.users = users;
        this.getOrderBills();
      })
  }

  //   getUserList(users){
  //     this.users = users;
  //     this.getOrderBills();
  // }

  syncOrderAbstractors() {
    this.getOrderAbstractorHistory()
      .subscribe((orderAbstractors) => {
        this.orderAbstractors = orderAbstractors;
        this.reArrangeUserList(this.users, orderAbstractors);
        this.addDefaultBills(orderAbstractors);
      })
  }

  reArrangeUserList(abstractorUsers, history) {
    if (history.length > 0) {
      var commonUsers = [];
      history.forEach((orderAbstractor) => {
        let matchedIndex = abstractorUsers.findIndex((absUser) => orderAbstractor.Abstrator_User_ID == absUser.User_ID);
        let matchedUser = abstractorUsers.splice(matchedIndex, 1);
        commonUsers.push(matchedUser[0]);
      })
      this.users = commonUsers.concat(abstractorUsers);
      this.assignedAbstractors = commonUsers;
      this.availableAbstractors = abstractorUsers;
    }
    else {
      this.users = abstractorUsers;
      this.assignedAbstractors = [];
      this.availableAbstractors = abstractorUsers;
    }
  }

  addDefaultBills(orderAbstractors) {
    if (orderAbstractors.length > 0) {
      let defaultUsers = orderAbstractors.filter((orderAbstractor) => orderAbstractor.Bill_Details == null);
      if (defaultUsers.length > 0) {
        defaultUsers.forEach((defaultUser) => {
          var bill = this.addNewBill();
          bill.isDefault = true;
          bill.Quickbooks_Bill_Abstractor_User_ID = defaultUser.Abstrator_User_ID;
          var userIndex = this.users.findIndex((user) => user.User_ID == defaultUser.Abstrator_User_ID)
          bill.abstractorControl.setValue(this.users[userIndex]);
          this.assigNewBillDetails(this.users[userIndex], bill);
        })
      }
    }
  }

  assigNewBillDetails(details, bill) {
    bill.User_Status = details.User_Status;
    bill.Abstrator_Full_Name = details.Abstrator_Full_Name;
    bill.Address_ID = details.Address_ID;
    bill.Address_City = details.Address_City;
    bill.Address_State_Abbr = details.Address_State_Abbr;
    bill.Address_ZipCode = details.Address_ZipCode;
    bill.Address_Valid_From = details.Address_Valid_From;
    bill.Address_Valid_To = details.Address_Valid_To;
    bill.Address_2 = details.Address_2;
    bill.Address_1 = details.Address_1;
    bill.Address_Country = details.Address_Country;
    bill.Address_Country_Abbr = details.Address_Country_Abbr;
    bill.Email_Address = details.Email_Address;
    bill.Contact_Num = details.Contact_Num;
  }

  deleteQBOBill(bill) {
    this.promptDelete().afterClosed()
      .subscribe((res) => {
        if (res) {
          this.cleanUpS3Files(bill.Documents);
          this.deleteQBOBillRequest(bill.Quickbooks_Bill_Mapping_ID);
        }
      })
  }

  removeBill(bill) {
    if (isNaN(bill.Quickbooks_Bill_Mapping_ID)) {
      var matchedIndex = this.bills.findIndex((billEntry) => billEntry.Quickbooks_Bill_Mapping_ID == bill.Quickbooks_Bill_Mapping_ID)
      if (matchedIndex > -1) {
        this.cleanUpS3Files(this.bills[matchedIndex].Documents);
        this.bills.splice(matchedIndex, 1);
        if (this.error) this.clearError();
      }
    }
  }

  getOrderAbstractorHistory() {
    return this.abstractorService.getOrderAbstractorHistory(this.route.snapshot.params['orderId'])
  }

  _filter(users, value): Abstractor[] {
    var result = users.filter(user => { if (user.Abstrator_Full_Name && (user.Abstrator_Full_Name.toLowerCase().indexOf(value.toLowerCase()) === 0)) return true; });
    if (result.length === 0) result = [{ Abstrator_Full_Name: 'No users found' }];
    return result;
  }

  displayAbstractor(user?: Abstractor): any {
    return user ? user.Abstrator_Full_Name : user;
  }

  abstractorSelected(selectedUser, bill) {
    bill.Quickbooks_Bill_Abstractor_User_ID = selectedUser.User_ID;
    this.assigNewBillDetails(selectedUser, bill);
  }

  deleteQBOBillRequest(qboBillMappingId) {
    this.abstractorService.deleteOrderBill(qboBillMappingId, this.route.snapshot.params['orderId'])
      .subscribe((res) => {
        if (res != 0) {
          this.userMsg.setSuccessMsg("Bill deleted succesfully");
          this.totalbill = 0;
          this.total = 0;
          this.getOrderBills();
          if (this.error) this.clearError();
        }
      }, (err) => {
        this.error = err;
      })
  }

  promptDelete() {
    return this.matDialog.open(ConfirmComponent, {data:{
      title: 'Delete Bill',
      message: 'Are you sure you want to delete this bill ?'
    }, ...this.config.getDialogOptions()})
  }

  promptForUnSavedChanges() {
    return this.matDialog.open(ConfirmComponent, {data:{
      title: 'Unsaved changes',
      message: 'Are you sure you want to discard the changes ?'
    }, ...this.config.getDialogOptions()});
  }

  verifyAndGoBack() {
    if (this.verifyForUnsavedChanges()) {
      this.promptForUnSavedChanges().afterClosed()
        .subscribe((res) => {
          if (res) this.backToListing()
        })
    }
    else this.backToListing();
  }

  verifyForUnsavedChanges(): boolean {
    var flag = false;
    flag = this.checkForNewBills() || this.checkForDocsAndItemUpdates() || this.checkForDefaultBillUpdates();
    return flag;
  }

  checkForNewBills() {
    return this.bills.filter((bill) => !bill.isDefault).some((bill) => isNaN(bill.Quickbooks_Bill_Mapping_ID));
  }

  checkForDocsAndItemUpdates() {
    return this.bills.filter((bill) => !bill.isDefault).some((bill) => this.checkForItemUpdates(bill.Items) || this.checkForDocUpdates(bill.Documents));
  }

  checkForDefaultBillUpdates() {
    return this.bills.filter((bill) => bill.isDefault).some((bill) => {
      return (bill.Items.some((item) => item.dirty) || bill.Documents.some((doc) => isNaN(doc.Quickbooks_Bill_Mapping_Document_ID)))
    })
  }

  checkForItemUpdates(Items) {
    return Items.some((item) => {
      return (isNaN(item.Order_Bill_Item_ID) || (item.Order_Bill_Item_Status == this.constants.orderBillItemStatus.inActive) || (item.dirty))
    })
  }


  checkForDocUpdates(documents) {
    return documents.some((doc) => {
      return (isNaN(doc.Quickbooks_Bill_Mapping_Document_ID) || (doc.Quickbooks_Bill_Mapping_Document_Status == this.constants.orderBillDocumentStatus.inActive))
    })
  }

  setDirty(item) {
    item.dirty = true;
  }

  isNewBill(bill) {
    return isNaN(bill.Quickbooks_Bill_Mapping_ID);
  }

  ngOnDestroy() {
    this.cleanUpTempFiles();
    this.timer.forEach((time) => {
      clearTimeout(time)
    });
  }

  cleanUpTempFiles() {
    var files = [];
    this.bills.forEach((bill) => {
      var billFiles = bill.Documents.filter((doc) => isNaN(doc.Quickbooks_Bill_Mapping_Document_ID));
      Array.prototype.push.apply(files, billFiles);
    })
    if (files.length > 0) this.cleanUpS3Files(files);
  }

  cleanUpS3Files(files) {
    var collection = [];
    files.forEach((file) => collection.push(this.s3Service.deleteFromS3(file.Quickbooks_Bill_Mapping_Document_Path)))
    Promise.all(collection).then(() => { })
  }

  checkForSaveBtn(validateStatus, newDocs) {
    if (validateStatus) return true;
    else {
      if (newDocs.length > 0) return true;
      else return false;
    }
  }

  // checkDaylight(date) {
  //   if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
  //   else return 'EST'
  // }

  checkForBillDate(bill) {
  //  let billQuick =  new Date(momentTimeZone.tz(bill.Quickbooks_Bill_Date, 'America/New_York').format('MM/DD/YYYY HH:mm'));
  //    if(billQuick.getFullYear() >= 1500){
  //     if (bill.Quickbooks_Bill_Date) {
  //       var estShift = momentTimeZone.tz(bill.Quickbooks_Bill_Date, 'America/New_York').isDST() ? 4 : 5;
  //       let compareBillDate = moment(bill.Quickbooks_Bill_Date).utcOffset(moment(bill.Quickbooks_Bill_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DD');
  //       return this.bills.some((billDetails) => (moment(billDetails.duplicateBillDate, "YYYY-MM-DD").isSame(compareBillDate) && bill.Quickbooks_Bill_ID === billDetails.Quickbooks_Bill_ID))
  //     } else return true
  //    } else return true
    let billQuick =  new Date(bill.Quickbooks_Bill_Date);
     if(billQuick.getFullYear() >= 1500){
      if (bill.Quickbooks_Bill_Date) {
        let compareBillDate = moment(bill.Quickbooks_Bill_Date).utc().format('YYYY-MM-DD');
        return this.bills.some((billDetails) => (moment(billDetails.duplicateBillDate, "YYYY-MM-DD").utc().isSame(compareBillDate) && bill.Quickbooks_Bill_ID === billDetails.Quickbooks_Bill_ID))
      } else return true
     } else return true
     
  }

  checkForNewBilldate(bill) {
    return !(Boolean(bill.Quickbooks_Bill_Date));
  }


  getPippinDate(event, billMapId) {
    this.bills.forEach((bills)=>{
      if(bills.Quickbooks_Bill_ID != null){
        bills['previousBillDate'] = bills.Quickbooks_Bill_Date
      }
    })
    if(this.getActiveBills().length > 0 ) {
      this.getActiveBills().forEach((bill) => {
        if(event && event.Pippin_Date ) {
          if(event.Pippin_Date._d.getFullYear() >= '1500'){
            if(bill.Quickbooks_Bill_Mapping_ID == billMapId){
              bill['Quickbooks_Bill_Date'] = event.Pippin_Date;
            this.validDueDate=false;
          }
          }else this.validDueDate=true;
    }else if(bill.Quickbooks_Bill_Mapping_ID == billMapId)bill['Quickbooks_Bill_Date'] = null;
    this.ref.detectChanges();
    })
    } 
}


  promptForPastDate(bill) {
    const sameorBefore = moment(this.minDate).isSameOrBefore(bill.Quickbooks_Bill_Date, 'day')
    if (!sameorBefore) {
      this.matDialog.open(ConfirmComponent, {data:{
        title: 'Abstractor bill date',
        message: 'The bill is dated before the order creation date. Are you sure you want to proceed?'
      }, ...{ closeByClickingOutside: false }}).afterClosed()
        .subscribe((res) => {
          if(res){
          this.getActiveBillItems(bill).map((items, index) => items.Index = index + 1);
          // var estShift = momentTimeZone.tz(bill.Quickbooks_Bill_Date, 'America/New_York').isDST() ? 4 : 5;
          // bill.Quickbooks_Bill_Date = moment(bill.Quickbooks_Bill_Date).utcOffset(moment(bill.Quickbooks_Bill_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
          bill.Total = this.calculateBillTot(bill);
          this.manageOrderBillRequest(bill);
          if (bill.Quickbooks_Bill_Date) bill.Quickbooks_Bill_Date = ''
          }else {
            if (this.bills.length > 0) {
              this.bills.forEach((prevBill) => {
                if (bill.Quickbooks_Bill_Mapping_ID === prevBill.Quickbooks_Bill_Mapping_ID) {
                  bill['Quickbooks_Bill_Date'] = new Date(prevBill.previousBillDate)
                  return false;
                } else {
                  if (bill.Quickbooks_Bill_Mapping_ID === prevBill.Quickbooks_Bill_Mapping_ID)  bill['Quickbooks_Bill_Date'] = null;
                  return false
                }
              })
            } else {
              bill['Quickbooks_Bill_Date'] = null;
              return false;
            }
          }
        })
    } else return true;
  }

  loadHideDetails(){
    this.hidePippinDetails = Number(this.auth.getUserRole()) == this.constants.userRole.searchMaster && 
    Number(this.auth.getSearchMasterType()) == this.constants.userType.externalSM;
  }
}
