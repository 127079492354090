// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog {
  margin-top: 4rem;
}

.form-border {
  border: 1px solid #e6e1e1;
  border-radius: 2px;
}

.modal-content {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/new-abs-quote/new-abs-quote.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,yBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".modal-dialog {\n  margin-top: 4rem;\n}\n\n.form-border {\n  border: 1px solid #e6e1e1;\n  border-radius: 2px;\n}\n\n.modal-content {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
