// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sec-desc {
  font-weight: 500;
  letter-spacing: -0.435px;
}

.save-btn {
  top: 16px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/app/counties/counties.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,wBAAA;AACF;;AAEA;EACE,SAAA;EACA,kBAAA;AACF","sourcesContent":[".sec-desc{  \n  font-weight: 500;\n  letter-spacing: -0.435px;\n}\n\n.save-btn{\n  top:16px;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
