import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormsModule, FormBuilder, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subject, ReplaySubject } from 'rxjs';
import * as _ from 'underscore';
import { MatSelect } from '@angular/material/select';
import { CONSTANTS } from '../../app.constants';

@Component({
  selector: 'app-mat-multi-select-for-list',
  templateUrl: './mat-multi-select-for-list.component.html',
  styleUrls: ['./mat-multi-select-for-list.component.scss']
})
export class MatMultiSelectForListComponent implements OnInit {
  constants = CONSTANTS;
  selectedOption: any;
  handleBy: string;
  timer: any = [];
  
  protected _onDestroy = new Subject<void>();

  filteredEntityList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  entityFilterCtrl = new UntypedFormControl();
  @Input() entityList: Array<any>;
  @Input() isMultipleSelection: boolean = false;
  @Input() isMultiEnabled: boolean = false;

  @Input() matSearchCtrl = new UntypedFormControl();
  @Input() selectedId: string;
  @Input() placeHolderSel: any;
  @Input() matSelPlaceHolder: string;
  @Input() entityName: string = 'Entity';
  @Input() selectKey: string;
  @Input() filterKey: string;
  @Input() initializeData: any = null;
  @Input() selected: any;
  @Input() disabled: boolean = false;
  @Input() hiddenComp: boolean = false;
  @Input() selectAllValue: any = 'all';
  @Input() isSelectAllEnabled: boolean;
  @Input() isLoading: boolean = false;
  @Input() requiredBool: boolean = false;
  @Input() selectAll: boolean = true;
  @Input() dynamicFilter:boolean=false;
  
  @Output() selectedChange = new EventEmitter<any>();
  @Output() detectChange = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();
  @Output() manualChange = new EventEmitter<any>();
  @Output() queryChange=new EventEmitter<String>();
  @ViewChild('matSelSearch') matSelSearch: MatSelect
  @ViewChild('myInput') myInput: ElementRef
  constructor(
    private ref: ChangeDetectorRef,

  ) { }

  ngOnInit() {
    this.entityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if(this.dynamicFilter){
          this.queryChange.emit(this.entityFilterCtrl.value);
        }else{
          this.filterEntity()
        }
        
      });
      this.timer.push(setTimeout(() => {
        if (this.selected) { 
          this.selectedOption = this.selected;
          this.matSearchCtrl.setValue(this.selected);
        }
      }, 0));
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let prop in changes) {
      if (prop == 'selected') {
        this.selectedOption = this.selected;
        this.ref.detectChanges();
      }
      if (prop == 'entityList' && this.filteredEntityList) {
        if (_.isArray(this.entityList)) {
          this.filteredEntityList.next(this.entityList.slice())
        }
        if (this.initializeData) {
          this.selectedOption = this.initializeData;
        };
      }

    }
  }

  protected filterEntity() {
    if (!this.entityList) {
      return;
    }
    let search = this.entityFilterCtrl.value;
    if(this.selectAll){
      if (!search) {
        this.filteredEntityList.next(this.entityList.slice());
        // this.isMultiEnabled=true;
      }
      else {
        search = search.toLowerCase();
        this.isMultiEnabled=false;
      }
    }
    else{
      if (!search) this.filteredEntityList.next(this.entityList.slice()); 
      else search = search.toLowerCase();
    }
    
    this.filteredEntityList.next(
      this.entityList.filter(entityData => entityData[this.filterKey].toLowerCase().indexOf(search) > -1));
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getSelectedOptionData(selectedList:any[]){
    if(selectedList.length>0){
       return this.entityList.filter((value)=>{
        return selectedList.includes(value.Organization_ID)
       });
    }

    return [];
   }

  emitSelected(event) {

      if(event.length != 0){
          this.selectedChange.emit(this.selectedOption);
          this.selectionChange.emit(this.getSelectedOptionData(this.selectedOption));
      }

  }

  emitManualSelectionChange(event) {
      if(event.value.length == 0) {
        event.value = ""
          this.selectedChange.emit(event.value);
          this.selectionChange.emit(event.value);
      }else{
        if (event.value == "") {     
          this.selectedChange.emit(event.value);
          this.selectionChange.emit(event.value);
        }
        this.manualChange.emit(event);
      }     
  }

  clearSelection(ev, event){
    if(this.detectChange){
      this.detectChange.emit(true)     
      this.selectedOption ="";
      this.selectedChange.emit(this.selectedOption);
      this.selectionChange.emit(this.selectedOption);
    }
    this.ref.detectChanges();
    event.stopPropagation();     
  }


}
