import { Component, OnDestroy, OnInit } from "@angular/core";
import { OrdersService } from "app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-chk-delivery',
  templateUrl: './chk-delivery.component.html',
  styleUrls: ['./chk-delivery.component.scss']
})
export class ChkDeliveryComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  orderNotes: any;
  clientSOP: any;
  clientSopLink : any;

  constructor(
    private ordersService: OrdersService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.ordersService.orderNotes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderNotes = data));
    this.ordersService.clientSOP$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.clientSOP = null;
        if(data && data[0] && data[0].Note_Text !== undefined)
          this.clientSOP = data[0].Note_Text;
      });
  }

  validURL(str) {
      const urlRegex = /(https?:\/\/[^\s]+)/;
      const match = str.match(urlRegex);

      if (match) {
        const extractedURL = match[0];
        if(!this.clientSopLink) this.clientSopLink = extractedURL
        return extractedURL;
      } else {
        return false;
      }

    // var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    // '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    // '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    // '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    // '(\\?[;&a-z\\d%_.~+=-]*)?','i'); // query string
    // // '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    // // '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    //   let result = !!pattern.test(str);
    //   if(result) return true;
    //   else {
    //     let booleanVal = false;
    //     try{
    //       let urlObj = new URL(str);
    //       if(urlObj && (str.includes('https') || str.includes('http'))) {booleanVal = true;}
    //     }
    //     catch (e){}
    //     return booleanVal;
    //   }
  }
  
}
