import { Component, OnDestroy, OnInit } from "@angular/core";
import { CountiesService } from "app/services/counties/counties.service";
import { ColDef, GridApi, ModuleRegistry } from "ag-grid-community";
import { AgGridAngular } from "@ag-grid-community/angular";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { Router } from "@angular/router";
import * as _ from "underscore";
import { MatTabsModule } from "@angular/material/tabs";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ConfigService, PricingService } from "app/services";
import * as moment from "moment";
import { SuccessComponent } from '../../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: "app-review-data-depth-upld",
  standalone: true,
  imports: [AgGridAngular, MatTabsModule],
  templateUrl: "./review-data-depth-upld.component.html",
  styleUrl: "./review-data-depth-upld.component.scss",
})
export class ReviewDataDepthUpldComponent implements OnInit, OnDestroy {
  groupedData: any = [];
  groupedStates: any = [];
  selectedIndex: number = -1;
  modules = [ClientSideRowModelModule];
  counties: any = [];
  countyMappings: any = {};
  totalExceptions = [];
  private ngUnsubscribe = new Subject();
  constructor(
    private countiesService: CountiesService,
    private router: Router,
    private pricingService: PricingService,
    private config: ConfigService,
    private matDialog:MatDialog,
  ) {}

  ngOnInit(): void {
    this.countiesService.validatedDepthInfo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.length > 0) {
          let grouped = _.groupBy(data, "State");
          this.selectedIndex = 0;
          this.groupedStates = Object.keys(grouped);
          this.groupedStates.forEach((stateKey) => {
            this.groupedData.push({
              key: stateKey,
              rowData: grouped[stateKey],
              colDefs: [],
              api: null,
            });
          });
          this.getCountyList(this.groupedData[0]);
        } else this.router.navigate(["upload-types"]);
      });
  }

  cellValueChanged(event) {
    if(event.colDef.cellDataType == 'date'){      
      let modifiedRow = Object.assign({}, event.data);
      let matchedStateData = this.groupedData.find((stData) => stData.key == modifiedRow.State)
      let modifiedGridData = matchedStateData.rowData[event.rowIndex]
      if(modifiedRow[event.colDef.field]){ 
        let selected = moment(modifiedRow[event.colDef.field]).format('MM-DD-YYYY');
        event.node.setDataValue(event.colDef.field, moment.utc(selected).startOf('day').toISOString());
        modifiedRow[event.colDef.field]= moment.utc(selected).startOf('day').toISOString();
        modifiedGridData[event.colDef.field]= moment.utc(selected).startOf('day').toISOString(); 
      }
      else  {
        event.node.setDataValue(event.colDef.field,null); 
        modifiedRow[event.colDef.field]= null;
        modifiedGridData[event.colDef.field]= null;
      }      
      let dateExp = modifiedRow.Exceptions.findIndex((e) => e == event.colDef.field);
      if(this.validateDate(modifiedRow[event.colDef.field])){
        if(dateExp > -1) {
          modifiedRow.Exceptions.splice(event.colDef.field, 1)
        };
      }
      else {
        if(dateExp == -1) {
          modifiedRow.Exceptions.push(event.colDef.field)
        };
      }
      event.api.refreshCells({ force: true });
    }
    if(event.colDef.field == 'County'){
      let modifiedRow = Object.assign({}, event.data);
      let mappedCountyValue = this.countyMappings[modifiedRow[event.colDef.field]]
      modifiedRow.State_ID  = mappedCountyValue ? mappedCountyValue : null;
      let stateExp = modifiedRow.Exceptions.findIndex((e) => e == 'State-County');
      if(modifiedRow.State_ID){        
        if(stateExp > -1) modifiedRow.Exceptions.splice(stateExp, 1);
      }
      else {
        if(stateExp == -1) modifiedRow.Exceptions.push('State-County');
      }
    }
  }

  upload() {    
    this.checkExceptions();
    if(this.totalExceptions.length == 0){
      this.countiesService.postBulkUpldData(this.groupedData)
      .subscribe((uploadResponse) => {   
         uploadResponse.forEach((state) => {          
          state.failed.forEach((failure) => {            
            this.totalExceptions.push(`${state.key} - ${failure}`)
          })          
         })
         if(this.totalExceptions.length == 0) {
          this.openSuccessPopup('All rows uploaded successfully.');
          this.router.navigate(['upload-types'])
         }
      }, (err) => {
        this.totalExceptions.push(`Upload failed!`);
      })
    }
  }

  goBack() {
    this.router.navigate(["upload-types"]);
  }

  createColumnDefs(sampleRecord) {
    let cols = [];
    let blackList = ["Exceptions", "State_ID"];
    let nonEditableFlds = ["State"];
    let yesNoFlds = ["Online FS"];
    let dropDownFlds = ["County"];
    let dateFields = [
      "Index",
      "Images",
      "Unindexed",
      "GO",
      "Grantor Grantee",
      "TitlePoint Images",
    ];
    Object.keys(sampleRecord).forEach((key) => {
      if (!blackList.includes(key)) {
        let fieldCol = {
          headerName: key,
          field: key,
          editable: nonEditableFlds.includes(key) ? false : true,
        };
        if (yesNoFlds.includes(key)) {
          fieldCol["cellEditor"] = "agSelectCellEditor";
          fieldCol["cellEditorParams"] = {
            values: extractValues({ TRUE: "TRUE", FALSE: "FALSE" }),
          };
        }
        if (dropDownFlds.includes(key)) {
          fieldCol["cellEditor"] = "agSelectCellEditor";
          fieldCol["cellEditorParams"] = {
            values: extractValues(this.countyMappings),
          };
          fieldCol["valueFormatter"] = (params) => {
            if(params && params.value) return params.value.toLowerCase();
            else return null
          }
        }
        if (dateFields.includes(key)) {
          fieldCol["cellDataType"] = "date";
          fieldCol["valueFormatter"] = (params) => {
            if(params && params.value) return moment.utc(params.value).format('MM-DD-YYYY');
            else return null
          }
        }
        cols.push(fieldCol);
      }
    });
    cols.unshift({
      headerName: 'Row',
      valueGetter: 'node.rowIndex + 1',
      sortable: false,
      filter: false,
    })
    return cols;
  }

  selectedTabChange(event) {
    this.getCountyList(this.groupedData[event.index]);
  }

  ngOnDestroy(): void {
    this.countiesService.resetValidatedData();
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  getCountyList(group) {
    this.pricingService.getAvailableCountyList(group.key).subscribe((data) => {
      this.counties = data;
      this.countyMappings = {};
      this.counties.forEach((county) => {
        this.countyMappings[county.County_Name.toLowerCase()] = county.State_ID;
      });
      group.api.setGridOption(
        "columnDefs",
        this.createColumnDefs(group.rowData[0])
      );
    });
  }

  checkExceptions(){
    this.totalExceptions = []
    this.groupedData.forEach((group) => {
      group.rowData.forEach((row, index) => {
        if(row.Exceptions.length > 0) {
          row.Exceptions.forEach((exception) => {            
            this.totalExceptions.push(`${group.key} - Invalid ${exception} at row ${index+1}`)
          })
        }
      })
    })
  }

  validateDate(dateVal) {
    if (dateVal) {
      return (
        moment(dateVal, moment.ISO_8601).isValid()
      )
    } else return true
  }

  openSuccessPopup(msg) {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: "Success",
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  
}

function extractValues(mappings) {
  return Object.keys(mappings);
}
