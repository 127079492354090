// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clearfix {
  padding-top: 8px;
}

.of-pos {
  float: right;
  margin-top: 3px;
}

.clr-red-bg {
  background: #EE4751 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/order-checklist/chk-docs/chk-docs.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;AACF;;AAEA;EACE,8BAAA;AACF","sourcesContent":[".clearfix{\n  padding-top: 8px;\n}\n\n.of-pos{\n  float: right;\n  margin-top: 3px;\n}\n\n.clr-red-bg{\n  background: #EE4751 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
