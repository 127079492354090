import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService, SchedulingService } from '../../services'
import { ConfigService, UsermessageService,} from '../../services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import * as moment from 'moment';
import { CONSTANTS } from '../../app.constants';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-holiday-schedule',
  templateUrl: './holiday-schedule.component.html',
  styleUrls: ['./holiday-schedule.component.scss']
})
export class HolidayScheduleComponent implements OnInit {
  delHolidateErrMsg: any = {};
  newHolidayGroupFrm: UntypedFormGroup;
  holidaylist: [];
  error: string = '';
  newholidate: any = {};    
  @Input() linkData: any
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() createEvent = new EventEmitter<boolean>();
  @Output() saveEvent = new EventEmitter<boolean>();
  @Output() deleteEvent = new EventEmitter<boolean>();
  getUSDateString = new Date('01/02/1500').toLocaleString("en-US", { timeZone: "America/New_York" });
  min_Date = new Date(this.getUSDateString);
  constructor(
    public holidayScheduling: SchedulingService,
    private config: ConfigService,
    private fb: UntypedFormBuilder,
    private userMsg: UsermessageService,
    public auth: AuthService,
    private matDialog:MatDialog,

  ) {
    this.newHolidayGroupFrm = fb.group({
      'Holiday_Date': [{ disabled: false },Validators.compose([Validators.required])],
      'Holiday_Name': [null, Validators.compose([Validators.required])],
    })
    this.newHolidayGroupFrm.valueChanges.subscribe((data) => {
      this.delHolidateErrMsg = '';
    });
 
   }

  ngOnInit() {
  }

  createholidate(data) {
    if(data.Holiday_Date) data.Holiday_Date = moment(data.Holiday_Date).format("YYYY-MM-DD")
    if(this.delHolidateErrMsg) this.delHolidateErrMsg={};
    this.holidayScheduling.createNew(data)
    .subscribe((result) => {
      if (result) {
        this.createEvent.emit(true);
        this.cancelholidate();
        this.userMsg.setSuccessMsg("Holiday Record Created successfully");
        this.DeleteStoredData();
      }
    }, (err) => {
      this.delHolidateErrMsg = err;
    })
  }
  

  edit(data) {
    if(data.Holiday_Date) data.Holiday_Date = moment(data.Holiday_Date).format("YYYY-MM-DD")
    if(this.delHolidateErrMsg) this.delHolidateErrMsg={};
    this.holidayScheduling.editholiday(data)
    .subscribe((result) => {
      if (result) {
        this.saveEvent.emit(true);
        this.userMsg.setSuccessMsg("Holiday Record Updated successfully");
        this.DeleteStoredData();
      }
    }, (err) => {
      this.delHolidateErrMsg = err;
    })
  }

  DeleteStoredData(){
    sessionStorage.removeItem("holidays");
  }
  
  deleteFile(data) {
    this.matDialog.open(ConfirmComponent, {data:{
      title: 'Delete Holiday Record',
      message: 'Are you sure you want to delete ' + data.Holiday_Name + ' ?'
    },...this.config.getDialogOptions()}).afterClosed()
    .subscribe((res) => {
      if (res) {
        this.holidayScheduling.deletedate(data)
        .subscribe((res)=>{
          this.deleteEvent.emit(true);
          this.userMsg.setSuccessMsg("Holiday Record deleted successfully");
          this.DeleteStoredData();
        }, (err) =>{
          this.delHolidateErrMsg = err;
        })
      }
    })
  }


  cancelholidate() {
    this.linkData.Holiday_Date = null;
    this.linkData.Holiday_Name = null;
    this.linkData.Cancel = true;
    this.cancelEvent.emit(true);
  } 

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }  

  getPippinDate(event) {
    if(event && event.Pippin_Date ) {
      this.linkData['Holiday_Date'] = event.Pippin_Date;
    }else this.linkData['Holiday_Date'] = null;
  }

}
