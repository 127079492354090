// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .fr-box p{
    text-align: left !important
} */
.pad-top {
  padding-top: 12px;
}

.pad-bot {
  padding-bottom: 5px;
}

.date {
  width: 100%;
}

.date::-webkit-inner-spin-button {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/eula/eula-edit/eula-edit.component.scss"],"names":[],"mappings":"AAAA;;GAAA;AAMA;EACI,iBAAA;AAFJ;;AAKA;EACI,mBAAA;AAFJ;;AAKA;EACI,WAAA;AAFJ;;AAKA;EACE,aAAA;AAFF","sourcesContent":["/* .fr-box p{\n    text-align: left !important\n} */\n\n\n\n.pad-top{\n    padding-top: 12px\n}\n\n.pad-bot{\n    padding-bottom: 5px;\n}\n\n.date{\n    width:100%\n}\n\n.date::-webkit-inner-spin-button {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
