
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, PagerService, ConfigService, OrganizationService, StateService, ScrollService } from '../../services';
import { PreloaderService } from '../../services/preloader/preloader.service'
import { CONSTANTS } from '../../app.constants';
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as momentTimeZone from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit {
  listingType = 'live';
  constants = CONSTANTS
  organizations = [];
  filterBy: any = [];
  pager: any = {};
  sortField: string;
  orderBy: string;
  pageNum: number;
  searchString: string;
  organizationToDisplay: any;
  totalCount: number;
  searchInProgress: boolean = false;
  scrollEnable: boolean = true;
  srollDistance=this.config.setScrollDistance();
  srollThrottle=this.config.setScrollThrottle();
  isErrorModal: boolean = true;
  searchQuery = new Subject<string>();
  timer: any = [];
  constructor(
    private auth: AuthService,
    private organizationService: OrganizationService,
    private pagerService: PagerService,
    private config: ConfigService,
    private preloaderService: PreloaderService,
    private matDialog:MatDialog,
    private router: Router,
    private stateService: StateService,
    private scrollService: ScrollService
  ) {
    this.pager.pages = [];
    if(this.pageNum == undefined)
    this.pageNum = 1;
    this.searchString = '';
    this.orderBy = 'ASC';
    this.sortField = 'Organization_Name';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    if(this.stateService.getOrganization())
    this.initializeComponentData(this.stateService.getOrganization());
    else this.getInitialOrganizationList();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(14), 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  detectTypeChange(event) {
    this.pageNum = 1;
    this.getInitialOrganizationList()
  }

  getOrganizationList() {
    this.pageNum = 1;
    this.filterBy = [];
    this.filterBy.push({ page: this.pageNum });
    this.filterBy.push({ sort: this.sortField });
    this.filterBy.push({ order: this.orderBy });
    this.filterBy.push({ value: this.getSearchString() });
    this.filterBy.push({ listType: this.listingType });
    this.organizationService.getOrganizations(this.filterBy)
      .subscribe((result) => {
        if (result) {
          this.organizations = result.rows;
          this.totalCount = result.count;
          console.log("organizations list", this.organizations)
          this.setOrganizationList();
          console.log("Now displaying", this.organizationToDisplay)
          this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
          this.searchInProgress = false;
        }
      }, () => {
        this.searchInProgress = false;
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        // this.auth.logout();
        // this.router.navigate(['/login']);
      })
  }

  getInitialOrganizationList() {
    if(this.pageNum == 1){
      this.getFilterData(this.pageNum);
      this.stateService.setOrganization(this)
      this.organizationService.getOrganizations(this.filterBy)
        .subscribe((result1) => {
          if (result1.count > this.config.getNumRecordsPerPage()) {
            this.pageNum++;
            var pageFilterIndex = this.filterBy.findIndex((filterVal) => Object.keys(filterVal).indexOf('page') > -1);
            this.filterBy[pageFilterIndex].page = this.pageNum;
            this.organizationService.getOrganizations(this.filterBy)
              .subscribe((result2) => {
                var result = {
                  rows: result1.rows.concat(result2.rows),
                  count: result1.count
                }
                this.organizations = result.rows;
                this.totalCount = result.count;
                this.getOrganizationListData(this.totalCount)
                this.getScrollPosData()
              })
          }
          else {
            this.organizations = result1.rows;
            this.totalCount = result1.count;
            this.getOrganizationListData(this.totalCount)
            this.getScrollPosData()
          }
  
        }
          , () => {
            this.searchInProgress = false;
            this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
            // this.auth.logout();
            // this.router.navigate(['/login']);
          })
    }else{
      this.getRetainData();
    }
  }

  getRetainData(){
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      this.getFilterData(i);
      ObsCollection.push(this.organizationService.getOrganizations(this.filterBy))
    }
    observableForkJoin(ObsCollection)
    .subscribe((retainedOrganization) =>{
      if(retainedOrganization){
        var totalOrders = [];
        for (var i = 0; i < retainedOrganization.length; i++) {
          this.stateService.setOrganization(this)
          totalOrders = totalOrders.concat(retainedOrganization[i]['rows']);
        }
        this.organizations = totalOrders;
        this.totalCount = retainedOrganization[0]['count'];
        this.getOrganizationListData(this.totalCount);
        this.getScrollPosData()
    }
    })
  }
  getSearchString() {
    var format = /[!@#$%^*()+\=\[\]{};:\\|<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }



  // setPage(page: number) {
  //   console.log("Inside set page", page)
  //   if (page < 1 || page > this.pager.totalPages) {
  //     return;
  //   }
  //   this.pageNum = page;
  //   this.setOrganizationList();
  //   this.getOrganizationList();
  //   this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
  //   console.log("Pager", this.pager)
  // }

  setSort(field: string) {
    this.pageNum = 1;
    console.log("setSort", field);
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getInitialOrganizationList();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.getInitialOrganizationList();
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  setOrganizationList() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.organizationToDisplay = this.organizations.slice(start, this.totalCount);
    if (this.organizationToDisplay.length > this.config.getNumRecordsPerPage()) {
      console.log("Slicing")
      this.organizationToDisplay = this.organizationToDisplay.slice(0, this.config.getNumRecordsPerPage());
    }
  }

  findOrganization() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getInitialOrganizationList();
  }

  initializeComponentData(data) {
    this.listingType = data.listingType;
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.totalCount = data.totalCount;
    this.organizationToDisplay = data.clientsToDisplay;
    this.searchInProgress = data.searchInProgress;  
    this.getInitialOrganizationList();
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.filterBy = [];
      this.filterBy.push({ page: this.pageNum });
      this.filterBy.push({ sort: this.sortField });
      this.filterBy.push({ order: this.orderBy });
      this.filterBy.push({ value: this.getSearchString() });
      this.organizationService.getOrganizations(this.filterBy)
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag()
            this.totalCount = result.count;
            if (this.totalCount > this.organizations.length) {
              this.organizations = this.organizations.concat(result.rows);
              this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  getOrganizationListData(result) {
      this.config.resetShowFlag();
      this.config.setClearTimeout(); 
      this.setOrganizationList();
      this.pager = this.pagerService.getPager(result, this.pageNum, this.config.getNumRecordsPerPage());
      this.searchInProgress = false;
      this.config.setDelayRecords();
  }

  getFilterData(pageNo){
    this.filterBy = [];
    this.filterBy.push({ page: pageNo });
    this.filterBy.push({ sort: this.sortField });
    this.filterBy.push({ order: this.orderBy });
    this.filterBy.push({ value: this.getSearchString() });
    this.filterBy.push({ listType: this.listingType });
  }

  goToOrganizationDetails(orgId){
    if(this.checkAdminType()){
      var getPos = document.documentElement.scrollTop;
      this.scrollService.setScrollPosOrganization(getPos);
      this.router.navigate(['/organization/edit/' + orgId])
    }
  }

  getScrollPosData(){
    if (this.scrollService.getScrollPosOrganization()) {
      var x = this.scrollService.getScrollPosOrganization()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }));
    }
  }

  searchField(search){
    this.scrollService.setScrollPosOrganization(0);
    var format = /[!@#$%^*()+\=\[\]{};:\\|<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if (this.searchString) {
      let dotIndex = this.searchString.indexOf(".")
      if (dotIndex == 0 && this.isErrorModal) {
        this.isErrorModal = false;
        this.openErrorPopup("First character cannot be dot(.) in search field.");
      } else if (dotIndex != 0) {
        if (!format.test(this.searchString) && this.searchString==" "){
          this.searchString='';
          this.getInitialOrganizationList();
        }else this.findOrganization();
        this.isErrorModal = true;
      }
    } else {
      this.findOrganization();
      this.isErrorModal = true;
    }
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }

  goToExceptionList() {
    this.router.navigate(['excluded/domains/list']);
  }
  
}
