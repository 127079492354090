import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormGroup,FormsModule,UntypedFormBuilder,FormControl,Validators,ReactiveFormsModule} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, EmailValidator, ConfigService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.scss']
})
export class ForgetComponent implements OnInit {

  forgetFrm:UntypedFormGroup;
  forgetModel:any = {};
  error: string = '';
  errorMsg: any = {};
  timer: any = [];
  @ViewChild('myfocus') myfocus:any;

  constructor(    
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    private matDialog : MatDialog,
    private config: ConfigService
  ) { 
    this.forgetFrm = fb.group({
      'Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
    })
     this.forgetFrm.valueChanges.subscribe(data => this.error = '');
  }

  ngOnInit() {
    if (this.auth.getEmailIdForForgetPwd() != undefined) {
      this.forgetModel.Email_Address = this.auth.getEmailIdForForgetPwd();
      console.log('this.forgetModel.Email_Address ', this.forgetModel.Email_Address);
    }
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
    this.myfocus.nativeElement.focus(); 
  }
  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  forget(){
    console.log("Model:",this.forgetModel);
    this.auth.forget(this.forgetModel)
    .subscribe(()=>{
      let disposable = this.matDialog.open(SuccessComponent, {data:{
        title:'Success', 
        message: 'Password reset link has been sent to your mail.'
      }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res)=>{
        console.log("Hello:",res);
        this.router.navigate(['login']);
      });
    },
    (error)=>{
       console.log("Error:",error);
      this.errorMsg = error;
    });
  }
   changeModelVal(modelVal){
    this.errorMsg = {};
  }

  goToLogin(){
    this.router.navigate(['login']);
  }
  
}
