
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {
  AuthService, PagerService, ConfigService, MessagesService,
  OrdersService, ClientsService, SocketService, OrganizationService, StateService, ScrollService, UsermessageService
} from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSTANTS } from '../app.constants';
import { PreloaderService } from '../services/preloader/preloader.service'
import { DialogService } from "ng2-bootstrap-modal";
import { MessageComponent } from '../dialog/message/message.component';
import { SuccessComponent } from '../dialog/success/success.component';
import { ErrorDialogComponent } from '../dialog/error-dialog/error-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as momentTimeZone from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-client-messages',
  templateUrl: './client-messages.component.html',
  styleUrls: ['./client-messages.component.scss']
})
export class ClientMessagesComponent implements OnInit {
  messages: any = []
  messagesToDisplay: any;
  totalCount: number;
  pager: any = {};
  searchString: string;
  searchInProgress: boolean = false;
  organizations = [];
  allorganizations = [];
  pageNum: number;
  constants = CONSTANTS;
  curMsg = 0;
  sortField: string;
  orderBy: string;
  scrollEnable: boolean = true;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  searchQuery = new Subject<string>();
  fullName:string;
  timer: any = [];
  orderMsgPopup: boolean = true;
  isDemoFlag: boolean = false;
  hidePippinDetails: boolean = false
  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private messagesService: MessagesService,
    private router: Router,
    private pagerService: PagerService,
    private orderService: OrdersService,
    private config: ConfigService,
    private clientService: ClientsService,
    private preloaderService: PreloaderService,
    private matDialog:MatDialog,
    private socket: SocketService,
    private organizationService: OrganizationService,
    private stateService: StateService,
    private scrollService: ScrollService,
    private userMsg: UsermessageService
  ) {
    this.pager.pages = [];
    if (this.pageNum == undefined)
      this.pageNum = 1;
    this.orderBy = 'DESC';
    this.searchString = '';
    this.sortField = 'Message_Creation_Date';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }


  ngOnInit() {
    this.loadHideDetails()
    this['selectedOrgId'] = '';
    if (this.stateService.getMessages()) {
      this.initializeComponentData(this.stateService.getMessages())
    }
    else this.getInitialMessages();

    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.admin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) this.getAdminOrganizationList();
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) this.getAllOrganizationList();
    this.socket.syncMessage("message").subscribe((result) => {
      console.log("CAUGHT EVENT EMITTED ON CREATE............")
      this.getInitialMessagesOnBg();
    })

    this.timer.push(setTimeout(() => this.config.setSideBarNode(12), 0))
  }
  
  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  checkOrderIsCancelled(orderStatus) {
    if(this.checkIfSearchMaster()) {
      if(orderStatus == CONSTANTS.orderStatus.cancelled) return true;
      else return false;
    } else return false;
  }

  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getInitialMessages();
  }

  setDisplayMessages() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.messagesToDisplay = this.messages.slice(start, this.totalCount);
    if (this.messagesToDisplay.length > this.config.getNumRecordsPerPage()) {
      this.messagesToDisplay = this.messagesToDisplay.slice(0, this.config.getNumRecordsPerPage());
    }
  }

  applyFilter() {
    this.setScrollResetPos();
    this.pageNum = 1;
    this.getInitialMessages()
  }

  getInitialMessages() {
    if (this.pageNum == 1) {
      var data = {};
      data['Admin_ID'] = this.auth.getUserId();
      if (this['selectedOrgId'] && (this['selectedOrgId'] != 'all')) {
        data['Organization_ID'] = this['selectedOrgId'];
      }
      data['isDemoFlag'] = this.isDemoFlag;
      this.stateService.setMessages(this);
      this.messagesService.getAllClientMsgs(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), data)
        .subscribe((result1) => {
           this.getMessageData(result1)
        }, (err) => {
          this.searchInProgress = false;
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    } else {
      this.getRetainData();
    }
  }

  getRetainData() {
    var ObsCollection = [];
    var data = {};
    data['Admin_ID'] = this.auth.getUserId();
    if (this['selectedOrgId'] && (this['selectedOrgId'] != 'all')) {
      data['Organization_ID'] = this['selectedOrgId'];
    }
    data['isDemoFlag'] = this.isDemoFlag;
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.messagesService.getAllClientMsgs(i, this.sortField, this.orderBy, this.getSearchString(), data))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedMsg) => {
        if (retainedMsg) {
          var totalOrders = [];
          for (var i = 0; i < retainedMsg.length; i++) {
            this.stateService.setMessages(this);
            totalOrders = totalOrders.concat(retainedMsg[i]['rows']);
          }
          this.messages = totalOrders;
          this.totalCount = retainedMsg[0]['count'];
          this.getMessagesData(this.totalCount)
          this.getScrollPosData()
        }
      })
  }

  getSearchString() {
    var format = /[!@#$%^*()_+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }

  getMessagesOnBg() {
    this.pageNum = 1;
    var data = {};
    data['Admin_ID'] = this.auth.getUserId();
    if (this['selectedOrgId'] && (this['selectedOrgId'] != 'all')) {
      data['Organization_ID'] = this['selectedOrgId'];
    }
    this.messagesService.getBackgroundMsgs(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), data)
      .subscribe((result) => {
        this.messages = result.rows;
        this.getMessagesData(result)
      }, () => {
        this.searchInProgress = false;
      })
  }

  getInitialMessagesOnBg() {
    if (this.pageNum == 1) {
      var data = {};
      data['Admin_ID'] = this.auth.getUserId();
      if (this['selectedOrgId'] && (this['selectedOrgId'] != 'all')) {
        data['Organization_ID'] = this['selectedOrgId'];
      }
    data['isDemoFlag'] = this.isDemoFlag;
      this.messagesService.getBackgroundMsgs(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), data)
        .subscribe((result1) => {
            this.getMessageData(result1)
        }, (err) => {
          this.searchInProgress = false;
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    } else {
      this.getRetainDataBg();
    }
  }

  getRetainDataBg() {
    var ObsCollection = [];
    var data = {};
    data['Admin_ID'] = this.auth.getUserId();
    if (this['selectedOrgId'] && (this['selectedOrgId'] != 'all')) {
      data['Organization_ID'] = this['selectedOrgId'];
    }
    data['isDemoFlag'] = this.isDemoFlag;
    for (var i = 1; i <= this.pageNum; i++) {

      ObsCollection.push(this.messagesService.getBackgroundMsgs(i, this.sortField, this.orderBy, this.getSearchString(), data))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedMsg) => {
        if (retainedMsg) {
          var totalOrders = [];
          for (var i = 0; i < retainedMsg.length; i++) {
            totalOrders = totalOrders.concat(retainedMsg[i]['rows']);
          }
          this.messages = totalOrders;
          this.totalCount = retainedMsg[0]['count'];
          this.getMessagesData(this.totalCount)
          this.getScrollPosData()
        }
      })
  }
  searchField(search){
    this.scrollService.setScrollPosMsg(0);
    var format = /[!@#$%^*()_+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();    
    this.searchString = search;
    if(!format.test(this.searchString) && this.searchString==" "){
      this.searchString='';
      this.getInitialMessages();
    } else this.findMeaasges();
  }

  findMeaasges() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getInitialMessages();
  }

  showMsg(msg, msgIdx) {
    if(msg && msg.Order_Is_Quote) this.orderMsgPopup = false;
    else this.orderMsgPopup = true;
    this.curMsg = msgIdx;
    this.fullName = msg.User_First_Name + " " + msg.User_Last_Name
    if(msg.Admin_MsgRead_Status != CONSTANTS.messageStatus.read){
    this.messagesService.updateMsgToRead(msg)
      .subscribe((data) => {
        console.log(data);
        this.getInitialMessagesOnBg();
        this.getUnreadCount();
      }, (err) => {
        console.log(err);
      })
    }
    if (msg.Parent_Message_ID) {
      this.messagesService.getAllMsgs(msg.Parent_Message_ID)
        .subscribe((allMsgs) => {
          msg.allMsg = allMsgs;
          this.showAllMsgs(msg);
        }, (err) => {
          console.log(err);
        })
    } else {
      this.messagesService.getMsgInfoData(msg.Message_ID)
        .subscribe((data) => {
          this.showAllMsgs(data);
        }, (err) => {
          console.log(err);
        })
    }
    var getPos = document.documentElement.scrollTop;
    this.scrollService.setScrollPosMsg(getPos);
  }

  updateUnreadMsgStatus(ids, statuses) {
    statuses.forEach((status, index) => {
      if (status == '1') this.updateToRead(Number(ids[index]))
    })
  }

  showAllMsgs(msg) {
    this.messagesService.getMsgData(msg.Message_ID)
      .subscribe((data) => {
        this.openMessageInterface(msg, data);
      }, (err) => {
        console.log(err);
      })
  }



  updateParentId(msgData, data) {
    if (!data.checked){
      msgData.msgDataToSent.Message_Type = CONSTANTS.messageType.orderMessage
      return this.messagesService.updateParentId(msgData)
    } else {
      msgData.msgDataToSent.Message_Type = CONSTANTS.messageType.internalMessage
      return this.messagesService.updateParentId(msgData)
    }
  }

  goToOrderDetails(event, orderId, orderQuoteFlag) {
    event.stopPropagation();
    var getPos = document.documentElement.scrollTop;
    this.scrollService.setScrollPosMsg(getPos);
    if(Number(orderQuoteFlag)) this.router.navigate(['/quotes/' + orderId + '/view'])
    else this.router.navigate(['/orders/' + orderId + '/view']);
  }


  openMessageInterface(allMsg, msgDataToSent) {    
    let isCancelledOrder;
    if(msgDataToSent.Order_Status == CONSTANTS.orderStatus.cancelled && this.checkIfSearchMaster()) isCancelledOrder = true;
    else isCancelledOrder = false;
    let title = '';
    let t1, t2, t3 = '';
    let msgSendType ;
    if (!msgDataToSent.Order_ID) {
      t1 = 'Direct Message'
      msgSendType = CONSTANTS.messageSendStatus.directMessage;
      this.auth.deleteUserNotificationbyMsgIdAndUserId(allMsg.Message_ID).subscribe((data) => {}, (err) => { console.log(err); })
    }
    else {
      if(this.orderMsgPopup) t1 = 'Order '
      else t1 = 'Quote ';
      t2 = msgDataToSent.Titles_ID;
      t3 = 'Messages';
      msgSendType = CONSTANTS.messageSendStatus.orderMessage
    }
    let disposable = this.matDialog.open(MessageComponent, {data:{
      msgSendType:msgSendType,
      t1: t1,
      t2: t2,
      t3: t3,
      message: allMsg,
      cancelorderFlag:isCancelledOrder,
      orderMsgPopup: this.orderMsgPopup
    }, ...{ closeByClickingOutside : false}}).afterClosed()
      .subscribe((data) => {
        if (data) {
          let res = data.messageText;
          let messageType = data.checked
          msgDataToSent.Message_Text = res;
          msgDataToSent.UserId = this.auth.getUserId();
          msgDataToSent.ClientId = msgDataToSent.Sender_User_ID;
          if (allMsg.Parent_Message_ID) msgDataToSent.Parent_Message_ID = allMsg.Parent_Message_ID;
          else msgDataToSent.Parent_Message_ID = allMsg.Message_ID;
          allMsg.msgDataToSent = msgDataToSent;
          if (!msgDataToSent.Order_ID) msgDataToSent.Order_ID = null;
          if (!allMsg.Parent_Message_ID) this.updateParentId(allMsg, data)
            .subscribe((msgData) => {
              if (msgData) {
                this.getInitialMessagesOnBg();
                let msg;
                if(!messageType) msg = "Message has been sent successfully to "+ this.fullName+".";
                else msg = "Message has been sent successfully."
                this.userMsg.setSuccessMsg(msg);
                // let disposable = this.matDialog.open(SuccessComponent, {
                //   title: 'Success',
                //   message: 'Message has been sent successfully.'
                // }, this.config.getDialogOptions())
                //   .subscribe((res) => {
                //   });
              }
            }, (err) => {
              console.log(err);
              this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
            })
          else if (msgDataToSent.Order_ID) {
            if (!data.checked) this.sendMessageWithOrderId(res, msgDataToSent, messageType);
            else this.sendInternalMsgWithOrderId(res, msgDataToSent, messageType)
          }
          else this.sendMessageWithoutOrderId(res, msgDataToSent);
        }
      });
  }

  sendInternalMsgWithOrderId(msgText, msgDataToSent, messageType) {
    var data = {
      Message_Text: msgText,
      Parent_Message_ID: msgDataToSent.Parent_Message_ID,
      Message_Type:CONSTANTS.messageType.internalMessage
    }
    this.orderService.sendInternalMessage(msgDataToSent.Order_ID, data, this.auth.getUserId())
      .subscribe((data) => {
        if (data) {
          this.getInitialMessagesOnBg();
          let msg
          if(!messageType) msg = "Message has been sent successfully to "+ this.fullName+".";
          else msg = "Message has been sent successfully."
          this.userMsg.setSuccessMsg(msg);
          // let disposable = this.matDialog.open(SuccessComponent, {
          //   title: 'Success',
          //   message: 'Message has been sent successfully.'
          // }, this.config.getDialogOptions())
          //   .subscribe((res) => {
          //   });
        }
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
      })
  }

  sendMessageWithOrderId(msgText, msgDataToSent, messageType) {
    var data = {
      Message_Text: msgText,
      Customer_ID: msgDataToSent.Customer_ID,
      Parent_Message_ID: msgDataToSent.Parent_Message_ID
    }
    console.log("text", msgDataToSent)
    this.orderService.sendMessage(msgDataToSent.Order_ID, data, this.auth.getUserId())
      .subscribe((data) => {
        if (data) {
          this.getInitialMessagesOnBg();
          let msg
          if(!messageType) msg = "Message has been sent successfully to "+ this.fullName+".";
          else msg = "Message has been sent successfully."
          this.userMsg.setSuccessMsg(msg);
          // let disposable = this.matDialog.open(SuccessComponent, {
          //   title: 'Success',
          //   message: 'Message has been sent successfully.'
          // }, this.config.getDialogOptions())
          //   .subscribe((res) => {
          //   });
        }
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
      })
  }

  sendMessageWithoutOrderId(msgText, msgDataToSent) {
    var data = {
      Message_Text: msgText,
      Parent_Message_ID: msgDataToSent.Parent_Message_ID
    }
    console.log("sendMessageWithoutOrderId", data);
    this.clientService.sendMessage(data, msgDataToSent.Sender_User_ID)
      .subscribe((data) => {
        this.getInitialMessagesOnBg();
        let msg = "Message has been sent successfully to "+ this.fullName+".";
        // else msg = "Message has been sent successfully."
        this.userMsg.setSuccessMsg(msg);
        // let disposable = this.matDialog.open(SuccessComponent, {
        //   title: 'Success',
        //   message: 'Message sent successfully.',
        // }, this.config.getDialogOptions())
        //   .subscribe((data) => {
        //   })
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
      })
  }

  updateToRead(msgId) {
    this.messagesService.updateToRead(msgId)
      .subscribe((data) => {
        this.getInitialMessagesOnBg();
        this.getUnreadCount();
      }, (err) => {
        console.log(err);
      })
  }


  getUnreadCount() {
    var data = {}
    data['Admin_ID'] = this.auth.getUserId();
    this.messagesService.getAllUnreadCount(data)
      .subscribe((data) => {
      }, (err) => {
        console.log(err);
      })
  }

  getAdminOrganizationList() {
    this.organizationService.addedOrganizationList(this.auth.getUserId())
      .subscribe((result) => {
        if (result) {
          this.organizations = result;
        }
      }, (err) => {
        console.log("err", err);
      })
  }

  getAllOrganizationList() {
    this.organizationService.getAllOrganizations()
      .subscribe((result) => {
        if (result) {
          this.allorganizations = result;
        }
      }, (err) => {
        console.log("err", err);
      })
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.totalCount = data.totalCount;
    this.messagesToDisplay = data.messagesToDisplay;
    this.searchInProgress = data.searchInProgress;
    this['selectedOrgId'] = data.selectedOrgId
    this.isDemoFlag = data.isDemoFlag;
    this.getInitialMessages();
  }

  hasUnreadmsg(msg) {
    if (msg.Admin_MsgRead_Status || msg.Internal_User_MsgRead_Status) {
      if ((msg.Admin_MsgRead_Status == CONSTANTS.messageStatus.unread) || ((msg.Internal_User_MsgRead_Status == CONSTANTS.messageStatus.unread) && (msg.Internal_Receiver_User_ID == this.auth.getUserId()))) {
        return true;
      }
      else false;
    }
  }

  isSuperAdmin() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
    else return false;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData()
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      var data = {};
      data['Admin_ID'] = this.auth.getUserId();
      if (this['selectedOrgId'] && (this['selectedOrgId'] != 'all')) {
        data['Organization_ID'] = this['selectedOrgId'];
      }
      this.messagesService.getAllClientMsgs(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), data)
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag();
            this.totalCount = result.count;
            if (this.totalCount > this.messages.length) {
              this.messages = this.messages.concat(result.rows);
              this.pager = this.pagerService.getPager(this.totalCount, this.pageNum, this.config.getNoOfRecordsPerPage());
            }
          }
        })
    }
  }

  getMessagesData(result) {
    this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setDisplayMessages();
    this.pager = this.pagerService.getPager(result, this.pageNum, this.config.getNoOfRecordsPerPage());
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  getScrollPosData() {
    if (this.scrollService.getScrollPosMsg()) {
      var x = this.scrollService.getScrollPosMsg()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }));
    }
  }

  getMessageData(result) {
    this.messages = result.rows;
    this.totalCount = result.count;
    this.getMessagesData(this.totalCount)
    this.getScrollPosData()
  }

  setScrollResetPos() {
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosMsg(x);
  }
  
  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }
  
  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return true;
    else return false;
  }

  checkDemoFlag(event) {
      if(event.checked == true) this.isDemoFlag = true;
      else  this.isDemoFlag = false;
      this.getInitialMessages();
  }

  checkIfNotExternalSM() {
    if(Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.externalSM) return false;
    else return true;
  }

  loadHideDetails(){
    this.hidePippinDetails = Number(this.auth.getUserRole()) == this.constants.userRole.searchMaster && 
    Number(this.auth.getSearchMasterType()) == this.constants.userType.externalSM;
  }

}