import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { AuthService } from '../../services/authentication/auth.service';
import { AutogrowDirective } from '../../directives';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OrdersService, ConfigService } from 'app/services';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.scss'],
  providers: [AutogrowDirective]
})
export class CancelOrderComponent{

  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;

  title: any;
  comment = '';
  timer: any = [];
  quoteId : any;
  quoteReq : boolean = false;
  checked: boolean = true;
  cancelOrderReasons: any = [];
  selectedType: any;
  cancelOrderMsgText= '';
  selectedReason = '';

  constructor( private auth: AuthService, private orderService: OrdersService, private config: ConfigService,
    public dialogRef: MatDialogRef<CancelOrderComponent>,
    private matDialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.initDialog();
    if(this.quoteId){
      this.quoteReq=true;
      this.timer.push(setTimeout(() => { this.myFirstFocus.nativeElement.focus() }, 0))
    }else{
      this.timer.push(setTimeout(() => { this.getCacnelOrderReasonTypes(); }, 0))
    }
  }

  initDialog(){
    this.title = this.data.title;
    this.quoteId = this.data.quoteId;
  }

  cancelOrder() {
    this.dialogRef.close({
      state: true,
      comment: this.comment
    })
    this.clearTime();
  }

  Close() {
    this.dialogRef.close(false);
    this.clearTime();
  }  

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }  

  getCacnelOrderReasonTypes() {
    this.orderService.getCancelOrderReasonTypes()
      .subscribe((res) => {
        if (res) this.cancelOrderReasons = res;
      }, (err) => {
        this.openErrorPopup('Error while fetching data. Please try again.');
      })
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  isInValid() {
    if(this.quoteReq) return false;
    else {
      if (this.selectedType == 999) {
        return this.cancelOrderMsgText.trim().length == 0;
      } else if (!this.selectedType) return true;
    }

  }

  onSelect(event) {
    if (this.selectedType != 999) {
      var selectedOption = this.cancelOrderReasons.filter((reason) => reason.Order_Cancellation_Reason_Type_ID == this.selectedType);
      this.selectedReason = selectedOption[0].Order_Cancellation_Reason_Text;
    }
  }

  confirm() {
    if(this.quoteReq) this.cancelOrder()
    else {

      let result:any = {
        state: true,
        Order_Cancellation_Reason_Type_ID: this.selectedType,
        cancelledMail : this.checked
      }
      if (this.selectedType == 999) result.Message_Text = this.cancelOrderMsgText.trim();
      else result.Message_Text = this.selectedReason;

      this.dialogRef.close(result);
      this.clearTime();
    }
  }

}
