import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usPhonenumber',
  pure: false
})
export class UsPhonenumberPipe implements PipeTransform {

  transform(num) {
    // transform(value: any, args?: any): any {
    //return null;
    if (!num) return null; 
    else {
      num = num.replace(/[^\d]/g, '');
      var cleaned = ('' + num).replace(/\D/g, '')
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    }


  }
  

}
