
import {throwError as observableThrowError,  Observable, BehaviorSubject, throwError } from 'rxjs';

import {catchError, map, mapTo} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';

@Injectable()
export class OrganizationService {
  direct: HttpClient;
  private _commtmntItems$: BehaviorSubject<any> = new BehaviorSubject(null);
  public commtmntItems$: Observable<any> =
    this._commtmntItems$.asObservable();

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private handler: HttpBackend,
    private http: HttpClient,
  ) { 
    this.direct = new HttpClient(this.handler);
  }

  createOrganization(modelData) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/organization/new';
    return this.http.post(url, modelData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  addOrgToAdmin(modelData) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/add/admin';
    return this.http.post(url, modelData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  addSelectedOrgToAdmin(modelData) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/add/select/admin';
    return this.http.post(url, modelData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrganizations(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/list';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  // CreateOrgWithDomainName(data) {
  //   console.log("getOrganizations :", data);
  //   let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
  //   let options = new RequestOptions({ headers: headers });
  //   let url = this.config.getBasePath() + '/organizations/new/domain';
  //   return this.http.post(url, data, options)
  //     .map((response: Response) => {
  //       console.log("response:", response.json());
  //       return response.json();
  //     })
  //     .catch((error: any) => Observable.throw(error.json()));
  // }

  getOrganizationsForAdmin() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/update/domain';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllOrganizations() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/list/admin';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrganizationsForDomainName(domainName) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let data = {
      "Domain_Name": domainName
    }
    let url = this.config.getBasePath() + '/organizations/domain/list';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  deleteOrganization(orgData) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    // let data = {
    //   "Organization_ID": orgData.Organization_ID,
    //   "Admin_ID": this.auth.getUserId()
    // }
    let url = this.config.getBasePath() + '/organizations/inactive/' + orgData.Organization_ID + '/admin/' + orgData.Admin_User_ID;
    return this.http.delete(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  addedOrganizationList(adminId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/assigned/' + adminId;
    return this.http.get(url, httpOptions)
    .pipe(
      map((response: any) => {
        if(!this.auth.getUserOrgCount() && response) this.auth.setUserOrgCount(response.length)
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);

  }

  getAvailableOrganizationList(inputString) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/available/list';
    return this.direct.post(url, { searchString: inputString } ,httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAssociatedOrgs(adminId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/associated/' + adminId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        if (!this.auth.getUserOrgCount() && response) this.auth.setUserOrgCount(response.length)
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrganizationDetails(orgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/' + orgId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  editOrganizationDetails(data, orgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/edit/' + orgId;
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  // updateOrgWithDomainName(data) {
  //   let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
  //   let options = new RequestOptions({ headers: headers });
  //   let url = this.config.getBasePath() + '/organizations/edit/';
  //   return this.http.put(url, data, options)
  //     .map((response: Response) => {
  //       return response
  //     })
  //     .catch((error: any) => Observable.throw(error.json()));
  // }


  getAllAdmins(orgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/admin/' + orgId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllClients(orgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/client/' + orgId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateDemoStatusForOrg(toggleStatus, orgId) {
    let data = {
      Organization_Demo_Flag: toggleStatus,
      Organization_ID: orgId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/demo/status';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateNewClientStatusForOrg(toggleStatus, orgId) {
    let data = {
      Organization_New_Client_Flag: toggleStatus,
      Organization_ID: orgId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/new/client/status';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }
  
  updateNewClientForOrg(toggleStatus, orgId){
    let data = {
      New_Organization: toggleStatus,
      Organization_ID: orgId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/NewClientForOrg';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateAutoScreenStatusForOrg(toggleStatus, orgId) {
    let data = {
      Organization_Auto_Screen_Flag: toggleStatus,
      Organization_ID: orgId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/auto/screen/status';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updInvDelStatus(toggleStatus, orgId) {
    let data = {
      Org_AddInvoice_Deliverables: toggleStatus,
      Organization_ID: orgId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/add/invoiceTo/deliverables/status';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateMsgNotifyStatusForOrg(toggleStatus, orgId) {
    let data = {
      Organization_Message_Notify_Flag: toggleStatus,
      Organization_ID: orgId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/message/notify/status';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  changePaymentStatus(organizationId, paymentStatus) {
    let data = {
      Prepayment_Status: paymentStatus,
      Organization_ID: organizationId,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/prepayment';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  changeClientType(organizationId, clientType) {
    let data = {
      Organization_Type: clientType,
      Organization_ID: organizationId,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/type';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  generateAuthToken(organizationId) {
    let data = {
      Organization_ID: organizationId,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/generate/token';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  fetchAuthToken(organizationId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/auth/token/' + organizationId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllExcludedDomainList(searchString, sortField, orderBy) {
    let data = {
      searchString: searchString,
      sortField: sortField,
      orderBy: orderBy,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/excludedDomains/list';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }


  deleteExludedDomain(excludeDomainId) {
    let data = {
      Excluded_Domain_ID: excludeDomainId,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/excludedDomains/exception/suspend';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createNewException(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/excludedDomains/exception/new';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  editException(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/excludedDomains/exception/edit';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  syncCompanyWatch(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/watch/status/sync';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getWatchStatus(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/watch/status';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  changeRemoteLang(organizationId, remoteLanguage) {
    let data = {
      remoteLanguage: remoteLanguage,
      Organization_ID: organizationId,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/remotelang';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateLogo(organizationId, Logo) {
    let data = {
      Logo: Logo,
      Organization_ID: organizationId,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/changelogo';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getSearchPackageTemplates() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/get/sptemplates';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getCommitmentTemplates() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/commitmentTyping/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
       return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  updateDocInReportStatusForOrg(toggleStatus, orgId) {
    let data = {
      SP_DocInReport: toggleStatus,
      Organization_ID: orgId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/organizations/report/docs/status';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getCommitmentItems(orgId) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() + "/reportbuilder/commitmentItemTypes/" + orgId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => this._commtmntItems$.next(response)),
      catchError((error: any) => throwError(error.error))
    );
  }

  saveCommitmentItem(cmmtmntItm) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
      observe: 'response' as 'body'
    };
    let url =
      this.config.getBasePath() + "/reportbuilder/commitmentItemTypes";
    return this.http.put(url, cmmtmntItm, httpOptions).pipe(
      map((response: any) => this._commtmntItems$.next(response.body)),
      catchError((error: any) => throwError(error.error))
    );
  }
  
  updateClientOrderInfoForOrg(data, orgId) {
    let dataToBeUpdated = {... data}
    dataToBeUpdated.Organization_Id = orgId
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/clientOrderInfo/update';
    return this.http.put(url, dataToBeUpdated, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateClienBillingInfoForOrg(data, orgId) {
    let dataToBeUpdated = {... data}
    dataToBeUpdated.Organization_Id = orgId
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/clientBillingInfo/update';
    return this.http.put(url, dataToBeUpdated, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createCompanyDocs(data) {    
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/document';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllCompanyMappings(cpnyId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/document/all/' + cpnyId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
       return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  deleteCompanyMapping(mappingId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/document/' + mappingId;
    return this.http.delete(url, httpOptions).pipe(
      map((response: any) => {
       return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }
}
