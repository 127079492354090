// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  box-shadow: 2px 0px 12px 2px rgb(202, 94, 87);
}

.newLine {
  white-space: pre !important;
}

.bulkOrderError {
  max-height: 50vh;
  overflow-y: auto;
}

.modalDialog {
  margin-top: 5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/error-dialog/error-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,6CAAA;AACJ;;AACA;EACE,2BAAA;AAEF;;AACA;EACE,gBAAA;EACA,gBAAA;AAEF;;AACA;EACE,gBAAA;AAEF","sourcesContent":[".modal-content{\n    box-shadow: 2px 0px 12px 2px rgb(202, 94, 87);\n}\n.newLine {\n  white-space: pre !important;\n}\n\n.bulkOrderError{\n  max-height: 50vh;\n  overflow-y: auto;\n}\n\n.modalDialog {\n  margin-top: 5rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
