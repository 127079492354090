import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ElementRef } from "@angular/core";
import { FormsModule, NgModel, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Routes, RouterModule, Router } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { AppComponent } from "./app.component";
import { CookieModule, CookieService } from "ngx-cookie";
// import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, DateAdapter } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ClipboardModule as AngularClipboardModule } from '@angular/cdk/clipboard';

import { DragDropModule } from "@angular/cdk/drag-drop";
import { ClipboardModule } from "ngx-clipboard";
import { environment } from "../environments/environment";
import {
  PreloaderService,
  HttpService,
  AuthService,
  ConfigService,
  AuthGuardService,
  EmailValidator,
  PasswordValidator,
  RoleGuardService,
  PhoneValidator,
  FileService,
  NewOrderService,
  EqualValidator,
  ProgressBarService,
  PagerService,
  AboutService,
  UsersService,
  ClientsService,
  OrdersService,
  EulaService,
  PricingService,
  ProductService,
  MessagesService,
  SocketService,
  StateService,
  InvoiceService,
  OrganizationService,
  AbstractorService,
  SSNValidator,
  EINValidator,
  SiteSettingService,
  ScrollService,
  AwsS3Service,
  SearchMasterService,
  QuickLinksService,
  GroupsService,
  AdminOnlyGuardService,
  QuickbooksService,
  RoutingStateService,
  TagsService,
  ClientPasswordValidator,
  ExpenseService,
  NotificationsService,
  QuoterequestService,
  SchedulingService,
} from "./services";
import { PreloaderComponent } from "./services/preloader/preloader.component";
import { LoginComponent } from "./authentication/login/login.component";
import { HeaderComponent } from "./common/header/header.component";
import { PrivacyComponent } from "./common/privacy/privacy.component";
import { TermsComponent } from "./common/terms/terms.component";
import {
  PhonenumberPipe,
  CapitalizePipe,
  TruncatePipe,
  SsnFieldPipe,
  EinFieldPipe,
} from "./pipes";
import { SidebarComponent } from "./common/sidebar/sidebar.component";
import { FooterComponent } from "./common/footer/footer.component";
import {
  LimitToDirective,
  NumberOnlyDirective,
  AutogrowDirective,
  DragDropDirective,
  EscapePressDirective,
  CurrencyInputDirective,
} from "./directives";
import { BootstrapModalModule } from "ng2-bootstrap-modal";
import { BaseComponent } from "./base/base.component";
import { E404Component } from "./common/e404/e404.component";
import { HomeComponent } from "./orders//home/home.component";
import { SuccessComponent } from "./dialog/success/success.component";
import { ForgetComponent } from "./authentication/forget/forget.component";
import { InprocessOrdersComponent } from "./orders/inprocess-orders/inprocess-orders.component";
import { ClientComponent } from "./clients/client/client.component";
import { UsersComponent } from "./user/users/users.component";
import { EulaComponent } from "./eula/eula/eula.component";
import { OrderDetailsComponent } from "./orders/order-details/order-details.component";
import { ConfirmComponent } from "./dialog/confirm/confirm.component";
import { DragDropDocsComponent } from "./dialog/drag-drop-docs/drag-drop-docs.component";
import { ApproveClientComponent } from "./dialog/approve-client/approve-client.component";
import { AddClientOrgsComponent } from "./dialog/add-client-orgs/add-client-orgs.component";
import { MessageComponent } from "./dialog/message/message.component";
import { UserEditComponent } from "./user/user-edit/user-edit.component";
import { ChangeComponent } from "./authentication/change/change.component";
import { CreatePasswordComponent } from "./authentication/create-password/create-password.component";
import { ProgressBarComponent } from "./common/progress-bar/progress-bar.component";
import { AccountInfoComponent } from "./user/account-info/account-info.component";
import { SearchLoaderComponent } from "./services/search-loader/search-loader.component";
// import { JWBootstrapSwitchModule } from 'jw-bootstrap-switch-ng2';
import { EulaEditComponent } from "./eula/eula-edit/eula-edit.component";
import { TermsAndConditionsComponent } from "./eula/terms-and-conditions/terms-and-conditions.component";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { NewOrder1Component } from "./orders/new-order/new-order1/new-order1.component";
import { NewOrder3Component } from "./orders/new-order/new-order3/new-order3.component";
import { Neworder2Component } from "./orders/new-order/neworder2/neworder2.component";
import { PricingViewComponent } from "./pricing-view/pricing-view.component";
import { PricingEditComponent } from "./pricing-edit/pricing-edit.component";
import { CustomPricingComponent } from "./custom-pricing/custom-pricing/custom-pricing.component";
import { EditCustomPricingComponent } from "./custom-pricing/edit-custom-pricing/edit-custom-pricing.component";
import { NewClientComponent } from "./clients/new-client/new-client.component";
import { AssignComponent } from "./dialog/assign/assign.component";
import { CompleteOrderComponent } from "./dialog/complete-order/complete-order.component";
import { CancelOrderComponent } from "./dialog/cancel-order/cancel-order.component";
import { ChangeOrderStatusComponent } from "./dialog/change-order-status/change-order-status.component";
import { ProductComponent } from "./product/product.component";
import { OrderAcceptComponent } from "./orders/order-accept/order-accept.component";
import { CompletedordersComponent } from "./orders/./completedorders/completedorders.component";
import { OrderPricingEditComponent } from "./orders/order-pricing-edit/order-pricing-edit.component";
import { EditOrderDetailsComponent } from "./orders/edit-order-details/edit-order-details.component";
import { ClientMessagesComponent } from "./client-messages/client-messages.component";
import { EditClientComponent } from "./clients/edit-client/edit-client.component";
import { InvoiceComponent } from "./orders/invoice/invoice.component";
import { OrganizationListComponent } from "./organizations/organization-list/organization-list.component";
import { NewOrganizationComponent } from "./organizations/new-organization/new-organization.component";
import { EditOrganizationComponent } from "./organizations/edit-organization/edit-organization.component";
import { ClientTypeComponent } from "./clients/client-type/client-type.component";
import { EtaAlertComponent } from "./dialog/eta-alert/eta-alert.component";
import { ErrorDialogComponent } from "./dialog/error-dialog/error-dialog.component";
import { CancelledOrderComponent } from "./orders/./cancelled-order/cancelled-order.component";
import { OrderSearchComponent } from "./orders/order-search/order-search.component";
import { CustomAutofocusDirective } from "./directives/custom-autofocus/custom-autofocus.directive";
import { AbstractorListComponent } from "./abstractor/abstractor-list/abstractor-list.component";
import { AbstractorNewComponent } from "./abstractor/abstractor-new/abstractor-new.component";
import { AbstractorEditComponent } from "./abstractor/abstractor-edit/abstractor-edit.component";
import { NoWhitespaceDirective } from "./directives/no-whitespace/no-whitespace.directive";
import { AbstractorSendComponent } from "./dialog/abstractor-send/abstractor-send.component";
import { CreateAbsDialogComponent } from "./dialog/create-abs-dialog/create-abs-dialog.component";
import { StarRatingComponent } from "./abstractor/star-rating/star-rating.component";
import { BulkOrderComponent } from "./dialog/bulk-order/bulk-order.component";
import { ClientPasswordResetComponent } from "./dialog/client-password-reset/client-password-reset.component";
import { NoDecimalDirective } from "./directives/no-decimal/no-decimal.directive";
import { LoginHistoryComponent } from "./dialog/login-history/login-history.component";
import { JoinCompanyComponent } from "./dialog/join-company/join-company.component";
import { AddAdmin2companyComponent } from "./dialog/add-admin2company/add-admin2company.component";
import { RateAbstractorComponent } from "./dialog/rate-abstractor/rate-abstractor.component";
import { AbstractorMessageComponent } from "./dialog/abstractor-message/abstractor-message.component";
import { AbstractorMessagesComponent } from "./dialog/abstractor-messages/abstractor-messages.component";
import { OldAbstractorMailComponent } from "./dialog/old-abstractor-mail/old-abstractor-mail.component";
import { NewAbstractorMessageComponent } from "./dialog/new-abstractor-message/new-abstractor-message.component";
import { ChangeCompanyComponent } from "./dialog/change-company/change-company.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ScrollTopDirective } from "./directives/scrollTop/scroll-top.directive";
import { OneDecimalDirective } from "./directives/one-decimal/one-decimal.directive";
import { OauthSuccessComponent } from "./oauth-success/oauth-success.component";
import { NewSearchMasterComponent } from "./search-master/new-search-master/new-search-master.component";
import { EditSearchMasterComponent } from "./search-master/edit-search-master/edit-search-master.component";
import { ListSearchMasterComponent } from "./search-master/list-search-master/list-search-master.component";
import { WhitespaceDirective } from "./directives/whitespace/whitespace.directive";
// import { StorageServiceModule } from "angular-webstorage-service";
import { AllOrdersComponent } from "./orders/all-orders/all-orders.component";
import { OrderFlyComponent } from "./orders/orderFly/order-fly.component";
import { ReviewCompletePackageComponent } from "./dialog/review-complete-package/review-complete-package.component";
import { MatSelectFilterComponent } from "./common/mat-select-filter/mat-select-filter.component";
import { UsPhonenumberPipe } from "./pipes/us-phonenumber/us-phonenumber.pipe";
import { ActionCommentComponent } from "./dialog/action-comment/action-comment.component";
import { MatDividerModule } from "@angular/material/divider";
import { SortPipe } from "./pipes/sort/sort.pipe";
import { CreateEditTagComponent } from "./tag/create-edit-tag/create-edit-tag.component";
import { TagsComponent } from "./tag/tags/tags.component";
import { SearchPipe } from "./pipes/search/search.pipe";
import { NewTagComponent } from "./dialog/new-tag/new-tag.component";
import { OrderExpenseEditComponent } from "./orders/order-expense-edit/order-expense-edit.component";
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { SearchPropsPipe } from "./pipes/searchProps/search-props.pipe";
import { AutocompleteLibModule } from "./common/autocomplete-lib/autocomplete-lib.module";
import { ConfigurationsComponent } from "./configurations/configurations.component";
import { QuicklinkFormComponent } from "./quicklink-form/quicklink-form.component";
import { InternalNotesComponent } from "./dialog/internal-notes/internal-notes.component";
import { ScreenOrderComponent } from "./dialog/screen-order/screen-order.component";
import { ConfimedOrdersComponent } from "./orders/confimed-orders/confimed-orders.component";
import { ValidateOrdAddressComponent } from "./dialog/validate-ord-address/validate-ord-address.component";
import { SitusamcAcceptReject } from "./dialog/situsamc-accept-reject/situsamc-accept-reject.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MatSelectSearchComponent } from "./common/mat-select-search/mat-select-search.component";
import { TimecompleteComponent } from "./common/timecomplete/timecomplete.component";
import { UploadTypesComponent } from "./orders/bulk-upload/upload-types/upload-types.component";
import { EditPdfDataComponent } from "./orders/bulk-upload/edit-pdf-data/edit-pdf-data.component";
import { TableEditableComponent } from "./orders/bulk-upload/table-editable/table-editable.component";
import { DigitOnlyDirective } from "./directives/digitonly/digit-only.directive";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MatSingleSelectSearchComponent } from "./common/mat-single-select-search/mat-single-select-search.component";
import { DuedateComponent } from "./common/duedate/duedate.component";
import { GroupListingComponent } from "./groups/group-listing/group-listing.component";
import { CreateGroupComponent } from "./groups/create-group/create-group.component";
import { EditGroupComponent } from "./groups/edit-group/edit-group.component";
import { AddUser2groupComponent } from "./dialog/add-user2group/add-user2group.component";
// import { AgGridModule } from "ag-grid-angular";
// import { AgGridAngular } from "ag-grid-angular";
import { AgGridAngular } from "@ag-grid-community/angular";
import { MatMultiSelectForListComponent } from "./common/mat-multi-select-for-list/mat-multi-select-for-list.component";
import { SlideComponent } from "./common/slide/slide.component";
import { OrderChangeReasonComponent } from "./dialog/order-change-reason/order-change-reason.component";
import { EtaChangeReasonComponent } from "./dialog/eta-change-reason/eta-change-reason.component";
import { OrderChecklistComponent } from "./dialog/order-checklist/order-checklist.component";
import { NotificationSnackbarComponent } from "./common/notification-snackbar/notification-snackbar.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { PausedOrdersComponent } from "./orders/paused-orders/paused-orders.component";
import { ErrorReportComponent } from "./dialog/error-report/error-report.component";
import { CreateProductComponent } from "./product/create-product/create-product.component";
import { MatSelectSearchObjComponent } from "./common/mat-select-search-obj/mat-select-search-obj.component";
import { EditProductComponent } from "./product/edit-product/edit-product.component";
import { SelectCheckAllComponent } from "./common/select-check-all/select-check-all.component";
import { CancelOrderMailComponent } from "./dialog/cancel-order-mail/cancel-order-mail.component";
import { NotificationGridComponent } from "./notifications/notifications-grid/notification-grid.component";
import { DatetimeFormComponent } from "./common/datetime-form/datetime-form.component";
import { ReportsComponent } from "./reports/reports.component";
import { QuoteDetailsComponent } from "./orders/quote-details/quote-details.component";
import { RequestQuoteComponent } from "./orders/request-quote/request-quote.component";
import { QuoteCompleteComponent } from "./dialog/quote-complete/quote-complete.component";
import { NewClientOrdersComponent } from "./orders/new-client-orders/new-client-orders.component";
import { ChangeProductTypeComponent } from "./dialog/change-product-type/change-product-type.component";
import { BillingComponent } from "./orders/billing/billing.component";
import { HolidayScheduleComponent } from "./holidayScheduling/holiday-schedule/holiday-schedule.component";
import { HolidayConfigComponent } from "./holidayConfig/holiday-config/holiday-config.component";
import { QuoteRequest1Component } from "./orders/quote-request/quote-request1/quote-request1/quote-request1.component";
import { QuoteRequest2Component } from "./orders/quote-request/quote-request2/quote-request2/quote-request2.component";
import { QuoteRequest3Component } from "./orders/quote-request/quote-request3/quote-request3/quote-request3.component";
import { ConvertOrderComponent } from "./dialog/convert-order/convert-order/convert-order.component";
import { NotificationListComponent } from "./notifications/notifications-list/notification-list/notification-list.component";
import { MatSingleSearchProdComponent } from "./common/mat-single-search-prod/mat-single-search-prod.component";
import { AbstractorQuoteComponent } from "./dialog/abstractor-quote/abstractor-quote.component";
import { AbsQuoteMessageComponent } from "./dialog/abs-quote-message/abs-quote-message.component";
import { NewAbsQuoteComponent } from "./dialog/new-abs-quote/new-abs-quote.component";
import { TrackerproResponseHandlerComponent } from "./dialog/trackerpro-response-handler/trackerpro-response-handler.component";
import { TestMaskPipe } from "./common/text-mask/test-mask.pipe";
import { AgGridTooltipComponent } from "./common/ag-grid-tooltip/ag-grid-tooltip.component";
import { EmailTemplateListingComponent } from "./email-template-listing/email-template-listing.component";
import { EmailTemplateComponent } from "./email-template/email-template.component";
import { PriorityOrdersComponent } from "./orders/priority-orders/priority-orders/priority-orders.component";
import { WatchOrdersComponent } from "./orders/watch-orders/watch-orders/watch-orders.component";
import { ExcludedDomainsComponent } from "./excluded-domains/excluded-domains.component";
import { AddExceptionComponent } from "./dialog/add-exception/add-exception.component";
import { EditExceptionComponent } from "./dialog/edit-exception/edit-exception.component";
import { PippinDatesComponent } from "./common/pippin-dates/pippin-dates.component";
import { ButtonRendererComponent } from "./button-renderer/button-renderer.component";
import { MatMenuSearchComponent } from "./common/mat-menu-search/mat-menu-search.component";
// import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { HubspotOauthComponent } from "./hubspot-oauth/hubspot-oauth.component";
import { ErrorDialogMessageComponent } from "./dialog/error-dialog-message/error-dialog-message.component";
import { OrderNoteComponent } from "./dialog/order-note/order-note.component";
import { ReportBuilderErrSnackbarComponent } from "./common/report-builder-err-snackbar/report-builder-err-snackbar.component";
import { DailyTimeBoxComponent } from "./common/daily-time-box/daily-time-box.component";
import { EscalatedOrdersComponent } from "./orders/escalated-orders/escalated-orders.component";
import { OrderListingTableComponent } from "./common/order-listing-table/order-listing-table.component";
import { CopyToClipboardComponent } from "./common/copy-to-clipboard/copy-to-clipboard.component";
import { UserStatusComponent } from "./user/user-status/user-status.component";
import { OrderAssignComponent } from "./dialog/order-assign/order-assign.component";
import { CommitmentInputComponent } from "./common/commitment-input/commitment-input.component";
import { EditQuoteDetailsComponent } from "./orders/edit-quote-details/edit-quote-details.component";
import { OrderByPipe } from "./pipes/order-by/order-by.pipe";
import { ChkIdentificationComponent } from "./dialog/order-checklist/chk-identification/chk-identification.component";
import { ChkDeliveryComponent } from "./dialog/order-checklist/chk-delivery/chk-delivery.component";
import { ChkTaxesComponent } from "./dialog/order-checklist/chk-taxes/chk-taxes.component";
import { ChkLegalComponent } from "./dialog/order-checklist/chk-legal/chk-legal.component";
import { ChkJudgementsComponent } from "./dialog/order-checklist/chk-judgements/chk-judgements.component";
import { ChkCotComponent } from "./dialog/order-checklist/chk-cot/chk-cot.component";
import { ChkProceedingsComponent } from "./dialog/order-checklist/chk-proceedings/chk-proceedings.component";
import { ChkSecInstComponent } from "./dialog/order-checklist/chk-sec-inst/chk-sec-inst.component";
import { ChkDocsComponent } from "./dialog/order-checklist/chk-docs/chk-docs.component";
import { ChkSopComponent } from "./dialog/order-checklist/chk-sop/chk-sop.component";
import { ChkFeesComponent } from "./dialog/order-checklist/chk-fees/chk-fees.component";
import { ReportBuilderComponent } from "./report-builder/report-builder.component";
import { RbReportComponent } from "./report-builder/rb-report/rb-report.component";
import { RbPropertyComponent } from "./report-builder/rb-property/rb-property.component";
import { RbSearchPartyComponent } from "./report-builder/rb-search-party/rb-search-party.component";
import { RbAssessmentComponent } from "./report-builder/rb-assessment/rb-assessment.component";
import { RbTaxComponent } from "./report-builder/rb-tax/rb-tax.component";
import { RbVestingComponent } from "./report-builder/rb-vesting/rb-vesting.component";
import { RbDeedComponent } from "./report-builder/rb-deed/rb-deed.component";
import { RbSecInstComponent } from "./report-builder/rb-sec-inst/rb-sec-inst.component";
import { RbLiensComponent } from "./report-builder/rb-liens/rb-liens.component";
import { RbEncumbranceComponent } from "./report-builder/rb-encumbrance/rb-encumbrance.component";
import { RbCommentsComponent } from "./report-builder/rb-comments/rb-comments.component";
import { RbCommitmentsComponent } from "./report-builder/rb-commitments/rb-commitments.component";
import { RbGlobalCommitmentsComponent } from "./report-builder/rb-global-commitments/rb-global-commitments.component";
import { RptBuilderService } from "./report-builder/rpt-builder.service";
import { RbPartySectionComponent } from "./report-builder/rb-search-party/rb-party-section/rb-party-section.component";
import { RbAssessmentSectionComponent } from "./report-builder/rb-assessment/rb-assessment-section/rb-assessment-section.component";
import { RbTaxSectionComponent } from "./report-builder/rb-tax/rb-tax-section/rb-tax-section.component";
import { RbVestingSectionComponent } from "./report-builder/rb-vesting/rb-vesting-section/rb-vesting-section.component";
import { RbDeedSectionComponent } from "./report-builder/rb-deed/rb-deed-section/rb-deed-section.component";
import { RbSecInstSectionComponent } from "./report-builder/rb-sec-inst/rb-sec-inst-section/rb-sec-inst-section.component";
import { RbLiensSectionComponent } from "./report-builder/rb-liens/rb-liens-section/rb-liens-section.component";
import { RbEncumbranceSectionComponent } from "./report-builder/rb-encumbrance/rb-encumbrance-section/rb-encumbrance-section.component";
import { RbGlobCommSectionComponent } from "./report-builder/rb-global-commitments/rb-glob-comm-section/rb-glob-comm-section.component";
import { RbDocumentComponent } from "./report-builder/rb-document/rb-document.component";
import { RbVersionsComponent } from "./report-builder/rb-versions/report-versions.component";
import { RbCommInputComponent } from "./report-builder/rb-commitments/rb-comm-input/rb-comm-input.component";
import { RbGlobCommInputComponent } from "./report-builder/rb-global-commitments/rb-glob-comm-section/rb-glob-comm-input/rb-glob-comm-input.component";
import { E500Component } from "./common/e500/e500.component";
import { RbAdditionalDataComponent } from "./report-builder/rb-additional-data/rb-additional-data.component";
import { RbErrSnackbarComponent } from "./report-builder/rb-err-snackbar/rb-err-snackbar.component";
import { RbSectionScrollerComponent } from "./report-builder/rb-section-scroller/rb-section-scroller.component";
import { CustomerDropdownComponent } from "./customer-dropdown/customer-dropdown.component";
import { OrgCommitmentsComponent } from "./organizations/org-commitments/org-commitments.component";
import { EditCommitmentsComponent } from "./organizations/edit-commitments/edit-commitments.component";
import { SelectUserComponent } from "./dialog/select-user/select-user.component";
import { CountiesComponent } from './counties/counties.component';
import { ReviewDataDepthUpldComponent } from './orders/bulk-upload/review-data-depth-upld/review-data-depth-upld.component';
import { RbAddNewWidgetComponent } from './report-builder/rb-add-new-widget/rb-add-new-widget.component';
import { VendorOrdersComponent } from "./orders/vendor-orders/vendor-orders.component";

const appRoutes: Routes = [
  { path: "login/:success", component: LoginComponent },
  { path: "login", component: LoginComponent },
  { path: "base", component: BaseComponent },
  { path: "forget", component: ForgetComponent },
  {
    path: "privacypolicy",
    component: PrivacyComponent,
    canActivate: [AuthGuardService],
  },
  { path: "terms/:userId", component: TermsComponent },
  { path: "user/new/:verificationId", component: CreatePasswordComponent },
  {
    path: "orders/new",
    component: HomeComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "orders/confirmed",
    component: ConfimedOrdersComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "orders/inprocess",
    component: InprocessOrdersComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "orders/completed",
    component: CompletedordersComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "orders/vendor",
    component: VendorOrdersComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "orders/search",
    component: OrderSearchComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "orders/paused",
    component: PausedOrdersComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "orders/cancelled",
    component: CancelledOrderComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "orders/requestquote",
    component: RequestQuoteComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "new/client/orders",
    component: NewClientOrdersComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "orders/invoice",
    component: InvoiceComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "orders/:orderId/view",
    component: OrderDetailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "orders/:orderId/edit",
    component: EditOrderDetailsComponent,
    canActivate: [AuthGuardService],
  },
  // { path: 'orders/:orderId/report-builder', component: OrderReportComponent, canActivate: [AuthGuardService] },
  {
    path: "orders/:orderId/report-builder",
    component: ReportBuilderComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "orders/:orderId/accept",
    component: OrderAcceptComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "edit-order-pricing/:orderId",
    component: OrderPricingEditComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "edit-order-expense/:orderId",
    component: OrderExpenseEditComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "clients",
    component: ClientComponent,
    canActivate: [AdminOnlyGuardService],
  },
  // { path: 'client/new', component: NewClientComponent, canActivate: [AuthGuardService] },
  {
    path: "client/create",
    component: ClientTypeComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "client/create/:orgid",
    component: NewClientComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "inbox",
    component: ClientMessagesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "client/:id",
    component: EditClientComponent,
    canActivate: [AdminOnlyGuardService],
  },
  { path: "eula", component: EulaComponent, canActivate: [RoleGuardService] },
  {
    path: "eula/:eulaId",
    component: EulaEditComponent,
    canActivate: [RoleGuardService],
  },
  {
    path: "email-templates/listing",
    component: EmailTemplateListingComponent,
    canActivate: [RoleGuardService],
  },
  {
    path: "email-template/:templateId",
    component: EmailTemplateComponent,
    canActivate: [RoleGuardService],
  },
  {
    path: "termsAndConditions/:userId",
    component: TermsAndConditionsComponent,
  },
  { path: "users", component: UsersComponent, canActivate: [RoleGuardService] },
  {
    path: "user/:userId",
    component: UserEditComponent,
    canActivate: [RoleGuardService],
  },
  {
    path: "customer/orders/new/step1",
    component: NewOrder1Component,
    canActivate: [AuthGuardService],
  },
  {
    path: "customer/orders/new/step1/:back",
    component: NewOrder1Component,
    canActivate: [AuthGuardService],
  },
  {
    path: "customer/orders/new/step3",
    component: NewOrder3Component,
    canActivate: [AuthGuardService],
  },
  {
    path: "customer/orders/new/step2",
    component: Neworder2Component,
    canActivate: [AuthGuardService],
  },
  {
    path: "user/account/:userId",
    component: AccountInfoComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "user/change-password/:userId",
    component: ChangeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "product/list",
    component: ProductComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "product-pricing/view",
    component: PricingViewComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "product-pricing/:costId",
    component: PricingEditComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "custom-pricing/view",
    component: CustomPricingComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "custom-pricing/:costId",
    component: EditCustomPricingComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "custom-pricing/:costId/customer/:userId",
    component: EditCustomPricingComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "quickbooks/auth/success",
    component: OauthSuccessComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "hubspot/auth/success",
    component: HubspotOauthComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "organization/list",
    component: OrganizationListComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "organization/new",
    component: NewOrganizationComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "organization/edit/:id",
    component: EditOrganizationComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "abstractor/new",
    component: AbstractorNewComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "abstractor/list",
    component: AbstractorListComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "abstractor/edit/:id",
    component: AbstractorEditComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "searchmaster/new",
    component: NewSearchMasterComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "searchmaster/list",
    component: ListSearchMasterComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "searchmaster/detail/:userId",
    component: EditSearchMasterComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "all/orders/due",
    component: AllOrdersComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "update/:orderId/view",
    component: OrderFlyComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "tags/create",
    component: CreateEditTagComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "tags/edit/:tagId",
    component: CreateEditTagComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "tags/list",
    component: TagsComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "config",
    component: ConfigurationsComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "upload-types",
    component: UploadTypesComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path:"data-depth-upload-review",
    component: ReviewDataDepthUpldComponent,
    canActivate:[AdminOnlyGuardService]
  },
  {
    path: "edit-pdf-data",
    component: EditPdfDataComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "table-editable/customer/:id/:orgId",
    component: TableEditableComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "groups/list",
    component: GroupListingComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "groups/create",
    component: CreateGroupComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "groups/edit/:groupId",
    component: EditGroupComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "create/product",
    component: CreateProductComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "edit/product/:prodId",
    component: EditProductComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "notifications/list",
    component: NotificationGridComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/generate",
    component: ReportsComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "excluded/domains/list",
    component: ExcludedDomainsComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "quotes/:quoteId/view",
    component: QuoteDetailsComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "quotes/:quoteId/edit",
    component: EditQuoteDetailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "orders/billing",
    component: BillingComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "orders/priority",
    component: PriorityOrdersComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "orders/watched",
    component: WatchOrdersComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "scheduling",
    component: HolidayConfigComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "quote/request/step1",
    component: QuoteRequest1Component,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "quote/request/step1/:back",
    component: QuoteRequest1Component,
    canActivate: [AuthGuardService],
  },
  {
    path: "quote/request/step2",
    component: QuoteRequest2Component,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "quote/request/step3",
    component: QuoteRequest3Component,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "notifications/details",
    component: NotificationListComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "orders/escalated",
    component: EscalatedOrdersComponent,
    canActivate: [AdminOnlyGuardService],
  },
  {
    path: "oper/status",
    component: UserStatusComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "counties",
    component: CountiesComponent,
    canActivate: [RoleGuardService],
  },
  { path: "maintenance", component: E404Component },
  { path: "serverdown", component: E500Component },
  { path: "**", redirectTo: "/base" },
];

// const configSocket: SocketIoConfig = { url: environment.basePath, options: {} };
const routing = RouterModule.forRoot(appRoutes);

// export function httpServiceFactory(backend: XHRBackend, defaultOptions: RequestOptions,
//   preloaderService: PreloaderService, cookieService: CookieService, router: Router) {
//   return new HttpService(backend, defaultOptions, preloaderService, cookieService, router);
// }

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    LoginComponent,
    HeaderComponent,
    EmailValidator,
    PasswordValidator,
    ClientPasswordValidator,
    PhoneValidator,
    PhonenumberPipe,
    CapitalizePipe,
    TruncatePipe,
    EqualValidator,
    SidebarComponent,
    FooterComponent,
    LimitToDirective,
    NumberOnlyDirective,
    BaseComponent,
    PrivacyComponent,
    TermsComponent,
    AutogrowDirective,
    DragDropDirective,
    E404Component,
    HomeComponent,
    SuccessComponent,
    ForgetComponent,
    InprocessOrdersComponent,
    ClientComponent,
    UsersComponent,
    EulaComponent,
    OrderDetailsComponent,
    ConfirmComponent,
    DragDropDocsComponent,
    ApproveClientComponent,
    AddClientOrgsComponent,
    MessageComponent,
    UserEditComponent,
    ChangeComponent,
    CreatePasswordComponent,
    ProgressBarComponent,
    AccountInfoComponent,
    NewOrder1Component,
    NewOrder3Component,
    SearchLoaderComponent,
    EulaEditComponent,
    TermsAndConditionsComponent,
    EulaEditComponent,
    PricingViewComponent,
    PricingEditComponent,
    CustomPricingComponent,
    EditCustomPricingComponent,
    NewClientComponent,
    AssignComponent,
    OrderAcceptComponent,
    CompleteOrderComponent,
    CancelOrderComponent,
    ChangeOrderStatusComponent,
    ProductComponent,
    CompletedordersComponent,
    OrderPricingEditComponent,
    EditOrderDetailsComponent,
    ClientMessagesComponent,
    EditClientComponent,
    InvoiceComponent,
    OrganizationListComponent,
    NewOrganizationComponent,
    EditOrganizationComponent,
    ClientTypeComponent,
    EtaAlertComponent,
    ErrorDialogComponent,
    CancelledOrderComponent,
    OrderSearchComponent,
    Neworder2Component,
    CustomAutofocusDirective,
    AbstractorListComponent,
    AbstractorNewComponent,
    AbstractorEditComponent,
    DragDropDirective,
    NoWhitespaceDirective,
    AbstractorSendComponent,
    CreateAbsDialogComponent,
    StarRatingComponent,
    BulkOrderComponent,
    ClientPasswordResetComponent,
    NoDecimalDirective,
    LoginHistoryComponent,
    JoinCompanyComponent,
    AddAdmin2companyComponent,
    SsnFieldPipe,
    EinFieldPipe,
    SSNValidator,
    EINValidator,
    RateAbstractorComponent,
    AbstractorMessageComponent,
    AbstractorMessagesComponent,
    OldAbstractorMailComponent,
    NewAbstractorMessageComponent,
    ChangeCompanyComponent,
    ScrollTopDirective,
    OneDecimalDirective,
    OauthSuccessComponent,
    NewSearchMasterComponent,
    EditSearchMasterComponent,
    ListSearchMasterComponent,
    WhitespaceDirective,
    AllOrdersComponent,
    OrderFlyComponent,
    ReviewCompletePackageComponent,
    MatSelectFilterComponent,
    UsPhonenumberPipe,
    ActionCommentComponent,
    EscapePressDirective,
    SortPipe,
    OrderByPipe,
    CreateEditTagComponent,
    TagsComponent,
    SearchPipe,
    NewTagComponent,
    OrderExpenseEditComponent,
    SearchPropsPipe,
    ConfigurationsComponent,
    QuicklinkFormComponent,
    InternalNotesComponent,
    ScreenOrderComponent,
    ConfimedOrdersComponent,
    ValidateOrdAddressComponent,
    SitusamcAcceptReject,
    MatSelectSearchComponent,
    TimecompleteComponent,
    UploadTypesComponent,
    EditPdfDataComponent,
    TableEditableComponent,
    DigitOnlyDirective,
    MatSingleSelectSearchComponent,
    DuedateComponent,
    GroupListingComponent,
    CreateGroupComponent,
    EditGroupComponent,
    AddUser2groupComponent,
    MatMultiSelectForListComponent,
    OrderChangeReasonComponent,
    EtaChangeReasonComponent,
    OrderChecklistComponent,
    NotificationSnackbarComponent,
    NotificationsComponent,
    PausedOrdersComponent,
    ErrorReportComponent,
    CreateProductComponent,
    MatSelectSearchObjComponent,
    EditProductComponent,
    SelectCheckAllComponent,
    CancelOrderMailComponent,
    NotificationGridComponent,
    DatetimeFormComponent,
    ReportsComponent,
    QuoteDetailsComponent,
    RequestQuoteComponent,
    QuoteCompleteComponent,
    NewClientOrdersComponent,
    ChangeProductTypeComponent,
    BillingComponent,
    HolidayScheduleComponent,
    HolidayConfigComponent,
    QuoteRequest1Component,
    QuoteRequest2Component,
    QuoteRequest3Component,
    ConvertOrderComponent,
    NotificationListComponent,
    MatSingleSearchProdComponent,
    AbstractorQuoteComponent,
    AbsQuoteMessageComponent,
    NewAbsQuoteComponent,
    TrackerproResponseHandlerComponent,
    TestMaskPipe,
    AgGridTooltipComponent,
    EmailTemplateListingComponent,
    EmailTemplateComponent,
    CurrencyInputDirective,
    PriorityOrdersComponent,
    WatchOrdersComponent,
    ExcludedDomainsComponent,
    AddExceptionComponent,
    EditExceptionComponent,
    PippinDatesComponent,
    ButtonRendererComponent,
    SlideComponent,
    MatMenuSearchComponent,
    HubspotOauthComponent,
    ErrorDialogMessageComponent,
    OrderNoteComponent,
    ReportBuilderErrSnackbarComponent,
    DailyTimeBoxComponent,
    EscalatedOrdersComponent,
    OrderListingTableComponent,
    CopyToClipboardComponent,
    UserStatusComponent,
    OrderAssignComponent,
    CommitmentInputComponent,
    EditQuoteDetailsComponent,
    ChkIdentificationComponent,
    ChkDeliveryComponent,
    ChkTaxesComponent,
    ChkLegalComponent,
    ChkJudgementsComponent,
    ChkCotComponent,
    ChkProceedingsComponent,
    ChkSecInstComponent,
    ChkDocsComponent,
    ChkSopComponent,
    ChkFeesComponent,
    ReportBuilderComponent,
    RbReportComponent,
    RbVersionsComponent,
    RbPropertyComponent,
    RbSearchPartyComponent,
    RbAssessmentComponent,
    RbTaxComponent,
    RbVestingComponent,
    RbDeedComponent,
    RbSecInstComponent,
    RbLiensComponent,
    RbEncumbranceComponent,
    RbCommentsComponent,
    RbCommitmentsComponent,
    RbGlobalCommitmentsComponent,
    RbPartySectionComponent,
    RbAssessmentSectionComponent,
    RbTaxSectionComponent,
    RbVestingSectionComponent,
    RbDeedSectionComponent,
    RbSecInstSectionComponent,
    RbLiensSectionComponent,
    RbEncumbranceSectionComponent,
    RbGlobCommSectionComponent,
    RbDocumentComponent,
    RbCommInputComponent,
    RbGlobCommInputComponent,
    E500Component,
    RbAdditionalDataComponent,
    RbErrSnackbarComponent,
    RbSectionScrollerComponent,
    CustomerDropdownComponent,
    OrgCommitmentsComponent,
    EditCommitmentsComponent,
    SelectUserComponent,
    CountiesComponent,
    RbAddNewWidgetComponent,
    VendorOrdersComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CookieModule.withOptions(),
    // SocketIoModule.forRoot(configSocket),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    routing,
    RouterModule,
    BootstrapModalModule.forRoot({ container: document.body }),
    // JWBootstrapSwitchModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatCheckboxModule,
    MatCardModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatMenuModule,
    MatListModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    ClipboardModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatTabsModule,
    MatExpansionModule,
    MatDividerModule,
    MatFormFieldModule,
    MatBadgeModule,
    CommonModule,
    InfiniteScrollModule,
    EditorModule,
    // StorageServiceModule,
    DragDropModule,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
    AutocompleteLibModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    NgxMatSelectSearchModule,
    // AgGridModule.withComponents([ButtonRendererComponent]),
    AgGridAngular,
    // MatPasswordStrengthModule.forRoot(),
    AngularClipboardModule

  ],
  providers: [
    AuthService,
    CookieService,
    ConfigService,
    AuthGuardService,
    RoleGuardService,
    PreloaderService,
    EmailValidator,
    PasswordValidator,
    ClientPasswordValidator,
    PhoneValidator,
    EqualValidator,
    ProgressBarService,
    PagerService,
    AboutService,
    UsersService,
    ClientsService,
    OrdersService,
    EulaService,
    FileService,
    PricingService,
    NewOrderService,
    ProductService,
    MessagesService,
    SocketService,
    StateService,
    InvoiceService,
    OrganizationService,
    SiteSettingService,
    AbstractorService,
    SSNValidator,
    EINValidator,
    DatePipe,
    ScrollService,
    SearchMasterService,
    AdminOnlyGuardService,
    QuickbooksService,
    AwsS3Service,
    RoutingStateService,
    TagsService,
    ExpenseService,
    QuickLinksService,
    GroupsService,
    NotificationsService,
    QuoterequestService,
    SchedulingService,
    RptBuilderService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpService, multi: true },
    CurrencyPipe,
    { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill", FloatLabel: "always", hideRequiredMarker: false },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
