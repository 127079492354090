// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prefill_ribbon {
  background-color: #F3FBFF;
  font-weight: 600;
  padding: 12px 15px 12px 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/report-builder/report-builder.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,gBAAA;EACA,4BAAA;AACF","sourcesContent":[".prefill_ribbon{\n  background-color: #F3FBFF;\n  font-weight: 600;\n  padding: 12px 15px 12px 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
