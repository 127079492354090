import { Component, OnInit } from '@angular/core';
import { ConfigService, OrdersService, PricingService, UsersService, AuthService,
  NewOrderService,StateService, ProductService, ScrollService, PagerService, PreloaderService } from '../services';
  import { DialogService } from "ng2-bootstrap-modal";
  import { CONSTANTS } from '../app.constants';
  import { Router} from '@angular/router';
  import { ErrorDialogComponent } from '../dialog/error-dialog/error-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  products: any = [];
  listingType = '3';
  constants = CONSTANTS;
  timer: any = [];
  searchString: string;
  searchInProgress: boolean = false;
  pageNum: number;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  scrollEnable: boolean = true;
  searchQuery = new Subject<string>();
  sortField:string;
  orderBy: string;
  totalCount: number;
  pager: any = {};
  productToDisplay: any;
  constructor(
    private productList: OrdersService,
    private newOrder: NewOrderService,
    private stateService:StateService,
    private matDialog:MatDialog,
    private router:Router,
    private auth:AuthService,
    private config:ConfigService,
    private prodService:ProductService,
    private scrollService:ScrollService,
    private pagerService: PagerService,
    private preloaderService: PreloaderService
  ) {
    if (this.pageNum == undefined) this.pageNum = 1;
    this.searchString = '';
    this.orderBy = 'ASC';
    this.sortField = 'Product_Description';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  detectTypeChange(event) {
    this.pageNum = 1;
    this.scrollService.setScrollPosProduct(0);
    this.getCompleteProductList();
  }

  setSort(field: string) {
    this.pageNum = 1;
    console.log("setSort", field);
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getCompleteProductList();
  }

  searchField(search){
    this.scrollService.setScrollPosProduct(0);
    var format = /[!@#$%^*\=\[\]{};:\\|,.<>\/?]/;
    search=search.trim();
    this.searchString = search;
    if(!format.test(this.searchString) && this.searchString==" "){
      this.searchString='';
      this.getCompleteProductList();
    }
    else this.findProducts();
  }

  findProducts() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getCompleteProductList();
  }

  ngOnInit() {
    if(this.stateService.getProducts()){
      this.initializeComponentData(this.stateService.getProducts())
    }
    else this.getCompleteProductList();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(6), 0));
  }
  
  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getCompleteProductList() {
    if (this.pageNum == 1) {
      this.stateService.setProducts(this);
      this.prodService.getAllProductsData(this.pageNum, this.getSearchString(), this.listingType, this.orderBy, this.sortField)
      .subscribe((result1) => {
        if (result1.count > this.config.getNumRecordsPerPage()) {
          this.pageNum++;
          this.prodService.getAllProductsData(this.pageNum, this.getSearchString(), this.listingType, this.orderBy, this.sortField)
            .subscribe((result2) => {
              var result = {
                rows: result1.rows.concat(result2.rows),
                count: result1.count
              }
              this.products = result.rows;
              this.totalCount = result.count;
              this.getProductsData(this.totalCount)
              this.getScrollPosData()
            })
        } else {
          this.products = result1.rows;
          this.totalCount = result1.count;
          this.getProductsData(this.totalCount);
          this.getScrollPosData()
        }
      })
    } else this.getRetainData()
  }

  getRetainData() {
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.prodService.getAllProductsData(i, this.getSearchString(), this.listingType, this.orderBy, this.sortField))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedTags) => {
        if (retainedTags) {
          var totalOrders = [];
          for (var i = 0; i < retainedTags.length; i++) {
            this.stateService.setProducts(this)
            totalOrders = totalOrders.concat(retainedTags[i]['rows']);
          }
          this.products = totalOrders;
          this.totalCount = retainedTags[0]['count'];
          this.getProductsData(this.totalCount);
          this.getScrollPosData()
        }
      })
  }

  getScrollPosData() {
    if (this.scrollService.getScrollPosProduct()) {
      var x = this.scrollService.getScrollPosProduct()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }))
    }
  }

  getProductsData(result) {
    this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setDisplayOrders();
    this.pager = this.pagerService.getPager(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  getSearchString() {
    var format = /[!@#$%^*\=\[\]{};:\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }

  setDisplayOrders() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    if (this.totalCount != undefined) this.productToDisplay = this.products.slice(start, this.totalCount);
    if (this.productToDisplay.length > this.config.getNumRecordsPerPage()) this.productToDisplay = this.productToDisplay.slice(0, this.config.getNumRecordsPerPage());
  }

  initializeComponentData(data){
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.totalCount = data.totalCount;
    this.listingType = data.listingType;
    this.productToDisplay = data.productToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.sortField = data.sortField;
    this.orderBy = data.orderBy;
    this.getCompleteProductList();
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  checkForSuperAdmin() {
    if((Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) || (Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin)) return true;
    else return false;
  }

  createNewProduct() {
    this.router.navigate(['/create/product'])
  }

  goToProductDetails(productID) {
    var getPos = document.documentElement.scrollTop;
    this.scrollService.setScrollPosProduct(getPos);
    this.router.navigate(['/edit/product/' + productID])
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.prodService.getAllProductsData(this.pageNum, this.getSearchString(), this.listingType, this.orderBy, this.sortField)
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag()
            this.totalCount = result.count;
            if (this.totalCount > this.products.length) {
              this.products = this.products.concat(result.rows);
              this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  
}
