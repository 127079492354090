import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService, EmailValidator, EqualValidator, UsersService, OrganizationService, ConfigService, UsermessageService } from '../../services'
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from '../../dialog/confirm/confirm.component'
import { SuccessComponent } from '../../dialog/success/success.component';
import { CONSTANTS } from '../../app.constants'
import { LoginHistoryComponent } from '../../dialog/login-history/login-history.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  userForm: any = {}
  userModel: any = {}
  userId: string = '';
  error: any = {}
  organizations = [];
  prvOrganizations = [];
  addedOrgs = [];
  errorMsg: any;
  constants = CONSTANTS;
  timer: any = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private frmBuilder: UntypedFormBuilder,
    public auth: AuthService,
    private matDialog:MatDialog,
    private userService: UsersService,
    private organizationService: OrganizationService,
    private config:ConfigService,
    private userMsg:UsermessageService,
  ) {
    this.userForm = frmBuilder.group({
      'Admin_Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      'Confirm_Email_Address': [null, Validators.required],
      'Admin_First_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'Admin_Last_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'isSuper': [false],
      "userActive": [false],
      "Organization_ID": [null],
      "Added_Organization_ID": [null],
      "readOnlyAdminStatus": [null]
    })
    this.userForm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    this.userModel.readOnlyAdminStatus = false;
    this.getOrganizationList();
    this.userModel.Organization_ID = [];
    if (this.route.snapshot.params['userId']) {
      this.userId = this.route.snapshot.params['userId'];
      this.userForm = this.frmBuilder.group({
        'Admin_Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
        'Admin_First_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
        'Admin_Last_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
        'isSuper': [false],
        "userActive": [false],
        "Organization_ID": [null],
        "Added_Organization_ID": [null],
        "readOnlyAdminStatus": [null]
      });
      this.getUserInfo();
      this.addedOrganizationList(this.route.snapshot.params['userId']);
    } else {
    } this.userModel.Is_Super = false;
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();
    }, 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  isOrgID() {
    if (this.userModel.Organization_ID.length ==  0) return true;
    else return false;
  }

  // isOrg() {
  //   if (this.userModel.prvOrganizations == 0) return true;
  //   else return false;
  // }

  isNewUser() {
    if (this.route.snapshot.params['userId']) return false;
    else return true;
  }

  resetOrderData(event) {
    console.log("event", event)
  }

  addedOrganizationList(userId) {
    this.organizationService.getAssociatedOrgs(userId)
      .subscribe((result) => {
        if (result) {
          this.prvOrganizations = result;
        }
        else this.prvOrganizations = [];
      }, (err) => {
        this.errorMsg = err;
      })
  }

  deleteOrg(orgDetails) {
    this.organizationService.deleteOrganization(orgDetails)
      .subscribe((result) => {
        if (result) {
          this.addedOrganizationList(this.route.snapshot.params['userId']);
        }
      }, (err) => {
        this.errorMsg = err;
      })
  }


  addOrganization(ev) {
    this.userModel.Modified_By = this.auth.getUserId();
    this.organizationService.addSelectedOrgToAdmin(this.userModel)
      .subscribe((result) => {
        if (result) {
          this.userModel.Organization_ID= [];
          ev._selected = false;
          this.addedOrganizationList(this.route.snapshot.params['userId']);
        }
      }, (err) => {
        this.errorMsg = err;
      })
  }


  getOrganizationList() {
    this.organizationService.getAllOrganizations()
      .subscribe((result) => {
        if (result) {
          this.organizations = result;
          // if ((this.prvOrganizations.length !=0)  && this.organizations.length !=0) {
          //   console.log("Inside if 2");
          //   for (var i = 0; i < this.prvOrganizations.length; i++) {
          //     for (var j = 0; j < this.organizations.length; j++) {
          //       console.log("this.prvOrganizations[i]", this.prvOrganizations[i]);
          //       console.log("this.organizations[j]", this.organizations[j]);
          //       if (this.prvOrganizations[i]['Organization_Name'] == this.organizations[j]['Organization_Name']) {
          //         console.log("Inside if 1");
          //         this.organizations.splice(this.organizations[j], 1)
          //         console.log(" this.organizations splice", this.organizations);
          //       }
          //     }
          //   }
          // }
          // console.log("this.organizations", this.organizations);
        }
      }, (err) => {
        this.errorMsg = err;
      })
  }

  changeModelVal(modelVal) {
    this.error = {};
  }

  checkIfOrgAvailable(org) {
    if (this.prvOrganizations) {
      if (this.prvOrganizations.findIndex(x => x.Organization_ID == org.Organization_ID) > -1) {
        return false;
      }
      return true;
    }
    else return true;
  }

  getUserInfo() {
    this.userService.getUserInfo(this.userId)
      .subscribe((data) => {
        if (data) {
          this.loadData(data)
        }
      }, (err) => {
        console.log(err);
      })
  }

  confirm() {
    if (this.route.snapshot.params['userId']) {
      this.updateUser();
    }
    else {
      this.createUser();
    }
  }

  updateUser() {
    this.userService.updateUser(this.userModel)
      .subscribe((data) => {
          if (this.route.snapshot.params['userId'] == this.auth.getUserId()) this.auth.updateCookie(data);
        let msg = "Updated succesfully."
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/users']);
        // this.openModal('Success', 'Updated succesfully.').subscribe((res) => {
        //   if (this.route.snapshot.params['userId'] == this.auth.getUserId()) this.auth.updateCookie(data);
        //   this.router.navigate(['/users'])
        // });       
      }, (err) => {
        this.errorMsg = err
      })
  }

  createUser() {
    this.userService.createUser(this.userModel)
      .subscribe((data) => {
        let msg = "Password link has been sent to the registered email."
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/users']);
        // this.openModal('Success', 'Password link has been sent to the registered email.').subscribe((res) => {
        // });        
      }, (err) => {
        this.errorMsg = err
      })
  }

  openModal(title, msg) {
    return this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  loadData(data) {
    this.userModel.Admin_First_Name = data.User_First_Name;
    this.userModel.User_Role = data.User_Role;
    if (this.userModel.Admin_First_Name) {
      document.getElementById('Admin_First_Name').parentElement.classList.remove('is-empty')
    }
    this.userModel.Admin_Last_Name = data.User_Last_Name;
    if (this.userModel.Admin_Last_Name) {
      document.getElementById('Admin_Last_Name').parentElement.classList.remove('is-empty')
    }
    this.userModel.Admin_Email_Address = data.Email_Address;
    if (this.userModel.Admin_Email_Address) {
      document.getElementById('Admin_Email_Address').parentElement.classList.remove('is-empty')
    }

    this.userModel.Admin_ID = data.User_ID
    this.userModel.User_Status = data.User_Status;
    if (data.User_Status == CONSTANTS.userStatus.inActive) {
      this.userModel.userState = false;
    } else {
      this.userModel.userState = true;
    }
    if (data.User_Role == CONSTANTS.userRole.compAdmin) this.userModel.Is_Super = true;
    if (data.User_Type == CONSTANTS.userType.ReadOnlyAdmin) {
      this.userModel.readOnlyAdminStatus = true;
    } else {
      this.userModel.readOnlyAdminStatus = false;
    }
  }

  openConfirm(title, msg) {
    return this.matDialog.open(ConfirmComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  userAction(event) {
    if (this.userModel.User_Status != this.constants.userStatus.inActive) {
      this.openConfirm('Deactivate', 'Would you like to de-activate this user ?').afterClosed()
        .subscribe((res) => {
          if (res == true) {
            this.deactivateUsr();
          }
          else {
            this.userModel.userState = !this.userModel.userState
          }
        });
    } else if (this.userModel.User_Status == this.constants.userStatus.inActive) {
      this.openConfirm('Activate', 'Would you like to re-activate this user ?').afterClosed()
        .subscribe((res) => {
          if (res == true) {
            this.activateUsr();
          }
          else {
            this.userModel.userState = !this.userModel.userState
          }
        });
    }
  }

  activateUsr() {
    var data = {
      Admin_ID: this.auth.getUserId(),
      User_ID: this.route.snapshot.params['userId']
    }
    this.userService.activateUser(data)
      .subscribe((data) => {
        let msg = "Password reset link has been sent to the registered email."
        this.userMsg.setSuccessMsg(msg);
        // this.openModal('Success', 'Password reset link has been sent to the registered email.')
        // .subscribe(() => {
        this.router.navigate(['/users'])
        // })
      }, (err) => {
        this.errorMsg = err
      })
  }

  deactivateUsr() {
    var data = {
      Admin_ID: this.auth.getUserId(),
      User_ID: this.route.snapshot.params['userId']
    }
    this.userService.deactivateUser(data)
      .subscribe((data) => {
        let msg = "User deactivated successfully."
        this.userMsg.setSuccessMsg(msg);
        // this.openModal('Success', 'User deactivated successfully.').subscribe(()=> {          
          this.router.navigate(['/users']);
        // })
      }, (err) => {
        this.errorMsg = err
      })
  }

  getAdminLoginInfo(){
    this.userService.getAdminLoginInfo(this.route.snapshot.params['userId'])
    .subscribe((result) => {
      let disposable = this.matDialog.open(LoginHistoryComponent, {data:{
        message: result
      }, ...this.config.getDialogOptions()});
    }, (err) => {
      console.log(err);
    })
  }

    multipleSelect(ev){
    if(ev._selected){
     let remainedOrg = [];
     this.organizations.filter(x => {
       if(!this.prvOrganizations.some(y=> y.Organization_ID === x.Organization_ID))
       remainedOrg.push(x.Organization_ID);
     });
     this.userForm.controls['Organization_ID'].setValue(remainedOrg)
     ev._selected=true;
    }
     if(ev._selected==false){
       this.userForm.controls['Organization_ID'].setValue([]);
     }    
   }

   readOnlyUserAction(event) {
    if (this.userModel.readOnlyAdminStatus) {
      this.openConfirm('Admin', 'Would you like to convert admin to read-only admin ?').afterClosed()
        .subscribe((res) => {
          if (res == true) {
            this.readOnlyUsr();
          }
          else {
            this.userModel.readOnlyAdminStatus = !this.userModel.readOnlyAdminStatus
          }
        });
    } else if (!this.userModel.readOnlyAdminStatus) {
      this.openConfirm('Read-Only Admin', 'Would you like to convert read-only admin to admin ?').afterClosed()
        .subscribe((res) => {
          if (res == true) {
            this.adminUsr();
          }
          else {
            this.userModel.readOnlyAdminStatus = !this.userModel.readOnlyAdminStatus
          }
        });
    }
  }

  readOnlyUsr(){
    var data = {
      Admin_ID: this.auth.getUserId(),
      User_ID: this.route.snapshot.params['userId'],
      User_Type: this.constants.userType.ReadOnlyAdmin
    }
    this.userService.readOnlyUsr(data)
      .subscribe((data) => {
        let msg = "Converted admin to read-only admin successfully."
        this.userMsg.setSuccessMsg(msg);
        // this.openModal('Success', 'User deactivated successfully.').subscribe(()=> {          
          this.router.navigate(['/users']);
        // })
      }, (err) => {
        this.errorMsg = err
      })
  }

  adminUsr(){
    var data = {
      Admin_ID: this.auth.getUserId(),
      User_ID: this.route.snapshot.params['userId'],
      User_Type: this.constants.userType.Admin
    }
    this.userService.readOnlyUsr(data)
      .subscribe((data) => {
        let msg = "Converted read-only admin to admin successfully."
        this.userMsg.setSuccessMsg(msg);
        // this.openModal('Success', 'Password reset link has been sent to the registered email.')
        // .subscribe(() => {
        this.router.navigate(['/users'])
        // })
      }, (err) => {
        this.errorMsg = err
      })
  }
}
