import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { ConfigService, OrdersService } from '../../services';
import { CONSTANTS } from '../../app.constants';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-eta-change-reason',
  templateUrl: './eta-change-reason.component.html',
  styleUrls: ['./eta-change-reason.component.scss']
})
export class EtaChangeReasonComponent  {
  newGrpFrm: UntypedFormGroup;
  messageText = '';
  selectedReason = '';
  selectType: any;
  error: string = '';
  constants = CONSTANTS;
  etaReasonsTypes: any = [];
  timer: any = [];
  label = 'Order'
  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EtaChangeReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrdersService,
    private matDialog:MatDialog,
    private config: ConfigService
  ) {
    
  }

  ngOnInit() {
    if(this.data && this.data.label){
      this.label = this.data.label
    }
    this.timer.push(setTimeout(() => { this.getETAChangeReasonTypes(); }, 0))
  }


  getETAChangeReasonTypes() {
    this.orderService.getETAChangeReasonTypes()
      .subscribe((res) => {
        if (res) {
          this.etaReasonsTypes = res;
        }
      }, (err) => {
        this.openErrorPopup('Error while fetching data. Please try again.');
      })
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  onSelect(event) {
    // if (this.selectType != 999) {  // PT-3628
      var selectedOption = this.etaReasonsTypes.filter((reason) => reason.Order_ETA_Change_Reason_Type_ID == this.selectType);
      this.selectedReason = selectedOption[0].Order_ETA_Change_Reason_Text;
    // }
  }

  confirm() {
     let result:any= {
      state: true,
      Order_ETA_Change_Reason_Type_ID: this.selectType
    }
    // PT-3628
     result.Message_Text = this.selectedReason + " - " + this.messageText.trim();

     this.dialogRef.close(result);
    
    // if (this.selectType == 999)  this.dialogRef.close(.Message_Text = this.messageText.trim()
    // else  this.dialogRef.close(.Message_Text = this.selectedReason;
    
    this.clearTime();
  }

  // PT-3628
  // isInValid() {
  //   if (this.selectType == 999) {
  //     return this.messageText.trim().length == 0;
  //   } else if (!this.selectType) return true;
  // }

  // PT-3628
  isInValid() {
    if (!this.selectType) return true;
    return this.messageText.trim().length == 0; 
  }

  Close() {
     this.dialogRef.close(false);
    this.clearTime()
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
