import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { AuthService } from '../../services/authentication/auth.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AutogrowDirective } from '../../directives';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OrdersService, QuickbooksService, FileService, ConfigService } from '../../services'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: "app-cancel-order-mail",
  templateUrl: "./cancel-order-mail.component.html",
  styleUrls: ["./cancel-order-mail.component.scss"],
})
export class CancelOrderMailComponent{


  
  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;

  message: any;
  title: any;
  client_mail_text: string = "";
  resourceUrls = [];
  timer: any = [];
  checked: boolean = true;
  fileURL: SafeResourceUrl;
  tinyOptions: any = {
    branding:false,
    promotion :false,
    skin:'oxide',
    menubar:false,
    auto_focus: false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"],
    toolbar:
      "undo redo | styleselect | forecolor backcolor |  bold italic |  alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
  };



  constructor( private auth: AuthService, private ordersService: OrdersService,
    private ref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CancelOrderMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
  }

  ngOnInit() {
    this.initDialog();
    this.ref.detectChanges();
    this.timer.push(
      setTimeout(() => {
        this.myFirstFocus.nativeElement.focus();
      }, 0)
    );
    this.ordersService
      .getOrderDocumentUrls(this.message.Order_ID)
      .subscribe((urls) => {
        this.resourceUrls = urls;
        this.constructMessage();
      });
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  constructMessage() {
    this.client_mail_text =
      "<br>" +
      "<p>Your order has been cancelled. Relevant document links are attached. Please click to download.</p>";
    // this.resourceUrls.forEach((url) => {
    //     this.client_mail_text += '<a href="' + url.Link + '">' + url.Document_Original_Name + '</a>' + '<br>'
    // })
    this.client_mail_text +=
      "<p>You can also find your order on the Pippin Title Services website after logging in.<br>Thank you for your business! We are happy to assist you with your additional title needs.</p><br>";
  }

  confirm() {
    if(this.checked){
      this.dialogRef.close({
        checked:this.checked,
        mail_text: this.client_mail_text
       });  
    }else this.dialogRef.close({
      checked:false,
      mail_text: ''  
     });
     
    this.clearTime();
  }

  Close() {
    this.dialogRef.close(false);
    this.clearTime();
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
}
