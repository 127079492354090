import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { AbstractorService, ConfigService, UsermessageService } from '../../services';
import { AuthService } from '../../services';
import { CONSTANTS } from '../../../app/app.constants';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { SuccessComponent } from '../../dialog/success/success.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-abstractor-message',
  templateUrl: './new-abstractor-message.component.html',
  styleUrls: ['./new-abstractor-message.component.scss']
  
})
export class NewAbstractorMessageComponent   {

  message: any = {};
  callback: any = null;
  absMessage: string = "";
  docsToSend: any = [];
  tinyOptions: any = {
    auto_focus: false,
    branding:false,
    promotion :false,
    skin:'oxide',
    menubar:false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"],
    toolbar:
      "undo redo | styleselect | forecolor backcolor |  bold italic |  alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
  };

  constructor( private abstractorService: AbstractorService, public auth: AuthService, private config: ConfigService,
  private userMsg: UsermessageService,private matDialog:MatDialog,
  public dialogRef: MatDialogRef<NewAbstractorMessageComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    
  }

  ngOnInit() {
    this.initDialog();

  }

  initDialog(){
    this.message = this.data.message;
    this.callback=this.data.callback;
  }

  ngAfterViewInit() {
    document.querySelector("iframe").setAttribute("data-gramm", "false");
  }

  send() {
    this.abstractorService
      .sendMailToAbstarctor({
        Order_ID: this.message.orderDetails.Order_ID,
        Abstrator_ID: this.message.orderDetails.Abstrator_ID,
        Message_Text: this.formatMsg(this.absMessage),
        Abstractor_Documents: this.docsToSend,
      })
      .subscribe(
        () => {
          this.userMsg.setSuccessMsg(CONSTANTS.apiErrors.absMailSuccess);
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.absMailFail);
        }
      ).add(()=>{this.callback()})
      this.dialogRef.close({
        state: true,
        sentMessage:{
          Message_Text:this.formatMsg(this.absMessage),
          Message_Creation_Date: new Date().toISOString()
        }
      })
  }

  Close() {
    this.dialogRef.close(false);

  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  openSuccessModal(title, msg) {
    this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  isMessageEmpty() {
    var span = document.createElement("span");
    span.innerHTML = this.absMessage;
    var x = span.textContent || span.innerText;
    if (x.trim().length == 0) return true;
    else return false;
  }

  formatMsg(msg){
    return msg.replace(/(<br\s*\/?>\s*)+/g, '</p><br><p>')
    .replace(/(&nbsp;)+/g, '')
    .replace(/>\s*</g, '><')
    .replace(/<p>(&nbsp;|\s)*<\/p>/g, '')
    .replace(/<\/p><p>/g, '</p><br><p>')
    .replace(/(<br>\s*)+$/, '')
    .replace(/\s+/g, ' ')
    .replace(/<br\s*\/?>/g, '\n').trim()
  }
}
