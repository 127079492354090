// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog {
  width: 43rem !important;
  margin-top: 6rem !important;
}

.dottedLine {
  cursor: not-allowed;
}

.errMsg {
  margin-top: -1.3em;
  font-size: 75%;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/situsamc-accept-reject/situsamc-accept-reject.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,2BAAA;AACJ;;AAEA;EAOI,mBAAA;AALJ;;AAQE;EACE,kBAAA;EACA,cAAA;AALJ","sourcesContent":[".modal-dialog {\n    width: 43rem !important;\n    margin-top: 6rem !important;\n}\n\n.dottedLine {\n    // background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);\n    // background-size: 4px 1px;\n    // background-repeat: repeat-x;\n    // position: relative;\n    // background-position-y: 24px;\n    // padding-bottom: 8px;\n    cursor: not-allowed;  \n  }\n\n  .errMsg {\n    margin-top: -1.3em;\n    font-size: 75%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
