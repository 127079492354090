import { Directive, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appOneDecimal]',
  host: {
    '(ngModelChange)': 'validateDec($event)',
  }
})

export class OneDecimalDirective {
  @Output() private isValid = new EventEmitter<any>();
  @Input() private elemntID;
  data={};
  validateDec(event) {
    var flag=0;
    var firstIndex=event.indexOf('.');
    var len=event.length;
    if(firstIndex == len-1) flag++;
    else if(event.indexOf('.',firstIndex +1) > -1) flag++;
    if(flag ==0){
    this.data={
      ID:this.elemntID,
      status:true
    }
    this.isValid.emit(this.data);
    }
    else {
      this.data={
        ID:this.elemntID,
        status:false
      }
    this.isValid.emit(this.data);
    }
  }
}