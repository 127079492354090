import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  ConfigService,
  UsersService,
  OrdersService,
  StateService,
  UsermessageService,
  PricingService,
  FileService,
} from "../../../services";
import { CONSTANTS } from "../../../app.constants";
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from "../../../dialog/error-dialog/error-dialog.component";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { Location } from "@angular/common";
import * as XLSX from "xlsx/xlsx";
import { MatDialog } from "@angular/material/dialog";
import { CountiesService } from "app/services/counties/counties.service";
type AOA = Array<Array<any>>;
const wb: any = {};
const STATE = "STATE";
const COUNTY = "COUNTY";

@Component({
  selector: "app-upload-types",
  templateUrl: "./upload-types.component.html",
  styleUrls: ["./upload-types.component.scss"],
})
export class UploadTypesComponent implements OnInit {
  @ViewChild("fileInput") fileInput: any;
  @ViewChild("firstFocus") firstFocus: ElementRef;
  uploadType = null;
  selectedCustomer = "";
  selectedOrganizationID = "";
  fileName = "";
  customers = [];
  templeteUrls = [];
  uploadTypes = [
    { type: "PDF order upload for Mortgage Connect and Inspire", value: "1" },
    { type: "Excel bulk order upload", value: "2" },
    { type: "Product pricing bulk upload", value: "3" },
    { type: "Custom pricing bulk upload", value: "4" },
    { type: "Data depth bulk upload", value: "5" },
  ];
  selectedFile: File;
  xlData = null;
  inProgress: boolean = false;
  completed: number = 0;
  dataDepthDetails: any = [];
  products: any = [];
  refHeader: any = [];
  headerdata: any = [];
  failedData: any = [];
  headerKeys: any = {};
  sheetData: any = {};
  isUpload: boolean = false;
  isvalidSheet: boolean = false;
  logData: any = [];
  isUploadClicked: boolean = false;
  excelFile: any = {};
  timer: any = [];
  clearData: boolean = false;
  customPricingCustomers = [];
  selectedCustomerForCustomPrice = "";
  constructor(
    private userService: UsersService,
    private matDialog: MatDialog,
    private config: ConfigService,
    private router: Router,
    private location: Location,
    private orderService: OrdersService,
    private stateService: StateService,
    private userMsg: UsermessageService,
    private productList: OrdersService,
    private priceService: PricingService,
    private fileService: FileService,
    private countiesService: CountiesService
  ) {}

  ngOnInit() {
    if (this.stateService.getUploadTypesData()) {
      this.initializeComponentData(this.stateService.getUploadTypesData());
    }
    // this.getAllCustomers();
    this.getTempleteUrls();
    this.priceService.getCustomerListForCustomPricing().subscribe((data) => {
      this.customPricingCustomers = data;
    });
    this.timer.push(
      setTimeout(() => {
        this.config.setSideBarNode(0);
        // this.firstFocus.nativeElement.focus();
      }, 0)
    );
  }

  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }
  setComponentDataToService() {
    var retainedData: any = {};
    retainedData.uploadType = this.uploadType;
    retainedData.selectedCustomer = this.selectedCustomer;
    retainedData.selectedCustomerForCustomPrice =
      this.selectedCustomerForCustomPrice;
    retainedData.fileName = this.fileName;
    retainedData.selectedFile = this.selectedFile;
    retainedData.xlData = this.xlData;
    if (this.uploadType === "1") retainedData.customers = this.customers;
    return retainedData;
  }

  getOrderProductsForClient(customerId) {
    console.log({ customerId });
    this.resetHeaderDataInUI();
    let customerIndex = this.customers.findIndex(
      (customer) => customer.User_ID == customerId
    );
    if (customerIndex > -1) {
      let userId;
      if (this.customers[customerIndex].Organization_ID)
        userId = this.customers[customerIndex].Organization_ID;
      else userId = this.customers[customerIndex].User_ID;
      this.orderService.getOrderProductsForClient(userId).subscribe((data) => {
        if (data) {
          this.products = data;
        }
      });
    }
  }

  getOrderProductsForClientV2(data) {
    console.log({ getOrderProductsForClientV2_data: data });
    const userId = data.Organization_ID ? data.Organization_ID : data.User_ID;
    this.selectedCustomer = data.User_ID;
    this.selectedOrganizationID = data.Organization_ID;
    this.orderService.getOrderProductsForClient(userId).subscribe((data) => {
      if (data) {
        this.products = data;
      }
    });
  }

  resetHeaderDataInUI() {
    if (this.headerdata.length > 0) {
      this.headerdata = [];
      this.failedData = [];
      this.fileInput.nativeElement.value = null;
      this.isUpload = false;
      this.isvalidSheet = false;
      this.logData = [];
      this.isUploadClicked = false;
      this.excelFile = {};
    }
  }

  getOrderProducts(userId) {
    this.resetHeaderDataInUI();
    this.orderService
      .getOrderProductsForClientBulk(userId, false)
      .subscribe((data) => {
        if (data) {
          const ids = data.map((o) => o.Product_ID);
          const filtered = data.filter(
            ({ Product_ID }, index) => !ids.includes(Product_ID, index + 1)
          );
          this.products = filtered;
          var dontUpload: any = {};
          dontUpload.Product_ID = -1;
          dontUpload.Product_Description = "Do not upload";
          this.products.unshift(dontUpload);
        }
      });
  }

  initializeComponentData(data) {
    this.uploadType = data.uploadType;
    this.selectedCustomer = data.selectedCustomer;
    this.selectedCustomerForCustomPrice = data.selectedCustomerForCustomPrice;
    this.fileName = data.fileName;
    this.selectedFile = data.selectedFile;
    this.xlData = data.xlData;
    if (data.uploadType === "1") {
      this.customers = data.customers;
      this.getOrderProductsForClient(this.selectedCustomer);
    }
  }

  getAllCustomers() {
    this.userService.getAllCustomers().subscribe(
      (users) => {
        this.customers = users;
      },
      (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      }
    );
  }

  getTempleteUrls() {
    this.fileService.getTempleteUrls().subscribe(
      (templeteUrls) => {
        if (templeteUrls) {
          this.templeteUrls = templeteUrls;
        }
      },
      (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
      }
    );
  }

  downloadTemplate() {
    if (this.uploadType && this.uploadType == 2) {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = "Bulk_Order_Template";
      link.href = this.templeteUrls[0].bulkOrdersLink;
      link.click();
      document.body.removeChild(link);
    } else if (
      this.uploadType &&
      (this.uploadType == 3 || this.uploadType == 4)
    ) {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = "Pricing_Template";
      link.href = this.templeteUrls[1].bulkPricingLink;
      link.click();
      document.body.removeChild(link);
    } else if (this.uploadType && this.uploadType == 5) {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = "Data_Depth_Template";
      link.href = this.templeteUrls[2].bulkDataDepthLink;
      link.click();
      document.body.removeChild(link);
    }
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {
      data: {
        message: msg,
      },
      ...this.config.getDialogOptions(),
    });
  }

  goBack() {
    this.stateService.setUploadTypesData(null);
    this.router.navigate(["/orders/new"]);
  }
  clearFileName() {
    this.fileName = "";
    this.xlData = null;
    this.resetHeaderData();
  }

  openFileSelector() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  checkIfMultiFiles(evt) {
    if (evt.target.files.length > 1) return true;
    else return false;
  }

  uploadDocument(evt) {
    if (!this.checkIfMultiFiles(evt)) {
      this.fileName = evt.target.files[0].name;
      this.selectedFile = evt.target.files[0];
    } else {
      this.openErrorPopup("Cannot upload multiple files on the entry");
    }
  }

  extractPDFData() {
    this.orderService
      .extractPDFData(
        this.selectedFile,
        this.uploadType == 4
          ? this.selectedCustomerForCustomPrice
          : this.selectedCustomer
      )
      .subscribe(
        (pdfData) => {
          pdfData.Organization_ID = this.selectedOrganizationID;
          pdfData.customerID =
            this.uploadType == 4
              ? this.selectedCustomerForCustomPrice
              : this.selectedCustomer;
          pdfData.selectedFile = this.selectedFile;
          pdfData.products = this.products;
          this.stateService.setPDFData(pdfData);
          this.router.navigate(["edit-pdf-data"]);
        },
        (err) => {
          this.openErrorPopup("Error while featching data.");
        }
      );
  }

  /////////////////////// Bulk order upload code ////////////////////

  fetchDataFromXL(evt) {
    if (!this.checkIfMultiFiles(evt)) {
      var fileList: FileList = evt.target.files;
      this.selectedFile = evt.target.files[0];
      /* wire up file reader */
      if (this.checksize(fileList)) {
        this.fileName = evt.target.files[0].name;
        const target: DataTransfer = <DataTransfer>evt.target;
        // if (target.files.length != 1) throw new Error("Cannot upload multiple files on the entry");
        const reader = new FileReader();
        var sbComponent = this;
        reader.onload = function (e: any) {
          /* read workbook */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });

          /* get first sheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /* save data to scope */
          var data: AOA = [["Excel Sheet"]];
          data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
          sbComponent.xlData = sbComponent.pruneArray(data);
        };
        reader.readAsBinaryString(target.files[0]);
      } else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    } else {
      this.openErrorPopup("Cannot upload multiple files on the entry");
    }
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(
      files,
      (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024
    );
    if (largeFiles.length > 0) return false;
    else return true;
  }
  pruneArray(dataArray) {
    var prunedArray = [];
    dataArray.forEach((item) => {
      if (item.length) prunedArray.push(item);
    });
    return prunedArray;
  }

  uploadData() {
    this.stateService.setUploadTypesData(this.setComponentDataToService());
    if (this.uploadType && this.uploadType == 1) {
      this.extractPDFData();
    } else {
      if (this.xlData.length > 1) {
        if (this.validateHeader() && this.xlData.length > 1) {
          var orders = [];
          let populatedHeaders = [];
          var header = this.duplicateHeaderLabels(this.xlData[0]);
          for (let j = 0; j < this.xlData.length; j++) {
            if (j > 0) {
              var order = {};
              for (let i = 0; i < header.length; i++) {
                order[header[i]] = this.xlData[j][i];
                if (
                  order[header[i]] &&
                  populatedHeaders.indexOf(header[i]) == -1
                )
                  populatedHeaders.push(header[i]);
              }
              orders.push(order);
            }
          }
          this.stateService.setBulkOrdData({
            Orders: orders,
            Headers: populatedHeaders,
          });
          if (this.uploadType == 4)
            this.router.navigate([
              `table-editable/customer/${this.selectedCustomer}/${this.selectedOrganizationID}`,
            ]);
          this.router.navigate([
            `table-editable/customer/${this.selectedCustomer}/${this.selectedOrganizationID}`,
          ]);
        } else
          this.openErrorPopup(
            "The file format is invalid. Please click the link above to download the bulk order template in a valid format."
          );
      } else
        this.openErrorPopup(
          "File is empty/no valid data. Please select file with proper records."
        );
    }
  }

  duplicateHeaderLabels(incomingHeader) {
    let map = {};
    let count = incomingHeader.map(function (val) {
      return (map[val] = typeof map[val] === "undefined" ? 1 : map[val] + 1);
    });

    let outgoingHeader = incomingHeader.map(function (val, index) {
      if (map[val] === 1) {
        if (val.includes("Co-borrower")) return val + " " + count[index];
        return val;
      } else {
        return val + " " + count[index];
      }
    });
    return outgoingHeader;
  }

  validateHeader() {
    return (
      this.xlData[0].indexOf("Client Reference") > -1 &&
      this.xlData[0].indexOf("Names") > -1 &&
      this.xlData[0].indexOf("Property Address") > -1 &&
      this.xlData[0].indexOf("State") > -1 &&
      this.xlData[0].indexOf("County") > -1 &&
      this.xlData[0].indexOf("Product Ordered") > -1 &&
      this.xlData[0].indexOf("Due Date") > -1
    );
  }

  fetchUploadProgress() {
    this.orderService.bulkProgress.subscribe((res) => {
      this.completed = Math.round((res.loaded / res.total) * 100 * 100) / 100;
    });
  }

  /////////////////////// Bulk Pricing upload code ////////////////////
  fetchPricingDataFromXL(evt: any) {
    this.headerdata = [];
    this.failedData = [];
    if (!this.checkIfMultiFiles(evt)) {
      console.log("onFileChange");
      this.isUpload = true;
      const scope = this;
      this.isvalidSheet = false;
      var fileList: FileList = evt.target.files;
      if (this.checksize(fileList)) {
        this.excelFile = fileList[0];
        if (this.excelFile.name) {
          this.isUploadClicked = false;
          this.logData = [];
        } else this.isUploadClicked = true;
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>evt.target;
        // if (target.files.length != 1) throw new Error("Cannot upload multiple files on the entry");
        const reader = new FileReader();
        console.log("reader", reader);
        reader.onload = function (e: any) {
          /* read workbook */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          console.log("wb", wb);
          scope.sheetData = wb;

          /* grab first sheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* save data to scope */
          var data: AOA = [["Excel Sheet"]];
          data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
          console.log("scope.data", data);
          scope.headerdata = [];
          if (scope.isValidHeaderRow(data[0])) {
            scope.refHeader = data[0];
            data[0].forEach((values) => {
              scope.getHeadersForDropDown(values);
            });
          } else {
            // give error message stating state (AND/OR) county missing
            scope.isvalidSheet = true;
          }
        };
        reader.readAsBinaryString(target.files[0]);
      } else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    } else {
      this.openErrorPopup("Cannot upload multiple files on the entry");
    }
  }

  fetchProductPricing(event) {
    this.getProductList();
    this.fetchPricingDataFromXL(event);
  }

  getHeadersForDropDown(values) {
    var curVal = values.trim().toUpperCase();
    if (curVal != STATE && curVal != COUNTY) {
      if (this.uploadType == 3) this.pushValuesToHeaderData(values);
      else {
        if (this.checkProdAvlOrNot(values)) this.pushValuesToHeaderData(values);
        else {
          var failedProducts: any = {};
          failedProducts.Product_Name = values;
          this.failedData.push(failedProducts);
        }
      }
    }
  }

  getProductList() {
    this.productList.getAllOrderProducts().subscribe((data) => {
      if (data) {
        this.products = data;
        var dontUpload: any = {};
        dontUpload.Product_ID = -1;
        dontUpload.Product_Description = "Do not upload";
        this.products.unshift(dontUpload);
      }
    });
  }

  pushValuesToHeaderData(values) {
    var rowHeader: any = {};
    rowHeader.colHeader = values;
    rowHeader.dbIndex = 0;
    this.headerdata.push(rowHeader);
  }

  checkProdAvlOrNot(data) {
    if (data) {
      let prodDescription = "";
      if (data.match("C/O Search")) prodDescription = "current owner search";
      else if (data.match("Deed Report /Update Report"))
        prodDescription = "update search";
      // else if(data.match("Two Owner Search")) prodDescription = "two owner search";
      else if (data.match("Full Search/Foreclosure/REO"))
        prodDescription = "full search";
      // else if(data.match("Copy Charges")) prodDescription = "copy charges";
      else prodDescription = data.toLowerCase();
      return this.products.some((productDet) => {
        if (productDet.Product_ID != -1) {
          let dbProductName = productDet.Product_Description.toLowerCase();
          if (dbProductName.match(prodDescription)) return true;
          else return false;
        }
      });
    }
  }

  isValidHeaderRow(row) {
    return (
      row.indexOf("STATE") > -1 && row.indexOf("COUNTY") > -1
      // && ((row.indexOf("C/O Search ") > -1) || (row.indexOf("C/O Search") > -1)) && (row.indexOf("Deed Report /Update Report") > -1)
      // && (row.indexOf("Two Owner Search") > -1) && (row.indexOf("Full Search/Foreclosure/REO") > -1)
      // && (row.indexOf("Copy Charges") > -1)
    );
  }

  showHeading() {
    return this.headerdata.length != 0;
  }

  resetHeaderData() {
    this.headerdata = [];
    this.failedData = [];
    this.isUpload = false;
    this.isvalidSheet = false;
    this.logData = [];
    this.isUploadClicked = false;
    this.excelFile = {};
  }

  checkHeaderData() {
    var flag = 0;
    this.headerdata.forEach((value) => {
      if (value.dbIndex != 0) {
        flag++;
      }
    });
    if (
      flag == this.headerdata.length &&
      this.checkDuplicateFile() &&
      !this.isvalidSheet
    )
      return false;
    else return true;
  }

  checkDuplicateFile() {
    var flag = true;
    var temp: string = "";
    for (var j = 0; j < this.headerdata.length - 1; j++) {
      temp = this.headerdata[j].dbIndex;
      for (var k = j + 1; k < this.headerdata.length; k++) {
        if (temp == this.headerdata[k].dbIndex) {
          if (temp != "-1") {
            flag = false;
            break;
          } else continue;
        } else {
          continue;
        }
      }
    }
    return flag;
  }

  ifNoFile() {
    if (this.excelFile && this.excelFile.name) return false;
    else return true;
  }

  getSelectedCustomer() {
    var matchedCustomer = this.customers.filter(
      (user) => user.User_ID == this.selectedCustomer
    );
    if (matchedCustomer.length > 0) return matchedCustomer[0];
    else return null;
  }

  getSelectedCustomerForCustomPrice() {
    var matchedCustomer = this.customPricingCustomers.filter(
      (user) => user.ID == this.selectedCustomerForCustomPrice
    );
    if (matchedCustomer.length > 0) return matchedCustomer[0];
    else return null;
  }

  uploadSheet() {
    this.isUploadClicked = true;
    this.isvalidSheet = false;
    if (this.uploadType == 3 || this.uploadType == 4) {
      var customerId = null;
      if (this.uploadType == 4)
        customerId = this.selectedCustomerForCustomPrice;
      this.headerdata.forEach((val) => {
        this.headerKeys[val.colHeader] = val.dbIndex;
      });
      this.priceService
        .sendExcelPriceSheetToDb(this.excelFile, this.headerKeys, customerId)
        .subscribe(
          (result) => {
            this.logData = result;
          },
          (err) => {
            if (err && err.code == "PTU12")
              this.openErrorPopup(
                "Upload got interrupted. Please refresh the page and retry again"
              );
            else this.openErrorPopup("Error while uploading pricing");
          }
        );
    } else {
      this.validateDataDepthDetails(this.dataDepthDetails);
    }
  }

  getTemplateText() {
    var str = "";
    if (this.uploadType && this.uploadType == 2) {
      str = "Download bulk order template";
    } else if (this.uploadType && this.uploadType == 3) {
      str = "Download product pricing template";
    } else if (this.uploadType && this.uploadType == 4) {
      str = "Download custom pricing template";
    } else if (this.uploadType && this.uploadType == 5) {
      str = "Download data depth coverage template";
    } else {
      str = "Download template";
    }
    return str;
  }

  downloadLogs() {
    if (this.logData) {
      var link = document.body.appendChild(document.createElement("a"));
      link.download = "Bulk_Upload_LogFile.txt";

      var list = document.getElementsByTagName("td");
      var newline = "";
      for (var i = 0; i < list.length; i++) {
        newline += list[i].innerHTML + "\r";
        newline = newline
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&nbsp;/g, "\t  ");
      }
      newline = encodeURIComponent(newline);
      link.href = "data:text/html," + newline;
      link.click();
    }
  }

  clearAll() {
    this.excelFile = {};
    this.fileName = "";
    this.isUploadClicked = true;
    this.logData = [];
    this.uploadType = "";
    this.clearData = true;
    this.selectedCustomer = "";
    this.isvalidSheet = false;
    this.selectedCustomerForCustomPrice = "";
  }

  getPlaceholder(row) {
    return "Product for Excel Column " + row.colHeader;
  }

  parseDataDepthData(evt: any) {
    this.headerdata = [];
    this.failedData = [];
    if (!this.checkIfMultiFiles(evt)) {
      this.isUpload = true;
      const scope = this;
      this.isvalidSheet = false;
      var fileList: FileList = evt.target.files;
      if (this.checksize(fileList)) {
        this.excelFile = fileList[0];
        if (this.excelFile.name) {
          this.isUploadClicked = false;
          this.logData = [];
        } else this.isUploadClicked = true;
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>evt.target;
        const reader = new FileReader();
        var thisComponent = this;
        reader.onload = function (e: any) {
          /* read workbook */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary", cellDates: true });
          scope.sheetData = wb;
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          var data: AOA = [["Excel Sheet"]];
          data = thisComponent.pruneArray(
            thisComponent.convertDatesToUTC(
              <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 })
            )
          );
          if (data.length > 1) {
            if (scope.isValidDataDepthHeaderRow(data[0]))
              thisComponent.dataDepthDetails = data;
            else scope.isvalidSheet = true;
          } else scope.isvalidSheet = true;
        };
        reader.readAsBinaryString(target.files[0]);
      } else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    } else {
      this.openErrorPopup("Cannot upload multiple files on the entry");
    }
  }

  isValidDataDepthHeaderRow(row) {
    return row.indexOf("State") > -1 && row.indexOf("County") > -1;
  }

  validateDataDepthDetails(data) {
    if (data) {
      this.countiesService.validateBulkUpldData(data).subscribe(() => {
        this.router.navigate([`data-depth-upload-review`]);
      });
    }
  }

  convertDatesToUTC(json: any[][]): any[][] {
    return json.map((row) =>
      row.map((cell) => {
        if (cell instanceof Date) {
          return new Date(Date.UTC(cell.getFullYear(), cell.getMonth(), cell.getDate(), cell.getHours(), cell.getMinutes(), cell.getSeconds() + 10));
        }
        return cell;
      })
    );
  }
}
