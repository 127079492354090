import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-mat-menu-search',
  templateUrl: './mat-menu-search.component.html',
  styleUrls: ['./mat-menu-search.component.scss']
})
export class MatMenuSearchComponent implements OnInit {
  searchItemText: string = '';
  @Input() entityList: Array<any>;
  @Input() searchKey: any;
  @Input() displayKey: any;
  @Input() placeHolder: any;
  @Output() selectedItem = new EventEmitter<any>();
  @ViewChild('searchInput') focusedElement: ElementRef;


  constructor() { }

  ngOnInit() {
  }

  emitSelected(item) {
    this.selectedItem.emit(item);
  }

  clearSearchTextinChild() {
    this.searchItemText = '';
  }

  focusOnInputField() {
    this.focusedElement.nativeElement.focus();
  }

}
