import { Component, OnInit , Output, EventEmitter, OnDestroy} from '@angular/core';
import { AuthService, UsermessageService, ConfigService, NotificationsService } from '../../services';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../app.constants'
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() openDrawer = new EventEmitter<boolean>();  
  private ngUnsubscribe = new Subject();
  networkStatus = true;
  constructor(
    public auth: AuthService,
    private router: Router,
    private userMsg: UsermessageService,
    private config: ConfigService,
    public notificationSevice: NotificationsService
  ) { }

  ngOnInit() {
    this.auth.isServerReachable$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((stat) => this.networkStatus = stat);
    this.networkStatus = true;
  }


  goToChangePassword() {
    this.auth.getUserId();
    this.router.navigate(['user/change-password/' + this.auth.getUserId()]);
  }
  goToProfile() {
    this.auth.getUserId();
    this.router.navigate(['user/' + this.auth.getUserId() + '/profile']);
  }

  logout() {
    this.auth.logout();
    // sessionStorage.clear();
    this.router.navigate(['/']);
  }

  checkTagListURL() {
    if (this.router.url.match('/tags/list')) return true
    else return false
  }

  showStatusMsg() {
    if (this.userMsg.getSuccessMsgData()) return this.userMsg.getSuccessMsgData()
    else return false;
  }

  showErrorStatusMsg() {
    if (this.userMsg.getErrorMsgData()) return this.userMsg.getErrorMsgData()
    else return false;
  }

  showQueueStatusMsg() {
    if (this.userMsg.getQueueMsgData()) return this.userMsg.getQueueMsgData()
    else return false;
  }

  clearSuccessMsg() {
    return this.userMsg.setSuccessMsgNull();
  }

  clearErrorMsg() {
    return this.userMsg.setErrorMsgNull();
  }

  clearQueueMsg(){
    return this.userMsg.setQueueMsgNull();
  }

  checkSuccessMsgData() {
    return this.userMsg.getSuccessMsgData()
  }

  checkErrorMsgData () {
    return this.userMsg.getErrorMsgData()
  }

  checkQueueMsgData () {
    return this.userMsg.getQueueMsgData()
  }

  checkSidebarStyle() {
    return this.config.getMySidebar()
  }

   getHeaderStyle() {
      return {
        'margin-left' : (this.checkSidebarStyle() && Boolean(this.config.getSideBarNode())) ? '250px' : '0px',
        'transition' : "margin-left .5s"
      }
  }

  showNotifications(event){
    event.stopPropagation();
    this.openDrawer.emit(true);
  }

  ngOnDestroy(){   
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}