import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReactiveFormsModule, FormsModule, UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AuthService, EmailValidator, NewOrderService, StateService, OrganizationService, ConfigService, NotificationsService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { CONSTANTS } from 'app/app.constants';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationSnackbarComponent } from 'app/common/notification-snackbar/notification-snackbar.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('emailBtn') focusedElement: ElementRef;

  loginFrm: UntypedFormGroup;
  error: string = '';
  errorMsg: any = {};
  returnUrl = '';
  loginModel: any = {};
  timer: any = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private frmBuilder: UntypedFormBuilder,
    private auth: AuthService,
    private config: ConfigService,
    private newOrderService: NewOrderService,
    private stateService: StateService,
    private matDialog:MatDialog,
    private orgService: OrganizationService,
    private notificationService: NotificationsService
  ) {
    this.loginFrm = frmBuilder.group({
      'Email_Address': [null, Validators.compose([Validators.required,Validators.maxLength(255)])],
      'User_Password': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
    })
    this.loginFrm.valueChanges.subscribe(data => this.error = '');
  }

  ngOnInit() {
    // this.auth.serverHealthCheck()
    // .subscribe((res)=>{
    //   if(res.statusText == 'OK'){
        if(this.auth.isLoggedIn()){
          this.router.navigate(['/']);
        }else{
        this.config.setSideBarNode(0);
        }
        this.newOrderService.resetAll();
        this.stateService.resetAll();   
        this.notificationService.resetNotificationsAndCount();
        if (this.route.snapshot.queryParams['returnUrl']) {
          this.returnUrl = this.route.snapshot.queryParams['returnUrl']
        }
        else this.returnUrl = '/orders/new';
        if(this.focusedElement !== undefined)
          this.focusedElement.nativeElement.focus(); 
        if(this.auth.getSnackRef()) {
          this.auth.getSnackRef().dismiss();
        };
    //   } else this.router.navigate(['/maintenance'])
    // })
  
  }

  login() {
    this.auth.login(this.loginModel)
      .subscribe(
      (response) => {
        if (!response['token'] && response['User_ID']) this.router.navigate(['termsAndConditions/' + response['User_ID']]);
        else {
          this.orgService.getAssociatedOrgs(response['user']['User_ID']).subscribe();
          if(response['user'] && (response['user']['User_Role'] == CONSTANTS.userRole.searchMaster)) this.router.navigate(['/orders/inprocess']);
          else this.router.navigate([this.returnUrl]);
        }
      },
      (error) => {
        this.errorMsg = error;
      }
      );
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0))      
  }
  changeModelVal(modelVal) {
    this.errorMsg = {};
  }
  // TODO: Remove this when we're done
  get diagnostic() { return JSON.stringify(this.loginModel); }
  display() {
    // console.log(this.loginFrm.controls['Email_Address']);
  }

  goToForget(userEmail) {
    if (userEmail != undefined) {
      this.auth.setEmailIdForForgetPwd(userEmail);
    }
    this.router.navigate(['/forget']);
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
}
