import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule, FormsModule, UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { ProgressBarService, AuthService, NewOrderService, OrdersService, UsersService, PricingService, ConfigService, SchedulingService, UsermessageService } from '../../services';
import { AutogrowDirective } from '../../directives';
import { CONSTANTS } from '../../app.constants';
import { DialogService } from "ng2-bootstrap-modal";
import { Location, DatePipe } from '@angular/common';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import * as moment from 'moment';
import * as _ from 'underscore';
import * as momentTimeZone from 'moment-timezone';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { OrderChangeReasonComponent } from '../../dialog/order-change-reason/order-change-reason.component';
import { EtaChangeReasonComponent } from '../../dialog/eta-change-reason/eta-change-reason.component';
import { ChangeProductTypeComponent } from 'app/dialog/change-product-type/change-product-type.component';
import { environment } from 'environments/environment';
// import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';

import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridTooltipComponent } from '../../common/ag-grid-tooltip/ag-grid-tooltip.component';
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';



declare var google: any

@Component({
  selector: 'app-edit-order-details',
  templateUrl: './edit-order-details.component.html',
  styleUrls: ['./edit-order-details.component.scss'],
  providers: [DatePipe]
})
export class EditOrderDetailsComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  newOrderForm: UntypedFormGroup;
  newOrderModel: any = {};
  orderNotes: any = {}
  error: String = '';
  errors: any = {};
  states = [];
  selectedStates = [];
  selectedCounty=[];
  counties = [];
  getUSDateString = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
  minDate = new Date(this.getUSDateString);
  customers = [];
  fieldValidate: boolean = false;
  errorMsg = '';
  constants = CONSTANTS;
  input = "";
  loanInput = "";
  propStateAddr = '';
  propState = '';
  propCounty = ''
  propCity = '';
  propZipcode = '';
  checkAvlCounty: any;
  order_ETA_Time: string = "17:00";
  requested_Time :string = "17:00" ;
  isDueRangeErr: boolean = false;
  dueDateFrmObj: any;
  dueDateTimeObj: any;
  dueDate_Range: any;
  dueDateRangeOption: Number
  order_ETA_Date: any;
  order_Requested_Date: any;
  orderETAtime = new UntypedFormControl({ value: '', disabled: true }, [Validators.required]);
  orderReqtime = new UntypedFormControl({ value: '', disabled: true }, [Validators.required]);
  tempStates = [];
  refModel: any = {};
  refNotes: any = {};	
  timer: any = [];
  products: any = [];
  pricingDetailsObj : any = {};
  productUpdateFlag: boolean = false;
  holidayList: any;
  weekendHolidayName: String;
  copyOrderDetails: any;
  allOrderStatus :any ;
  allIndepdentCities:any;
  showCityInCounty: boolean = false;
  modules = [ClientSideRowModelModule, InfiniteRowModelModule];
  rowData = [];
  columnDefs: any;
  defaultColDef = { width: 250, sortable: true, resizable: true }
  domLayout = "autoHeight"
  editType = "fullRow";
  borrowerDetails: any = {};
  copyBorrowerDetails : any = {};
  isCompleted: boolean = false;
  gridApi: any;
  gridColumnApi: any;
  OrderETADate: boolean = false;
  validDueDate: boolean = false;
  getUSDate = new Date('01/02/1500').toLocaleString("en-US", { timeZone: "America/New_York" });
  min_Date = new Date(this.getUSDate);
  dataDepthYears:any;
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private progressServ: ProgressBarService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private auth: AuthService,
    private orderService: OrdersService,
    private route: ActivatedRoute,
    private matDialog:MatDialog,
    private userService: UsersService,
    private location: Location,
    private pricingService: PricingService,
    private _datePipe: DatePipe,
    private config: ConfigService,
    private holidayScheduling: SchedulingService,
    private userMsg:UsermessageService
  ) {
    this.newOrderForm = frmBuilder.group({
      Order_Co_Buyer: [null, Validators.compose([Validators.maxLength(512)])],
      Order_Buyer_SSN: [null, Validators.compose([Validators.maxLength(4), Validators.minLength(4)])],
      Order_Co_Buyer_SSN: [null, Validators.compose([Validators.maxLength(4), Validators.minLength(4)])],
      Order_Buyer: [null, Validators.compose([Validators.maxLength(512)])],
      Property_First_Name: [null, Validators.maxLength(512)],
      Property_Address_1: [null, Validators.compose([Validators.maxLength(127)])],
      Property_Address_2: [null, Validators.compose([Validators.maxLength(127)])],
      Property_City: [null, Validators.compose([Validators.maxLength(127)])],
      Property_State_Abbr: [null, Validators.compose([Validators.maxLength(2)])],
      Property_ZipCode: [null, Validators.compose([Validators.maxLength(5)])],
      Co_Borrower_Property_Address_1: [null, Validators.compose([Validators.maxLength(127)])],
      Co_Borrower_Property_State_Abbr: [null, Validators.compose([Validators.maxLength(2)])],
      Co_Borrower_Property_ZipCode: [null, Validators.compose([Validators.maxLength(5)])],
      Order_ETA: [{ disabled: false, value: '' }],
      Property_County: [null],
      Property_Order_Number: [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      Property_Order_Notes: [null],
      Order_Instructions: [null],
      Order_Seller: [null, Validators.compose([Validators.maxLength(512)])],
      Order_Co_Seller: [null, Validators.compose([Validators.maxLength(512)])],
      Order_Customer_File_Number: [null, Validators.compose([Validators.maxLength(127)])],
      Order_Purpose: [null, Validators.compose([Validators.maxLength(127)])],
      Order_Parcel: [null, Validators.compose([Validators.maxLength(255)])],
      Years_Required : [null, Validators.compose([Validators.maxLength(255)])],
      Order_Subdivision: [null, Validators.compose([Validators.maxLength(255)])], // PT-3598
      Order_Qualia_Internal_ID: [null, Validators.compose([Validators.maxLength(127)])],
      Order_Loan: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Mortgage_Amount: [null, Validators.compose([Validators.maxLength(14)])],
      Order_Mortgage_Date: [{ disabled: false, value: '' }],
      Order_Requested_Date: [{ disabled: false, value: '' }],
      Order_Book: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Page: [null, Validators.compose([Validators.maxLength(255)])],
      searchValue: [null],
      Order_Type:[null, Validators.required],
      Order_Product_Custom_Name: [null],
      Loan_ID: [null, Validators.compose([Validators.maxLength(50)])],
    })
    this.newOrderForm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    if(this.isCompAdmin()) this.executeOninit();
    else this.route.params.subscribe(params =>
    this.orderService.checkOrderTags(params['orderId'], this.auth.getUserId())
      .subscribe((status) => {
        if (!status.approved) {
            if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) this.router.navigate(['/orders/inprocess']);
            else this.router.navigate(['/orders/new']);
            this.openDenyPermission();
        } else this.executeOninit();
      }, (err) => {
        if (err.code) this.openErrorPopup(err.msg);
        if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) this.router.navigate(['/orders/inprocess']);
        else this.router.navigate(['/orders/new']);
      }))
  }

  executeOninit() {
     // if (this.checkIfNotExternalSM) this.newOrderForm.controls['Property_Order_Number'].clearValidators();
     this.getOrderDetails(this.route.snapshot.params['orderId']);
     this.getOrderNotes(this.route.snapshot.params['orderId']);
     this.getCountyForIndependCity();
     this.pricingService.getStateList().subscribe((states) => {
       if (states) {
         states.forEach((state) => this.tempStates.push({ State_Short_Name: state[0].toUpperCase() }));
         this.states = this.tempStates;
         this.selectedStates = this.tempStates;
       }
     });
     // this.getholidaylist();
     this.getholidaylistFromLocal();
     this.timer.push(setTimeout(() => {
       this.config.setSideBarNode(0);
       this.myFirstFocus.nativeElement.focus();
     }, 0));
     this.columnDefs = this.createBorrowerCol()
  }

  isCompAdmin() {
    let role = Number(this.auth.getUserRole());
    if(role == CONSTANTS.userRole.superAdmin || role == CONSTANTS.userRole.compAdmin) return true;
    else return false;
  }

  openDenyPermission() {
    this.matDialog.open(
      SuccessComponent,
      {data:{
        message: "You do not have sufficient permissions to view this order. Please get in touch with your team lead to obtain access to this order.",
        title: "Access denied"
      },
      ...this.config.getDialogOptions()}
    )
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getOrderDetails(orderId) {
    this.orderService.getOrderDetails(orderId)
      .subscribe((details) => {
        this.modifyEditFormForNameSearch(details);
        this.getAllOrderStatus(orderId)
        let customerId = details.Organization_ID ? details.Organization_ID : details.Customer_ID;
        this.getOrderProductsForClient(customerId, details)
        this.refModel = Object.assign({},details); 
        this.propStateAddr = details.Property_Address_1;
        this.propState = details.Property_State_Abbr;
        this.propCounty = details.Property_County;
        this.propCity = details.Property_City;
        this.propZipcode = details.Property_ZipCode;
        this.newOrderModel = details;
        this.newOrderModel.Property_Order_Number = details.File_ID;
        this.newOrderModel.Product_ID = details.Product_ID;
        this.getBaseProductPrice(this.newOrderModel.Product_ID, orderId);
        if (this.newOrderModel.Property_County) this.newOrderModel.Property_County = this.newOrderModel.Property_County.toUpperCase();
        // if (details.Order_Mortgage_Date) {
        //   this.newOrderModel.Order_Mortgage_Date = new Date(momentTimeZone.tz(this.newOrderModel.Order_Mortgage_Date, 'America/New_York').format('MM/DD/YYYY HH:mm'));
        // }
        if (details.Order_ETA) this.getAvlOrderETAnTime()
        if (details.Order_Requested_Date) this.getAvlOrderReqTime()
        // this.newOrderModel.Order_ETA = new Date(momentTimeZone.tz(this.newOrderModel.Order_ETA, 'America/New_York').format('MM/DD/YYYY HH:mm'));
        // if (details.Order_Status == CONSTANTS.orderStatus.received || details.Order_Status == CONSTANTS.orderStatus.assigned) this.initReceivedOrderFormBuilder();
        // else this.initConfirmedOrderFormBuilder();
        this.loadDetailFields();
        if(details.Order_Status == CONSTANTS.orderStatus.completed || details.Order_Status == CONSTANTS.orderStatus.cancelled ) this.isCompleted = true;
        else this.isCompleted = false;
        this.getDataDepthInfo() 
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        // this.auth.logout();
        // this.router.navigate(['/login']);
      })
  }

  modifyEditFormForNameSearch(orderDetails){    
    if(orderDetails.Product_ID != environment.nameSearchProductId){
      this.setValidatorsNUpdate('Property_State_Abbr');
      this.setValidatorsNUpdate('Property_County');
    }
  }

  setValidatorsNUpdate(propertyName) {
		this.newOrderForm.controls[propertyName].clearValidators();
		this.newOrderForm.controls[propertyName].setValidators(Validators.required)
		this.newOrderForm.controls[propertyName].updateValueAndValidity();
	}

  isOrderDetailsChange() {
    if ((this.newOrderModel.Property_Order_Number != this.refModel.File_ID) || (this.newOrderModel.Property_First_Name != this.refModel.Property_First_Name) ||
      (this.newOrderModel.Property_Address_1 != this.refModel.Property_Address_1) || (this.newOrderModel.Property_Address_2 != this.refModel.Property_Address_2) ||
      (this.newOrderModel.Property_City != this.refModel.Property_City) || (this.newOrderModel.Property_State_Abbr != this.refModel.Property_State_Abbr) ||
       (this.newOrderModel.Property_ZipCode != this.refModel.Property_ZipCode) ||
      (this.newOrderModel.Co_Borrower_Property_Address_1 != this.refModel.Co_Borrower_Property_Address_1) ||
      (this.newOrderModel.Co_Borrower_Property_State_Abbr != this.refModel.Co_Borrower_Property_State_Abbr) ||
      (this.newOrderModel.Co_Borrower_Property_ZipCode != this.refModel.Co_Borrower_Property_ZipCode) ||
      (this.orderNotes.Property_Order_Notes != this.refNotes.Search_Ins) || (this.orderNotes.Order_Instructions != this.refNotes.Special_Ins) ||
      (this.newOrderModel.Order_Buyer != this.refModel.Order_Buyer) || (this.newOrderModel.Order_Co_Buyer != this.refModel.Order_Co_Buyer) ||
      (this.newOrderModel.Borrower_SSN != this.refModel.Borrower_SSN) || (this.newOrderModel.Co_Borrower_SSN != this.refModel.Co_Borrower_SSN) ||  (this.newOrderModel.Loan_ID != this.refModel.Loan_ID) ||
      (this.newOrderModel.Order_Seller != this.refModel.Order_Seller) || (this.newOrderModel.Order_Co_Seller != this.refModel.Order_Co_Seller) ||
      (this.newOrderModel.Order_Parcel != this.refModel.Order_Parcel) || (this.newOrderModel.Order_Subdivision != this.refModel.Order_Subdivision)|| (this.newOrderModel.Order_Qualia_Internal_ID != this.refModel.Order_Qualia_Internal_ID) || (this.newOrderModel.Order_Loan != this.refModel.Order_Loan) ||
      (this.newOrderModel.Order_Book != this.refModel.Order_Book) || (this.newOrderModel.Order_Page != this.refModel.Order_Page) ||
      (this.newOrderModel.Order_Mortgage_Amount != this.refModel.Order_Mortgage_Amount)|| this.isMortgageChanged() || this.isRequestedDateChanged() || this.isCountyChanged()) return true;     
    else return false;
  }

  isCountyChanged() {
    if(this.newOrderModel.Property_County && this.refModel.Property_County) {
      if(this.newOrderModel.Property_County.toUpperCase() != this.refModel.Property_County.toUpperCase()) return true;
      else return false;
    } else {
      if(this.newOrderModel.Property_County != this.refModel.Property_County) return true;
      else return false;
    }
  }

  isProductChanged() {
    if(this.newOrderModel.Product_ID != this.refModel.Product_ID || this.newOrderModel.Product_Description != this.refModel.Product_Description) return true;
    else {
      this.productUpdateFlag = false;
      return false;
    }
  }

  isMortgageChanged() {   
    var oldDate;
    var newDate;
    // if(this.refModel.Order_Mortgage_Date) oldDate = moment(this.refModel.Order_Mortgage_Date).add(new Date().getTimezoneOffset()/60, 'hours').toISOString();
    // else oldDate = null;
    // if(this.newOrderModel.Order_Mortgage_Date_EST) newDate = moment(this.newOrderModel.Order_Mortgage_Date_EST).toISOString();
    // else newDate = null;
    // if(this.refModel.Order_Mortgage_Date) {
    //   let estShift = momentTimeZone.tz(this.refModel.Order_Mortgage_Date, 'America/New_York').isDST() ? 4 : 5;
    //   oldDate = momentTimeZone.tz(this.refModel.Order_Mortgage_Date,'America/New_York').add(estShift, 'hours').format('MM-DD-YYYYTHH:mm');
    // } else oldDate = null;
    // if(this.newOrderModel.Order_Mortgage_Date_EST) newDate = momentTimeZone.tz(this.newOrderModel.Order_Mortgage_Date_EST, 'America/New_York').format('MM-DD-YYYYTHH:mm');
    // else newDate = null;
    // if(oldDate != newDate) return true;
    // else return false;
    if(this.refModel.Order_Mortgage_Date) {
      oldDate = this.refModel.Order_Mortgage_Date;
    } else oldDate = null;
    if(this.newOrderModel.Order_Mortgage_Date_EST) newDate = this.newOrderModel.Order_Mortgage_Date_EST;
    else newDate = null;
    if(oldDate != newDate) return true;
    else return false;
  }

  isRequestedDateChanged() {   
    var oldDate;
    var newDate;
    if(this.refModel.Order_Requested_Date) {
      let estShift = momentTimeZone.tz(this.refModel.Order_Requested_Date, 'America/New_York').isDST() ? 4 : 5;
      oldDate = momentTimeZone.tz(this.refModel.Order_Requested_Date,'America/New_York').add(estShift, 'hours').format('MM-DD-YYYY');
    } else oldDate = null;
    if(this.newOrderModel.Order_Requested_Date_EST) newDate = momentTimeZone.tz(this.newOrderModel.Order_Requested_Date_EST, 'America/New_York').format('MM-DD-YYYY');
    else newDate = null;
    if(oldDate != newDate) return true;
    else return false;
  }

  isETAChanged() {   
    var oldETA;
    var newETA;
    // if(this.refModel.Order_ETA) oldETA = moment(this.refModel.Order_ETA).add(new Date().getTimezoneOffset()/60, 'hours').toISOString();
    // else oldETA = null;
    // if(this.newOrderModel.Order_ETA_EST) newETA = moment(this.newOrderModel.Order_ETA_EST).toISOString();
    // else newETA = null;
    if(this.refModel.Order_ETA) {
      let estShift = momentTimeZone.tz(this.refModel.Order_ETA, 'America/New_York').isDST() ? 4 : 5;
      oldETA = momentTimeZone.tz(this.refModel.Order_ETA,'America/New_York').add(estShift, 'hours').format('MM-DD-YYYYTHH:mm'); //UTC =>EST
    } else oldETA = null;
    if(this.newOrderModel.Order_ETA_EST) {
      newETA = momentTimeZone.tz(this.newOrderModel.Order_ETA_EST, 'America/New_York').format('MM-DD-YYYYTHH:mm'); //EST
    }
    else newETA = null;
    if(oldETA != newETA) return true;
    else return false;
  }

  getAvlOrderETAnTime() {
    let etaFullDate = new Date(momentTimeZone.tz(this.newOrderModel.Order_ETA, 'America/New_York').format('MM/DD/YYYY HH:mm'));
    let date = new Date(etaFullDate.toDateString());
    this.newOrderModel.Order_ETA = date;
    let etaHours = etaFullDate.getHours().toString();
    let etaMinutes = etaFullDate.getMinutes().toString();
    if (etaHours.length == 1) etaHours = "0" + etaHours;
    if (etaMinutes.length == 1) etaMinutes = "0" + etaMinutes;
    this.order_ETA_Time = etaHours + ":" + etaMinutes;
    if (this.newOrderModel.Order_ETA) this.orderETAtime.enable();
  }

  getAvlOrderReqTime() {
    let etaFullDate = new Date(momentTimeZone.tz(this.newOrderModel.Order_Requested_Date, 'America/New_York').format('MM/DD/YYYY HH:mm'));
    let date = new Date(etaFullDate.toDateString());
    this.newOrderModel.Order_Requested_Date = date;
    let etaHours = etaFullDate.getHours().toString();
    let etaMinutes = etaFullDate.getMinutes().toString();
    if (etaHours.length == 1) etaHours = "0" + etaHours;
    if (etaMinutes.length == 1) etaMinutes = "0" + etaMinutes;
    this.requested_Time = etaHours + ":" + etaMinutes;
    if (this.newOrderModel.Order_Requested_Date) this.orderReqtime.enable();
  }

  checkOrderETATime() {
    if (this.order_ETA_Time) return false;
    else return true;
  }

  setOrderETATime(orderETA) {
    if(orderETA && orderETA._d){
    let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
    let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
    return orderETA._d.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
  }else if(orderETA && !orderETA._d){
    let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
    let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
    return orderETA.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
  }else return null;
  }

  setOrderRequestDate(RequestDate){
    if(RequestDate && RequestDate._d){
    let getOrderEtaHours = this.requested_Time.split(":")[0];
    let getOrderEtaMinutes = this.requested_Time.split(":")[1];
    return RequestDate._d.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
  }else if(RequestDate && !RequestDate._d){
    let getOrderEtaHours = this.requested_Time.split(":")[0];
    let getOrderEtaMinutes = this.requested_Time.split(":")[1];
    return RequestDate.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
  }else return null;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  initReceivedOrderFormBuilder() {
    this.newOrderForm.controls['Order_ETA'].setValidators([Validators.compose([Validators.maxLength(127)])])
    this.newOrderForm.controls['Order_ETA'].updateValueAndValidity();

  }

  initConfirmedOrderFormBuilder() {
    this.newOrderForm.controls['Order_ETA'].setValidators([Validators.compose([Validators.required, Validators.maxLength(127)])])
    this.newOrderForm.controls['Order_ETA'].updateValueAndValidity();
  }

  getOrderNotes(orderId) {
    this.orderService.getOrderNotes(orderId)
      .subscribe((notes) => {
        notes.forEach(note => {
          if (note.Instruction_Type == CONSTANTS.instructionType.orderNotes) {
            this.refNotes.Search_Ins = note.Instruction_Text;
            this.orderNotes.Property_Order_Notes = note.Instruction_Text;
          }
          if (note.Instruction_Type == CONSTANTS.instructionType.orderIns) {
            this.refNotes.Special_Ins = note.Instruction_Text;
            this.orderNotes.Order_Instructions = note.Instruction_Text;
          }
        })
        this.loadTextFields();
      }, (err) => {
        console.log(err);
      })
  }


  ngAfterViewInit() {
    let searchBox: any = document.getElementById('search-box');
    let options = {
      types: [
        // return only geocoding results, rather than business results.
        'geocode',
      ]
    };
    var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
    console.log('searchBox', searchBox);
    // Add listener to the place changed event
    autocomplete.addListener('place_changed', () => {
      this.getPlaceDetails(autocomplete.getPlace());

    });
  }

  getPlaceDetails(place: any) {
    this.newOrderModel.searchValue = '';
    console.log("place: ", place);
    console.log("place.geometry: ", place.geometry);
    if (place.geometry == undefined) {
      //set an error - show the error under google search line
      // a custom validator need to be defined and an error message "Place not found" need to be shown

      //console.log("noBookmarkError:",this.noBookmarkError);      
      this.newOrderModel.Property_Address_1 = '';
      this.newOrderModel.Property_City = '';
      this.newOrderModel.Property_State_Abbr = '';
      this.newOrderModel.Property_ZipCode = '';
      // this.newOrderModel.Property_County = '';
      this.checkAvlCounty = '';
      document.getElementById('search-box').parentElement.classList.add('is-empty');
      document.getElementById('Property_City').parentElement.classList.add('is-empty');
      document.getElementById('Property_State_Abbr').parentElement.classList.add('is-empty');
      document.getElementById('Property_ZipCode').parentElement.classList.add('is-empty');
      //this.searchError = true;
      return;
    } else {
      //this.searchError = false;
      this.newOrderModel.Property_Latitude = place.geometry.location.lat();
      this.newOrderModel.Property_Longitude = place.geometry.location.lng();

      this.newOrderModel.Property_Address_1 = '';
      this.newOrderModel.Property_City = '';
      this.newOrderModel.Property_State_Abbr = '';
      this.newOrderModel.Property_ZipCode = '';
      // this.newOrderModel.Property_County = '';
      this.checkAvlCounty = '';
      // for (let i = 0; i < place.address_components.length; i++) {
      for (let i = place.address_components.length - 1; i >= 0; i--) {
        switch (place.address_components[i].types[0]) {
          case 'street_number': this.newOrderModel.Property_Address_1 = place.address_components[i].long_name + ' ' + this.newOrderModel.Property_Address_1;
            document.getElementById('Property_Address_1').parentElement.classList.remove('is-empty');
            break;
          case 'route': this.newOrderModel.Property_Address_1 = place.address_components[i].long_name;
            document.getElementById('Property_Address_1').parentElement.classList.remove('is-empty');
            break;
          case 'sublocality_level_1': if (this.newOrderModel.Property_City.length == 0) this.newOrderModel.Property_City = place.address_components[i].long_name;
            //document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'sublocality': if (this.newOrderModel.Property_City.length == 0) this.newOrderModel.Property_City = place.address_components[i].long_name;
            //document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'locality': if (this.newOrderModel.Property_City.length == 0) this.newOrderModel.Property_City = place.address_components[i].long_name;
            //document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'administrative_area_level_2': {
            if (this.checkSaint(place.address_components[i].long_name)) this.checkCountyNReplace(place, i)
            else this.checkCountyNReplace(place, i)
            this.checkAvlCounty = place.address_components[i].long_name.toUpperCase();
            this.checkCountyAvl()
            break;
          }
          case 'administrative_area_level_1':
            if (this.checkIfState(place.address_components[i].short_name)) {
              this.newOrderModel.Property_State_Abbr = place.address_components[i].short_name;
              if (this.newOrderModel.Property_State_Abbr == 'DC') {
                this.checkAvlCounty = "WASHINGTON"
                this.checkCountyAvl();
              }
              //document.getElementById('Property_State_Abbr').parentElement.classList.remove('is-empty');
            }
            break;
          case 'postal_code': this.newOrderModel.Property_ZipCode = place.address_components[i].short_name;
            //document.getElementById('Property_ZipCode').parentElement.classList.remove('is-empty');
            break;
          default: break;
        }
      }
      this.ref.detectChanges();
    if(this.checkIfState(this.newOrderModel.Property_State_Abbr)){
      if(!this.checkAvlCounty){
        this.checkIfCityIsIndependent();
        this.checkCountyAvl();
        this.showCityInCounty = true;
    }else this.showCityInCounty = false;
    }else {
        this.newOrderModel.Property_State_Abbr = '';
        this.newOrderModel.Property_County = '';
        this.newOrderModel.Country = '';
      }
    }

  }

  checkSaint(county) {
    //console.log("saint check",county);
    var lowecaseCounties = CONSTANTS.saintArray.map((value) => value.toLowerCase())
    if (county.toLowerCase().startsWith('st.') && lowecaseCounties.indexOf(county.toLowerCase()) == -1) return true;
    else return false;
  }

  checkCounty(county) {
    //console.log("county check",county)
    var lowecaseCounties = CONSTANTS.countyArray.map((value) => value.toLowerCase())
    if (county.toLowerCase().endsWith(' county') && lowecaseCounties.indexOf(county.toLowerCase()) == -1) return true;
    else return false;
  }

  validateDateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors.Order_Mortgage_Date = true;
    else delete this.errors.Order_Mortgage_Date;
  }

  validateReqDateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy 
    if(event.value) this.orderReqtime.enable();
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors.Order_Requested_Date = true;
    else delete this.errors.Order_Requested_Date;
  }

  validateOrderETADateFn(event) {

    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy
    if (event.value) this.orderETAtime.enable();
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors.Order_ETA = true;
    else delete this.errors.Order_ETA;
  }

  getIfErrors() {
    if (Object.keys(this.errors).length > 0) {
      return true
    }
    else return false;
  }

  // validateForm() {
  //   this.errors = {};
  //   if (this.newOrderModel.Property_Order_Number == null)
  //     this.errors['Property_Order_Number'] = true;
  //   if (!this.newOrderModel.Customer_ID)
  //     this.errors['Customer_ID'] = true;
  //   if (this.newOrderModel.Property_County && !this.newOrderModel.Property_State_Abbr )
  //     this.errors['Property_State_Abbr'] = true;
  //   if (!this.newOrderModel.Property_County  && this.newOrderModel.Property_State_Abbr)
  //     this.errors['Property_County'] = true;
  //   if (this.newOrderModel.Order_Product_Custom_Name && this.newOrderModel.Order_Product_Custom_Name == null)
  //     this.errors["Order_Product_Custom_Name"] = true;
  //   if ((this.newOrderModel.Order_Buyer ||
  //     (this.newOrderModel.Property_County && this.newOrderModel.Property_State_Abbr) ||
  //     this.newOrderModel.Order_Seller ||
  //     this.newOrderModel.Property_ZipCode) && Object.keys(this.errors).length == 0) {
  //     console.log("All are ok..");
  //     this.editOrder();      
  //   }
  //   else {
  //     console.log("error keys", Object.keys(this.errors))
  //     this.errors['Minimun_Mandatory_Fields'] = true;
  //   }
  //   console.log("errors", this.errors)
  // } 

  getCountyList(event) {
    this.clearForms();
    if (event) {
      this.pricingService.getCountyList(this.newOrderModel.Property_State_Abbr).subscribe((counties) => {
        var tempCounties = [];
        counties.forEach((county) => tempCounties.push({ County_Name: county.County_Name.toUpperCase() }));
        if(!this.showCityInCounty) {
          let countyIndex = counties.findIndex((county) => {
            if(county.County_Name.toUpperCase() === this.newOrderModel.Property_County) return true;
            else return false;
          })
          if(countyIndex === -1 && this.newOrderModel.Property_County) tempCounties.push({ County_Name: this.newOrderModel.Property_County.toUpperCase() }); 
        }
         this.counties = tempCounties;
        this.ngAfterViewInit();
        this.ref.detectChanges();
      });
    }
  }

  checkCountyAvl() {
    let avlCountyName = [];
    this.counties.forEach((county) => avlCountyName.push(county.County_Name.toUpperCase()))
    if (avlCountyName.indexOf(this.checkAvlCounty)) this.newOrderModel.Property_County = this.checkAvlCounty
    if(this.newOrderModel.Product_ID != this.refModel.Product_ID) this.getProductPricing(this.newOrderModel.Product_ID)
  }

  detectManualChange() {
    this.newOrderModel.Property_County = null;
    this.newOrderModel.Property_ZipCode = null;
    }

  detectManualChangeZipcode() {
    this.newOrderModel.Property_ZipCode = null;
    this.getProductPricing(this.newOrderModel.Product_ID);
  }

  editOrder() {
    if (this.isDueRangeErr) {
      this.order_ETA_Time = "17:00";
      this.requested_Time = "17:00";
      if(this.newOrderModel.Order_ETA) this.newOrderModel.Order_ETA = null;
      if(this.order_ETA_Date) this.order_ETA_Date = null;
      if(this.dueDate_Range){
        this.order_ETA_Date = this.setOrderETATime(this.dueDate_Range);
        var estShift = momentTimeZone.tz(this.order_ETA_Date, 'America/New_York').isDST() ? 4 : 5;
        var etaInEstTimezone = moment(this.order_ETA_Date).utcOffset(moment(this.order_ETA_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
        this.newOrderModel.Order_ETA_EST = etaInEstTimezone;
      }else if(this.newOrderModel.Order_ETA_Range) this.newOrderModel.Order_ETA_Range = null;
    }else {
      if(this.newOrderModel.Order_ETA_Range) this.newOrderModel.Order_ETA_Range = null;
      if(this.order_ETA_Date) this.order_ETA_Date = null;
      if (this.newOrderModel.Order_ETA) {
      this.order_ETA_Date = this.setOrderETATime(this.newOrderModel.Order_ETA);
      var estShift = momentTimeZone.tz(this.order_ETA_Date, 'America/New_York').isDST() ? 4 : 5;
      var etaInEstTimezone = moment(this.order_ETA_Date).utcOffset(moment(this.order_ETA_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
      this.newOrderModel.Order_ETA_EST = etaInEstTimezone;
      }else{
        if(this.newOrderModel.Order_Status < CONSTANTS.orderStatus.confirmed) this.newOrderModel.Order_ETA_EST=this.newOrderModel.Order_ETA;
      }
    }
    if (this.newOrderModel.Order_Mortgage_Date) {
      // console.log("endtered in the case of order_mortgage_Date");
      // var estShift = momentTimeZone.tz(this.newOrderModel.Order_Mortgage_Date, 'America/New_York').isDST() ? 4 : 5;
      // var etaInEstTimezone = moment(this.newOrderModel.Order_Mortgage_Date).utcOffset(moment(this.newOrderModel.Order_Mortgage_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
      // // var etaInEstTimezone = moment(this.newOrderModel.Order_Mortgage_Date).utcOffset(moment(this.newOrderModel.Order_Mortgage_Date).utcOffset()).add(estShift, 'hours').add(17, 'hours').format('YYYY-MM-DDTHH:mm');
      // this.newOrderModel.Order_Mortgage_Date_EST = etaInEstTimezone;
      this.newOrderModel.Order_Mortgage_Date_EST = this.newOrderModel.Order_Mortgage_Date;
    }
    if(this.newOrderModel.Order_Requested_Date) {
      // console.log("endtered in the case of order_Requested_Date");
     this.order_Requested_Date =  this.setOrderRequestDate(this.newOrderModel.Order_Requested_Date)
     var estShift = momentTimeZone.tz(this.order_Requested_Date, 'America/New_York').isDST() ? 4 : 5;
      var etaInEstTimezone = moment(this.order_Requested_Date).utcOffset(moment(this.order_Requested_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
      this.newOrderModel.Order_Requested_Date_EST = etaInEstTimezone;
    }
    var data = this.newOrderModel;
    let result = this.checkStateChanges(data);
    if (this.newOrderModel.Abstrator_ID) {
      // console.log("endtered in the case of asbtrator id not null");
      if (!result) {
        // console.log("endtered in the case status change false");
        let disposable = this.matDialog.open(ConfirmComponent, {data:{
          title: 'Alert',
          message: 'Abstractor is assigned for previous state and county. If you continue with address change then you might need to change the abstractor too.'
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) this.creatingAddress(data,this.order_Requested_Date);
            else this.retainAddrs();
          })
      } else {
        // console.log("endtered in the case status change true");
        this.creatingAddress(data,this.order_Requested_Date);}
    } else {
      // console.log("endtered in the case of asbtrator id null");
      this.creatingAddress(data,this.order_Requested_Date);}
  }

  creatingAddress(data,finalReqDate) {
    data.Property_Order_Notes = this.orderNotes.Property_Order_Notes
    data.Order_Instructions = this.orderNotes.Order_Instructions;
    if (data.Property_City && data.Property_City.trim()) data.Property_City = data.Property_City.trim();  
    if(this.isOrderDetailsChange()) {
      let disposable = this.matDialog.open(OrderChangeReasonComponent, {data:{
        title: 'Send Message',
        msgSendType: CONSTANTS.messageSendStatus.orderMessage
      }, ...{ closeByClickingOutside: false }}).afterClosed()
        .subscribe((res) => {
          if (res) {
           if(res.state) data.ordDetChangeReason = res.Message_Text;
           if(this.isProductChanged()) this.showProductChangedPopup(data,finalReqDate);
          else this.checkForSelectedETA(data,finalReqDate);
          }
        });
    } else { 
      data.ordDetChangeReason = '';
      if(this.isProductChanged()) this.showProductChangedPopup(data,finalReqDate);
      else this.checkForSelectedETA(data,finalReqDate);
    }
  }

  showProductChangedPopup(data,finalReqDate) {
      // let newProductIndex = this.products.findIndex((product) => product.Product_ID === this.newOrderModel.Product_ID)
      let disposable = this.matDialog.open(ChangeProductTypeComponent, {data:{
        title: 'Confirm Product Type Change',
        oldProduct:this.refModel.Product_Description,
        newProduct:this.newOrderModel.Product_Description,
        pricingObj:this.pricingDetailsObj
      }}).afterClosed()
      .subscribe((res) => {
        if(res){
          if(res.updatePriceFlag) {
            data.Updated_Product_Price = res.pricingData.New_Price;
            data.Product_Base_Price = res.pricingData.Base_Price;
            data.updatePriceFlag = res.updatePriceFlag;
          }else data.updatePriceFlag = res.updatePriceFlag;
          this.productUpdateFlag = true;
          this.checkForSelectedETA(data,finalReqDate)
        }
      })
  }

  checkForETAChanged(data) {
    if(this.isETAChanged() && (data.Order_Status >= CONSTANTS.orderStatus.confirmed || this.checkIfOrderConfirmed())) this.openETAChangeMsgInterface(data);
    else {
      data.orderETAChangeReason = '';
      data.Order_ETA_Change_Reason_Type_ID = ''
      this.editOrderDetails(data);
    }
  }

  openETAChangeMsgInterface(data) {
    let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if(res.state) { 
          data.orderETAChangeReason = res.Message_Text;
          data.Order_ETA_Change_Reason_Type_ID = res.Order_ETA_Change_Reason_Type_ID;
          this.editOrderDetails(data); 
        }
      });
  }


  editOrderDetails(data) {
    if(this.newOrderModel.Order_Mortgage_Date) {
      this.newOrderForm.controls['Order_Mortgage_Date'].setValidators([Validators.compose([Validators.nullValidator])])
    this.newOrderForm.controls['Order_Mortgage_Date'].updateValueAndValidity();
    }
    if(this.newOrderModel.Order_ETA){
      this.newOrderForm.controls['Order_ETA'].setValidators([Validators.compose([Validators.nullValidator])])
    this.newOrderForm.controls['Order_ETA'].updateValueAndValidity();
    } 
    if(data.Product_ID == 999) {
      if(!(this.newOrderModel.Order_Product_Custom_Name === this.refModel.Order_Product_Custom_Name)) data.updateProductFlag = true;
    }
    else data.updateProductFlag = this.productUpdateFlag;
    data.independent_City = this.showCityInCounty;
    this.orderService.editOrder(data, this.auth.getUserId())
    .subscribe((res) => {
      if (res) {
        this.router.navigate(['/orders/' + this.route.snapshot.params['orderId'] + '/view']);
        //this.location.back();          
      }
    }, (err) => {
      console.log("Error:", err);
      this.errorMsg = err;
      console.log("this.errorMsg:", this.errorMsg);
    })
  }

  checkForSelectedETA(data,finalReqDate){
    if(this.isETAChanged()){
      let orderETADate = moment(data.Order_ETA).format("YYYY-MM-DD");
      let checkETAIsHoliday = this.holidayList.findIndex((holiday) => {
        if(holiday && moment(holiday.Holiday_Date, "YYYY-MM-DD").isSame(orderETADate)) return true;
        else return false
      })
     
      if(checkETAIsHoliday > -1 ) this.showWarningPopup(this.holidayList[checkETAIsHoliday].Holiday_Name, data);
      else if(this.checkETALieOnWeekend(data.Order_ETA)) this.showWarningPopup(this.weekendHolidayName, data)
      else this.checkForETAChanged(data);
    }else this.checkForETAChanged(data);
  }

  showWarningPopup(holidayName, data){
    let disposable = this.matDialog.open(ConfirmComponent, {data:{
      title:'ETA Note',
      message:"The ETA on this order is a US holiday ("+holidayName+"). Would you like to continue?"
    }, ...this.config.getDialogOptions()}).afterClosed()
    .subscribe((res) => { 
      if(res) this.checkForETAChanged(data);
    });
  }

  checkETALieOnWeekend(orderETA) {
    if(moment(orderETA).isoWeekday() == 7){ // iso Weekday for sunday is 7
      this.weekendHolidayName = "Sunday";
      return true;
    }
    else if(moment(orderETA).isoWeekday() == 6){ // iso Weekday for saturday is 6
      this.weekendHolidayName = "Saturday";
      return true;
    }else return false;
  }

  retainAddrs() {
    this.newOrderModel.Property_Address_1 = this.propStateAddr;
    this.newOrderModel.Property_State_Abbr = this.propState;
    this.newOrderModel.Property_County = this.propCounty;
    this.newOrderModel.Property_ZipCode = this.propZipcode;
    this.newOrderModel.Property_City = this.propCity;
  }

  checkStateChanges(data) {
    if(this.propState && this.propCounty){
      var propState = data.Property_State_Abbr;
      var propCounty = data.Property_County.toUpperCase();
  if (((propState.localeCompare(this.propState)) || (propCounty.localeCompare(this.propCounty.toUpperCase()))) == 0) return true;
      else return false;
    }
    else return false;
  }

  clearForms() {
    this.errors = {};
  }

  focusFunction(event) {
    console.log("Focused", event.srcElement);
    event.srcElement.type = 'date'
  }

  focusOutFunction(event) {
    console.log("Out focused", event.srcElement)
    if (!event.srcElement.value) event.srcElement.type = 'text';
  }

  goBack() {
    // this.router.navigate(['/orders/' + this.route.snapshot.params['orderId'] + '/view']);
    this.location.back();
  }

  loadDetailFields() {
    if (this.checkIfSearchMaster() && this.checkIfNotExternalSM()) {
      if (this.newOrderModel.Property_Order_Number) document.getElementById('Property_Order_Number').parentElement.classList.remove('is-empty');
    }
    // if (this.newOrderModel.Order_ETA) document.getElementById('Order_ETA').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Order_Buyer) document.getElementById('Order_Buyer').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Order_Co_Buyer) document.getElementById('Order_Co_Buyer').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Order_Seller) document.getElementById('Order_Seller').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Order_Co_Seller) document.getElementById('Order_Co_Seller').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Order_Loan) document.getElementById('Order_Loan').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Order_Parcel) document.getElementById('Order_Parcel').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Order_Subdivision) document.getElementById('Order_Subdivision').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Order_Qualia_Internal_ID) document.getElementById('Order_Qualia_Internal_ID').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Order_Book) document.getElementById('Order_Book').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Order_Page) document.getElementById('Order_Page').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Property_Address_1) document.getElementById('Property_Address_1').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Property_City) document.getElementById('Property_City').parentElement.classList.remove('is-empty');
    //if (this.newOrderModel.Property_State_Abbr) document.getElementById('Property_State_Abbr').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Property_ZipCode) document.getElementById('Property_ZipCode').parentElement.classList.remove('is-empty');
    //if (this.newOrderModel.Property_County) document.getElementById('Property_County').parentElement.classList.remove('is-empty');
    if (this.newOrderModel.Order_Mortgage_Amount) document.getElementById('Order_Mortgage_Amount').parentElement.classList.remove('is-empty');
    if (this. newOrderModel && this.newOrderModel.Order_Mortgage_Date) document.getElementById('Order_Mortgage_Date').parentElement.classList.remove('is-empty');
  }

  loadTextFields() {
    if (this.orderNotes.Property_Order_Notes) {
      console.log("removing for notes")
      document.getElementById('Property_Order_Notes').parentElement.classList.remove('is-empty');
    }
    if (this.orderNotes.Order_Instructions) {
      document.getElementById('Property_Order_Instructions').parentElement.classList.remove('is-empty');
    }
  }

  checkIfState(state) {
    var states = _.flatten(this.tempStates);
    let stateBoolean;
    if(states) {
      stateBoolean = states.some((stateList) => {      
        if(stateList.State_Short_Name.includes(state)) return true;
        else return false
      })
    }   
    return stateBoolean;
  }

  getAmountFormat(e, editOrder) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((this.input.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.input.length > 0 || this.input.length < this.config.getLimitPriceMaxLength())) {
        this.input = this.input.slice(0, this.input.length - 1);
        e.target.value = this.numberFormat(this.input);
        if (e.target.value == '') editOrder.Order_Mortgage_Amount = null;
        else editOrder.Order_Mortgage_Amount = e.target.value;
      }
      else if (this.input.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.input = this.input + key;
          e.target.value = this.numberFormat(this.input);
          editOrder.Order_Mortgage_Amount = e.target.value;
        }
      }
      return false;
    }
  }


  getLoanFormat(e, editOrder) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((this.loanInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.loanInput.length > 0 || this.loanInput.length < this.config.getLimitPriceMaxLength())) {
        this.loanInput = this.loanInput.slice(0, this.loanInput.length - 1);
        e.target.value = this.numberFormat(this.loanInput);
        if (e.target.value == '') editOrder.Order_Loan = null;
        else editOrder.Order_Loan = e.target.value;
      }
      else if (this.loanInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.loanInput = this.loanInput + key;
          e.target.value = this.numberFormat(this.loanInput);
          editOrder.Order_Loan = e.target.value;
        }
      }
      return false;
    }
  }


  numberFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  checkStartDate(etaDate) {
    if (!etaDate) {
      let newDate = new Date(this.getUSDateString);
      if (newDate.getHours() > 17) {
        newDate.setDate(newDate.getDate() + 1)
        newDate.setHours(17);
        newDate.setMinutes(0);
      } else {
        newDate.setHours(17);
        newDate.setMinutes(0);
      }
      return newDate;
    }
  }

  checkIfNotExternalSM() {
    if(Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.externalSM) return false;
    else return true;
  }

  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return true;
    else return false;
  }

  getDueDateData(event) {
    this.dueDateRangeOption = Number(event.dueDateType);
    if (Number(event.dueDateType)) {
      if (event.dueDateFrmCtrl) {
        this.isDueRangeErr = true;
        this.dueDateFrmObj = event.dueDateFrmCtrl;
        if (this.dueDateFrmObj) {
          if (this.dueDateFrmObj.value.fromDayCtrl && this.dueDateFrmObj.value.toDayCtrl) {
            let getDueDateRange = this.dueDateFrmObj.value.fromDayCtrl + "-" + this.dueDateFrmObj.value.toDayCtrl;
            let addDays = momentTimeZone.tz('America/New_York').add(Number(this.dueDateFrmObj.value.toDayCtrl), 'days').format()
            let getDateObj =  this.Convert_UTCDate_To_LocalDate(addDays, 'America/New_York');
            this.dueDate_Range = getDateObj; 
            this.newOrderModel.Order_ETA_Range = getDueDateRange;
          } else if (this.newOrderModel.Order_Status >= CONSTANTS.orderStatus.received) {
            if (this.dueDateFrmObj.controls['fromDayCtrl'].touched && !(this.dueDateFrmObj.value.fromDayCtrl)) this.dueDateFrmObj.controls['fromDayCtrl'].setErrors({ 'fromDayRequired': true });
            if (this.dueDateFrmObj.controls['toDayCtrl'].touched && !(this.dueDateFrmObj.value.toDayCtrl)) this.dueDateFrmObj.controls['toDayCtrl'].setErrors({ 'toDayRequired': true });
          } else {
            this.dueDate_Range = null;
            this.newOrderModel.Order_ETA_Range = null;
          }
        }
      }
    } else {
      this.isDueRangeErr = false;
      if (event.dueDateTimeCtrl) {
        this.dueDateTimeObj = event.dueDateTimeCtrl;
        if (event.dueDateTimeCtrl.valid) this.checkForDueDateEST(event);
      }
      else this.checkForDueDateEST(event);
    }
  }


  Convert_UTCDate_To_LocalDate(utcTime: any, localTimeZone: string) {
    var utcMoment = momentTimeZone(utcTime);
    var localDateTime = utcMoment.tz(localTimeZone);
    return new Date(localDateTime.format('lll'));
  }

  checkForDueDateValid() {
    if (this.dueDateRangeOption) {
      if (this.dueDateFrmObj && this.dueDateFrmObj.valid) return false;
      else return true;
    } else {
      if (this.dueDateTimeObj) {
        if (this.dueDateTimeObj.valid) return false;
        else if (!this.dueDateTimeObj.valid) return true
      } else return false;
    }
  }

  checkForDueDateEST(event) {
    if(this.newOrderModel.Order_Status < CONSTANTS.orderStatus.confirmed)  {
      if (event.Due_Date_EST || event.Due_TIme_EST) {
        this.newOrderModel.Order_ETA = event.Due_Date_EST;
        this.order_ETA_Time = event.Due_TIme_EST;
        }
    }else{
      if(event.Due_Date_EST){
        if (event.Due_Date_EST || event.Due_TIme_EST) {
          this.newOrderModel.Order_ETA = event.Due_Date_EST;
          this.order_ETA_Time = event.Due_TIme_EST;
          this.validDueDate=false;
          }
      }else this.validDueDate=true;
    }
        if(event.req_Date_EST || event.req_Time_EST){
          this.newOrderModel.Order_Requested_Date = event.req_Date_EST;
          this.requested_Time = event.req_Time_EST;
          this.validDueDate=false;
        }
    this.ref.detectChanges();
  }
  
  getOrderProductsForClient(customerId, orderDetails) { 
    this.orderService.getOrderProductsForClient(customerId)
    .subscribe((products) => {
      if(products.some((product) => product.Product_ID == orderDetails.Product_ID && product.Product_Description === orderDetails.Product_Description)) this.products = products; 
      else {
      let clonedArray = JSON.parse(JSON.stringify(products));
      let productFindIndex = products.findIndex((product) => product.Product_ID == orderDetails.Product_ID && product.Product_Description !== orderDetails.Product_Description)
      products[productFindIndex].Product_Description = orderDetails.Product_Description
      this.products = products;
      let clonedFindIndex = clonedArray.findIndex((product) => product.Product_ID === orderDetails.Product_ID);
      this.products.splice(clonedFindIndex+1, 0, clonedArray[clonedFindIndex]);
      }
    })
  }
  
  display() {
    if (this.newOrderModel.Product_ID == 999) {
      this.newOrderForm.controls['Order_Product_Custom_Name'].reset();
      if(this.checkForOrderStatus()) this.newOrderForm.controls['Order_Product_Custom_Name'].disable();
      else this.newOrderForm.controls['Order_Product_Custom_Name'].enable();       
      this.newOrderForm.controls['Order_Product_Custom_Name'].setValidators([Validators.required, Validators.maxLength(255)])
      this.newOrderForm.controls['Order_Product_Custom_Name'].updateValueAndValidity();
    } else {
      this.newOrderModel.Order_Product_Custom_Name = '';
      this.newOrderForm.controls['Order_Product_Custom_Name'].clearValidators();
      this.newOrderForm.controls['Order_Product_Custom_Name'].updateValueAndValidity();
    }
  }
  
  isCustomSearch() {
    if (this.newOrderModel.Product_ID == 999) return true;
    else return false;
  }

  checkForOrderStatus() {
    if(this.newOrderModel.Order_Status === CONSTANTS.orderStatus.completed || this.newOrderModel.Order_Status === CONSTANTS.orderStatus.cancelled) return true;
    else return false;
  }
  
  handleManualChangeProduct(event){
    if(event){
      this.getProductPricing(event.value.Product_ID, event.value.Product_Description)
    }
  }

  getProductPricing(productId, productDescription = null){
    let customerId = this.newOrderModel.Organization_ID ? this.newOrderModel.Organization_ID : this.newOrderModel.Customer_ID;
    if (this.newOrderModel.Property_County && this.newOrderModel.Property_State_Abbr) {
      this.pricingService.getCustomAndOrginalPriceDetails(this.newOrderModel.Property_State_Abbr,this.newOrderModel.Property_County, productId, customerId)
      .subscribe((data) => {
        this.pricingDetailsObj.New_Price = null;
        this.pricingDetailsObj.Base_Price = null;
        if(data.Original_Cost) {
          this.pricingDetailsObj.New_Price = data.Original_Cost.Cost_Amount; 
          this.pricingDetailsObj.Base_Price = data.Original_Cost.Cost_Amount; 
          if(data.Custom_Cost)this.pricingDetailsObj.New_Price = data.Custom_Cost.Custom_Cost_Amount;
        }
        if(!this.pricingDetailsObj.New_Price) this.pricingDetailsObj.New_Price = 0;
        if(!this.pricingDetailsObj.Base_Price) this.pricingDetailsObj.Base_Price = 0;
      })
      if(productDescription) this.updateProductDescription(productId, productDescription)
    }
  }

  updateProductDescription(productId, productDescription) {
    if(this.refModel.Product_Description !== productDescription) {
      let productFindIndex = this.products.findIndex((product) => product.Product_ID === productId);
      this.newOrderModel.Product_ID = productId;
      this.newOrderModel.Product_Description = productDescription;
      this.newOrderModel.Product_Name = this.products[productFindIndex].Product_Name;
    } else {
      let productFindIndex = this.products.findIndex((product) => product.Product_ID === productId);
      this.newOrderModel.Product_Description = this.refModel.Product_Description;
      this.newOrderModel.Product_Name = this.products[productFindIndex].Product_Name;
    }
  }

  getBaseProductPrice(productId, orderId){
    this.orderService.getBaseProductDetails(productId, orderId)
    .subscribe((data) => {
      if(data.Order_Product_Price) this.pricingDetailsObj.Old_Price = data.Order_Product_Price;
      else this.pricingDetailsObj.Old_Price = 0;
    })
  }

  getholidaylistFromLocal(){
    let allHolidays = JSON.parse(sessionStorage.getItem('holidays'))
    if(allHolidays) this.setHoliday(allHolidays)
    else this.getholidaylist();
  }

  setHoliday(holiday){
    this.holidayList = holiday
  }

  getholidaylist(){
    this.holidayScheduling.getAllHolidays()
    .subscribe((holidays)=>{
      if(holidays){
        this.setHoliday(holidays)
        sessionStorage.setItem('holidays', JSON.stringify(holidays));
     setTimeout(function(){sessionStorage.removeItem("holidays");}, CONSTANTS.sessiontimeOut.timeOut);
      
      }
      // this.holidayList = holidays;
    }, (err) =>{
      console.log("Error",err)
    })
  }

  checkCountyNReplace(place, i){
    place.address_components[i].long_name = place.address_components[i].long_name.replace(' County', '')
  } 

  getAllOrderStatus(orderId){
    this.orderService.getAllOrderStatus(orderId)
    .subscribe((orderStatusDet) => {
      this.allOrderStatus = orderStatusDet;
    })
  }

  checkIfOrderConfirmed() {
    let confirmedOrdIndex = this.allOrderStatus.findIndex((data) => data.Status_New === CONSTANTS.orderStatus.confirmed)
    if(confirmedOrdIndex > -1) return true;
    else return false;
  }

  getCountyForIndependCity = (state?: string) => {
		this.pricingService.getIndpendentCities()
		.subscribe((cities) => {
			this.allIndepdentCities = cities;
		})
	};

	checkIfCityIsIndependent() {
	let findCityIndex = this.allIndepdentCities.findIndex((city) => {
		if(city) return this.newOrderModel.Property_City.toLowerCase() === city.System_Independent_City.toLowerCase()
		else return false;
	})
	if(findCityIndex > -1 ) {
		if(this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name)  this.checkAvlCounty = this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name.toUpperCase();
		else this.checkAvlCounty = this.newOrderModel.Property_City.toUpperCase();;
	}else this.checkAvlCounty = this.newOrderModel.Property_City.toUpperCase();;
  }
 
  getBorrowerDetails(orderId){
    this.orderService.getAllBorrowerDetails(orderId)
    .subscribe((borrowerData) => {
    this.borrowerDetails = borrowerData
    this.copyBorrowerDetails = borrowerData;
    this.getRowDataForBorrower(this.borrowerDetails)
  })
  }

  getRowDataForBorrower(borrowerData){
    if(this.rowData && this.rowData.length > 0) this.rowData.length = 0;
    let borrowerDataArr =[], coBorrowerDataArr = [];
    borrowerData.forEach((borrower) => {
      if(borrower.Order_Borrower_Priority == 1) borrowerDataArr.push(this.getRowObjectData(borrower))
      else coBorrowerDataArr.push(this.getRowObjectData(borrower))
    })
    this.rowData.push(borrowerDataArr, coBorrowerDataArr);
    this.rowData = _.flatten(this.rowData);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getBorrowerDetails(this.route.snapshot.params['orderId'])
  }


  getRowObjectData(borrowerData){
    let rowDataObject ={};
    if(borrowerData.Property == null) {
     rowDataObject ={
       Order_Borrower_ID :borrowerData.Order_Borrower_ID,
        Order_Borrower_Name:borrowerData.Order_Borrower_Name,
        Property_Address_1:'-',
        Property_City:'-',
        Property_State_Abbr:'-',
        Property_ZipCode :'-',
        Order_Borrower_SSN:borrowerData.Order_Borrower_SSN,
        Order_Borrower_Findings:borrowerData.Order_Borrower_Finding,
        Order_ID:borrowerData.Order_ID,
        Order_Borrower_Priority:borrowerData.Order_Borrower_Priority,
      }
      return rowDataObject;
    }
    else{
      rowDataObject = {
        Order_Borrower_Name:borrowerData.Order_Borrower_Name,
        Property_Address_1:borrowerData.Property.Property_Address_1,
        Property_City:borrowerData.Property.Property_City,
        Property_State_Abbr:borrowerData.Property.Property_State_Abbr,
        Property_ZipCode :borrowerData.Property.Property_ZipCode,
        Order_Borrower_SSN:borrowerData.Order_Borrower_SSN,
        Order_Borrower_Findings:borrowerData.Order_Borrower_Finding,
        Property_ID :borrowerData.Property.Property_ID,
        Order_Borrower_ID :borrowerData.Order_Borrower_ID,
        Order_Borrower_Priority:borrowerData.Order_Borrower_Priority,
        Property_First_Name :borrowerData.Property.Property_First_Name,
        Property_County :borrowerData.Property.Property_County,
        Order_ID:borrowerData.Order_ID
      }
      return rowDataObject;
    }
  }

  createBorrowerCol() {
    return [
      {
        headerName: 'Property ID',
        field: 'Property_ID',
        hide : true
      },
      {
        headerName: 'Order Borrower ID',
        field: 'Order_Borrower_ID',
        hide : true
      },
      {
        headerName: 'Order Borrower Priority',
        field: 'Order_Borrower_Priority',
        hide : true
      },
      {
        headerName: 'Order ID',
        field: 'Order_ID',
        hide : true
      },
     
      {
        headerName: 'Property County ',
        field: 'Property_County',
        hide : true,
      },
      {
        headerName: 'Name',
        field: 'Order_Borrower_Name',
        width:150,
        editable:true,
      },
      {
        headerName: 'Address',
        field: 'Property_Address_1',
        width:200,
        editable:true,
      },
      {
        headerName: 'City',
        field: 'Property_City',
        width:120,
        editable:true,
      },
      {
        headerName: 'State',
        field: 'Property_State_Abbr',
        suppressKeyboardEvent: this.getOnlyCharacter,
        width:100,
        editable:true,
      },
        {
          headerName: 'Zip code',
          field: 'Property_ZipCode',
          suppressKeyboardEvent: this.getOnlyNumber,
          width:120,
        editable:true,
        },
      {
        headerName: 'Last 4 SSN',
        field: 'Order_Borrower_SSN',
        width:130,
        editable:true,
        suppressKeyboardEvent:this.getCharacterForSSN
      },
      {
        headerName: 'Findings',
        field: 'Order_Borrower_Findings',
        width:390,
        flex: 1,
        editable:false,
        cellEditor: 'agLargeTextCellEditor',
        cellRendererFramework: AgGridTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => this.formatToolTip(params.data),
        cellEditorParams: {
          maxLength: 65535,  // override the editor defaults
          cols: '55',
          rows: '6'
        }
      }
    ]
  }

  formatToolTip(params: any) {
    const first = params.Order_Borrower_Findings
    const toolTipArray = [first]
    return { toolTipArray }
  }


  getOnlyCharacter(params){
      let e = params.event;
      let limit = 2;
      let characterValue = e.target.value;
      if ([8, 9, 27, 13, 110, 46,190].indexOf(e.keyCode) !== -1 ||
      (e.keyCode == 65 && e.ctrlKey === true) ||
      (e.keyCode == 67 && e.ctrlKey === true) ||
      (e.keyCode == 88 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)) {
        return;
      }
        if(!(e.keyCode < 48 || e.keyCode > 57)) e.preventDefault();
        else if(characterValue.length >= limit) e.preventDefault();
  }



  getCharacterForSSN(params){
    let e = params.event;
    let limit = 11;
    let numberValue = e.target.value;
    if ([8, 9, 27, 13, 110, 46,190].indexOf(e.keyCode) !== -1 ||
    (e.keyCode == 65 && e.ctrlKey === true) ||
    (e.keyCode == 67 && e.ctrlKey === true) ||
    (e.keyCode == 88 && e.ctrlKey === true) ||
    (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    }
      if(!(e.keyCode > 48 || e.keyCode < 57)) e.preventDefault();
      else if(numberValue.length >= limit) e.preventDefault();
}


  getOnlyNumber(params){
    let e = params.event;
    let limit = 5;
    let numberValue = e.target.value;
      if ([8, 9, 27, 13, 110, 46,190].indexOf(e.keyCode) !== -1 ||
      (e.keyCode == 65 && e.ctrlKey === true) ||
      (e.keyCode == 67 && e.ctrlKey === true) ||
      (e.keyCode == 88 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)) {
        return;
      }
      else if(numberValue.length >= limit) e.preventDefault();
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)))  {
        if(e.keyCode == 46 && numberValue.indexOf('.') < 0) return 
        else if((e.keyCode < 96 || e.keyCode > 105))  e.preventDefault();
        else if(numberValue.length >= limit) e.preventDefault();
      }
  }




  rowValueChanged(event){
    let cellChangeObj = event;
    if(cellChangeObj.data.Property_State_Abbr == "" || cellChangeObj.data.Property_State_Abbr.length < 2 || cellChangeObj.data.Property_State_Abbr.length > 2){
      let errMsg = "Invalid State."
      this.userMsg.setErrorMsg(errMsg);
      this.gridApi.undoCellEditing();
    }
      else if(cellChangeObj.data.Property_ZipCode == "" || cellChangeObj.data.Property_ZipCode.length < 5 || cellChangeObj.data.Property_ZipCode.length > 5){
      let errMsg = "Invalid Zipcode."
      this.userMsg.setErrorMsg(errMsg);
      this.gridApi.undoCellEditing();
    }
    else if(cellChangeObj.data.Order_Borrower_SSN.length > 11){
      let errMsg = "Invalid SSN."
      this.userMsg.setErrorMsg(errMsg);
      this.gridApi.undoCellEditing();
    }
    else {
    if(this.checkIfValueRSame(event.data, event.data.Order_Borrower_ID)){
      this.orderService.editCoBorrowerDetails(event.data,this.newOrderModel.Order_ID)
      .subscribe(()=>{
        this.getBorrowerDetails(this.newOrderModel.Order_ID)
      },(err) => {
          this.openErrorPopup(err.msg);
      })
    }
    }
  }

  checkIfValueRSame(newBorrowerObject, borrowerId){
    return this.copyBorrowerDetails.some((borrower) => {
      if(borrower.Order_Borrower_ID === borrowerId) {
        if(borrower.Property == null) return true;
        else{
          if(borrower.Order_Borrower_Name === newBorrowerObject.Order_Borrower_Name &&
            borrower.Property.Property_Address_1 === newBorrowerObject.Property_Address_1 &&
            borrower.Property.Property_City === newBorrowerObject.Property_City &&
            borrower.Property.Property_State_Abbr === newBorrowerObject.Property_State_Abbr &&
            borrower.Property.Property_ZipCode === newBorrowerObject.Property_ZipCode &&
            borrower.Order_Borrower_SSN === newBorrowerObject.Order_Borrower_SSN &&
            borrower.Order_Borrower_Finding === newBorrowerObject.Order_Borrower_Findings
            ) return false
            else return true;
        }
      }
    })
   }
   checkForNameSearch(){
    if(this.newOrderModel.Product_ID == environment.nameSearchProductId)return true;
    else return false;
  }

  getPippinDate(event) {
    if(event && event.Pippin_Date ) {
      this.newOrderModel['Order_Mortgage_Date'] = event.Pippin_Date;
    }else this.newOrderModel['Order_Mortgage_Date'] = null;
  }

  checkForETAValidation(){
    if(this.newOrderForm){
      if(!this.newOrderForm.valid && !this.newOrderForm.touched ){
        if(this.newOrderModel.Order_Status < CONSTANTS.orderStatus.confirmed){
          if(this.newOrderForm['controls']['Order_ETA'].hasError('matDatepickerMin')){ 
            this.OrderETADate = true;
            return true
          }
        }else{
          if(this.newOrderForm['controls']['Order_ETA'].hasError('matDatepickerMin')){ 
            this.OrderETADate = true;
            return true
          }
        }
        
      }else return false;
    }else return false;

  }

  getDataDepthInfo() {
    this.orderService.getDataDepthInfo({Property_State_Abbr: this.newOrderModel.Property_State_Abbr })
      .subscribe((info) => {
        this.dataDepthYears = info[0]["State Statute"][0]
        // if(info.length > 0) this.dataDepth = info[0];
        // else this.gsheetSearchInfo = {};
        if(this.newOrderModel.Years_Required == null) this.newOrderModel.Years_Required = info[0]["State Statute"][0];
      }, (err) => {
      })
  }
}
