import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";

@Component({
  selector: "app-chk-sec-inst",
  templateUrl: "./chk-sec-inst.component.html",
  styleUrls: ["./chk-sec-inst.component.scss"],
})
export class ChkSecInstComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  secInstsCnt: any = 0;
  liensCnt: any = 0;
  judgementsCnt: any = 0;

  constructor(private builder: RptBuilderService) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.liens$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) this.getJudgementDetails(data.curData);
      });
    this.builder.secInst$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) this.getSecInstDetails(data.curData);
      });
  }

  getJudgementDetails(data) {
    if (data && data.length) {
      this.judgementsCnt = data.filter(
        (itm) => itm.Entity_ID == 2
      ).length;
      this.liensCnt = data.filter(
        (itm) => itm.Entity_ID == 1
      ).length;
    }
  }

  getSecInstDetails(data) {
    if (data && data.length) {
      this.secInstsCnt = data.length;
    }
  }
}
