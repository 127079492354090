import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService, ConfigService } from "app/services";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private config: ConfigService
  ) {}

  getOptions(inputValue: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() + "/v2/orders/orderForm/customerDropDown";

    // if (inputValue.length === 0 || inputValue.length > 2)
    return this.http.post(
      url,
      { userId: this.auth.getUserId(), query: inputValue },
      httpOptions
    );
  }
}
function observableThrowError(error: any): any {
  throw new Error("Function not implemented.");
}
