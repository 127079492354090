import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormsModule, UntypedFormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, EqualValidator, PasswordValidator, ConfigService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss']
})
export class ChangeComponent implements OnInit {

  changeFrm: UntypedFormGroup;
  changeModel: any = {};
  userData: any = {};
  error: string = '';
  errorType: string = '';
  errorMsg: any = {};
  timer: any = [];
  @ViewChild('myfocus') myfocus:any;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog:MatDialog,
    private config:ConfigService
  ) {
    this.changeFrm = fb.group({
      'Cur_Password': [null, Validators.required],
      'password': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      'confirmpassword': [null, Validators.required],
    })
    this.changeFrm.valueChanges.subscribe(data => this.error = '');
  }

  ngOnInit() {
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
    this.myfocus.nativeElement.focus(); 
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  setPassword() {
    this.errorMsg.msg = '';
    this.userData['User_ID'] = this.auth.getUserId();
    this.userData['Cur_Password'] = this.changeModel.password;// user new password
    this.userData['User_Password'] = this.changeModel.Cur_Password;// user current password
    console.log("setPassword function changeModel=>:", this.changeModel);
     if(this.changeFrm.valid){
      this.auth.changePassword(this.userData)
        .subscribe((res) => {
          console.log("set password :", res);
          this.openDialog();
          this.auth.logout();
          this.router.navigate(['login']);
        }, (error) => {
          console.log("Error:", error);
          this.errorMsg = error;
          this.errorType = error.type;
          console.log("this.errorMsg:", this.errorMsg);
          console.log("this.errorType:", this.errorType);
        });
   } else {
      this.errorType = 'general';
      this.errorMsg.msg = 'Please enter correct details in all required fields';
    }
  }

  goToHome() {
    this.router.navigate(['orders/current'])
  }

  clearError() {
    this.errorMsg.msg = '';
  }

  openDialog() {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: 'Success',
      message: 'Please login using your new password.'
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        console.log("Closed", res)
      });
  }
}
