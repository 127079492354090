import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, ConfigService } from "app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-e500",
  templateUrl: "./e500.component.html",
  styleUrls: ["./e500.component.scss"],
})
export class E500Component implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  constructor(
    private auth: AuthService,
    private router: Router,
    private config: ConfigService
  ) {}

  ngOnInit() {
    this.auth.isServerReachable$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((stat) => {
        if (stat) this.router.navigate(["/base"]);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
