// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  text-decoration-line: underline;
}

.bg-grey {
  background-color: #fafafa;
  color: #949494;
  display: block;
  font-weight: 500;
  padding: 8px;
}

.integration-container {
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  padding: 0px 20px 20px;
}

.integration-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}
.integration-item mat-form-field {
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/clients/edit-client/edit-client.component.scss"],"names":[],"mappings":"AAAC;EACG,+BAAA;AACJ;;AAEA;EACI,yBAAA;EACA,cAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;AACJ;;AAEE;EACE,aAAA;EAAc,sBAAA;EAAuB,yBAAA;EAA0B,sBAAA;AAInE;;AADE;EACE,aAAA;EAAe,mBAAA;EAAoB,mBAAA;EAAoB,SAAA;AAO3D;AANI;EACE,YAAA;AAQN","sourcesContent":[" a{\n    text-decoration-line: underline;\n}\n\n.bg-grey {\n    background-color: #fafafa;\n    color: #949494;\n    display: block;\n    font-weight: 500;\n    padding: 8px;\n  }\n  \n  .integration-container{\n    display: flex;flex-direction: column;background-color: #fdfdfd;padding: 0px 20px 20px\n  }\n  \n  .integration-item{\n    display: flex; flex-direction: row;align-items: center;gap: 40px;\n    mat-form-field{\n      width:200px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
