import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable()
export class ConfigService {
    private basePath: string;
    private access_token: string;
    private numberOfRecords: number = 25;
    private sideBarNode: number = 0;
    private scrollYThreshold = 300;
    private showingRecords: boolean = true;
    private infiniteScrollThrottle = 800;
    private infiniteScrollDistance = 0.1;
    private amountMaxLength = 9;
    private priceMaxLength = 11;
    private limitPriceMaxLength = 13;
    private maxFileSize = 1000;
    private dialogOptions: any = { closeByClickingOutside: false };
    timer: any;
    public successTimeout = 10000;
    private numberOfOrderRecords: number = 25;
    private numberOfNewOrderRecords: number = 100;
    private numberOfDueOrderRecords: number = 50;
    
    private OrderSearchandComplete: number = 50;
    private debounceTime = 2000;
    private mySidebar: boolean = true;
    private totalMaxQBOFileSize: number = 100;
    private individualMaxQBOFileSize: number = 20;
    private googleAPIKey = "AIzaSyBQxrh1ixO7oDDfGrBYxvpRkSoIl1RrNVk"
    private allowedFileTypes = ['ai', 'csv', 'doc', 'docx', 'eps', 'gif', 'jpeg' , 'jpg', 'ods', 'pdf', 'png', 'rtf', 'tif', 'txt', 'xls', 'xlsx', 'xml'];
    private notificationDisplayTime = 3000;
    private noOfRecords: number = 50;
    constructor() { }

    getS3Region(): string {
        return environment.aws.region;
    }

    getS3Bucket(): string {
        return environment.aws.bucket;
    }

    getS3Identity(): string {
        return environment.aws.credentials;
    }

    getS3ApiVersion(): string {
        return environment.aws.apiVersion;
    }

    getBasePath(): string {
        return environment.basePath;
    }

    getBasePathSituSAMCService(): string {
        return environment.basePathSitusAMCService;
    }

    getClientHomePath(): string {
        return environment.clientHome;
    }

    getAdminHomePath(): string {
        return environment.adminHome;
    }

    getQualiaOrgId(): string {
        return environment.qualiaOrgId;
    }

    getNewClientTagId(): string {
        return environment.newClientTagId;
    }

    getPriorityClientTagId(): string {
        return environment.priorityClientTagId;
    }

    getFirstOrdTagId(): string {
        return environment.firstOrderTagID;
    }

    getPrePaidTagId(): string {
        return environment.prePaidTagId;
    }

    getPartiallyDeliveredTagId(): string {
        return environment.partiallyDelivered;
    }

    getAbsDoubleAssignTagId(): string {
        return environment.absDoubleAssign;
    }

    getQCTagId(): number {
        return environment.QCTagId;
    }

    getSearchingTagId(): number {
        return environment.SearchingTagId;
    }

    getSoftProTypingTagId(): number {
        return environment.SoftProTagId;
    }

    getAwaitDlvryTagId(): number {
        return environment.AwaitDlvryTagId;
    }

    getAwaitPartDlvryTagId(): number {
        return environment.AwaitPartDlvryTagId;
    }

    isProductionEnv(): boolean {
        return environment.production;
    }

    getAccessToken() {
        return environment.access_token;
    }

    getNumRecordsPerPage() {
        return this.numberOfRecords;
    }
    getNoOfRecordsPerPage( ){
        return this.noOfRecords; 
    }

    setSideBarNode(activeNode) {
        this.sideBarNode = activeNode;
    }

    getSideBarNode() {
        return this.sideBarNode;
    }

    getScrollYThreshold() {
        return this.scrollYThreshold;
    }

    setDelayRecords() {
        this.timer = setTimeout(() => {
            this.showingRecords = true;
        }, 4000);
    }

    setClearTimeout() {
        window.clearTimeout(this.timer);
    }

    resetShowFlag() {
        this.showingRecords = false;
    }

    showResetFlag() {
        return this.showingRecords;
    }

    setScrollThrottle() {
        return this.infiniteScrollThrottle;
    }

    setScrollDistance() {
        return this.infiniteScrollDistance;
    }

    getPriceMaxLength() {
        return this.priceMaxLength;
    }

    getLimitPriceMaxLength() {
        return this.limitPriceMaxLength;
    }

    getAmountMaxLength() {
        return this.amountMaxLength;
    }

    getMaxFileSize() {
        return this.maxFileSize;
    }

    getDialogOptions() {
        return this.dialogOptions;
    }

    getNumOrdRecordsPerPage() {
        return this.numberOfOrderRecords;
    }
    
    getNumNewOrdRecordsPerPage() {
        return this.numberOfNewOrderRecords;
    }
    getNumDueOrdRecordsPerPage() {
        return this.numberOfDueOrderRecords;
    }

    getnoOrderSearchandComplete(){
        return this.OrderSearchandComplete;
    }

    getDebounceTime() {
        return this.debounceTime;
    }
    setMySidebar(sidebar) {
        this.mySidebar = sidebar
    }

    getMySidebar() {
        return this.mySidebar;
    }

    getTotalMaxQBOFileSize(){
        return this.totalMaxQBOFileSize;
    }

    getIndividualMaxQBOFileSize(){
        return this.individualMaxQBOFileSize;
    }

    getAllowedFileTypes(){
        return this.allowedFileTypes;
    }

    getGoogleAPIKEY() {
        return this.googleAPIKey;
    }

    getNotifDisplayTime(){
        return this.notificationDisplayTime;
    }

    getVapidPublicKey(){
        return environment.vapidPublicKey;
    }

    getQualiaMsURL(){
        return environment.qualia_ms_url;
    }
}
