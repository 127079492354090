// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-body {
  overflow-y: auto;
  width: 100%;
  height: 300px;
}

.modal-dialog-over {
  width: 50rem;
}

.modal-content {
  width: 100%;
}

tbody tr:hover {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/login-history/login-history.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,WAAA;EACA,aAAA;AACJ;;AACA;EACG,YAAA;AAEH;;AACA;EACI,WAAA;AAEJ;;AAAA;EACK,eAAA;AAGL","sourcesContent":[".dialog-body{\n    overflow-y: auto;\n    width: 100%;\n    height: 300px;\n}\n.modal-dialog-over {\n   width: 50rem;\n}\n\n.modal-content{\n    width: 100%;\n}\ntbody tr:hover {\n     cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
