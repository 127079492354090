import { Component, OnInit, ChangeDetectorRef, Inject } from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { OrdersService, PricingService, ConfigService } from "../../services";
import { CONSTANTS } from "../../app.constants";
import * as _ from "underscore";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import { environment } from "environments/environment";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
declare var google: any;

@Component({
  selector: "app-validate-ord-address",
  templateUrl: "./validate-ord-address.component.html",
  styleUrls: ["./validate-ord-address.component.scss"],
})
export class ValidateOrdAddressComponent {
  title = "";
  validateAddressForm: UntypedFormGroup;
  validateAddrModel: any = {};
  states = [];
  completeAddress: any;
  getAddress: any;
  selectAddressType = "2";
  errors: any = {};
  timer: any = [];
  allIndepdentCities: any;
  showCityInCounty: boolean = false;

  constructor(
    private ref: ChangeDetectorRef,
    private orderService: OrdersService,
    private pricingService: PricingService,
    private formBuilder: UntypedFormBuilder,
    private config: ConfigService,
    public dialogRef: MatDialogRef<ValidateOrdAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog
  ) {
    this.validateAddressForm = this.formBuilder.group({
      Property_Address_1: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(127)]),
      ],
      Property_City: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(127)]),
      ],
      Property_State_Abbr: new UntypedFormControl({
        value: null,
        disabled: false,
      }),
      Property_County: new UntypedFormControl(null),
      Property_ZipCode: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(11)]),
      ],
      searchValue: [null],
    });
  }

  ngOnInit() {
    this.initDialog();
    this.modifyEditFormForNameSearch(this.getAddress.Product_ID);
    let fullOrderAddress = this.buildAddress(this.getAddress.Address);
    this.getCountyForIndependCity();
    this.timer.push(
      setTimeout(() => {
        this.checkOrderAddrInGoogle(fullOrderAddress);
        this.pricingService.getStateList().subscribe((states) => {
          if (states) {
            this.states = states;
          }
        });
      }, 0)
    );
  }

  initDialog() {
    this.title = this.data.title;
    this.getAddress = this.data.getAddress;
  }

  modifyEditFormForNameSearch(productId) {
    if (productId != environment.nameSearchProductId) {
      this.setValidatorsNUpdate("Property_State_Abbr");
      this.setValidatorsNUpdate("Property_County");
    }
  }

  setValidatorsNUpdate(propertyName) {
    this.validateAddressForm.controls[propertyName].clearValidators();
    this.validateAddressForm.controls[propertyName].setValidators(
      Validators.required
    );
    this.validateAddressForm.controls[propertyName].updateValueAndValidity();
  }

  buildAddress(model) {
    this.completeAddress = "";
    if (model.Property_Address_1)
      this.completeAddress += model.Property_Address_1;
    if (model.Property_Address_2) {
      if (this.completeAddress) this.completeAddress += "\n";
      this.completeAddress += model.Property_Address_2;
    }
    if (model.Property_City) {
      if (this.completeAddress) this.completeAddress += "\n";
      this.completeAddress += model.Property_City;
    }
    if (model.Property_State_Abbr) {
      if (this.completeAddress) this.completeAddress += ", ";
      this.completeAddress += model.Property_State_Abbr;
    }
    if (model.Property_ZipCode) {
      if (this.completeAddress) this.completeAddress += " ";
      this.completeAddress += model.Property_ZipCode;
    }
    return this.completeAddress;
  }

  checkOrderAddrInGoogle(fullOrderAddress) {
    let addressValidateObject = {
      postal_code: null,
      country: null,
      administrative_area_level_1: null,
      administrative_area_level_2: null,
      locality: null,
      sublocality: null,
      route: null,
      formatted_address: null,
      Property_Latitude: null,
      Property_Longitude: null,
      street_number: null,
      status: "ERROR",
    };

    this.orderService
      .checkAddressAPI(fullOrderAddress)
      .subscribe((addressResponse) => {
        if (addressResponse) {
          addressValidateObject.status = addressResponse.status;
          if (addressValidateObject.status == "OK") {
            addressValidateObject.formatted_address =
              addressResponse.results[0].formatted_address;
            addressValidateObject.Property_Latitude =
              addressResponse.results[0].geometry.location.lat;
            addressValidateObject.Property_Longitude =
              addressResponse.results[0].geometry.location.lng;
            addressResponse.results[0].address_components.forEach(
              (addressData) => {
                if (
                  Object.keys(addressValidateObject).some(
                    (x) => x != "route" && x == addressData.types[0]
                  )
                ) {
                  if (
                    addressData.types[0] &&
                    addressData.types[0] == "administrative_area_level_2"
                  )
                    addressValidateObject[addressData.types[0]] =
                      addressData.long_name;
                  else
                    addressValidateObject[addressData.types[0]] =
                      addressData.short_name;
                }
                if (
                  Object.keys(addressValidateObject).some(
                    (x) => addressData.types[1] && x == addressData.types[1]
                  )
                )
                  addressValidateObject[addressData.types[1]] =
                    addressData.short_name;
                if (
                  Object.keys(addressValidateObject).some(
                    (x) => x == "route" && x == addressData.types[0]
                  )
                )
                  addressValidateObject[addressData.types[0]] =
                    addressData.long_name;
              }
            );
            this.checkIfStreetNumber(addressValidateObject.street_number);
            this.checkIfStateError(
              addressValidateObject.administrative_area_level_1
            );
            this.checkIfCountyError(
              addressValidateObject.administrative_area_level_2,
              addressValidateObject.administrative_area_level_1
            );
            this.checkIfZipcodeError(addressValidateObject.postal_code);
            this.checkIfCountryError(addressValidateObject.country);
            this.checkIfRouteError(addressValidateObject.route);
            if (addressValidateObject.locality)
              this.checkIfLocailtyError(addressValidateObject.locality);
            else this.checkIfLocailtyError(addressValidateObject.sublocality);
            if (addressValidateObject.Property_Latitude)
              this.validateAddrModel.Property_Latitude =
                addressValidateObject.Property_Latitude;
            if (addressValidateObject.Property_Longitude)
              this.validateAddrModel.Property_Longitude =
                addressValidateObject.Property_Longitude;
          } else {
            this.errors["Country"] = true;
          }
        }
      });
  }

  checkIfStreetNumber(googleAddrStreetNo) {
    if (googleAddrStreetNo)
      this.validateAddrModel.Property_Address_1 = googleAddrStreetNo;
  }

  checkIfStateError(googleAddrStateData) {
    if (!googleAddrStateData) this.validateAddrModel.Property_State_Abbr = null;
    else {
      if (
        !googleAddrStateData.includes(
          this.getAddress.Address.Property_State_Abbr
        )
      ) {
        this.errors["stateError"] = true;
        this.validateAddrModel.Property_State_Abbr = googleAddrStateData;
      } else {
        this.validateAddrModel.Property_State_Abbr = googleAddrStateData;
        delete this.errors["stateError"];
      }
    }
  }

  checkIfCountyError(googleAddrCountyData, googleAddrStateData) {
    if (googleAddrStateData != "DC") {
      if (!googleAddrCountyData) this.validateAddrModel.Property_County = null;
      else {
        if (this.checkSaint(googleAddrCountyData))
          googleAddrCountyData = googleAddrCountyData.replace(" County", "");
        googleAddrCountyData = googleAddrCountyData.replace(" County", "");
        googleAddrCountyData = googleAddrCountyData.toUpperCase();
        this.validateAddrModel.Property_County = googleAddrCountyData;
        if (this.getAddress.Address.Property_County) {
          if (
            !googleAddrCountyData.includes(
              this.getAddress.Address.Property_County.toUpperCase()
            )
          ) {
            this.errors["countyError"] = true;
            this.validateAddrModel.Property_County = googleAddrCountyData;
          } else {
            this.validateAddrModel.Property_County = googleAddrCountyData;
            delete this.errors["countyError"];
          }
        }
      }
    } else this.validateAddrModel.Property_County = "WASHINGTON";
  }

  checkIfZipcodeError(googleAddrZipcodeData) {
    if (!googleAddrZipcodeData) this.validateAddrModel.Property_ZipCode = null;
    else {
      if (
        !googleAddrZipcodeData.includes(
          this.getAddress.Address.Property_ZipCode
        )
      ) {
        this.errors["zipCodeError"] = true;
        this.validateAddrModel.Property_ZipCode = googleAddrZipcodeData;
      } else {
        this.validateAddrModel.Property_ZipCode = googleAddrZipcodeData;
        delete this.errors["zipCodeError"];
      }
    }
  }

  checkIfCountryError(googleAddrCountryData) {
    if (!this.checkForCountry(googleAddrCountryData)) {
      this.validateAddrModel.Property_County = "";
      this.validateAddrModel.Property_State_Abbr = "";
      this.validateAddrModel.Property_ZipCode = "";
      this.validateAddrModel.Country = null;
      this.errors["countryError"] = true;
    } else delete this.errors["countryError"];
  }

  checkForCountry(country) {
    if (country === "US") return true;
    else return false;
  }

  checkIfRouteError(googleAddrRouteData) {
    if (!googleAddrRouteData) this.validateAddrModel.Property_Address_1 = null;
    else {
      if (this.validateAddrModel.Property_Address_1) {
        this.validateAddrModel.Property_Address_1 =
          this.validateAddrModel.Property_Address_1 + " " + googleAddrRouteData;
        this.checkPropertyAddress();
      } else {
        this.validateAddrModel.Property_Address_1 = googleAddrRouteData;
        this.checkPropertyAddress();
      }
    }
  }

  checkPropertyAddress() {
    if (
      !this.validateAddrModel.Property_Address_1.includes(
        this.getAddress.Address.Property_Address_1
      ) ||
      !this.getAddress.Address.Property_Address_1.includes(
        this.validateAddrModel.Property_Address_1
      )
    )
      this.errors["propAddressError"] = true;
    else delete this.errors["propAddressError"];
  }

  checkIfLocailtyError(googleAddrLocalityData) {
    if (!googleAddrLocalityData) {
      this.errors["cityError"] = true;
      this.validateAddrModel.Property_City = googleAddrLocalityData;
    } else {
      this.validateAddrModel.Property_City = googleAddrLocalityData;
      delete this.errors["cityError"];
    }
  }

  ngAfterViewInit() {
    let searchBox: any = document.getElementById("search-box");
    let options = {
      types: ["geocode"],
      // componentRestrictions: {country: "us"}
    };
    var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
    autocomplete.addListener("place_changed", () => {
      this.getPlaceDetails(autocomplete.getPlace());
      let sets = document.getElementsByClassName("pac-container pac-logo");
      if (sets.length > 1) {
        sets[0].remove();
      }
    });
  }

  getPlaceDetails(place: any) {
    this.validateAddressForm.controls["searchValue"].markAsDirty();
    this.validateAddrModel.searchValue = "";
    console.log("place.geometry: ", place);
    if (place.geometry == undefined) {
      this.validateAddrModel.Property_Address_1 = "";
      this.validateAddrModel.Property_City = "";
      this.validateAddrModel.Property_State_Abbr = "";
      this.validateAddrModel.Property_ZipCode = "";
      this.validateAddrModel.Property_County = "";
      return;
    } else {
      this.validateAddrModel.Property_Latitude = place.geometry.location.lat();
      this.validateAddrModel.Property_Longitude = place.geometry.location.lng();

      this.validateAddrModel.Property_Address_1 = "";
      this.validateAddrModel.Property_City = "";
      this.validateAddrModel.Property_State_Abbr = "";
      this.validateAddrModel.Property_ZipCode = "";
      this.validateAddrModel.Property_County = "";
      for (let i = place.address_components.length - 1; i >= 0; i--) {
        switch (place.address_components[i].types[0]) {
          case "premise":
            this.validateAddrModel.Property_Address_1 =
              place.address_components[i].long_name +
              " " +
              this.validateAddrModel.Property_Address_1;
            document
              .getElementById("Property_Address_1")
              .parentElement.classList.remove("is-empty");
            break;
          case "street_number":
            this.validateAddrModel.Property_Address_1 =
              place.address_components[i].long_name +
              " " +
              this.validateAddrModel.Property_Address_1;
            document
              .getElementById("Property_Address_1")
              .parentElement.classList.remove("is-empty");
            break;
          case "route":
            this.validateAddrModel.Property_Address_1 =
              place.address_components[i].long_name;
            document
              .getElementById("Property_Address_1")
              .parentElement.classList.remove("is-empty");
            break;
          case "sublocality_level_1":
            if (this.validateAddrModel.Property_City.length == 0)
              this.validateAddrModel.Property_City =
                place.address_components[i].long_name;
            document
              .getElementById("Property_City")
              .parentElement.classList.remove("is-empty");
            break;
          case "sublocality":
            if (this.validateAddrModel.Property_City.length == 0)
              this.validateAddrModel.Property_City =
                place.address_components[i].long_name;
            document
              .getElementById("Property_City")
              .parentElement.classList.remove("is-empty");
            break;
          case "locality":
            if (this.validateAddrModel.Property_City.length == 0)
              this.validateAddrModel.Property_City =
                place.address_components[i].long_name;
            document
              .getElementById("Property_City")
              .parentElement.classList.remove("is-empty");
            break;
          case "administrative_area_level_2": {
            if (this.checkSaint(place.address_components[i].long_name))
              this.checkCountyNReplace(place, i);
            else this.checkCountyNReplace(place, i);
            this.validateAddrModel.Property_County =
              place.address_components[i].long_name.toUpperCase();
            // place.address_components[i].long_name = place.address_components[i].long_name.replace('St.', 'saint');
            //if (this.checkCounty(place.address_components[i].long_name))
            // console.log("Switch Case",this.newOrderModel.Property_County, this.newOrderModel.Property_County);
            // document.getElementById('Property_County').parentElement.classList.remove('is-empty');
            break;
          }
          case "administrative_area_level_1": {
            this.validateAddressForm.controls["Property_State_Abbr"].setValue(
              place.address_components[i].short_name
            );
            this.validateAddrModel.Property_State_Abbr =
              place.address_components[i].short_name;
            if (place.address_components[i].short_name == "DC")
              this.validateAddrModel.Property_County = "WASHINGTON";
            break;
          }
          case "postal_code":
            this.validateAddrModel.Property_ZipCode =
              place.address_components[i].short_name;
            break;
          case "country":
            this.validateAddrModel.Country =
              place.address_components[i].short_name;
            break;
          default:
            break;
        }
      }
      if (
        this.checkIfState(
          this.validateAddrModel.Property_State_Abbr,
          this.validateAddrModel.Country
        )
      ) {
        if (!this.validateAddrModel.Property_County) {
          this.checkIfCityIsIndependent();
          this.showCityInCounty = true;
        } else this.showCityInCounty = false;
      } else {
        this.validateAddrModel.Property_State_Abbr = "";
        this.validateAddrModel.Property_County = "";
        this.validateAddrModel.Country = "";
        this.validateAddrModel.Property_City = "";
      }
      this.ref.detectChanges();
    }
  }

  checkIfState(state, country) {
    var states = _.flatten(this.states);
    if (country == "US" && states.indexOf(state) != -1) return true;
    else return false;
  }

  checkSaint(county) {
    var lowecaseCounties = CONSTANTS.saintArray.map((value) =>
      value.toLowerCase()
    );
    if (
      county.toLowerCase().startsWith("st.") &&
      lowecaseCounties.indexOf(county.toLowerCase()) == -1
    )
      return true;
    else return false;
  }

  confirmAddress(selectAddressType) {
    if (selectAddressType == "2") {
      this.validateAddrModel.independent_City = this.showCityInCounty;

      this.dialogRef.close({
        validateAddrssObj: this.validateAddrModel,
        selectAddressType: selectAddressType,
      });
      this.clearTime();
    } else {
      this.dialogRef.close({
        selectAddressType: selectAddressType,
      });
      this.clearTime();
      // if (Object.keys(this.errors).length > 0) {
      //   if(this.errors['countyError']) this.addressErrorDialog('Provided county is mismatch in google address.');
      //   if(this.errors['stateError']) this.addressErrorDialog('Provided state is mismatch in google address.');
      //   if(this.errors['zipCodeError']) this.addressErrorDialog('Provided zipcode is mismatch in google address.');
      //   if(this.errors['cityError']) this.addressErrorDialog('Provided city is mismatch in google address.');
      //   if(this.errors['countryError']) this.addressErrorDialog('Provided country is mismatch in google address.');
      //   if(this.errors['propAddressError']) this.addressErrorDialog('Provided address is mismatch in google address.');
      //   this.result = {
      //     selectAddressType: selectAddressType
      //   }
      //   this.clearTime();
      // } else {
      //   this.result = {
      //     selectAddressType: selectAddressType
      //   }
      //   this.clearTime()
      // }
    }
  }

  Close() {
    this.dialogRef.close(null);
    // this.result = null;
    this.clearTime();
  }

  checkAddressFormValid(selectAddressType) {
    if (selectAddressType == "2") {
      if (!this.validateAddressForm.valid) return true;
      else return false;
    } else return false;
  }

  addressErrorDialog(value) {
    let disposable = this.matDialog
      .open(ErrorDialogComponent, {
        data: {
          message: value,
        },
        ...this.config.getDialogOptions(),
      })
      .afterClosed()
      .subscribe((res) => {});
  }

  changeAddressType(selectedAddressType) {
    if (selectedAddressType == "1") this.selectAddressType = "2";
  }

  clearTime() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  checkCountyNReplace(place, i) {
    place.address_components[i].long_name = place.address_components[
      i
    ].long_name.replace(" County", "");
  }

  getCountyForIndependCity = (state?: string) => {
    this.pricingService.getIndpendentCities().subscribe((cities) => {
      this.allIndepdentCities = cities;
    });
  };

  checkIfCityIsIndependent() {
    let findCityIndex = this.allIndepdentCities.findIndex((city) => {
      if (city)
        return (
          this.validateAddrModel.Property_City.toLowerCase() ===
          city.System_Independent_City.toLowerCase()
        );
      else return false;
    });
    if (findCityIndex > -1) {
      if (
        this.allIndepdentCities[findCityIndex]
          .System_Independent_City_Custom_Name
      )
        this.validateAddrModel.Property_County =
          this.allIndepdentCities[
            findCityIndex
          ].System_Independent_City_Custom_Name.toUpperCase();
      else
        this.validateAddrModel.Property_County =
          this.validateAddrModel.Property_City.toUpperCase();
    } else
      this.validateAddrModel.Property_County =
        this.validateAddrModel.Property_City.toUpperCase();
  }
}
