import { Component, OnInit,ViewChild, ElementRef, Inject  } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { CONSTANTS } from '../../app.constants';
import { AuthService, OrganizationService, UsersService } from '../../services';
import { UntypedFormGroup,UntypedFormBuilder,Validators } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import * as momentTimeZone from 'moment-timezone';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-approve-client',
  templateUrl: './approve-client.component.html',
  styleUrls: ['./approve-client.component.scss']
})
export class ApproveClientComponent{
  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('selectBtn') focusedElement1: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  constants = CONSTANTS
  message :any;
  title = '';
  listingType = '0';
  organizations = [];
  selectedOrgId = '';
  domainName = '';
  errorMsg: any = {};
  user : any = {};
  changeModel: any = {};	
  changeCompFrm: UntypedFormGroup;
  timer: any = [];
  companyName = null;
  clientType = [
    { value: 0, viewValue: 'None' },
    { value: 1, viewValue: 'Qualia' },
    { value: 2, viewValue: 'TrackerPro' },
    { value: 4, viewValue: 'SoftPro' },
    { value: 8, viewValue: 'Resware' }
  ];
  constructor(
    public dialogRef: MatDialogRef<ApproveClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog,
    private organizationService: OrganizationService,
    private usersService: UsersService,
    private auth: AuthService,
    private fb: UntypedFormBuilder) {
    this.changeCompFrm = fb.group({	
      'organizationName': [null, Validators.compose([Validators.required, Validators.maxLength(127)])]
    })
  }

  ngOnInit() {
    this.initDialog();
    this.user = Object.assign({},this.message);    
    if (this.user['User_Type'] == CONSTANTS.userType.company) {
      this.usersService.getDomainName(this.user['Email_Address']).then((res) => {
        this.domainName = res + '';
        console.log("domainName 1", this.domainName);
        if (this.domainName) {
          this.organizationService.getAllOrganizations()
            .subscribe((domainRes) => {
              // console.log("domainRes 1", domainRes);
              if (domainRes) {
                // console.log("domainRes", domainRes);
                // if (domainRes && domainRes[0] && domainRes[0].Organization_Domain == this.domainName) this.selectedOrgId = domainRes[0].Organization_ID
                this.organizations = domainRes;
              }
            }, (err) => {
              console.log("err", err);
            })
        }
      });
    }
    this.timer.push(setTimeout(() => { 
      this.myFirstFocus.nativeElement.focus();
      this.getInfoToApprove(); 
    }, 0));
  }  

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  confirm() {
    this.errorMsg = {};
    if (this.listingType == "0") this.user['Organization_ID'] = this.selectedOrgId;
    else this.user['Organization_Name'] = this.changeModel.organizationName;
    this.user['Organization_Domain']= this.domainName;
    this.user['User_Modified_By']= this.auth.getUserId();
    this.user['Admin_User_ID']= this.auth.getUserId();
    this.user['Organization_Type']=this.changeModel.Organization_Type;
    this.dialogRef.close(this.user);
    this.clearTime()
  }

  isDisabled() {
    if (this.listingType == '0') {
      this.errorMsg = {};
      this.changeCompFrm.controls['organizationName'].markAsUntouched();	
      this.changeModel.organizationName = null;
      return this.selectedOrgId == '';
    }
    else return this.changeModel.organizationName == '';
  }
  Close() {
    this.dialogRef.close(false);
    this.clearTime()
  }


  isNewCompany() {
    return this.listingType;
  }

  validateCompany() {	
    if (this.listingType == "1") {	
      if (this.changeCompFrm.controls['organizationName'].errors)	
        return true;	
    } else return false;
  }

  checkDaylight(date){
    if(momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getInfoToApprove(){
    this.usersService. getInfoToApprove(this.user.User_ID)
    .subscribe((data) => {
      if(data) {
        this.companyName = data.Company_Name;
        this.changeModel.organizationName = data.Company_Name;
        this.changeModel.Organization_Type = data.Company_Type;
      }
    })
  }

  handleTypeChange(evt){
    console.log(evt);
    if(evt.value == '1')  
      setTimeout(() => {    
        this.changeModel.organizationName = this.companyName
    }, 0)
  }

}
