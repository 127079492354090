import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-report-builder-err-snackbar',
  templateUrl: './report-builder-err-snackbar.component.html',
  styleUrls: ['./report-builder-err-snackbar.component.scss']
})
export class ReportBuilderErrSnackbarComponent implements OnInit {

  constructor(
    public bottomSheetRef: MatBottomSheetRef<ReportBuilderErrSnackbarComponent>) { }

  ngOnInit() {
  }

  close(){
    this.bottomSheetRef.dismiss({ value : false });
  }

  proceed(){
    this.bottomSheetRef.dismiss({ value : true });
  }
}
