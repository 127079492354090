
import { forkJoin as observableForkJoin, Observable, Subject, ReplaySubject } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AuthService, PagerService, ConfigService, OrdersService, PricingService, StateService, SocketService, OrganizationService, ScrollService, TagsService, UsersService, UsermessageService } from '../../services';
import { CONSTANTS } from '../../app.constants'
import { Router } from '@angular/router';
import { PreloaderService } from '../../services/preloader/preloader.service'
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { DialogService } from "ng2-bootstrap-modal";
import { UntypedFormControl } from '@angular/forms';
import { NewTagComponent } from '../../dialog/new-tag/new-tag.component';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { DatePipe } from '@angular/common';
import * as momentTimeZone from 'moment-timezone';
import { ScreenOrderComponent } from '../../dialog/screen-order/screen-order.component';
import * as _ from 'underscore';
import { environment } from 'environments/environment'
import { CookieService } from 'ngx-cookie';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DatePipe]
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  constants = CONSTANTS;
  orders: any = []
  orderStatus: any = {};
  ordersToDisplay: any;
  totalCount: number;
  selectedState = '';
  selectedCounty = '';
  selectedProduct = 0;
  pager: any = {};
  states = [];
  counties = [];
  products = [];
  filterBy: any = [];
  searchInProgress: boolean = false;
  searchString: string;
  sortField: string;
  orderBy: string;
  pageNum: number;
  organizations = [];
  allorganizations = [];
  selectedOrgId = '';
  selectedOrgId2 = '';
  scrollEnable: boolean = true;
  isDemoFlag: boolean = false;
  allActiveTags = [];
  selectedTags = [];
  searchTag: string = ''; 
  tagsOrderCount = [];  
  orderTags = [];
  associatedTags = [];
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  getDuplicateOrder = [];
  orgFilterCtrl = new UntypedFormControl();
  stateFilterCtrl = new UntypedFormControl();
  countyFilterCtrl = new UntypedFormControl();
  productFilterCtrl = new UntypedFormControl();
  _onDestroy = new Subject<void>()
  filteredOrg = new ReplaySubject<any[]>(1)
  filteredState = new ReplaySubject<any[]>(1)
  filteredCounty = new ReplaySubject<any[]>(1)
  filteredProduct = new ReplaySubject<any[]>(1)
  searchQuery = new Subject<string>();
  ordsToassign=[];
  selectedOrgIds=[];
  timer: any = [];
  selectedorg = '';
  selecteState = '';
  selectecounty = '';
  selecteprod = 0;
  newClientTagId = '';
  priorityClientTagId = '';
  firstOrdTagId = '';
  prePaidTagId= '';
  absDoubleAssignTagId = '';
  currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();
  selectAllTags: boolean = false;
  negativeTag: boolean = false;
  intialTag: boolean = true;
  selectedNegTags =[];
  isNegTagSelected: boolean = false;
  allNegativeTags = [];
  allActiveTagsBackUp=[];
  constructor(
    public auth: AuthService,
    private router: Router,
    private pagerService: PagerService,
    private config: ConfigService,
    private orderService: OrdersService,
    private preloaderService: PreloaderService,
    private pricingService: PricingService,
    private stateService: StateService,
    private socket: SocketService,
    private organizationService: OrganizationService,
    private matDialog:MatDialog,
    private scrollService: ScrollService,
    private tagService: TagsService,
    private _datePipe: DatePipe,
    private userService: UsersService,
    public ordersService: OrdersService,
    private userMsg: UsermessageService,
    private cookie:CookieService
  ) {
    this.pager.pages = [];
    if (this.pageNum == undefined)
      this.pageNum = 1;
    this.orderBy = 'DESC';
    this.sortField = 'Order_Creation_Date';
    this.searchString = '';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }



  ngOnInit() {
    // this.auth.startTimer();
    // this.getAvailableStateLocal();
    this.pricingService.getAvailableStateList().subscribe((states) => {
      this.states = states;
      this.filteredState.next(this.states.slice())
    });
    // this.getAvailableproductLocal();
    this.pricingService.getProductList().subscribe((products: any[]) => {
      this.products = products;
      this.filteredProduct.next(this.products.slice())
    });
    if (this.stateService.getNewOrders()) this.initializeComponentData(this.stateService.getNewOrders());
    else{
      this.getAllActiveTags()
      this.getOrders();
    } 
    this.orderStatus = this.orderService.getOrderStatus();
    //this.getOrders();
    this.socket.syncMessage("order").subscribe((result) => {
      console.log("CAUGHT EVENT EMITTED ON CREATE............")
      this.getOrdersOnBg();
    })
    this.getAdminOrganizationList();
    this.getAllOrganizationList();   ;
    if(this.config.getNewClientTagId()) this.newClientTagId = this.config.getNewClientTagId();
    if(this.config.getPriorityClientTagId()) this.priorityClientTagId = this.config.getPriorityClientTagId();
    if(this.config.getFirstOrdTagId()) this.firstOrdTagId = this.config.getFirstOrdTagId();
    if(this.config.getPrePaidTagId()) this.prePaidTagId = this.config.getPrePaidTagId();
    if(this.config.getAbsDoubleAssignTagId()) this.absDoubleAssignTagId = this.config.getAbsDoubleAssignTagId();
    // this.getAvailableTagLocal();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(1), 0))
  }

//   getAvailableproductLocal(){
//     let AllProd = JSON.parse(sessionStorage.getItem('AllProductAvailable'));
//     if(AllProd) this.setProd(AllProd)         
//     else this.getAvailableprodList();
// }
  
// getAvailableprodList(){
//   this.pricingService.getProductList().subscribe((Prod: any[]) => { 
//       if(Prod){
//         this.setProd(Prod)         
//         sessionStorage.setItem('AllProductAvailable',JSON.stringify(Prod)); 
//        setTimeout(function(){sessionStorage.removeItem("AllProductAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });

//   }

//   setProd(AllProdData){
//       if(AllProdData) this.products = AllProdData;
//       this.filteredProduct.next(this.products.slice())
//   }

//   getAvailableStateLocal(){
//     let Allstates = JSON.parse(sessionStorage.getItem('AllStateAvailable'));
//     if(Allstates) this.setState(Allstates)         
//     else this.getAvailableStateList();
// }
  
//   getAvailableStateList(){
//     this.pricingService.getAvailableStateList().subscribe((states) => { 
//       if(states){
//         this.setState(states)         
//         sessionStorage.setItem('AllStateAvailable',JSON.stringify(states)); 
//        setTimeout(function(){sessionStorage.removeItem("AllStateAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });

//   }

//   setState(AllstatesData){
//     if(AllstatesData) this.states = AllstatesData;
//     this.filteredState.next(this.states.slice())
//   }

//   getAvailableTagLocal(){
//     let AllTag = JSON.parse(sessionStorage.getItem('AllTagsAvailable'));
//     if(AllTag) this.getAllActiveTags(AllTag)         
//     else this.getAvailableTagList();
// }
  
//   getAvailableTagList(){
//     this.tagService.getAllActiveTagsForListing().subscribe((tags) => { 
//       if(tags){
//         this.getAllActiveTags(tags)         
//         sessionStorage.setItem('AllTagsAvailable',JSON.stringify(tags)); 
//        setTimeout(function(){sessionStorage.removeItem("AllTagsAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     }, (err) => {
//         this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
//     })
//   }

checkDemoFlag(event){
  if(event.checked == true) this.isDemoFlag = true;
  else  this.isDemoFlag = false;
  this.getOrders();
  }
  getAllActiveTags() {
    this.tagService.getAllActiveTagsForListing()
      .subscribe((activeTags) => {
        if (activeTags) {
          this.allActiveTags = []
          if (this.selectedTags.length > 0 || this.allNegativeTags.length>0) {
            activeTags.rows.forEach((chip) => {
              let index=this.allNegativeTags.indexOf(chip.Tag_ID);
              if(index == -1){
                index = this.selectedTags.indexOf(chip.Tag_ID);
                if (index > -1) chip.selected = true;
                this.allActiveTags.push(chip);
              }  
            })
          }else this.allActiveTags=activeTags.rows;   
          if(this.tagsOrderCount.length > 0) this.addOrderCountToTags(this.tagsOrderCount);
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  // getAllActiveTags(activeTags) {
  //       if (activeTags) {
  //         this.allActiveTags = activeTags.rows;
  //         if (this.selectedTags.length > 0) {
  //           this.allActiveTags.map((chip) => {
  //             var index = this.selectedTags.indexOf(chip.Tag_ID);
  //             if (index > -1) chip.selected = true;
  //           })
  //         }   
  //         if(this.tagsOrderCount.length > 0) this.addOrderCountToTags(this.tagsOrderCount);
  //       }
  // }

  addOrderCountToTags(tagsOrderCount) {
    tagsOrderCount.forEach((chip) => {
        this.allActiveTags.forEach((activeChip)=>{
            if(chip.Tag_ID == activeChip.Tag_ID) activeChip.Order_Count = chip.Order_Count;
        })
        this.allNegativeTags.forEach((negativeChip) => {
          if (chip.Tag_ID == negativeChip.Tag_ID) negativeChip.Order_Count = chip.Order_Count;
        })
    })
  }

  getFontColor(hexColor){
    return this.tagService.getFontColor(hexColor)  
  }
  onChipSelect(chip,i) {
    if(this.selectAllTags && this.negativeTag && Boolean(chip.selected)){
      this.onNegSelectionChange(chip, i);
    }else{
        chip.selected = !chip.selected;
        this.selectAllTags = true;
        this.negativeTag = true;
      this.onSelectionChange(chip);
    }
  }

  onNegSelectionChange(chip,i){
    var index = this.selectedNegTags.indexOf(chip.Tag_ID);
    if (index > -1) {
      this.selectedNegTags.splice(index, 1);
      this.allActiveTags.push(chip)
      chip.selected = !chip.selected
      if(this.allNegativeTags.length>0) this.allNegativeTags.splice(i,1)
      this.isNegTagSelected = true;
      this.pageNum = 1;
      this.setScrollResetPos()
      this.getOrders();
    } else{ 
      this.selectedNegTags.push(chip.Tag_ID);
      this.allActiveTags.splice(i,1)
      this.allNegativeTags.push(chip)
      this.allNegativeTags.map((chip) => {
          chip.deselected = true
        })
      this.isNegTagSelected = false;
      this.onSelectionChange(chip);
    }
  }
  onSelectionChange(chip) {
    var index = this.selectedTags.indexOf(chip.Tag_ID);
    if (index > -1) {
      this.selectedTags.splice(index, 1);
    } else this.selectedTags.push(chip.Tag_ID);
    this.pageNum = 1;
    this.setScrollResetPos();
    this.getOrders();
  }

  menuClosed(order) {
    this.searchTag = '';    
  }

  menuOpened(order) {
    this.orderTags = [];
    this.searchTag = '';
    this.getAvailableTags(order).map((tag) => tag.on = false)
    this.associatedTags= [];
  }

  setScrollResetPos() {
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosNewOrd(x);
  }

  getAvailableTags(order) {
    let preselectedTags = [];
    let availableTags = [];
    if (order.Tag_Names) preselectedTags = order.Tag_Names;
    for (var i = 0, len = this.allActiveTags.length; i < len; i++) {
      if (preselectedTags.findIndex((select => this.allActiveTags[i].Tag_ID == select.Tag_ID)) == -1) {
        if(this.allActiveTags[i].Tag_ID != this.newClientTagId && this.allActiveTags[i].Tag_ID != this.priorityClientTagId && this.allActiveTags[i].Tag_ID != this.firstOrdTagId && this.allActiveTags[i].Tag_ID != this.prePaidTagId ) availableTags.push(this.allActiveTags[i]);
      }
    }
    return availableTags;
  }

  openTagMenu(event, order) {
    event.stopPropagation();
    this.assignPreselectedTags(order);
  }

  assignPreselectedTags(order) {
    if (order.Tag_Names) this.associatedTags = order.Tag_Names
  }

  clearSearchTag() {
    this.searchTag = '';
  }

  emitOrdTagCreateEvt() {
    this.getAllActiveTags();
    this.getOrders();
  }

  openNewTagModal(order) {
    var selectedTagsCopy = Object.assign([], this.selectedTags);
    this.matMenuTrigger.closeMenu();
    this.matDialog.open(NewTagComponent, {data:{
      message: { Order_ID: order.Order_ID}
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if (res) {
        this.tagsOrderCount = [];
        this.getAllActiveTags();  
        // this.getAvailableTagLocal();
        // this.getTagCount();
        if(selectedTagsCopy.length > 0) this.getOrders();
        else {
          this.getOrderTags(order);
          // this.getTagCount();
          this.getOrders();
        }
      }
    });
  }

  getOrderTags(order){
    this.orderService.getOrderTags(order.Order_ID)
    .subscribe((ordTags) => {
      if (ordTags) {
        let getAllTagID = "";
        ordTags.forEach((tag) => {
          getAllTagID += "," + tag.Tag_ID;
        })
        order.Order_Tags = getAllTagID;
        order.Tag_Names = ordTags;
      }
      else order.Tag_Names = [];
    }, (err) => {
      this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
    })
   }

  doFilterOrganization() {
    if (!this.organizations || !this.allorganizations) return
    let search = this.orgFilterCtrl.value
    if (!search) {
      if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.admin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) this.filteredOrg.next(this.organizations.slice())
      if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) this.filteredOrg.next(this.allorganizations.slice())
      return
    } else {
      search = search.toLowerCase();
    }
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.admin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin)
      this.filteredOrg.next(
        this.organizations.filter(org => org.Organization_Name.toLowerCase().indexOf(search) > -1)
      );
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin)
      this.filteredOrg.next(
        this.allorganizations.filter(org => org.Organization_Name.toLowerCase().indexOf(search) > -1)
      );
  }

  doFilterState() {
    if (!this.states) return;
    let search = this.stateFilterCtrl.value
    if (!search) {
      this.filteredState.next(this.states.slice());
      return
    } else search = search.toLowerCase(); 
    this.filteredState.next(
      this.states.filter(state => state.State_Short_Name.toLowerCase().indexOf(search) > -1)
    )
  }

  doFilterCounty() {
    if (!this.counties) return;
    let search = this.countyFilterCtrl.value
    if (!search) {
      this.filteredCounty.next(this.counties.slice())
      return
    } else search = search.toLowerCase();
    this.filteredCounty.next(
      this.counties.filter(county => county.County_Name.toLowerCase().indexOf(search) > -1)
    )
  }

  doFilterProduct() {
    if (!this.products) return;
    let search = this.productFilterCtrl.value
    if (!search) {
      this.filteredProduct.next(this.products.slice());
      return
    } else search = search.toLowerCase();
    this.filteredProduct.next(
      this.products.filter(prod => prod.Product_Description.toLowerCase().indexOf(search) > -1)
    )
  }

  getAdminOrganizationList() {
    this.organizationService.addedOrganizationList(this.auth.getUserId())
      .subscribe((result) => {
        if (result) {
          this.organizations = result
          if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.admin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin)
            this.filteredOrg.next(this.organizations.slice())
        }
      }, (err) => {
        console.log("err", err);
        // this.errorMsg = err;
      })
  }

  getAllOrganizationList() {
    this.organizationService.getAllOrganizations()
      .subscribe((result) => {
        if (result) {
          this.allorganizations = result;
          if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) this.filteredOrg.next(this.allorganizations.slice())

        }
      }, (err) => {
        console.log("err", err);
        // this.errorMsg = err;
      })
  }


  // setPage(page: number) {
  //   console.log("Inside set page", page)
  //   if (page < 1 || page > this.pager.totalPages) {
  //     return;
  //   }
  //   this.pageNum = page;
  //   this.setDisplayOrders();
  //   this.getOrders();
  //   this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
  //   console.log("Pager", this.pager)
  // }

  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getOrders();
  }
  
  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.getOrders();
  }

  setDisplayOrders() {
    let start = ((this.pageNum - 1) * this.config.getNumNewOrdRecordsPerPage());
    if (this.totalCount != undefined) this.ordersToDisplay = this.orders.slice(start, this.totalCount);
    if (this.ordersToDisplay !== undefined && this.ordersToDisplay.length > this.config.getNumNewOrdRecordsPerPage()) this.ordersToDisplay = this.ordersToDisplay.slice(0, this.config.getNumNewOrdRecordsPerPage());
  }

  getCountyList(event) {
    if (event == 'all') {
      this.selectedCounty = 'all';
      if(this.selecteState != this.selectedState){
        this.selecteState='';
        this.applyFilter(true)
      }
      else this.applyFilter();
    }
    if (event.length != 0 && event != 'all') {     
    // this.selectedCounty = '';    
    
      this.pricingService.getAvailableCountyList(this.selectedState).subscribe((counties) => {
        this.counties = counties;
        this.filteredCounty.next(this.counties.slice())
      });
      if(this.selecteState != this.selectedState){
        this.selecteState='';
        this.applyFilter(true);
      }
      else this.applyFilter();
    }
  }

  applyFilter(filterFlag = false) {
    this.pageNum = 1;
    this.filterBy = [];
    if(!filterFlag){
      if(((this.selectedOrgId != this.selectedorg) || (this.selectecounty != this.selectedCounty) || (this.selecteprod!=this.selectedProduct))) {
        this.deselectAllChips();}
    }else this.deselectAllChips();
    if (this.selectedOrgId && this.selectedOrgId != 'all') this.filterBy.push({ Organization_ID: this.selectedOrgId })
    if (this.selectedOrgId2 && this.selectedOrgId2 != 'all') this.filterBy.push({ Organization_ID: this.selectedOrgId2 })
    if (this.selectedState && this.selectedState != 'all') this.filterBy.push({ Property_State_Abbr: this.selectedState })
    if (this.selectedCounty && this.selectedCounty != 'all') this.filterBy.push({ Property_County: this.selectedCounty })
    if (this.selectedProduct && this.selectedProduct != -1) this.filterBy.push({ Product_Description: this.selectedProduct })
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosNewOrd(x);
    if(!filterFlag){
      if(((this.selectedOrgId != this.selectedorg) || (this.selectecounty != this.selectedCounty) || (this.selecteprod!=this.selectedProduct))) {
        if(this.selectedOrgId != this.selectedorg) this.selectedorg='';
        if(this.selectecounty != this.selectedCounty) this.selectecounty='';
        if(this.selecteprod != this.selectedProduct) this.selecteprod=0; 
        this.getOrders();
      }
    }else this.getOrders();
  }

  getOrders() {
    if (this.pageNum == 1) {
      this.stateService.setNewOrders(this);
      let promiseCollection = [];
      promiseCollection.push(this.orderService.getNewOrders(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags,this.isDemoFlag))
      promiseCollection.push(this.orderService.getNewOrdersCount(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags,this.isDemoFlag))
      observableForkJoin(promiseCollection)
        .subscribe((result) => {
          if(result) this.getOrdersData(result);
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);         
        })
    } else {
      this.getRetainData();
    }
  }

  getRetainData() {
    var ObsCollection = [];
    let pageSize = this.pageNum * this.config.getNumNewOrdRecordsPerPage();
    ObsCollection.push(this.orderService.getNewOrders(1, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags,this.isDemoFlag, pageSize))
    // for (var i = 1; i <= this.pageNum; i++) {
    //   ObsCollection.push(this.orderService.getNewOrders(i, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags,this.isDemoFlag))
    //   // ObsCollection.push(this.orderService.getNewOrdersCount(i, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags,this.isDemoFlag))
    // }
    observableForkJoin(ObsCollection)
      .subscribe((retainedNewOrd) => {
        this.getRetainedNewOrd(retainedNewOrd);
        // this.getNewOrdersCount();
        this.getNewRetainedOrdersCount(pageSize);
      })
  }

  getNewRetainedOrdersCount(pageSize){
    this.orderService.getNewOrdersCount(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags,this.isDemoFlag, pageSize)
    .subscribe((newOrderCount) => {
    this.totalCount = newOrderCount.count;
    this.getOrdersRecordsData(this.totalCount);
    })
  }

  getNewOrdersCount(){
    this.orderService.getNewOrdersCount(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags,this.isDemoFlag)
    .subscribe((newOrderCount) => {
    this.totalCount = newOrderCount.count;
    this.getOrdersRecordsData(this.totalCount);
    })
  }

  getSearchString() {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }


  getUserRole() {
    return Number(this.auth.getUserRole())
  }

  getOrdersOnBg() {
    // this.pageNum = 1;
    if (this.pageNum == 1) {
      this.stateService.setNewOrders(this);
      this.orderService.getBackGroundNewOrders(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags)
        .subscribe((result) => {
         if(result) {
          let arrRes = [result]
          // this.getOrdersData(result);
          this.getOrdersData(arrRes);
        }
        })
    } else {
      this.getRetainDataBg()
    }
  }

  getRetainDataBg(){
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.orderService.getBackGroundNewOrders(i, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedNewOrd) => {
        this.getRetainedNewOrd(retainedNewOrd);
      })
  }

  goToOrderDetails(orderId) {
    if(this.checkAdminType()){
      var getPos = document.documentElement.scrollTop
      this.scrollService.setScrollPosNewOrd(getPos);
      this.router.navigate(['/orders/' + orderId + '/view'])
    }
  }

  searchField(search){
    this.scrollService.setScrollPosNewOrd(0)
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if(!format.test(this.searchString)&& this.searchString==" "){
      this.searchString='';
      this.getOrders();
    }else this.findOrders();
  }

  findOrders() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getOrders();
  }

  initializeComponentData(data) {
    let orderState={
      orderStatus : CONSTANTS.orderStatus.received,
      org_ID: data.selectedOrgId,
      state: data.selectedState,
      county: data.selectedCounty,
      prod: data.selectedProduct
 }
    this.orderService.getAssociatedTags(orderState)
    .subscribe((tags)=>{
    let filterdata = _.intersection(data.selectedTags, tags)
    this.selectedTags = filterdata;
    this.selectedorg = data.selectedOrgId;
    this.selecteState  = data.selectedState;
    this.selectecounty  = data.selectedCounty;
    this.selecteprod = data.selectedProduct; 
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.selectedOrgId = data.selectedOrgId;
    this.selectedOrgId2 = data.selectedOrgId2;
    this.selectedState = data.selectedState;
    this.selectedCounty = data.selectedCounty;
    this.selectedProduct = data.selectedProduct;
    this.filterBy = data.filterBy;
    this.counties = data.counties;
    // this.products = data.products;
    this.totalCount = data.totalCount;
    this.ordersToDisplay = data.ordersToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.isDemoFlag = data.isDemoFlag;
    this.selectAllTags = data.selectAllTags;
    this.allNegativeTags = data.allNegativeTags;
    this.negativeTag = data.negativeTag;
    this.selectedNegTags = data.selectedNegTags;
    this.isNegTagSelected = data.isNegTagSelected;
    this.intialTag = data.intialTag;
    this.allActiveTags = data.allActiveTags 
    this.getOrders();    
   })
  }


  ngOnDestroy() {
    // this.socket.unsubscribeMessage('order');
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumNewOrdRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }


  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {

    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      let promiseCollection = [];
      promiseCollection.push(this.orderService.getNewOrders(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags,this.isDemoFlag))
      promiseCollection.push(this.orderService.getNewOrdersCount(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags,this.isDemoFlag))
      observableForkJoin(promiseCollection)
      .subscribe((result: any) => {
          if (result && result.length > 0) {
            // this.config.resetShowFlag()
            this.totalCount = result[1].count
            if (this.totalCount > this.orders.length) {
              this.addCheckedProperty(result[0].rows);              
              this.orders = this.orders.concat(result[0].rows);
              this.pager = this.pagerService.getPageNewOrders(this.totalCount, this.pageNum);
                          }
          }
        })
    }
  }

  getOrdersRecordsData(result) {
    // this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setDisplayOrders();
    this.pager = this.pagerService.getPageNewOrders(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  getOrdersData(result){
    this.currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();
    this.orders = result[0].rows;
    if(result[1] !== undefined)
      this.totalCount = result[1].count;
    this.tagsOrderCount = result[0]['tagsWithOrderCount'];
    this.getOrdersRecordsData(this.totalCount);
    this.addOrderCountToTags(result[0]['tagsWithOrderCount']);
    this.ordsToassign=[];
    this.selectedOrgIds=[];
    this.addCheckedProperty(this.orders);
    this.getScrollPosData();
      }

  getScrollPosData(){
    if (this.scrollService.getScrollPosNewOrd()) {
      var x = this.scrollService.getScrollPosNewOrd()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }))
    }
  }

  getRetainedNewOrd(retainedNewOrd){
    var totalOrders = [];
    for (var i = 0; i < retainedNewOrd.length; i++) {
      this.stateService.setNewOrders(this);
      totalOrders = totalOrders.concat(retainedNewOrd[i]['rows']);
    }
    this.orders = totalOrders;
    // this.totalCount = retainedNewOrd[1]['count'];
    this.tagsOrderCount = retainedNewOrd[0]['tagsWithOrderCount'];    
    this.ordsToassign=[];
    this.selectedOrgIds=[];
    this.addCheckedProperty(this.orders);
    this.addOrderCountToTags(retainedNewOrd[0]['tagsWithOrderCount']);
    // this.getOrdersRecordsData(this.totalCount);
    this.getScrollPosData();
      }

  clearTags(){
    this.selectedTags = [];
    this.allActiveTags.map((chip) => chip.selected = false);
    this.selectedNegTags =[];
    this.allNegativeTags.forEach((negTag)=>{
      negTag.deselected = false;
      negTag.selected = false;
      this.allActiveTags.push(negTag)
    })
    this.allNegativeTags = [];
  }    
  resetFilter(){
    this.selectedState = '';
    this.selectedCounty = '';
    this.selectedProduct = 0;
    this.selectedOrgId = '';
    this.selectedOrgId2 = '';
    this.searchString = '';
    this.isDemoFlag = false;
    this.selectAllTags = false;
    this.negativeTag = false;
    this.isNegTagSelected = false;
    this.filterBy = [];
    this.counties = [];
    this.clearTags()
    this.getOrders();
  }

  deselectAllChips() {
    this.selectedTags = [];
    this.allActiveTags.map((chip) => chip.selected = false);
  }

  deselctAllNegTagsChip(){
    // this.selectedNegTags =[];
    // this.allNegativeTags.map((chip) => chip.deselected = false)
    // this.allNegativeTags.forEach((negTag)=>{
    //   this.allActiveTags.push(negTag)
    // })
    // this.allNegativeTags = [];
  } 
  checkIfFilterPresent(){
    if(this.selectedOrgId == 'all' || this.selectedOrgId2 == 'all' || this.selectedState == 'all' || this.selectedProduct == -1 || this.searchString || (this.filterBy.length > 0) || (this.selectedTags.length > 0) || (this.selectedNegTags.length > 0) || this.isDemoFlag) return true
    else return false
  }

  createExcel() {
    this.orderService.createNewordersExcelReport(this.filterBy, this.searchString, this.sortField, this.orderBy, this.selectedTags,this.selectedNegTags, this.isDemoFlag)
      .subscribe((blobData) => {
        if (blobData) this.composeAndDwnld(blobData);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  composeAndDwnld(blobData) {
     let file = new Blob([blobData], { type: 'application/octet-stream' });
    var fileUrl = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("download", "Report.xlsx");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }  

  csvExport() {

    const fields = [
      {
        label: 'Sl No',
        value: 'serialNumber'
      },
      {
        label: 'Pippin Order Number',
        value: 'Order_ID'
      },
      {
        label: 'Client Reference',
        value: 'File_ID'
      },
      {
        label: 'Client',
        value: (row, Client_User_Full_Name) => {
          if (row.Organization_Name) return row.Client_User_Full_Name+' - ' + row.Organization_Name;
          else return row.Client_User_Full_Name;
        }
      },
      {
        label: 'Qualia Order',
        value: 'qualiaOrder'
      },
      {
        label: 'Owner/Seller',
        value: 'Property_First_Name'
      },
      {
        label: 'Property Address',
        value: (row, Property_Address_1) => {
          var completeAddress = '';
          if (row.Property_Address_1) completeAddress += row.Property_Address_1
          else return completeAddress;
          if (row.Property_Address_2) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_Address_2
          }
          if (row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_City + ', ' + row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          if (!row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          return completeAddress;
        }
      },
      {
        label: 'State',
        value: 'Property_State_Abbr'
      },
      {
        label: 'County',
        value: 'Property_County'
      },
      {
        label: 'Status',
        value: (row, Order_Status) => {
          if (row.Order_Status == CONSTANTS.orderStatus.received) {
            return 'Received';
          } else if (row.Order_Status == CONSTANTS.orderStatus.confirmed) {
            return 'Confirmed';
          } else if (row.Order_Status == CONSTANTS.orderStatus.assigned) {
            return 'Assigned';
          } else if (row.Order_Status == CONSTANTS.orderStatus.cancelled) {
            return 'Cancelled';
          } else if (row.Order_Status == CONSTANTS.orderStatus.processing) {
            return 'Processing';
          } else if (row.Order_Status == CONSTANTS.orderStatus.completed) {
            return 'Completed';
          } else if (row.Order_Status == CONSTANTS.orderStatus.quotecompleted) {
          return 'Completed';
        }
        }
      },
      {
        label: 'Product Type',
        value: 'Product_Description'
      },
      {
        label: 'Submission Date',
        value: (row, Order_Creation_Date) => {
          return this._datePipe.transform(row.Order_Creation_Date_EST, 'MMM d, yyyy');
        }
      },
      {
        label: 'Submission Time (ET)',
        value: (row, Order_Creation_Date) => {
          return this._datePipe.transform(row.Order_Creation_Date_EST, 'h:mm a');
        }
      },
      {
        label: 'Priority Order',
        value: (row, Order_Creation_Date) => {
          if(row.Order_Priority == CONSTANTS.orderPriority.level2) return 'Yes';
          else return '';
        }
      },
      {
        label: 'Original ETA Date',
        value: (row, Order_Original_ETA) => {
          if (row.Order_Original_ETA) return this._datePipe.transform(row.Order_Original_ETA_EST, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Original ETA Time (ET)',
        value: (row, Order_Original_ETA) => {
          if (row.Order_Original_ETA) return this._datePipe.transform(row.Order_Original_ETA_EST, 'h:mm a');
          else return '';
        }
      },
      {
        label: 'Current Task Due Date',
        value: (row, Order_Internal_Due_Time) => {
          if (row.Order_Internal_Due_Time) return this._datePipe.transform(row.Order_Internal_Due_Time_EST, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Current Task Due Time (ET)',
        value: (row, Order_Internal_Due_Time) => {
          if (row.Order_Internal_Due_Time) return this._datePipe.transform(row.Order_Internal_Due_Time_EST, 'h:mm a');
          else return '';
        }
      },
      {
        label: 'Handled By',
        value: (row, Order_Admin_ID) => {
          if (row.Order_Admin_ID) return `${row.Admin_User_Full_Name}`;
          else return '';
        }
      },
      {
        label: 'Assigned Duration',
        value: (row, Order_Admin_ID) => {
            const duration = this.orderService.getAssignedDuration(row);
            return duration.replace(/[\(\)]/g, ''); // Remove parentheses
        }
      },
      {
        label: 'Searcher',
        value: (row, Order_Admin_ID) => {
          if (row.Order_Admin_ID && row.Abstrator_Price_ID) return 'Abstractor';
          else if (row.Order_Admin_ID) return 'Pippin';
          else return '';
        }
      },
      {
        label: 'Search Master Type',
        value: (row, Order_Admin_ID) => {
          if(row.Order_Admin_ID) {
            if(row.Admin_User_Role == CONSTANTS.userRole.searchMaster){
              if(row.Admin_User_Type == CONSTANTS.userType.internalSM || row.Admin_User_Type == null) return "Internal";
              else if(row.Admin_User_Type == CONSTANTS.userType.externalSM) return "External"
            }
          } else return "";
        }
      },
      {
        label: 'Tags',
        value: 'Order_Tag_Name'
      },
      // {
      //   label: 'ETA Delay Reason',
      //   value: (row, Order_ETA_Change_Reason) => {
      //     if (row.Order_ETA_Change_Reason) return row.Order_ETA_Change_Reason;
      //     else return '';
      //   }
      // },
      {
        label : 'Latest Note',
        value: (row, Latest_Note) => {
          if (row.Latest_Note) return row.Latest_Note;
          else return '';
        },
      }
    ];  
    if(this.checkForSuperRole()){
      fields.push({
        label: 'Price',
        value: (row, Order_Custom_Price) => {
          if(row.Order_Custom_Price) return row.Order_Custom_Price;
          else return '';
        }
      })
    }
    this.orderService.downloadCSVfile(fields, this.orders, this.allActiveTags)
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  checkIfAlreadyCompleted(orderStatusDet){
    let completedOrdIndex = orderStatusDet.findIndex((data) => data.Status_New === CONSTANTS.orderStatus.completed)
    if(completedOrdIndex > -1) return true;
    else return false;
  }

  modifyOrderTags(event, tag, order) {
    if(tag && tag.Tag_ID ==  this.config.getPartiallyDeliveredTagId()){      
      this.orderService.getAllOrderStatus(order.Order_ID)
      .subscribe((orderStatusDet) => {
        if(this.checkIfAlreadyCompleted(orderStatusDet)) this.modifyOrderTagsRequest(event, tag, order);
        else {
          this.openErrorPopup('This tag can only be assigned after an order has been completed at least once.');
          event.source.checked = false;
          this.matMenuTrigger.closeMenu();
        }
      })      
    }
    else this.modifyOrderTagsRequest(event, tag, order);    
  }

  modifyOrderTagsRequest(event, tag, order){
    var selectedTagsCopy = Object.assign([], this.selectedTags);
    if (this.selectedTags.length > 0) this.deselectAllChips();
    if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip();
     var isTagToAdd:boolean;
     if(event.checked) isTagToAdd = true;
     else isTagToAdd = false;
      this.setScrollResetPos();
      this.orderService.modifyOrderTags({ Order_ID: order.Order_ID, Order_Tag: tag, isTagToAdd: isTagToAdd})
      .subscribe((orderTagdata) => {
        order.Tag_Names=orderTagdata.Tag_Names;
        order.Order_Tags=orderTagdata.Order_Tags;
        if(selectedTagsCopy.length > 0) {
          this.matMenuTrigger.closeMenu();
          this.getOrders();
        }
        // else this.getTagCount();
        else this.getOrders();

      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderTagging);
        this.matMenuTrigger.closeMenu();
    })
   }


   emitOrdTagChangeEvt() {
     var selectedTagsCopy = Object.assign([], this.selectedTags);
    if (this.selectedTags.length > 0) this.deselectAllChips();
    if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip();
    if (selectedTagsCopy.length > 0) {
      this.getOrders();
      this.getAllActiveTags()
    }
    else this.getTagCount();
   }

   trackOrders(order){
    return order.Order_ID;
  }

  getTagCount(){
    this.orderService.getNewTagOrderCount(this.getSearchString(), this.filterBy)
    .subscribe((data) => {
     this.tagsOrderCount= data;
     this.addOrderCountToTags(data);
    }, (err) => {
      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
    })
  }

  onOrdSelectEvt(data) {
    this.onOrdSelect(data.event, data.ordId, data.order, data.checked)
  }

  onOrdSelect(event, ordId, order, checked) {
    event.stopPropagation();
    if(this.ordsToassign.includes(ordId)){
      var index = this.ordsToassign.indexOf(ordId);
      if (index > -1) {
        this.ordsToassign.splice(index, 1);
      }
    } else {
     this.ordsToassign.push(ordId);      
    }
    if(checked) {
      var index = this.selectedOrgIds.findIndex((filterItem) => filterItem.Order_ID === order.Order_ID);
      if(index > -1) {
        this.selectedOrgIds.splice(index, 1);
      }
    } else {
      this.selectedOrgIds.push(order);   
    }   

  }

  addCheckedProperty(orders) {    
    orders.map((order) => {
      order.checked=false;
      return order;
    })
  }

  assignSelectedOrds() {
    var data = {};
    if(this.selectedOrgIds.length > 0) data['Organization_IDs'] = this.selectedOrgIds;
    this.userService.getCommonAdminsNSearchers(data).subscribe((list) => {
        var dataToDialog = {
          Current_Admin:null,
          admins: list.adminList,
          Search_Masters: list.searcherList,
        };
        let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
          title: 'Screen order',
          message: dataToDialog
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }
              this.orderService.assignBulkOrds(this.ordsToassign, res.admin, null, res.msgText, msgType, res.getTimeToCompl, null, true)
                  .subscribe((data) => {
                    this.ordsToassign=[];
                    this.selectedOrgIds=[];
                    this.getOrders();
                    this.getRecivedOrderCountOnBg();
                  }, (err) => {
                    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                  })
            }
          });
    }, (err) =>{
      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
    })
  }  
    getRecivedOrderCountOnBg() {
    this.ordersService.getAllBackgroundReceivedOrderCount()
      .subscribe((data) => {
      }, (err) => {
      })
  }

  assignToScreener(order){
    var data = {};
    if(this.selectedOrgIds.length > 0) data['Organization_IDs'] = this.selectedOrgIds;
    if(order.Organization_ID) data['Organization_ID'] = order.Organization_ID;
    // this.userService.getAllOrgAdminsToOrderAssign(data).subscribe((adminData) => {
    //   this.userService.getAllOrgSearchMasterToOrderAsign(data).subscribe((searchData) => {
      this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
        let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
          title: 'Screen order',
          message: {
            admins: adminData['admins'],
            Search_Masters: adminData['searchers'],
            Current_Admin: order.Order_Admin_ID
          }
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }
              this.orderService.screenOrder(order.Order_ID, res.admin, res.msgText, msgType, res.getTimeToCompl)
              .subscribe((data) => {
              this.ordsToassign=[];
              this.selectedOrgIds=[];
              this.getOrders();
              this.getRecivedOrderCountOnBg();
                }, (err) => {
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
            }          
      }, (err) => { console.log(err); })
    })
  }

  changeScreener(order) {
    if(order.Order_Status == CONSTANTS.orderStatus.received) this.assignToScreener(order);
    else {
      var data = {};
      if(this.selectedOrgIds.length > 0) data['Organization_IDs'] = this.selectedOrgIds;
      if(order.Organization_ID) data['Organization_ID'] = order.Organization_ID;
      this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
      // this.userService.getAllOrgAdminsToOrderAssign(data).subscribe((adminData) => {
      //   this.userService.getAllOrgSearchMasterToOrderAsign(data).subscribe((searchData) => {
          let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
            title: 'Screen order',
            message: {
              admins: adminData['admins'],
              Search_Masters: adminData['searchers'],
              Current_Admin: order.Order_Admin_ID
            }
          }, ...this.config.getDialogOptions()}).afterClosed()
            .subscribe((res) => {
              if (res) {
                let msgType;
                if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
                else {
                  res.msgText = ""
                  msgType = CONSTANTS.messageType.internalNotes;
                }
               if(res.admin && res.getTimeToCompl){
                this.orderService.changeScreener(order.Order_ID, res.admin, res.msgText, msgType, res.getTimeToCompl)
                .subscribe((data) => {
                this.ordsToassign=[];
                this.selectedOrgIds=[];
                this.getOrders();
                this.getRecivedOrderCountOnBg();
                }, (err) => {
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
              }else{
                this.orderService.unassignOrder(order.Order_ID,res.msgText,msgType,order.Order_Status).subscribe((data)=>{
                  this.getOrders();
                  }, (err) => {
                    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
              }
              }            
        }, (err) => { console.log(err); })
      })
    }
  }
  resetAllValue(event){
     this.selectedCounty = '';    
  }

  afterTextCopy() {
    this.userMsg.setSuccessCopyMsg("Text copied successfully");
  }

  getFullAddress(orderDetails) {
    let completeAddress = '';
    if (orderDetails.Property_Address_1) completeAddress += orderDetails.Property_Address_1
    if (orderDetails.Property_Address_2) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += orderDetails.Property_Address_2
    }
    if (orderDetails.Property_City) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += orderDetails.Property_City
    }
    if (orderDetails.Property_State_Abbr) {
      if (completeAddress) completeAddress += ', '
      completeAddress += orderDetails.Property_State_Abbr
    }
    if (orderDetails.Property_ZipCode) {
      if (completeAddress) completeAddress += ' '
      return completeAddress += orderDetails.Property_ZipCode
    }
  }

  getClientName(order) {
    if(order.Organization_Name) return order.Client_User_Full_Name +" - "+ order.Organization_Name
    else return order.Client_User_Full_Name 
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }

  isSuperAdmin(){
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else if(this.auth.getUserId() == environment.superAdminId) return true;
    else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
    else return false;
  }

  checkForSuperRole(){
    if(this.auth.getUserId() == environment.superAdminId) return true;
    else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) return true;
    else return false;
  }
  
}




