// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styleHeight {
  height: 80px;
}

.modal-dialog-over {
  width: 40rem;
}

.modal-content {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/action-comment/action-comment.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACI,WAAA;AAAJ","sourcesContent":[".styleHeight{\n    height: 80px;\n}\n  \n  \n.modal-dialog-over{\n    width: 40rem;\n}\n\n.modal-content{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
