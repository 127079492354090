import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { RptBuilderService } from "../rpt-builder.service";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-rb-report",
  templateUrl: "./rb-report.component.html",
  styleUrls: ["./rb-report.component.scss"],
})
export class RbReportComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  sectionObject: any = {};
  orderDetails: any = {
    Order_ID: "",
    File_ID: "",
  };
  orgDetails:any = null;
  reportDetails: any = {};
  commitmentTemplates: any = [];
  reportBuilderTemplates: any = [];
  dataForm: UntypedFormGroup;
  getUSDate = new Date("01/02/1500").toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  min_Date = new Date(this.getUSDate);
  environment = environment;
  sopLink: any = "";
  itemData = {
    Applies: true,
    Id: null,
    Sp_Id: 0,
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private frmBuilder: UntypedFormBuilder,
    private builder: RptBuilderService
  ) {
    this.dataForm = this.frmBuilder.group({
      Search_Start_Date: [{ value: "", disabled: false }],
      Prior_Effective_Date: [{ value: "", disabled: false }],
      Effective_Date: [{ value: "", disabled: false }],
      Other_Information: ["", [Validators.maxLength(65535)]],
      Internal_Notes: ["", [Validators.maxLength(65535)]],
      Report_Notes: ["", [Validators.maxLength(65535)]],
      Title_Commitment_Status: [false],
      Include_Does_Not_Apply: [false],
      Builder_Templ:[null, [Validators.required]]
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.syncReportDetails(this.dataForm));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.Commitment_Typing_Templates)
          this.commitmentTemplates = data.Commitment_Typing_Templates;
          if (data && data.Report_Builder_Templates)
          this.reportBuilderTemplates = data.Report_Builder_Templates;
      });
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComponent(data));
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initOrderdata(data));
    this.builder.orgDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.orgDetails = data;
      });
    this.setFieldAsMarked("Effective_Date");
  }

  initComponent(data) {
    this.sectionObject = data;
    if (data && data.curData && data.curData.Parcel !== undefined) {
      this.reportDetails = data.curData;
      if(!this.reportDetails.Commit_Tmplt_Id) {
        this.reportDetails.Commit_Tmplt_Id = this.orgDetails&& this.orgDetails.Def_Comm_Temp_ID ? this.orgDetails.Def_Comm_Temp_ID : null
      }
      this.setSopLink(this.reportDetails.Commit_Tmplt_Id);
      this.itemData.Sp_Id = this.reportDetails.Id;
      this.dataForm.controls["Include_Does_Not_Apply"].patchValue(
        this.reportDetails.Include_Does_Not_Apply == 1,
        { emitEvent: false }
      );
      this.dataForm.controls["Title_Commitment_Status"].patchValue(
        this.reportDetails.Title_Commitment_Status == 1,
        { emitEvent: false }
      );
      if (
        this.orderDetails &&
        this.orderDetails.Order_ID !== undefined &&
        this.orderDetails.Order_ID !== ""
      )
        this.builder.manageError(
          `Report Data_${this.orderDetails.Order_ID}_0`,
          this.dataForm.status !== "VALID",
          this.dataForm.controls
        );
      this.cdr.detectChanges();
    }
  }

  initOrderdata(data) {
    if (data && data.Order_ID !== undefined) this.orderDetails = data;
    this.cdr.detectChanges();
    // this.syncReportDetails(this.dataForm);
  }

  getDoesNotApply(data) {
    this.reportDetails.Include_Does_Not_Apply = data ? 1 : 0;
  }

  getCommitStat(data) {
    this.reportDetails.Title_Commitment_Status = data ? 1 : 0;
  }

  getPippinDateEffective(event) {
    this.reportDetails.Effective_Date = event.Pippin_Date;
  }
  getPippinDatePriorEffective(event) {
    this.reportDetails.Prior_Effective_Date = event.Pippin_Date;
  }

  getPippinSearchStartDate(event) {
    this.reportDetails.Search_Start_Date = event.Pippin_Date;    
    if (event && event.Pippin_Date) {
      this.reportDetails.Years_Searched = moment().diff(
        moment(this.reportDetails.Search_Start_Date),
        "years"
      );
      if (!this.reportDetails.Years_Searched)
        this.reportDetails.Years_Searched = 0;
    }
  }

  saveToDb() {
    this.sectionObject.curData = this.reportDetails;
    this.sectionObject.Sp_Id = this.reportDetails.Id;
    this.sectionObject.Derived_From = this.reportDetails.Derived_From;
    this.builder.saveBasicData(this.sectionObject).pipe(take(1)).subscribe();
  }

  syncReportDetails(event) {
    if (
      this.orderDetails &&
      this.orderDetails.Order_ID !== undefined &&
      this.orderDetails.Order_ID !== ""
    )
      this.builder.manageError(
        `Report Data_${this.orderDetails.Order_ID}_0`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine) {
      this.reportDetails = this.builder.trimInput(event, this.reportDetails);
      this.saveToDb();
    }
  }

  setSopLink(tmplateId) {
    let template = this.commitmentTemplates.find((ct) => ct.Id == tmplateId);
    if (template !== undefined) this.sopLink = template.SOP_Link;
  }

  commitmentChange(tmplateId) {
    this.setSopLink(tmplateId);
    this.saveToDb();
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "RPT_DT",
      this.reportDetails.Id,
      fieldName
    );
  }

  setFieldAsMarked(fieldKey){
    var keyIndex = Object.keys(this.dataForm.controls).findIndex((key) => key == fieldKey);
    if (keyIndex > -1) {
      let key =  Object.keys(this.dataForm.controls)[keyIndex] 
      this.dataForm.get(key).markAsTouched();
    }    
  }
}
