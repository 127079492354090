// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge {
  font-weight: normal !important;
  font-size: 12px !important;
  background-color: transparent;
}

tbody tr:hover .fontColor {
  color: white;
}

div.tagsColor {
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
}
div.tagsColor .mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
  border: 2px solid #143306 !important;
  color: black;
}

i.fa.fa-trash {
  display: none !important;
}

mat-form-field:hover i.fa.fa-trash {
  display: inline-block !important;
}

.overlay_count {
  z-index: 9;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/order-search/order-search.component.scss"],"names":[],"mappings":"AACA;EACI,8BAAA;EACA,0BAAA;EACA,6BAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACI,4HAAA;AAAJ;AAKI;EACI,oCAAA;EACA,YAAA;AAHR;;AAOA;EACI,wBAAA;AAJJ;;AAQI;EACM,gCAAA;AALV;;AASA;EACI,UAAA;AANJ","sourcesContent":["@import '../../../scss/variables';\n.badge {\n    font-weight: normal !important;    \n    font-size: 12px !important;\n    background-color:transparent;\n}\n\ntbody tr:hover .fontColor {\n    color:white\n}\n\ndiv.tagsColor {\n    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */\n    // mat-chip.mat-mdc-standard-chip.mat-chip-selected.mat-primary { \n    //     border: 3px solid #2196f3 !important;\n    //     color:black; \n    // }  \n    .mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {\n        border: 2px solid  #143306 !important;\n        color:black; \n      } \n}\n\ni.fa.fa-trash {\n    display: none !important ;\n  }\n\n  mat-form-field:hover {\n    i.fa.fa-trash {\n          display: inline-block !important;\n        }\n      }\n\n.overlay_count{\n    z-index: 9;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
