// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-tag {
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  vertical-align: text-bottom;
}

.badgeColor {
  background-color: #60cc70;
  border-color: #0F9D58;
}`, "",{"version":3,"sources":["webpack://./src/app/groups/edit-group/edit-group.component.scss"],"names":[],"mappings":"AAEA;EACI,oBAAA;EACA,uBAAA;EACA,2BAAA;AADJ;;AAIE;EACE,yBAAA;EACA,qBAAA;AADJ","sourcesContent":["@import \"../../../scss/variables\";\n\n.add-tag {\n    padding-top: 0.45rem;\n    padding-bottom: 0.45rem;\n    vertical-align: text-bottom;\n  }\n\n  .badgeColor {\n    background-color: #60cc70;\n    border-color: #0F9D58;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
