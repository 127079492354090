import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'testMask'
})
export class TestMaskPipe implements PipeTransform {

  transform(plainText: string, visibleChars: number = 4): string {
    let maskedSection = plainText.slice(0, -visibleChars);
    let visibleSection = plainText.slice(-visibleChars);
    return maskedSection.replace(/./g, '*') + visibleSection;
  }

}
