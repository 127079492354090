
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {mergeMap, catchError, map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
// import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class QuickbooksService {

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient,
    @Inject(SESSION_STORAGE) private storage: StorageService,
    @Inject(DOCUMENT) private document: any
  ) { }

  setSharedSessionData(data){
    if(data)this.storage.set('app_quickbook_success', JSON.stringify(data))
  }

  getSharedSessionData(){
    var data = this.storage.get('app_quickbook_success');
    if(data) return JSON.parse(data);
    else return null;
  }

  resetSharedSessionData(){
    this.storage.remove('app_quickbook_success');
  }

  generateAccessToken(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    data.User_ID = this.auth.getUserId();
    let url = this.config.getBasePath() + '/quickbooks/auth/getAccessToken';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getUserToken(){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/quickbooks/auth/getAccessToken/' +  this.auth.getUserId();
    return this.http.get(url, httpOptions);
  }

  getOrderInvoice(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/quickbooks/invoice/' + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  downloadOrderInvoice(orderId, data, screen) {
    if (!this.getSharedSessionData()) {
      return this.getUserToken().pipe(
        mergeMap((response: any) => {
          var parsedResponse = response;
          if (!parsedResponse.status) {
            this.setSharedSessionData({ url: '/orders/' + orderId + '/view', data: data, continue: 'download_invoice', screen: screen });
            this.document.location.href = parsedResponse.url;
            return Promise.resolve(0);
          }
          else return this.directDownload(orderId);
        }),
        catchError((error: any) => observableThrowError(error.error)),);
    }
    else return this.directDownload(orderId);
  }


  directDownload(orderId) {
    this.resetSharedSessionData();
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType:'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/quickbooks/invoice/' + orderId + '/pdf';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError(err => {
        if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === "application/json") {           
            return new Promise<any>((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = (e: Event) => {
                    try {
                        const errmsg = JSON.parse((<any>e.target).result);
                        reject(new HttpErrorResponse({
                            error: errmsg,
                            headers: err.headers,
                            status: err.status,
                            statusText: err.statusText,
                            url: err.url
                        }));
                    } catch (e) {
                        reject(err);
                    }
                };
                reader.onerror = (e) => {
                    reject(err);
                };
                reader.readAsText(err.error);
            });
        }
        return observableThrowError(err);
    }));
  }  

}
