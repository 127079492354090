import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class RoleGuardService   {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    return this.auth.canSuperAdminActivate()
    .pipe(
      map((loggedIn) => { return true; }),
      catchError((error) => {
        this.auth.logout();
        this.auth.setRedirectURL(state.url);
        this.router.navigate(['/login']);
        return new Promise((resolve, reject) => resolve(error));
      }),
  );
 }   

}
