import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { QuickbooksService } from '../services';

@Component({
  selector: 'app-oauth-success',
  templateUrl: './oauth-success.component.html',
  styleUrls: ['./oauth-success.component.scss']
})
export class OauthSuccessComponent implements OnInit {

  status: string = 'pending';
  constructor(
    private router: Router,
    public quickbooks: QuickbooksService,
    private activateRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {        
        if (params['state'] && params['code']) {
          this.quickbooks.generateAccessToken({ redirectURL: this.router.url })
            .subscribe((data) => {
              this.status = 'successful';
              if(this.quickbooks.getSharedSessionData()){
                if(this.quickbooks.getSharedSessionData().queryParams) this.router.navigate([this.quickbooks.getSharedSessionData()['url']], this.quickbooks.getSharedSessionData().queryParams);
                else this.router.navigate([this.quickbooks.getSharedSessionData()['url']]);
              }              
            })
        }
        else {
          this.status = 'failed';
          if(this.quickbooks.getSharedSessionData()){
            if(this.quickbooks.getSharedSessionData().queryParams) this.router.navigate([this.quickbooks.getSharedSessionData()['url']], this.quickbooks.getSharedSessionData().queryParams);
            else this.router.navigate([this.quickbooks.getSharedSessionData()['url']]);
          }
          this.quickbooks.resetSharedSessionData();
        }
      }  
      else this.router.navigate(['/orders/new']);   
    })
  }
  
}
