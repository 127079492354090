import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Validator, AbstractControl, Validators, NG_VALIDATORS } from '@angular/forms';
import { NoWhitespaceValidator } from '../../services/validator/validator.service';

@Directive({
  selector: '[appNoWhitespace]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NoWhitespaceDirective, multi: true }]
})
export class NoWhitespaceDirective implements Validator {

  private valFn = NoWhitespaceValidator();

  validate(control: AbstractControl) {
    if (this.valFn(control)) {
      return { noWhiteSpace: true };
    } else {
      if (control.errors) delete control.errors['noWhiteSpace'];
      if (control.errors && !Object.keys(control.errors).length) control.setErrors(null);
    }
    return null;
  }
}