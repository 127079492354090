import { Component, OnDestroy, OnInit } from "@angular/core";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { OrdersService } from "app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
const TAXSTATUS = {
  1: "Paid",
  2: "Due",
  3: "Past Due",
  4: "Delinquent",
  5: "Not yet assessed",
  6: "Unknown",
  7: "Not applicable",
};

@Component({
  selector: "app-chk-taxes",
  templateUrl: "./chk-taxes.component.html",
  styleUrls: ["./chk-taxes.component.scss"],
})
export class ChkTaxesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  propDet: any;
  assessments: any = [];
  taxes: any = [];
  taxDisplayArray: any = [];
  assCnt = 0;
  taxCnt = 0;
  assessorURL = null;
  taxURL = null;
  orderId: any;

  constructor(
    private builder: RptBuilderService,
    private ordersService: OrdersService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) this.propDet = data.curData;
      });
    this.builder.assessments$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) {
          this.assessments = data.curData;
          this.assCnt = data.curData.length;
        }
      });

    this.builder.taxes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) {
          this.taxes = data.curData;
          this.cleanseTaxes();
          this.taxCnt = data.curData.length;
        }
      });
    this.ordersService.searchInfo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.getButtonLinks(data));
    this.ordersService.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderId = data.Order_ID));
  }

  cleanseTaxes() {
    this.taxDisplayArray = [];
    this.taxes.forEach((tax) => {
      if (tax.Entity_ID == 1) {
        if (tax.Annual_Amount)
          this.taxDisplayArray.push({
            text: `${tax.Year} - Annual - `,
            amount: `$${tax.Annual_Amount} (${
              TAXSTATUS[tax.Status]
            }) `,
          });
      }
      if (tax.Entity_ID == 2) {
        if (tax.Fst_Semi_Annual_Amount)
          this.taxDisplayArray.push({
            text: `${tax.Year} - 1st Semi-Annual - `,
            amount: `$${tax.Fst_Semi_Annual_Amount} (${
              TAXSTATUS[tax.Fst_Semi_Annual_Paid_Status_Type_ID]
            }) `,
          });
        if (tax.Snd_Semi_Annual_Amount)
          this.taxDisplayArray.push({
            text: `${tax.Year} - 2nd Semi-Annual - `,
            amount: `$${tax.Snd_Semi_Annual_Amount} (${
              TAXSTATUS[tax.Snd_Semi_Annual_Paid_Status_Type_ID]
            }) `,
          });
      }
      if (tax.Entity_ID == 3) {
        if (tax.Fst_Quarter_Amount)
          this.taxDisplayArray.push({
            text: `${tax.Year} - 1st Quarter - `,
            amount: `$${tax.Fst_Quarter_Amount} (${
              TAXSTATUS[tax.Fst_Quarter_Paid_Status_Type_ID]
            }) `,
          });
        if (tax.Snd_Quarter_Amount)
          this.taxDisplayArray.push({
            text: `${tax.Year} - 2nd Quarter - `,
            amount: `$${tax.Snd_Quarter_Amount} (${
              TAXSTATUS[tax.Snd_Quarter_Paid_Status_Type_ID]
            }) `,
          });
        if (tax.Thrd_Quarter_Amount)
          this.taxDisplayArray.push({
            text: `${tax.Year} - 3rd Quarter - `,
            amount: `$${tax.Thrd_Quarter_Amount} (${
              TAXSTATUS[tax.Thrd_Quarter_Paid_Status_Type_ID]
            }) `,
          });
        if (tax.Frth_Quarter_Amount)
          this.taxDisplayArray.push({
            text: `${tax.Year} - 4th Quarter - `,
            amount: `$${tax.Frth_Quarter_Amount} (${
              TAXSTATUS[tax.Frth_Quarter_Paid_Status_Type_ID]
            }) `,
          });
      }
    });
  }

  getButtonLinks(data) {
    this.assessorURL = null;
    this.taxURL = null;
    if (data.length) {
      if (data[0].Assessor && data[0].Assessor.length)
        this.assessorURL = data[0].Assessor[0];
      if (data[0].Tax && data[0].Tax.length) this.taxURL = data[0].Tax[0];
    }
  }
}
