import { Component, OnInit } from '@angular/core';
import { AuthService, ConfigService, ScrollService, OrdersService, UsermessageService, StateService, PagerService, PreloaderService } from 'app/services';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as momentTimeZone from 'moment-timezone';
import { CONSTANTS } from '../../app.constants'
import { DialogService } from 'ng2-bootstrap-modal';
import { ErrorDialogComponent } from 'app/dialog/error-dialog/error-dialog.component';
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  timer: any = [];
  searchString: string;
  searchQuery = new Subject<string>();
  srollDistance=this.config.setScrollDistance();
  srollThrottle=this.config.setScrollThrottle();
  scrollEnable: boolean = true;
  totalCount: number;
  sortField: string;
  orderBy: string;
  pageNum: number;
  pager: any = {};
  billingOrders: any;
  constants = CONSTANTS
  orderStatus: any = {};
  ordersToDisplay: any;
  searchInProgress: boolean = false;
  currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();

  constructor(private config: ConfigService, private scrollService: ScrollService, private stateService:StateService,
    private pagerService: PagerService,private matDialog:MatDialog,private router: Router, 
    private _datePipe: DatePipe,public orderService: OrdersService, private userMsg: UsermessageService, 
    private preloaderService: PreloaderService,
    private auth: AuthService,
    ) { 
      this.pager.pages = [];
      if (this.pageNum == undefined) this.pageNum = 1;
      this.orderBy = 'DESC';
      this.sortField = 'Order_Completion_Date';
      this.searchString = '';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    this.timer.push(setTimeout(() => this.config.setSideBarNode(28), 0));
    if (this.stateService.getNonBillingOrders()) {
      this.initializeComponentData(this.stateService.getNonBillingOrders());
    }
    else this.getOrders();
    this.orderStatus = this.orderService.getOrderStatus();
  }

  searchField(search){
    this.scrollService.setScrollPosBillingOrder(0);
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if(!format.test(this.searchString)&& this.searchString==" "){
      this.searchString='';
      this.getOrders();
    }else this.findOrders();
  }

  findOrders() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getOrders();
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.orderService.getNonBillingOrders(this.pageNum, this.sortField, this.orderBy, this.getSearchString())
        .subscribe((result) => {
          if (result) {
            this.totalCount = result['count'];
            if (this.totalCount > this.billingOrders.length) {
              this.billingOrders = this.billingOrders.concat(result['rows']);
              this.pager = this.pagerService.getPageOrderSearchandComplete(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.totalCount = data.totalCount;
    this.ordersToDisplay = data.ordersToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.getOrders();
  }

  handleEvent(event){
    if(event ==false){
    if(this.totalCount > this.config.getNumRecordsPerPage()){
      if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
      else this.scrollEnable = true;
    }
  }else this.scrollEnable = true;
  }

  getOrders() {
    if(this.pageNum == 1) {
      this.stateService.setNonBillingOrders(this);
      this.orderService.getNonBillingOrders(this.pageNum, this.sortField, this.orderBy, this.getSearchString())
      .subscribe((data) => {
        if(data){
          this.billingOrders = data.rows;
          this.totalCount = data.count;
          this.getNonBillingData(this.totalCount)
          this.getScrollPosData()
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
    }else this.getRetainData()
  }

  getRetainData() {
    var ObsCollection = [];
    let pageSize = this.pageNum * this.config.getnoOrderSearchandComplete();
    ObsCollection.push(this.orderService.getNonBillingOrders(1, this.sortField, this.orderBy, this.getSearchString(), pageSize))
    // for (var i = 1; i <= this.pageNum; i++) {
    //   ObsCollection.push(this.orderService.getNonBillingOrders(i, this.sortField, this.orderBy, this.getSearchString()))
    // }
    observableForkJoin(ObsCollection)
      .subscribe((retainedComplOrd) => {
        if (retainedComplOrd) {
          var totalOrders = [];
          for (var i = 0; i < retainedComplOrd.length; i++) {
            this.stateService.setNonBillingOrders(this)
            totalOrders = totalOrders.concat(retainedComplOrd[i]['rows']);
          }
          this.billingOrders = totalOrders;
          this.totalCount = retainedComplOrd[0]['count'];
          this.getNonBillingData(this.totalCount)
          this.getScrollPosData()
        }
      })
  }

  getNonBillingData(result) {
    this.config.setClearTimeout();
    this.setDisplayOrders();
    this.pager = this.pagerService.getPageOrderSearchandComplete(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }
  
  getScrollPosData() {
    if (this.scrollService.getScrollPosBillingOrder()) {
      var x = this.scrollService.getScrollPosBillingOrder()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }));
    }
  }

  getSearchString() {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }


  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getOrders();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.getOrders();
  }

  goToOrderDetails(orderId) {
    if(this.checkAdminType()){
      var getPos = document.documentElement.scrollTop;
      this.scrollService.setScrollPosBillingOrder(getPos);
      this.router.navigate(['/orders/' + orderId + '/view'])
    }
  }

  trackOrders(order){
    return order.Order_ID;
  }

  afterTextCopy() {
    this.userMsg.setSuccessCopyMsg("Text copied successfully");
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  getFullAddress(order){
    let completeAddress = '';
    if (order.Property_Address_1) completeAddress += order.Property_Address_1
    if (order.Property_Address_2) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += order.Property_Address_2
    }
    if (order.Property_City) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += order.Property_City
    }
    if (order.Property_State_Abbr) {
      if (completeAddress) completeAddress += ', '
      completeAddress += order.Property_State_Abbr
    }
    if (order.Property_ZipCode) {
      if (completeAddress) completeAddress += ' '
      return completeAddress += order.Property_ZipCode
    }
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  setDisplayOrders() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    if (this.totalCount != undefined) this.ordersToDisplay = this.billingOrders.slice(start, this.totalCount);
    if (this.ordersToDisplay.length > this.config.getNumRecordsPerPage()) this.ordersToDisplay = this.ordersToDisplay.slice(0, this.config.getNumRecordsPerPage());
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  setScrollResetPos() {
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosBillingOrder(x);
  }

  resetFilter(){
    this.searchString = '';
    this.getOrders();
  }

  
  checkIfFilterPresent(){
    if(this.searchString) return true
    else return false
  }

  csvExport() {

    const fields = [
      {
        label: 'Sl No',
        value: 'serialNumber'
      },
      {
        label: 'Pippin Order Number',
        value: 'Order_ID'
      },
      {
        label: 'Client Reference',
        value: 'File_ID'
      },
      {
        label: 'Client',
        value: (row, User_First_Name) => {
          if (row.Organization_Name) return row.Client_User_Full_Name + ' - ' + row.Organization_Name;
          else return row.Client_User_Full_Name;
        }
      },
      {
        label: 'Qualia Order',
        value: 'qualiaOrder'
      },
      {
        label: 'Owner/Seller',
        value: 'Property_First_Name'
      },
      {
        label: 'Property Address',
        value: (row, Property_Address_1) => {
          var completeAddress = '';
          if (row.Property_Address_1) completeAddress += row.Property_Address_1
          else return completeAddress;
          if (row.Property_Address_2) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_Address_2
          }
          if (row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_City + ', ' + row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          if (!row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          return completeAddress;
        }
      },
      {
        label: 'State',
        value: 'Property_State_Abbr'
      },
      {
        label: 'County',
        value: 'Property_County'
      },
      {
        label: 'Status',
        value: (row, Order_Status) => {
          if (row.Order_Status == CONSTANTS.orderStatus.cancelled) {
            return 'Cancelled';
          }else if (row.Order_Status == CONSTANTS.orderStatus.completed) {
            return 'Completed';
          }
        }
      },
      {
        label: 'Product Type',
        value: 'Product_Description'
      },
      {
        label: 'Completed Date',
        value: (row, Order_Completion_Date) => {
          if (row.Order_Completion_Date) return this._datePipe.transform(row.Order_Completion_Date_EST, 'MMM d, yyyy');
          else return '';
        }
      },  
      {
        label: 'Completed Time (ET)',
        value: (row, Order_Completion_Date) => {
          if (row.Order_Completion_Date) return this._datePipe.transform(row.Order_Completion_Date_EST, 'h:mm a');
          else return '';
        }
      },    
      {
        label: 'Cancellation Date',
        value: (row, Order_Cancellation_Date) => {
          if (row.Order_Cancellation_Date) return this._datePipe.transform(row.Order_Cancellation_Date_EST, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Cancellation Time (ET)', 
        value: (row, Order_Cancellation_Date) => {
          if (row.Order_Cancellation_Date) return this._datePipe.transform(row.Order_Cancellation_Date_EST, 'h:mm a');
          else return '';
        }
      },
      {
        label: 'Handled By',
        value: (row, Order_Admin_ID) => {
          if(row.Order_Admin_ID) return `${row.Admin_User_Full_Name} ${this.orderService.getLastAssignedDuration(row, this.currTime)}`;
            else return '';
        }
      },
      {
        label: 'Searcher',
        value: (row, Order_Admin_ID) => {
          if (row.Order_Admin_ID && row.Abstrator_Price_ID) return 'Abstractor';
          else if (row.Order_Admin_ID) return 'Pippin';
          else return '';
        }
      },
      {
        label: 'Assigned Abstractor',
        value: (row, Abstrator_ID) => {
          if(row.Order_Admin_ID && row.Abstrator_Price_ID) return row.Abstractor_User_Full_Name;
          else return '';
        }
      },
      {
        label: 'Priority Order',
        value: (row, Order_Creation_Date) => {
          if(row.Order_Priority == CONSTANTS.orderPriority.level2) return 'Yes';
          else return '';
        }
      },
      {
        label : 'Latest Note',
        value: (row, Latest_Note) => {
          if (row.Latest_Note) return row.Latest_Note;
          else return '';
        },
      }
    ];  

    this.orderService.downloadCSVfile(fields, this.billingOrders, null)
  }

  createExcel() {
    this.orderService.createNonBillingOrdReport(this.searchString, this.sortField, this.orderBy )
      .subscribe((blobData) => {
        if (blobData) this.composeAndDwnld(blobData);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  composeAndDwnld(blobData) {
    let file = new Blob([blobData], { type: 'application/octet-stream' });
   var fileUrl = URL.createObjectURL(file);
   var link = document.createElement("a");
   link.setAttribute("href", fileUrl);
   link.setAttribute("download", "Report.xlsx");
   link.style.visibility = "hidden";
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
 }

 checkAdminType() {
  if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
  else return true;
}

}
