// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-bordered,
.table-bordered > tbody > tr > td {
  border: none;
  position: relative;
}

.table > tbody > tr:nth-of-type(even) {
  background-color: transparent;
}

.table > tbody > tr:nth-of-type(even):hover {
  background-color: rgb(66, 139, 202);
}

tbody tr:hover {
  background-color: transparent !important;
  cursor: initial !important;
  color: black !important;
}

textarea.mat-mdc-input-element {
  overflow-y: hidden !important;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/order-pricing-edit/order-pricing-edit.component.scss"],"names":[],"mappings":"AAEA;;EAEI,YAAA;EACA,kBAAA;AADJ;;AAIA;EACI,6BAAA;AADJ;;AAIA;EACI,mCAAA;AADJ;;AAIA;EACI,wCAAA;EACA,0BAAA;EACA,uBAAA;AADJ;;AAIA;EACI,6BAAA;AADJ","sourcesContent":["@import '../../../scss/variables';\n\n.table-bordered ,\n.table-bordered>tbody>tr>td {\n    border: none;\n    position: relative;\n}\n\n.table>tbody>tr:nth-of-type(even) {\n    background-color: transparent;\n}\n\n.table>tbody>tr:nth-of-type(even):hover {\n    background-color: rgb(66, 139, 202);\n}\n\ntbody tr:hover {\n    background-color:transparent !important;\n    cursor: initial !important;\n    color: black !important;\n}\n\ntextarea.mat-mdc-input-element {\n    overflow-y: hidden !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
