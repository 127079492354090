
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
// import { HttpService } from '../interceptor/http.service';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { CONSTANTS } from '../../app.constants';

@Injectable()
export class PricingService {
  direct: HttpClient;
  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient,
    private handler: HttpBackend
  ) { 
    this.direct = new HttpClient(this.handler);
  }

  // storePricingToDatabase(counties, productPrices) {
  //   console.log("productPrices", productPrices);
  //   let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
  //   let options = new RequestOptions({ headers: headers });
  //   let url = this.config.getBasePath() + '/pricings/upload/admin/' + this.auth.getUserId();
  //   let dataToSend: any = {};
  //   dataToSend.states = counties;
  //   dataToSend.prices = productPrices;
  //   return this.http.post(url, dataToSend, options)
  //     .map((response: Response) => {
  //       console.log("response", response);
  //       return response.json();
  //     })
  //     .catch((error: any) => Observable.throw(error));
  // }

  sendExcelPriceSheetToDb(excelFile, headerKeys, customerId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/price/upload/admin/' + this.auth.getUserId();
    if(customerId) url += '/' +customerId;
    // let dataToSend: any = {};
    // dataToSend.excel = excelFile;
    // dataToSend.productIds = headerKeys;
    let formData: FormData = new FormData();
    formData.append('file', excelFile, excelFile.name);
    for (var key in headerKeys) {
      formData.append(key, headerKeys[key]);
    }
    // if(customerId) formData.append('customerId', customerId);
    return this.http.post(url, formData, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createSinglePricing(model) {    
    model.User_ID = this.auth.getUserId();
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/pricings/create/new';    
    return this.http.post(url, model, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createSingleCustomPricing(model) {    
    model.User_ID = this.auth.getUserId();
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/pricings/custom/new';    
    return this.http.post(url, model, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllPricings(searchStr, page,sortBy,orderBy,filterBy) {
    var data={
      filterBy:filterBy
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/all';
    url = url + '/page/' + page;
    if(sortBy != ''){
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) url = url + '/search/' + searchStr
    return this.http.post(url, data,httpOptions).pipe(
      map((response: any) => {
        // console.log("response" ,response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllCustomPricings(customerId,searchStr, page,sortBy,orderBy,filterBy) {
    var data={
      filterBy:filterBy
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/all/customer/'+customerId ;
    url = url + '/page/' + page;
    if(sortBy != ''){
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) url = url + '/search/' + searchStr
    return this.http.post(url, data,httpOptions).pipe(
      map((response: any) => {
        // console.log("response" ,response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateProductCost(costId, model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/pricings/' + costId + '/update';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateCustomPricing(costId, model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/pricings/custom/' + costId + '/update';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getProductCost(costId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/' + costId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getCustomPricingCost(costId,userId) {
    var data ={
      Cost_ID:costId,
      Customer_ID:userId
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/custom/pricing/details';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getStateList() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/states/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getCountyList(state) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/state/' + state + '/counties/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);    
  }

  getCountyDirectList(state) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/state/' + state + '/counties/all';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);    
  }

  getProductList() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/active/products';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAvailableProductList(state,county) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/state/'+ state +'/county/'+county;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAvailableStateList() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/pricings/states/available/all';
    return this.http.get(url, httpOptions).pipe(
      map((response:any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getCountiesByGivenStates(states) {
    var data ={
      states:states,
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/get/counties';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAvailableCountyList(state) { 
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/state/' + state + '/counties/available/all';
    return  this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        // console.log("COUNTIES", response);
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);    
  }

  getCustomAndOrginalPrice(state,county,productId,customerId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/custom/'+ customerId +'/state/' + state + '/county/' + county + '/product/' + productId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);    
  }

  getCustomAndOrginalPriceDetails(state,county,productId,customerId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/details/custom/'+ customerId +'/state/' + state + '/county/' + county + '/product/' + productId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);    
  }

  updateCustomCostStatus(data,status) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    var action = status==true?'enable':'disable' ;  
    let url = this.config.getBasePath() + '/pricings/custom/'+ action;
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);    
  }
 
  getCustomerListForCustomPricing() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    } 
    let url = this.config.getBasePath() + '/pricings/clients/list';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);    
  }

  getIndpendentCities(){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/state/all/independent/city';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAdditionalCompanyProducts(orgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/mapping/' + orgId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }
}
