import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent  {

  @ViewChild('okBtn') focusedElement: ElementRef;

  message = '';
  title = '';
  timer: any = [];
  breakWordFlag: boolean = false;
  constructor( public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => { this.focusedElement.nativeElement.focus(); }, 0));
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }
 
  confirm() {
     this.dialogRef.close(true);
    this.clearTime();
  }

  Close() {
     this.dialogRef.close(false);
    this.clearTime()
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
}
