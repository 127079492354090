import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { AuthService, AbstractorService, OrdersService, QuickbooksService } from '../../services';
import { AutogrowDirective } from '../../directives';
import { CONSTANTS } from 'app/app.constants';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-complete-order',
  templateUrl: './complete-order.component.html',
  styleUrls: ['./complete-order.component.scss'],
  providers: [AutogrowDirective]
})
export class CompleteOrderComponent{
  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  message: any;
  title = '';
  comment = '';
  rating = 1;
  userNotes:any= {};
  starList: boolean[] = [false, true, true, true, true];
  invoiceUpdate: boolean = true;
  showInvoiceOption: boolean;
  invoiceDetails: any = {};
  error: any;
  timer: any = [];
  blockAction: boolean = false;
  result:any;
  constructor(
    matDialog:MatDialog,
    private auth: AuthService,
    private abstractorService: AbstractorService,
    private quickbooks: QuickbooksService,
    private ordersService: OrdersService,
    public dialogRef: MatDialogRef<CompleteOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => {
      if (this.quickbooks.getSharedSessionData() &&
        this.quickbooks.getSharedSessionData().url == '/orders/' + this.message['assignment'].Order_ID + '/view' &&
        this.quickbooks.getSharedSessionData().continue == 'generate_invoice') {
        this.restoreCompData(this.quickbooks.getSharedSessionData().data);
        this.storeInvoiceAndProceed();
      } else {        
        if (this.ordersService.getCompleteOrderData()) this.initPrevdata(this.ordersService.getCompleteOrderData());
        else {
          if (this.isAbstractorActive()) this.fetchAbstractorNotes();
        }
        this.getOrderInvoice().subscribe((data) => {
          if (data) {
            this.showInvoiceOption = false;
            this.invoiceDetails = data;
          }
          else this.showInvoiceOption = true;
        });
      }
      this.myFirstFocus.nativeElement.focus();
    }, 0));
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  initPrevdata(data) {
    this.comment = data.comment;
    this.userNotes.User_Notes_Text = data.notes;
    this.rating = data.rating;
    this.invoiceUpdate = data.Invoice_Update;
    for (let i = 1; i <= this.rating; i++) {
      this.starList[i] = false;
    }
  }

  restoreCompData(data) {
    this.result = {
      state: data.state,
      comment: data.comment,
      rating: data.rating,
      notes: data.notes,
      Invoice_Update: data.Invoice_Update
    }
  }

  CompleteOrder() { 
    if (this.invoiceUpdate) this.storeInvoiceAndProceed();
    else {
      this.result = {
        state: true,
        comment: this.comment,
        Invoice_Update: this.invoiceUpdate
      }
      this.dialogRef.close(this.result);
      this.clearTime();
      this.ordersService.setCompleteOrderData(this.result);
    }
  }

  Close() {
   
    this.result = false;
    this.dialogRef.close(this.result);
    this.clearTime();
  }

  goBack() {
    this.result = true;
    this.clearTime();
  }

  setStar(data: any) {
    this.rating = data + 1;
    for (var i = 0; i <= 4; i++) {
      if (i <= data) {
        this.starList[i] = false;
      }
      else {
        this.starList[i] = true;
      }
    }
  }

  fetchAbstractorNotes() {
    this.abstractorService.getAbstractorNotes(this.message['abstrator'].User_ID)
      .subscribe(notes => {
        if (notes) this.userNotes = notes;
      }, (err) => { });
  }

  isAbstractorActive() {
    if ((this.message['assignment'].Admin_User_Role == CONSTANTS.userRole.searchMaster)) return false;
    else {
      if (this.message['abstrator'].User_ID) return true;
      return false;
    }
  }


  generateInvoice() {
    return this.ordersService.verifyAndGenerateInvoice(this.message['assignment'].Order_ID, this.result, 'review')
  }

  getOrderInvoice() {
    return this.quickbooks.getOrderInvoice(this.message['assignment'].Order_ID)
  }

  storeInvoiceAndProceed() {    
    this.blockAction = true;
    this.generateInvoice()
      .subscribe((res) => {
        if (res) {
          this.getOrderInvoice()
            .subscribe((invoiceData) => {
              this.blockAction = false;
              this.result = {
                state: true,
                comment: this.comment,
                rating: this.rating,
                notes: this.userNotes.User_Notes_Text,
                Invoice_Update: this.invoiceUpdate,
                Invoice_Details : invoiceData
              }
              this.clearTime();
              this.ordersService.setCompleteOrderData(this.result);
            }, (err) => { 
              this.error = err;
              this.blockAction = false; 
            })
        }
      }, (err) => {         
        this.blockAction = false;
        if(err && err.code == 'PTOR10') this.showInvoiceOption = true;
        this.error = err; 
      });
  }

  clearTime(){
    this.dialogRef.close(this.result);
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }


}