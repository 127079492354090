// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.read {
  transition: background-color 2s ease-out;
}

.unread {
  background-color: rgba(53, 126, 189, 0.3);
}

.msg-text {
  white-space: pre-wrap;
}

.orderlink {
  text-decoration: underline;
  text-decoration-color: cadetblue;
}`, "",{"version":3,"sources":["webpack://./src/app/client-messages/client-messages.component.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;AACJ;;AACA;EACI,yCAAA;AAEJ;;AACA;EACI,qBAAA;AAEJ;;AACA;EACI,0BAAA;EACA,gCAAA;AAEJ","sourcesContent":[".read{    \n    transition: background-color 2s ease-out;\n}\n.unread{    \n    background-color:rgba(53, 126, 189, 0.3);\n}\n\n.msg-text{\n    white-space: pre-wrap;\n}\n\n.orderlink{\n    text-decoration: underline;\n    text-decoration-color: cadetblue;\n  }\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
