import { Component, OnDestroy, OnInit } from "@angular/core";
import { OrdersService } from "app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";
const momentTimeZone = require('moment-timezone');

import { RptBuilderService } from "app/report-builder/rpt-builder.service";

@Component({
  selector: "app-chk-cot",
  templateUrl: "./chk-cot.component.html",
  styleUrls: ["./chk-cot.component.scss"],
})
export class ChkCotComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  titleData: any = [];
  vestingData: any = [];
  timelineData: any = [];
  chainIssues: any = [];
  orderId: any;
  recorderURL = null;
  timeLineCtr = 0;
  gapCtr = 0;
  isMinMet = true;
  isVestingMet = true;
  yearsRequired = 0;
  dataDepthYears:any;
  orderDetails : any = [];
  reportDetails :any = null;
  startYear: any = new Date().getFullYear();

  constructor(
    private builder: RptBuilderService,
    private ordersService: OrdersService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) {
          this.reportDetails = data.curData;
          this.createTitleTimeline();
        }
      });
    this.builder.vesting$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData) {
          this.vestingData = data.curData;
          this.createTitleTimeline();
        }
      });
    this.builder.cot$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (data && data.curData) {
        this.titleData = data.curData;
        this.createTitleTimeline();
      }
    });
    this.ordersService.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) =>{
        this.orderDetails = data;
        this.getOrderData(data);
      }) 
    this.ordersService.searchInfo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.getButtonLinks(data));
     this.getDataDepthInfo()

  }

  getOrderData(data) {
     this.orderId = data.Order_ID;
   if(this.orderDetails && this.orderDetails.Years_Required != null) this.yearsRequired = this.orderDetails.Years_Required;
    this.startYear = new Date().getFullYear();
    if (this.yearsRequired > 0) {
      this.startYear = new Date().getFullYear() - this.yearsRequired;
    }
    if(this.timelineData.length)
    this.checkChainIssues();
  }

  createTitleTimeline() {
    if(this.reportDetails && this.reportDetails.Id && this.reportDetails.Id  != undefined){
      this.timelineData = [];
      let cleansedTitleData = this.reportDetails.CT_Manual_Sort == 0 ? this.sortTitleData(this.titleData) : this.titleData
      let chainData = this.vestingData.concat(cleansedTitleData);
      chainData.forEach((deed) => {
            let cotData = {
              dated: deed.Rec_Date,
              grantee: (deed.Entity_ID === 2) ? deed.Beneficiaries : deed.Grantee,
              grantor: (deed.Entity_ID === 2) ? deed.Estate_Of : deed.Grantor,
              gapPresent: false,
            };
          this.timelineData.push(cotData);
      });
      // this.timelineData.sort(
      //   (a, b) =>
      //     Date.parse(a.dated) - Date.parse(b.dated)
      // );
      this.timeLineCtr = 0; this.gapCtr = 0;
      this.timelineData.forEach((deed, idx) => {
        this.timeLineCtr += 1;
        if (idx < this.timelineData.length - 1) {
          if (deed.grantee !== this.timelineData[idx + 1].grantor) {
            // this.timeLineCtr += 1;
            this.gapCtr += 1;
            deed.gapPresent = true;
          }
        }
      });
      if(this.timelineData.length)
      this.checkChainIssues();
    }    
  }

  sortTitleData(data){
    data.sort((titleA, titleB) => {
      const primarySort =
        new Date(titleB.Rec_Date).valueOf() - new Date(titleA.Rec_Date).valueOf();
      if (primarySort !== 0) {
        return primarySort;
      }
      const numA = String(titleA.Instrument_Num || "");
      const numB = String(titleB.Instrument_Num || "");
      const secondarySort = numB.localeCompare(numA);
      if (secondarySort !== 0) {
        return secondarySort;
      }
      const pageA = String(titleA.Page || "");
      const pageB = String(titleB.Page || "");
      return pageB.localeCompare(pageA);
    });
    return data;
  }

  checkChainIssues() {
    this.chainIssues = [];
    let vestingGap = null;
    if (new Date(this.timelineData[0].dated).getFullYear() > this.startYear){
      this.chainIssues.push({
        type: 1,
        text: `Search Only Goes back to 
        ${momentTimeZone.tz(new Date(this.timelineData[0].dated),'America/New_York').format('MMMM D, YYYY')}`,
      });
      this.timeLineCtr += 1;
      this.isMinMet = false;
    }
    this.timelineData.forEach((deed, idx) => {
      if(idx == 0){
        if(this.orderDetails.Property_First_Name == null || this.orderDetails.Property_First_Name !== deed.grantee){
          vestingGap ={
            type: 3,
            text: this.orderDetails.Property_First_Name !== null
            ? `The grantee (${deed.grantee}) of the last (vesting) deed does not match the current owner name (${this.orderDetails.Property_First_Name})`
            : `The grantee (${deed.grantee}) of the last (vesting) deed does not match the current owner name (- - -)`,
          };
        }
      }
      if (idx < this.timelineData.length - 1) {
        if (deed.grantee !== this.timelineData[idx + 1].grantor) {
          let grantee = `There is a grantee (${deed.grantee}) / grantor (${this.timelineData[idx + 1].grantor})
            mismatch on the deed dated  ${momentTimeZone.tz(new Date(deed.dated),'America/New_York').format('MMMM D, YYYY')}`;

        //   let grantor = `Grantor ${
        //     this.timelineData[idx + 1].grantee
        // } on deed dated  ${momentTimeZone.tz(new Date(this.timelineData[idx + 1].dated),'America/New_York').format('MMMM D, YYYY')}`;
          this.chainIssues.push({
            type: 2,
            text: `${grantee}.`,
          });
        }
      }
    });

    if(vestingGap !== null) {
      this.chainIssues.push(vestingGap);
      this.gapCtr += 1;
      this.isVestingMet = false;
    }
  }

  getButtonLinks(data) {
    this.recorderURL = null;
    if (data.length) {
      if (data[0]["Recorder Site"] && data[0]["Recorder Site"].length)
        this.recorderURL = data[0]["Recorder Site"][0];
    }
  }

  getDataDepthInfo() {
    this.ordersService.getDataDepthInfo({Property_State_Abbr: this.orderDetails.Property_State_Abbr })
      .subscribe((info) => {
        this.dataDepthYears = info[0]["State Statute"][0]
        // if(info.length > 0) this.dataDepth = info[0];
        // else this.gsheetSearchInfo = {};
        if(this.orderDetails && this.orderDetails.Years_Required == null) this.yearsRequired = this.dataDepthYears;
      }, (err) => {
        throw err;
      })
  }
}
