// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog-over {
  width: 40rem;
  height: 90vh;
}

.form-border {
  border: 1px solid #e6e1e1;
  border-radius: 2px;
}

.modal-content {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/create-abs-dialog/create-abs-dialog.component.scss"],"names":[],"mappings":"AAAA;EACG,YAAA;EACA,YAAA;AACH;;AAGA;EACI,yBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,WAAA;AAAJ","sourcesContent":[".modal-dialog-over{   \n   width: 40rem;\n   height: 90vh;\n}\n\n\n.form-border{\n    border : 1px solid #e6e1e1;\n    border-radius: 2px;\n}\n\n.modal-content{\n    width:100%;    \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
