import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { FormGroup, FormsModule, FormBuilder, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subject, ReplaySubject } from 'rxjs';
import * as _ from 'underscore';
import { MatSelect } from '@angular/material/select';
import { CONSTANTS } from '../../app.constants';

@Component({
  selector: 'app-mat-select-search-obj',
  templateUrl: './mat-select-search-obj.component.html',
  styleUrls: ['./mat-select-search-obj.component.scss']
})
export class MatSelectSearchObjComponent implements OnInit, OnChanges {
  constants = CONSTANTS;
  selectedOption: any;
  timer: any = [];
  optionList: any = [];
  protected _onDestroy = new Subject<void>();
  filteredEntityList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  entityFilterCtrl = new UntypedFormControl();
  @Input() isMultiSelection: boolean = false;
  @Input() isMultipleSelection: boolean = false;
  @Input() existingRecords: Array<any>
  @Input() entityList: Array<any>;
  @Input() matSearchCtrl = new UntypedFormControl();
  @Input() showUserFullName: boolean;
  @Input() selectedId: string;
  @Input() disabledOptions: boolean = false;
  @Input() placeHolderSel: any;
  @Input() matSelPlaceHolder: String;
  @Input() entityName: String = 'Entity';
  @Input() selectKey: String;
  @Input() matchingKey: string;
  @Input() initializeData: any = null;
  @Input() entityMatchingKey: string;
  @Input() selectAllValue: any = 'all'; 
  @Input() isSelectAllEnabled : boolean;
  @Input() filterKey: any[];
  @Input() selected: any;
  @Input() isLoading: boolean = false;
  @Input() selectAll: boolean = true;
  @Input() isMultiSelectValue: any;    
  @Output() selectedChange = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();
  @ViewChild('matSelSearch') matSelSearch: MatSelect;
  @Input() clearRetainData : boolean = false;
  @Input() showOrganizationName: boolean = true;
  @Input() isFilterSelectAll : boolean = true;
  @Input() filterEntitySelectAll : boolean = false;
  @Output() manualChange = new EventEmitter<any>();
  @Input() selectCheckAll: boolean = false;
  constructor(
    private ref:ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.filteredEntityList
    .pipe(takeUntil(this._onDestroy))
    .subscribe((lst) => {
      this.optionList = lst;
      this.ref.detectChanges();
    });
    this.entityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEntity();
      });
      this.timer.push(setTimeout(() => {
      if (this.selected) {
        this.selectedOption = this.selected;
        this.matSearchCtrl.setValue(this.selected);
      }
    }, 0));
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.clearRetainData) this.selectedOption = null;
    for (let prop in changes) {
      if(prop == 'selected'){        
        if(this.selected) this.selectedOption = this.selected;
        this.ref.detectChanges();
      }   
      if (prop == 'entityList' && this.filteredEntityList) {
        if (_.isArray(this.entityList)) this.filteredEntityList.next(this.entityList.slice());
        if (this.initializeData) {
          this.selectedOption = this.initializeData;
        }
      }
    }
  }


  protected filterEntity() {
    if (!this.entityList) {
      return;
    }
    let search = this.entityFilterCtrl.value;
      if(this.selectAll){
       if (!search) {
      this.filteredEntityList.next(this.entityList.slice());
      this.isSelectAllEnabled=true;
      this.filterEntitySelectAll = true;
      this.selectCheckAll = true;
      return;
    } else {
      search = search.toLowerCase();
      this.isSelectAllEnabled=false;
      this.filterEntitySelectAll = false;
      this.selectCheckAll = false;
    }
  }
  else{
    if (!search) this.filteredEntityList.next(this.entityList.slice());
    else search = search.toLowerCase();    
  }
    this.filteredEntityList.next(this.entityList.filter(entityData => this.parseFilteredData(entityData, search)))
    this.ref.detectChanges();
  }

  parseFilteredData(entityData, search) {
    return this.filterKey.some((key) => entityData && (entityData[key] && entityData[key].toLowerCase().indexOf(search) > -1))       
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  emitSelected(event) {
    if(!this.filterEntitySelectAll){
      if(this.isMultipleSelection && event.length==0) this.selectedOption=["all"];
      if(event) {
        this.selectedChange.emit(this.selectedOption);
        this.selectionChange.emit(this.selectedOption);
      }
    }else {
      if(event) {
        this.selectedChange.emit(this.selectedOption);
        this.selectionChange.emit(this.selectedOption);
      }
    }

  }
  checkAvailableID(entity) {
    if (!this.matchingKey || !this.entityMatchingKey) return true;
    else {
      if (this.existingRecords && this.existingRecords.length > 0) {
        if (this.existingRecords.findIndex(x => x[this.matchingKey] == entity[this.entityMatchingKey]) > -1) {
          return false;
        }else return true;
      }
      else return true;
    }
  }

  selectAllUsers(ev, event) {
    console.log("============>Event======>",ev);
    if (ev._selected) {
      let selectAllUsers = [];
      this.entityList.filter(x => {
        if(x && x.User_ID) selectAllUsers.push(x);
        if(x && !x.User_ID) selectAllUsers.push(x)
      });
      this.matSearchCtrl.setValue(selectAllUsers)
      ev._selected = true;
    }
    if (ev._selected == false) {
      this.matSearchCtrl.setValue([]);
    }
  }

  checkForMultiple() {
    if(this.isMultipleSelection) return true;
    else if (this.isMultiSelection) return true;
    else return false;
  }

  clearSelection(ev, event){
    this.selectedOption=["all"]; 
    this.ref.detectChanges();
    event.stopPropagation();    
  }
 
  emitManualSelectionChange(event) {
    if (event.value == "") {
      this.selectedChange.emit(event.value);
      this.selectionChange.emit(event.value);
    }
    this.manualChange.emit(event);
  }
 
}