import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmComponent } from "app/dialog/confirm/confirm.component";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { ConfigService } from "app/services";
import { DialogService } from "ng2-bootstrap-modal";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import * as momentTimeZone from "moment-timezone";
import * as moment from "moment";
import { MatButton } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-rb-tax-section",
  templateUrl: "./rb-tax-section.component.html",
  styleUrls: ["./rb-tax-section.component.scss"],
})
export class RbTaxSectionComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() dbData: any;
  @Input() itemData: any;
  @Input() itemIndex: any;
  @Output() dragEnable: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('firstInput') firstInput : MatButton;
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionName = "Tax";
  sectionType = "Taxes";
  sectionDocs: any = [];
  sectionTitle: any = "";
  isToggleChanged=false;
  reportDetails = {
    Derived_From: null,
  };
  orderDetails: any = {};
  taxSourceTypes: any = [];
  taxPaidTypeOptions: any = [];
  truthfulPaidTypes: any = [];
  taxAuthorityTypeOptions: any = [];
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private matDialog:MatDialog,
    private config: ConfigService,
    private cdr: ChangeDetectorRef,
    private builder: RptBuilderService,
    private chnageDetRef: ChangeDetectorRef
  ) {
    this.dataForm = this.frmBuilder.group({
      Year: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(4)]),
      ],
      Tax_Type:[{value: this.itemData?.Tax_Type_ID, disable:false}],
      Prior_Delinquencies: [null],
      Source_Type_ID: [null],
      Annual_Amount: [null],
      Annual_Paid_Date: [{ value: "", disable: false }],
      Annual_Paid_Status_Type_ID: [null],
      Fst_Quarter_Amount: [null],
      Fst_Quarter_Paid_Date: [{ value: "", disable: false }],
      Fst_Quarter_Paid_Status_Type_ID: [null],
      Snd_Quarter_Amount: [null],
      Snd_Quarter_Paid_Date: [{ value: "", disable: false }],
      Snd_Quarter_Paid_Status_Type_ID: [null],
      Thrd_Quarter_Amount: [null],
      Thrd_Quarter_Paid_Date: [{ value: "", disable: false }],
      Thrd_Quarter_Paid_Status_Type_ID: [null],
      Frth_Quarter_Amount: [null],
      Frth_Quarter_Paid_Date: [{ value: "", disable: false }],
      Frth_Quarter_Paid_Status_Type_ID: [null],
      Fst_Semi_Annual_Amount: [null],
      Fst_Semi_Annual_Paid_Date: [{ value: "", disable: false }],
      Fst_Semi_Annual_Paid_Status_Type_ID: [null],
      Snd_Semi_Annual_Amount: [null],
      Snd_Semi_Annual_Paid_Date: [{ value: "", disable: false }],
      Snd_Semi_Annual_Paid_Status_Type_ID: [null],
      Comments: [null, [Validators.maxLength(65535)]],
      Internal_Comments: [null, [Validators.maxLength(65535)]],
      Applies: [null],
      Parcel: [null, [Validators.maxLength(255)]],
      Language: ["good", Validators.required],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveItem(this.dataForm));
  }

  ngAfterViewInit(): void {
    this.dataForm.controls["Applies"].patchValue(this.itemData.Applies == 1, {
      emitEvent: false,
    });
    if (!this.itemData.Status) this.dataForm.disable();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data) {
          let selectedEntity = data.SP_Tax_Entities.find(
            (entity) => entity.Id == this.itemData.Entity_ID
          );
          if (selectedEntity !== undefined)
            this.sectionTitle = selectedEntity.Entity;
          this.taxSourceTypes = data.SP_Tax_Source_Types;
          this.taxPaidTypeOptions = data.SP_Tax_Paid_Status_Types;
          this.taxAuthorityTypeOptions=data.SP_Tax_Authority_Types;
          this.truthfulPaidTypes = this.taxPaidTypeOptions.filter((tax) =>
            [1, 2, 3, 4].includes(tax.Id)
          );
        }
      });
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderDetails = data));
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData && data.dbData.Id !== undefined){
          this.reportDetails = data.dbData;
          if (
            this.orderDetails &&
            this.orderDetails.Order_ID !== undefined &&
            this.orderDetails.Order_ID !== ""
          )
            {
                this.builder.manageError(
                `Taxes_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
                this.dataForm.status !== "VALID",
                this.dataForm.controls
              );
            this.cdr.detectChanges();
            this.handleMandatoryFldStates();
          }
        }
      });
    this.cdr.detectChanges();
    this.setFieldAsMarked("Year");
    if(this.itemData.Entity_ID ==1 || this.itemData.Entity_ID == 4){
      this.setFieldAsMarked("Annual_Paid_Status_Type_ID");
    }
    else if(this.itemData.Entity_ID == 2){
      this.setFieldAsMarked("Fst_Semi_Annual_Paid_Status_Type_ID");
      this.setFieldAsMarked("Snd_Semi_Annual_Paid_Status_Type_ID");
    }
    else {
      this.setFieldAsMarked("Fst_Quarter_Paid_Status_Type_ID");
      this.setFieldAsMarked("Snd_Quarter_Paid_Status_Type_ID");
      this.setFieldAsMarked("Thrd_Quarter_Paid_Status_Type_ID");
      this.setFieldAsMarked("Frth_Quarter_Paid_Status_Type_ID");
    }
  }

  ngOnChanges(changes: SimpleChanges){
    for (const propName in changes) {
      if (propName == 'itemData') {
        this.updateValidityForStatusFlds();
      }
    }
  }

  enableDrag(event) {
    this.dragEnable.emit(true);
  }

  diasbleDrag() {
    this.dragEnable.emit(false);
  }

  toggleApplies(event) {
    this.itemData.Applies = event ? 1 : 0;
    this.handleMandatoryFldStates();
    this.isToggleChanged=true;
  }

  manageAppliesTgleChange(control, onValidators, offValidators){
    if(!this.itemData.Applies) {
      control.setValidators(offValidators);  
      control.setErrors(null);  
      this.builder.manageError(
        `Taxes_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        false,
        {}
      );          
    }   
    else {
      control.setValidators(onValidators)
      this.builder.manageError(
        `Taxes_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        this.dataForm.status !== "VALID",
        this.dataForm.controls
      );
    };    
    control.updateValueAndValidity();
  }

  saveItem(event) {
    if (
      this.orderDetails &&
      this.orderDetails.Order_ID !== undefined &&
      this.itemData.Status
    )
      this.builder.manageError(
        `Taxes_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine) {
      let payload = {
        curData: this.builder.trimInput(event, this.itemData),
        dbData: this.dbData,
        Sp_Id: this.reportDetails["Id"],
        Derived_From: this.reportDetails["Derived_From"],
        toggleControl:{changed:this.isToggleChanged,state:this.itemData.Applies}
      };
      this.isToggleChanged=false
      this.builder.saveTax(payload, false).pipe(take(1)).subscribe();
    }
  }

  deleteItem() {
    this.matDialog
      .open(
        ConfirmComponent,
        {data:{
          title: "Delete " + this.sectionTitle,
          message:
            "Are you sure you want to delete this " + this.sectionTitle + " ?",
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.itemData.Status = 0;
          let payload = {
            curData: this.itemData,
            dbData: this.dbData,
            Sp_Id: this.reportDetails["Id"],
            Derived_From: this.reportDetails["Derived_From"],
          };
          this.builder.manageError(
            `Taxes_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
            false,
            {}
          );
          this.builder.saveTax(payload, true).pipe(take(1)).subscribe();
        } else {
          console.log("Closed", res);
        }
      });
  }

  handleCommitmentError(isValid) {
    this.dataForm.controls["Language"].setValue(isValid ? "good" : null);
  }

  getPippinDateAnnual(event) {
    if (event && event.Pippin_Date) {
      this.itemData["Annual_Paid_Date"] = event.Pippin_Date;
    } else {
      this.itemData["Annual_Paid_Date"] = null;
    }
  }
  getPippinDate1stQuarter(event) {
    if (event && event.Pippin_Date) {
      this.itemData["Fst_Quarter_Paid_Date"] = event.Pippin_Date;
    } else {
      this.itemData["Fst_Quarter_Paid_Date"] = null;
    }
  }
  getPippinDate2ndQuarter(event) {
    if (event && event.Pippin_Date) {
      this.itemData["Snd_Quarter_Paid_Date"] = event.Pippin_Date;
    } else {
      this.itemData["Snd_Quarter_Paid_Date"] = null;
    }
  }
  getPippinDate3rdQuarter(event) {
    if (event && event.Pippin_Date) {
      this.itemData["Thrd_Quarter_Paid_Date"] = event.Pippin_Date;
    } else {
      this.itemData["Thrd_Quarter_Paid_Date"] = null;
    }
  }
  getPippinDate4thQuarter(event) {
    if (event && event.Pippin_Date) {
      this.itemData["Frth_Quarter_Paid_Date"] = event.Pippin_Date;
    } else {
      this.itemData["Frth_Quarter_Paid_Date"] = null;
    }
  }
  getPippinDateSemiAnnual(event) {
    if (event && event.Pippin_Date) {
      this.itemData["Fst_Semi_Annual_Paid_Date"] = event.Pippin_Date;
    } else {
      this.itemData["Fst_Semi_Annual_Paid_Date"] = null;
    }
  }
  getPippinDateSecSemiAnnual(event) {
    if (event && event.Pippin_Date) {
      this.itemData["Snd_Semi_Annual_Paid_Date"] = event.Pippin_Date;
    } else {
      this.itemData["Snd_Semi_Annual_Paid_Date"] = null;
    }
  }

  getPaidTypeLabel(num) {
    let selStatType = {
      1: "Annual_Paid_Status_Type_ID",
      2: "Fst_Quarter_Paid_Status_Type_ID",
      3: "Snd_Quarter_Paid_Status_Type_ID",
      4: "Thrd_Quarter_Paid_Status_Type_ID",
      5: "Frth_Quarter_Paid_Status_Type_ID",
      6: "Fst_Semi_Annual_Paid_Status_Type_ID",
      7: "Snd_Semi_Annual_Paid_Status_Type_ID",
    };
    if (this.dataForm.controls[selStatType[num]].value == 1) return "Paid Date";
    if ([2, 3, 4].includes(this.dataForm.controls[selStatType[num]].value))
      return "Due Date";
    return "Date";
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "TAX_ES",
      this.itemData.Id,
      fieldName
    );
  }

  setFieldAsMarked(fieldKey){
    var keyIndex = Object.keys(this.dataForm.controls).findIndex((key) => key == fieldKey);
    if (keyIndex > -1) {
      let key =  Object.keys(this.dataForm.controls)[keyIndex] 
      this.dataForm.get(key).markAsTouched();
    }    
  }

  updateValidityForStatusFlds(){
    if(this.itemData.Entity_ID ==1 || this.itemData.Entity_ID == 4){
      this.dataForm.get('Annual_Paid_Status_Type_ID').setValidators([Validators.required])
    }
    else if(this.itemData.Entity_ID == 2){
      this.dataForm.get('Fst_Semi_Annual_Paid_Status_Type_ID').setValidators([Validators.required])
      this.dataForm.get('Snd_Semi_Annual_Paid_Status_Type_ID').setValidators([Validators.required])
    }
    else {
      this.dataForm.get('Fst_Quarter_Paid_Status_Type_ID').setValidators([Validators.required])
      this.dataForm.get('Snd_Quarter_Paid_Status_Type_ID').setValidators([Validators.required])
      this.dataForm.get('Thrd_Quarter_Paid_Status_Type_ID').setValidators([Validators.required])
      this.dataForm.get('Frth_Quarter_Paid_Status_Type_ID').setValidators([Validators.required])
    }
  }

  handleMandatoryFldStates(){
    this.manageAppliesTgleChange(
      this.dataForm.controls['Year'], 
      [Validators.compose([Validators.required, Validators.maxLength(4)])], 
      [Validators.maxLength(4)]
    )
    if(this.itemData.Entity_ID ==1 || this.itemData.Entity_ID == 4){
      this.manageAppliesTgleChange(this.dataForm.get('Annual_Paid_Status_Type_ID'), [Validators.required], []);
    }
    else if(this.itemData.Entity_ID == 2){
      this.manageAppliesTgleChange(this.dataForm.get('Fst_Semi_Annual_Paid_Status_Type_ID'), 
      [Validators.required], []);
      this.manageAppliesTgleChange(this.dataForm.get('Snd_Semi_Annual_Paid_Status_Type_ID'), 
      [Validators.required], []);  
    }
    else {
      this.manageAppliesTgleChange(this.dataForm.get('Fst_Quarter_Paid_Status_Type_ID'), 
      [Validators.required], [])
      this.manageAppliesTgleChange(this.dataForm.get('Snd_Quarter_Paid_Status_Type_ID'), 
      [Validators.required], [])
      this.manageAppliesTgleChange(this.dataForm.get('Thrd_Quarter_Paid_Status_Type_ID'), 
      [Validators.required], [])
      this.manageAppliesTgleChange(this.dataForm.get('Frth_Quarter_Paid_Status_Type_ID'), 
      [Validators.required], [])      
    }
  }
}
