import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule,FormsModule,FormGroup,FormControl,Validators,UntypedFormBuilder } from '@angular/forms';
import { AuthService,EulaService, ConfigService } from '../../services'
import { CONSTANTS } from 'app/app.constants';


@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsAndConditionsComponent implements OnInit {
  isAccept:boolean=false;
  errorMsg={}
  eula:any={};
  timer: any = [];
  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private frmBuilder:UntypedFormBuilder,
    public auth: AuthService,
    private eulaService:EulaService,
    private config:ConfigService
  ) { }

  ngOnInit() {
    if(this.route.snapshot.params['userId']){
      this.isAccept=true;
      this.eulaService.getActiveEULA()
        .subscribe((data)=>{
          if(data){
            this.eula=data;
          }
        })
    }else{
      this.eulaService.getEULAByUserId(this.auth.getUserId())
        .subscribe((data)=>{
          if(data){
            this.eula=data;
          }
        })
    }
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  updateUserEULA(){
    var data={
      EULA_Version:this.eula.EULA_Version
    }
    this.eulaService.updateUserEULA(this.route.snapshot.params['userId'],data)
      .subscribe((data)=>{
        if(data){
          if(data.user){
            if (data.user.User_Role == CONSTANTS.userRole.searchMaster) this.router.navigate(['orders/inprocess']);
            else this.router.navigate(['orders/new']);
          }
        }
      },(err)=>{
        console.log(err);
        this.errorMsg=err;
      })
  }

}
