import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { OrdersService, ConfigService } from '../../services';
import { CONSTANTS } from '../../app.constants';
import * as _ from 'underscore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  templateUrl: './situsamc-accept-reject.component.html',
  styleUrls: ['./situsamc-accept-reject.component.scss']
})
export class SitusamcAcceptReject {
  title: String = 'Acccept or Reject SitusAMC Order';
  body: String = 'Would you like to accept or reject this SitusAMC order?';
  pippinOrderID: String = '';
  buttonSet: number = 1;
  timer: any = [];

  constructor(
    private ref: ChangeDetectorRef,
    private orderService: OrdersService,
    private config: ConfigService,
    public dialogRef: MatDialogRef<SitusamcAcceptReject>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    
  }

  ngOnInit() {
    this.initDialog();

  }

  initDialog(){
    this.title = this.data.title;
    this.pippinOrderID = this.data.pippinOrderID;
  }

  ngAfterViewInit() {
    
  }

  rejectOrder1() {
    this.body = 'Are you sure you want to reject this order? You will not be able to go back.';
    this.buttonSet = 2;
  }

  confirmOrder() {
    this.orderService.approveOrRejectSitusAMCOrder(String(this.pippinOrderID), "approved").subscribe((serviceResponse) => {
      if(serviceResponse){
        this.Close(true);
      }
    });
  }

  rejectOrder2No() {
    this.body = 'Would you like to accept or reject this SitusAMC order?';
    this.buttonSet = 1;
  }

  rejectOrder2Yes() {
    this.orderService.approveOrRejectSitusAMCOrder(String(this.pippinOrderID), "rejected").subscribe((serviceResponse) => {
      if(serviceResponse){
        this.Close(false);
      }
    });
  }

  Close(dataResponse) {
    this.dialogRef.close(dataResponse);
    // this.result = dataResponse;
    this.clearTime()
  }

  clearTime(){
    // this.close();
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
}
