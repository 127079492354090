import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { CONSTANTS } from '../../app.constants';
import { AuthService, OrganizationService, ClientsService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatRadioButton } from '@angular/material/radio';
import { MatSelectSearchComponent } from 'app/common/mat-select-search/mat-select-search.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'add-client-orgs',
  templateUrl: './add-client-orgs.component.html',
  styleUrls: ['./add-client-orgs.component.scss']
})
export class AddClientOrgsComponent{
  @ViewChild('myFirstFocus') myFirstFocus: MatRadioButton
  @ViewChild('matSearchFocus') matSearchFocus:MatSelectSearchComponent
  constants = CONSTANTS
  message: any;
  title = '';
  listingType = '0';
  clients = [];
  userId = '';
  companyName = '';
  domainName = '';
  timer: any = [];
  constructor(private organizationService: OrganizationService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientsService, 
    public dialogRef: MatDialogRef<AddClientOrgsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title=this.data.title;
    this.message=this.data.message;
  }

  ngOnInit() {
    this.initDialog();
  //  this.timer.push(setTimeout(() => { this.myFirstFocus.focus() }, 0));
  this.timer.push(setTimeout(() => { this.matSearchFocus.matSelSearch.focus()}))
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  confirm() {
    if (this.listingType != '0') {
      this.dialogRef.close("new");
      // this.result = "new";
      this.clearTime();
    } else {
      this.dialogRef.close(this.userId);
      // this.result = this.userId;
      this.clearTime();
    }
  }

  isDisabled() {
    if (this.listingType == '0') {
      if (!this.userId) return true;
      else return false;
    }
    else return false;
  }

  isNewOption() {
    return this.listingType;
  }

  Close() {
    this.dialogRef.close("cancel");
    // this.result = "cancel";
    this.clearTime()
  }

  checkIfClientAvailable(client) {
      if (this.message['Assigned_Clients']) {
      if (this.message['Assigned_Clients'].findIndex(x => x.User_ID == client.User_ID) > -1) {
        return false;
      }
      return true;
    }
    else return true;
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

 }

