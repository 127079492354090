import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-rb-add-new-widget',
  templateUrl: './rb-add-new-widget.component.html',
  styleUrl: './rb-add-new-widget.component.scss',
  encapsulation : ViewEncapsulation.None,
})
export class RbAddNewWidgetComponent {  
  searchString: string;
  searchCtrl:FormControl = new FormControl();
  @Output() addNewItem = new EventEmitter<any>();
  @Input() sectionOptions:any[];
  filteredSectionOptions:any 
  @ViewChild('itemSearchInp') itemSearchInp : ElementRef
  ngOnInit(): void{
    this.searchCtrl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value) => {
        this.filterItems(value)
      });
  }

  addNewSectionItem(item){
    this.addNewItem.emit(item);
  }

  menuOpened(){    
    this.searchString = "";
    this.filterItems("");
    setTimeout(()=> {
      if(this.itemSearchInp) this.itemSearchInp.nativeElement.focus();
    }, 0)
  }

  menuClosed(){
  }

  filterItems(search){
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search ? search.trim(): "";
    if (!format.test(search) && search == " ") search = "";
    this.filteredSectionOptions = this.sectionOptions;
    search = search.toLowerCase();
    if (search !== "")
      this.filteredSectionOptions = this.sectionOptions.filter(
        (itm) =>
          (itm.Entity && itm.Entity.toLowerCase().includes(search)) ||
          (itm.Name && itm.Name.toLowerCase().includes(search)) 
      );
    if(this.itemSearchInp)this.itemSearchInp.nativeElement.focus();
  }
}
