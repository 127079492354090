import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-datetime-form',
  templateUrl: './datetime-form.component.html',
  styleUrls: ['./datetime-form.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DatetimeFormComponent implements OnInit {
  @ViewChild('etaDatePicker') etaDatePicker: ElementRef;
  @Input() dateFormCtrl = new UntypedFormControl({ value: '', disabled: false });
  @Input() timeValue: string = "00:00";
  @Input() dateValue: any;
  @Input() actionField:any;
  @Output() eventEmitter = new EventEmitter<any>();
  @Output() clearFilterEvn = new EventEmitter<any>();  
  @Input() placeHolderDate: string;
  @Input() placeHolderTime: string;
  timeFormCtrl = new UntypedFormControl({ value: '', disabled: true }, [Validators.required]);
  dateTimeObj = {};
  errors: any = {};
  getUSDateString = new Date('01/02/1500').toLocaleString("en-US", { timeZone: "America/New_York" });
  min_Date = new Date(this.getUSDateString);

  constructor(private adapter: DateAdapter<any>) { }

  ngOnInit() {
  }

  validateDateFn(event) {
    if (event.value) {
      this.timeFormCtrl.enable();
      this.dateTimeObj['Date_EST'] = this.dateValue;
      this.dateTimeObj['Time_EST'] = this.timeValue;
      this.eventEmitter.emit(this.dateTimeObj);
    }else{
      this.dateTimeObj['Date_EST'] = this.dateValue;
      this.dateTimeObj['Time_EST'] = this.timeValue;
      this.eventEmitter.emit(this.dateTimeObj);
    }
  }

  dateNTime() {
    if (this.timeFormCtrl.valid) {
      if (this.dateValue && this.timeValue) {
        this.dateTimeObj['Date_EST'] = this.dateValue;
        this.dateTimeObj['Time_EST'] = this.timeValue;
        if (this.dateTimeObj['timeCtrlForm']) {
          delete this.dateTimeObj['timeCtrlForm'];
        }
        this.eventEmitter.emit(this.dateTimeObj);
      }
    } else {
      this.dateTimeObj['timeCtrlForm'] = this.timeFormCtrl  
      this.eventEmitter.emit(this.dateTimeObj);
    }
  }

  clearForms() {
    this.errors = {};
    if (this.dateValue) this.timeFormCtrl.enable();
    else this.timeFormCtrl.disable();
  }

  clearFilter(actionField) {
   this.clearFilterEvn.emit(actionField);
  }

  restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
     return false;
    }
    if (e.which === 0) {
     return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[///\d\s]/.test(input);
   }

   checkYear(){
    if(this.dateTimeObj){
      if(this.dateTimeObj['Date_EST']&& this.dateTimeObj['Date_EST']._d.getFullYear() >= '1500') return true;
      else return false;
    }else return false;
   }

}
