// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pad-scroll-index {
  padding: 8px 0px 8px 0px;
  text-align: -webkit-center;
  text-align: -moz-center;
}

.scroll-label {
  vertical-align: top;
  line-height: 18px;
  font-size: 18.5px;
  color: rgb(182, 182, 182);
}

.scroller:hover .scroll-label {
  font-weight: bolder;
  color: black;
}

.scroller {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  display: flex;
  gap: 5px;
}

.scroll-stamp:hover {
  color: #4fb2d9;
  font-size: 18.5px;
  font-family: "Karla";
  line-height: 18px;
  font-weight: 700;
}
.scroll-stamp:hover .arrow {
  fill: white;
}
.scroll-stamp:hover .symbol {
  background-color: #4fb2d9;
  border: radius 2.5px;
}

.scroll-stamp {
  color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/report-builder/rb-section-scroller/rb-section-scroller.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,0BAAA;EACA,uBAAA;AACF;;AAEA;EACE,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,yBAAA;AACF;;AAGE;EACE,mBAAA;EACA,YAAA;AAAJ;;AAIA;EACE,uBAAA;EAAA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,QAAA;AADF;;AAIA;EAQE,cAAA;EACA,iBAAA;EACA,oBAAA;EACA,iBAAA;EACA,gBAAA;AARF;AAHE;EACE,WAAA;AAKJ;AAHE;EACE,yBAAA;EACA,oBAAA;AAKJ;;AAIA;EACE,kBAAA;AADF","sourcesContent":[".pad-scroll-index {\n  padding: 8px 0px 8px 0px;\n  text-align: -webkit-center;\n  text-align: -moz-center;\n}\n\n.scroll-label {\n  vertical-align: top;\n  line-height: 18px;\n  font-size: 18.5px;\n  color: rgb(182, 182, 182);\n}\n\n.scroller:hover {\n  .scroll-label {\n    font-weight: bolder;\n    color: black;\n  }\n}\n\n.scroller {\n  width: fit-content;\n  align-items: center;\n  display: flex;\n  gap: 5px;\n}\n\n.scroll-stamp:hover {\n  .arrow {\n    fill: white;\n  }\n  .symbol {\n    background-color: #4fb2d9;\n    border: radius 2.5px;\n  }\n  color: #4fb2d9;\n  font-size: 18.5px;\n  font-family: \"Karla\";\n  line-height: 18px;\n  font-weight: 700; \n}\n\n.scroll-stamp {\n  color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
