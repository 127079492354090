// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.grey {
  color: grey;
  font-size: 200px;
}

.align-icon {
  margin-right: 5px; /* Adjust the size as needed */
}

.red-color {
  color: red;
}

.blue-color {
  color: #2196f3;
}`, "",{"version":3,"sources":["webpack://./src/app/customer-dropdown/customer-dropdown.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA,EAAA,8BAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.grey {\n  color: grey;\n  font-size: 200px;\n}\n\n.align-icon {\n  margin-right: 5px;/* Adjust the size as needed */\n}\n\n.red-color {\n  color: red;\n}\n\n.blue-color {\n  color: #2196f3;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
