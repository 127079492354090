// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assign {
  cursor: pointer;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-option-text {
  display: block !important;
}

.styleHeight {
  height: 80px;
}

.modal-dialog-over {
  width: 50rem;
}

.onlyTextBtn {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.onlyTextBtn:active, .onlyTextBtn:focus {
  outline: none !important;
}

.disableOnlyTxtBtn {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: not-allowed;
}

.modal-content {
  width: 100%;
}

.row-button {
  display: flex;
  flex-direction: row;
}

.row-button div {
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/assign/assign.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AACA,6HAAA;AACA;EACE,yBAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AACA;EACE,gBAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;AAEF;AADE;EACE,wBAAA;AAGJ;;AACA;EACE,gBAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,mBAAA;AAEF;;AACA;EACE,WAAA;AAEF;;AACA;EACE,aAAA;EAAe,mBAAA;AAGjB;;AACA;EACE,OAAA;AAEF","sourcesContent":[".assign{\n    cursor: pointer;\n}\n/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */\n.mat-option-text{\n  display:block !important\n}\n\n.styleHeight{\n  height: 80px;\n}\n\n.modal-dialog-over{\n  width: 50rem;\n}\n\n.onlyTextBtn{\n  background:none;\n  border:none;\n  margin:0;\n  padding:0;\n  cursor: pointer;\n  &:active, &:focus {\n    outline: none !important;\n  }\n}\n\n.disableOnlyTxtBtn{\n  background: none;\n  border: none;\n  margin: 0;\n  padding: 0;\n  cursor: not-allowed;\n}\n\n.modal-content{\n  width: 100%;\n}\n\n.row-button{\n  display: flex; flex-direction: row;\n\n}\n\n.row-button div{\n  flex: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
