import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appWhitespace]'
})
export class WhitespaceDirective {

  @Input() appWhitespace: boolean;

  constructor(private el: ElementRef) { }

  @HostListener('keypress', ['$event']) onkeydown(event) {
    let e = <KeyboardEvent>event
    if (this.appWhitespace) {
      if (e.charCode == 32)
        e.preventDefault();
    }
  }
}
