import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { AuthService } from '../../services/authentication/auth.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelect } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'change-order-status',
  templateUrl: './change-order-status.component.html',
  styleUrls: ['./change-order-status.component.scss']
})
export class ChangeOrderStatusComponent{
  @ViewChild('selectBtn') focusedElement: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: MatSelect

  selectedtype: 0;
  message = [];
  title: any
  timer: any = [];
  constructor( private auth: AuthService, public dialogRef: MatDialogRef<ChangeOrderStatusComponent>,
    private matDialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  changeOrderStatus() {
    this.dialogRef.close(this.selectedtype);
    this.clearTime();
  }

  Close() {
    this.dialogRef.close(false);
    this.clearTime();
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => { this.myFirstFocus.focus()}, 0));
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
 