import { Component, OnInit,  ElementRef, ViewChild  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService, EmailValidator, EqualValidator, SearchMasterService, ConfigService, OrganizationService, UsermessageService } from '../../services'
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from '../../dialog/confirm/confirm.component'
import { SuccessComponent } from '../../dialog/success/success.component';
import { CONSTANTS } from '../../app.constants';
import { LoginHistoryComponent } from '../../dialog/login-history/login-history.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { SelectUserComponent } from '../../dialog/select-user/select-user.component';


@Component({
  selector: 'app-edit-search-master',
  templateUrl: './edit-search-master.component.html',
  styleUrls: ['./edit-search-master.component.scss']
})
export class EditSearchMasterComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  userForm: any = {}
  userModel: any = {}
  userId: string = '';
  error: any = {};
  errorMsg: any;
  constants = CONSTANTS;
  organizations = [];
  prvOrganizations = [];
  addedOrgs = [];
  timer: any = [];
  searchMasters = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private frmBuilder: UntypedFormBuilder,
    public auth: AuthService,
    private matDialog:MatDialog,
    private masterService: SearchMasterService,
    private config: ConfigService,
    private organizationService: OrganizationService,
    private searchMasterService: SearchMasterService,
    private userMsg: UsermessageService
  ) {
    this.userForm = frmBuilder.group({
      'Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      'User_First_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'User_Last_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      "userActive": [false],
      "Organization_ID": [null],
      "searchMaster_Status":[null],
      "Is_Manager":[null]
    })
    this.userForm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (!params['userId']) {
        this.router.navigate(['/searchmaster/list']);
      } else {
        this.userId = params['userId'];
        this.userModel.Organization_ID = [];
        this.timer.push(setTimeout(() => { 
          this.config.setSideBarNode(0);
          this.myFirstFocus.nativeElement.focus(); 
        }, 0));
        this.getUserInfo();
        this.getOrganizationList();
        this.addedOrganizationList(params['userId']);
        this.getAllSearchmastersForManager();
      }
    });
  }  

   ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getUserInfo() {
    this.masterService.getMasterDetail(this.userId)
      .subscribe((data) => {
        if (data) this.loadData(data);
      }, (err) => {
        console.log(err);
      })
  }

  loadData(data){
    this.userModel.User_First_Name = data.User_First_Name;
    this.userModel.User_Last_Name = data.User_Last_Name;
    this.userModel.Email_Address = data.Email_Address;
    this.userModel.User_ID = data.User_ID;
    if(data.Is_Manager && data.Is_Manager == CONSTANTS.managerType.searchManager) this.userModel.Is_Manager = CONSTANTS.managerType.searchManager;
    if (data.User_Status == CONSTANTS.userStatus.inActive) {
      this.userModel.userState = false;
    } else {
      this.userModel.userState = true;
    }
    if (data.User_Type == CONSTANTS.userType.externalSM) {
      this.userModel.searchMaster_Status = true;
    } else {
      this.userModel.searchMaster_Status = false;
    }
  }

  changeModelVal(modelVal) {
    this.error = {};
    this.errorMsg = '';
  }

  editSearchMaster() {
    this.masterService.editMasterDetail(this.userModel)
      .subscribe((res) => {
        let msg = "Updated successfully.";
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/searchmaster/list']);
        // this.openModal('Success', 'Updated successfully.').subscribe((res) => {
        //   this.router.navigate(['/searchmaster/list']);
        //  });
      }, (err) => {
        this.errorMsg = err;
      });
  }

  openModal(title, msg) {
    return this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  userAction(event) {
    this.editUserStatus(event)
      .subscribe((res) => {
        this.getUserInfo();
        if (event.checked == true){
          let msg = "Password reset link has been sent to the registered email.";
          this.successMsg(msg);
        }
        else{
          let msg = "User suspended successfully.";
          this.successMsg(msg);
        }
      }, (err) => {
        event.checked = !event.checked;
        this.errorMsg = err;
      });
  }  

  editUserStatus(event) {
    if (event.checked == true) return this.masterService.enableMasterStatus({ User_ID: this.userModel.User_ID})
    else return this.masterService.disableMasterStatus({ User_ID: this.userModel.User_ID });
  }

  getOrganizationList() {
    this.organizationService.getAllOrganizations()
      .subscribe((result) => {
        if (result) {
          this.organizations = result;
        }
      }, (err) => {
        this.errorMsg = err;
      })
  }

  addedOrganizationList(userId) {
    this.organizationService.getAssociatedOrgs(userId)
      .subscribe((result) => {
        if (result) {
          this.prvOrganizations = result;
        }
        else this.prvOrganizations = [];
      }, (err) => {
        this.errorMsg = err;
      })
  }

  isOrgID() {
    if (this.userModel.Organization_ID.length == 0) return true;
    else return false;
  }

  deleteOrg(orgDetails) {
    this.organizationService.deleteOrganization(orgDetails)
      .subscribe((result) => {
        if (result) {
          this.addedOrganizationList(this.route.snapshot.params['userId']);
        }
      }, (err) => {
        this.errorMsg = err;
      })
  }


  addOrganization(ev) {
    this.userModel.Modified_By = this.auth.getUserId();
    this.userModel.Admin_ID = this.userId;
    this.organizationService.addSelectedOrgToAdmin(this.userModel)
      .subscribe((result) => {
        if (result) {
          this.userModel.Organization_ID = [];
          ev._selected = false;
          this.addedOrganizationList(this.route.snapshot.params['userId']);
        }
      }, (err) => {
        this.errorMsg = err;
      })
  }

  checkIfOrgAvailable(org) {
    if (this.prvOrganizations) {
      if (this.prvOrganizations.findIndex(x => x.Organization_ID == org.Organization_ID) > -1) {
        return false;
      }
      return true;
    }
    else return true;
  }

  getSearchMastHistory() {
    this.searchMasterService.getSearchMasterHistory(this.route.snapshot.params['userId'])
      .subscribe((searchMaster) => {
        let disposable = this.matDialog.open(LoginHistoryComponent, {data:{
          message: searchMaster
        }, ...this.config.getDialogOptions()});
      }, (err) => {
        console.log(err);
      })
  }

  multipleSelect(ev){
   if(ev._selected){
    let remainedOrg = [];
    this.organizations.filter(x => {
      if(!this.prvOrganizations.some(y=> y.Organization_ID === x.Organization_ID))
      remainedOrg.push(x.Organization_ID)
    });
    this.userForm.controls['Organization_ID'].setValue(remainedOrg)
    ev._selected=true;
   }
    if(ev._selected==false){
      this.userForm.controls['Organization_ID'].setValue([]);
    }    
  }

  userActionSM(event){
    if(event.checked){
      this.searchMasterService.changeSearchMasterType(this.userId, CONSTANTS.userType.internalSM, CONSTANTS.userType.externalSM)
      .subscribe((searchMaster) => {
        let msg = "Updated successfully.";
        this.successMsg(msg);
      })
    }else {
      this.searchMasterService.changeSearchMasterType(this.userId, CONSTANTS.userType.externalSM, CONSTANTS.userType.internalSM)
      .subscribe((searchMaster) => {
        let msg = "Updated successfully.";
        this.successMsg(msg);
      })
    }
  }

  searchManagerAction(event) {
    if(event.checked){
      this.searchMasterService.switchSearchManager(this.userId, CONSTANTS.managerType.searchManager)
      .subscribe((details) => {
          this.userMsg.setSuccessMsg("Updated successfully.");
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.userModel.Is_Manager = !this.userModel.Is_Manager;
        }
      );
    } else {
      this.searchMasterService.switchSearchManager(this.userId, null)
      .subscribe((details) => {
          this.userMsg.setSuccessMsg("Updated successfully.");
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.userModel.Is_Manager = !this.userModel.Is_Manager;
        }
      );
    }
  }

  addSearchMasterAction() {
    this.masterService.getAllActiveMasters().subscribe((searcherList) => {
      let filteredSearcherList = searcherList.filter(searcher => {
        // Return true if the User_ID of the searcher does not exist in this.searchMasters
        // and it is not equal to params['userId']
        return !this.searchMasters.some(master => master.Srchr_User_Id === searcher.User_ID) &&
               searcher.User_ID !== this.userId;
    });
      let disposable = this.matDialog
        .open(
          SelectUserComponent,
          {data:{
            message: {
              userList: filteredSearcherList,
              existingUserList: this.searchMasters,
              Type: "Search Master",
            },
          },
          ...this.config.getDialogOptions()}
        ).afterClosed()
        .subscribe((res) => {
          if (res && res.state) {
            if(res.Selected_User_ID) {
              this.assignSMToSearchManager(res.Selected_User_ID)             
            }
          }
        });
    });
  }

  assignSMToSearchManager(SMUserId) {
    var data = {};
    data['Srchr_User_Id'] = SMUserId;
    data['Mngr_User_Id'] = this.userId;
    data['Modified_By'] = this.auth.getUserId();
    this.masterService.assignSMToSearchManager(data)
    .subscribe((result) => {
    if (result) {
      let msg = "Added successfully.";
      this.successMsg(msg);
      this.getAllSearchmastersForManager();
    }
    }, (err) => {
      console.log("err", err);
      this.openErrorPopup("Failed to update. Please try again.");
    })
  }

  getAllSearchmastersForManager() {
    this.masterService.getAllSearchmastersForManager(this.userId)
      .subscribe(
        (data) => {
          this.searchMasters = data;
        },
        (err) => {
          console.log(err);
          this.openErrorPopup("Failed to fetch. Please try again.");
        }
      );
  }

  deleteSMMappingAction(user) {
    this.masterService.deleteSearcherManagerMappingbyId(user.Srchr_User_Id, user.Mngr_User_Id).subscribe(
      (result) => {
        if (result) {
          let msg = "Deleted successfully.";
          this.successMsg(msg);
          this.getAllSearchmastersForManager();
        }
      },
      (err) => {
        console.log(err);
        this.openErrorPopup("Failed to delete. Please try again.");
      }
    );
  }

  getUserRole() {
    return Number(this.auth.getUserRole());
  }


  openErrorPopup(msg) {
    let disposable = this.matDialog
      .open(
        ErrorDialogComponent,
        {data:{
          message: msg,
        },
        ...this.config.getDialogOptions()}
      );
  }

  successMsg(msg){
    this.userMsg.setSuccessMsg(msg);
    // this.router.navigate(['/searchmaster/list']);
  }

  isAuthorized() {
    if(this.getUserRole() == CONSTANTS.userRole.superAdmin || this.getUserRole() == CONSTANTS.userRole.compAdmin || this.getUserRole() == CONSTANTS.userRole.admin) return true
    else return false
  }

  navigateToDetailPage(userId){
    this.router.navigate(['/searchmaster/detail/', userId]);
  }

}
