// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorColor {
  color: #f44336 !important;
}

.successColor {
  color: #4BB543;
}`, "",{"version":3,"sources":["webpack://./src/app/tag/create-edit-tag/create-edit-tag.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AAEA;EACA,cAAA;AACA","sourcesContent":[".errorColor{\n    color: #f44336 !important\n}\n\n.successColor{\ncolor: #4BB543;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
