import { Component, OnInit } from '@angular/core';
import { UntypedFormControl,UntypedFormBuilder ,UntypedFormGroup} from '@angular/forms';
import { AuthService, InvoiceService, ClientsService, UsersService, ConfigService, OrganizationService } from '../../services';
import * as moment from 'moment'
import { Router } from '@angular/router';
import { DialogService } from "ng2-bootstrap-modal";
import { CONSTANTS } from '../../app.constants';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { startWith ,  map } from 'rxjs/operators';
import * as momentTimeZone from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';

export class Org {
  constructor(
    public Organization_Creation_Date: string,
    public Organization_Description: string,
    public Organization_Domain: string,
    public Organization_ID: string,
    public Organization_Modification_Date: string,
    public Organization_Modified_By: string,
    public Organization_Name: string,
    public Organization_Status: number
  ) { }
}

export class Customer {
  constructor(
    public Email_Address: string,
    public User_First_Name: string,
    public User_ID: string,
    public User_Last_Name: string,
  ) { }
}


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  customers = [];
  orgs = [];
  filterBy = {
    Organization: {},
    Customer: {}
  };
  errors = {};
  errors1 = {};
  orders = [];
  address = '';
  listingType = "1";
  availabledates = [];
  selectedPeriod: any;
  pdfFilter:{};
  myControl = new UntypedFormControl();
  filteredOrgs: Observable<Org[]>;
  filteredCustomers: Observable<Customer[]>;
  total = 0;
  clientAddress: any = {};
  timer: any = [];
  orderList: any = [];
  newOrderForm: UntypedFormGroup;
  date=""
  getUSDate = new Date('01/02/1500').toLocaleString("en-US", { timeZone: "America/New_York" });
  min_Date = new Date(this.getUSDate);
  private _customerList$: BehaviorSubject<any> = new BehaviorSubject([]);
  public customerList$: Observable<any> = this._customerList$.asObservable();
  private _orgList$: BehaviorSubject<any> = new BehaviorSubject([]);
  public orgList$: Observable<any> = this._orgList$.asObservable();
  constructor(
    private auth: AuthService,
    private invoiceService: InvoiceService,
    private clientsService: ClientsService,
    private userService: UsersService,
    private matDialog:MatDialog,
    private router: Router,
    private config: ConfigService,
    private organizationService: OrganizationService,
    private frmBuilder: UntypedFormBuilder,

  ) {
    this.newOrderForm = frmBuilder.group({
      fromDate: [{ disabled: false, value: '' }],
      toDate: [{ disabled: false, value: '' }],

    })
   }



  ngOnInit() {
    this.orgList$.subscribe((data) => { 
      this.orgs = data; 
      if (this.filterBy['Organization'] && !Object.keys(this.filterBy['Organization']).length && this.orgs.length > 0)
        this.filterBy['Organization'] = this.orgs[0]; 
    });
    this.customerList$.subscribe((data) => {  
      this.customers = data; 
      if (this.filterBy['Customer'] && !Object.keys(this.filterBy['Customer']).length && this.customers.length > 0) 
        this.filterBy['Customer'] = this.customers[0];
    })
    this.initDropDown();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(13), 0));
    this.configFilter();
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  configFilter() {
     this.filteredOrgs = this.myControl.valueChanges
      .pipe(startWith({} as Org), map(org => org && typeof org === 'object' ? org['Organization_Name'] : org),
        map(Organization_Name => Organization_Name ? this.filterOrgs(Organization_Name) : this.orgs.slice())
      );
    this.filteredCustomers = this.myControl.valueChanges
      .pipe(
        startWith({} as Customer), map(user => user && typeof user === 'object' ? this.getUserFirstLastName(user) : user),
        map(userFullName => userFullName ? this.filterCustomers(userFullName) : this.customers.slice())
      );
  }
  getUserFirstLastName(user) {
    return user['User_First_Name'] + ' ' + user['User_Last_Name']
  }

  filterOrgs(name): Org[] {
    return this.orgs.filter(option =>
      option.Organization_Name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  filterCustomers(name): Customer[]{
      if (name.indexOf(' ') > -1) {
        name = name.split(' ');
        if (name[0]) {
          return this.customers.filter(option =>
            (option.User_First_Name.toLowerCase().indexOf(name[0].toLowerCase()) === 0) && (option.User_Last_Name.toLowerCase().indexOf(name[1].toLowerCase()) === 0));
        }
      }
      return this.customers.filter(option =>
        (option.User_First_Name.toLowerCase().indexOf(name.toLowerCase()) === 0));
  }

  displayOrgFn(org): string {
    return org ? org.Organization_Name : org;
  }

  displayCustomerFn(user): string {
    return user ? user.User_First_Name + ' ' + user.User_Last_Name : user;
  }

  initDropDown() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) this.getAllOrganizationList()
      else this.getAdminOrganizationList();
    this.getIndividualCustomers();
  }

  detectTypeChange(event) {
    if (event.value == "1") 
  {
    this.clientAddress = {};
    this.filterBy['Organization'] ={};
  }
    else this.filterBy['Customer'] ={};
    this.errors={}
    this.orders = [];
    this.orderList = [];
    this.availabledates = [];
    this._customerList$.next(this.customers);
    this._orgList$.next(this.orgs);
  }

  validateDateFn(event) {
    var formattedDate=moment(event).format('L');
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (formattedDate && !DATE_REGEXP.test(formattedDate)) this.errors['date'] = true;
    else {
      delete this.errors['date']
    }
  }

  validateDateRange() {
    if (this.filterBy['fromDate'] && this.filterBy['toDate'] && (this.filterBy['fromDate'] > this.filterBy['toDate'])) {
      return false;
    } else {
      return true;
    }
  }


  validatateForInvoice() {
    if (!this.validateDateRange()) {
      this.errors['Date_Range'] = true;
      this.orders = [];
      this.orderList = [];
    }
    else {
      delete this.errors['Date_Range'];
      var data = {
        fromDate: this.filterBy['fromDate'],
        toDate: this.filterBy['toDate']
      }
      if (this.listingType == "2") {
          data['Customer_ID'] = this.filterBy['Customer']['User_ID']
          this.getClientAddress();
      }
      else data['Organization_ID'] = this.filterBy['Organization']['Organization_ID']
      if (this.filterBy['fromDate'] && this.filterBy['toDate'] && !this.errors['date'] && this.valideYear()){
        this.fetchInvoice(data);
      }
    }
  }

  fetchInvoice(data) {
    this.pdfFilter = Object.assign({}, data);
    if(!data.Organization_ID && this.listingType == "1"){
      this.availabledates = [];
      this.openErrorPopup(CONSTANTS.apiErrors.selectOrg);
    }else if(!data.Customer_ID && this.listingType == "2"){
      this.availabledates = [];
      this.openErrorPopup(CONSTANTS.apiErrors.selectCust);
    }else this.getInvoiceRecords(data);
  }

  
  getInvoiceRecords(data) {
    this.orderList = [];
    // data.fromDate = momentTimeZone(data.fromDate, 'America/New_York').startOf('day').format("YYYY-MM-DDTHH:mm:ss");
    // data.toDate = momentTimeZone(data.toDate, 'America/New_York').endOf('day').format("YYYY-MM-DDTHH:mm:ss");
    data.fromDate = momentTimeZone(data.fromDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss");
    data.toDate = momentTimeZone(data.toDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss");
    this.invoiceService.getInvoiceOrders(data)
      .subscribe((res) => {
        this.orders = res;
          this.orders.forEach((order) => {
            this.orderList.push(order.Order_ID)
          }, this)
          this.getOrderProductList(this.orderList)
        this.calculateTotal(this.orders)
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      });
  }

  getSpan(event) {
    if (event) {
      if (Object.keys(event).length) {
      if(this.checkForName(event)){
          this.availabledates = [];
         this.getCompletedOrderSpan();
        }
      }
    }
  }

  checkForName(val){
    if(this.listingType == "1"){
      if(this.orgs.findIndex(x => x.Organization_Name == val.Organization_Name) > -1) return true;
      else return false;
    }
    else{
      if(typeof val === 'string'){
        return false;
      }
      else if(typeof val === 'object')return true;
      else return false;
    }
  }

  matchFirstLastName(value){
  return this.filterCustomers(value);
  }

  getOrderProducts(orderId, index) {
    this.invoiceService.getOrderProducts(orderId)
      .subscribe((res) => {
        this.orders[index].Order_Products = res;
      }, (err) => {
        console.log(err);
      });
  }

  getOrderProductList(orderList) {
    this.invoiceService.getOrderProductsLists(orderList)
      .subscribe((res) => {
        let order = res;
        order.forEach((res, index) => {
          this.orders[index].Order_Products = res;
        })
      }, (err) => {
        console.log(err);
      });
  }

  getClientAddress() {
    if (this.filterBy['Customer']['User_ID']) {
      this.clientsService.getClientDetails(this.filterBy['Customer']['User_ID'])
        .subscribe((data) => {
          this.clientAddress=data;
          this.buildAddress(data)
        }, (err) => {
          console.log(err);
        })
    }
  }

  buildAddress(model) {
    this.address = '';
    if (model.Address_1) this.address += model.Address_1
    if (model.Address_2) {
      if (this.address) this.address += ' '
      this.address += model.Address_2
    }
    if (model.Address_City) {
      if (this.address) this.address += ', '
      this.address += model.Address_City
    }
    if (model.Address_State_Abbr) {
      if (this.address) this.address += ', '
      this.address += model.Address_State_Abbr
    }
    if (model.Address_ZipCode) {
      if (this.address) this.address += ' '
      this.address += model.Address_ZipCode
    }
  }

  calculateTotal(orders) {
    this.total = 0;
    orders.forEach((order) => {
      this.total += Number(order.Order_Custom_Price);
      this.total += Number(order.Order_Custom_Price_Tax)
    })
  }

  getIndividualCustomers() {
    this.clientsService.getIndividualClients()
      .subscribe((result) => {        
        this._customerList$.next(result);
      }, (err) => {
        console.log(err);
      })
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  getAdminOrganizationList() {
    this.organizationService.addedOrganizationList(this.auth.getUserId())
      .subscribe((result) => {
        this._orgList$.next(result);
      }, (err) => {
        console.log("err", err);
      })
  }

  getAllOrganizationList() {
    this.organizationService.getAllOrganizations()
      .subscribe((result) => {
        this._orgList$.next(result);
      }, (err) => {
        console.log("err", err);
      })
  }

  setEvalperiod(event) {
    this.orders = [];
    this.orderList = [];
    if (event != -1) {
      this.filterBy['fromDate'] = moment().year(event.year).month(event.month).startOf('month').toDate();
      this.filterBy['toDate'] = moment().year(event.year).month(event.month).endOf('month').toDate();
    }
    this.validatateForInvoice();
  }

  getlastComplMonth() {
    var last = moment().subtract(1, 'months');
    return {
      moment: last,
      month: last.format('MMMM'),
      year: last.year()
    }
  }

  getCompletedOrderSpan() {
    var data = {};
    if (this.listingType == "2") data['Customer_ID'] = this.filterBy['Customer']['User_ID'];
    else data['Organization_ID'] = this.filterBy['Organization']['Organization_ID'];
    this.invoiceService.getInvoiceSpan(data)
      .subscribe((res) => {
        this.generateDateSpan(res, this.getlastComplMonth())
      });
  }

  generateDateSpan(start, end) {
    if (start) {
      var startMoment = moment(start);
      var endMoment = end.moment;
      // while (endMoment.month() >= startMoment.month()) {
      while (endMoment.isSameOrAfter(startMoment,'month')) {
        this.availabledates.push({ moment: endMoment, month: endMoment.format('MMMM'), year: endMoment.year() });
        endMoment.subtract(1, 'month');
      }
      if (!this.availabledates.length) this.availabledates.push(end);
    }
    else this.availabledates.push(end);
    this.selectedPeriod=this.availabledates[0];
    this.filterBy['fromDate'] = moment().year(this.selectedPeriod.year).month(this.selectedPeriod.month).startOf('month').toDate();
    this.filterBy['toDate'] = moment().year(this.selectedPeriod.year).month(this.selectedPeriod.month).endOf('month').toDate();
    this.validatateForInvoice();
  }

  savePDF(){
    this.pdfFilter['fromDate'] = moment(this.pdfFilter['fromDate']).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
    this.pdfFilter['toDate'] = moment(this.pdfFilter['toDate']).endOf('day').format("YYYY-MM-DDTHH:mm:ss")
    this.invoiceService.generatePDF(this.pdfFilter)
      .subscribe((userFile) => {
        var blob = new Blob(this.getByteArray(userFile), { type: 'application/octet-stream' });
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.download = 'invoice.pdf';
        link.href = window.URL.createObjectURL(blob);
        link.click();
        document.body.removeChild(link);
      });
  }

  getByteArray(file) {
    // var byteCharacters = atob(file);
    // var returnArray = [];
    // var byteNumbers = new Array(byteCharacters.length);
    // for (var i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // returnArray.push(new Uint8Array(byteNumbers));
    // return returnArray;
    var sliceSize = 512;

    var byteCharacters = atob(file);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    return byteArrays;
  }
  validateAndresetToDate(event){
    this.validateDateFn(event)
    this.filterBy['toDate']=null;
  }

  getPippinDate(event) {
    if(event && event.Pippin_Date ) {
      this.filterBy['fromDate'] = event.Pippin_Date;
    }else this.filterBy['fromDate'] = null;
    this.validatateForInvoice()
  }
  getPippinDate1(event) {
    if(event && event.Pippin_Date ) {
      this.filterBy['toDate'] = event.Pippin_Date;
    }else this.filterBy['toDate'] = null;
    this.validatateForInvoice()
  }

  getToDateError(){
    return {fromDate:this.filterBy['fromDate'], toDate:this.filterBy['toDate']}
  }

  valideYear(){
    if(this.filterBy['fromDate'] && this.filterBy['toDate']){
      if((this.filterBy['fromDate']._d && this.filterBy['fromDate']._d.getFullYear() >= '1500') && (this.filterBy['toDate']._d && this.filterBy['toDate']._d.getFullYear() >= '1500')) return true;
      else return false;
    }
  }
}
