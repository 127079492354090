import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmComponent } from "app/dialog/confirm/confirm.component";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { ConfigService } from "app/services";
import { DialogService } from "ng2-bootstrap-modal";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import * as momentTimeZone from 'moment-timezone';
import * as moment from 'moment';
import { MatButton } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-rb-deed-section',
  templateUrl: './rb-deed-section.component.html',
  styleUrls: ['./rb-deed-section.component.scss']
})
export class RbDeedSectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() dbData: any;
  @Input() itemData: any;
  @Input() itemIndex: any;
  @Output() dragEnable: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('firstInput') firstInput: MatButton;
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionName = "Chain of Title";
  sectionType = "Chain of Title";
  sectionDocs: any = [];
  sectionTitle: any = "";
  isToggleChanged=false;
  reportDetails = {
    Derived_From: null
  };
  orderDetails: any = {};
  instrumentOptions: any = [];
  getUSDate = new Date("01/02/1500").toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  min_Date = new Date(this.getUSDate);
  today = new Date();
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private config: ConfigService,
    private cdr: ChangeDetectorRef,
    private builder: RptBuilderService,
    private chnageDetRef: ChangeDetectorRef
  ) {
    this.dataForm = this.frmBuilder.group({
      Grantor: [null, [Validators.maxLength(2048)]],
      Instrument_Type_ID: [null],
      Grantee: [null, [Validators.maxLength(2048)]],
      Estate_Of: [null, [Validators.maxLength(2048)]],
      Beneficiaries: [null, [Validators.maxLength(2048)]],
      Instrument_Num: [null, [Validators.maxLength(127)]],
      Page: [null, [Validators.maxLength(127)]],
      Dated_Date: [{ value: '', disable: false }],
      Rec_Date: [{ value: '', disable: false }],
      Comments: [null, [Validators.maxLength(65535)]],
      Internal_Comments: [null, [Validators.maxLength(65535)]],
      Date_Of_Death: [{ value: '', disable: false }],
      Consideration: [null],
      Others: [null, [Validators.maxLength(65535)]],
      Applies: [null],
      Language: ["good", Validators.required],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveItem(this.dataForm));
  }

  ngAfterViewInit(): void {
    this.dataForm.controls["Applies"].patchValue(
      this.itemData.Applies == 1,
      {
        emitEvent: false,
      }
    );
    if (!this.itemData.Status) this.dataForm.disable();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data) {
          let selectedEntity = data.SP_Chain_Of_Title_Entities.find(
            (entity) => entity.Id == this.itemData.Entity_ID
          );
          if (selectedEntity !== undefined)
            this.sectionTitle = selectedEntity.Entity;
        }
        this.instrumentOptions = data.SP_Chain_Of_Title_Instrument_Types;
      });
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderDetails = data));
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData && data.dbData.Id !== undefined) {
          this.reportDetails = data.dbData;
          if (
            this.orderDetails &&
            this.orderDetails.Order_ID !== undefined &&
            this.orderDetails.Order_ID !== ""
          ) {
            this.builder.manageError(
              `Chain_Of_Title_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
              this.dataForm.status !== "VALID",
              this.dataForm.controls
            );
            this.cdr.detectChanges();
          }
        }
      });
    this.cdr.detectChanges();
  }

  enableDrag(event) {
    this.dragEnable.emit(true);
  }

  diasbleDrag() {
    this.dragEnable.emit(false);
  }

  toggleApplies(event) {
    this.itemData.Applies = event ? 1 : 0;
    this.isToggleChanged=true;
  }

  saveItem(event) {
    if (
      this.orderDetails &&
      this.orderDetails.Order_ID !== undefined &&
      this.itemData.Status
    )
      this.builder.manageError(
        `Chain_Of_Title_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine) {
      let payload = {
        curData: this.builder.trimInput(event, this.itemData),
        dbData: this.dbData,
        Sp_Id: this.reportDetails["Id"],
        Derived_From: this.reportDetails["Derived_From"],
        toggleControl:{changed:this.isToggleChanged,state:this.itemData.Applies}
      };
      this.isToggleChanged=false
      this.builder.saveChain(payload, false).pipe(take(1)).subscribe();
    }
  }

  deleteItem() {
    this.matDialog
      .open(
        ConfirmComponent,
       {data: {
          title: `Delete ${this.sectionTitle}`,
          message: `Are you sure you want to delete this ${this.sectionTitle.toLowerCase()} ?`,
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.itemData.Status = 0;
          let payload = {
            curData: this.itemData,
            dbData: this.dbData,
            Sp_Id: this.reportDetails["Id"],
            Derived_From: this.reportDetails["Derived_From"],
          };
          this.builder.manageError(
            `Chain_Of_Title_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
            false,
            {}
          );
          this.builder.saveChain(payload, true).pipe(take(1)).subscribe();
        } else {
          console.log("Closed", res);
        }
      });
  }

  handleCommitmentError(isValid) {
    this.dataForm.controls["Language"].setValue(isValid ? "good" : null);
  }

  onInsTypehange() {
    this.dataForm.controls['Others'].reset();
  }

  getInsTypesForDeed() {
    return this.instrumentOptions.filter(
      (option) => (option.Type_ID == 1 || option.Type_ID == 99)
    );
  }

  getInsTypesForEstate() {
    return this.instrumentOptions.filter(
      (option) => (option.Type_ID == 2 || option.Type_ID == 99)
    );
  }

  getPippinDateDeed(event) {
    if (event && event.Pippin_Date) {
      this.itemData['Dated_Date'] = event.Pippin_Date;
    } else {
      this.itemData['Dated_Date'] = null;
    }
  }
  getPippinDateOD(event) {
    if (event && event.Pippin_Date) {
      this.itemData['Date_Of_Death'] = event.Pippin_Date;
      this.itemData['Dated_Date'] = event.Pippin_Date;
    } else {
      this.itemData['Date_Of_Death'] = null;
    }
  }
  getPippinDateRec(event) {
    if (event && event.Pippin_Date) {
      this.itemData['Rec_Date'] = event.Pippin_Date;
    } else {
      this.itemData['Rec_Date'] = null;
    }
  }

  checkForETAValidation() {
    if (this.dataForm) {
      if (!this.dataForm.valid && !this.dataForm.touched) return true;
      else false;
    } else false
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "CHN_TL",
      this.itemData.Id,
      fieldName
    );
  }
}
