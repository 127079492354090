import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { MatSelect } from '@angular/material/select';
import { AuthService, OrganizationService, UsersService } from '../../services';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-add-exception',
  templateUrl: './add-exception.component.html',
  styleUrls: ['./add-exception.component.scss']
})
export class AddExceptionComponent{
  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('selectBtn') focusedElement1: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef


  message: any;
  title = '';
  timer: any = [];
  errorMsg: any = {};
  addExpForm: UntypedFormGroup
  changeModel: any = {};
  excludedDomainName='';


  constructor(
    private organizationService: OrganizationService,
    private auth: AuthService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddExceptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.addExpForm = fb.group({
      'excludedDomainName': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
    });
    this.title=this.data.title;
    this.message=this.data.message;
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => { this.myFirstFocus.nativeElement.focus() }, 0));

  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  confirm() {
    this.errorMsg = '';
    let obj = {};
    obj['Excluded_Domain_Name'] = this.changeModel.excludedDomainName;
    obj['Admin_User_ID'] = this.auth.getUserId();
    this.organizationService.createNewException(obj)
      .subscribe(res => {
        this.dialogRef.close(true);
        // this.result = true;
        this.clearTime();
      }, (err) => {
        this.errorMsg = err;
      });

  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    this.clearTime()
  }

  clearTime() {
    this.timer.forEach((time) => {
      clearTimeout(time)
    });
  }

}
