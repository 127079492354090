import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService, QuickLinksService, UsermessageService, ConfigService } from '../services';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from '../dialog/confirm/confirm.component';
import { CONSTANTS } from '../app.constants';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-quicklink-form',
  templateUrl: './quicklink-form.component.html',
  styleUrls: ['./quicklink-form.component.scss']
})
export class QuicklinkFormComponent implements OnInit {
  quickLinksForm: any = {};
  error: any = {};
  cancelCreateForm: boolean = false;
  quicklinkConfig : any = {};
  @Input() linkData: any
  @Output() deleteEvent = new EventEmitter<boolean>();
  @Output() saveEvent = new EventEmitter<boolean>();
  @Output() createEvent = new EventEmitter<boolean>();
  @Output() cancelEvent = new EventEmitter<boolean>();
  constructor(
    private frmBuilder: UntypedFormBuilder,
    public quickLinksService: QuickLinksService,
    private userMsg: UsermessageService,
    private matDialog:MatDialog,
    private config: ConfigService,
    public auth: AuthService,

  ) {
    this.quickLinksForm = frmBuilder.group({
      'Quick_Links_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'Quick_Links_URL': [null, Validators.compose([Validators.required, Validators.maxLength(4096)])],
    })
    this.quickLinksForm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    this.linkDatatoquicklinkConfig();
  }

  linkDatatoquicklinkConfig(){
    this.quicklinkConfig.Quick_Links_Name = this.linkData.Quick_Links_Name;
    this.quicklinkConfig.Quick_Links_URL = this.linkData.Quick_Links_URL;
    this.quicklinkConfig.Quick_Links_ID = this.linkData.Quick_Links_ID;
  }

  createQuickLink(data) {
    this.error = {};
    this.quickLinksService.createQuickLink(data)
      .subscribe((updateRes) => {
        this.createEvent.emit(true);
        this.cancelCreateFrm();
        this.userMsg.setSuccessMsg("Quick link Created successfully");
      }, (err) => {
        this.error = err;
      })

  }

  EditQuickLink(data) {
    this.error = {};
    this.quickLinksService.editQuickLink(data.Quick_Links_Name, data.Quick_Links_URL, data.Quick_Links_ID)
      .subscribe((updateRes) => {
        this.saveEvent.emit(true);
        this.userMsg.setSuccessMsg("Quick link Updated successfully");
      }, (err) => {
        this.error = err;

      })

  }

  DeleteQuickLink(data) {
    this.matDialog.open(ConfirmComponent, {data:{
      title: 'Delete Quick Link',
      message: 'Are you sure you want to delete ' + data.Quick_Links_Name + ' ?'
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.error = {};
          this.quickLinksService.deleteQuickLink(data.Quick_Links_ID)
            .subscribe((deleteRes) => {
              this.deleteEvent.emit(true);
              this.userMsg.setSuccessMsg("Quick link deleted successfully");
            }, (err) => {
              this.error = err;
            })

        }
      })
  }

  cancelCreateFrm() {
    this.linkData.Quick_Links_Name = null;
    this.linkData.Quick_Links_URL = null;
    this.linkData.Cancel = true;
    this.cancelEvent.emit(true);
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  } 

}
