import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { CONSTANTS } from '../../app.constants';
import { AuthService, OrganizationService, UsersService } from '../../services';
import { UntypedFormGroup,Validators,UntypedFormBuilder } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatRadioButton } from '@angular/material/radio';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-company',
  templateUrl: './change-company.component.html',
  styleUrls: ['./change-company.component.scss']
})
export class ChangeCompanyComponent{

  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('selectBtn') focusedElement1: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: MatRadioButton;

  constants = CONSTANTS
  message: any;
  title = '';
  listingType = '0';
  organizations = [];
  selectedOrgId = '';
  domainName = '';
  organizationName = '';
  errorMsg: any = {};
  user : any = {};
  changeModel:any = {};
  changeCompFrm:UntypedFormGroup
  timer: any = [];

  constructor(
    private organizationService: OrganizationService,
    private usersService: UsersService,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    public dialogRef: MatDialogRef<ChangeCompanyComponent>,
    private matDialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.changeCompFrm = fb.group({
      'organizationName': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
    })
  }

  ngOnInit() {
    this.initDialog();
    this.user = Object.assign({},this.message);
    this.usersService.getDomainName(this.user['Email_Address']).then((res) => {
      this.domainName = res + '';
      if (this.domainName) {
        this.organizationService.getOrganizationsForDomainName(this.domainName)
          .subscribe((domainRes) => {
            if (domainRes) {
              this.organizations = domainRes;
            }
          }, (err) => {
          })
      }
    });
    this.timer.push(setTimeout(() => { this.myFirstFocus.focus() }, 0))
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  confirm() {
    this.errorMsg = '';
    this.user['Organization_ID'] = '';
    if (this.listingType == "0") this.user['Organization_ID'] = this.selectedOrgId;
    else this.user['Organization_Name'] = this.changeModel.organizationName;
    this.user['Organization_Domain']= this.domainName;
    this.user['User_Modified_By']= this.auth.getUserId();
    this.user['Admin_User_ID']= this.auth.getUserId();
    this.usersService.changeCompany(this.user)
      .subscribe(res => {
        this.dialogRef.close(true);
        this.clearTime();
      }, (err) => {
        this.errorMsg = err;
      });
  }

  checkIfOrgAvailable(orgID) {
    this.errorMsg = '';
    if(orgID == this.message['Organization_ID']){
      return false;
    }
    else return true;
  }


  isDisabled() {
    if (this.listingType == '0') {
      this.changeCompFrm.controls['organizationName'].markAsUntouched();
      this.changeModel.organizationName = null;
      return this.selectedOrgId == '';
    }
    else return this.changeModel.organizationName == '';
  }

  Close() {
    this.dialogRef.close(false);
    this.clearTime()
  }


  isNewCompany() {
    return this.listingType;
  }

  validateCompany() {
    if (this.listingType == "1") {
      if (this.changeCompFrm.controls['organizationName'].errors)
        return true;
    } else return false;
  }
  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
