import { Component, OnDestroy, OnInit } from "@angular/core";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { OrdersService } from "app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-chk-legal",
  templateUrl: "./chk-legal.component.html",
  styleUrls: ["./chk-legal.component.scss"],
})
export class ChkLegalComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  legalDesc: any;
  orderId: any;

  constructor(
    private builder: RptBuilderService,
    private ordersService: OrdersService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.curData)
          this.legalDesc = data.curData.Legal_Description;
      });
    this.ordersService.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderId = data.Order_ID));
  }
}
