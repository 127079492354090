import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { OrganizationService, ConfigService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSTANTS } from '../../app.constants';
import { MatRadioButton } from '@angular/material/radio';

@Component({
  selector: 'app-client-type',
  templateUrl: './client-type.component.html',
  styleUrls: ['./client-type.component.scss']
})
export class ClientTypeComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: MatRadioButton
  constants = CONSTANTS
  organizations:any = [];
  userType = "1";
  selectedOrgId = '';
  timer:any = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private config: ConfigService,
    private location: Location,
    private organizationService:OrganizationService
  ) {}

  ngOnInit() {
    this.getAllOrganizationList();
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.focus();
    }, 0));
  }
  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  getAllOrganizationList() {
    this.organizationService.getAllOrganizations()
    .subscribe((result) => this.organizations = result, (err) => console.log("err", err))
  }

  goBack() {
    this.location.back();    
  }


  isDisabled(){
    return this.userType == '2' && this.selectedOrgId == '';
  }

  createClient(){
    var clientType = "individual"
    if(this.userType == '2') clientType = this.selectedOrgId
    this.router.navigate(['/client/create/' + clientType]);
  }

  isIndividual(){
    if(Number(this.userType) == CONSTANTS.userType.individual) return true;
    else return false;
  }


}
