// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-grey {
  background-color: #fafafa;
  color: #949494;
  padding: 8px 0 0 0;
}

.blk-commit {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.det-table {
  width: 100%;
  table-layout: fixed;
}

.cmmtmnt-body tr:hover {
  cursor: default;
}

.cmmtmnt-itm .edt-btn {
  display: none;
}

.cmmtmnt-itm:hover .edt-btn {
  display: inline;
  position: relative;
  cursor: pointer;
  color: darkblue;
}`, "",{"version":3,"sources":["webpack://./src/app/organizations/org-commitments/org-commitments.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;AACF","sourcesContent":[".bg-grey {\n  background-color: #fafafa;\n  color: #949494;\n  padding: 8px 0 0 0;\n}\n\n.blk-commit {\n  max-height: 400px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n\n.det-table {\n  width: 100%;\n  table-layout: fixed;\n}\n\n.cmmtmnt-body tr:hover {\n  cursor: default;\n}\n\n.cmmtmnt-itm .edt-btn {\n  display: none;\n}\n\n.cmmtmnt-itm:hover .edt-btn {\n  display: inline;\n  position: relative;\n  cursor: pointer;\n  color: darkblue;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
