
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { CONSTANTS } from '../../app.constants';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';

@Injectable()
export class MessagesService {

    unReadCount = 0;
    direct: HttpClient;
    constructor(
        private router: Router,
        private auth: AuthService,
        private config: ConfigService,
        private http: HttpClient,
        private handler: HttpBackend
    ) { 
        this.direct = new HttpClient(this.handler);
    }

    getAllClientMsgs(pageNum, sortBy, orderBy, searchStr, data) {
        // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
        // let options = new RequestOptions({ headers: headers });
        let httpOptions = {
            headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
          }
        let url = this.config.getBasePath() + '/messages/client/all';
        url = url + '/page/' + pageNum + '/size/' + this.config.getNoOfRecordsPerPage();
        if (sortBy != '') {
            url = url + '/sort/' + sortBy + '/order/' + orderBy;
        }
        if (searchStr) {
            url = url + '/search/' + searchStr;
        }
        return this.http.post(url, data, httpOptions).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }

    getBackgroundMsgs(pageNum, sortBy, orderBy, searchStr, data) {
        let httpOptions = {
            headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
          }
        let url = this.config.getBasePath() + '/messages/client/all';
        url = url + '/page/' + pageNum + '/size/' + this.config.getNoOfRecordsPerPage();
        if (sortBy != '') {
            url = url + '/sort/' + sortBy + '/order/' + orderBy;
        }
        if (searchStr) {
            url = url + '/search/' + searchStr;
        }
        return this.direct.post(url, data, httpOptions).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }

    getAllUnreadCount(data) {
        let httpOptions = {
            headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
          }
        let url = this.config.getBasePath() + '/messages/admin/unread/count';
        return this.direct.post(url, data, httpOptions).pipe(
            map((response: any) => {
                this.unReadCount = response;
                return response;
            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }

     getAllUnreadCountForAdmin(data) {
        let httpOptions = {
            headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
          }
        let url = this.config.getBasePath() + '/messages/admin/unread/count';
        return this.direct.post(url, data, httpOptions).pipe(
            map((response: any) => {
                this.unReadCount = response;
                return response;
            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }

    getBackgroundUnreadCount(data) {
        let httpOptions = {
            headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
          }
        let url = this.config.getBasePath() + '/messages/admin/unread/count';
        return this.direct.post(url, data, httpOptions).pipe(
            map((response: any) => {
                this.unReadCount = response;
                return response;
            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }

    updateToRead(msgId) {
        let httpOptions = {
            headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
            observe: 'response' as 'body'
          }
        let url = this.config.getBasePath() + '/messages/' + msgId + '/updatetoRead';
        return this.direct.put(url, null, httpOptions).pipe(
            map((response: any) => {
                return response;

            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }

    updateParentId(msgData) {
        msgData['assignType'] = CONSTANTS.SMCompleteMsgType.inactive;
        let httpOptions = {
            headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
            observe: 'response' as 'body'
          }
        let url = this.config.getBasePath() + '/messages/admin/' + msgData.Message_ID + '/updateparentid'
        return this.http.put(url, msgData, httpOptions).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }

    updateMsgToRead(msgData) {
        let httpOptions = {
            headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
            observe: 'response' as 'body'
          }
        let url = this.config.getBasePath() + '/messages/update/read'
        return this.http.put(url, { Message: msgData, Admin_User_ID: this.auth.getUserId() }, httpOptions).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }

    getMsgData(msgId) {
        let httpOptions = {
            headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
          }
        let url = this.config.getBasePath() + '/messages/message/admin/' + msgId
        return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }

    getMsgInfoData(msgId) {
        let httpOptions = {
            headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
          }
        let url = this.config.getBasePath() + '/messages/message/admin/' + msgId + '/details'
        return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }

    getAllMsgs(msgId) {
        let httpOptions = {
            headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
          }
        let url = this.config.getBasePath() + '/messages/parentmsg/admin/' + msgId
        return this.http.get(url, httpOptions).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }



}
