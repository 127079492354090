// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-remove {
  border: 0;
  border-radius: 10em !important;
  background-color: #ff3547 !important;
  color: #fff;
}

.table-remove:hover {
  cursor: pointer;
}

tbody tr:hover:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: #212529 !important;
}

tbody tr:hover:nth-child(even) {
  background-color: #fff !important;
  color: #212529 !important;
}

.textWrap {
  word-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/bulk-upload/table-editable/table-editable.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,8BAAA;EACA,oCAAA;EACA,WAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,gDAAA;EACA,yBAAA;AACJ;;AAEA;EACI,iCAAA;EACA,yBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ","sourcesContent":[".table-remove {\n    border:0;\n    border-radius: 10em !important;\n    background-color: #ff3547!important;\n    color: #fff;\n}\n\n.table-remove:hover{\n    cursor: pointer;\n}\n\ntbody tr:hover:nth-child(odd) {\n    background-color: rgba(0, 0, 0, 0.05) !important;\n    color: #212529 !important;\n}\n\ntbody tr:hover:nth-child(even) {\n    background-color: #fff !important;\n    color: #212529 !important;\n}\n\n.textWrap {\n    word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
