import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { RptBuilderService } from "../rpt-builder.service";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "app-rb-commitments",
  templateUrl: "./rb-commitments.component.html",
  styleUrls: ["./rb-commitments.component.scss"],
})
export class RbCommitmentsComponent implements OnInit, OnDestroy {
  @Input() sectionData: any;
  @Input() sectionName: any;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter();
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  searchPackage: any = {};
  orderDetails: any;
  isQualiaOrder = false;
  isRemoteLanguage = null;
  isCreateSend = false;
  sectionType: any = 0;
  commitmentItemTypes: any;
  categoryOptions = [
    { label: "Requirement", value: "R" },
    { label: "Exception", value: "E" },
    { label: "Vesting", value: "V" },
    { label: "No Entry", value: "N" },
  ];
  Cust_Commit_Type_Id: any;
  commitmentItem: any = {};
  dbCommitmentItem: any = null;
  fieldSet = [];
  placeHolderPairs = [
    ["_", "_"],
    ["{", "}"],
  ];
  Cmtmnt_Mappings = {
    1: "SRC_PT",
    2: "ASS_NS",
    3: "TAX_ES",
    4: "VES_DD",
    5: "CHN_TL",
    6: "SCR_IN",
    7: "LEN_JG",
    8: "ERA",
  };
  pippinExpsItems: any = [];
  pippinReqsItems: any = [];
  errors: any = [];
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private builder: RptBuilderService
  ) {
    this.dataForm = this.frmBuilder.group({
      Category: [null],
      Type_Id: [null],
      Statement: [null],
      Language: [null],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveItem(this.dataForm));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initCommitment(data));
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dataTypes) => {
        if (dataTypes && dataTypes.SP_Item_Types !== undefined) {
          let sectionBlock = dataTypes.SP_Item_Types.find(
            (docType) =>
              docType.Name.toLowerCase() == this.sectionName.toLowerCase()
          );
          if (sectionBlock !== undefined) {
            this.sectionType = sectionBlock.Id;
            this.initComponent();
          }
        }
      });
    this.builder.isRemoteLanguage$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.isRemoteLanguage = data;
        this.validateLanguage(this.commitmentItem["Language"]);
      });
    this.builder.isQualiaOrder$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.isQualiaOrder = data;
      });
    this.builder.commtmntItmTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.commitmentItemTypes = data;
        if (this.commitmentItemTypes && !this.isQualiaOrder) {
          this.pippinReqsItems = this.commitmentItemTypes.filter(
            (item) => item && item.Commitment_Type && item.Commitment_Type == 1
          );
          this.pippinExpsItems = this.commitmentItemTypes.filter(
            (item) => item && item.Commitment_Type && item.Commitment_Type == 2
          );
        }
      });
    this.dataForm.controls["Language"].valueChanges.subscribe((value) => {
      this.validateLanguage(value);
    });
  }

  collectCommitment() {
    this.builder.commitments$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((commitments) => {
        if (commitments && commitments.curData) {
          let curItem = commitments.curData.find(
            (ci) =>
              ci.Sp_Id == this.searchPackage.Id &&
              ci.Item_Type_Id == this.sectionType &&
              ci.Record_Id == this.sectionData.Id
          );
          if (curItem !== undefined) {
            this.commitmentItem = curItem;
            this.dbCommitmentItem = { ...curItem };
            this.getFieldSet();
            this.validateForm();
          } else this.createCommitment();
        }
      });
  }

  isFieldSetArray() {
    return Array.isArray(this.fieldSet);
  }

  createCommitment() {
    if (!this.isCreateSend) {
      this.isCreateSend = true;
      this.commitmentItem = {
        Sp_Id: this.searchPackage.Id,
        Pippin_Order_Id: this.orderDetails.Order_ID,
        Item_Type_Id: this.sectionType,
        Record_Id: this.sectionData.Id,
        Category: "N",
        Field_Set: [],
        Statement: "",
        Language: "",
        SortOrder: this.sectionData.Sort_Order,
        Cust_Commit_Type_Id: null,
        Applies: 1,
      };
      this.builder
        .createCommitment(this.commitmentItem, this.sectionData.Sp_Id)
        .subscribe();
    }
  }

  initComponent() {
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData) {
          this.searchPackage = data.dbData;
          if (this.searchPackage && this.searchPackage.Id !== undefined)
            this.collectCommitment();
        }
      });
  }

  initCommitment(order) {
    this.orderDetails = order;
  }

  getEntities() {
    return this.isQualiaOrder
      ? this.getQualiaEntities()
      : this.getPippinEntities();
  }

  getQualiaEntities() {
    if (this.commitmentItemTypes) {
      if (
        this.commitmentItem &&
        this.commitmentItem["Category"] == "R" &&
        this.commitmentItemTypes.accepted_requirements
      )
        return this.commitmentItemTypes.accepted_requirements;
      else if (
        this.commitmentItem &&
        this.commitmentItem["Category"] == "E" &&
        this.commitmentItemTypes.accepted_exceptions
      )
        return this.commitmentItemTypes.accepted_exceptions;
      else return [];
    } else return [];
  }

  getPippinEntities() {
    if (this.commitmentItemTypes && this.commitmentItemTypes.length > 0) {
      if (this.commitmentItem && this.commitmentItem["Category"] == "R")
        // return this.commitmentItemTypes.filter(
        //   (itm) => itm.Commitment_Type == 1
        // );
        return this.pippinReqsItems;
      else if (this.commitmentItem && this.commitmentItem["Category"] == "E")
        // return this.commitmentItemTypes.filter(
        //   (itm) => itm.Commitment_Type == 2
        // );
        return this.pippinExpsItems;
      else return [];
    } else return [];
  }

  getSearchKey() {
    return this.isQualiaOrder ? ["description", "code"] : "Type_Name";
  }

  getSelectKey() {
    return this.isQualiaOrder ? "Index" : "Cust_Commit_Type_Id";
  }

  getFilteredOptions() {
    if (this.sectionType > 0) {
      if ([4, 5].includes(this.sectionType))
        return this.categoryOptions.filter(
          (ops) => ops.value == "V" || ops.value == "N"
        );
      else
        return this.categoryOptions.filter(
          (ops) => ops.value == "R" || ops.value == "E" || ops.value == "N"
        );
    }
  }

  getLabel(itemVal) {
    let selectedOpt = this.categoryOptions.filter(
      (cat) => cat.value == itemVal
    );
    return selectedOpt[0].label;
  }

  getFieldSet() {
    if (this.commitmentItem && this.commitmentItem["Field_Set"]) {
      if (typeof this.commitmentItem["Field_Set"] == "string") {
        if (this.commitmentItem["Field_Set"].length == 4)
          this.commitmentItem["Field_Set"] = [];
        else
          this.commitmentItem["Field_Set"] = JSON.parse(
            this.commitmentItem["Field_Set"]
          );
      }
      this.fieldSet = this.commitmentItem["Field_Set"];
      if (typeof this.fieldSet !== "object")
        this.fieldSet = JSON.parse(this.fieldSet);
    }
  }

  saveItem(event) {
    if (!event.pristine) this.saveCommitment();
  }

  saveCommitment() {
    this.commitmentItem.Language = this.commitmentItem.Language.trim();
    this.builder
      .updatecommitment(
        this.commitmentItem,
        this.dbCommitmentItem,
        this.searchPackage.Id
      )
      .pipe(take(1))
      .subscribe();
  }

  handleTypeChange() {
    this.commitmentItem["Language"] = "";
    this.commitmentItem["Statement"] = "";
    this.commitmentItem["Cust_Commit_Type_Id"] = null;
    this.commitmentItem["Field_Set"] = [];
    this.fieldSet = [];
    this.isValid.emit(true);
  }

  optionChangeHandler(event) {
    if (event) {
      if (this.isQualiaOrder) this.handleQualiaOptionChange(event);
      else this.handlePippinOptionChange(event);
    }
  }

  handleQualiaOptionChange(event) {
    let optionList =
      this.commitmentItem["Category"] == "R"
        ? this.commitmentItemTypes.accepted_requirements
        : this.commitmentItemTypes.accepted_exceptions;
    let matchedIndex = optionList.findIndex(
      (itemTypes) => itemTypes.Index == event
    );
    if (matchedIndex > -1) {
      this.commitmentItem["Statement"] = optionList[matchedIndex].sampleText;
      this.commitmentItem["Language"] = optionList[matchedIndex].sampleText;
    }
  }

  handlePippinOptionChange(event) {
    let commitType = 1;
    if (this.commitmentItem["Category"] == "E") commitType = 2;
    let matchedIndex = this.commitmentItemTypes.findIndex(
      (itemTypes) =>
        itemTypes.Cust_Commit_Type_Id == event &&
        itemTypes.Commitment_Type == commitType
    );
    if (matchedIndex > -1) {
      this.commitmentItem["Statement"] =
        this.commitmentItemTypes[matchedIndex].Language;
      this.commitmentItem["Language"] =
        this.commitmentItemTypes[matchedIndex].Language;
      this.getLangFieldSet(this.commitmentItemTypes[matchedIndex].Language);
    }
  }

  getLangFieldSet(lang) {
    this.commitmentItem["Field_Set"] = [];
    this.commitmentItem["Language"] = lang;
    this.placeHolderPairs.forEach((ph) => {
      let elmStart = false;
      let elm = "";
      let lastChar = "";
      lang.split("").forEach((ch) => {
        if (elmStart) {
          elm += ch;
          lastChar = ch;
          if (ch == ph[1]) {
            elmStart = false;
            let field = this.commitmentItem["Field_Set"].find(
              (fld) => fld.key == elm
            );
            if (elm.length > 2 && field == undefined) {
              lastChar = "";
              this.commitmentItem["Field_Set"].push({ key: elm, val: "" });
            }
          }
        } else if (ch == ph[0] && lastChar !== ph[0]) {
          lastChar = ch;
          elmStart = true;
          elm = ch;
        } else lastChar = ch;
      });
    });
    this.getFieldSet();
  }

  isEmpty(value) {
    for (let prop in value) {
      if (value.hasOwnProperty(prop)) return false;
    }
    return true;
  }

  fieldChangeHandler(field, event) {
    if (field && field != undefined) {
      field.val = event;
      this.setLanguageText();
      this.validateForm();
    }
  }

  validateForm() {
    if (this.isRemoteLanguage)
      this.validateLanguage(this.commitmentItem["Language"]);
    else if (this.isRemoteLanguage !== null) {
      if (typeof this.fieldSet !== "object")
        this.fieldSet = JSON.parse(this.fieldSet);
      if (this.fieldSet.length > 0) {
        if (typeof this.fieldSet !== "object")
          this.fieldSet = JSON.parse(this.fieldSet);
        if (typeof this.fieldSet == "object") {
          let errFieldIndex = this.fieldSet.findIndex((fld) => fld.val === "");
          if (errFieldIndex > -1) {
            setTimeout(() => {
              if (!this.dataForm.controls["Language"].hasError("incorrect")) {
                this.dataForm.controls["Language"].setErrors({
                  incorrect: true,
                });
                this.dataForm.controls["Language"].markAsTouched();
                this.isValid.emit(false);
              }
            }, 0);
          } else {
            this.dataForm.controls["Language"].setErrors(null);
            this.isValid.emit(true);
          }
        }
      }
    }
  }

  setLanguageText() {
    let newLang = this.commitmentItem["Statement"];
    this.placeHolderPairs.forEach((ph) => {
      let curLang = newLang;
      newLang = "";
      let elmStart = false;
      let elm = "";
      let lastChar = "";
      curLang.split("").forEach((ch) => {
        if (elmStart) {
          elm += ch;
          lastChar = ch;
          if (ch == ph[1]) {
            elmStart = false;
            if (elm.length > 2) {
              lastChar = "";
              let field = this.commitmentItem.Field_Set.find(
                (fld) => fld.key == elm
              );
              if (field.val == "" || !field.val) newLang += `${field.key}`;
              else {
                let newVal = field.val;
                if (this.isDateValue(newVal))
                  newVal = moment(newVal).format("M/D/YYYY");
                newLang += `${newVal}`;
              }
            } else newLang += elm;
          }
        } else if (ch == ph[0] && lastChar !== ph[0]) {
          lastChar = ch;
          elmStart = true;
          elm = ch;
        } else {
          lastChar = ch;
          newLang += ch;
        }
      });
    });
    this.commitmentItem["Language"] = newLang;
    this.saveCommitment();
  }

  isDateValue(inpDate: any) {
    return inpDate instanceof Date && !isNaN(inpDate.valueOf());
  }

  isEntitiesPresent() {
    return this.isQualiaOrder
      ? this.getQualiaEntities().length > 0
      : this.getPippinEntities().length > 0;
  }

  isCommitmentModified(fieldName) {
    if (this.commitmentItem.Derived_From && this.commitmentItem.Status)
      return this.builder.isFieldModified(
        this.searchPackage.Id,
        this.Cmtmnt_Mappings[this.commitmentItem.Item_Type_Id],
        this.commitmentItem.Record_Id,
        this.commitmentItem.Id
      );
    else return false;
  }

  validateLanguage(val) {
    if (val && val !== undefined && this.isRemoteLanguage) {
      this.errors = [];
      let lastChar = "";
      this.placeHolderPairs.forEach((ph) => {
        let elmStart = false;
        let elm = "";
        val.split("").forEach((ch) => {
          if (elmStart) {
            elm += ch;
            lastChar = ch;
            if (ch == ph[1]) {
              elmStart = false;
              if (elm.length > 2) {
                lastChar = "";
                this.errors.push(elm);
              }
            }
          } else if (ch == ph[0] && lastChar !== ph[0]) {
            lastChar = ch;
            elmStart = true;
            elm = ch;
          } else lastChar = ch;
        });
      });
      if (this.dataForm.controls["Language"]) {
        if (
          this.errors.length == 0
        ) {
          this.dataForm.controls["Language"].setErrors(null);
          this.isValid.emit(true);
        } else if (
          this.errors.length > 0 &&
          this.dataForm.controls["Language"].errors == null
        ) {
          setTimeout(() => {
            this.dataForm.controls["Language"].setErrors({ incorrect: true });
            this.dataForm.controls["Language"].markAsTouched();
            this.isValid.emit(false);
          }, 0);
        }
      }
    }
  }
}
