// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cot-tl-red {
  height: 4px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #EE4751;
}

.cot-tl-blue {
  height: 4px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #428BCA;
}

.cot-tl {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cot-yrs {
  padding: 8px 0;
}

.cot-clr-red {
  color: #EE4751;
}

.cot-pad {
  padding-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/order-checklist/chk-cot/chk-cot.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".cot-tl-red{\n  height: 4px;\n  flex-shrink: 0;\n  border-radius: 5px;\n  background: #EE4751;\n}\n\n.cot-tl-blue{\n  height: 4px;\n  flex-shrink: 0;\n  border-radius: 5px;\n  background: #428BCA;\n}\n\n.cot-tl{\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.cot-yrs{\n  padding: 8px 0;\n}\n\n.cot-clr-red{\n  color: #EE4751;\n}\n\n.cot-pad{\n  padding-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
