import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AboutService, ConfigService } from '../../services';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivacyComponent implements OnInit {
  // innerText:String = ''
  timer: any = [];
  constructor(
    private pages: AboutService,
    private config: ConfigService
  ) { }

  ngOnInit() {
    // this.pages.getWithToken(3).subscribe((res)=>{
    //   this.innerText = res.Page_Text;
    // });
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
  }
  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
