// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.server-down-container {
  text-align: center;
  font-family: Arial, sans-serif;
}
.server-down-container img {
  max-width: 300px;
  margin-bottom: 20px;
}
.server-down-container h1 {
  color: #FF0000;
}
.server-down-container p {
  color: #666666;
  font-size: 18px;
  line-height: 1.6;
}
.server-down-container .illustration {
  padding-top: 6rem;
}`, "",{"version":3,"sources":["webpack://./src/app/common/e500/e500.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,8BAAA;AACF;AACE;EACE,gBAAA;EACA,mBAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AAGE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAGE;EACI,iBAAA;AADN","sourcesContent":[".server-down-container {\n  text-align: center;\n  font-family: Arial, sans-serif;\n\n  img {\n    max-width: 300px;\n    margin-bottom: 20px;\n  }\n\n  h1 {\n    color: #FF0000;\n  }\n\n  p {\n    color: #666666;\n    font-size: 18px;\n    line-height: 1.6;\n  }\n  .illustration {\n      padding-top: 6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
