import {throwError as observableThrowError,  Observable } from 'rxjs';

import { catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class EmailTemplatesService {

  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient,
    private cookies: CookieService
  ) { }

  getEmailTemplates(pageNum) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/email-templates/';
    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getEmailTemplateById(templateId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/email-templates/' + templateId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createEmailTemplate(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/email-templates/new';
    return this.http.post(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateEmailTemplate(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/email-templates/' + model.Template_Email_ID;
    return this.http.put(url, { Template : model, Modified_By: this.auth.getUserId()}, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  manageEmailTemplateMappings(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/email-templates/products/mapping/manage';
    return this.http.put(url, { Mapping : data, Modified_By: this.auth.getUserId()}, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getEmailTemplateMappings(templateId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/email-templates/'+ templateId +'/products/mapping';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getTemplatesForProducts() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/email-templates/products/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  clearTemplateMapping(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/email-templates/remove/mappings';
    return this.http.put(url, { Mapping : data, Modified_By: this.auth.getUserId()}, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  sanitizeTextData(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
  
    const defaultStyles = {
      fontSize: '14px',
      fontFamily: 'Arial, sans-serif',
    };
  
    const elementsWithStyle = doc.querySelectorAll('[style]');
  
    elementsWithStyle.forEach((element) => {
      const style = element.getAttribute('style') || '';
  
      const styleArray = style.split(';').map(rule => rule.trim()).filter(rule => rule);
  
      const styleMap = {};
   
      styleArray.forEach((rule) => {
        const [property, value] = rule.split(':').map(part => part.trim());
        if (property === 'font-size') {
          styleMap['font-size'] = `${defaultStyles.fontSize}`; 
        } else if (property === 'font-family') {
          styleMap['font-family'] = `${defaultStyles.fontFamily}`; 
        } else if (property === 'font') {
          styleMap['font-size'] = defaultStyles.fontSize;
          styleMap['font-family'] = defaultStyles.fontFamily;
        } else if (property === 'background-color') {
          return; 
        }else {
          styleMap[property] = value; 
        }
      });
  
      if (!styleMap['font-size']) {
        styleMap['font-size'] = `${defaultStyles.fontSize}`;
      }
      if (!styleMap['font-family']) {
        styleMap['font-family'] = `${defaultStyles.fontFamily}`;
      }
  
      const updatedStyle = Object.entries(styleMap)
        .map(([key, value]) => `${key}: ${value}`)
        .join('; ') + ';';
  
      element.setAttribute('style', updatedStyle.trim());
    });
  
    return doc.body.innerHTML;
  }
}
