// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-item {
  line-height: 2em !important;
  height: 8em !important;
}

.o_stat {
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/order-assign/order-assign.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,sBAAA;AACF;;AAEA;EACE,0BAAA;AACF","sourcesContent":[".order-item {\n  line-height: 2em !important;\n  height: 8em !important;\n}\n\n.o_stat {\n  text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
