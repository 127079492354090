
import { pairwise, filter, take } from 'rxjs/operators';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { AuthService, ConfigService, RoutingStateService, SocketService, NotificationsService } from './services';
import { Router, NavigationEnd } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationSnackbarComponent } from '../app/common/notification-snackbar/notification-snackbar.component';
import {CONSTANTS} from '../app/app.constants'
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  @ViewChild('drawer') drawer: MatDrawer
  auth: any = {};
  isShowMenuPanel: boolean = false;
  isLoggedIn: boolean = false;
  healthCheck: any;
  isServerUp = true;
  currentSnackBarRef: MatSnackBarRef<NotificationSnackbarComponent>
  constructor(
    private authService: AuthService,
    private router: Router,
    private config: ConfigService,
    private routingState: RoutingStateService,
    private socket:SocketService,
    private snackBar: MatSnackBar,
    private notificationService:NotificationsService
  ) {
    this.auth = authService;
    this.routingState.loadRouting();
  }

  ngOnInit() {
    $.material.init();
    $.material.ripples();
    // this.auth.fetchVersionDetails();
    this.subscribeToWakeUpCall();
    this.auth.isServerReachable$
      .subscribe((stat) => this.isServerUp = stat);
    this.auth.loggedOutHealthCheck().pipe(take(1))
      .subscribe((res)=> {
        let stat = res.statusText == 'OK';
        this.authService.setServerStatus(stat);
        
      })
    this.authService.isLoggedin$
      .subscribe((loggedIn) => {
        if(loggedIn && !this.isLoggedIn){
          this.isServerUp = true;
          this.isLoggedIn = loggedIn;
          this.healthCheck = setInterval(() => {

            this.auth.serverHealthCheck().pipe(take(1))
            .subscribe((res)=> {
              let stat = res.statusText == 'OK';
              this.authService.setServerStatus(stat);
              
            })
          }, 30000)
        } else if(!loggedIn) {
          this.isLoggedIn = loggedIn;
          clearInterval(this.healthCheck);
        }
      });
  }

  loggedoutRoutes = [];
  isSidebarActive() {
    if(!this.isServerUp) return false;
    this.loggedoutRoutes = ["/login", '/forget', '/408', '/maintenance','**']
    if(this.router.url.match('/view') && !this.router.url.match('update') && !this.router.url.match('pricing')) {
      this.isShowMenuPanel =true;
      if(this.config.getSideBarNode()) return this.config.getSideBarNode();
      else return this.config.setSideBarNode(1);
    }
    if (this.router.url.match('/user/new') || this.router.url.match('/termsAndConditions')) {
      this.isShowMenuPanel =false;
      this.config.setMySidebar(true);
      return this.config.getSideBarNode();
    }
    if (this.router.url != '/') {
      this.isShowMenuPanel =false;
      if(!this.router.url.match('/view')) this.config.setMySidebar(true);
      if (!this.auth.isLoggedIn() && this.loggedoutRoutes.indexOf(this.router.url) == -1) this.router.navigate(['login']);
      if (this.auth.isLoggedIn() && this.loggedoutRoutes.indexOf(this.router.url) > -1) this.router.navigate(['home']);
      return this.config.getSideBarNode();
    }
  }

  openNav() {
    this.config.setMySidebar(true);
    this.config.getMySidebar();
  }

  closeNav() {
    this.config.setMySidebar(false);
    this.config.getMySidebar()
  }

  checkSidebarStyle() {
    return this.config.getMySidebar()
  }

  ngAfterViewInit() {
    this.checkSidebarStyle();
  }

  subscribeToWakeUpCall(){
    this.socket.syncMessage("userWakeUp").subscribe((result: any) => {
      if (this.auth.isLoggedIn()){
        if(this.auth.getUserId() == result.id){
          this.currentSnackBarRef = this.snackBar.openFromComponent(
            NotificationSnackbarComponent, 
            { data: { subject : result.body , orderId : result.orderId },  horizontalPosition: "center", panelClass: ['mat-elevation-z3'] }
          );
          this.auth.setSnackRef(this.currentSnackBarRef);
          this.currentSnackBarRef.afterDismissed().subscribe(() => { this.auth.setSnackRef(this.currentSnackBarRef) });
        }
      }
    })
  }  

  ngOnDestroy(){
    this.socket.unsubscribeMessage("userWakeUp");
  }

  openDrawer(){
    this.drawer.open();
  }

  seeAllNotfif() {
    this.router.navigate(['/notifications/list'])
    this.drawer.close()
  }

  clearAllNotif(){
    this.authService.clearAllNotfications()
    .subscribe(() => {
      this.notificationService.resetNotificationsAndCount()
    })
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }

  checkForSideBarActive() {
    if(this.isSidebarActive()){
      if(this.checkSidebarStyle()){
        if(this.isShowMenuPanel) return true;
        else return true;
      }else return false;
    }else return false;
  }
}
