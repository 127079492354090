import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OrdersService } from 'app/services';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-daily-time-box',
  templateUrl: './daily-time-box.component.html',
  styleUrls: ['./daily-time-box.component.scss']
})
export class DailyTimeBoxComponent implements OnInit { 
  intervalId:any;
  displayTime: Date;
  dueTodayCount: Number;
  time: Moment;  
  startdayMoment : Moment;
  endDayMoment : Moment;
  nineAMMoment: Moment;
  threePMMoment : Moment;
  fourPMMoment: Moment;
  
  constructor(private orders: OrdersService, private router: Router) {}
  
  ngOnInit() { 
    this.initTimer();
    // this.syncCount();
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)  
    // ).subscribe((event: NavigationEnd) => {
      // this.syncCount();
    // });
    this.intervalId = setInterval(() => {      
      if(!moment.tz('America/New_York').isSame(this.time, 'date')) this.initTimer();   
      else {
        this.time = moment.tz('America/New_York');
        this.displayTime = new Date(new Date().toLocaleString("en-US", {timeZone: "America/New_York"}))  
      }
    }, 1000);
    
  }

  ngOnDestroy(){
    clearInterval(this.intervalId);
  }

  initTimer(){
    this.time= moment().tz('America/New_York');
    this.displayTime = new Date(new Date().toLocaleString("en-US", {timeZone: "America/New_York"}))  
    this.startdayMoment = this.time.clone().startOf('day');
    this.endDayMoment =  this.time.clone().endOf('day');
    this.nineAMMoment =  this.startdayMoment.clone().add(9, 'hours');
    this.threePMMoment = this.startdayMoment.clone().add(15, 'hours');
    this.fourPMMoment =this.startdayMoment.clone().add(16, 'hours');
  }

  syncCount(){
    this.orders.getDailyDueCount().subscribe((countData) => {
      this.dueTodayCount = countData.count;
    })
  }

}
