import { Component, OnInit } from '@angular/core';
import { AuthService, ConfigService, GroupsService, ScrollService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { CONSTANTS } from '../../app.constants';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { forkJoin as observableForkJoin, Observable, Subject, ReplaySubject } from 'rxjs';
import { PreloaderService } from '../../services/preloader/preloader.service'
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-group-listing',
  templateUrl: './group-listing.component.html',
  styleUrls: ['./group-listing.component.scss']
})
export class GroupListingComponent implements OnInit {
  groups = [];
  constants = CONSTANTS;
  pageNum: number;
  sortField: string;
  sortOrder: string;
  searchString: string;
  searchInProgress: boolean = false;
  searchQuery = new Subject<string>();
  isErrorModal: boolean = true;
  timer: any = [];
  constructor(
    public groupsService: GroupsService,
    public auth: AuthService,
    private config: ConfigService,
    private matDialog:MatDialog,
    private preloaderService: PreloaderService,
    private router: Router,
    private scrollService: ScrollService
  ) {
    if (this.pageNum == undefined)
      this.pageNum = 1;
    this.sortOrder = 'ASC';
    this.sortField = 'Group_Name';
    this.searchString = '';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    this.getAllGroups();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(23), 0));
  }
  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
    getAllGroups() {
    this.groupsService.getAllGroups(this.sortField, this.sortOrder, this.searchString)
      .subscribe((groups) => {
        this.groups = groups;
        this.searchInProgress = false;
      }, (err) => {
        this.searchInProgress = false;
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.sortOrder == 'ASC') this.sortOrder = 'DESC';
      else this.sortOrder = 'ASC';
    } else {
      this.sortField = field;
      this.sortOrder = 'ASC';
    }
    this.getAllGroups();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.sortOrder == 'ASC') this.sortOrder = 'DESC';
      else this.sortOrder = 'ASC';
    } else {
      this.sortField = field;
      this.sortOrder = 'DESC';
    }
    this.getAllGroups();
  }

  trackGroups(grp) {
    return grp.Group_ID;
  }

  searchField(search) {
    this.scrollService.setScrollPosAbstractor(0);
    var format = /[!#$%^*\=\[\]{};:\\|<>\/?]/;
    search=search.trim();
    this.searchString = search;
    if (this.searchString) {
      let dotIndex = this.searchString.indexOf(".")
      if (dotIndex == 0 && this.isErrorModal) {
        this.isErrorModal = false;
        this.openErrorPopup("First character cannot be dot(.) in search field.");
      } else if (dotIndex != 0) {
        if (!format.test(this.searchString)&& this.searchString==" "){
          this.searchString='';
            this.getAllGroups();
        }else this.getGroups();
        this.isErrorModal = true;
      }
    } else {
      this.getGroups();
      this.isErrorModal = true;
    }
  }

  getGroups() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getAllGroups();
  }

  goToGroupDetails(groupId) {
    if(this.checkAdminType()) this.router.navigate(['/groups/edit/' + groupId])
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  } 
}
