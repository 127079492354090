// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rb-doc-removed {
  color: #EE4751;
  opacity: 0.25;
  text-decoration: line-through;
}
.rb-doc-removed .rb-del-doc {
  visibility: hidden !important;
}
.rb-doc-removed .fa-file {
  color: #EE4751;
}
.rb-doc-removed .rb-file-img {
  border: solid #EE4751 1px;
}

.rb-doc-added {
  color: #0F9D58;
}
.rb-doc-added .fa-file {
  color: #0F9D58;
}
.rb-doc-added .rb-file-img {
  border: solid #0F9D58 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/report-builder/rb-document/rb-document.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,6BAAA;AACF;AAAE;EACE,6BAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,yBAAA;AAEJ;;AAEA;EACE,cAAA;AACF;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,yBAAA;AAEJ","sourcesContent":[".rb-doc-removed {\n  color: #EE4751;\n  opacity: 0.25;\n  text-decoration: line-through;\n  .rb-del-doc {\n    visibility: hidden !important;\n  }\n  .fa-file {\n    color: #EE4751;\n  }\n  .rb-file-img {\n    border: solid #EE4751 1px;\n  }\n}\n\n.rb-doc-added {\n  color: #0F9D58;\n  .fa-file {\n    color: #0F9D58;\n  }\n  .rb-file-img {\n    border: solid #0F9D58 1px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
