// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-component-drop-zone {
  width: 300px;
  height: 300px;
}

.drap-icon {
  height: 100px;
  width: 100%;
  border: 1px solid;
}

.modal-content {
  width: 100%;
}

#dragDropModal {
  margin-top: 70px;
}

.modal-dialog {
  max-width: 1500px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/drag-drop-docs/drag-drop-docs.component.scss"],"names":[],"mappings":"AAAA;EACM,YAAA;EACA,aAAA;AACN;;AAEA;EACI,aAAA;EACA,WAAA;EACA,iBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":[".custom-component-drop-zone {\n      width: 300px;\n      height: 300px;\n    }\n\n.drap-icon{\n    height: 100px;\n    width: 100%;\n    border: 1px solid;\n    }\n\n.modal-content{\n    width: 100%;\n}\n\n#dragDropModal{\n    margin-top: 70px;\n}\n\n.modal-dialog{\n    max-width: 1500px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
