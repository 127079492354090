// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fees-det {
  width: 60%;
}

.fees-base {
  width: 20%;
}

.fees-price {
  width: 20%;
}

.adj-hr {
  margin-right: -10px;
}

.clearfix {
  padding-top: 6px;
}

.pad-price {
  padding-top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/order-checklist/chk-fees/chk-fees.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".fees-det{\n  width: 60%;\n}\n\n.fees-base{\n  width: 20%;\n}\n\n.fees-price{\n  width: 20%;\n}\n\n.adj-hr{\n  margin-right: -10px;\n}\n\n.clearfix{\n  padding-top: 6px;\n}\n\n.pad-price {\n  padding-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
