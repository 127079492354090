import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-timecomplete',
  templateUrl: './timecomplete.component.html',
  styleUrls: ['./timecomplete.component.scss']
})

export class TimecompleteComponent implements OnInit {
  dueDayString:any;
  dueTimeString:any;
  timeToComplForm: UntypedFormGroup
  @Input() dueDateDuration:any;
  @Output() timeToComplete = new EventEmitter<any>();
  constructor(
    private frmBuilder: UntypedFormBuilder,
  ) {
    this.timeToComplForm =this.frmBuilder.group({
      'taskDueDay': [null],
      'taskDueHour':[null]
    });
  }

  ngOnInit() { }

  getValues() {
    if (this.dueTimeString || this.dueDayString) {
      this.dueDateDuration = this.timeToComplForm;
      this.timeToComplete.emit(this.dueDateDuration);
    }
    if (!this.dueTimeString && !this.dueDayString) {
      this.dueTimeString = undefined;
      this.dueDayString = undefined;
      this.dueDateDuration = this.timeToComplForm;
      this.timeToComplete.emit(this.dueDateDuration);
    }
  }

  getTaskDays(event) {
    this.dueDayString = event;
  }

  getTaskMinutes(event) {
    this.dueTimeString = event;
  }
}

