import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService, AuthService, ConfigService, ClientsService, OrganizationService, UsermessageService } from 'app/services';
import { DialogService } from 'ng2-bootstrap-modal';
import { CONSTANTS } from '../../app.constants'
import * as _ from 'underscore';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {
  productForm: UntypedFormGroup
  productModel: any = {};
  productID: any;
  timer: any = [];
  errorMsg: any;
  constants = CONSTANTS
  successMsg: any;
  customers: any;
  prodCategories = [];
  selectedProdCategoryId: any = null;
  selectedProdCategoryName: any = null;
  activeQBProdCategory: any = null;
  organizations: any;
  activeClients: any;
  activeOrganizations: any;
  activeParentOrganizations: any;
  userIds = [];
  organizationIds = [];
  parentOrganizationIds = [];
  productStatusChanged:boolean = false;
  tempClients = [];
  tempOrganizations = [];
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef
  parentOrganization = [
    { value: '1', Organization_Name: 'Qualia' },
    { value: '2', Organization_Name: 'TrackerPro' },
    { value: '4', Organization_Name: 'SoftPro' }
  ];
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private productService: ProductService,
    private auth: AuthService,
    private router: Router,
    private config: ConfigService,
    private clientsService: ClientsService,
    private matDialog:MatDialog,
    private organizationService: OrganizationService,
    private userMsg: UsermessageService
  ) {
    this.productForm = frmBuilder.group({
      'Product_Name': [null, Validators.compose([Validators.required, Validators.maxLength(512)])],
      'Product_Description': [null, Validators.compose([Validators.required, Validators.maxLength(256)])],
      'Product_Default_Flag': [null],
      'Product_Status':[null]
    })
    this.getIndividualCustomers();
    this.getAllOrganizations();
    this.getAllActiveOrganizationsInProd();
    this.getQBProdCategory();
  }

  ngOnInit() {
    this.getProductDetails(this.route.snapshot.params['prodId'])
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();
    }, 0));
  }

  getProductDetails(productId) {
    this.productService.getProduct(productId)
      .subscribe((data) => {
        this.productModel = data;     
        if(this.productModel.Product_Default_Flag == 0 && this.productModel.Product_Status == 1) this.productModel.Product_Default_Flag ='0';
        else if(this.productModel.Product_Default_Flag == 1 && this.productModel.Product_Status == 1) this.productModel.Product_Default_Flag ='1';
        else if(this.productModel.Product_Default_Flag == 1 && this.productModel.Product_Status == 2) this.productModel.Product_Default_Flag ='2';
      })
  }

  getQBProdCategory() {
    this.productService.getQBProdCategory()
    .subscribe((result) => {
      if (result) {
        this.prodCategories = result;
        this.getQBItem();
      }
    }, (err) => {
      console.log(err);
    })
  }

  getQBItem() {
    this.productService.getQBItem(this.route.snapshot.params['prodId'])
    .subscribe((result) => {
      if (result) {
        if(result && result.IncomeAccountRef && result.IncomeAccountRef.value) {
          this.selectedProdCategoryId = result.IncomeAccountRef.value;
          this.selectedProdCategoryName = result.IncomeAccountRef.name;
          this.activeQBProdCategory = result;
        }
      }
    }, (err) => {
      console.log(err);
    })
  }

  getIndividualCustomers() {
    this.clientsService.getActiveIndividualClients()
      .subscribe((result) => {
        if (result) {
          this.customers = result;
          this.getAllActiveClientInProd();
        }
      }, (err) => {
        console.log(err);
      })
  }

  getAllOrganizations() {
    this.organizationService.getAllOrganizations()
      .subscribe((result) => {
        if (result) {
          this.organizations = result;
          this.getAllActiveCompanyInProd();
        }
      }, (err) => {
        console.log(err);
      })
  }

  getAllActiveClientInProd() {
    this.productService.getAllUsersInProducts(this.route.snapshot.params['prodId'], 'Client')
      .subscribe((data) => {
        if (data) {
          data.forEach((x) => {
            this.customers.filter((customer) => {
              if (customer.User_ID == x.Mapped_To_ID) {
                x['User_First_Name'] = customer.User_First_Name;
                x['User_Last_Name'] = customer.User_Last_Name;
              }
            })
            x['User_ID'] = x.Mapped_To_ID;
          })
          this.activeClients =  this.getActiveUsersFromMapping(data);
        }
      })
  }


  getActiveUsersFromMapping(mappings){
    let users = [];
    mappings.forEach((mapping) => {
      let matchedUserIndex = this.customers.findIndex((customer) => customer.User_ID == mapping.Mapped_To_ID)
      if(matchedUserIndex > -1) users.push(this.customers[matchedUserIndex]);
    })
    return users;
  }

  getActiveCompanyFromMapping(mappings){
    let orgs = [];
    mappings.forEach((mapping) => {
      let matchedUserIndex = this.organizations.findIndex((org) => org.Organization_ID == mapping.Mapped_To_ID)
      if(matchedUserIndex > -1) orgs.push(this.organizations[matchedUserIndex]);
    })
    return orgs;
  }

  getActiveOrganizationFromMapping(mappings){
    let orgs = [];
    mappings.forEach((mapping) => {
      let matchedUserIndex = this.parentOrganization.findIndex((org) => org.value == mapping.Mapped_To_ID)
      if(matchedUserIndex > -1) orgs.push(this.parentOrganization[matchedUserIndex]);
    })
    return orgs;
  }

  getAllActiveCompanyInProd() {
    this.productService.getAllUsersInProducts(this.route.snapshot.params['prodId'], 'Company')
      .subscribe((data) => {
        if (data) {
          data.forEach((x) => {
            this.organizations.filter((org) => {
              if (org.Organization_ID == x.Mapped_To_ID) x['Organization_Name'] = org.Organization_Name;
            })
            x['Organization_ID'] = x.Mapped_To_ID;
          })
          this.activeOrganizations = this.getActiveCompanyFromMapping(data);
        }
      })
  }

  getAllActiveOrganizationsInProd() {
    this.productService.getAllUsersInProducts(this.route.snapshot.params['prodId'], 'Organization')
      .subscribe((data) => {
        if (data) {
          data.forEach((x) => {
            this.parentOrganization.filter((org) => {
              if (org.value == x.Mapped_To_ID) x['Organization_Name'] = org.Organization_Name;
            })
            x['value'] = x.Mapped_To_ID;
          })
          this.activeParentOrganizations = this.getActiveOrganizationFromMapping(data);
        }
      })
  }

  prodCategoryChanged(event) {
    this.selectedProdCategoryId=event;
    const selectedCategory = this.prodCategories.find(obj => obj.Id === event);
    this.selectedProdCategoryName = selectedCategory ? selectedCategory.Name : '';
  }

  addNewClients(event) {
    let temp = []; 
    temp.push(event.value);
    this.activeClients =_.flatten( temp);
  }

  addNewCompanys(event) {
    let temp = []; 
    temp.push(event.value);
    this.activeOrganizations =_.flatten( temp);
  }

  addNewOrgs(event) {
    let temp = []; 
    temp.push(event.value);
    this.activeParentOrganizations =_.flatten( temp);
  }

  editProduct(data) {
    let allOrganizationIds = [];
    let allClientIds = [];
    let allParentOrganizationIds = [];
    if(this.activeOrganizations && this.activeOrganizations.length > 0) this.activeOrganizations.forEach((org) => allOrganizationIds.push(org.Organization_ID));
    if(this.activeParentOrganizations && this.activeParentOrganizations.length > 0) this.activeParentOrganizations.forEach((org) => allParentOrganizationIds.push(org.value));
    if(this.activeClients && this.activeClients.length > 0) this.activeClients.forEach((user) => allClientIds.push(user.User_ID));
    if(this.productModel.Product_Default_Flag == "0") data['Users_Ids'] = allClientIds;
    else data['Users_Ids'] = allClientIds;
    if(this.productModel.Product_Default_Flag == "0") data['Organization_Ids'] = allOrganizationIds;
    else data['Organization_Ids'] = allOrganizationIds;
    if(this.productModel.Product_Default_Flag == "0") data['Parent_Organization_Ids'] = allParentOrganizationIds;
    else data['Parent_Organization_Ids'] = allParentOrganizationIds;
    if (data.Product_Name) data.Product_Name = data.Product_Name.trim();
    data.selectedProdCategoryId = this.selectedProdCategoryId;
    data.selectedProdCategoryName = this.selectedProdCategoryName;
    data.activeQBProdCategory = this.activeQBProdCategory;
    this.productService.editProduct(data,  this.route.snapshot.params['prodId'])
      .subscribe((productDetails) => {
        // sessionStorage.removeItem('AllProductAvailable');
        let msg = "Product updated successfully"
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/product/list'])
      }, (err) => {
        this.errorMsg = err;
        if (data.Product_Name.match(/_/g)) this.productModel.Product_Name = data.Product_Name.replace(/_/g, " ");
        this.userMsg.setErrorMsg(this.errorMsg.msg);
      })
  }

  changeProductStatus(event) {
    this.productStatusChanged = true
    this.productModel.Product_Status = event.checked
  }

  checkIfNoClients() {
    if(this.activeClients && this.customers && (this.customers.length == this.activeClients.length)) return true;
    else return false
  }

  checkIfNoCOrganizations() {
    if(this.activeOrganizations && this.organizations && (this.organizations.length == this.activeOrganizations.length)) return true;
    else return false
  }

  checkIfNoParentOrganizations() {
    if(this.activeParentOrganizations && this.parentOrganization && (this.parentOrganization.length == this.activeParentOrganizations.length)) return true;
    else return false
  }

  checkProductStatus(){
    if(this.productModel.Product_ID  == environment.copyChargeProductId || this.productModel.Product_Status == CONSTANTS.selectProductStatus.subproduct) return false;
    else return true;
  }

  checkForStickyProduct() {
    if(this.productModel.Product_ID  == environment.copyChargeProductId || this.productModel.Product_Status == CONSTANTS.selectProductStatus.subproduct) return false;
    else return true;
  }
}
