import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { SocketService, AuthService , NotificationsService, MessagesService, UsermessageService, ConfigService, ClientsService, QuoterequestService} from '../services';
import { Router } from '@angular/router';
import * as momentTimeZone from 'moment-timezone';
import { CONSTANTS } from '../app.constants';
import { DialogService } from 'ng2-bootstrap-modal';
import { MessageComponent } from '../dialog/message/message.component';
import { ErrorDialogComponent } from 'app/dialog/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Output() closeDrawer = new EventEmitter<boolean>();
  constructor(
    private socketService : SocketService,
    private auth: AuthService,
    private router: Router,
    public notificationService: NotificationsService,
    private messagesService : MessagesService,
    private matDialog : MatDialog,    
    private userMsg: UsermessageService,
    private config: ConfigService,
    private clientService: ClientsService,
    private quoteService : QuoterequestService
  ) { }

  ngOnInit() {
    if(this.auth.isLoggedIn()) this.auth.getAllUserNotifications();   
    // this.socketService.syncMessage("syncNotifcations").subscribe((result: any) => {
    //  if((this.auth.isLoggedIn()) && (result && result.id == this.auth.getUserId())) this.auth.getAllUserNotifications();      
    // })
  }

  ngDestroy(){
    // this.socketService.unsubscribeMessage("syncNotifcations");
  }

  dismiss(id){
    this.auth.deleteUserNotificationbyId(id)
      .subscribe(() => { this.auth.getAllUserNotifications() }, (err) => {
        console.log("Error", err);
      })
  }

  notificationLookIn(notification){
    if(notification.Notifications_Order_ID){
      this.quoteService.getQuoteDetails(notification.Notifications_Order_ID)
      .subscribe((quoteDetails) => {
        if(quoteDetails) this.router.navigate(['/quotes/' + notification.Notifications_Order_ID +'/view'])
        else this.router.navigate(['/orders/' + notification.Notifications_Order_ID +'/view'])   
      }, () => { }) 
    }   
    else {
      if(notification.Notifications_Subject.match('New client message')) this.showMsg(notification.Notification_Message_ID)
    } 
    this.closeDrawer.emit(true);
  }

  trackByFn(index, item) {
    return item.Notifications_ID;
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  showMsg(msgId) {    
    this.messagesService.getMsgInfoData(msgId)
      .subscribe((message) => {
        this.messagesService.updateMsgToRead(message).subscribe((data) => {}, (err) => { console.log(err); })
        this.auth.deleteUserNotificationbyMsgIdAndUserId(msgId).subscribe((data) => {}, (err) => { console.log(err); })
        if(message.Parent_Message_ID){
          this.messagesService.getAllMsgs(message.Parent_Message_ID)
          .subscribe((allMsgs) => {
            message.allMsg = allMsgs;
            this.showAllMsgs(message);
          }, (err) => {
            console.log(err);
          })
        }
        else this.showAllMsgs(message);        
      }, (err) => {
        console.log(err);
      })          
  }

  showAllMsgs(msg) {
    this.messagesService.getMsgData(msg.Message_ID)
      .subscribe((data) => {
        this.openMessageInterface(msg, data);
      }, (err) => {
        console.log(err);
      })
  }

  openMessageInterface(allMsg, msgDataToSent) {
    let t1= 'Direct Message', msgSendType = CONSTANTS.messageSendStatus.directMessage, t2, t3: '', isCancelledOrder = false;
    let disposable = this.matDialog.open(MessageComponent, {data:{
      msgSendType:msgSendType,
      t1: t1,
      t2: t2,
      t3: t3,
      message: allMsg,
      cancelorderFlag:isCancelledOrder,
      orderMsgPopup: false
    }, ...{ closeByClickingOutside : false}}).afterClosed()
      .subscribe((data) => {
        if (data) {
          let res = data.messageText;
          msgDataToSent.Message_Text = res;
          msgDataToSent.UserId = this.auth.getUserId();
          msgDataToSent.ClientId = msgDataToSent.Sender_User_ID;
          if (allMsg.Parent_Message_ID) msgDataToSent.Parent_Message_ID = allMsg.Parent_Message_ID;
          else msgDataToSent.Parent_Message_ID = allMsg.Message_ID;
          allMsg.msgDataToSent = msgDataToSent;
          if (!msgDataToSent.Order_ID) msgDataToSent.Order_ID = null;
          if (!allMsg.Parent_Message_ID) this.updateParentId(allMsg, data)
            .subscribe((msgData) => {
              if (msgData) this.userMsg.setSuccessMsg("Message has been sent successfully.");
            }, (err) => {
              console.log(err);
              this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
            })
          else this.sendMessageWithoutOrderId(res, msgDataToSent);
        }
      });
  }

  updateParentId(msgData, data) {
    if (!data.checked){
      msgData.msgDataToSent.Message_Type = CONSTANTS.messageType.orderMessage
      return this.messagesService.updateParentId(msgData)
    } else {
      msgData.msgDataToSent.Message_Type = CONSTANTS.messageType.internalMessage
      return this.messagesService.updateParentId(msgData)
    }
  }

  sendMessageWithoutOrderId(msgText, msgDataToSent) {
    var data = {
      Message_Text: msgText,
      Parent_Message_ID: msgDataToSent.Parent_Message_ID
    }
    this.clientService.sendMessage(data, msgDataToSent.Sender_User_ID)
      .subscribe((data) => {
        this.userMsg.setSuccessMsg("Message has been sent successfully.");        
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
      })
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }

}
