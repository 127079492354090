import { Component, OnInit , ViewChild, ElementRef, Inject} from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { AbstractorService, ConfigService } from '../../services';
import { OldAbstractorMailComponent } from '../../dialog/old-abstractor-mail/old-abstractor-mail.component';
import { NewAbstractorMessageComponent } from '../../dialog/new-abstractor-message/new-abstractor-message.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import * as momentTimeZone from 'moment-timezone';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-abstractor-messages',
  templateUrl: './abstractor-messages.component.html',
  styleUrls: ['./abstractor-messages.component.scss']
})
export class AbstractorMessagesComponent{
  @ViewChild('okBtn') okBtn: ElementRef;

  message: any = {};
  timer: any = [];

  constructor( private abstractorService: AbstractorService, private config: ConfigService,
    public dialogRef: MatDialogRef<AbstractorMessagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private matDialog:MatDialog) {
      this.message=this.data.message;
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => { this.okBtn.nativeElement.focus() }, 0));
  }

  initDialog(){
    this.message = this.data.message;
  }

  showMessage(message) {
    this.abstractorService.getOrderAbstractorDocs(message.Order_Abstractor_Message_ID)
      .subscribe((ordAbsDocs) => {
        let disposable = this.matDialog.open(OldAbstractorMailComponent, {data:{
          message: { orderDetails: this.message.orderDetails, absMessage: message, absDocuments: ordAbsDocs },
          title:message.Message_Creation_Date
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res && res.state) {
              this.clearTime();
            }

          })
      })
  }

  confirm() {
    this.dialogRef.close({
      state: true,
    })
    // this.result = {
    //   state: true,
    // }
    this.clearTime();
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    this.clearTime();
  }

  sendNewMsg() {
    const callback=()=>{
      let {Order_ID, Abstrator_ID}=this.message.orderDetails
      this.abstractorService.getAbstractorMessageList(Order_ID, Abstrator_ID).subscribe((messages)=>{
        this.message.absMessages=messages;
    })
    }
    let disposable = this.matDialog.open(NewAbstractorMessageComponent, {data:{
      title: 'New message',
      message: { orderDetails: this.message.orderDetails },
      callback
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res && res.state) {
          this.message.absMessages=[res.sentMessage, ...this.message.absMessages];
          this.clearTime();
        }
      })

  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
