
import {onErrorResumeNext as observableOnErrorResumeNext,  forkJoin as observableForkJoin } from 'rxjs';
import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { OrdersService, ConfigService, AwsS3Service, NewOrderService, PricingService, ProductService, AuthService, TagsService, SchedulingService,OrganizationService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { CONSTANTS } from '../../app.constants'
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { DialogService } from 'ng2-bootstrap-modal';
import { Location } from '@angular/common';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { SuccessComponent } from '../../dialog/success/success.component';
import { EtaAlertComponent } from '../../dialog/eta-alert/eta-alert.component';
import { NewTagComponent } from '../../dialog/new-tag/new-tag.component';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-order-fly',
  templateUrl: './order-fly.component.html',
  styleUrls: ['./order-fly.component.scss']
})
export class OrderFlyComponent implements OnInit {
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChildren('myFirstFocus') myFirstFocus: QueryList<ElementRef>
  @ViewChildren('subProductFocus') subProductFocus: QueryList<ElementRef>
  @ViewChild('etaDatePicker') etaDatePicker: ElementRef<HTMLElement>
  productDetails: any = {}
  products = [];
  allProducts=[];
  orderDetails: any = {};
  selectedProduct: any;
  selectedProductDescription: any;
  selectedProductName: any;
  prodDesc: ''
  selectedState = '';
  selectedCounty = '';
  clientRef = '';
  newDocs = [];
  baseProduct = [];
  subProductList = [];
  subProducts = [];
  defaultproducts = [];
  dotEvent = [];
  input = "";
  errors: any = {};
  suggTotPrice = 0;
  totalProductPrice = 0;
  totalTaxPrice = 0;
  grandTotPrice = 0;
  getUSDateString = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
  minDate = new Date(this.getUSDateString);
  documents = [];
  ordDetails: any = {}
  orderNotes: any = {};
  orderIns: any = {};
  orderProducts: any = [];
  constants = CONSTANTS;
  focused:boolean = false;
  subProductFocusFlag:boolean = false;
  order_ETA_Time :string = "17:00" ;
  requested_Time :string = "17:00" ;
  orderETAtime = new UntypedFormControl({value: '', disabled: true}, [Validators.required]);
  orderTags: any = [];
  searchTag: string = '';
  availableTags: any = [];
  isDueRangeErr: boolean = false;
  dueDateFrmObj: any;
  dueDateTimeObj: any;
  dueDate_Range: any;
  dueDateRangeOption: Number
  order_ETA_Date: any;
  order_Requested_Date: any;
  timer: any = [];
  isPricingFlag: boolean = false;
  holidayList: any;
  weekendHolidayName: String;
  newOrderForm: UntypedFormGroup;
  constructor(
    private auth: AuthService,
    private orderService: OrdersService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private s3Service: AwsS3Service,
    private matDialog:MatDialog,
    private router: Router,
    private newOrder: NewOrderService,
    private pricingService: PricingService,
    private productsService: ProductService,
    private location: Location,
    private tagService: TagsService,
    private holidayScheduling:SchedulingService,
    private frmBuilder: UntypedFormBuilder,
    private organizationService: OrganizationService

  ) {
   this.newOrderForm = frmBuilder.group({
      Order_Requested_Date: [{ disabled: false, value: '' }],
      Order_ETA: [{ disabled: false, value: '' }]
    })
    this.newOrderForm.valueChanges.subscribe((data) => {
      this.errors = '';
    });
   }

  ngOnInit() {
    this.orderService.getOrderProducts().subscribe((products) => {
      this.products = products;
      let updateSearchIndex = this.products.findIndex((x) => x.Product_ID == 5)
      this.selectedProduct = this.products[updateSearchIndex].Product_ID;
      this.selectedProductDescription = this.products[updateSearchIndex].Product_Description;
      this.selectedProductName = this.products[updateSearchIndex].Product_Name
      this.getOrdDetails(this.route.snapshot.params['orderId'])
      this.fetchUploadProgress()
    })
    this.getholidaylistFromLocal();
    // this.getholidaylist();
    this.getAllsubNBaseProd();

  }

   ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  ngAfterViewInit(){
    this.myFirstFocus.changes.subscribe((select) => {
      if(select.first.nativeElement && !this.focused) {
        this.timer.push(setTimeout(() => { select.first.nativeElement.focus() }, 0));
        this.focused = true;
      }
    });
    this.subProductFocus.changes.subscribe((input) => {
      if(input.last && !this.subProductFocusFlag) {
        this.timer.push(setTimeout(() => { input.last.nativeElement.focus() }, 0));
        this.subProductFocusFlag = true;
      }
    });
  }

  getOrdDetails(orderId) {
    this.orderService.getOrderDetails(orderId)
      .subscribe((result) => {
        this.orderDetails = result;
        this.getDocs(orderId)
        if (this.orderDetails.Order_ETA)
          this.orderDetails.Order_ETA = null;
        if(this.orderDetails.Order_Requested_Date) this.orderDetails.Order_Requested_Date = null;
        if (this.orderDetails && this.orderDetails.Property_County && this.selectedProduct) {
          if (!this.orderDetails.orderDocId) this.orderDetails.orderDocId = [];
          this.orderDetails.Order_Products = [];
          this.addNewProduct();
          this.getOrderNotes(orderId);
          this.addDefaultsToOrderProducts();
          this.getProductPricing(this.selectedProduct, this.orderDetails.Property_State_Abbr, this.orderDetails.Property_County, 'baseProductCost', 'baseProductCost', null,null);
          this.orderDetails.Order_Products.map((newProd) => newProd.currInput = '');
          this.orderDetails.Order_Products.map((newProd) => newProd.currBaseInput = '');
        }
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        // this.auth.logout();
        // this.router.navigate(['/login']);
      })
  }

  addNewProduct() {
    var orderProd = {};
    orderProd['Product_ID'] = [this.selectedProduct];
    orderProd['Product_Description'] = this.selectedProductDescription;
    orderProd['Product_Name'] = this.selectedProductName;
    orderProd['Order_Product_Status'] = 1;
    orderProd['Order_Product_Price'] = 0;
    orderProd['Order_Product_Original_Price'] = 0;
    this.orderDetails.Order_Products.push(orderProd);
  }

  getProductPricing(productId, state, county, id, modelname, index,subProductIndex) {
    if (productId && state && county) {
      let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID : this.orderDetails.Customer_ID;
      this.pricingService.getCustomAndOrginalPriceDetails(state, county, productId, customerId)
        .subscribe((data) => {
          if (data != null) {
            this.productDetails = data;
            if (index == null) {
              if (data.Original_Cost) {
                this.productDetails.Cost_Min_Tat = data.Original_Cost.Cost_Min_Tat;
                this.productDetails.Cost_Max_Tat = data.Original_Cost.Cost_Max_Tat;
              }
            }
            this.loadPricing(id, data, modelname, index,subProductIndex);
            this.resetProductPrice(productId)
            this.calculateTotal();
          }
          else {
            this.loadPricing(id, null, modelname, index,subProductIndex);
            this.resetProductPrice(productId)
            this.calculateTotal();
            this.productDetails.Cost_Min_Tat = null;
            this.productDetails.Cost_Max_Tat = null;
          }
        }, (err) => {
        })
    }
  }

  checkAddProdDesc(){
    if (this.orderDetails.Order_Products){
    var flag = 0;
    this.orderDetails.Order_Products.forEach(orderProduct => {
            if (orderProduct.Order_Product_Flag == CONSTANTS.orderProductFlag.active && orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
       if(!orderProduct.hasOwnProperty('Product_ID')){
                flag++;
              }
              else if(orderProduct.hasOwnProperty('Product_ID')){
                if(orderProduct.Product_ID === 999){
                  if((orderProduct.Order_Product_Custom_Name == undefined) || (orderProduct.Order_Product_Custom_Name == '')){
                    flag++;
                  }
                }
              }
            }
          })
          if (flag == 0) return false;
              else return true;
        }
        else return false;
  }


  getOrderNotes(orderId) {
    this.orderService.getOrderNotes(orderId)
      .subscribe((notes) => {
        notes.forEach(note => {
          if (note.Instruction_Type == CONSTANTS.instructionType.orderNotes) {
            this.orderNotes = note;
            this.orderDetails.Property_Order_Notes = this.orderNotes.Instruction_Text
          }
          if (note.Instruction_Type == CONSTANTS.instructionType.orderIns) {
            this.orderIns = note;
            this.orderDetails.Order_Instructions = this.orderIns.Instruction_Text
          }
        })
      }, (err) => {
      })
  }


  getDocs(orderId) {
    this.orderService.getOrderDocuments(orderId)
      .subscribe((data) => {
        this.documents = data;
        this.orderDetails.clientDoc = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.clientDocs);//Client Type
        this.orderDetails.adminDoc = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.adminDocs);//Admin Type
      }, (err) => {
      })
  }

  prepareOrderProduct(product) {
    return {
      Order_Product_ID: 'new' + UUID.UUID(),
      Order_ID: this.orderDetails.Order_ID,
      Product_ID: product.Product_ID,
      Order_Product_Status: CONSTANTS.selectProductStatus.defaultProduct,
      Order_Product_Flag: CONSTANTS.orderProductFlag.active,
      Cost_Amount: 0,
      Final_Cost: 0,
      Product_Name: product.Product_Name,
      Product_Description: product.Product_Description,
      currInput: '',
      currBaseInput: ''
    }
  }

  addDefaultsToOrderProducts() {
    this.productsService.getProduct(environment.copyChargeProductId)
      .subscribe((defaults) => {
        if (defaults) {
          let defProductData = [];
          defProductData.push(defaults);
          this.defaultproducts = defProductData;
          defProductData.forEach((item, index) => {
            if (this.orderDetails.Order_Products.findIndex(x => x.Product_ID == item.Product_ID) == -1)
            this.orderDetails.Order_Products.push(this.prepareOrderProduct(item));
            this.getDefaultProdPricing(item.Product_ID, this.orderDetails.Property_State_Abbr, this.orderDetails.Property_County, index);
            });
          this.calculateTotal();
        }
      })
  }

  getDefaultProdPricing(productId, state, county, index){
    if (productId && state && county) {
      let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID:  this.orderDetails.Customer_ID;
      this.pricingService.getCustomAndOrginalPriceDetails(state, county, productId, customerId)
        .subscribe((data) => {
          if (data.Original_Cost) {
            this.isPricingFlag = true;
            this.getDefaultProducts()[index].Cost_Amount = data.Original_Cost.Cost_Amount;
            // if (data.Custom_Cost) this.getDefaultProducts()[index].Final_Cost = data.Custom_Cost.Custom_Cost_Amount;
            // else this.getDefaultProducts()[index].Final_Cost = data.Original_Cost.Cost_Amount
          }else {
            this.isPricingFlag = false;
            this.getDefaultProducts()[index].Final_Cost = 0;
          }
          this.calculateTotal();
        }, (err) => {
          console.log(err);
        })
    }
}

  getSubProductList() {
    this.newOrder.getSubProductList()
      .subscribe((productList) => {
        if (productList) {
          this.subProductList = productList;
        }
      }, (err) => {
      });
  }



  resetProductPrice(productId) {
    var prodChangedIndex = this.orderDetails.Order_Products.findIndex((orderProduct) => orderProduct.Product_ID == productId)
    if (this.orderDetails.Order_Products[prodChangedIndex].currInput)
      this.orderDetails.Order_Products[prodChangedIndex].currInput = '';
  }

  loadPricing(id, pricing, modelname, index,subProductIndex) {
    var CustomPrice = null;
    var OriginalPrice = null;

    if (pricing.Original_Cost != null) OriginalPrice = pricing.Original_Cost.Cost_Amount;

    if (pricing.Custom_Cost != null ) {
      CustomPrice = pricing.Custom_Cost.Custom_Cost_Amount
    }
    else CustomPrice = OriginalPrice;

    if (pricing.Original_Cost == null && pricing.Custom_Cost == null) CustomPrice = OriginalPrice = 0;

  
if(subProductIndex != null){
  this.orderDetails.Order_Products[subProductIndex + 2].Cost_Amount = OriginalPrice;
  this.orderDetails.Order_Products[subProductIndex + 2].Final_Cost = CustomPrice;
}else{
  if (index != null) {
      this.getSubProducts()[index].Cost_Amount = OriginalPrice;
      this.getSubProducts()[index].Final_Cost = CustomPrice;
    } else {
      this.orderDetails.Order_Products[0].Cost_Amount = OriginalPrice;
      this.orderDetails.Order_Products[0].Final_Cost = CustomPrice;
    }
}

    if (id && document.getElementById(id)) document.getElementById(id).parentElement.classList.remove('is-empty');
  }

  buildAddress(model) {
    let address = '';
    if (model.Property_Address_1) address += model.Property_Address_1
    if (model.Property_Address_2) {
      if (address) address += '\n'
      address += model.Property_Address_2
    }
    if (model.Property_City) {
      if (address) address += '\n'
      address += model.Property_City
    }
    if (model.Property_State_Abbr) {
      if (address) address += ', '
      address += model.Property_State_Abbr
    }
    if (model.Property_ZipCode) {
      if (address) address += ' '
      address += model.Property_ZipCode
    }
    return address;
  }

  openFileSelector() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
    //this.fileInput.nativeElement.value = null;
  }

  uploadDocument(event) {
    if (this.validateFilenames(event)) {
      var fileUploads = [];
      var propertyUid = [];
      if (this.checksize(event.target.files)) {
        for (var i = 0; i < event.target.files.length; i++) {
          var uid = UUID.UUID();
          this.newDocs.push({ docId:uid, name: event.target.files[i].name, completed: 0 });
          fileUploads.push(this.uploadToS3AndUpdatePath(event.target.files[i], uid))
        }
        Promise.all(fileUploads)
          .then((files) => {
            this.removeCompletedDocs(files);
            var succesFiles = files.filter((file) => file !== undefined);
            if(succesFiles.length > 0) this.orderDetails.orderDocId = this.orderDetails.orderDocId.concat(succesFiles);
          })
          .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload) })
      }
      else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    }

    else {
      this.openErrorPopup(CONSTANTS.apiErrors.validateFile)
    }
  }

  removeCompletedDocs(files) {
    for (var i = 0, len = files.length; i < len; i++) {
      for (var j = 0, len2 = this.newDocs.length; j < len2; j++) {
        if (files[i] && files[i].docId === this.newDocs[j].docId) {
          this.newDocs.splice(j, 1);
          len2 = this.newDocs.length;
        }
      }
    }
  }

  validateFilenames(event) {
    var flag = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      var filename = event.target.files[i].name;
      if (filename.indexOf(',') > -1) flag++;
    }
    if (flag == 0) return true;
    else return false;
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(files, (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024);
    if (largeFiles.length > 0) return false;
    else return true;
  }

  uploadToS3AndUpdatePath(file, propertyId) {
    return this.s3Service.onManagedUpload(file, '0')
      .then((data) => {
        return this.s3Service.updateDocumentPath('0',
          propertyId,
          file,
          data.Key,
          this.extractFilename(data.Key),
          CONSTANTS.orderDocumentType.internalDocs
        )
          .then((doc) => {
            doc['docId'] = propertyId;
            doc['completed'] = 100;
            return doc;
          })
          .catch((err) => { throw err; })
      })
      .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload); })
  }

  extractFilename(filePath) {
    var parts = filePath.split('/');
    var filename = parts[parts.length - 1];
    return filename;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  goBack() {
    this.location.back()
  }

  getDefaultProducts() {
    if (this.orderDetails.Order_Products && this.orderDetails.Order_Products.length)
      return this.orderDetails.Order_Products.filter((product) => product.Product_ID == environment.copyChargeProductId)
    else return [];
  }

  getBaseProduct() {
    if (this.orderDetails.Order_Products && this.orderDetails.Order_Products.length) {
      return this.orderDetails.Order_Products.filter((product) => product.Order_Product_Status == CONSTANTS.selectProductStatus.baseProduct)
    }
    else return [];
  }

  getSubProducts() {
    if (this.orderDetails.Order_Products && this.orderDetails.Order_Products.length)
      return this.orderDetails.Order_Products.filter((product) => product.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct)
    else return [];
  }

  addproduct() {
    var newProd: any = {};
    newProd.Order_Product_ID = 'new' + UUID.UUID();
    newProd.Order_ID = this.orderDetails.Order_ID;
    newProd.Order_Product_Custom_Input_Description = '';
    newProd.Order_Product_Original_Price = null;
    newProd.Order_Product_Flag = CONSTANTS.orderProductFlag.active;
    newProd.Order_Product_Status = CONSTANTS.selectProductStatus.subproduct;
    newProd.Final_Cost = "0.00";
    newProd.currInput = ''
    newProd.currBaseInput = ''
    this.orderDetails.Order_Products.push(newProd);
    this.calculateTotal();
    this.assignSubProductFocus();
  }

  getNumberFormat(e, orderProduct) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((orderProduct.currInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (orderProduct.currInput.length > 0 || orderProduct.currInput.length < this.config.getLimitPriceMaxLength())) {
        orderProduct.currInput = orderProduct.currInput.slice(0, orderProduct.currInput.length - 1);
        e.target.value = this.numberFormat(orderProduct.currInput);
        orderProduct.Final_Cost = e.target.value;
      }
      else if (orderProduct.currInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          orderProduct.currInput = orderProduct.currInput + key;
          e.target.value = this.numberFormat(orderProduct.currInput);
          orderProduct.Final_Cost = e.target.value;
        }
      }
      this.calculateTotal();
      return false;
    }
  }

  getBasePriceFormat(e, orderProduct) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((orderProduct.currBaseInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (orderProduct.currBaseInput.length > 0 || orderProduct.currBaseInput.length < this.config.getLimitPriceMaxLength())) {
        orderProduct.currBaseInput = orderProduct.currBaseInput.slice(0, orderProduct.currBaseInput.length - 1);
        e.target.value = this.numberBaseFormat(orderProduct.currBaseInput);
        if (e.target.value == '')
          orderProduct.Cost_Amount = null;
        else
          orderProduct.Cost_Amount = e.target.value;
      }
      else if (orderProduct.currBaseInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          orderProduct.currBaseInput = orderProduct.currBaseInput + key;
          e.target.value = this.numberBaseFormat(orderProduct.currBaseInput);
          orderProduct.Cost_Amount = e.target.value;
        }
      }
      this.calculateTotal();
      return e.preventDefault();
    }
  }

  getTaxFormat(e) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((this.input.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.input.length > 0 || this.input.length < this.config.getLimitPriceMaxLength())) {
        this.input = this.input.slice(0, this.input.length - 1);
        e.target.value = this.numberFormat(this.input);
        this.totalTaxPrice = e.target.value;
      }
      else if (this.input.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.input = this.input + key;
          e.target.value = this.numberFormat(this.input);
          this.totalTaxPrice = e.target.value;
        }
      }
      this.calculateTotal()
      return false;
    }
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return "0.00";
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  numberBaseFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  calculateTotal() {
    var total = 0;
    if (this.orderDetails.Order_Products) {
      this.orderDetails.Order_Products.forEach((prod) => {
        // prod.Final_Cost = 0;
        // prod.Cost_Amount = 0;
        if (prod.Final_Cost) total = Number(total) + Number(prod.Final_Cost);
      })
      this.totalProductPrice = Number(total.toFixed(2));
      this.grandTotPrice = Number((Number(this.totalTaxPrice) + Number(this.totalProductPrice)).toFixed(2));
    }
  }

  removeSubProduct(orderProduct) {
    orderProduct.Order_Product_Flag = CONSTANTS.orderProductFlag.inActive;
    orderProduct.Order_Product_Price = 0;
    this.calculateTotal();
    this.removeByOrderProductId(orderProduct.Order_Product_ID);
    this.removeDeletedfieldEvent(orderProduct);
    this.assignSubProductFocus();
  }

  removeByOrderProductId(orderProductId) {
    var i = this.orderDetails.Order_Products.length;
    while (i--) {
      if (this.orderDetails.Order_Products[i]
        && this.orderDetails.Order_Products[i].hasOwnProperty('Order_Product_ID')
        && (this.orderDetails.Order_Products[i]['Order_Product_ID'] === orderProductId)) {
        this.orderDetails.Order_Products.splice(i, 1);
      }
    }
    this.calculateTotal();
    return this.orderDetails.Order_Products;
  }

  removeDeletedfieldEvent(orderProduct) {
    var orderProductId = orderProduct.Order_Product_ID
    var finalpriceFieldId = "Final_Price_" + orderProductId;
    var matchedFinalEventIndex = this.dotEvent.findIndex((eventItem) => eventItem.ID == finalpriceFieldId);
    if (matchedFinalEventIndex > -1) this.dotEvent.splice(matchedFinalEventIndex, 1);
    if (orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
      var basepriceFieldId = "newOrder3InpBasePrc" + orderProductId;
      var matchedBaseEventIndex = this.dotEvent.findIndex((eventItem) => eventItem.ID == basepriceFieldId);
      if (matchedBaseEventIndex > -1) this.dotEvent.splice(matchedBaseEventIndex, 1);
    }
  }

  validateOrderETADateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy   
    if(event.value) this.orderETAtime.enable();
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors.Order_ETA = true;
    else delete this.errors.Order_ETA;
  }

  validateFinalPricingFields() {
    if (!this.grandTotPrice || (this.totalTaxPrice == null || this.totalTaxPrice == undefined || this.totalTaxPrice.toString() == '') || !this.selectedState || !this.selectedCounty
      || !this.validateProductPricingFields()) return false;
    else return true;
  }

  validateProductPricingFields() {
    if (this.orderDetails.Order_Products) {
      if (this.orderDetails.Order_Products.findIndex(orderProduct => isNaN(parseInt(orderProduct.Final_Cost)) && orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.baseProduct) > -1) return false;
      else return true;
    }

  }

  getIfErrors() {
    if (Object.keys(this.errors).length > 0) {
      return true
    }
    else return false;
  }

  validatePrice() {
    if (this.orderDetails.Order_Products) {
      if (this.orderDetails.Order_Products.findIndex(orderProduct => (isNaN(parseInt(orderProduct.Final_Cost)) && ((orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) || (orderProduct.Product_ID == environment.copyChargeProductId)))) > -1) return true;
      else return false;
    }
  }

  getOrderEta() {
    if (this.orderDetails.Order_ETA) return 1;
    else return 0;
  }

  validateCustomInputDesc() {
    if (this.orderDetails.Order_Products) {
      var flag = 0;
      this.orderDetails.Order_Products.forEach(orderProduct => {
        if (orderProduct.Order_Product_Flag == CONSTANTS.orderProductFlag.active && orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
          if (orderProduct.Order_Product_Custom_Input_Description == '' || orderProduct.Order_Product_Custom_Input_Description == undefined) {
            flag++;
          }
          if (orderProduct.Order_Product_Custom_Input_Description) {
            if (orderProduct.Order_Product_Custom_Input_Description.indexOf(' ') >= 0) {
              if (orderProduct.Order_Product_Custom_Input_Description.trim().length == 0) flag++;
            }
          }
        }
      })
      if (flag == 0) return false;
      else return true;
    }
    else return false;
  }

  deleteNewDocs(documents, index) {
    this.matDialog.open(ConfirmComponent, {data:{
      title: 'Delete Document',
      message: 'Are you sure you want to delete ' + documents.Document_Original_Name + ' ?'
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.orderDetails.orderDocId.splice(index, 1);
        } else {
        }
      });
  }

  deleteClientDocs(documents, index) {
    this.matDialog.open(ConfirmComponent, {data:{
      title: 'Delete Document',
      message: 'Are you sure you want to delete ' + documents.Document_Original_Name + ' ?'
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.orderDetails.clientDoc.splice(index, 1);
        } else {
        }
      });
  }

  deleteAdminDocs(documents, index) {
    this.matDialog.open(ConfirmComponent, {data:{
      title: 'Delete Document',
      message: 'Are you sure you want to delete ' + documents.Document_Original_Name + ' ?'
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.orderDetails.adminDoc.splice(index, 1);
        } else {
        }
      });
  }

  placeOrder() {
    if(this.isDueRangeErr) {
      this.order_ETA_Time = "17:00";
      this.requested_Time = "17:00";
      if(this.order_ETA_Date)this.order_ETA_Date = null;
      if(this.orderDetails.Order_ETA) this.orderDetails.Order_ETA = null;
      if(this.dueDate_Range) this.order_ETA_Date = this.setOrderETATime(this.dueDate_Range);
    }else {
      if(this.orderDetails.Order_ETA_Range) this.orderDetails.Order_ETA_Range = null;
      if(this.order_ETA_Date)this.order_ETA_Date = null;
      if (this.orderDetails.Order_ETA && this.order_ETA_Time) this.order_ETA_Date = this.setOrderETATime(this.orderDetails.Order_ETA);
    }
    if(this.orderDetails.Order_Requested_Date){
      if( this.orderDetails.Order_Requested_Date && this.requested_Time) this.order_Requested_Date =  this.setOrderRequestDate(this.orderDetails.Order_Requested_Date)
    }
    let orderDoc = [];
    orderDoc = orderDoc.concat(this.orderDetails.clientDoc);
    orderDoc = orderDoc.concat(this.orderDetails.adminDoc);
    if (this.alertMsgForPlaceOrder()) this.createOrder(orderDoc, this.order_ETA_Date, this.order_Requested_Date)
    else {
      this.openETAAlert().afterClosed()
        .subscribe((res) => {
          if (res) {
            this.createOrder(orderDoc, this.order_ETA_Date, this.order_Requested_Date);
          }
        })
    }
    // }
  }

  setOrderETATime(orderETA) {
    if(orderETA){
    let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
    let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
    return orderETA._d.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    }else return null;
  }

  setOrderRequestDate(RequestDate){
    if(RequestDate){
    let getOrderEtaHours = this.requested_Time.split(":")[0];
    let getOrderEtaMinutes = this.requested_Time.split(":")[1];
    return RequestDate._d.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
  }else return null;
}


  createOrder(orderDoc, finalDueDate, finalReqDate) {
    if(this.orderDetails.Order_ETA) this.checkForSelectedETA(this.orderDetails.Order_ETA, finalDueDate, orderDoc,finalReqDate);
    else this.createOrderSteps(finalDueDate, orderDoc, finalReqDate)

  }


  createOrderSteps(finalDueDate, orderDoc, finalReqDate) {
    if (finalDueDate) {
      var estShift = momentTimeZone.tz(finalDueDate, 'America/New_York').isDST() ? 4 : 5;
      var etaInEstTimezone = moment(finalDueDate).utcOffset(moment(finalDueDate).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
      this.orderDetails.Order_ETA = etaInEstTimezone;
    }
    if(finalReqDate) {
      var estShift = momentTimeZone.tz(finalReqDate, 'America/New_York').isDST() ? 4 : 5;
      var etaInEstTimezone = moment(finalReqDate).utcOffset(moment(finalReqDate).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
      this.orderDetails.Order_Requested_Date = etaInEstTimezone;
      
    }
    this.addCustomInputDescription();
    this.getOrderDetails();
    this.orderDetails.Order_Products.map((orderProduct) => {
      delete orderProduct.currInput;
      delete orderProduct.currBaseInput;
    });
    this.ordDetails.Order_Tags = this.orderTags;
    // this.ordDetails.Order_Source_Type_ID = 7;
    this.ordDetails.Order_Mode_Type_ID = 1;
		this.ordDetails['Parent_Order_ID'] = this.orderDetails.Order_ID;    
    if(this.orderDetails.Order_ETA) this.orderDetails.Order_ETA='';
    if(this.orderDetails.Order_Requested_Date) this.orderDetails.Order_Requested_Date='';
    console.log("this.orderDetails",this.orderDetails)
    this.newOrder.checkIfQualaiUser(this.orderDetails.Customer_ID)
      .subscribe((data) => {
        this.ordDetails.Qualia_Fee_Add = data.status ? 1: 0;   
        if(this.ordDetails.Organization_ID) {
          this.organizationService
          .getOrganizationDetails(this.ordDetails.Organization_ID)
          .subscribe((orgDetails) => {
              if(orgDetails) {
                if(orgDetails.Organization_Type == 1)  this.ordDetails.Order_Source_Type_ID = 4;
                else if(orgDetails.Organization_Type == 4) this.ordDetails.Order_Source_Type_ID = 3;
                else if(orgDetails.Organization_Type == 8) this.ordDetails.Order_Source_Type_ID = 2;
                else this.ordDetails.Order_Source_Type_ID = 7;
              }
               //check for qualia internal id for order source
              if(this.ordDetails.Order_Source_Type_ID &&this.ordDetails.Order_Source_Type_ID == 4 && !this.ordDetails.Order_Qualia_Internal_ID) this.ordDetails.Order_Source_Type_ID = 7;

             this.newOrder.updateNewOrder(this.ordDetails.Customer_ID, orderDoc, this.ordDetails)
              .subscribe((result) => {
                if (this.ordDetails.orderDocId) {
                  if (this.ordDetails.orderDocId.length > 0) this.updateOrderDocs(result);
                }
                this.router.navigate(['/orders/' + result.Order_ID + '/view']);
                this.newOrder.resetAll();
              })
          })         
        }           
      })    
  }

  alertMsgForPlaceOrder() {
    if (this.grandTotPrice) return 1;
    else return 0;
  }

  openETAAlert() {
    return this.matDialog.open(EtaAlertComponent, {data:{
      title: 'Place order',
      message: 'Grand total is $0. Do you want to proceed?',
      value: 'Proceed'
    }, ...this.config.getDialogOptions()})
  }

  getOrderDetails() {
    this.ordDetails = {
      Customer_ID: this.orderDetails.Customer_ID,
      Organization_ID: this.orderDetails.Organization_ID,
      BaseProduct: this.selectedProduct,
      Product_Description:this.selectedProductDescription,
      Product_Name:this.selectedProductName,
      Property_Order_Number: this.orderDetails.File_ID,
      Property_Latitude: this.orderDetails.Property_Latitude,
      Property_Longitude: this.orderDetails.Property_Longitude,
      Order_Products: this.orderDetails.Order_Products,
      Property_Address_1: this.orderDetails.Property_Address_1,
      Property_Address_2: this.orderDetails.Property_Address_2,
      Property_City: this.orderDetails.Property_City,
      Property_Status: this.orderDetails.Property_Status,
      Property_State_Abbr: this.orderDetails.Property_State_Abbr,
      Property_County: this.orderDetails.Property_County,
      Property_ZipCode: this.orderDetails.Property_ZipCode,
      Property_First_Name: this.orderDetails.Property_First_Name,
      orderDocId: this.orderDetails.orderDocId,
      Order_ETA: this.orderDetails.Order_ETA,
      Order_Parcel: this.orderDetails.Order_Parcel,
      Order_Loan: this.orderDetails.Order_Loan,
      Order_Buyer: this.orderDetails.Order_Buyer,
      Order_Co_Buyer: this.orderDetails.Order_Co_Buyer,
      Property_Order_Notes: this.orderDetails.Property_Order_Notes,
      Order_Instructions: this.orderDetails.Order_Instructions,
      Order_Book: this.orderDetails.Order_Book,
      Order_Loadn: this.orderDetails.Order_Loan,
      Order_Seller: this.orderDetails.Order_Seller,
      Order_Co_Seller: this.orderDetails.Order_Co_Seller,
      Order_Mortgage_Amount: this.orderDetails.Order_Mortgage_Amount,
      Order_Mortgage_Date: this.orderDetails.Order_Mortgage_Date,
      Order_Page: this.orderDetails.Order_Page,
      Order_Status: CONSTANTS.orderStatus.received,
      Order_Final_Price: this.suggTotPrice,
      Order_Custom_Price_Tax: this.totalTaxPrice,
      Order_Min_Tat: this.productDetails.Cost_Min_Tat,
      Order_Max_Tat: this.productDetails.Cost_Max_Tat,
      Order_Custom_Price: this.totalProductPrice,
      Order_ETA_Range:this.orderDetails.Order_ETA_Range,
      Order_Requested_Date: this.orderDetails.Order_Requested_Date,
      Order_Subdivision: this.orderDetails.Order_Subdivision,
      Order_Qualia_Internal_ID: this.orderDetails.Order_Qualia_Internal_ID,
    }
  }

  updateOrderDocs(data) {
    var docUpdate = [];
    this.orderDetails.orderDocId.forEach(docId => {
      docUpdate.push(this.newOrder.updateOrderDocument(data.Order_ID, data.Property_ID, docId.Order_Document_ID));
    })
    observableForkJoin(docUpdate)
    .subscribe((result) => {
    })
    observableOnErrorResumeNext(docUpdate)
      .subscribe(result => {
      },
        err => {
        })
  }

  openDialog() {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: 'Success',
      message: 'Your order has been placed.'
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      this.router.navigate(['/order/new'])
    });
  }

  fetchUploadProgress() {
    this.s3Service.progress
      .subscribe((res) => {
        var matchedIndex = this.newDocs.findIndex((doc) => doc.name == res.name);
        if(this.newDocs[matchedIndex]){
          this.newDocs[matchedIndex].completed = Math.round(((res.progress.loaded / res.progress.total) * 100) * 100) / 100;
          this.newDocs[matchedIndex].completed = Math.round(this.newDocs[matchedIndex].completed);
        }
      });
  }

  checkForRoleAdmin() {
    if ((Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) || (Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin)) return true;
    else return false;
  }

  assignSubProductFocus(){
    this.subProductFocusFlag = false;
  }

  checkStartDate(etaDate){
    if(!etaDate){
      let newDate = new Date(this.getUSDateString);
      if(newDate.getHours() > 17){
        newDate.setDate(newDate.getDate() + 1)
        newDate.setHours(17);
        newDate.setMinutes(0);
      } else {
        newDate.setHours(17);
        newDate.setMinutes(0);
      }
      return newDate;
    }
  }

  getFontColor(hexColor) {
    return this.tagService.getFontColor(hexColor)
  }

  openNewTagModal(orderId) {
    this.matMenuTrigger.closeMenu();
    this.matDialog.open(NewTagComponent, {data:{
      message: { Order_ID: null }
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if (res) {
        // sessionStorage.removeItem('AllTagsAvailable');
        if(typeof res == 'object') this.orderTags.push(res);
      }
    });
  }

  clearSearchTag() {
    this.searchTag = '';
  }

  menuClosed() {
    this.clearSearchTag();
    this.availableTags = [];
  }

//   getAvailableTagLocal(){
//     let AllTag = JSON.parse(sessionStorage.getItem('AllTagsAvailable'));
//     if(AllTag) this.openTagMenu(AllTag)         
//     else this.getAvailableTagList();
// }
  
//   getAvailableTagList(){
//     this.tagService.getAllActiveTagsForListing().subscribe((tags) => { 
//       if(tags){
//         this.openTagMenu(tags)         
//         sessionStorage.setItem('AllTagsAvailable',JSON.stringify(tags)); 
//        setTimeout(function(){sessionStorage.removeItem("AllTagsAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     }, (err) => {
//         this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
//     })
//   }

  openTagMenu() {
    this.tagService.getAllActiveTagsForListing()
      .subscribe((activeTags) => {
        for (var i = 0, len = activeTags.rows.length; i < len; i++) {
          if (this.orderTags.findIndex((select => activeTags.rows[i].Tag_ID == select.Tag_ID)) == -1) {
            if(activeTags.rows[i].Tag_ID != this.config.getNewClientTagId() && activeTags.rows[i].Tag_ID != this.config.getPrePaidTagId() && activeTags.rows[i].Tag_ID != this.config.getPartiallyDeliveredTagId() && activeTags.rows[i].Tag_ID != this.config.getAbsDoubleAssignTagId()) this.availableTags.push(activeTags.rows[i]);
          }
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  // openTagMenu(activeTags) {
  //       for (var i = 0, len = activeTags.rows.length; i < len; i++) {
  //         if (this.orderTags.findIndex((select => activeTags.rows[i].Tag_ID == select.Tag_ID)) == -1)
  //           this.availableTags.push(activeTags.rows[i]);
  //       }
  // }

  removeTag(selectedTag) {
    var matchedIndex = this.orderTags.findIndex((tag) => tag.Tag_ID == selectedTag.Tag_ID)
    if(matchedIndex > -1) this.orderTags.splice(matchedIndex, 1);
  }

  identify(index, tag) {
    return tag.Tag_ID;
  }

  modifyOrderTags(event, tag) {
    if(event.checked){ 
      this.orderTags.push(tag);
      this.availableTags = this.availableTags.filter(item => (item.Tag_ID !== tag.Tag_ID))
    }else this.removeTag(tag);
  }

  getDueDateData(event) {
    this.dueDateRangeOption = Number(event.dueDateType);
    if (Number(event.dueDateType)) {
      if (event.dueDateFrmCtrl) {
        this.isDueRangeErr = true;
        this.dueDateFrmObj = event.dueDateFrmCtrl;
        if(this.dueDateFrmObj) {
          if (this.dueDateFrmObj.value.fromDayCtrl && this.dueDateFrmObj.value.toDayCtrl) {
            let getDueDateRange = this.dueDateFrmObj.value.fromDayCtrl + "-" + this.dueDateFrmObj.value.toDayCtrl;
            let addDays = momentTimeZone.tz('America/New_York').add(Number(this.dueDateFrmObj.value.toDayCtrl), 'days').format()
            let getDateObj =  this.Convert_UTCDate_To_LocalDate(addDays, 'America/New_York');
            this.dueDate_Range = getDateObj; 
            this.orderDetails.Order_ETA_Range = getDueDateRange;
          }else {
            this.dueDate_Range =null;
            this.orderDetails.Order_ETA_Range = null;        
          }
        }
      }
    } else {
      this.isDueRangeErr = false;
      if (event.dueDateTimeCtrl) {
        this.dueDateTimeObj = event.dueDateTimeCtrl;
        if (event.dueDateTimeCtrl.valid) this.checkForDueDateEST(event);
      }
      else this.checkForDueDateEST(event);
    }
  }

  Convert_UTCDate_To_LocalDate(utcTime: any, localTimeZone: string) {
    var utcMoment = momentTimeZone(utcTime);
    var localDateTime = utcMoment.tz(localTimeZone);
    return new Date(localDateTime.format('lll'));
  }

  checkForDueDateValid() {
    if (this.dueDateRangeOption) {
          if(this.dueDateFrmObj && this.dueDateFrmObj.valid) return false;
          else return true;
    } else {
      if (this.dueDateTimeObj) {
        if (this.dueDateTimeObj.valid) return false;
        else if (!this.dueDateTimeObj.valid) return true
      } else return false;
    }
  }

  checkForDueDateEST(event) {
    if (event.Due_Date_EST || event.Due_TIme_EST) {
      this.orderDetails.Order_ETA = event.Due_Date_EST;
      this.order_ETA_Time = event.Due_TIme_EST;
    }
    if(event.req_Date_EST || event.req_Time_EST){
      this.orderDetails.Order_Requested_Date = event.req_Date_EST;
      this.requested_Time = event.req_Time_EST;
    }
  }

  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return false;
    else return true;
  }  
  
  getholidaylistFromLocal(){
    let allHolidays = JSON.parse(sessionStorage.getItem('holidays'))
    if(allHolidays) this.setHoliday(allHolidays)
    else this.getholidaylist();
  }

  setHoliday(holiday){
    this.holidayList = holiday
  }

  getholidaylist(){
    this.holidayScheduling.getAllHolidays()
    .subscribe((holidays)=>{
      if(holidays){
        this.setHoliday(holidays)
        sessionStorage.setItem('holidays', JSON.stringify(holidays));
     setTimeout(function(){sessionStorage.removeItem("holidays");}, CONSTANTS.sessiontimeOut.timeOut);
      }
      // this.holidayList = holidays;
    }, (err) =>{
      console.log("Error",err)
    })
  }
  
  checkForSelectedETA(orderETA, finalDueDate, orderDoc, finalReqDate){
    let orderETADate = moment(orderETA).format("YYYY-MM-DD");
    let checkETAIsHoliday = this.holidayList.findIndex((holiday) => {
      if(holiday && moment(holiday.Holiday_Date, "YYYY-MM-DD").isSame(orderETADate)) return true;
      else return false
    })
    if(checkETAIsHoliday > -1 ) this.showWarningPopup(this.holidayList[checkETAIsHoliday].Holiday_Name, finalDueDate,orderDoc,finalReqDate);
    else if(this.checkETALieOnWeekend(orderETA)) this.showWarningPopup(this.weekendHolidayName,  finalDueDate,orderDoc,finalReqDate)
    else this.createOrderSteps(finalDueDate, orderDoc, finalReqDate);
  }

  showWarningPopup(holidayName, finalDueDate, orderDoc,finalReqDate){
    let disposable = this.matDialog.open(ConfirmComponent, {data:{
      title:'ETA Note',
      message:"The ETA on this order is a US holiday ("+holidayName+"). Would you like to continue?"
    }, ...this.config.getDialogOptions()}).afterClosed()
    .subscribe((res) => { 
      if(res) this.createOrderSteps(finalDueDate, orderDoc,finalReqDate);
    });
  }

  checkETALieOnWeekend(orderETA) {
    if(moment(orderETA).isoWeekday() == 7){ // iso Weekday for sunday is 7
      this.weekendHolidayName = "Sunday";
      return true;
    }
    else if(moment(orderETA).isoWeekday() == 6){ // iso Weekday for saturday is 6
      this.weekendHolidayName = "Saturday";
      return true;
    }else return false;
  }

  isSuperAdmin(){
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else if(this.auth.getUserId() == environment.superAdminId) return true;
    else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
    else return false;
  }

  getAllsubNBaseProd(){
    let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID:  this.orderDetails.Customer_ID;
    this.orderService.getSubNBaseProduct(customerId)
   .subscribe((res)=>{
     this.allProducts = res;
   })
  }

  validateSearchDesc() {
    if (this.orderDetails.Order_Products) {
      if (this.orderDetails.Order_Products.findIndex(orderProduct =>{
        return (orderProduct.Product_ID == 999) && ((orderProduct.Order_Product_Custom_Name == undefined) || (orderProduct.Order_Product_Custom_Name == ''))})) return true;
      else return false;
    }
  }

  getProductPricingDetails(event, productId,state, county, id, modelname, index,subProductIndex=null) {
    if (event && county) {
      this.orderDetails.BaseProduct = productId;
      this.getProductPricing(productId, state, county, id, modelname, index,subProductIndex)
    }
  }

  addCustomInputDescription(){
    this.orderDetails.Order_Products.forEach((prodDesc,index)=>{
      if(prodDesc.Order_Product_Status == 1){
        let prodDescIndex = this.products.findIndex((prod)=>prod.Product_ID == prodDesc.Product_ID)
        prodDesc.Order_Product_Custom_Input_Description = this.products[prodDescIndex].Product_Description;
       }
       if(prodDesc.Order_Product_Status == 2){
          let prodDescIndex = this.allProducts.findIndex((prod)=>prod.Product_ID == prodDesc.Product_ID)
          prodDesc.Order_Product_Custom_Input_Description = this.allProducts[prodDescIndex].Product_Description;
          prodDesc.Order_Product_Description = this.allProducts[prodDescIndex].Product_Description;
        }
    })
  }

  validateReqDateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors.Order_Requested_Date = true;
    else this.errors = {};
  }

  clearForms(event){
    this.errors = {}
  }

}
