import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchProps'
})
export class SearchPropsPipe implements PipeTransform {

  transform(items: any[], searchText: string, keys: string[]): any[] {    
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.trim().toLowerCase();
    return items.filter(item => this.matchKeys(item, keys, searchText));
  }

  matchKeys(item, keys: any[], searchText) : boolean{
    var matched = false;    
    for(var key in keys){
      var currentKey = keys[key];
      if((item[currentKey] && !isNaN(item[currentKey]) && item[currentKey].toString().includes(searchText)) || (item[currentKey] && isNaN(item[currentKey]) && item[currentKey].toLowerCase().includes(searchText))){
        matched = true;
        break;
      }
    }
    return matched;
  }

}
