// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.align-icons {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/holidayScheduling/holiday-schedule/holiday-schedule.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EAAiB,aAAA;EAAe,SAAA;AAGpC","sourcesContent":[".align-icons{\n    margin-top: 20px;display: flex; gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
