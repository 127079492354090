// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog {
  margin-top: 9rem;
}

.dialog-alignment {
  text-align: center;
  padding-top: 40px;
}

.modal-content {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/complete-order/complete-order.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AACA;EACI,kBAAA;EACA,iBAAA;AAEJ;;AACA;EACI,WAAA;AAEJ","sourcesContent":[".modal-dialog {\n    margin-top: 9rem;\n}\n.dialog-alignment{\n    text-align: center;\n    padding-top: 40px;\n}\n\n.modal-content{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
