import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from 'app/app.constants';
import { ErrorDialogComponent } from 'app/dialog/error-dialog/error-dialog.component';
import { EmailTemplatesService, AuthService, PagerService, ConfigService, PreloaderService, ScrollService, StateService, } from 'app/services';
import { DialogService } from 'ng2-bootstrap-modal';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-email-template-listing',
  templateUrl: './email-template-listing.component.html',
  styleUrls: ['./email-template-listing.component.scss']
})
export class EmailTemplateListingComponent implements OnInit {

  constants = CONSTANTS
  emailTemplates: any = [];
  emailTemplatesToDisplay: any;
  totalCount: number;
  pager: any = {};
  pageNum: number;
  scrollEnable: boolean = true;
  srollDistance=this.config.setScrollDistance();
  srollThrottle=this.config.setScrollThrottle();
  timer: any = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private pagerService: PagerService,
    private config: ConfigService,
    private emailTemplateService: EmailTemplatesService,
    private matDialog:MatDialog,
    private stateService: StateService,
    private scrollService: ScrollService
  ) {
    this.pager.pages = [];
    if(this.pageNum == undefined)
    this.pageNum = 1;
  }

  ngOnInit() {
    if(this.stateService.getEmailTemplates())
    this.initializeComponentData(this.stateService.getEmailTemplates())
    else this.getInitialEmailTemplates();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(31), 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }


  setDisplayEmailTemplates() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.emailTemplatesToDisplay = this.emailTemplates.slice(start, this.totalCount);
    if (this.emailTemplatesToDisplay.length > this.config.getNumRecordsPerPage()) this.emailTemplates = this.emailTemplates.slice(0, this.config.getNumRecordsPerPage());
  }

  getEmailTemplates() {
    this.pageNum = 1;
    this.emailTemplateService.getEmailTemplates(this.pageNum)
      .subscribe((result) => {
        if (result) {
          this.emailTemplates = result.rows;
          this.totalCount = result.count;
          this.setDisplayEmailTemplates();
          this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  getInitialEmailTemplates() {
    if(this.pageNum == 1){
      this.stateService.setEmailTemplates(this);
      this.emailTemplateService.getEmailTemplates(this.pageNum)
      .subscribe((result1) => {
        if (result1.count > this.config.getNumRecordsPerPage()) {
          this.pageNum++;
          this.emailTemplateService.getEmailTemplates(this.pageNum)
            .subscribe((result2) => {
              var result = {
                rows: result1.rows.concat(result2.rows),
                count: result1.count
              }
              this.getEmailTemplateData(result)
            })
        }
        else {
          this.getEmailTemplateData(result1)
        }
      } , (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
    }else{
      this.getRetainData();
    }
  }

  getRetainData(){
    var ObsCollection = [];
    for(var i=1;i<=this.pageNum;i++){
      ObsCollection.push(this.emailTemplateService.getEmailTemplates(i));
    }

    observableForkJoin(ObsCollection)
    .subscribe((retainEmailTemplate) => {
      if(retainEmailTemplate){
        var totalOrders = [];
        for (var i = 0; i < retainEmailTemplate.length; i++) {
          this.stateService.setEmailTemplates(this)
          totalOrders = totalOrders.concat(retainEmailTemplate[i]['rows']);
        }
        this.emailTemplates = totalOrders;
        this.totalCount = retainEmailTemplate[0]['count'];
        this.getEmailTemplateRecordsData(this.totalCount);
        this.getScrollPosData()
      }
    })
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.getInitialEmailTemplates();
  }

  goToEmailTemplateDetails(templateId) {
    var getPos = document.documentElement.scrollTop
    this.scrollService.setScrollPosEmailTemplates(getPos);
    this.router.navigate(['/email-template/' + templateId])
  }
  

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  handleEvent(event){
    if(event ==false){
    if(this.totalCount > this.config.getNumRecordsPerPage()){
      if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
      else this.scrollEnable = true;
    }
  }else
    this.scrollEnable = true;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.emailTemplateService.getEmailTemplates(this.pageNum)
        .subscribe((result) => {
          if (result) {
            this.totalCount = result.count;
            this.config.resetShowFlag()
            if (this.totalCount > this.emailTemplates.length) {
              this.emailTemplates = this.emailTemplates.concat(result.rows);
              this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }
  getEmailTemplateRecordsData(result){
      this.config.resetShowFlag();
      this.config.setClearTimeout();
      this.setDisplayEmailTemplates();
      this.pager = this.pagerService.getPager(result, this.pageNum, this.config.getNumRecordsPerPage());
      this.config.setDelayRecords();
  }

  getScrollPosData(){
    if (this.scrollService.getScrollPosEmailTemplates()) {
      var x = this.scrollService.getScrollPosEmailTemplates()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }));
    }
  }

  getEmailTemplateData(result){
    this.emailTemplates = result.rows;
    this.totalCount = result.count;
    this.getEmailTemplateRecordsData(this.totalCount)
    this.getScrollPosData()
  }  

}
