import { Injectable, EventEmitter } from "@angular/core";
import { UUID } from "angular2-uuid";
import { AuthService } from "../authentication/auth.service";
import { ConfigService } from "../config/config.service";
import { HttpClient, HttpHeaders, HttpBackend } from "@angular/common/http";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import * as mime from "mime/lite";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class AwsS3Service {
  //adds the S3 service, make sure the api version and bucket are correct
  direct: HttpClient;
  s3: S3;
  private _presignedURL$: BehaviorSubject<any> = new BehaviorSubject("");
  public presignedURL$: Observable<any> = this._presignedURL$.asObservable();
  public progress: EventEmitter<any> = new EventEmitter();

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private handler: HttpBackend,
    private http: HttpClient
  ) {
    this.direct = new HttpClient(this.handler);
    //Configures the AWS service and initial authorization
    AWS.config.update({
      region: this.config.getS3Region(),
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.config.getS3Identity(),
      }),
    });
    this.s3 = new S3({
      apiVersion: this.config.getS3ApiVersion(),
      params: { Bucket: this.config.getS3Bucket() },
    });
  }

  generatePath(orderId) {
    return (
      new Date().getFullYear().toString() +
      "/" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "/" +
      new Date().getDate() +
      "/" +
      orderId.toString() +
      "/"
    );
  }

  generateDocTagPath(orderId, documentTagName) {
    return (
      new Date().getFullYear().toString() +
      "/" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "/" +
      new Date().getDate() +
      "/" +
      orderId.toString() +
      "/" +
      documentTagName.toString() +
      "/"
    );
  }

  generateOtherDocTagPath(orderId, documentTagName, otherDocsListName) {
    return (
      new Date().getFullYear().toString() +
      "/" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "/" +
      new Date().getDate() +
      "/" +
      orderId.toString() +
      "/" +
      otherDocsListName.toString() +
      "/" +
      documentTagName.toString() +
      "/"
    );
  }

  getFileExt(fileName) {
    var temp = fileName.split(".");
    return temp[temp.length - 1];
  }

  updateDocumentPath(
    orderId,
    propertyId,
    file,
    path,
    name,
    documentType,
    category = null
  ) {
    var data = {
      Property_ID: propertyId,
      Order_ID: orderId,
      File: {
        Original_Name: file.name,
        Path: path,
        Name: name,
        Type: documentType,
        Doc_Cat_ID: category,
      },
      Created_By: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() + "/orders/" + orderId + "/admin/documentpath";
    return this.direct
      .post(url, data, httpOptions)
      .toPromise()
      .then((response: any) => response)
      .catch((error: any) => {
        throw error.error;
      });
  }

  updateDocumentTagPath(
    orderId,
    propertyId,
    file,
    path,
    name,
    documentType,
    documentTagName,
    otherDocsListName
  ) {
    var data = {
      Property_ID: propertyId,
      Order_ID: orderId,
      File: {
        Original_Name: file.name,
        Path: path,
        Name: name,
        Type: documentType,
      },
      Document_Tag_Name: documentTagName,
      Created_By: this.auth.getUserId(),
      Other_Tag_List_Name: otherDocsListName,
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/orders/upload/documentTag";
    return this.direct
      .post(url, data, httpOptions)
      .toPromise()
      .then((response: any) => response)
      .catch((error: any) => {
        throw error.error;
      });
  }

  updateAbsDocumentPath(orderId, propertyId, file, path, name) {
    var data = {
      Property_ID: propertyId,
      Order_ID: orderId,
      File: {
        Original_Name: file.name,
        Path: path,
        Name: name,
        Type: 3,
      },
      Created_By: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() +
      "/abstractors/orders/" +
      orderId +
      "/documentpath";
    return this.direct
      .post(url, data, httpOptions)
      .toPromise()
      .then((response: any) => response)
      .catch((error: any) => {
        throw error.error;
      });
  }

  public onManagedUpload(file: File, orderId, filename = UUID.UUID()) {
    let params = {
      Key:
        this.generatePath(orderId) +
        filename +
        "." +
        this.getFileExt(file.name),
      Bucket: this.config.getS3Bucket(),
      Body: file,
      ContentDisposition:
        'attachment; filename="' + file.name.replace(/ /g, "%20") + '"',
    };
    let options = {
      params: params,
      partSize: 5 * 1024 * 1024,
    };
    let handler = new S3.ManagedUpload(options);
    handler.on(
      "httpUploadProgress",
      this._httpUploadProgress.bind(this, file.name)
    );
    handler.send(this._send.bind(this));
    return handler.promise();
  }

  public onManagedDocTagUpload(
    file: File,
    orderId,
    documentTagName,
    isOtherDocumentTag,
    otherDocsListName,
    filename = UUID.UUID()
  ) {
    let fullDocumentTagPath: any;
    if (!isOtherDocumentTag)
      fullDocumentTagPath = this.generateDocTagPath(orderId, documentTagName);
    else
      fullDocumentTagPath = this.generateOtherDocTagPath(
        orderId,
        documentTagName,
        otherDocsListName
      );
    let params = {
      Key: fullDocumentTagPath + filename + "." + this.getFileExt(file.name),
      Bucket: this.config.getS3Bucket(),
      Body: file,
      ContentDisposition:
        'attachment; filename="' + file.name.replace(/ /g, "%20") + '"',
    };
    let options = {
      params: params,
      partSize: 5 * 1024 * 1024,
    };
    let handler = new S3.ManagedUpload(options);
    handler.on(
      "httpUploadProgress",
      this._httpUploadProgress.bind(this, file.name)
    );
    handler.send(this._send.bind(this));
    return handler.promise();
  }

  public onManagedBillUpload(file: File, filename = UUID.UUID()) {
    let params = {
      Key: "Billing/" + filename + "." + this.getFileExt(file.name),
      Bucket: this.config.getS3Bucket(),
      Body: file,
      ContentDisposition:
        'attachment; filename="' + file.name.replace(/ /g, "%20") + '"',
    };
    let options = {
      params: params,
      partSize: 5 * 1024 * 1024,
    };
    let handler = new S3.ManagedUpload(options);
    handler.on(
      "httpUploadProgress",
      this._httpUploadProgress.bind(this, file.name)
    );
    handler.send(this._send.bind(this));
    return handler.promise();
  }

  public onManagedCpnyFileUpload(file: File, cpnyId, filename) {
    let params = {
      Key: `Company/${cpnyId}/${filename}.${this.getFileExt(file.name)}`,
      Bucket: this.config.getS3Bucket(),
      Body: file,
      ContentDisposition:
        'attachment; filename="' + file.name.replace(/ /g, "%20") + '"',
    };
    let options = {
      params: params,
      partSize: 5 * 1024 * 1024,
    };
    let handler = new S3.ManagedUpload(options);
    handler.on(
      "httpUploadProgress",
      this._httpUploadProgress.bind(this, file.name)
    );
    handler.send(this._send.bind(this));
    return handler.promise();
  }

  private _httpUploadProgress(filename, progress): void {
    var toBeEmitted = {
      progress: progress,
      name: filename,
    };
    this.progress.emit(toBeEmitted);
  }

  private _send(err, data): void {
    console.log("send()", err, data);
  }

  public getPreviewSignedUrl(key, filename) {
    return this.s3.getSignedUrl("getObject", {
      Bucket: this.config.getS3Bucket(),
      Key: key,
      ResponseContentType: this.getFileExtension(filename),
      // ResponseContentType: mime.getType(filename)
    });
  }

  private getFileExtension(filename: string): string | null {
    const parts = filename.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1].toLowerCase();
    }
    return null;
  }

  public deleteFromS3(key) {
    return this.s3
      .deleteObject({ Bucket: this.config.getS3Bucket(), Key: key })
      .promise();
  }

  updateDocumentPathForPDF(
    orderId,
    propertyId,
    file,
    path,
    name,
    documentType
  ) {
    var data = {
      Property_ID: propertyId,
      Order_ID: orderId,
      File: {
        Original_Name: file.name,
        Path: path,
        Name: name,
        Type: documentType,
      },
      Created_By: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() + "/orders/" + orderId + "/admin/documentpath";
    return this.http
      .post(url, data, httpOptions)
      .toPromise()
      .then((response: any) => response)
      .catch((error: any) => {
        throw error.error;
      });
  }

  downloadFromPath(key, filename) {
    const resourceStore = this.s3;
    const bucketDetail = this.config.getS3Bucket();
    return new Promise(function (resolve, reject) {
      AWS.config.getCredentials((err) => {
        if (err) reject(err);
        else {
          resolve(
            resourceStore.getSignedUrl("getObject", {
              Bucket: bucketDetail,
              Key: key,
              ResponseContentDisposition:
                "attachment; filename = " + filename.replace(/ /g, "%20"),
            })
          );
        }
      });
    });
  }

  resetPresignedURL() {
    this._presignedURL$.next("");
  }

  observePresignedURL(key, filename) {
    var params = {
      Bucket: this.config.getS3Bucket(),
      Key: key,
      ResponseContentType: this.getFileExtension(filename),
      Expires: 60*60*24*7
    };
    this.s3
      .getSignedUrlPromise("getObject", params)
      .then((signed) => {
        this._presignedURL$.next(signed);
      });
  }
}
