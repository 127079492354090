// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alignBaseline {
  vertical-align: -webkit-baseline-middle;
  vertical-align: -moz-middle-with-baseline;
}`, "",{"version":3,"sources":["webpack://./src/app/search-master/edit-search-master/edit-search-master.component.scss"],"names":[],"mappings":"AAAA;EACI,uCAAA;EACA,yCAAA;AACJ","sourcesContent":[".alignBaseline{\n    vertical-align: -webkit-baseline-middle;\n    vertical-align: -moz-middle-with-baseline   \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
