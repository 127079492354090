import { Component, OnInit ,ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmModel {
  title:string;
  message:any;
}

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent{
  @ViewChild('okBtn') focusedElement: ElementRef;


  title: string;
  message: any;
  result : boolean = true;
  timer: any = [];
  constructor(public dialogRef: MatDialogRef<SuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      this.title=this.data.title;
      this.message=this.data.message;
  }
  confirm() {
    this.dialogRef.close(this.result);
    // this.result = this.result;
    this.clearTime();
  }

   
  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => { this.focusedElement.nativeElement.focus() }, 0));
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  clearTime(){
    this.dialogRef.close();
    // this.close();
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
