import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ConfigService,
  OrdersService,
  PricingService,
  StateService,
  UsermessageService,
} from "../services";
import { CountiesService } from "app/services/counties/counties.service";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import { ColDef, GridApi } from "ag-grid-community";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import * as moment from "moment";
@Component({
  selector: "app-counties",
  templateUrl: "./counties.component.html",
  styleUrls: ["./counties.component.scss"],
})
export class CountiesComponent implements OnInit, OnDestroy {
  timer: any = [];
  selectedState: any = null;
  states: any = [];
  counties: any = [];
  countyMappings: any = {};
  modules = [ClientSideRowModelModule, InfiniteRowModelModule];
  rowData: any[] = [];
  colDefs: ColDef[];
  gridApi: GridApi;
  error: string = "";
  sopError: string = "";
  tinyOptions: any = {
    auto_focus: false,
    branding: false,
    promotion: false,
    skin: "oxide",
    menubar: false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"],
    placeholder: "County Notes...",
    toolbar:
      "undo redo | styleselect | forecolor backcolor |  bold italic |  alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
  };
  private ngUnsubscribe = new Subject();
  NotesForm: any;
  SOPNNotes: any = {};
  constructor(
    private config: ConfigService,
    private pricingService: PricingService,
    private countiesService: CountiesService,
    private frmBuilder: FormBuilder,
    private ordersService: OrdersService,
    private userMessaging: UsermessageService,
    private stateService: StateService
  ) {
    this.config.setMySidebar(false);
    this.countiesService.resetDataDepth();
    this.NotesForm = frmBuilder.group({
      SOP_Link: [null],
      Note_Text: [null],
    });
  }

  ngOnInit() {
    this.showSideBarPanel();
    this.pricingService.getAvailableStateList().subscribe((states) => {
      this.states = states;
      if (this.stateService.getCounties())
        this.initializeComponentData(this.stateService.getCounties());
    });
    this.countiesService.dataDepthInfo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.rowData = data;
      });
  }

  ngOnDestroy(): void {
    this.countiesService.resetDataDepth();
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  showSideBarPanel() {
    if (Number(this.config.getMySidebar()))
      this.timer.push(setTimeout(() => this.config.setSideBarNode(36), 0));
    else this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
  }

  getData(statename) {
    this.error = "";
    this.stateService.setCounties({ selectedState: this.selectedState });
    this.pricingService.getAvailableCountyList(statename).subscribe((data) => {
      this.counties = data;
      this.countyMappings = {};
      this.counties.forEach((county) => {
        this.countyMappings[county.County_Name.toLowerCase()] = county.State_ID;
      });
      this.colDefs = this.createColumnDefs();
      this.countiesService.getStateDataDepth(statename).subscribe();
      this.getSOPInfo();
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  cellValueChanged(event) {
    this.error = "";
    // console.log("cellValueChanged event", event);
    if (!event.data.County_Name) this.handleInvalidCounty(event);
    else {
      if (event.colDef.field == "County_Name") {
        let matchingDupIndex = this.checkIfCountyAlreadyAvbl(
          event.value,
          event.rowIndex
        );
        if (matchingDupIndex > -1 && matchingDupIndex != event.rowIndex)
          this.handleDupCounty(event, matchingDupIndex);
      } else {
      }
      let matchingStateId = this.lookupCountyMap(event);
      if (!matchingStateId) this.handleInvalidCounty(event);
      else {
        let modifiedRow = Object.assign({}, event.data);
        if(['GO', 'Grantor_Grantee', 'Title_Point_Images', 'Images', 'Index', 'Unindexed'].includes(event.colDef.field)) {
          let modifiedGridRow = this.rowData.find((row) => row.Id == event.data.Id)
          if(modifiedRow[event.colDef.field]){  
            let selected = moment(modifiedRow[event.colDef.field]).format('MM-DD-YYYY');
            modifiedRow[event.colDef.field]= moment.utc(selected).startOf('day').toISOString();
            modifiedGridRow[event.colDef.field]= moment.utc(selected).startOf('day').toISOString();
            event.node.setDataValue(event.colDef.field, moment.utc(selected).startOf('day').toISOString());
          }
          else {
            modifiedRow[event.colDef.field] = null; 
            modifiedGridRow[event.colDef.field]=null;
            event.node.setDataValue(event.colDef.field, null);
          }             
          event.api.refreshCells({ force: true });       
        }
        modifiedRow.State_Id = matchingStateId;
        let focussedCell = this.gridApi.getFocusedCell();
        this.countiesService.updateDataDepth(modifiedRow).subscribe(() => {
          this.gridApi.setFocusedCell(
            focussedCell.rowIndex,
            focussedCell.column.getColId()
          );
        });
      }
    }
  }

  handleInvalidCounty(event) {
    this.error = "Please select a county name";
    var editCol = event.api.getAllDisplayedColumns()[1];
    event.api.ensureColumnVisible(editCol);
    event.api.setFocusedCell(event.rowIndex, editCol);
  }

  handleDupCounty(event, matchingDupIndex) {
    this.error = `Selected county name ${
      event.value
    } is available elsewhere in row number ${
      matchingDupIndex + 1
    } in the list. Please update the relevant row.`;
    event.api.undoCellEditing();
  }

  checkIfCountyAlreadyAvbl(selectedCounty, modifiedIndex) {
    return this.rowData.findIndex(
      (row, index) =>
        row.County_Name &&
        selectedCounty &&
        index != modifiedIndex &&
        row.County_Name.toLowerCase() == selectedCounty.toLowerCase()
    );
  }

  lookupCountyMap(event) {
    return this.countyMappings.hasOwnProperty(
      event.data.County_Name.toLowerCase()
    )
      ? this.countyMappings[event.data.County_Name.toLowerCase()]
      : null;
  }

  createColumnDefs() {
    return [
      { headerName: "State", field: "State_Short_Name", editable: false },
      {
        headerName: "County",
        field: "County_Name",
        editable: this.checkEditFunction.bind(this),
        cellEditorParams: {
          values: extractValues(this.countyMappings),
        },
      },
      // { headerName: "State-County", field: "State-County", editable: false },
      {
        headerName: "TitlePoint GO",
        field: "GO",
        editable: true,
        cellDataType: "date",
        valueFormatter: this.dateFormatter,
      },
      {
        headerName: "TitlePoint Grantor Grantee",
        field: "Grantor_Grantee",
        editable: true,
        cellDataType: "date",
        valueFormatter: this.dateFormatter
      },
      {
        headerName: "TitlePoint Images",
        field: "Title_Point_Images",
        editable: true,
        cellDataType: "date",
        valueFormatter: this.dateFormatter
      },
      { headerName: "State Statute", field: "State_Statute", editable: true },
      {
        headerName: "Images",
        field: "Images",
        editable: true,
        cellDataType: "date",
        valueFormatter: this.dateFormatter
      },
      {
        headerName: "Index",
        field: "Index",
        editable: true,
        cellDataType: "date",
        valueFormatter: this.dateFormatter
      },
      {
        headerName: "Unindexed",
        field: "Unindexed",
        editable: true,
        cellDataType: "date",
        valueFormatter: this.dateFormatter
      },
      {
        headerName: "Online Data [years]",
        field: "Online Data [years]",
        editable: false,       
      },
      {
        headerName: "Online FS?",
        field: "Online FS",
        editable: false,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: extractValues({ TRUE: "TRUE", FALSE: "FALSE" }),
        }
      },
      {
        headerName: "Title Point Online Data [years]",
        field: "Title Point Online Data Years",
        editable: false,
      },
      { headerName: "PAID/FREE/ABS", field: "Paid_Free_Abs", editable: true },
      {
        headerName: "Subscription Recurrence",
        field: "Subscription_Recurrence",
        editable: true,
      },
      {
        headerName: "Subscription Cost",
        field: "Subscription_Cost",
        editable: true,
      },
      {
        headerName: "Subscription Active?",
        field: "Subscription_Active",
        editable: true,
      },
      {
        headerName: "Subscription Expiry Date",
        field: "Subscription_Expiry_Date",
        editable: true,
      },
      {
        headerName: "1Password Credentials Available?",
        field: "1Password_credentials",
        editable: true,
      },
      { headerName: "Tax", field: "Tax", editable: true },
      { headerName: "Assessor", field: "Assessor", editable: true },
      { headerName: "Recorder Site", field: "Recorder_Site", editable: true },
      {
        headerName: "Recorder Site - 2",
        field: "Recorder_Site_2",
        editable: true,
      },
      {
        headerName: "Unindexed Search Link",
        field: "Unindexed_Search_Link",
        editable: true,
      },
      { headerName: "Judgement Link", field: "Judgement_Link", editable: true },
      { headerName: "Probate Link", field: "Probate_Link", editable: true },
      { headerName: "STL", field: "STL", editable: true },
      { headerName: "Divorce Link", field: "Divorce_Link", editable: true },
      {
        headerName: "Addtl Lien Search",
        field: "Addtl_Lien_Search",
        editable: true,
      },
      { headerName: "Child Support", field: "Child_Support", editable: true },
      { headerName: "Comments", field: "Comments", editable: true },
      { headerName: "UCC Search", field: "UCC_Search", editable: true },
      {
        headerName: "Recording Jurisdiction",
        field: "Recording_Jurisdiction",
        editable: true,
      },
    ];
  }

  addCounty() {
    this.rowData.unshift({
      Id: "New",
      State_Short_Name: this.selectedState,
    });
    this.gridApi.setGridOption("rowData", this.rowData);
  }

  saveSOPInfo() {
    this.sopError = "";
    if (!this.SOPNNotes.Id) this.SOPNNotes.Id = "New";
    this.countiesService
      .updateSOPInfo(this.SOPNNotes, this.selectedState)
      .subscribe(
        (data) => {
          this.SOPNNotes = data ? data : {};
          this.userMessaging.setSuccessMsg("Updated successfully!");
        },
        (err) => {
          this.sopError = "Failed to update!";
        }
      );
  }

  getSOPInfo() {
    this.ordersService
      .getCountyInfoFromGsheet(this.selectedState)
      .subscribe((data) => {
        this.SOPNNotes = data ? data : {};
      });
  }

  checkEditFunction(params) {
    if (params && params.data && params.data.Id && params.data.Id == "New")
      return true;
    else return false;
  }

  initializeComponentData(data) {
    this.selectedState = data.selectedState;
  }

  dateFormatter(params: any) {
    if(params && params.value) return moment.utc(params.value).format('MM-DD-YYYY');
    else return null;
  }

}

function extractValues(mappings) {
  return Object.keys(mappings);
}
