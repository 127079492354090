import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


interface ToolTipParams extends ICellRendererParams {
  lineBreak?: boolean;
  toolTipArray?: string[];
  toolTip?: string;
}

@Component({
  selector: 'app-ag-grid-tooltip',
  templateUrl: './ag-grid-tooltip.component.html',
  styleUrls: ['./ag-grid-tooltip.component.scss']
})

export class AgGridTooltipComponent implements ICellRendererAngularComp {

  public params: ToolTipParams;
  public data: any;
  public toolTip: string;

  constructor() { }

  agInit(params: ToolTipParams): void {
    if(params && params.data.Order_Borrower_Findings){
      this.params = params;
      this.toolTip = "Edit findings from order details page." 
    }
    else{
      this.params = params;
      this.toolTip = "Use Shift + Enter to insert new line."
    }
  }

  refresh(params: ToolTipParams): boolean {
    this.params = params;
    return true;
  }
}
