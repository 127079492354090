import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";

@Component({
  selector: "pippin-slide",
  templateUrl: "./slide.component.html",
  styleUrls: ["./slide.component.scss"],
})
export class SlideComponent {
  @Input() CONTROL = new UntypedFormControl();
  @Input() LABELON: string = "Enabled";
  @Input() LABELOFF: string = "Disabled";
  @Input() CHECKED: any;
  @Input() COLOR: ThemePalette = "primary";
  @Input() data: any;
  @Output() eventEmitter = new EventEmitter<any>();
  hide = false;

  constructor() {}

  public validateControl = (errorName: string) =>
    this.CONTROL.hasError(errorName);

  controlitem(event) {
    if (event) {
      this.eventEmitter.emit(this.CONTROL.value);
    }
  }
}
