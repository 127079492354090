import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { AuthService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSTANTS } from '../../app.constants';
import { DragDropModule } from '@angular/cdk/drag-drop';
import * as momentTimeZone from 'moment-timezone';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent  {
  constants = CONSTANTS;
  @ViewChild('replyBtn') focusedElement: ElementRef;
  @ViewChild('msgArea') focusedElement1: any;
  checked: boolean = true;
  orderMsgPopup: boolean = true;
  title = '';
  t1 = '';
  t2 = '';
  t3 = '';
  msgSendType;
  message: any;
  messageText = '';
  isReply: boolean = false;
  cancelorderFlag:boolean = false;
  timer: any = [];
  constructor( public auth: AuthService,
    private router: Router,
    public dialogRef: MatDialogRef<MessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data)
  }

  ngOnInit() {
    this.initDialog();
    if(!this.orderMsgPopup) this.orderMsgPopup = false;
    else this.orderMsgPopup = true;
    if (this.message == undefined) this.isReply = true;
    else this.isReply = false;
    this.timer.push(setTimeout(() => { if (this.focusedElement) this.focusedElement.nativeElement.focus() }, 0));
  }

  initDialog(){
    this.title = this.data.title;
    this.msgSendType = this.data.msgSendType;
    this.t1 = this.data.t1;
    this.t2 = this.data.t2;
    this.t3 = this.data.t3;
    this.message = this.data.message;
    this.cancelorderFlag = this.data.cancelorderFlag;
    this.orderMsgPopup = this.data.orderMsgPopup;
  }

  reply() {
    this.isReply = true;
  }

  isReplyClicked() {
    if (this.isReply) return true;
    else return false;
  }

  send() {
    this.dialogRef.close({ messageText: this.messageText, checked: this.checked })
    this.clearTime();
  }

  isMessageEmpty() {
    return this.messageText.trim().length == 0;

  }

  Close() {
    this.dialogRef.close(false);
    this.clearTime()
  }
  getClick(id) {
    if(this.orderMsgPopup) this.router.navigate(['/orders/' + id + '/view']);
    else this.router.navigate(['/quotes/' + id + '/view'])
    this.clearTime()

  }

  checkTitleMsg() {
    if ((this.msgSendType == CONSTANTS.messageSendStatus.directMessage) || (this.msgSendType == CONSTANTS.messageSendStatus.clientSendMessage)) return false
    else return true;
  }

  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) != CONSTANTS.userRole.searchMaster) return true
    else return false
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  checkIfNotExternalSM() {
    if(Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.externalSM) return false;
    else return true;
  }

  searchMasterMessage(statusMsg) {
    let clientRef = statusMsg.match(this.message.allMsg[0].File_ID)
    var findString = this.message.allMsg[0].File_ID;
    var regularExpString = new RegExp(findString, 'g')
    if (clientRef) return statusMsg.replace(regularExpString, this.message.Order_ID);
    else return statusMsg
  }

  checkOrderLinkAvl() {
    if(this.cancelorderFlag) return true;
    else return false
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }
}