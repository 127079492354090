import { Component, OnInit, ViewChild, ElementRef,AfterViewInit, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CONSTANTS } from '../../../app/app.constants';
import { FileService, OrdersService, ConfigService, AwsS3Service, EmailTemplatesService } from '../../services';
import { Observable } from 'rxjs';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UUID } from 'angular2-uuid';
import { threadId } from 'worker_threads';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-abstractor-message",
  templateUrl: "./abstractor-message.component.html",
  styleUrls: ["./abstractor-message.component.scss"],
})
export class AbstractorMessageComponent{
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('okBtn') focusedElement: ElementRef;
  message: any = {};
  absMessage: string = "";
  absDocuments: any = [];
  newDocs = [];
  constants = CONSTANTS;
  templates: any = [];
  selectedTemplate: any = null;
  tinyOptions: any = {
    auto_focus: false,
    branding: false,
    promotion: false,
    skin: 'oxide',
    menubar: false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"], 
    toolbar: "undo redo | styleselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
    content_style: `
      body { font-size: 14px; font-family: Arial, sans-serif; }
    `,
  };
  constructor(private fileService: FileService, private orderService: OrdersService,
    private config: ConfigService, private s3Service: AwsS3Service, private emailTemplateService: EmailTemplatesService,public dialogRef: MatDialogRef<AbstractorMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private matDialog:MatDialog) {
      this.message=this.data.message;
  }

  ngOnInit() {
    this.initDialog();
    this.absDocuments = Object.assign([], this.message.documents);
    this.fetchUploadProgress();
    setTimeout(() => {
      this.getEmailTemplates();
    }, 0);
  }

  initDialog(){
    this.message = this.data.message;
  }

  ngAfterViewInit() {
    if (document.querySelector("iframe"))
      document.querySelector("iframe").setAttribute("data-gramm", "false");
  }

  confirm() {
    let subStr = /\[Abstractor Name\]/gi;
    let absFeeSubStr = /\[Fee\]/gi;
    this.absMessage = this.absMessage.replace(subStr, this.getDisplayName(this.message.abstractor));
    this.absMessage = this.absMessage.replace(absFeeSubStr, "$" + this.message.abstractor.Abstrator_Cost);

    
    // this.result = {
    //   state: true,
    //   absMessage: this.absMessage,
    //   absDocuments: this.absDocuments
    // }
    this.dialogRef.close({
      state: true,
      absMessage: this.absMessage,
      absDocuments: this.absDocuments
    })
    
  }

  getDisplayName(item) {
    if (item.Abstrator_Full_Name) return item.Abstrator_Full_Name;
    else if (item.User_First_Name) return item.User_First_Name;
    else return "";
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    // this.close();
  }

  buildAddress(orderDetail) {
    var address = "";
    if (orderDetail.Property_Address_1)
      address += orderDetail.Property_Address_1;
    if (orderDetail.Property_City) {
      if (address != "") address += ", ";
      address += orderDetail.Property_City;
    }
    if (orderDetail.Property_State_Abbr) {
      if (address != "") address += ", ";
      address += orderDetail.Property_State_Abbr;
    }
    if (orderDetail.Property_ZipCode) {
      if (address != "") address += " ";
      address += orderDetail.Property_ZipCode;
    }
    return address;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  deleteFile(i) {
    this.absDocuments.splice(i, 1);
  }

  openFileSelector() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
    // this.fileInput.nativeElement.value = null;
  }

  uploadDocument(event) {
    if (this.validateFilenames(event)) {
      var fileUploads = [];
      if (this.checksize(event.target.files)) {
        for (var i = 0; i < event.target.files.length; i++) {
          var docId = UUID.UUID();
          this.newDocs.push({
            docId: docId,
            name: event.target.files[i].name,
            completed: 0,
          });
          fileUploads.push(
            this.uploadToS3AndUpdatePath(docId, event.target.files[i])
          );
        }
        Promise.all(fileUploads)
          .then((files) => {
            this.removeCompletedDocs(files);
            var succesFiles = files.filter((file) => file !== undefined);
            if (succesFiles.length > 0)
              this.absDocuments = this.absDocuments.concat(succesFiles);
          })
          .catch((err) => {
            this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
          });
      } else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    } else {
      this.openErrorPopup(CONSTANTS.apiErrors.validateFile);
    }
  }

  removeCompletedDocs(files) {
    for (var i = 0, len = files.length; i < len; i++) {
      for (var j = 0, len2 = this.newDocs.length; j < len2; j++) {
        if (files[i] && files[i].docId === this.newDocs[j].docId) {
          this.newDocs.splice(j, 1);
          len2 = this.newDocs.length;
        }
      }
    }
  }

  validateFilenames(event) {
    var flag = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      var filename = event.target.files[i].name;
      if (filename.indexOf(",") > -1) flag++;
    }
    if (flag == 0) return true;
    else return false;
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(
      files,
      (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024
    );
    if (largeFiles.length > 0) return false;
    else return true;
  }

  isMessageEmpty() {
    var span = document.createElement("span");
    span.innerHTML = this.absMessage;
    var x = span.textContent || span.innerText;
    if (x.trim().length == 0) return true;
    else return false;
  }

  fetchUploadProgress() {
    this.s3Service.progress.subscribe((res) => {
      var matchedIndex = this.newDocs.findIndex((doc) => doc.name == res.name);
      if (this.newDocs[matchedIndex]) {
        this.newDocs[matchedIndex].completed =
          Math.round((res.progress.loaded / res.progress.total) * 100 * 100) /
          100;
        this.newDocs[matchedIndex].completed = Math.round(
          this.newDocs[matchedIndex].completed
        );
      }
    });
  }

  extractFilename(filePath) {
    var parts = filePath.split("/");
    var filename = parts[parts.length - 1];
    return filename;
  }

  uploadToS3AndUpdatePath(docId, file) {
    return this.s3Service
      .onManagedUpload(file, this.message.orderDetails.Order_ID)
      .then((data) => {
        return this.s3Service
          .updateAbsDocumentPath(
            this.message.orderDetails.Order_ID,
            this.message.orderDetails.Property_ID,
            file,
            data.Key,
            this.extractFilename(data.Key)
          )
          .then((doc) => {
            doc["docId"] = docId;
            doc["completed"] = 100;
            return doc;
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
      });
  }

  getEmailTemplates() {
    this.emailTemplateService
      .getTemplatesForProducts()
      .subscribe((templates) => {
        this.templates = templates;
        this.selectedTemplate = this.filterMappedOrDefaultTemplate();
        this.absMessage = this.draftEmailBody(this.selectedTemplate);
      });
  }

  filterMappedOrDefaultTemplate() {
    let matched;
    this.templates.forEach((template) => {
      if (template.Product_ID == this.message.orderDetails.Product_ID)
        matched = template;
    });
    if (!matched) {
      let defaultIndex = this.templates.findIndex(
        (template) => template.Template_Is_Default
      );
      if (defaultIndex > -1) matched = this.templates[defaultIndex];
    }
    return matched;
  }

  selectionChange(event) {
    if (event) this.absMessage = this.draftEmailBody(event.value);
  }

  draftEmailBody(selectedTemplate) {
    let stateCounty, sellerNames, borrowerNames;
    let draft = selectedTemplate["Template_Content"];
    let sellerNamesPrimary = this.clubSellerNames();
    if (
      this.message &&
      this.message.orderDetails &&
      this.message.orderDetails.Order_ID
    ) {
      draft = draft.replace(
        /&lt;orderid&gt;/g,
        this.message.orderDetails.Order_ID
      );
    }
    if (
      this.message &&
      this.message.orderDetails &&
      (this.message.orderDetails.Property_County ||
        this.message.orderDetails.Property_State_Abbr)
    ) {
      if (
        this.message.orderDetails.Property_County &&
        this.message.orderDetails.Property_State_Abbr
      )
        stateCounty =
          this.message.orderDetails.Property_County +
          " " +
          this.message.orderDetails.Property_State_Abbr;
      if (
        !this.message.orderDetails.Property_County &&
        this.message.orderDetails.Property_State_Abbr
      )
        stateCounty = this.message.orderDetails.Property_State_Abbr;
      if (
        this.message.orderDetails.Property_County &&
        !this.message.orderDetails.Property_State_Abbr
      )
        stateCounty = this.message.orderDetails.Property_County;
      draft = draft.replace(/&lt;statecounty&gt;/g, stateCounty);
    } else {
      draft = draft.replace(/&lt;statecounty&gt;/g, " - ");
    }
    // if (
    //   this.message &&
    //   this.message.orderDetails &&
    //   this.message.orderDetails.Property_First_Name
    // ) {
    //   draft = draft.replace(
    //     /&lt;propertyownername&gt;/g,
    //     this.message.orderDetails.Property_First_Name
    //   );
    // } else {
    //   draft = draft.replace(/&lt;propertyownername&gt;/g, " - ");
    // }
    if (this.message && this.message.orderDetails) {
      draft = draft.replace(
        /&lt;fulladdress&gt;/g,
        this.buildAddress(this.message.orderDetails)
      );
    }
    if (
      this.message &&
      this.message.orderDetails &&
      (sellerNamesPrimary ||
        this.message.orderDetails.Order_Co_Seller)
    ) {
      if (
        sellerNamesPrimary &&
        this.message.orderDetails.Order_Co_Seller
      )
        sellerNames =
        sellerNamesPrimary +
          " - " +
          this.message.orderDetails.Order_Co_Seller;
      if (
        sellerNamesPrimary &&
        !this.message.orderDetails.Order_Co_Seller
      )
        sellerNames = sellerNamesPrimary;
      if (
        !sellerNamesPrimary &&
        this.message.orderDetails.Order_Co_Seller
      )
        sellerNames = this.message.orderDetails.Order_Co_Seller;
      draft = draft.replace(/&lt;sellersname&gt;/g, sellerNames);
    } else {
      draft = draft.replace(/&lt;sellersname&gt;/g, " - ");
    }
    if (
      this.message &&
      this.message.orderDetails &&
      (this.message.orderDetails.Order_Buyer ||
        this.message.orderDetails.Order_Co_Buyer)
    ) {
      if (
        this.message.orderDetails.Order_Buyer &&
        this.message.orderDetails.Order_Co_Buyer
      )
        borrowerNames =
          this.message.orderDetails.Order_Buyer +
          " - " +
          this.message.orderDetails.Order_Co_Buyer;
      if (
        this.message.orderDetails.Order_Buyer &&
        !this.message.orderDetails.Order_Co_Buyer
      )
        borrowerNames = this.message.orderDetails.Order_Buyer;
      if (
        !this.message.orderDetails.Order_Buyer &&
        this.message.orderDetails.Order_Co_Buyer
      )
        borrowerNames = this.message.orderDetails.Order_Co_Buyer;
      draft = draft.replace(/&lt;borrowersname&gt;/g, borrowerNames);
    } else {
      draft = draft.replace(/&lt;borrowersname&gt;/g, " - ");
    }
    if (
      this.message &&
      this.message.orderDetails &&
      this.message.orderDetails.Product_ID
    ) {
      let Product_Description = this.message.orderDetails.Product_Description;
      if (Product_Description.includes("Plus")) {
        var lastIndex = Product_Description.lastIndexOf(" ");
        Product_Description = Product_Description.substring(0, lastIndex);
      }
      draft = draft.replace(/&lt;product&gt;/g, Product_Description);
    }
    if (
      this.message &&
      this.message.orderDetails &&
      this.message.orderDetails.Order_Parcel
    ) 
      draft = draft.replace(/&lt;parcelIds&gt;/g, this.message.orderDetails.Order_Parcel);
    else 
      draft = draft.replace(/&lt;parcelIds&gt;/g, ' - ');

    if (this.message?.clientInfo) {
        const clientInfoSnippet = `<div>${this.message.clientInfo}</div>`;
        draft = draft.replace(/&lt;client_info&gt;/g, clientInfoSnippet);
    } else {
        draft = draft.replace(/&lt;client_info&gt;/g, " - ");
    }

    draft = this.emailTemplateService.sanitizeTextData(draft);
    
    return draft;
  }

  clubSellerNames(){
    let sellerNames = '';
    if (
      this.message.orderDetails.Order_Seller &&
      this.message.orderDetails.Property_First_Name
    )
    sellerNames =
        this.message.orderDetails.Order_Seller +
        ", " +
        this.message.orderDetails.Property_First_Name;
    if (
      this.message.orderDetails.Order_Seller &&
      !this.message.orderDetails.Property_First_Name
    )
    sellerNames = this.message.orderDetails.Order_Seller;
    if (
      !this.message.orderDetails.Order_Seller &&
      this.message.orderDetails.Property_First_Name
    )
    sellerNames = this.message.orderDetails.Property_First_Name;
    return sellerNames;
  }
}
