// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-body {
  overflow-y: auto;
  height: 300px;
}

.modal-dialog {
  margin-top: 6rem;
  max-width: 1500px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/abstractor-messages/abstractor-messages.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;AACJ;;AACA;EACI,gBAAA;EACA,iBAAA;AAEJ","sourcesContent":[".dialog-body{\n    overflow-y: auto;\n    height: 300px;\n}\n.modal-dialog {\n    margin-top: 6rem;\n    max-width: 1500px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
