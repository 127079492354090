import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
} from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { DatePipe } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";
import * as momentTimeZone from "moment-timezone";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-login-history",
  templateUrl: "./login-history.component.html",
  styleUrls: ["./login-history.component.scss"],
})
export class LoginHistoryComponent {
  @ViewChild("okBtn") focusedElement: ElementRef;

  message: any = [];
  timer: any = [];

  constructor(
    public dialogRef: MatDialogRef<LoginHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(
      setTimeout(() => {
        this.focusedElement.nativeElement.focus();
      }, 0)
    );
  }

  close(){
    this.dialogRef.close();
  }

  initDialog() {
    this.message = this.data.message;
  }

  getLocationJson(msg) {
    if (msg.Event_IP_Location_JSON)
      return JSON.parse(msg.Event_IP_Location_JSON);
    else return null;
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, "America/New_York").isDST()) return "EDT";
    else return "EST";
  }

  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }
}
