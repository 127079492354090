import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService, EmailValidator, EqualValidator, UsersService, OrganizationService, ConfigService, SiteSettingService, UsermessageService, GroupsService } from '../../services'
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../../dialog/success/success.component';
import { CONSTANTS } from '../../app.constants'
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  userForm: any = {};
  mailSettingForm :any = {};
  userModel: any = {}
  userId: string = '';
  error: any = {}
  errorMsg: any;
  constants = CONSTANTS;
  orgList = [];
  siteSetting: any = {};
  mailSiteSetting:any = {};
  timer: any = [];
  eventSubscribers: any = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private frmBuilder: UntypedFormBuilder,
    public auth: AuthService,
    private matDialog:MatDialog,
    private userService: UsersService,
    private orgService: OrganizationService,
    private config: ConfigService,
    private siteSettingService: SiteSettingService,
    private userMsg: UsermessageService,
    private groupService: GroupsService
  ) {
    this.userForm = frmBuilder.group({
      'Admin_First_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'Admin_Last_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      "Is_Super": [null]
    })
    this.userForm.valueChanges.subscribe((data) => {
      this.error = '';
    });
    this.mailSettingForm = frmBuilder.group({
      'Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])]
    })
    this.mailSettingForm.valueChanges.subscribe((data) => { this.error = '';});
  }

  ngOnInit() {
    this.getUserInfo();
    this.getOrgList();
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();
    }, 0));
  }

   ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  updateUser() {
    this.userService.updateUser(this.userModel)
      .subscribe((data) => {
        let msg = "Updated succesfully."
        this.userMsg.setSuccessMsg(msg);
        this.auth.updateCookie(data);
        if(data.User_Role == CONSTANTS.userRole.searchMaster) this.router.navigate(['/orders/inprocess'])
        else this.router.navigate(['/orders/new']);
        // this.openModal('Success', 'Updated succesfully.').subscribe((res) => {
        //   this.auth.updateCookie(data);
        //   if(data.User_Role == CONSTANTS.userRole.searchMaster) this.router.navigate(['/orders/inprocess'])
        //   else this.router.navigate(['/orders/new']);
        // });                       
      }, (err) => {
        this.errorMsg = err
      })
  }

  loadData(data) {
    this.userModel.Admin_First_Name = data.User_First_Name;
    this.userModel.User_Role = data.User_Role;
    this.userModel.User_Type = data.User_Type;
    if (this.userModel.Admin_First_Name) {
      document.getElementById('Admin_First_Name').parentElement.classList.remove('is-empty')
    }
    this.userModel.Admin_Last_Name = data.User_Last_Name;
    if (this.userModel.Admin_Last_Name) {
      document.getElementById('Admin_Last_Name').parentElement.classList.remove('is-empty')
    }
    this.userModel.Admin_Email_Address = data.Email_Address;
    if (this.userModel.Admin_Email_Address) {
      document.getElementById('Admin_Email_Address').parentElement.classList.remove('is-empty')
    }
    this.userModel.Admin_ID = data.User_ID

    if (data.User_Role == CONSTANTS.userRole.superAdmin || data.User_Role == CONSTANTS.userRole.compAdmin) {
      this.userModel.Is_Super = true;
      this.getMailSiteSetting();
      this.getPresetMailAddress();
    }
    else if (data.User_Role == CONSTANTS.userRole.admin || data.User_Role == CONSTANTS.userRole.searchMaster) this.userModel.Is_Super = false;
    if(data.User_Role == CONSTANTS.userRole.superAdmin || data.User_Role == CONSTANTS.userRole.compAdmin || data.User_Role == CONSTANTS.userRole.admin) this.getSubscriberlist();
  }

  getUserInfo() {
    this.userService.getUserInfo(this.route.snapshot.params['userId'])
      .subscribe((data) => {
        if (data) {
          this.loadData(data)
        }
      }, (err) => {
        console.log(err);
      })
  }

  openModal(title, msg) {
    return this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  getOrgList() {
    this.orgService.getAssociatedOrgs(this.auth.getUserId())
      .subscribe((result) => {
        if (result) {
          this.orgList = result;
        }
      }, (err) => {
        this.errorMsg = err;
      })
  }

  isSuperAdmin() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) return true;
    else false;
  }

  isOrgList() {
    if (Number(this.orgList.length)) return true;
    else return false;
  }

  getMailSiteSetting() {
    this.siteSettingService.getSetting(this.constants.siteSettingName.ALLOW_REGISTERED_MAIL_ORDER)
      .subscribe((result) => {
        if (result) {
          this.siteSetting = result;
          this.siteSetting.Setting_Value = (this.siteSetting.Setting_Value === '1');
        }
      }, (err) => {
        this.errorMsg = err;
      })
  }

  userAction(setting) {
    this.siteSettingService.editSiteSetting(setting)
      .subscribe((result) => {
      }, (err) => {
        setting.Setting_Value = !setting.Setting_Value;
        this.errorMsg = err;
      })
  }

  getPresetMailAddress() {
    this.siteSettingService.getSetting(this.constants.siteSettingName.PRESET_STATUS_EMAIL_ADDRESS)
      .subscribe((result) => {
        if (result) this.mailSiteSetting= result;
      }, (err) => {
        this.errorMsg = err;
      })
  }

  updateEmail() {
    this.siteSettingService.editMailSiteSetting(this.mailSiteSetting)
      .subscribe((result) => {
        this.getPresetMailAddress();
        this.openModal('Email address', 'Updated successfully.')
      }, (err) => {
        this.errorMsg = err;
      })
  }

  onCancel(){
    if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return ['/orders/inprocess'];
    else return ['/orders/new'];
  }
  
  getSubscriberlist(){
    this.groupService.getAllEvents()
    .subscribe((events) => {
      if(events && events.length > 0){
        this.groupService.getAllGroupsforEvents(events)
        .subscribe((sub) => {
          events.forEach((event, index) => {
            event.Groups = sub[index]
            this.eventSubscribers.push(event);
          })
        })
      }
    })
  }
}
