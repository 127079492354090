import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'einval',
  pure: false
})
export class EinFieldPipe implements PipeTransform {

  transform(ein) {
  if (!ein) { return ''; }
    var value = ein.toString().trim().replace(/^\+/, '');
    value = value.replace(/[^\d]/g, '');
    var cleaned = ('' + value).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{2})(\d{7})$/)
    if (match) {
      return  match[1] + '-' + match[2];
    }
    var city, number;
    switch (value.length) {
      case 9:     
        city = value.slice(0, 2);
        number = value.slice(2);
        break;

      default:
        return ein;
    }

    return (city + '-' + number);
  }
} 