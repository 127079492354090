import { Component, OnInit } from '@angular/core';
import { ConfigService, OrdersService, PreloaderService, TagsService, PagerService, StateService, ScrollService, AuthService, UsermessageService } from '../../services';
import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { DialogService } from 'ng2-bootstrap-modal';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { Router, NavigationExtras } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as momentTimeZone from 'moment-timezone';
import { CONSTANTS } from '../../app.constants';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  searchString: string;
  searchInProgress: boolean = false;
  pageNum: number;
  listingType = 'active'
  onlyActiveTags: boolean = true;
  tags = [];
  totalCount: number;
  pager: any = {};
  tagsToDisplay: any;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  scrollEnable: boolean = true;
  searchQuery = new Subject<string>();
  sortField:string;
  orderBy: string;
  timer: any = [];
  constructor(
    private config: ConfigService,
    private tagService: TagsService,
    private preloaderService: PreloaderService,
    private matDialog:MatDialog,
    private router: Router,
    private pagerService: PagerService,
    private stateService: StateService,
    private scrollService: ScrollService,
    private auth: AuthService,
    private userMsg:UsermessageService
  ) {
    if (this.pageNum == undefined)
      this.pageNum = 1;
    this.searchString = '';
    this.orderBy = 'DESC';
    this.sortField = 'Tag_Count';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    if (this.stateService.getTags()) {
      this.initializeComponentData(this.stateService.getTags());
    } else this.getTags();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(20), 0));
    // For Different Menu Item(Success Popup) It Wont Show The Success Message.
    // if(this.userMsg.getSuccessMsgData()) {
    //   let msg =this.userMsg.getSuccessMsgData();
    //   this.getTagSuccessMsg(msg)
    // }else this.getTagSuccessMsg(null)
  }

   ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getTags() {
    if (this.pageNum == 1) {
      this.stateService.setTags(this);
      this.tagService.getAllActiveTag(this.pageNum, this.getSearchString(), this.listingType, this.orderBy, this.sortField)
        .subscribe((result1) => {
          if (result1.count > this.config.getNumRecordsPerPage()) {
            this.pageNum++;
            this.tagService.getAllActiveTag(this.pageNum, this.getSearchString(), this.listingType, this.orderBy, this.sortField)
              .subscribe((result2) => {
                var result = {
                  rows: result1.rows.concat(result2.rows),
                  count: result1.count
                }
                this.tags = result.rows;
                this.totalCount = result.count;
                this.getTagsData(this.totalCount)
                this.getScrollPosData()
              })
          }
          else {
            this.tags = result1.rows;
            this.totalCount = result1.count;
            this.getTagsData(this.totalCount);
            this.getScrollPosData()
          }
        })
    } else this.getRetainData()
  }

  getRetainData() {
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.tagService.getAllActiveTag(i, this.getSearchString(), this.listingType, this.orderBy, this.sortField))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedTags) => {
        if (retainedTags) {
          var totalOrders = [];
          for (var i = 0; i < retainedTags.length; i++) {
            this.stateService.setTags(this)
            totalOrders = totalOrders.concat(retainedTags[i]['rows']);
          }
          this.tags = totalOrders;
          this.totalCount = retainedTags[0]['count'];
          this.getTagsData(this.totalCount);
          this.getScrollPosData()
        }
      })
  }


  getSearchString() {
    var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }

  applyFilter(event) {
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosTags(x);
    if (event.checked) {
      this.listingType = "active";
      this.pageNum = 1;
      this.getTags();
    }
    else {
      this.listingType = "all";
      this.pageNum = 1;
      this.getTags();
    }
  }

  goToCurrentTag(tagId) {
    if(this.checkAdminType()){
      var getPos = document.documentElement.scrollTop;
      this.scrollService.setScrollPosTags(getPos);
      this.router.navigate(['/tags/edit/' + tagId])
    }
  }


  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.tagService.getAllActiveTag(this.pageNum, this.getSearchString(), this.listingType, this.orderBy, this.sortField)
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag()
            this.totalCount = result.count;
            if (this.totalCount > this.tags.length) {
              this.tags = this.tags.concat(result.rows);
              this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  getTagsData(result) {
    this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setDisplayOrders();
    this.pager = this.pagerService.getPager(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  setDisplayOrders() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    if (this.totalCount != undefined) this.tagsToDisplay = this.tags.slice(start, this.totalCount);
    if (this.tagsToDisplay.length > this.config.getNumRecordsPerPage()) this.tagsToDisplay = this.tagsToDisplay.slice(0, this.config.getNumRecordsPerPage());
  }

  searchField(search){
    this.scrollService.setScrollPosTags(0);
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search=search.trim();
    this.searchString = search;
    if(!format.test(this.searchString) && this.searchString==" "){
      this.searchString='';
      this.getTags();
    }else this.findTags();
  }

  findTags() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getTags();
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.totalCount = data.totalCount;
    this.listingType = data.listingType;
    this.tagsToDisplay = data.ordersToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.onlyActiveTags = data.onlyActiveTags;
    this.sortField = data.sortField;
    this.orderBy = data.orderBy;
    this.getTags();
  }

  getScrollPosData() {
    if (this.scrollService.getScrollPosTags()) {
      var x = this.scrollService.getScrollPosTags()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }))
    }
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  userAction(event, tagId) {
    if (event.checked) {
      this.tagService.chngStatusActiveTag({ Tag_ID: tagId, Admin_User_ID: this.auth.getUserId() })
        .subscribe((res) => {
            let msg= "Tag activated successfully.";
            this.getTagSuccessMsg(msg);
        })
    } else {
      this.tagService.chngStatusSuspendTag({ Tag_ID: tagId, Admin_User_ID: this.auth.getUserId() })
        .subscribe((res) => {
          let msg='';
          if(res && res.msg) msg ="Tag suspended successfully. "+ res.msg;
          else msg ="Tag suspended successfully."
          this.getTagSuccessMsg(msg);
        })
    }
  }
  
  getTagSuccessMsg(msg){
    this.userMsg.setClearSuccessTimeout();
    this.userMsg.setSuccessMsg(msg)
  }

  showOrderSearch(event,tagsDetails){
    event.stopPropagation();
    let navigationExtras: NavigationExtras = {
      queryParams: {
          "tagID": JSON.stringify(tagsDetails.Tag_ID)
      },
    };
    this.router.navigate(['/orders/search'],navigationExtras);
  }

  setSort(field: string) {
    this.pageNum = 1;
    console.log("setSort", field);
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getTags();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.getTags();
  }


  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else if(this.auth.getUserId() == environment.superAdminId) return true;
    else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
    else return false;
  }
}

