import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { RptBuilderService } from "../rpt-builder.service";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CONSTANTS } from "app/app.constants";
import { MatInput } from "@angular/material/input";

@Component({
  selector: 'app-rb-additional-data',
  templateUrl: './rb-additional-data.component.html',
  styleUrls: ['./rb-additional-data.component.scss']
})
export class RbAdditionalDataComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  @ViewChild('firstInput') firstInput : MatInput;
  sectionObject: any = {};  
  reportDetails: any = {};
  orderDetails: any = {};
  dataForm: UntypedFormGroup;
  sectionName = "Additional Document";
  itemData = {
    Applies: true,
    Id: null,
    Sp_Id: 0,
  };
  constants= CONSTANTS;
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private builder: RptBuilderService,
    private cdr: ChangeDetectorRef
  ) {
    this.dataForm = frmBuilder.group({      
      Other_Information: [null],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.syncReportDetails(this.dataForm));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {    
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComponent(data));
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initOrderdata(data));
  }

  initComponent(data) {
    this.sectionObject = data;
    if (data && data.curData && data.curData.Id !== undefined) {
      this.reportDetails = data.curData;
      this.itemData.Sp_Id = this.reportDetails.Id;
      this.itemData.Id = null;
      if (
        this.orderDetails &&
        this.orderDetails.Order_ID !== undefined &&
        this.orderDetails.Order_ID !== ""
      )
        this.builder.manageError(
          `Additional Data_${this.orderDetails.Order_ID}_0`,
          this.dataForm.status !== "VALID",
          this.dataForm.controls
        );
      this.cdr.detectChanges();
    }
  }

  initOrderdata(data) {
    if (data && data.Order_ID !== undefined) this.orderDetails = data;
  }

  syncReportDetails(event) {
    if (this.orderDetails && this.orderDetails.Order_ID !== undefined)
      this.builder.manageError(
        `Additional Data_${this.orderDetails.Order_ID}_0`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine) {
      this.sectionObject.curData = this.builder.trimInput(event, this.reportDetails);
      this.sectionObject.Sp_Id = this.reportDetails.Id;
      this.sectionObject.Derived_From = this.reportDetails.Derived_From;
      this.builder.saveBasicData(this.sectionObject).pipe(take(1)).subscribe();
    }
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "ADD_DATA",
      this.reportDetails.Id,
      fieldName
    );
  }

  focusFirsttItem(){ 
    if(this.firstInput) this.firstInput['nativeElement'].click();
  }

}
