// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-bordered,
.table-bordered > tbody > tr > td {
  border: none;
  position: relative;
}

.table > tbody > tr:nth-of-type(even) {
  background-color: transparent;
}

.table > tbody > tr:nth-of-type(even):hover {
  background-color: rgb(66, 139, 202);
}

tbody tr:hover {
  background-color: transparent !important;
  cursor: initial !important;
  color: black !important;
}

.subtitle {
  font-size: 14px;
  color: #666;
  position: relative;
  top: -16px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  /* Additional styling properties */
}`, "",{"version":3,"sources":["webpack://./src/app/orders/order-accept/order-accept.component.scss"],"names":[],"mappings":"AAAA;;EAEI,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,6BAAA;AACJ;;AAEA;EACI,mCAAA;AACJ;;AAEA;EACI,wCAAA;EACA,0BAAA;EACA,uBAAA;AACJ;;AAGA;EACI,eAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;EACA,iDAAA;EACA,kCAAA;AAAJ","sourcesContent":[".table-bordered ,\n.table-bordered>tbody>tr>td {\n    border: none;\n    position: relative;\n}\n\n.table>tbody>tr:nth-of-type(even) {\n    background-color: transparent;\n}\n\n.table>tbody>tr:nth-of-type(even):hover {\n    background-color: rgb(66, 139, 202);\n}\n\ntbody tr:hover {\n    background-color:transparent !important;\n    cursor: initial !important;\n    color: black !important;\n}\n\n\n.subtitle {\n    font-size: 14px;\n    color: #666;\n    position: relative;\n    top:-16px;\n    font-family: Roboto, \"Helvetica Neue\", sans-serif;\n    /* Additional styling properties */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
