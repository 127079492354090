import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { ClientsService } from '../../services';
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule, FormsModule, FormControl, Validators } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import {MatPasswordStrengthComponent} from '@angular-material-extensions/password-strength';

@Component({
  selector: 'app-client-password-reset',
  templateUrl: './client-password-reset.component.html',
  styleUrls: ['./client-password-reset.component.scss']
})
export class ClientPasswordResetComponent{
  // @ViewChild('passwordComponent') passwordComponent: MatPasswordStrengthComponent;
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef
  passResetFrm: UntypedFormGroup;
  error: string = '';
  errorMsg: any = {};
  errorType: string = '';
  passResetModel: any = {};
  message: any = '';
  timer: any = [];

  constructor(
    public dialogRef: MatDialogRef<ClientPasswordResetComponent>,
    private matDialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private clientService: ClientsService
  ) {
    this.passResetFrm = fb.group({
      'password': [null, Validators.compose([
        Validators.required, Validators.minLength(8),
        Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/),
        Validators.maxLength(255),
      ])],
      'confirmpassword': [null, Validators.required],
    })
    this.passResetFrm.valueChanges.subscribe(data => this.error = '');
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => { this.myFirstFocus.nativeElement.focus() }, 0))
  }

  initDialog(){
    this.message = this.data.message;
  }

  resetPassword() {
    this.clientService.resetClientPasswordOnFly({User_ID:this.message,password:this.passResetModel.password})
    .subscribe(()=>{
       this.dialogRef.close(true);
      this.clearTime();
    },(err)=>{      
      this.errorType = 'general';
      this.errorMsg.msg = 'Update failed.';
    })
  }

  clearError() {
    this.errorMsg.msg = '';
  }

  Close() {
     this.dialogRef.close(false);
    this.clearTime()
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
}
