// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.starSize {
  font-size: 20px;
  color: #f0ad4e;
}

.fontSizeSmall {
  font-size: 13px;
}

.fontSizeLarge {
  font-size: 16px;
}

.star-style {
  font-size: 16px;
  color: #f0ad4e;
}`, "",{"version":3,"sources":["webpack://./src/app/abstractor/star-rating/star-rating.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;AACJ;;AACA;EACI,eAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ;;AAAA;EACI,eAAA;EACA,cAAA;AAGJ","sourcesContent":[".starSize{\n    font-size: 20px;\n    color: #f0ad4e;\n}\n.fontSizeSmall{\n    font-size: 13px;\n}\n.fontSizeLarge{\n    font-size: 16px;\n}\n\n.star-style{\n    font-size: 16px;\n    color: #f0ad4e;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
