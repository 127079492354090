import { Component, OnInit, ViewChild, ElementRef,AfterViewInit, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CONSTANTS } from '../../../app/app.constants';
import { FileService, OrdersService, AbstractorService, ConfigService, AwsS3Service, UsermessageService } from '../../services';
import { Observable } from 'rxjs';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { SuccessComponent } from '../../dialog/success/success.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UUID } from 'angular2-uuid';
import * as momentTimeZone from 'moment-timezone';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-old-abstractor-mail",
  templateUrl: "./old-abstractor-mail.component.html",
  styleUrls: ["./old-abstractor-mail.component.scss"],
})
export class OldAbstractorMailComponent  {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('okBtn') focusedElement: ElementRef;

  message: any = {};
  title: any;
  absOldDocuments: any = [];
  absMessageText: string = "";
  date: any;
  newDocs = [];
  constants = CONSTANTS;
  tinyOptions: any = {
    auto_focus: false,
    branding:false,
    promotion :false,
    skin:'oxide',
    menubar:false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"],
    toolbar:
      "undo redo | styleselect | forecolor backcolor |  bold italic |  alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
  };
  constructor( public datepipe: DatePipe, private fileService: FileService,
    private orderService: OrdersService,
    private abstractorService: AbstractorService,
    private s3Service: AwsS3Service,
    private userMsg: UsermessageService,
    public dialogRef: MatDialogRef<OldAbstractorMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog:MatDialog,
    private config: ConfigService) {
    
  }

  ngOnInit() {
    this.initDialog();
    this.absOldDocuments = this.message.absDocuments;
    this.date = new Date(
      this.message.absMessage.Message_Creation_Date
    ).toLocaleString("en-US", { timeZone: "America/New_York" });
    this.constructMessage(this.message.absMessage);
    this.fetchUploadProgress();
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  ngAfterViewInit() {
    document.querySelector("iframe").setAttribute("data-gramm", "false");
  }

  constructMessage(msgText) {
    this.absMessageText =
      "<p>&nbsp;</p>" +
      "<p><b>On " +
      this.date +
      ", Pippin Title Services wrote:<b></p>" +
      "<p>" +
      msgText.Message_Text +
      "</p>";
  }

  confirm() {
    this.abstractorService
      .sendMailToAbstarctor({
        Order_ID: this.message.orderDetails.Order_ID,
        Abstrator_ID: this.message.orderDetails.Abstrator_ID,
        Message_Text: this.absMessageText,
        Abstractor_Documents: this.absOldDocuments,
      })
      
      this.dialogRef.close({
        state: true,
      })
  }

  Close() {
    this.dialogRef.close(false);
  }

  deleteFile(i) {
    this.absOldDocuments.splice(i, 1);
  }

  openFileSelector() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  uploadDocument(event) {
    if (this.validateFilenames(event)) {
      var fileUploads = [];
      if (this.checksize(event.target.files)) {
        for (var i = 0; i < event.target.files.length; i++) {
          var docId = UUID.UUID();
          this.newDocs.push({
            docId: docId,
            name: event.target.files[i].name,
            completed: 0,
          });
          fileUploads.push(
            this.uploadToS3AndUpdatePath(docId, event.target.files[i])
          );
        }
        Promise.all(fileUploads)
          .then((files) => {
            this.removeCompletedDocs(files);
            var succesFiles = files.filter((file) => file !== undefined);
            if (succesFiles.length > 0)
              this.absOldDocuments = this.absOldDocuments.concat(succesFiles);
          })
          .catch((err) => {
            this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
          });
      } else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    } else {
      this.openErrorPopup(CONSTANTS.apiErrors.validateFile);
    }
  }

  removeCompletedDocs(files) {
    for (var i = 0, len = files.length; i < len; i++) {
      for (var j = 0, len2 = this.newDocs.length; j < len2; j++) {
        if (files[i] && files[i].docId === this.newDocs[j].docId) {
          this.newDocs.splice(j, 1);
          len2 = this.newDocs.length;
        }
      }
    }
  }

  validateFilenames(event) {
    var flag = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      var filename = event.target.files[i].name;
      if (filename.indexOf(",") > -1) flag++;
    }
    if (flag == 0) return true;
    else return false;
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(
      files,
      (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024
    );
    if (largeFiles.length > 0) return false;
    else return true;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => { });
  }

  openSuccessModal(title, msg) {
    this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => { });
  }

  isMessageEmpty() {
    var span = document.createElement("span");
    span.innerHTML = this.absMessageText;
    var x = span.textContent || span.innerText;
    if (x.trim().length == 0) return true;
    else return false;
  }

  fetchUploadProgress() {
    this.s3Service.progress.subscribe((res) => {
      var matchedIndex = this.newDocs.findIndex((doc) => doc.name == res.name);
      if (this.newDocs[matchedIndex]) {
        this.newDocs[matchedIndex].completed =
          Math.round((res.progress.loaded / res.progress.total) * 100 * 100) /
          100;
        this.newDocs[matchedIndex].completed = Math.round(
          this.newDocs[matchedIndex].completed
        );
      }
    });
  }

  extractFilename(filePath) {
    var parts = filePath.split("/");
    var filename = parts[parts.length - 1];
    return filename;
  }

  uploadToS3AndUpdatePath(docId, file) {
    return this.s3Service
      .onManagedUpload(file, this.message.orderDetails.Order_ID)
      .then((data) => {
        return this.s3Service
          .updateAbsDocumentPath(
            this.message.orderDetails.Order_ID,
            this.message.orderDetails.Property_ID,
            file,
            data.Key,
            this.extractFilename(data.Key)
          )
          .then((doc) => {
            doc["docId"] = docId;
            doc["completed"] = 100;
            return doc;
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
      });
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, "America/New_York").isDST()) return "EDT";
    else return "EST";
  }
}
