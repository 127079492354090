// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dueDateCSS {
  flex: 0 0 35%;
  max-width: 35%;
}

.dueDateTime {
  flex: 0 0 24%;
  max-width: 24%;
}

.dueDateOption {
  flex: 0 0 38%;
  max-width: 38%;
}

.matTimepicker {
  display: flex;
  flex-direction: column;
}
.matTimepicker label {
  display: flex;
  text-overflow: ellipsis;
  margin-left: 15px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  max-lines: 1;
  white-space: nowrap;
}
.matTimepicker input {
  padding-left: 15px;
  border: none;
  border-bottom: 1px solid;
  line-height: 36px;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/app/common/duedate/duedate.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;AACJ;AAAI;EACI,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,eAAA;EACA,yBAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;AAER;AAAI;EACI,kBAAA;EACA,YAAA;EACA,wBAAA;EACA,iBAAA;EACA,aAAA;AAER","sourcesContent":[".dueDateCSS{\n    flex: 0 0 35%;\n    max-width: 35%;\n}\n\n.dueDateTime{\n    flex: 0 0 24%;\n    max-width: 24%;\n}\n\n.dueDateOption {\n    flex: 0 0 38%;\n    max-width: 38%;\n}\n\n.matTimepicker{\n    display: flex;\n    flex-direction: column;\n    label{\n        display: flex;\n        text-overflow: ellipsis;\n        margin-left: 15px;\n        font-size: 11px;\n        color: rgba(0, 0, 0, 0.5);\n        font-weight: bold;\n        max-lines: 1;\n        white-space: nowrap;\n    }\n    input{\n        padding-left: 15px;\n        border: none;\n        border-bottom: 1px solid;\n        line-height: 36px;\n        outline: none;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
