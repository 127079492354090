// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-mdc-icon-button .mat-icon, .mat-icon-button i {
  line-height: 18px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/common/mat-select-search-obj/mat-select-search-obj.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ","sourcesContent":["::ng-deep .mat-mdc-icon-button .mat-icon, .mat-icon-button i {\n    line-height: 18px !important\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
