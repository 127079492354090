import { Directive, forwardRef, Attribute, HostListener } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';


@Directive({
    selector: '[validateEmail][formControlName],[validateEmail][formControl],[validateEmail][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailValidator), multi: true }]
})
export class EmailValidator implements Validator {

    validate(control: AbstractControl): ValidationResult {
        //console.log("in in validator");
        var EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        if (control.value && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
            return { validateEmail: false };
        } else {
            if (control.errors) delete control.errors['validateEmail'];
            if (control.errors && !Object.keys(control.errors).length) control.setErrors(null);
        }

        return null;
    }

}

@Directive({
    selector: '[validatePhone][formControlName],[validatePhone][formControl],[validatePhone][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PhoneValidator), multi: true }]
})
export class PhoneValidator implements Validator {

    validate(control: AbstractControl): ValidationResult {
        var PHONE_REGEXP = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        
        if (control.value && (control.value.length != 12 || !PHONE_REGEXP.test(control.value))) {
            return { validatePhone: false };
        } else {
            if (control.errors) delete control.errors['validatePhone'];
            if (control.errors && !Object.keys(control.errors).length) control.setErrors(null);
        }

        return null;
    }

}

@Directive({
    selector: '[validatePassword][formControlName],[validatePassword][formControl],[validatePassword][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PasswordValidator), multi: true }]
})
export class PasswordValidator implements Validator {

    // Password must be atleast 7 characters long with atleast 1 Lower case, 1 Upper Case and a Numeral
    validate(control: AbstractControl): ValidationResult {
        //var PASSWORD_REGEXP = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])[A-Za-z\d$@$!%*?&]{7,}$/;
        // var PASSWORD_REGEXP = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{7,}$/;
        var PASSWORD_REGEXP = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}/;
        if (control.value && (control.value.length <= 7 || !PASSWORD_REGEXP.test(control.value))) {
            return { validatePassword: false };
        } else {
            if (control.errors) delete control.errors['validatePassword'];
            if (control.errors && !Object.keys(control.errors).length) control.setErrors(null);
        }

        return null;
    }

}

@Directive({
    selector: '[validateClientPassword][formControlName],[validateClientPassword][formControl],[validateClientPassword][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ClientPasswordValidator), multi: true }]
})
export class ClientPasswordValidator implements Validator {

    validate(control: AbstractControl): ValidationResult {
        var PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[0-9])(?=.{6,})/i;
        console.log("status", control, PASSWORD_REGEXP.test(control.value))
        if (control.value && (control.value.length <= 7 || !PASSWORD_REGEXP.test(control.value))) {
            return { validatePassword: false };
        } else {
            if (control.errors) delete control.errors['validateClientPassword'];
            if (control.errors && !Object.keys(control.errors).length) control.setErrors(null);
        }

        return null;
    }

}

@Directive({
    selector: '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true }
    ]
})

export class EqualValidator implements Validator {
    constructor(@Attribute('validateEqual') public validateEqual: string,
        @Attribute('reverse') public reverse: string) {
    }

    private get isReverse() {
        if (!this.reverse) return false;
        return this.reverse === 'true' ? true : false;
    }

    validate(c: AbstractControl): ValidationResult {
        // self value
        let v = c.value;
        // control vlaue
        let e = c.root.get(this.validateEqual);

        // value not equal
        if (e && v !== e.value && !this.isReverse) {
            return {
                validateEqual: false
            }
        }

        // value equal and reverse
        if (e && v === e.value && this.isReverse) {
            delete e.errors['validateEqual'];
            if (!Object.keys(e.errors).length) e.setErrors(null);
        }

        // value not equal and reverse
        if (e && v !== e.value && this.isReverse) {
            e.setErrors({ validateEqual: false });
        }

        return null;
    }
}

@Directive({
    selector: '[validateSSN][formControlName],[validateSSN][formControl],[validateSSN][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => SSNValidator), multi: true }]
})
export class SSNValidator implements Validator {

    validate(control: AbstractControl): ValidationResult {
        var SSN_REGEXP = /^\(?([0-9]{3})\)?[-. ]?([0-9]{2})[-. ]?([0-9]{4})$/;

        if (control.value && (control.value.length != 11 || !SSN_REGEXP.test(control.value))) {
            return { validateSSN: false };
        } else {
            if (control.errors) delete control.errors['validateSSN'];
            if (control.errors && !Object.keys(control.errors).length) control.setErrors(null);
        }

        return null;
    }

}

@Directive({
    selector: '[validateEIN][formControlName],[validateEIN][formControl],[validateEIN][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EINValidator), multi: true }]
})
export class EINValidator implements Validator {

    validate(control: AbstractControl): ValidationResult {
        var EIN_REGEXP = /^\(?([0-9]{2})\)?[-. ]?([0-9]{7})$/;

        if (control.value && (control.value.length != 10 || !EIN_REGEXP.test(control.value))) {
            return { validateEIN: false };
        } else {
            if (control.errors) delete control.errors['validateEIN'];
            if (control.errors && !Object.keys(control.errors).length) control.setErrors(null);
        }

        return null;
    }

}


export function NoWhitespaceValidator(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {

        // messy but you get the idea
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': 'value is only whitespace' }

    };
}

interface ValidationResult {
    [key: string]: boolean;
}


export function RequireMatchValidator(control: AbstractControl) {
    const selection: any = control.value;
    if (typeof selection === 'string') {
        return { incorrect: true };
    }
    return null;
}

export function ObjectValidator(control: AbstractControl) {
    const selection: any = control.value;
    if (!selection || typeof selection != 'object') {
        return { incorrect: true };
    }
    return null;
}

