import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService, ConfigService } from "app/services";

@Injectable({
  providedIn: "root",
})
export class SearchDropdownsService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private config: ConfigService
  ) {}

  getAdminOptions(inputValue = ""): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() + "/v2/orders/orderSearch/searchAdminDropdown";
    return this.http.post(
      url,
      { userId: this.auth.getUserId(), query: inputValue },
      httpOptions
    );
  }

  getAbstractorOptions(inputValue = ""): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() +
      "/v2/orders/orderSearch/searchAbstractorDropdown";
    return this.http.post(
      url,
      { userId: this.auth.getUserId(), query: inputValue },
      httpOptions
    );
  }

  getSearcherOptions(data, inputValue = ""): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() +
      "/v2/orders/orderSearch/searchSearcherDropdown";
    return this.http.post(
      url,
      { ...data, userId: this.auth.getUserId(), query: inputValue },
      httpOptions
    );
  }

  getStateOptions(): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() + "/v2/orders/orderSearch/searchStateDropdown";
    return this.http.post(url, { userId: this.auth.getUserId() }, httpOptions);
  }

  getCountyOptions(states): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() + "/v2/orders/orderSearch/searchCountyDropdown";
    return this.http.post(
      url,
      {states , userId: this.auth.getUserId() },
      httpOptions
    );
  }

  getProductOptions(): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() +
      "/v2/orders/orderSearch/searchProductsDropdown";
    return this.http.post(
      url,
      { userId: this.auth.getUserId() },
      httpOptions
    );
  }
}
