
import {throwError as observableThrowError,  Observable } from 'rxjs';

import { mergeMap, catchError, map} from 'rxjs/operators';
import { Injectable , Inject} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { QuickbooksService } from '../quickbooks/quickbooks.service';
import { DOCUMENT } from '@angular/common';
import { CONSTANTS } from '../../app.constants';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http'

@Injectable()
export class AbstractorService {
  direct: HttpClient;
  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient,
    private quickbooks: QuickbooksService,    
    private handler: HttpBackend,
    @Inject(DOCUMENT) private document: any,
    ) { 
      this.direct = new HttpClient(this.handler);
    }

  createAbstractor(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/new';
    return this.http.post(url, { absUser: model, User_Created_by: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAbstractors(searchStr, page, sortBy, orderBy, filterBy) {
    var data = {
      filterBy:filterBy
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/list';
    url = url + '/page/' + page + '/size/' + this.config.getNumRecordsPerPage();
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllAbstractorsList(){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/getAll/abstractors';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllEnabledDisabledAbs(){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/getAll/abstractors/enabled/disabled';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAbstractorDetails(id) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/userId/' + id;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAbstractorSecondaryEmails(id){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/emails/' + id;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAbstractorSecondaryPhones(id){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/phone/' + id;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }
  getAbstractorDetailsByAbsId(absId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/abstractor/' + absId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  editAbstractor(abstractorUserId, model) {
    if(!this.quickbooks.getSharedSessionData()){
      return this.quickbooks.getUserToken().pipe(
        mergeMap((response: any) => {
          var parsedResponse = response;
          if (!parsedResponse.status) {            
            this.quickbooks.setSharedSessionData({ url: '/abstractor/edit/' + abstractorUserId, data: model, continue: 'update_abstractor' });            
            this.document.location.href = parsedResponse.url;
            return Promise.resolve(0);
          }
          else return this.editAbstractorRequest(model);
        }),
        catchError((error: any) => {
          if(error.error) return observableThrowError(error.error);
          else return observableThrowError(error);
        }),);
    }
    else return this.editAbstractorRequest(model);
  }

  editAbstractorRequest(model) {
    this.quickbooks.resetSharedSessionData();
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/edit';
    return this.http.put(url, { absUser: model, User_Modified_by: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAvailableStateList() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/abspricings/states';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getProductList() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/abspricings/products';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAvailableCountyList(state) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/abspricings/county/' + state;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        console.log("COUNTIES", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  addPriceToAbstractor(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/abstractors/abspricings/add';
    return this.http.post(url, { Abstractor: data, Rating_Modified_By: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  editPriceToAbstractor(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/abstractors/abspricings/edit';
    return this.direct.post(url, { Abstractor: data, Rating_Modified_By: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getPriceList(data,page,filterBy) {
    let objData = {
      Abstractor_User_ID: data,
      filterBy: filterBy
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/abspricings/list';
    url = url + '/page/' + page;
    return this.direct.post(url, objData, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getPriceListWithLoader(data,page,filterBy) {
    let objData = {
      Abstractor_User_ID: data,
      filterBy: filterBy
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/abspricings/list';
    url = url + '/page/' + page;
    return this.http.post(url, objData, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  deletePriceFromList(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'      
    }
    let url = this.config.getBasePath() + '/abstractors/abspricings/inactive';
    return this.http.put(url, { Abstractor: data, Rating_Modified_By: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAbstractorList(orderId, order, filterBy = []) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/abspricings/list/' + orderId;
    return this.http.put(url, { order: order, filterBy: filterBy }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }


  getOrderAbstractorHistory(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/orders/' + orderId + '/history';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  assignOrderToAbs(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/orders/assign';
    return this.http.put(url, { Orders: model, Order_Modified_By: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  requestAbsQuote(model) {
    model.Admin_User_ID = this.auth.getUserId();
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/orders/send/quote';
    return this.http.post(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createAbstractorOnFly(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/new/onfly';
    return this.http.post(url, { absUser: model, User_Created_by: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createAbsOnFlyToSendQuote(model, selectedAbs) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/new/onfly/quote';
    return this.http.post(url, { absUser: model, selectedAbstractors: selectedAbs,  User_Created_by: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  sendMailToAbstarctor(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/abstractors/orders/sendmail';
    return this.http.put(url, { absUser: model, User_Created_by: this.auth.getUserId() } , httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAbstractorPricingById(id) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/abspricings/' + id;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAbstractorRatingById(id) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/absrating/' + id;
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAbstractorMessageList(orderId, absId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/orders/' + orderId + '/absmessages/' + absId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrderAbstractorDocs(ordAbsMsgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/orders/orderabsdocs/' + ordAbsMsgId ;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAbstractorNotes(absUsrId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/notes/' + absUsrId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrderAbstractor(orderId, absUsrId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/orders/'+ orderId + '/absuser/' + absUsrId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  setOrderDelivered(orderabsId, orderId, absRating,absNotes) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/orders/setDelivered';
    return this.http.put(url, { Order_Abstractor_ID: orderabsId, Order_ID : orderId, Order_Rating: absRating, Order_Notes: absNotes, Admin_User_ID:this.auth.getUserId(),Modified_By: this.auth.getUserId() } , httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  removeForBillItem(orderAbs){
    // const deleteProperty = ({[key]: _, ...newObj}, key) => newObj; 
    const deleteProperty = (key: any, { [key]: _, ...newObj }) => newObj;
    var finalBillData = [];
    var bill = orderAbs.Bills ? orderAbs.Bills : orderAbs.Final_Bills;
    bill.forEach((exp) => {
      // var wihoutCurrInp = deleteProperty(exp, 'currInput');
      // var withoutPrimaryItem  = deleteProperty(wihoutCurrInp, 'primaryItem');  
      var wihoutCurrInp = deleteProperty('currInput', exp);
      var withoutPrimaryItem  = deleteProperty('primaryItem', wihoutCurrInp);  
      finalBillData.push(withoutPrimaryItem);
    });
    orderAbs.Final_Bills = finalBillData;
    return orderAbs;
  }

  removeBillingFormValidatorsForBill(bill){
    const deleteProperty = (key: any, { [key]: _, ...newObj }) => newObj;
    var finalData = {};        
    var wihoutfilteredAbstractors = deleteProperty('filteredAbstractors', bill);
    var withoutAbstractorControl  = deleteProperty('abstractorControl', wihoutfilteredAbstractors);
    finalData = deleteProperty('Items', this.removeForItem(withoutAbstractorControl)); 
    return finalData;
  }

  removeForItem(orderAbs){
    const deleteProperty = (key: any, { [key]: _, ...newObj }) => newObj;
    var finalBillData = [];
    var bill = orderAbs.Items ? orderAbs.Items : orderAbs.Final_Items;
    bill.forEach((exp) => {
      var wihoutCurrInp = deleteProperty('currInput', exp);
      var withoutFilterCtrl  = deleteProperty('filterCtrl', wihoutCurrInp);
      var withoutPrimaryItem  = deleteProperty('primaryItem', withoutFilterCtrl);     
      finalBillData.push(deleteProperty('filteredExpenses', withoutPrimaryItem));
    });
    orderAbs.Final_Items = finalBillData;
    return orderAbs;
  }

  manageOrderBill(data, orderId){
    var finalData = this.removeBillingFormValidatorsForBill(data);
    if (!this.quickbooks.getSharedSessionData()) {
      return this.quickbooks.getUserToken().pipe(
        mergeMap((response: any) => {
          var parsedResponse = response;
          if (!parsedResponse.status) {            
            this.quickbooks.setSharedSessionData({ url: '/edit-order-expense/' + orderId, data: finalData, continue: 'manage_bill', queryParams: { tab: 'bills' } });            
            this.document.location.href = parsedResponse.url;
            return Promise.resolve(0);
          }
          else return this.manageOrderBillrequest(finalData, orderId)
        }),
        catchError((error: any) => {
          if(error.error) return observableThrowError(error.error);
          else return observableThrowError(error);
        }),);
    }
    else {
      return this.manageOrderBillrequest(finalData, orderId);
    }
  }


  manageOrderBillrequest(data, orderId){
    this.quickbooks.resetSharedSessionData(); 
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    };
    let url = this.config.getBasePath() + '/abstractors/orders/manage/bill';
    return this.http.put(url, { Order_Bill: data,  Order_ID: orderId, Modified_By: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  deleteOrderBill(QBOBillMappingId, orderId){
    if (!this.quickbooks.getSharedSessionData()) {
      return this.quickbooks.getUserToken().pipe(
        mergeMap((response: any) => {
          var parsedResponse = response;
          if (!parsedResponse.status) {            
            this.quickbooks.setSharedSessionData({ url: '/edit-order-expense/' + orderId, data: QBOBillMappingId, continue: 'delete_bill', queryParams: { tab: 'bills' } });            
            this.document.location.href = parsedResponse.url;
            return Promise.resolve(0);
          }
          else return this.deleteOrderBillRequest(QBOBillMappingId)
        }),
        catchError((error: any) => {
          if(error.error) return observableThrowError(error.error);
          else return observableThrowError(error);
        }),);
    }
    else {
      return this.deleteOrderBillRequest(QBOBillMappingId);
    }
  }


  deleteOrderBillRequest(QBOBillMappingId){
    this.quickbooks.resetSharedSessionData(); 
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    };
    let url = this.config.getBasePath() + '/abstractors/orders/bill/' + QBOBillMappingId;
    return this.http.put(url, { Modified_By : this.auth.getUserId() } ,httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  checForStateAndCounty(state, county) {
    let data = {
      "State":state,
      "County":county
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/checkstatecountycombo';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  cancelAbsAlerts(model) {   
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/orders/cancel/alerts';
    return this.http.put(url, { data : model, Modified_By : this.auth. getUserId() }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  generateAbsAlerts(model) {   
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/abstractors/orders/generate/alerts';
    return this.http.put(url, { data : model, Modified_By : this.auth. getUserId() }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateAbsStatus(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/abstractors/absusers/update/status';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

}
