
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const { Parser } = require('json2csv');

@Injectable()
export class SearchMasterService {

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) { }

  getAllMasters(search, pageNum, sortBy, orderBy, internalSM, externalSM, isManager) {
    var data = {
      search: search,
      page: pageNum,
      size: this.config.getnoOrderSearchandComplete(),
      sort:sortBy,
      order:orderBy,
      Internal_SM:internalSM,
      External_SM:externalSM,
      Is_Manager:isManager
    };
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/searchMaster/get/all';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getMasterDetail(id) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/searchMaster/' + id;    
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  assignSMToSearchManager(modelData) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/searchMaster/assign/searcher/toSearchManager';
    return this.http.post(url, modelData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllSearchmastersForManager(managerId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/searchMaster/manager/' + managerId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  deleteSearcherManagerMappingbyId(searcherId, managerId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/searchMaster/delete/mapping/manager/' + managerId + '/searcher/' + searcherId;
    return this.http.delete(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  editMasterDetail(data) { 
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/searchMaster/update';    
    return this.http.put(url, {User: data, User_Modified_By: this.auth.getUserId() } ,httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  disableMasterStatus(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/searchMaster/status/disable';    
    return this.http.put(url, {User: data, User_Modified_By: this.auth.getUserId() } ,httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  enableMasterStatus(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/searchMaster/status/enable';    
    return this.http.put(url, {User: data, User_Modified_By: this.auth.getUserId() } ,httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createNewMaster(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/searchMaster/new';    
    return this.http.post(url, {User: data, User_Created_By: this.auth.getUserId() } ,httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllActiveMasters() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/searchMaster/get/activemasters';    
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getSearchMasterHistory(searchId){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/searchMaster/loginSearchHistory/'+ searchId;
    return this.http.get(url, httpOptions).pipe(
    map((response: any) => response),
    catchError((error: any) => observableThrowError(error.error)),);
  }

  changeSearchMasterType(userId, oldType, newType){
    let body = {
      UserID: userId,
      oldType:oldType,
      newType:newType,
      adminId: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/searchMaster/change/type'
    return this.http.post(url, body,  httpOptions).pipe(
    map((response: any) => response),
    catchError((error: any) => observableThrowError(error.error)),);
  }

  switchSearchManager(userId, managerType) {
    let body = {
      UserID: userId,
      managerType:managerType,
      adminId: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/searchMaster/switch/search/manager'
    return this.http.post(url, body,  httpOptions).pipe(
    map((response: any) => response),
    catchError((error: any) => observableThrowError(error.error)),);
  }

  downloadSMCSVFile(fields, searchMasterData){
    searchMasterData.forEach((value, index) => {
      value.serialNumber = (index + 1).toString();
    });
  
    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(searchMasterData);
    
    var hiddenElement = document.createElement('a');
    document.body.appendChild(hiddenElement);
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'SMReport.csv';
    hiddenElement.click(); 
    document.body.removeChild(hiddenElement);   
  }
}
