import { Component, OnInit } from '@angular/core';
import { AuthService, ConfigService, PreloaderService, PagerService, SocketService, UsermessageService, QuoterequestService, MessagesService, ClientsService } from 'app/services';
import * as momentTimeZone from 'moment-timezone';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DialogService } from 'ng2-bootstrap-modal';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { CONSTANTS } from '../../app.constants';
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { MessageComponent } from 'app/dialog/message/message.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-grid',
  templateUrl: './notification-grid.component.html',
  styleUrls: ['./notification-grid.component.scss']
})
export class NotificationGridComponent implements OnInit {
  notifications: any;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  searchQuery = new Subject<string>();
  pageNum: number;
  pager: any = {};
  orderBy: string;
  searchString: string;
  sortField: string;
  totalCount: number;
  timer: any = [];
  searchInProgress: boolean = false;
  notificationToDisplay: any;
  constants = CONSTANTS;
  navigationSubscription: any;
  scrollEnable: boolean = true;
  constructor(
    private authService: AuthService,
    private config: ConfigService,
    private preloaderService: PreloaderService,
    private pagerService: PagerService,
    private router: Router,
    private matDialog:MatDialog,
    private socketService:SocketService,
    private userMsg:UsermessageService,
    private quoteService: QuoterequestService,
    private messagesService: MessagesService,
    private clientService: ClientsService
  ) {
    if (this.pageNum == undefined) this.pageNum = 1;
    this.orderBy = 'DESC';
    this.searchString = '';
    this.sortField = 'Notifications_Message_Creation_Date';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    this.showNotifications()
    this.socketService.syncMessage("syncNotifcations").subscribe((result: any) => {
      if((this.authService.isLoggedIn()) && (result && result.id == this.authService.getUserId()))
      if(this.router.url.match('/notifications/list')) this.getBackgroundNotifications();
     })
    this.timer.push(setTimeout(() => this.config.setSideBarNode(99), 0))
  }

  
  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time)
    });
    if (this.navigationSubscription) {  
      this.navigationSubscription.unsubscribe();
   }
  }

  getBackgroundNotifications() {
    if (this.pageNum == 1) {
      this.authService.getBackgroundNotifications(this.pageNum, this.sortField, this.orderBy, this.getSearchString())
      .subscribe((result1) => {
        if (result1.count > this.config.getNumRecordsPerPage()) {
          this.pageNum++;
          this.authService.getBackgroundNotifications(this.pageNum, this.sortField, this.orderBy, this.getSearchString())
            .subscribe((result2) => {
              var result = {
                rows: result1.rows.concat(result2.rows),
                count: result1.count
              }
              
              this.notifications = result.rows;
              this.totalCount = result.count;
              this.getNotificationRecord(this.totalCount)
            })
        }
        else {
          this.notifications = result1.rows;
          this.totalCount = result1.count;
          this.getNotificationRecord(this.totalCount)
        }
      }, () => {
        this.searchInProgress = false;
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
    }else this.getRetainDataOnBg()
  }

  getRetainDataOnBg() {
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.authService.getBackgroundNotifications(i, this.sortField, this.orderBy, this.getSearchString()))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedNotif) => {
        if (retainedNotif) {
          var totalOrders = [];
          for (var i = 0; i < retainedNotif.length; i++) {
            totalOrders = totalOrders.concat(retainedNotif[i]['rows']);
          }
          this.notifications = totalOrders;
          this.totalCount = retainedNotif[0]['count'];
          this.getNotificationRecord(this.totalCount)
        }
      })
  }

  showNotifications() {
    this.authService.seeAllNotifcations(this.pageNum, this.sortField, this.orderBy, this.getSearchString())
      .subscribe((result1) => {
        if (result1.count > this.config.getNumRecordsPerPage()) {
          this.pageNum++;
          this.authService.seeAllNotifcations(this.pageNum, this.sortField, this.orderBy, this.getSearchString())
            .subscribe((result2) => {
              var result = {
                rows: result1.rows.concat(result2.rows),
                count: result1.count
              }
              this.notifications = result.rows;
              this.totalCount = result.count;
              this.getNotificationRecord(this.totalCount)
            })
        }
        else {
          this.notifications = result1.rows;
          this.totalCount = result1.count;
          this.getNotificationRecord(this.totalCount)
        }
      }, () => {
        this.searchInProgress = false;
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData()
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.authService.seeAllNotifcations(this.pageNum, this.sortField, this.orderBy, this.getSearchString())
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag();
            this.totalCount = result.count;
            if (this.totalCount > this.notifications.length) {
              this.notifications = this.notifications.concat(result.rows);
              this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  getSearchString() {
    var format = /[!@#$%^*()_+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }

  searchField(search) {
    var format = /[!@#$%^*()_+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if (!format.test(this.searchString) && this.searchString == " ") {
      this.searchString = '';
      this.showNotifications();
    } else this.findNotifications();
  }

  findNotifications() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.showNotifications();
  }

  getNotificationRecord(result) {
    this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setNotificationData();
    this.pager = this.pagerService.getPager(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  setNotificationData() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.notificationToDisplay = this.notifications.slice(start, this.totalCount);
    if (this.notificationToDisplay.length > this.config.getNumRecordsPerPage()) {
      this.notificationToDisplay = this.notificationToDisplay.slice(0, this.config.getNumRecordsPerPage());
    }
  }

  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.showNotifications();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.showNotifications();
  }

  goToOrderDetails(event, notification) {
    event.stopPropagation();
    var getPos = document.documentElement.scrollTop;
    // this.router.navigate(['/orders/' + orderId + '/view']);
    if(notification.Notifications_Order_ID){
      this.quoteService.getQuoteDetails(notification.Notifications_Order_ID)
      .subscribe((quoteDetails) => {
        if(quoteDetails) this.router.navigate(['/quotes/' + notification.Notifications_Order_ID +'/view'])
        else this.router.navigate(['/orders/' + notification.Notifications_Order_ID +'/view'])   
      }, () => { }) 
    }
    else {
      if(notification.Notifications_Subject.match('New client message')) this.showMsg(notification.Notification_Message_ID)
    } 
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  afterTextCopy() {
    this.userMsg.setSuccessCopyMsg("Text copied successfully");
  }

  showMsg(msgId) {    
    this.messagesService.getMsgInfoData(msgId)
      .subscribe((message) => {
        this.messagesService.updateMsgToRead(message).subscribe((data) => {}, (err) => { console.log(err); })
        this.authService.deleteUserNotificationbyMsgIdAndUserId(msgId).subscribe((data) => {}, (err) => { console.log(err); })
        if(message.Parent_Message_ID){
          this.messagesService.getAllMsgs(message.Parent_Message_ID)
          .subscribe((allMsgs) => {
            message.allMsg = allMsgs;
            this.showAllMsgs(message);
          }, (err) => {
            console.log(err);
          })
        }
        else this.showAllMsgs(message);        
      }, (err) => {
        console.log(err);
      })          
  }

  showAllMsgs(msg) {
    this.messagesService.getMsgData(msg.Message_ID)
      .subscribe((data) => {
        this.openMessageInterface(msg, data);
      }, (err) => {
        console.log(err);
      })
  }

  openMessageInterface(allMsg, msgDataToSent) {
    let t1= 'Direct Message', msgSendType = CONSTANTS.messageSendStatus.directMessage, t2, t3: '', isCancelledOrder = false;
    let disposable = this.matDialog.open(MessageComponent, {data:{
      msgSendType:msgSendType,
      t1: t1,
      t2: t2,
      t3: t3,
      message: allMsg,
      cancelorderFlag:isCancelledOrder,
      orderMsgPopup: false
    }, ...{ closeByClickingOutside : false}}).afterClosed()
      .subscribe((data) => {
        if (data) {
          let res = data.messageText;
          msgDataToSent.Message_Text = res;
          msgDataToSent.UserId = this.authService.getUserId();
          msgDataToSent.ClientId = msgDataToSent.Sender_User_ID;
          if (allMsg.Parent_Message_ID) msgDataToSent.Parent_Message_ID = allMsg.Parent_Message_ID;
          else msgDataToSent.Parent_Message_ID = allMsg.Message_ID;
          allMsg.msgDataToSent = msgDataToSent;
          if (!msgDataToSent.Order_ID) msgDataToSent.Order_ID = null;
          if (!allMsg.Parent_Message_ID) this.updateParentId(allMsg, data)
            .subscribe((msgData) => {
              if (msgData) this.userMsg.setSuccessMsg("Message has been sent successfully.");
            }, (err) => {
              console.log(err);
              this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
            })
          else this.sendMessageWithoutOrderId(res, msgDataToSent);
        }
      });
  }

  updateParentId(msgData, data) {
    if (!data.checked){
      msgData.msgDataToSent.Message_Type = CONSTANTS.messageType.orderMessage
      return this.messagesService.updateParentId(msgData)
    } else {
      msgData.msgDataToSent.Message_Type = CONSTANTS.messageType.internalMessage
      return this.messagesService.updateParentId(msgData)
    }
  }

  sendMessageWithoutOrderId(msgText, msgDataToSent) {
    var data = {
      Message_Text: msgText,
      Parent_Message_ID: msgDataToSent.Parent_Message_ID
    }
    this.clientService.sendMessage(data, msgDataToSent.Sender_User_ID)
      .subscribe((data) => {
        this.userMsg.setSuccessMsg("Message has been sent successfully.");        
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
      })
  } 

}
