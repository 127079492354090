// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-form-field:hover .hideButton {
  display: inline-block !important;
}
mat-form-field:hover i.fa.fa-trash {
  display: inline-block !important;
}

.hideButton {
  display: none !important;
}

::ng-deep .mat-mdc-icon-button .mat-icon, .mat-icon-button i {
  line-height: 18px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/common/mat-multi-select-for-list/mat-multi-select-for-list.component.scss"],"names":[],"mappings":"AACQ;EACE,gCAAA;AAAV;AAEQ;EACE,gCAAA;AAAV;;AAII;EACI,wBAAA;AADR;;AAIA;EACE,4BAAA;AADF","sourcesContent":["mat-form-field:hover {\n        .hideButton {\n          display: inline-block !important;\n      }\n        i.fa.fa-trash {\n          display: inline-block !important;\n        }\n      }\n\n    .hideButton {\n        display: none !important;\n    }\n\n::ng-deep .mat-mdc-icon-button .mat-icon, .mat-icon-button i {\n  line-height: 18px !important\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
