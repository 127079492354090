
import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ConfigService } from '../config/config.service';
import { CONSTANTS } from '../../app.constants';
import { SocketService } from '../socket/socket.service'
import { StateService } from '../state/state.service';
import { ScrollService } from '../scroll/scroll.service';
import { HttpClient, HttpHeaders, HttpBackend} from '@angular/common/http'
import { UsermessageService } from '../usermessage/usermessage.service';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationSnackbarComponent } from 'app/common/notification-snackbar/notification-snackbar.component';
import { NotificationsService } from '../notifications/notifications.service';
import { SwPush } from '@angular/service-worker';
import { PreloaderService } from '../preloader/preloader.service';

@Injectable()
export class AuthService {

  private viewMenu: boolean;
  private forgetEmailId: any;
  private versionData: string = '';
  private userEmail: string;  
  private snackbarRef : MatSnackBarRef<NotificationSnackbarComponent>;
  direct: HttpClient;
  private redirectURL: string;
  private _isLoggedin$: BehaviorSubject<any> = new BehaviorSubject(false);
  public isLoggedin$: Observable<any> = this._isLoggedin$.asObservable();

  private _isServerReachable$: BehaviorSubject<any> = new BehaviorSubject(false);
  public isServerReachable$: Observable<any> = this._isServerReachable$.asObservable();
  // startTime:any;
  // public elapsedTime: string = '00:00:00';
  // private intervalId: any;
  accesstoken:string="";


  constructor(
    private http: HttpClient,
    private cookies: CookieService,
    private config: ConfigService,
    private socket: SocketService,
    private preloaderService: PreloaderService,
    private stateService: StateService,
    private scrollService: ScrollService,
    private userMsg: UsermessageService,
    private handler: HttpBackend,
    private notificationService: NotificationsService,
    private swPush: SwPush
  ) {
    this.viewMenu = false;
    this.direct = new HttpClient(this.handler);
  }

  setServerStatus(status){
    this._isServerReachable$.next(status);
  }

  setRedirectURL(url){
    this.redirectURL = url;
  }

  getRedirectURL(){
    return this.redirectURL;
  }

  resetRedirectURL(){
    this.redirectURL = '';
  }

  getToken(): string {
    if (this.cookies.getObject('pippinTitleAdm')) return this.cookies.getObject('pippinTitleAdm')['token'];
    return null;
  }

  isLoggedIn(): boolean {
    if (this.getToken()) {
      this._isLoggedin$.next(true);
      return true;
    }
    else return false;
  }

  fetchVersionDetails(){
    this.http.get('env.txt', { responseType: 'text' })
    .subscribe((res:any) => {
      console.log("version details:",res);
      this.setVersionDetails(res);
    });
  }

  canActivate(): any {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() }),
      observe: 'response' as 'body'
    }
    if (this.isLoggedIn())
      return this.http.get(this.config.getBasePath() + '/users/admin/isLoggedIn', httpOptions)
        .pipe(
          map((response) => response,
            (error) => observableThrowError(error.error)));

    return observableThrowError(false);
  }

  canSuperAdminActivate(): any {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() }),
      observe: 'response' as 'body'
    }
    if (this.isLoggedIn() && (Number(this.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.getUserRole()) == CONSTANTS.userRole.compAdmin))
      return this.http.get(this.config.getBasePath() + '/users/admin/isLoggedIn', httpOptions).pipe(
        map((response) => response,
          (error) => observableThrowError(error.error)));

    return observableThrowError(false);
  }

  canOnlyAdminActivate(): any {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() }),
      observe: 'response' as 'body'
    }
    if (this.isLoggedIn() && (Number(this.getUserRole()) == CONSTANTS.userRole.superAdmin ||
      Number(this.getUserRole()) == CONSTANTS.userRole.compAdmin) ||
      Number(this.getUserRole()) == CONSTANTS.userRole.admin)
      return this.http.get(this.config.getBasePath() + '/users/admin/isLoggedIn', httpOptions).pipe(
        map((response) => response,
          (error) => observableThrowError(error.error)));

    return observableThrowError(false);
  }

  /**
   *  ######################################Start Registration###################################################
   */
  registerNewUser(newUser) {
    let httpOptions = {
      observe: 'response' as 'body'
    }
    if (newUser) {
      newUser.access_token = this.config.getAccessToken();
      return this.http.post(this.config.getBasePath() + '/users/register', newUser, httpOptions).pipe(
        map((response: any) => response),
        catchError((error) => observableThrowError(error.error)), );
    }
  }

  setpassword(setPassObj) {
    let httpOptions = {
      observe: 'response' as 'body'
    }
    setPassObj.access_token = this.config.getAccessToken();
    return this.http.post(this.config.getBasePath() + '/users/createpassword', setPassObj, httpOptions).pipe(
      map((response: any) => response),
      catchError((error) => observableThrowError(error.error)), );
  }
  changePassword(modelObj) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
    // let options = new RequestOptions({ headers: headers });
    //modelObj.token =  this.getToken();
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() }),
      observe: 'response' as 'body'
    }
    return this.http.put(this.config.getBasePath() + '/users/update/admin/password', modelObj, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)), );
  }
  updateEmail(modelObj) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() }),
      observe: 'response' as 'body'
    }
    return this.http.put(this.config.getBasePath() + '/users/update/email', modelObj, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)), );
  }
  // updateFirstName(modelObj){
  //   let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
  //   let options = new RequestOptions({ headers: headers });
  //   console.log("this is from service function",modelObj);
  //   return this.http.put(this.config.getBasePath() + '/users/update/firstname',modelObj,options)
  //   .map((response: Response) => { 
  //     console.log("get response from server:",response);
  //     return response;
  //   })
  //   .catch((error:any) => Observable.throw(error.error));
  // }
  // updateLastName(modelObj){
  //   let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
  //   let options = new RequestOptions({ headers: headers });
  //   console.log("this is from service function",modelObj);
  //   return this.http.put(this.config.getBasePath() + '/users/update/lastname',modelObj,options)
  //   .map((response: Response) => { 
  //     console.log("get response from server:",response);
  //     return response;
  //   })
  //   .catch((error:any) => Observable.throw(error.error));
  // }
  // updatePhone(modelObj){
  //   let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
  //   let options = new RequestOptions({ headers: headers });
  //   console.log("this is from service function",modelObj);
  //   return this.http.put(this.config.getBasePath() + '/users/update/phonenumber',modelObj,options)
  //   .map((response: Response) => { 
  //     console.log("get response from server:",response);
  //     return response;
  //   })
  //   .catch((error:any) => Observable.throw(error.error));
  // }
  // updateFax(modelObj){
  //   let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
  //   let options = new RequestOptions({ headers: headers });
  //   console.log("this is from service function",modelObj);
  //   return this.http.put(this.config.getBasePath() + '/users/update/fax',modelObj,options)
  //   .map((response: Response) => { 
  //     console.log("get response from server:",response);
  //     return response;
  //   })
  //   .catch((error:any) => Observable.throw(error.error));
  // }
  updateCompanyName(modelObj) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() }),
      observe: 'response' as 'body'
    }
    return this.http.put(this.config.getBasePath() + '/users/update/company', modelObj, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)), );
  }

  updateUserInfo(modelObj) {
    modelObj.User_ID = this.getUserId();
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() }),
      observe: 'response' as 'body'
    }
    return this.http.put(this.config.getBasePath() + '/users/update/info', modelObj, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)), );
  }

  getProfile() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/users/profile/' + this.getUserId();
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        // create a cookies for newuser 
        this.cookies.put('userProfile', JSON.stringify(response));
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)), );
  }

  endSession() {
    if (this.getToken()) {
      let httpOptions = {
        headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
      }
      let url = this.config.getBasePath() + '/auth/admin/logout';
      this.http.post(url, { token: this.getToken(), User_ID: this.getUserId() }, httpOptions)
        .subscribe((data) => {
          console.log("data:", data);
        }, (err) => {
          console.log("err", err);
        })
    }
  }


  //  getEmail(){
  //   return this.cookies.getObject('userProfile')['Email_Address'];
  // }
  // getFirstName(){
  //   return this.cookies.getObject('userProfile')['User_First_Name'];
  // }
  // getLastName(){
  //   return this.cookies.getObject('userProfile')['User_Last_Name'];
  // }
  // getPhone(){
  //   return this.cookies.getObject('userProfile')['Contact_Num'];
  // }
  // getFax(){
  //   return this.cookies.getObject('userProfile')['User_Fax_Contact_Number'];
  // }
  // getCompanyName(){
  //   return this.cookies.getObject('userProfile')['Company_Name'];
  // }
  // getLocality(){
  //   return this.cookies.getObject('userProfile')['Address_1'];
  // }
  // getCity(){
  //   return this.cookies.getObject('userProfile')['Address_City'];
  // }
  // getState(){
  //   return this.cookies.getObject('userProfile')['Address_State_Abbr'];
  // }
  // getZipCode(){
  //   return this.cookies.getObject('userProfile')['Address_ZipCode'];
  // }
  // getAddress_2(){
  //   return this.cookies.getObject('userProfile')['Address_2'];
  // }

  // setCookieFirstName(value){    
  //   var pippinAdm=this.cookies.getObject('pippinTitleAdm');   
  //   if(pippinAdm){
  //     pippinAdm['user']['User_First_Name']=value
  //   }    
  //   this.cookies.put('pippinTitleAdm' ,JSON.stringify(pippinAdm));    
  // }

  // setCookieLastName(value){
  //   var pippinAdm=this.cookies.getObject('pippinTitleAdm');    
  //   if(pippinAdm){
  //     pippinAdm['user']['User_Last_Name']=value
  //   }
  //   this.cookies.put('pippinTitleAdm' ,JSON.stringify(pippinAdm));
  // }

  updateCookie(user) {
    var pippinAdm = this.cookies.getObject('pippinTitleAdm');
    if (pippinAdm) {
      pippinAdm['user'] = user
    }
    this.cookies.put('pippinTitleAdm', JSON.stringify(pippinAdm));
  }


  /**
   * ######################################END Registration######################################################
   */

  /**
   *  ######################################START LOGIN ###################################################
   */

  login(loginData: any) {
    let username: string = loginData.Email_Address;
    let password: string = loginData.User_Password;

    let access_token: string = this.config.getAccessToken();
    let headers = new HttpHeaders({ 'Authorization': 'Basic ' + btoa(username + ":" + password) });



    return this.http.post(this.config.getBasePath() + '/auth/admin/login', { access_token: access_token, User_Role: 'admin' }, { headers: headers })
      .pipe(
        map((response: any) => {
          var cookieObj = response;
          if (response && response['token']) {
            cookieObj['user']['User_Code'] = cookieObj['user']['User_Status'];
            delete cookieObj['user']['User_Status'];
            // create a cookies for newuser 
            this.cookies.put('pippinTitleAdm', JSON.stringify(cookieObj));
            this._isLoggedin$.next(true);
            this.setServerStatus(true);
            if(this.swPush.isEnabled){
              this.swPush.subscription.pipe(take(1)).subscribe((subs: PushSubscription) => {
                if(subs) this.updateToPushNotificationsSubscription(subs.toJSON())
                else this.requestPushNotifications();
              })
            }   
            this.getAllUserNotifications();
            // this.startTimer();
          }
          return response;
        }),
        catchError((error) => observableThrowError(error.error)), );

  }

  refreshToken(){

    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.cookies.getObject('pippinTitleAdm')['refreshToken'] ,
      'Content-Type': 'application/json'}),
      observe: 'response' as 'body',
    }

    let body={refreshToken:this.cookies.getObject('pippinTitleAdm')['refreshToken']};

    return this.http.post(this.config.getBasePath() + '/auth/admin/refresh', body, httpOptions)
      .pipe(
        map((response: any) => {
          var cookieObj = response.body;
          if (cookieObj && cookieObj['token']) {
            cookieObj['user']['User_Code'] = cookieObj['user']['User_Status'];
            delete cookieObj['user']['User_Status'];
            // create a cookies for newuser 
            this.cookies.put('pippinTitleAdm', JSON.stringify(cookieObj));
            this._isLoggedin$.next(true);
            this.setServerStatus(true);
            if(this.swPush.isEnabled){
              this.swPush.subscription.pipe(take(1)).subscribe((subs: PushSubscription) => {
                if(subs) this.updateToPushNotificationsSubscription(subs.toJSON())
                else this.requestPushNotifications();
              })
            }   
            this.getAllUserNotifications();
          }
        }),
        catchError((error) => observableThrowError(error.error)), ).subscribe((data)=>{
          // this.startTimer();
        });
  }

  // startTimer(){
    // clearInterval(this.intervalId); 
    // this.accesstoken=this.cookies.getObject('pippinTitleAdm')['token'];
    // let decode=this.decodeToken(this.cookies.getObject('pippinTitleAdm')['token'])
      
   
    // if (decode && decode.exp * 1000 > Date.now()) {
    //   this.startTime=this.convertMillisecondsToHHMMSS(decode.exp * 1000 - Date.now());
    //  }

   
    // this.intervalId = setInterval(() => {
    //   const elapsedMilliseconds = decode.exp * 1000 - Date.now();
    //   this.elapsedTime = this.convertMillisecondsToHHMMSS(elapsedMilliseconds);
      
    //   if (decode && decode.exp * 1000 <= Date.now()) {
    //     clearInterval(this.intervalId); 
    //     this.logout();
    //  }
    // }, 1000); 
    // Update every second
  // }

  public decodeToken(token: string): any {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      console.error('Error decoding token:', e);
      return null;
    }
  }

  convertMillisecondsToHHMMSS(milliseconds: number): string {
    const seconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const formattedHours = this.padZero(hours);
    const formattedMinutes = this.padZero(minutes);
    const formattedSeconds = this.padZero(remainingSeconds);
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  
  // Pad single-digit numbers with leading zero
  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  getClientLoginCreds(data) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/users/client/login';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)), );
  }

  logout(): void {
    this.stateService.resetAll();
    this.scrollService.resetAll();
    this.config.setSideBarNode(0);
    if(this.userMsg.getSuccessMsgData()) this.userMsg.setSuccessMsgNull();
    this.endSession();
    this._isLoggedin$.next(false);
    this.cookies.remove('pippinTitleAdm');
    this.socket.close();
  }




  /**
 *  ######################################End Login################################################### * 
 */
  /**
  *  ######################################START FORGET################################################### * 
  */
  forget(modelObj: any) {
    let httpOptions = {
      observe: 'response' as 'body'
    }
    modelObj.access_token = this.config.getAccessToken();
    modelObj.User_Role = 'admin';
    return this.http.put(this.config.getBasePath() + '/users/forgetpassword/admin', modelObj, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)), );

  }
  setEmailIdForForgetPwd(forgetEmailId) {
    this.forgetEmailId = forgetEmailId;
  }
  resetEmailIdForForgetPwd() {
    this.forgetEmailId = '';
  }

  getEmailIdForForgetPwd() {
    return this.forgetEmailId;
  }

  /**
  *  ######################################END FORGET################################################### * 
  */

  /**
     *  ######################################Start Helper functions###################################################
     */


  getName(): string {
    var curUser = this.cookies.getObject('pippinTitleAdm')['user'];
    if (curUser) return curUser['User_First_Name'] + ' ' + curUser['User_Last_Name'];
    return '';
  }

  getFirstName(): string {
    if (this.cookies.getObject('pippinTitleAdm')) return this.cookies.getObject('pippinTitleAdm')['user']['User_First_Name'];
    return '';
  }

  getUserCode(): string {
    if (this.cookies.getObject('pippinTitleUsr')) return this.cookies.getObject('pippinTitleUsr')['user']['User_Code'];
    return '';
  }

  getLastName(): string {
    if (this.cookies.getObject('pippinTitleAdm')) return this.cookies.getObject('pippinTitleAdm')['user']['User_Last_Name'];
    return '';
  }

  getUserId(): string {
    if (this.cookies.getObject('pippinTitleAdm') && this.cookies.getObject('pippinTitleAdm')['user']
      && this.cookies.getObject('pippinTitleAdm')['user']['User_ID']) return this.cookies.getObject('pippinTitleAdm')['user']['User_ID'];
    else {
      this.logout();
      // this.router.navigate(['/login']);
      return '';
    }

  }

  getUserRole(): string {
    if (this.cookies.getObject('pippinTitleAdm')) return this.cookies.getObject('pippinTitleAdm')['user']['User_Role'];
    return '';
  }

  getSearchMasterType():string {
    if (this.cookies.getObject('pippinTitleAdm')) return this.cookies.getObject('pippinTitleAdm')['user']['User_Type'];
    return '';
  }

  isSearchManager() {    
    if (this.cookies.getObject('pippinTitleAdm') && this.cookies.getObject('pippinTitleAdm')['user']) {
      if(this.cookies.getObject('pippinTitleAdm')['user']['User_Role'] == CONSTANTS.userRole.searchMaster && this.cookies.getObject('pippinTitleAdm')['user']['Is_Manager'] ==CONSTANTS.managerType.searchManager) return true
      else return false;
    } else return false;
  }

  getUserOrgCount(): number {
    if (this.cookies.getObject('pippinTitleAdm') && this.cookies.getObject('pippinTitleAdm')['user']['Org_Count'])
      return this.cookies.getObject('pippinTitleAdm')['user']['Org_Count'];
    return null;
  }

  showClientBubble(): boolean {
    if (Number(this.getUserRole()) == CONSTANTS.userRole.superAdmin
      || Number(this.getUserRole()) == CONSTANTS.userRole.compAdmin
      || Number(this.getUserRole()) == CONSTANTS.userRole.admin
      || Number(this.getUserRole()) == CONSTANTS.userRole.searchMaster)
      return true;
    if (this.cookies.getObject('pippinTitleAdm') && this.cookies.getObject('pippinTitleAdm')['user']['Org_Count']
      && this.cookies.getObject('pippinTitleAdm')['user']['Org_Count'] > 0)
      return false;
    return true;
  }

  setUserOrgCount(cnt) {
    var tmpCookieObj = this.cookies.getObject('pippinTitleAdm');
    tmpCookieObj['user']['Org_Count'] = cnt;
    this.cookies.put('pippinTitleAdm', JSON.stringify(tmpCookieObj));
  }

  getMenu(): boolean {
    return this.viewMenu;
  }

  showMenu(event) {
    this.viewMenu = !this.viewMenu;
    event.stopPropagation();
  }

  hideMenu() {
    this.viewMenu = false;
  }


  /**
 *  ######################################End  Helper functions###################################################
 * 
 */
  getVersionDetails() {
    if (this.config.isProductionEnv()) return "";
    return this.versionData;
  }
  setVersionDetails(vdata) {
    this.versionData = vdata;
  }  

  subscribeToWebPush(subscriptionBody) {
    // subscriptionBody.access_token = this.config.getAccessToken();
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/push-notification/subscribe';
    return this.direct.post(url, { Subscription: subscriptionBody, User_Type: 1, User_ID: this.getUserId(), User_Token: this.getToken() }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)), );
  }

  updateWebPushSubscription(subscriptionBody) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/push-notification/subscribe';
    return this.direct.put(url, { Subscription: subscriptionBody, User_Type: 1, User_ID: this.getUserId(), User_Token: this.getToken() }, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)), );
  }
  
  setSnackRef(ref){
    this.snackbarRef = ref;
  }

  getSnackRef(){
    return this.snackbarRef;
  }

  getAllUserNotifications() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/web-notification/' + this.getUserId();
    this.http.get(url, httpOptions)
      .subscribe((data) => {
        this.notificationService.setNotifications(data['rows']);
        this.notificationService.setCount(data['count']);
      }, (err) => {
        console.log("err", err);
      })
  }

  deleteUserNotificationbyId(id) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/web-notification/' + id;
    return this.direct.delete(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  deleteUserNotificationbyOrderId(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/web-notification/order/' + orderId;
    return this.http.delete(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  deleteUserNotificationbyOrderIdAndUserId(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/web-notification/order/' + orderId + '/user/' + this.getUserId();
    return this.direct.delete(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  deleteUserNotificationbyMsgIdAndUserId(msgId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/web-notification/message/' + msgId + '/user/' + this.getUserId();
    return this.direct.delete(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  updateToPushNotificationsSubscription(sub) {
    this.updateWebPushSubscription(sub)
    .subscribe((result) => {
    }, (err) => {
      console.log("Error", err);
    })
  }

  requestPushNotifications() {
    this.swPush.requestSubscription({
      serverPublicKey: this.config.getVapidPublicKey()
    })
      .then(sub => {  
        this.subscribeToPushNotifications(sub);
      })
      .catch(err => { console.error("Could not subscribe to notifications", err) });
  }

  subscribeToPushNotifications(sub) {
    this.subscribeToWebPush(sub)
    .subscribe((result) => {
    }, (err) => {
      console.log("Error", err);
    })
  }

  clearAllNotfications() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/web-notification/clearall/' + this.getUserId();
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {        
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  seeAllNotifcations(pageNum, sortBy, orderBy, searchString) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/web-notification/seeall/' + this.getUserId();
    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    if( sortBy != "") url = url + '/sort/' + sortBy + '/order/' + orderBy
    if(searchString ) url = url + '/search/' + searchString;
    return this.http.post(url, null, httpOptions).pipe(
      map((response: any) => {        
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getBackgroundNotifications(pageNum, sortBy, orderBy, searchString) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() })
    }
    let url = this.config.getBasePath() + '/web-notification/seeall/' + this.getUserId();
    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    if( sortBy != "") url = url + '/sort/' + sortBy + '/order/' + orderBy
    if(searchString ) url = url + '/search/' + searchString;
    return this.http.post(url, null, httpOptions).pipe(
      map((response: any) => {        
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  serverHealthCheck(): any {
    this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() }),
      observe: 'response' as 'body'
    }
      return this.http.get(this.config.getBasePath() + '/users/healthCheck', httpOptions).pipe(
        map((response) => response,
          (error) => observableThrowError(error.error)));
  }

  loggedOutHealthCheck(): any {
    let httpOptions = {
      observe: 'response' as 'body'
    }
      return this.http.get(this.config.getBasePath() + '/users/checkstat', httpOptions).pipe(
        map((response) => response,
          (error) => observableThrowError(error.error)));
  }

}