import { Component, OnInit, ViewChild, ElementRef, Inject} from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { AbstractorService } from '../../services';
import { DragDropModule } from '@angular/cdk/drag-drop'; 
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-rate-abstractor',
  templateUrl: './rate-abstractor.component.html',
  styleUrls: ['./rate-abstractor.component.scss']
})
export class RateAbstractorComponent  {

  @ViewChild('myFirstFocus') myFirstFocus: ElementRef

  message: any;
  title = '';
  rating = 1;
  userNotes:any= {};
  userNotesData : any;
  isConfirmFlag : boolean = false
  starList: boolean[] = [false, true, true, true, true];
  timer: any = [];
  constructor( 
    private abstractorService: AbstractorService,
    public dialogRef: MatDialogRef<RateAbstractorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(()=>{
      this.fetchAbstractorNotes();
      this.myFirstFocus.nativeElement.focus();
    }, 0));        
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }


  confirm(skipFlag = false) {
    
    let result:any = {
      state: true,
      rating: this.rating,
    }
    if(skipFlag) result['notes'] = this.userNotesData;
    else result['notes'] = this.userNotes.User_Notes_Text
    this.dialogRef.close(result);
    this.clearTime();
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    this.clearTime();
  }

  setStar(data: any) {
    this.rating = data + 1;
    for (var i = 0; i <= 4; i++) {
      if (i <= data) {
        this.starList[i] = false;
      }
      else {
        this.starList[i] = true;
      }
    }
  }

  skip(){
    this.rating = null;
    this.confirm(true);
  }

  fetchAbstractorNotes(){
    this.abstractorService.getAbstractorNotes(this.message.Abstrator.User_ID)
    .subscribe(notes => {
      if(notes){
        this.userNotes = notes;
        this.userNotesData = this.userNotes.User_Notes_Text
      }
    },(err)=>{ });
  }

  clearTime(){
    // this.close();
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
}
