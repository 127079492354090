import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[NoDecimal]'
})
export class NoDecimalDirective {

  constructor(private el: ElementRef) { }

  @Input() NoDecimal: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;
    if (this.NoDecimal) {
      if (e.keyCode !== 190 && e.keyCode !== 110) return;
      else e.preventDefault();
    }
  }
}
