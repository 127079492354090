import { Component, ViewChild, ElementRef ,ViewEncapsulation, Inject} from '@angular/core';
import { ConfigService, AbstractorService } from '../../services';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractorMessageComponent } from '../../dialog/abstractor-message/abstractor-message.component';
import { SuccessComponent } from '../../dialog/success/success.component';
import { CONSTANTS } from 'app/app.constants';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-abs-dialog',
  templateUrl: './create-abs-dialog.component.html',
  styleUrls: ['./create-abs-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateAbsDialogComponent{
  registerFrm: UntypedFormGroup;
  error: string = '';
  errorMsg: any = {};
  errorType: string = '';
  registerModel: any = {};
  message: any = {};
  itemInput="";
  checked: boolean = false;  
  sendReminder: boolean = true;  
  users = [];
  timer: any = [];
  keywords = ['Email_Address', 'Abstrator_Full_Name'];
  constructor(private config: ConfigService,
    private fb: UntypedFormBuilder,
    private abstractorService: AbstractorService,
    private matDialog:MatDialog,
    public dialogRef: MatDialogRef<CreateAbsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.registerFrm = fb.group({
      'User_First_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      'Confirm_Email_Address': [null, Validators.required],
      'User_SSN': [null, Validators.maxLength(11)],
      'User_EIN': [null, Validators.maxLength(10)],
      'Contact_Num': [null],
      'Abstrator_Cost': [null, Validators.compose([Validators.required, Validators.maxLength(14)])], 
      'isChecked':[null],
      'sendReminder':[null],
      'Contact_Type':[null]
    })
    this.registerFrm.valueChanges.subscribe((data) => {
      this.error = '';
    });    
  }

  ngOnInit() {
    this.initDialog();
    this.registerModel.Contact_Num = '';
    this.registerModel.Contact_Type = 'M';
    this.timer.push(setTimeout(() => {
      this.abstractorService.getAllAbstractorsList().subscribe((users) => {this.users = users })
      // this.getAvailableAbstractorsLocal();
    }, 0));   
  }    

  initDialog(){
    this.message = this.data.message;
  }

//   getAvailableAbstractorsLocal(){
//     let AllAbstractors = JSON.parse(sessionStorage.getItem('AllAbstractors'));
//     if(AllAbstractors) this.setAbstractor(AllAbstractors)         
//     else this.getAvailableStateList();
// }
  
//   getAvailableStateList(){
//     this.abstractorService.getAllAbstractorsList().subscribe((abstractors) => { 
//       if(abstractors){
//         this.setAbstractor(abstractors)         
//         sessionStorage.setItem('AllAbstractors',JSON.stringify(abstractors)); 
//        setTimeout(function(){sessionStorage.removeItem("AllAbstractors");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });
//   }

  
//   setAbstractor(AllAbstractorsData){
//     this.users = AllAbstractorsData;
// }

  createAbstractor() {
    // sessionStorage.removeItem('AllAbstractors');
    if(this.checked) {
    let disposable = this.matDialog.open(AbstractorMessageComponent, {data:{
      message: { 
        orderDetails: this.message['order'], 
        abstractor: this.registerModel, 
        instructions: this.message['instructions'], 
        documents: this.message['documents'],
        clientInfo : this.message?.clientInfo?.Note_Text
      }
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res:any) => {
        if (res && res.state) {
          this.registerModel.Message_Text = res.absMessage;
          this.registerModel.Abstractor_Documents = res.absDocuments;
          this.registerModel.Order_ID = this.message['order'].Order_ID;
          this.registerModel.rating = this.message.rating;
          this.registerModel.notes = this.message.notes;
          this.registerModel.Send_Reminder = this.sendReminder;
          this.registerModel.Send_Mail = this.checked;
          this.abstractorService.createAbstractorOnFly(this.registerModel)
            .subscribe((res:any) => {
              if (res) {
                  let result:any = {};
                  if(!res.mail) result.mail = false;
                  else result.mail = true;
                  result.checked = true;
                  this.dialogRef.close(result);
                  this.clearTime();
                }    
                else {
                  this.dialogRef.close(false);
                  // this.result = false;
                  this.clearTime();
              }
            }, (err) => {
              this.errorMsg = err;
            });
        }
      })
    } else this.sendOrdToAbsWithoutMail()
  }

  sendOrdToAbsWithoutMail(){
    this.registerModel.Message_Text = null;
    this.registerModel.Abstractor_Documents = [];
    this.registerModel.Order_ID = this.message['order'].Order_ID;
    this.registerModel.rating = this.message.rating;
    this.registerModel.notes = this.message.notes;
    this.registerModel.Send_Reminder = this.sendReminder;
    this.registerModel.Send_Mail = this.checked;
    this.abstractorService.createAbstractorOnFly(this.registerModel)
      .subscribe((res) => {
        if (res) {
          let result:any={}
          result.mail = false;
          result.checked = false;
          this.dialogRef.close(result);
          this.clearTime();
        }
        else {
          this.dialogRef.close(false);
          // this.result = false;
          this.clearTime();
      }
      }, (err) => {
        this.errorMsg = err;
      })
  }

  clearError() {
    this.errorMsg.msg = '';
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    this.clearTime()
  }

  openSuccessModal(title, msg) {
    this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  getAbstractorFormat(e, absModel){
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((this.itemInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.itemInput.length > 0 || this.itemInput.length < this.config.getLimitPriceMaxLength())) {
        this.itemInput = this.itemInput.slice(0, this.itemInput.length - 1);
        e.target.value = this.numberFormat(this.itemInput);
        absModel.Abstrator_Cost = e.target.value;
      }
      else if (this.itemInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.itemInput = this.itemInput + key;
          e.target.value = this.numberFormat(this.itemInput);
          absModel.Abstrator_Cost = e.target.value;
        }
      }
      return false;
    }
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return "";
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  assignAbsuser(abstractor){
    this.registerModel.User_First_Name = abstractor.User_First_Name
    this.registerModel.Email_Address = abstractor.Email_Address;
    this.registerModel.Confirm_Email_Address = abstractor.Email_Address;
    this.registerModel.User_SSN = abstractor.User_SSN ?  abstractor.User_SSN : '';
    this.registerModel.User_EIN = abstractor.User_EIN ? abstractor.User_EIN : '';
    this.registerModel.Contact_Num = abstractor.Contact_Num ? abstractor.Contact_Num : '';
    this.registerModel.Contact_Type = abstractor.Contact_Type ? abstractor.Contact_Type : 'M';
  }
  

  getDisplayName(item){
    if(item.Abstrator_Full_Name) return item.Abstrator_Full_Name.toUpperCase();
    else if(item.User_First_Name) return item.User_First_Name.toUpperCase();
    else return '';
  } 

  selectEvent(item) {    
    this.assignAbsuser(item);
  }  
  
  getOptionDisplayTemplate(item){
    return '<span>' + (item.Abstrator_Full_Name ? item.Abstrator_Full_Name :  '-N.A-')   + '</span>' + '&nbsp;(' +  item.Email_Address +')';
  }  

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
