import { Component,ChangeDetectorRef, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-duedate',
  templateUrl: './duedate.component.html',
  styleUrls: ['./duedate.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DuedateComponent implements OnInit {
  dueDateType = "0";
  dueDateForm: UntypedFormGroup
  @Input() dueDateCtrl = new UntypedFormControl({ value: '', disabled: false });
  @Input() dueDateEtaTime: string = "17:00";
  @Input() dueDateEtaDate: any;
  @Output() dueDateEvent = new EventEmitter<any>();
  @ViewChild('etaDatePicker') etaDatePicker: ElementRef;
  @ViewChild('dueDateRange') dueDateRange: ElementRef;
  @Input() placeHolderDate: string;
  @Input() placeHolderTime: string;
  @Input() validateString: string;
  @Input() reqDate:boolean=false;
  dueDateObj = {};
  orderETAtime = new UntypedFormControl({ value: '', disabled: true }, [Validators.required]);
  errors: any = {};
  getUSDateString:any;
  minDate:any;
  @Input() RequestDate: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    private adapter: DateAdapter<any>
 
  ) {
    this.dueDateForm = this.fb.group({
      'fromDayCtrl': [null],
      'toDayCtrl': [null]
    });
  }

  ngOnInit() { 
    if(!this.reqDate){
      this.getUSDateString = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
      this.minDate = new Date(this.getUSDateString);
    }else{
      this.getUSDateString = new Date('01/02/1500').toLocaleString("en-US", { timeZone: "America/New_York" });
      this.minDate = new Date(this.getUSDateString);
    }
  }

  dueDateNTime() {
    if(this.orderETAtime.valid) {
      if (this.dueDateEtaDate && this.dueDateEtaTime) {
        if(this.RequestDate){
          this.dueDateObj['req_Date_EST'] = this.dueDateEtaDate;
        this.dueDateObj['req_Time_EST'] = this.dueDateEtaTime;
        }else{
          this.dueDateObj['Due_Date_EST'] = this.dueDateEtaDate;
          this.dueDateObj['Due_TIme_EST'] = this.dueDateEtaTime;
          this.dueDateObj['dueDateType'] = this.dueDateType;
        }
        console.log("this.dueDateObj",this.dueDateObj)
        this.dueDateEvent.emit(this.dueDateObj);
      }
    }else {
      this.dueDateObj['dueDateTimeCtrl'] = this.orderETAtime
      this.dueDateEvent.emit(this.dueDateObj);
    }
  }

  validateOrderETADateFn(event) {
    if (event.value) {
      if (this.dueDateEtaDate && this.dueDateCtrl.valid) this.orderETAtime.enable();
      if(this.RequestDate){
        this.dueDateObj['req_Date_EST'] = this.dueDateEtaDate;
      this.dueDateObj['req_Time_EST'] = this.dueDateEtaTime;
      }else{
        this.dueDateObj['Due_Date_EST'] = this.dueDateEtaDate;
        this.dueDateObj['Due_TIme_EST'] = this.dueDateEtaTime;
        this.dueDateObj['dueDateType'] = this.dueDateType;
      }
      this.dueDateEvent.emit(this.dueDateObj);
    }else{
      if(this.RequestDate){
        this.dueDateObj['req_Date_EST'] = event.value;
      this.dueDateObj['req_Time_EST'] = this.dueDateEtaTime;
      }else{ 
        this.dueDateObj['Due_Date_EST'] = event.value;
        this.dueDateObj['Due_TIme_EST'] = this.dueDateEtaTime;
        this.dueDateObj['dueDateType'] = this.dueDateType;
      }
      this.dueDateEvent.emit(this.dueDateObj);
    }
  }

  clearForms() {
    this.errors = {};
    if (this.dueDateEtaDate && this.dueDateCtrl.valid) this.orderETAtime.enable();
    else this.orderETAtime.disable();
  }

  checkRangeDays(fromDay, toDay) {
    if (Number(fromDay) && !Number(toDay)) this.dueDateForm.controls['toDayCtrl'].setErrors({ 'toDayRequired': true });
    else this.dueDateForm.controls['toDayCtrl'].setErrors(null);
    if (Number(toDay) && !Number(fromDay)) this.dueDateForm.controls['fromDayCtrl'].setErrors({ 'fromDayRequired': true });
    else this.dueDateForm.controls['fromDayCtrl'].setErrors(null);
    if (Number(fromDay) && Number(toDay)) {
      if (Number(fromDay) >= Number(toDay)) this.dueDateForm.controls['toDayCtrl'].setErrors({ 'rangeError': true });
    }
    this.dueDateObj['dueDateFrmCtrl'] = this.dueDateForm
    this.dueDateEvent.emit(this.dueDateObj);
  }

  detectTypeChange (event) {
    this.dueDateObj['dueDateType'] = event.value;
    if(Number(event.value)) { 
      if(this.validateString == "editOrderComp") {
      if(this.placeHolderDate.includes("*")){
          this.dueDateForm.controls['fromDayCtrl'].setValidators(Validators.required);
          this.dueDateForm.controls['toDayCtrl'].setValidators(Validators.required);
          this.dueDateForm.updateValueAndValidity();
          this.dueDateEvent.emit(this.dueDateObj);
      }
      }
    } else {
      if(this.dueDateForm.controls['toDayCtrl'] && this.dueDateForm.controls['toDayCtrl'].touched) this.dueDateForm.controls['toDayCtrl'].markAsUntouched();     
      if(this.dueDateForm.controls['fromDayCtrl'] && this.dueDateForm.controls['fromDayCtrl'].touched) this.dueDateForm.controls['fromDayCtrl'].markAsUntouched();      
      this.dueDateForm.controls['toDayCtrl'].setValue(null);
      this.dueDateForm.controls['fromDayCtrl'].setValue(null);
      this.dueDateForm.clearValidators();
      this.dueDateForm.updateValueAndValidity();
      this.dueDateEvent.emit(this.dueDateObj);
    }
  }

  restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
     return false;
    }
    if (e.which === 0) {
     return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[///\d\s]/.test(input);
   }

   checkYear(){
    if(this.dueDateObj){
        if((this.dueDateObj['req_Date_EST']&& this.dueDateObj['req_Date_EST']._.getFullYear() >= '1500') || 
        (this.dueDateObj['Due_Date_EST'] && this.dueDateObj['Due_Date_EST']._d.getFullYear() >= '1500')) return true;
        else return false;
    }else return false;
   }
}
