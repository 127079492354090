import { Component, Inject, OnInit } from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { ConfigService, QuickLinksService } from "app/services";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { CONSTANTS } from "app/app.constants";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-quote-complete",
  templateUrl: "./quote-complete.component.html",
  styleUrls: ["./quote-complete.component.scss"],
})
export class QuoteCompleteComponent {
  quoteId: any;
  quoteCompleteForm: UntypedFormGroup;
  quoteModel: any = {};
  priceText = "";
  // priceObj = {
  //   currInput: ''
  // }
  constructor(
    private config: ConfigService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<QuoteCompleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.quoteCompleteForm = fb.group({
      Quote_Price: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(13)]),
      ],
      etaDays: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(3)]),
      ],
      Comment: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.initDialog();
  }

  initDialog() {
    this.quoteId = this.data.quoteId;
  }

  goBack() {
    this.dialogRef.close({ state: false });
  }

  quoteConfirm() {
    if (this.quoteCompleteForm && this.quoteCompleteForm.valid) {
      let quoteCompleteData = {
        state: true,
        Order_Price: this.quoteModel.Base_Price,
        Message_Text: this.quoteModel.Comment,
        Order_Estimated_Time: this.quoteModel.etaDays + " business days",
        Order_ID: this.quoteId,
        Order_Status: CONSTANTS.orderStatus.received,
      };
      this.dialogRef.close(quoteCompleteData);
      // this.result = quoteCompleteData;
      // this.close();
    }
  }

  getNumberFormat(e) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if (this.priceText.length == 0 && e.key == "0") e.preventDefault();
    else {
      if (
        (e.keyCode == 8 || e.keyCode == 46) &&
        (this.priceText.length > 0 ||
          this.priceText.length < this.config.getLimitPriceMaxLength())
      ) {
        this.priceText = this.priceText.slice(0, this.priceText.length - 1);
        e.target.value = this.numberFormat(this.priceText);
        this.quoteModel.Base_Price = e.target.value;
      } else if (this.priceText.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.priceText = this.priceText + key;
          e.target.value = this.numberFormat(this.priceText);
          this.quoteModel.Base_Price = e.target.value;
        }
      }
      return false;
    }
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return "0.00";
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57) return String.fromCharCode(keyCode);
  }

  checkRangeDays(fromDay, toDay) {
    if (Number(fromDay) && !Number(toDay))
      this.quoteCompleteForm.controls["toDayCtrl"].setErrors({
        toDayRequired: true,
      });
    else this.quoteCompleteForm.controls["toDayCtrl"].setErrors(null);
    if (Number(toDay) && !Number(fromDay))
      this.quoteCompleteForm.controls["fromDayCtrl"].setErrors({
        fromDayRequired: true,
      });
    else this.quoteCompleteForm.controls["fromDayCtrl"].setErrors(null);
    if (Number(fromDay) && Number(toDay)) {
      if (Number(fromDay) >= Number(toDay))
        this.quoteCompleteForm.controls["toDayCtrl"].setErrors({
          rangeError: true,
        });
    }
  }

  isCommentEmpty() {
    return this.quoteModel.Comment.trim().length == 0;
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    // this.close();
  }
}
