import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { RptBuilderService } from "./rpt-builder.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import {
  OrdersService,
  OrganizationService,
  ConfigService,
  UsermessageService,
  PreloaderService,
} from "app/services";
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from "../dialog/error-dialog/error-dialog.component";
import { MatBottomSheetRef, MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { RbErrSnackbarComponent } from "./rb-err-snackbar/rb-err-snackbar.component";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-report-builder",
  templateUrl: "./report-builder.component.html",
  styleUrls: ["./report-builder.component.scss"],
  providers:[
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
  ]
})
export class ReportBuilderComponent implements OnInit, OnDestroy, AfterViewInit {
  timer: any = [];
  private ngUnsubscribe = new Subject();
  orderDetails: any = {};
  reportDetails: any = null;
  interval: any;
  onNetwork = false;
  isReportValid = true;
  versionDetails: any = [];
  environment = environment;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrdersService,
    private organizationService: OrganizationService,
    private builder: RptBuilderService,
    private matDialog:MatDialog,
    private userMsg: UsermessageService,
    private config: ConfigService,    
    private bottomSheet: MatBottomSheet,
    private preLoader: PreloaderService,
    private cdr: ChangeDetectorRef,
    private location:Location
  ) {}

  ngOnDestroy(): void {
    this.builder.resetBuilderData();
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  ngOnInit() {
    this.preLoader.onNetwork$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state) => {
        this.onNetwork = state;
        this.cdr.detectChanges();
      });
    this.builder.resetBuilderData();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
    this.builder.clearErrors();
    this.builder.orgDetails$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data) => {
      if(data) this.builder.setRemoteLanguage(data.remoteLanguage == 1);
    });
    this.builder.isReportValid$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state) => (this.isReportValid = state));
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.reportDetails = data.curData;
        if (this.reportDetails && this.reportDetails.Id !== undefined) {
          if (
            this.orderDetails &&
            this.orderDetails.Report_Version !== undefined
          ) {
            if (this.orderDetails.Report_Version !== this.reportDetails.Id)
              this.builder
                .changeBuilderVersion(
                  this.orderDetails.Order_ID,
                  this.reportDetails.Id
                )
                .subscribe();
          }
        }
      });
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        setTimeout(()=> {
          this.initBuilder(data)
        }, 0)
      });
    this.route.params.subscribe((params) => {
      this.prepareBuilder(params);      
    });
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string) => {
      setTimeout(() => { 
        this.router.navigate([], { fragment: fragment });
      }, 4000)
    })
  }

  prepareBuilder(params) {
    this.builder.getDataTypes().subscribe();
    this.builder.getOrderDetails(params["orderId"]).subscribe();
  }

  initBuilder(data) {
    if (data) {
      this.orderDetails = data;

      // Need to create a new field reportVersion in Order table
      // Write a query to update the Order table reportVersion field with the search package ID

      if (this.orderDetails && this.orderDetails.Report_Version !== undefined) {
        if (this.orderDetails.Report_Version)
          this.builder
            .getReportDetails(this.orderDetails.Report_Version)
            .subscribe();
        else this.builder.initBuilder(this.orderDetails).subscribe();
      }

      if (this.orderDetails && this.orderDetails.Order_ID !== undefined) {
        this.builder.getAllVersions(this.orderDetails.Order_ID).subscribe();
        this.orderService
          .getQualiaOrderDetails(this.orderDetails.Order_ID)
          .subscribe((qualiaDetails) => {
            if (qualiaDetails && qualiaDetails.Order_Qualia_Internal_ID) {
              this.builder.setQualiaOrder(true);
              let qualiaOrderId =
                qualiaDetails.Order_Qualia_Internal_ID.split("-")[0];
              this.builder.getQualiaCommitmentTypes(qualiaOrderId).subscribe();
            } else {
              this.builder.setQualiaOrder(false);
              if(this.orderDetails.Organization_ID){
                this.builder
                .getCommitmentItemTypes(this.orderDetails.Organization_ID)
                .subscribe();
              }              
            }
          });
          if(this.orderDetails.Organization_ID){
            this.builder
            .getOrgDetails(this.orderDetails.Organization_ID)
            .subscribe(() => {});
          }        
      }
    }
  }

  goBack() {
    this.location.back();
    // this.router.navigate(["/orders/" + this.orderDetails.Order_ID + "/view"]);
  }

  generateCommitmentLetter() {
    if(this.isReportValid) this.createCommitmentLetter()
    else {
      this.showGlobalError().afterDismissed().subscribe((status) => { 
        if(status.value) this.createCommitmentLetter()
      });
    }
  }

  createCommitmentLetter(){
    this.builder
      .generateCommitment(
        this.reportDetails.Order_ID,
        this.reportDetails.Id,
        true
      )
      .subscribe(
        (data) => {
          this.previewCommitementLetter(
            data.updatePdfBase64,
            this.orderDetails.File_ID
          );
        },
        (err) => {
          let errMsgDisplay = "Failed to download Commitment Letter!";
          this.openErrorPopup(errMsgDisplay);
        }
      );
  }

  checkAndProceed(isPreview) {
    if(this.isReportValid) this.completeReport(isPreview)
    else {
      this.showGlobalError().afterDismissed().subscribe((status) => { 
        if(status.value) this.completeReport(isPreview)
      });
    }
   
  }

  completeReport(isPreview){
    this.builder
    .generateSearchPackage(
      this.reportDetails.Order_ID,
      this.reportDetails.Id,
      isPreview,
      (this.reportDetails.Title_Commitment_Status == 1 && this.reportDetails.Commit_Tmplt_Id && this.reportDetails.Commit_Tmplt_Id != environment.noCommitId)
    )
    .subscribe(
      (data) => {
        if (data.Link && isPreview) this.previewSearchPackage(data);
        if (!isPreview) {
          this.userMsg.setSuccessMsg("Report created successfully!");
          this.downloadSearchPackage(data[0].updatePdfBase64);
          if (data[1] && data[1].Link) this.composeAndDownloadCommitment(data[1].Link);
          this.builder.getAllVersions(this.reportDetails.Order_ID).subscribe();
        }
      },
      (err) => {
        if (err && err.hasOwnProperty("filename") && err.filename)
          this.openErrorPopup(err.msg);
        else this.openErrorPopup("Failed to preview search package!");
      }
    );
  }

  downloadSearchPackage(pdfData) {
    var blob = new Blob(this.getByteArray(pdfData), {
      type: "application/octet-stream",
    });
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.download = "searchPackage.pdf";
    link.href = window.URL.createObjectURL(blob);
    link.click();
    document.body.removeChild(link);
  }

  composeAndDownloadCommitment(S3Link) {
    var link = document.createElement("a");
    link.setAttribute("href", S3Link);
    link.setAttribute(
      "download",
      this.orderDetails.File_Id + " Commitment typing.docx"
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  previewSearchPackage(data) {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.target = "_blank";
    link.href = data.Link;
    link.click();
    document.body.removeChild(link);
  }

  previewCommitementLetter(base64Data, fileId) {
    var blob = new Blob(this.getByteArray(base64Data), {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.download = fileId + " Commitment typing.docx";
    link.href = window.URL.createObjectURL(blob);
    link.click();
    document.body.removeChild(link);
  }

  getByteArray(file) {
    var sliceSize = 512;

    var byteCharacters = atob(file);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    return byteArrays;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog
      .open(
        ErrorDialogComponent,
        {data:{
          message: msg,
        },
        ...this.config.getDialogOptions()}
      )
  }

  showGlobalError(){    
    let bottomBarRef : MatBottomSheetRef = this.bottomSheet.open(
      RbErrSnackbarComponent, {
        panelClass: 'pos-sheet'
      }
    );   
    return  bottomBarRef;
  }

  generateZipFile(){
    if(this.reportDetails.Id){
      this.builder.getReportFilesInZip(this.reportDetails.Id)
      .subscribe((zip) => {
        if(zip && zip.url){        
          var link = document.createElement("a");
          document.body.appendChild(link);
          link.href = zip.url;
          link.click();
          document.body.removeChild(link);
        }
        else this.openErrorPopup("Failed to generate zip file!");
      }, (err) => {
        this.openErrorPopup("Failed to generate zip file!");
      })
    }    
  }
}
