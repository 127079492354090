import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { OrganizationService } from '../../services/organization/organization.service';
import { AuthService } from '../../services/authentication/auth.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelect } from '@angular/material/select';
import { MatSelectSearchComponent } from 'app/common/mat-select-search/mat-select-search.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent{
  @ViewChild('matSearchFocus') matSearchFocus:MatSelectSearchComponent
  message: any = {};
  errorMsg: any = {};
  userList: any = [];
  selectedUser :any = '';
  timer: any = [];
  constructor(
    private organizationService: OrganizationService,
    private auth: AuthService,public dialogRef: MatDialogRef<SelectUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.message=this.data.message;
  }

  ngOnInit() {
    this.initDialog();
    if(this.message['userList'])this.userList = this.message['userList'];  
    this.timer.push(setTimeout(() => { this.matSearchFocus.matSelSearch.focus()}))
  }

  initDialog(){
    this.message = this.data.message;
  }
  

  selectedUserAction(selectedUserId) {
    let result:any = {
      state: true,
      Selected_User_ID: selectedUserId,
    }
    this.dialogRef.close(result);
    this.clearTime();
  }

  checkIfAdminAvailable(admin) {
    if (this.message['existingUserList']) {
      if (this.message['existingUserList'].findIndex(x => x.Admin_User_ID == admin.User_ID) > -1) {
        return false;
      }
      return true;
    }
    else return true;
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    this.clearTime();
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
