// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.variable-pane {
  border-radius: 6px;
  background: rgb(248, 249, 250);
  border: 1px solid #e4e4e4;
  padding: 1rem;
  font-size: smaller;
}`, "",{"version":3,"sources":["webpack://./src/app/email-template/email-template.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,8BAAA;EACA,yBAAA;EACA,aAAA;EACA,kBAAA;AACJ","sourcesContent":[".variable-pane{\n    border-radius: 6px;\n    background: rgb(248, 249, 250);\n    border: 1px solid #e4e4e4;\n    padding: 1rem;\n    font-size: smaller;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
