import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChange } from '@angular/core'

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnChanges {

  @Input() rating: any;
  @Input() size: any;
  num: number;
  decimal: any;
  halfIndex: number;
  reviewedOrders: number;

  constructor() { }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.calculate();
  }

  calculate() {
    if (this.rating) {
      if (this.rating.rating) {
        this.num = Math.round(this.rating.rating * 100) / 100;
        this.reviewedOrders = this.rating.orders;
        this.num = Math.floor(this.num);
        this.decimal = this.rating.rating - this.num;
        if (this.decimal < 0.2) this.decimal = 0.0;
        else if (this.decimal > 0.8) {
          this.decimal = 0.0;
          this.num++;
        }
        if (this.decimal != 0.0) this.halfIndex = this.num + 1;
      }
      else {
        if (this.rating.orders == null) this.reviewedOrders = 0;
        else this.reviewedOrders = this.rating.orders;
        this.num = 0;
      }
    }
  }

}
