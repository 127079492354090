import { Component, OnInit, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { DialogService } from "ng2-bootstrap-modal";
import { CONSTANTS } from '../../app.constants'
import { AuthService, NewOrderService, OrdersService, PricingService, ConfigService, ProductService, QuickbooksService, OrganizationService, UsermessageService } from '../../services';
import { EtaAlertComponent } from '../../dialog/eta-alert/eta-alert.component';
import { MatSelect } from '@angular/material/select';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { ErrorReportComponent } from '../../dialog/error-report/error-report.component';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component'

import { environment } from 'environments/environment';
import * as _ from 'underscore';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-order-pricing-edit',
  templateUrl: './order-pricing-edit.component.html',
  styleUrls: ['./order-pricing-edit.component.scss']
})
export class OrderPricingEditComponent implements OnInit {
  @ViewChildren('myFirstFocus') myFirstFocus: QueryList<MatSelect>
  @ViewChildren('subProductFocus') subProductFocus: QueryList<ElementRef>
  orderDetails: any = {}
  subOrderDetails:any ={};
  orderProducts: any = [];
  products: any = [];
  allProducts: any = [];
  allsubNBaseProducts = [];
  updateProducts = [];
  counties: any = [];
  error: any = {};
  defaultproducts = [];
  selectedCounty = '';
  states: any = [];
  selectedState = '';
  completeAddress: string = '';
  grandTotPrice = 0;
  constants = CONSTANTS;
  showErrMsg = '';
  dotEvent = [];
  invoiceUpdate: boolean = true;
  input = "";
  focused:boolean = false;    
  subProductFocusFlag:boolean = false;
  timer: any = [];
  isPricingFlag: boolean = false;
  copyOrderDetails: any;
  isTrackerProOrder: boolean = false;
  getOnLoadTotalPrice = 0;
  oldProds: any = [];
  orderProdctsSub = [];
  additionalProduct =[];
  additionProdArr =[];
  reason ='';
  venodorAmt= '';
  feeApproval: boolean = false;
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private orderService: OrdersService,
    private newOrder: NewOrderService,
    private pricingService: PricingService,
    private matDialog:MatDialog,
    private config: ConfigService,
    private productService: ProductService,
    private quickbooks: QuickbooksService,
    private organizationService : OrganizationService,
    private userMsg: UsermessageService,
  ) { }

  ngOnInit() {
    this.checkQuickBookSession();
    this.getOrderDetails(this.route.snapshot.params['orderId']);
    // this.getAvailableStateLocal();
    this.pricingService.getAvailableStateList().subscribe((states) => this.states = states);
    // this.orderService.getOrderProducts().subscribe((res) => this.products = res);
    this.newOrder.getSubProductList().subscribe((res) => this.allProducts = res);
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
  }

   ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

//   getAvailableStateLocal(){
//     let Allstates = JSON.parse(sessionStorage.getItem('AllStateAvailable'));
//     if(Allstates) this.setState(Allstates)         
//     else this.getAvailableStateList();
// }
  
//   getAvailableStateList(){
//     this.pricingService.getAvailableStateList().subscribe((states) => { 
//       if(states){
//         this.setState(states)         
//         sessionStorage.setItem('AllStateAvailable',JSON.stringify(states)); 
//        setTimeout(function(){sessionStorage.removeItem("AllStateAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });

//   }

//   setState(AllstatesData){
//     if(AllstatesData) this.states = AllstatesData;
//   }

  ngAfterViewInit(){ 
    this.myFirstFocus.changes.subscribe((select) => {
      if(select.first && !this.focused){ 
        this.timer.push(setTimeout(() => { select.first.focus() }, 0));
        this.focused = true;
      }
    });
    this.subProductFocus.changes.subscribe((input) => {
      if(input.last && !this.subProductFocusFlag) {
        this.subProductFocusFlag = true;
        this.timer.push(setTimeout(() => { input.last.nativeElement.focus() }, 0));
      }
    }); 
  }

  checkQuickBookSession() {
    if (this.quickbooks.getSharedSessionData()) {
      if(this.quickbooks.getSharedSessionData().url == '/edit-order-pricing/' + this.route.snapshot.params['orderId']){         
        switch(this.quickbooks.getSharedSessionData().continue){
          case 'edit_order_pricing': this.makeEditRequest(this.quickbooks.getSharedSessionData().data); break;          
          default : break;
        }   
      }     
    }
  }

  getOrderDetails(orderId) {
    this.orderService.getOrderDetails(orderId)
      .subscribe((details) => {
        this.orderDetails = details;
        this.copyOrderDetails = Object.assign({}, details); 
        console.log(this.orderDetails);
        if (!this.orderDetails.Property_State_Abbr) this.orderDetails.Property_State_Abbr = '';
        if (!this.orderDetails.Property_County) this.orderDetails.Property_County = '';
        this.getOrderProducts(orderId);
        this.buildAddress();
        this.resetMDFields();
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        // this.auth.logout();
        // this.router.navigate(['/login']);
      });
  }
  
  getOrderProductsForClient(orderDetails, orderProducts) {
    let userId; 
    if(orderDetails.Organization_ID) userId = orderDetails.Organization_ID
    else userId = orderDetails.Customer_ID;
    this.orderService.getOrderProductsForClient(userId)
    .subscribe((products) => {
      if(products.some((product) => product.Product_ID == orderDetails.Product_ID && product.Product_Description === orderDetails.Product_Description)) this.products = products;
      else if(products.some((product) => product.Product_ID == orderProducts[0].Product_ID && product.Product_Description !==  orderProducts[0].Product_Description)){
        // let clonedArray = JSON.parse(JSON.stringify(products));
        // let productFindIndex = products.findIndex((product) => product.Product_ID == orderProducts[0].Product_ID)
        // products[productFindIndex].Product_Description = orderProducts[0].Product_Description;
        this.products = products;
        // let clonedFindIndex = clonedArray.findIndex((product) => product.Product_ID === orderProducts[0].Product_ID);
        // this.products.splice(clonedFindIndex+1, 0, clonedArray[clonedFindIndex]);        
      } else {
        this.productService.getProduct(orderDetails.Product_ID)
        .subscribe((missedProducts) => {
          // products.push(missedProducts);
          this.products = products;
        })
      }
      this.getBaseProduct().forEach((orderProduct) => {
        orderProduct.isUpdated = this.checkIfOrderProductUpdatedBase(orderProduct);
        if(orderProduct.isUpdated){
          let randomID = this.randomIntFromInterval(6666, 7777);
          orderProduct.Old_Product_ID = orderProduct.Product_ID;
          orderProduct.Product_ID = randomID;
          orderProduct.Copy_Product_ID = randomID;
        }        
      })
    })
}

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  getOrderProducts(orderId) {
    if (!this.orderDetails.Order_Custom_Price_Tax) this.orderDetails.Order_Custom_Price_Tax = 0;
    this.orderService.getCustomerOrderProducts(orderId)
      .subscribe((products) => {
        this.oldProds = products.map((_arrayElement) => Object.assign({}, _arrayElement));  
        this.getTotalPriceOnLoad(products,this.orderDetails)
        this.orderProducts = products;
        this.getOrderProductsForClient(this.orderDetails, this.getBaseProduct())        
        this.getAllsubNBaseProd(this.route.snapshot.params['orderId']);
        this.orderProducts.map((newProd) => newProd.currInput = '');
        this.orderProducts.map((newProd) => newProd.currBaseInput = '');
        this.addDefaultsToOrderProducts(this.getDefaultProducts());
        this.calculateTotal();
      }, (err) => {
        console.log(err);
      })
  }

  getTotalPriceOnLoad(products,orderDetails){
    products.forEach((prod)=>{
      if(prod.Order_Product_Price) this.getOnLoadTotalPrice += prod.Order_Product_Price
    })
    if(orderDetails.Order_Custom_Price_Tax) this.getOnLoadTotalPrice += orderDetails.Order_Custom_Price_Tax
    this.getOnLoadTotalPrice = Number(this.getOnLoadTotalPrice.toFixed(2))
  }
  buildAddress() {
    this.completeAddress = '';
    if (this.orderDetails.Property_Address_1) this.completeAddress += this.orderDetails.Property_Address_1
    if (this.orderDetails.Property_Address_2) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += this.orderDetails.Property_Address_2
    }
    if (this.orderDetails.Property_City) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += this.orderDetails.Property_City
    }
    if (this.orderDetails.Property_State_Abbr) {
      if (this.completeAddress) this.completeAddress += ', '
      this.completeAddress += this.orderDetails.Property_State_Abbr
    }
    if (this.orderDetails.Property_ZipCode) {
      if (this.completeAddress) this.completeAddress += ' '
      this.completeAddress += this.orderDetails.Property_ZipCode
    }
  }

  resetMDFields() {
    document.getElementById('Order_Number').parentElement.classList.remove('is-empty');
    document.getElementById('Total_Price').parentElement.classList.remove('is-empty');
    document.getElementById('Total_Tax_Price').parentElement.classList.remove('is-empty');
  }

  resetPriceFields(fieldId) {
    if (document.getElementById('Product_Price_' + fieldId)) {
      document.getElementById('Product_Price_' + fieldId).parentElement.classList.remove('is-empty');
      document.getElementById('Final_Price_' + fieldId).parentElement.classList.remove('is-empty');
    }
  }

  getCountyList(event) {
    console.log("Event", event);
    if (event.length != 0) {
      this.orderDetails.Property_County = '';
      this.orderDetails.BaseProduct = 0;
      this.pricingService.getAvailableCountyList(this.orderDetails.Property_State_Abbr).subscribe((res) => this.counties = res)
    }
  }

  getBaseProduct() {
    if (this.orderProducts.length) return this.orderProducts.filter((product) => product.Order_Product_Status == CONSTANTS.selectProductStatus.baseProduct)
    return [];
  }

  getSubProducts() {
    if (this.orderProducts.length) return this.orderProducts.filter((product) => product.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct);
    return [];
  }

  getDefaultProducts() {
    if (this.orderProducts.length) return this.orderProducts.filter((product) => product.Product_ID == environment.copyChargeProductId);
    return [];
  }


  handleManualProductChange(event, orderProduct){
    if(event) {
      orderProduct.Product_ID = event.value.Product_ID;
      orderProduct.Order_Product_Custom_Input_Description = event.value.Product_Description;
      this.getProductPricingDetails(event.value.Product_ID, orderProduct,  event.value.Product_Description)
    }
  }

  getProductPricingDetails(event, product, productDesciption = null) {
    if (!product) var product = this.orderProducts[0];
    else product.Product_ID = event;
    if (this.orderDetails.Property_County && this.orderDetails.Property_State_Abbr) {
      let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID : this.orderDetails.Customer_ID;
      this.pricingService.getCustomAndOrginalPriceDetails(this.orderDetails.Property_State_Abbr,
        this.orderDetails.Property_County, product.Product_ID, customerId)
        .subscribe((data) => {
          console.log("PRICE: ", data);
          product.Order_Product_Original_Price = 0;
          product.Order_Product_Price = 0;
          if (data.Original_Cost) {
            product.Order_Product_Original_Price = data.Original_Cost.Cost_Amount;
            product.Order_Product_Price = data.Original_Cost.Cost_Amount;
            if (data.Custom_Cost) product.Order_Product_Price = data.Custom_Cost.Custom_Cost_Amount;
          }
          this.resetProductPrice(product);
          if(productDesciption) this.updateProductDescription(product, productDesciption);
          this.calculateTotal();
          this.resetPriceFields(product.Order_Product_ID);
        })
    }
  }

  updateProductDescription(orderProduct, productDesciption) {
    if(this.copyOrderDetails.Product_Description !== productDesciption) {
      let productFindIndex = this.products.findIndex((product) =>{
        return product.Product_ID === orderProduct.Product_ID
      });
      orderProduct.Product_Description = productDesciption
      orderProduct.Product_Name = this.products[productFindIndex].Product_Name;
    } else {
      let productFindIndex = this.products.findIndex((product) => product.Product_ID === orderProduct.Product_ID);
      orderProduct.Product_Description = this.copyOrderDetails.Product_Description;
      orderProduct.Product_Name = this.products[productFindIndex].Product_Name;
    }
  }

  resetProductPrice(product){
    if(product.currInput)
    product.currInput = ''
  }

  removeSubProduct(product) {
    product.Order_Product_Flag = CONSTANTS.orderProductFlag.inActive;
    product.Order_Product_Price = 0;
    this.removeByOrderProductId(product.Order_Product_ID);
    this.calculateTotal();
    this.removeDeletedfieldEvent(product);
    this.assignSubProductFocus();
  }

  removeByOrderProductId(orderProductId) {
    var i = this.orderProducts.length;
    while (i--) {
      if (this.orderProducts[i]
        && this.orderProducts[i].hasOwnProperty('Order_Product_ID')
        && (this.orderProducts[i]['Order_Product_ID'] === orderProductId)) {
          this.additionalProduct.push(this.orderProducts[i])
          this.orderProducts.splice(i, 1);
      }
    }
    this.calculateTotal();
    return this.orderProducts;
  }


  removeDeletedfieldEvent(orderProduct) {
    var orderProductId = orderProduct.Order_Product_ID
    var finalpriceFieldId = "Final_Price_" + orderProductId;
    var matchedFinalEventIndex = this.dotEvent.findIndex((eventItem) => eventItem.ID == finalpriceFieldId);
    if (matchedFinalEventIndex > -1) this.dotEvent.splice(matchedFinalEventIndex, 1);
    if (orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
      var basepriceFieldId = "EditPricingInpBasePrc" + orderProductId;
      var matchedBaseEventIndex = this.dotEvent.findIndex((eventItem) => eventItem.ID == basepriceFieldId);
      if (matchedBaseEventIndex > -1) this.dotEvent.splice(matchedBaseEventIndex, 1);
    }
  }

  calculateTotal() {
    this.orderDetails.Order_Final_Price = 0;
    this.orderDetails.Order_Custom_Price = 0;
    this.orderProducts.forEach((product) => {
      if (product.Order_Product_Flag == CONSTANTS.orderProductFlag.active) {
        if (!isNaN(Number(product.Order_Product_Original_Price)))
          this.orderDetails.Order_Final_Price = Number((Number(this.orderDetails.Order_Final_Price) + Number(product.Order_Product_Original_Price)).toFixed(2));
        else this.orderDetails.Order_Final_Price = Number(this.orderDetails.Order_Final_Price.toFixed(2));
        if (!isNaN(Number(product.Order_Product_Price)))
          this.orderDetails.Order_Custom_Price = Number((Number(this.orderDetails.Order_Custom_Price) + Number(product.Order_Product_Price)).toFixed(2));
        else this.orderDetails.Order_Custom_Price = Number(this.orderDetails.Order_Custom_Price.toFixed(2));
      }
    })
    this.calculateGrantTotal();
  }

  validateFinalPricingFields() {
    if (!this.grandTotPrice || (this.orderDetails.Order_Custom_Price_Tax == null || this.orderDetails.Order_Custom_Price_Tax == undefined || this.orderDetails.Order_Custom_Price_Tax == '')
      || !this.validateProductPricingFields()) return false;
    else return true;
  }

  validateProductPricingFields() {
    // var flag = 0;
    // this.orderProducts.forEach((orderProduct) => {
    //   if (orderProduct.Order_Product_Price == null || orderProduct.Order_Product_Price == undefined || orderProduct.Order_Product_Price == '') flag++;
    // })
    // console.log("flag", flag);
    // if (flag == 0) return true;
    // else return false;
    if (this.orderProducts.findIndex(orderProduct => isNaN(parseInt(orderProduct.Order_Product_Price)) && orderProduct.Order_Product_Status == 1) > -1) return false;
    else return true;
  }

  calculateGrantTotal() {
    if (!isNaN(Number(this.orderDetails.Order_Custom_Price_Tax)))
      this.grandTotPrice = Number((Number(this.orderDetails.Order_Custom_Price) + Number(this.orderDetails.Order_Custom_Price_Tax)).toFixed(2));
    else this.grandTotPrice = Number(this.orderDetails.Order_Custom_Price.toFixed(2));

  }

  addproduct() {
    var newProd: any = {};
    newProd.Order_Product_ID = 'new' + UUID.UUID();
    newProd.Order_ID = this.orderDetails.Order_ID;
    newProd.Order_Product_Custom_Input_Description = '';
    newProd.Order_Product_Original_Price = null;
    newProd.Order_Product_Flag = CONSTANTS.orderProductFlag.active;
    newProd.Order_Product_Status = CONSTANTS.selectProductStatus.subproduct;
    newProd.Order_Product_Price = "0.00";
    newProd.currInput = ''
    newProd.currBaseInput = ''
    this.orderProducts.push(newProd);
    this.calculateTotal();
    this.assignSubProductFocus();
  }

  backToListing() {
    this.location.back();
  }

  editOrderPricing() {
    if (this.orderDetails.Order_Custom_Price) {
      this.checkIfTrackerProOrderAndCntinue();
    } else
    {
      let disposable = this.matDialog.open(EtaAlertComponent, {data:{
        title: 'Order Pricing',
        message: 'Grand total is $0. Do you still want to continue with this price?',
        // message: 'Would you like to confirm this change in price from ' + '$'+this.getOnLoadTotalPrice + ' to '+ '$'+this.grandTotPrice +' ?',
        value: 'Order Pricing'
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res) => {
          if (res) this.checkIfTrackerProOrderAndCntinue()
        });
    }

  }

  editedOrder() {
   if(this.grandTotPrice < this.getOnLoadTotalPrice && this.grandTotPrice > this.orderProducts[0].Order_Product_Original_Price) this.editedOrderMapping()
   else if(this.grandTotPrice == this.getOnLoadTotalPrice || this.grandTotPrice == this.orderProducts[0].Order_Product_Original_Price)this.editedOrderMapping()
   else if(this.grandTotPrice < this.getOnLoadTotalPrice){
    console.log(this.grandTotPrice, this.getOnLoadTotalPrice)
        let disposable = this.matDialog.open(EtaAlertComponent, {data:{
          title: 'Order Pricing',
          message: 'Would you like to confirm this change in price from ' + '$'+this.getOnLoadTotalPrice + ' to '+ '$'+this.grandTotPrice +' ?',
          value: 'Yes',
          cancelValue: 'No'
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if(res) this.editedOrderMapping()
          });
    }
    else if(this.grandTotPrice > this.getOnLoadTotalPrice){
      console.log(this.grandTotPrice, this.getOnLoadTotalPrice,'wejkhrfwehru')
      let disposable = this.matDialog.open(EtaAlertComponent, {data:{
        title: 'Order Pricing',
        message: 'Would you like to request fee approval for increased price from ' + '$'+this.getOnLoadTotalPrice + ' to '+ '$'+this.grandTotPrice +' ?',
        value: 'Yes',
        cancelValue: 'Cancel',
        updateValue : 'Update Anyway'
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res) => {
          if(res && res == 'updateAnyway'){
            this.feeApproval = false;
            this.editedOrderMapping()
          } 
          if(res && res == true) this.feeChangeReason(res)
        });

    }
  }

  feeChangeReason(res){
    this.feeApproval = res;
    this.openErrorReportDialog().afterClosed()
    .subscribe((res)=>{
      if(res && res.state){
        this.reason = res.comment
        this.venodorAmt = res.vendorAmt
         this.processFeeApproch(res)
        };
    })
  }

processFeeApproch(res){
this.orderService.storeFeeResonOnHistory(res, this.orderDetails, this.feeApproval)
.subscribe((resData)=>{
  this.userMsg.setSuccessMsg("Fee approval request sent to slack");
 if(res) this.editedOrderMapping()
})
}

  openErrorReportDialog() {
    return this.matDialog.open(ErrorReportComponent, {data:{
       message: { Order_ID: this.route.snapshot.params['orderId'], isAbstractorAssigned : this.orderDetails.Abstrator_User_ID != null },
        value: 'Yes', },
         ...this.config.getDialogOptions()})
  }

  // editedOrder() {
  //   this.orderProducts.map((orderProduct) => {
  //     delete orderProduct.currInput;
  //     delete orderProduct.currBaseInput;
  //   });
  //   var data = {
  //     Order_Details: this.orderDetails,
  //     Order_Products: this.orderProducts,
  //     Admin_User_ID: this.auth.getUserId(),
  //     Invoice_Update: this.invoiceUpdate,
  //     Is_TrackerPro_Order: this.isTrackerProOrder
  //   }
  //   this.removeUnusedProductPricings();
  //   this.makeEditRequest(data);
  // }

  editedOrderMapping(){
    // this.orderProducts.map((orderProduct) => { //commented unwanted code causing error
    //   delete orderProduct.currInput;
    //   delete orderProduct.currBaseInput;
    // });
    if(this.additionalProduct.length > 0){
      this.additionProdArr = this.additionalProduct.filter((prod)=> prod.hasOwnProperty('Invoice_ID'))
    }
    var data = {
      Order_Details: this.orderDetails,
      Order_Products: this.orderProducts,
      Admin_User_ID: this.auth.getUserId(),
      Invoice_Update: this.invoiceUpdate,
      Is_TrackerPro_Order: this.isTrackerProOrder,
      Order_OldProducts: this.oldProds,
      Reason: this.reason,
      VendorFee:this.venodorAmt,
      OldOrder_Details: this.copyOrderDetails,
      Is_FeeApproval: this.feeApproval,
      additionalProduct : this.additionProdArr
    }
    this.removeUnusedProductPricings();
    this.removeTempProdIds();
    this.makeEditRequest(data);
  }

  // checkWestFieldBankPriceCap(){
  //   if(this.orderDetails.Organization_ID == environment.westFieldBank){
  //     if(Number(this.auth.getUserRole()) == this.constants.userRole.compAdmin || Number(this.auth.getUserRole()) == this.constants.userRole.superAdmin){
  //       return false;
  //     }
  //     else {
  //       if((this.orderDetails.Order_Custom_Price + this.orderDetails.Order_Custom_Price_Tax) > 190){
  //         return true;          
  //       }
  //       else return false;
  //     }
  //   }
  //   else return false;
  // }

  makeEditRequest(data){
    // if(this.checkWestFieldBankPriceCap()){
    //   this.openErrorPopup("Grand total price of the Westfield Bank order cannot be more than $190.");
    // }
    // else{
      if(data.additionalProduct.length > 0){
        data.Order_Products.push(data.additionalProduct)
        data.Order_Products = _.flatten(data.Order_Products);
      }
      this.orderService.editOrderPricing(data, this.route.snapshot.params['orderId'])
      .subscribe((res) => {
        console.log(res);
        if(res)this.router.navigate(['/orders/' + this.route.snapshot.params['orderId'] + '/view'])
      }, (err) => {
        console.log(err);
        this.error = err;
      })
    // }
  }

  removeUnusedProductPricings() {
    this.orderProducts.forEach((orderProduct) => {
      if (isNaN(parseInt(orderProduct.Order_Product_Price))) orderProduct.Order_Product_Price = 0;
    })
  }

  addDefaultsToOrderProducts(defaultOrdProducts) {
    this.productService.getProduct(environment.copyChargeProductId)
      .subscribe((defaults) => {
        if (defaults) {
          let defProductData = [];
          defProductData.push(defaults);
          if(defaultOrdProducts && defaultOrdProducts.length > 0) defProductData[0].Product_Description = defaultOrdProducts[0].Product_Description;
          this.defaultproducts = defProductData;
          defProductData.forEach((item) => {
            if (this.orderProducts.findIndex(x => x.Product_ID == item.Product_ID) == -1)
              this.orderProducts.push(this.prepareOrderProduct(item));
          });
          this.orderProducts.forEach((product) => {
            if (!product.Order_Product_Price && (product.Product_ID != 7)) this.getProductPricingDetails(product.Product_ID, product)
            if (!product.Order_Product_Price && (product.Product_ID == 7)) this.getCopyChargesPricing(product.Product_ID, product)
          })
          this.calculateTotal();
        }
      })
  }

  getCopyChargesPricing(productId, orderProduct){
    if (this.orderDetails.Property_County && this.orderDetails.Property_State_Abbr && productId) {
      let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID : this.orderDetails.Customer_ID;
      this.pricingService.getCustomAndOrginalPriceDetails(this.orderDetails.Property_State_Abbr,
        this.orderDetails.Property_County, productId, customerId)
        .subscribe((copyResponse) => {
        if (copyResponse.Original_Cost) {
          this.isPricingFlag = true;
          orderProduct.Order_Product_Original_Price = copyResponse.Original_Cost.Cost_Amount;
          // orderProduct.Order_Product_Price = copyResponse.Original_Cost.Cost_Amount;
          // if (copyResponse.Custom_Cost) orderProduct.Order_Product_Price = copyResponse.Custom_Cost.Custom_Cost_Amount;
        }else {
          this.isPricingFlag = false;
          orderProduct.Order_Product_Price = 0;
        }
        this.calculateTotal();
        })
      }
  }

    // if (data.Original_Cost) {
  //   
  //   product.Order_Product_Price = data.Original_Cost.Cost_Amount;
  //   if (data.Custom_Cost) product.Order_Product_Price = data.Custom_Cost.Custom_Cost_Amount;
  // }
  prepareOrderProduct(product) {
    return {
      Order_Product_ID: 'new' + UUID.UUID(),
      Order_ID: this.orderDetails.Order_ID,
      Product_ID: product.Product_ID,
      Order_Product_Status: CONSTANTS.selectProductStatus.defaultProduct,
      Order_Product_Flag: CONSTANTS.orderProductFlag.active,
      Order_Product_Price: 0,
      Order_Product_Original_Price: 0,
      Product_Name: product.Product_Name,
      Product_Description: product.Product_Description,
      currInput: '',
      currBaseInput: ''
    }
  }

  checkForActiveProducts() {
    var activeProducts = this.getSubProducts().filter((product) => product.Order_Product_Flag == CONSTANTS.orderProductFlag.active);
    if (activeProducts.length > 0) return true;
    return false;
  }

  isSubProductsAvailable() {
    if (this.getSubProducts().length != 0 && this.checkForActiveProducts()) return true;
    else return false;
  }
  validatePrice() {
    if (this.orderProducts) {
      if (this.orderProducts.findIndex(orderProduct => (isNaN(parseInt(orderProduct.Order_Product_Price)) && ((orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) || (orderProduct.Product_ID == environment.copyChargeProductId)) && (orderProduct.Order_Product_Flag == CONSTANTS.orderProductFlag.active))) > -1) return true;
      else return false;
    }
  }

  validateGrandTotal(){
    if(this.grandTotPrice<0) return false;
    return true;
  }
  validateSearchDesc() {
    if (this.orderProducts) {
      if (this.orderProducts.findIndex(orderProduct => (orderProduct.Product_ID == 999) && ((orderProduct.Order_Product_Custom_Name == undefined) || (orderProduct.Order_Product_Custom_Name == '')))) return true;
      else return false;
    }
  }
  validateCustomInputDesc() {
    if (this.orderProducts) {
      var flag = 0;
      this.orderProducts.forEach(orderProduct => {
        if (orderProduct.Order_Product_Flag == CONSTANTS.orderProductFlag.active && orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
          if((orderProduct.Product_ID == '' || orderProduct.Product_ID == undefined) || (orderProduct.Order_Product_Custom_Name == '' || orderProduct.Order_Product_Custom_Name == undefined)){
            flag++;
          }
          if (orderProduct.Order_Product_Custom_Name) {
            if (orderProduct.Order_Product_Custom_Name.indexOf(' ') >= 0) {
              if (orderProduct.Order_Product_Custom_Name.trim().length == 0) flag++;
            }
          }
        }
      })
      if (flag == 0) return false;
      else return true;
    }
    else return false;
  }

  // getNumberFormat(e, orderProduct) {
  //   if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
  //   if ((orderProduct.currInput.length == 0 && e.key == "0")) e.preventDefault();
  //   else {
  //     if ((e.keyCode == 8 || e.keyCode == 46) && (orderProduct.currInput.length > 0 || orderProduct.currInput.length < this.config.getLimitPriceMaxLength())) {
  //       orderProduct.currInput = orderProduct.currInput.slice(0, orderProduct.currInput.length - 1);
  //       e.target.value = this.numberFormat(orderProduct.currInput);
  //       orderProduct.Order_Product_Price = e.target.value;
  //     }
  //     else if (orderProduct.currInput.length < this.config.getLimitPriceMaxLength()) {
  //       var key = this.keyValue(e.keyCode);
  //       if (key) {
  //         orderProduct.currInput = orderProduct.currInput + key;
  //         e.target.value = this.numberFormat(orderProduct.currInput);
  //         orderProduct.Order_Product_Price = e.target.value;
  //       }
  //     }
  //     this.calculateTotal();
  //     return false;
  //   }
  // }

  getNumberFormat(e, orderProduct) {
    const key = e.key;
    const isArrowOrTab = ['ArrowLeft', 'ArrowRight', 'Tab'].includes(key);
    const isBackspaceOrDelete = key === 'Backspace' || key === 'Delete';
    const isMinus = key === '-';
    const maxLength = this.config.getLimitPriceMaxLength();
    
    if (isArrowOrTab) return true;
    
    if (orderProduct.currInput.length === 0 && (key === '0' || isMinus)) {
        if (key === '0') {
            e.preventDefault();
        } else if (isMinus) {
            orderProduct.currInput = '-';
            e.target.value = this.numberFormat(orderProduct.currInput);
            orderProduct.Order_Product_Price = e.target.value;
        }
        return;
    }

    const updateInput = (newValue) => {
        orderProduct.currInput = newValue;
        e.target.value = this.numberFormat(orderProduct.currInput);
        orderProduct.Order_Product_Price = e.target.value;
    };
    
    if (isBackspaceOrDelete && orderProduct.currInput.length > 0 && orderProduct.currInput.length < maxLength) {
        updateInput(orderProduct.currInput.slice(0, -1));
    } else if (orderProduct.currInput.length < maxLength) {
        const keyValue = this.keyValue(e.keyCode);
        if (keyValue) {
            if (orderProduct.currInput.includes('-') && orderProduct.currInput.length === 1 && keyValue === '-') {
                e.preventDefault();
                return;
            }
            updateInput(orderProduct.currInput + keyValue);
        }
    }
    
    this.calculateTotal();
    return false;
}

  getBasePriceFormat(e, orderProduct) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((orderProduct.currBaseInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (orderProduct.currBaseInput.length > 0 || orderProduct.currBaseInput.length < this.config.getLimitPriceMaxLength())) {
        orderProduct.currBaseInput = orderProduct.currBaseInput.slice(0, orderProduct.currBaseInput.length - 1);
        e.target.value = this.numberBaseFormat(orderProduct.currBaseInput);
        if (e.target.value == '')
          orderProduct.Order_Product_Original_Price = null;
        else
          orderProduct.Order_Product_Original_Price = e.target.value;
      }
      else if (orderProduct.currBaseInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          orderProduct.currBaseInput = orderProduct.currBaseInput + key;
          e.target.value = this.numberBaseFormat(orderProduct.currBaseInput);
          orderProduct.Order_Product_Original_Price = e.target.value;
        }
      }
      this.calculateTotal();
      return e.preventDefault();
    }
  }

  getTaxFormat(e, orderDetails) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((this.input.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.input.length > 0 || this.input.length < this.config.getLimitPriceMaxLength())) {
        this.input = this.input.slice(0, this.input.length - 1);
        e.target.value = this.numberFormat(this.input);
        orderDetails.Order_Custom_Price_Tax = e.target.value;
      }
      else if (this.input.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.input = this.input + key;
          e.target.value = this.numberFormat(this.input);
          orderDetails.Order_Custom_Price_Tax = e.target.value;
        }
      }
      this.calculateGrantTotal()
      return false;
    }
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return "0.00";
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  numberBaseFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  assignSubProductFocus(){ 
    this.subProductFocusFlag = false;
  }

  showBasePriceFlag(orderProduct) {
    if(this.isPricingFlag) return true;
    else {
      if(orderProduct && orderProduct.Order_Product_Original_Price) return true;
      else return false;
    }
  }

  checkIfTrackerProOrderAndCntinue() {
    if (!this.orderDetails.Organization_ID) {
      this.isTrackerProOrder = false;
      this.editedOrder();
    }
    else {
      this.organizationService.getOrganizationDetails(this.orderDetails.Organization_ID)
        .subscribe((orgData) => {
          if (orgData.Organization_Type == 2) this.isTrackerProOrder = true;
          else this.isTrackerProOrder = false;
           this.editedOrder();
        })
    }
  }


  getAllsubNBaseProd(orderId){
    this.orderService.getOrderDetails(orderId)
    .subscribe((details) => {
    this.subOrderDetails = details;
    let customerId = this.subOrderDetails.Organization_ID ? this.subOrderDetails.Organization_ID:  this.subOrderDetails.Customer_ID;
    this.orderService.getSubNBaseProduct(customerId)
    .subscribe((res)=>{
      this.allsubNBaseProducts = res;
      this.getSubProducts().forEach((orderProduct) => {
        orderProduct.isUpdated = this.checkIfOrderProductUpdatedSub(orderProduct);
        if(orderProduct.isUpdated) {
          let randomID = this.randomIntFromInterval(8888, 9999);
          orderProduct.Old_Product_ID = orderProduct.Product_ID;
          orderProduct.Product_ID = randomID;
          orderProduct.Copy_Product_ID = randomID;
        }
      })
    })
    })
   }
 
    

  checkAddProdDesc(){
    if (this.orderProducts){
    var flag = 0;
    this.orderProducts.forEach(orderProduct => {
      if (orderProduct.Order_Product_Flag == CONSTANTS.orderProductFlag.active && orderProduct.Order_Product_Status == CONSTANTS.selectProductStatus.subproduct) {
          if(!orderProduct.hasOwnProperty('Product_ID')){
            flag++;
          }
          else if(orderProduct.hasOwnProperty('Product_ID')){
            if(orderProduct.Product_ID === 999){
              if((orderProduct.Order_Product_Custom_Name == undefined) || (orderProduct.Order_Product_Custom_Name == '')){
                flag++;
              }
            }
          }
        }
      })
      if (flag == 0) return false;
          else return true;
    }
    else return false;
  }


  handleManualSubProductChange(event, orderProduct){
    if(event) {
      orderProduct.Product_ID = event.value.Product_ID;
      orderProduct.Order_Product_Custom_Input_Description = event.value.Product_Description;
      this.getSubProductPricingDetails(event.value.Product_ID, orderProduct,  event.value.Product_Description)
    }
  }

  getSubProductPricingDetails(event, product, productDesciption = null) {
    if (!product) var product = this.orderProducts[0];
    else product.Product_ID = event;
    if (this.orderDetails.Property_County && this.orderDetails.Property_State_Abbr) {
      let customerId = this.orderDetails.Organization_ID ? this.orderDetails.Organization_ID : this.orderDetails.Customer_ID;
      this.pricingService.getCustomAndOrginalPriceDetails(this.orderDetails.Property_State_Abbr,
        this.orderDetails.Property_County, product.Product_ID, customerId)
        .subscribe((data) => {
          console.log("PRICE: ", data);
          product.Order_Product_Original_Price = 0;
          product.Order_Product_Price = 0;
          if (data.Original_Cost) {
            product.Order_Product_Original_Price = data.Original_Cost.Cost_Amount;
            product.Order_Product_Price = data.Original_Cost.Cost_Amount;
            if (data.Custom_Cost) product.Order_Product_Price = data.Custom_Cost.Custom_Cost_Amount;
          }
          this.resetProductPrice(product);
          if(productDesciption) this.updateSubProductDescription(product, productDesciption);
          this.calculateTotal();
          this.resetPriceFields(product.Order_Product_ID);
        })
    }
  }

  updateSubProductDescription(orderProduct, productDesciption) {
    this.orderService.getAllOrderProducts()
    .subscribe((res)=>{
      this.updateProducts = res;
      if(this.copyOrderDetails.Product_Description !== productDesciption) {
        let productFindIndex = this.updateProducts.findIndex((product) =>{
          return product.Product_ID === orderProduct.Product_ID
        });
        orderProduct.Product_Description = productDesciption
        orderProduct.Product_Name = this.updateProducts[productFindIndex].Product_Name;
      } else {
        let productFindIndex = this.updateProducts.findIndex((product) => product.Product_ID === orderProduct.Product_ID);
        orderProduct.Product_Description = this.copyOrderDetails.Product_Description;
        orderProduct.Product_Name = this.updateProducts[productFindIndex].Product_Name;
      }
    })
  }

  checkIfOrderProductUpdatedBase(orderProduct) {
    let isUpdated = false;
    if (orderProduct.Product_ID) {
      if (
        orderProduct.Order_Product_Status ==
        CONSTANTS.selectProductStatus.baseProduct
      ) {
        let matchIndex = this.products.findIndex(
          (product) => product.Product_ID == orderProduct.Product_ID
        );
        if(matchIndex > -1){          
          let matchedProduct = this.products[matchIndex];
          if (
            (orderProduct.Order_Product_Custom_Input_Description && matchedProduct.Product_Description.toLowerCase() ==
              orderProduct.Order_Product_Custom_Input_Description.toLowerCase()) ||
            (orderProduct.Order_Product_Custom_Name && matchedProduct.Product_Description.toLowerCase() ==
              orderProduct.Order_Product_Custom_Name.toLowerCase())
          )
            isUpdated = false;
          else isUpdated = true;
        }
        else isUpdated = true;         
      }
    }
    return isUpdated;
  }

  checkIfOrderProductUpdatedSub(orderProduct) {
    let isUpdated = false;
    if (orderProduct.Product_ID) {
      if (
        orderProduct.Order_Product_Status ==
        CONSTANTS.selectProductStatus.subproduct
      ) {
        let matchIndex = this.allsubNBaseProducts.findIndex(
          (product) => product.Product_ID == orderProduct.Product_ID
        );
        if(matchIndex > -1) {
          let matchedProduct = this.allsubNBaseProducts[matchIndex];
          if (
            (orderProduct.Order_Product_Custom_Input_Description && matchedProduct.Product_Description.toLowerCase() ==
              orderProduct.Order_Product_Custom_Input_Description.toLowerCase() )||
            (orderProduct.Order_Product_Custom_Name && matchedProduct.Product_Description.toLowerCase() ==
              orderProduct.Order_Product_Custom_Name.toLowerCase())
          )
            isUpdated = false;
          else isUpdated = true;
        }   
        else isUpdated = true;     
      }
    }
    return isUpdated;
  }

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  removeTempProdIds(){
    this.orderProducts.forEach((orderProduct) => {
      if(orderProduct.isUpdated){
        if(orderProduct.Product_ID == orderProduct.Copy_Product_ID) {
          orderProduct.Product_ID = orderProduct.Old_Product_ID;         
        }
        delete orderProduct.Copy_Product_ID;
        delete orderProduct.Old_Product_ID;
      }
    })
  }

  getProductDisplayLabelBase(orderProduct){
    if(orderProduct.Order_Product_Custom_Input_Description){
      if(this.products.some((product) => 
        product.Product_Description.toLowerCase() == orderProduct.Order_Product_Custom_Input_Description.toLowerCase()
      )) return 'Item Product';
      else return orderProduct.Order_Product_Custom_Input_Description;
    }
    else return 'Item Product'
  }

  getProductDisplayLabelSub(orderProduct){
    if(orderProduct.Order_Product_Custom_Input_Description){
      if(this.allsubNBaseProducts.some((product) => 
        product.Product_Description.toLowerCase() == orderProduct.Order_Product_Custom_Input_Description.toLowerCase()
      )) return 'Item Product';
      else return orderProduct.Order_Product_Custom_Input_Description; 
    }
    else return 'Item Product'
  }
}