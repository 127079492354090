import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Validators, UntypedFormBuilder } from "@angular/forms";
import {
  AuthService,
  ConfigService,
  UsermessageService,
  EmailTemplatesService,
  ProductService,
} from "../services";
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../dialog/success/success.component';
import { ErrorDialogComponent } from '../dialog/error-dialog/error-dialog.component';
import { CONSTANTS } from '../app.constants';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: "app-email-template",
  templateUrl: "./email-template.component.html",
  styleUrls: ["./email-template.component.scss"],
})
export class EmailTemplateComponent implements OnInit {
  @ViewChild("namefocus") namefocus: ElementRef;
  emailTemplateForm: any = {};
  emailTemplateModel: any = {};
  templateId: string = "";
  error: any = {};
  errors: any = {};
  errorMsg: any;
  constants = CONSTANTS;
  timer: any = [];
  mappedProduct: any = {};
  products: any = [];
  tinyOptions: any = {
    auto_focus: false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"],
    branding:false,
    promotion :false,
    skin:'oxide',
    menubar:false,
    toolbar:
      "undo redo | styleselect | forecolor backcolor |  bold italic |  alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
  };
  variables = [
    { name: "[Abstractor Name]", value: "abc xyz Inc." },
    { name: "[Fee]", value: "$34.00" },
    { name: "<orderid>", value: "222222" },
    // { name: "<propertyownername>", value: "Fraser Paul" },
    { name: "<sellersname>", value: "Joe Bas - Tony Greigh" },
    { name: "<borrowersname>", value: "John doe - Kevin brown" },
    { name: "<fulladdress>", value: "2777 US 7, Ferrisburgh, LA 05456" },
    { name: "<product>", value: "Current owner search" },
    { name: "<statecounty>", value: "Kings NY" },
    { name: "<parcelIds>", value: "20-2214666, 20-2214667"}
  ];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private frmBuilder: UntypedFormBuilder,
    public auth: AuthService,
    private config:ConfigService,
    private matDialog:MatDialog ,
    private emailTemplateService:EmailTemplatesService ,
    private userMsg: UsermessageService,
    private productService: ProductService
  ) {
    this.emailTemplateForm = frmBuilder.group({
      Template_Name: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(63)]),
      ],
      Template_Content: [null, Validators.compose([Validators.required])],
      Template_Is_Default: [0],
    });
    this.emailTemplateForm.valueChanges.subscribe((data) => {
      this.error = "";
    });
  }

  ngOnInit() {
    this.getProducts();
    if (this.route.snapshot.params["templateId"]) {
      this.templateId = this.route.snapshot.params["templateId"];
      this.getEmailTemplateDetails();
    }
    this.timer.push(
      setTimeout(() => {
        this.config.setSideBarNode(0);
        this.namefocus.nativeElement.focus();
      }, 0)
    );
  }

  ngAfterViewInit() {
    if (document.querySelector("iframe"))
      document.querySelector("iframe").setAttribute("data-gramm", "false");
  }

  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  getEmailTemplateDetails() {
    this.emailTemplateService.getEmailTemplateById(this.templateId).subscribe(
      (data) => {
        this.emailTemplateModel = data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  confirm() {
    if (this.route.snapshot.params["templateId"]) {
      this.updateEmailTemplate();
    } else {
      this.createEmailTemplate();
    }
  }

  getIfErrors() {
    return Object.keys(this.errors).length;
  }

  createEmailTemplate() {
    this.emailTemplateModel.User_ID = this.auth.getUserId();
    this.emailTemplateService
      .createEmailTemplate(this.emailTemplateModel)
      .subscribe(
        (data) => {
          this.successMsgFunc("Email template created successfully");
        },
        (err) => {
          console.log(err);
          this.errorMsg = err;
        }
      );
  }

  updateEmailTemplate() {
    this.emailTemplateService
      .updateEmailTemplate(this.emailTemplateModel)
      .subscribe(
        (data) => {
          this.successMsgFunc("Email template updated successfully");
        },
        (err) => {
          console.log(err);
          this.openErrorPopup("Update email template failed.");
        }
      );
  }

  openDialog(msg){
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title:'Success', 
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res)=>{      
      this.router.navigate(['email-templates/listing']);
    });
  }

  successMsgFunc(msg) {
    this.userMsg.setSuccessMsg(msg);
    this.router.navigate(["email-templates/listing"]);
  }

  manageProductChange(event) {
    this.emailTemplateModel.Product_ID = event.value;
    this.emailTemplateService
      .manageEmailTemplateMappings(this.emailTemplateModel)
      .subscribe(
        () => {
          this.userMsg.setSuccessMsg("Updated successfully");
          this.getMappedProducts();
        },
        (err) => {
          if (err.code == "PTG01")
            this.openErrorPopup("Update product mapping failed.");
          else this.openErrorPopup(err.msg);
        }
      );
  }

  getProducts() {
    this.productService.getProductForTemplateMapping().subscribe(
      (products) => {
        this.products = products;
        if (this.route.snapshot.params["templateId"]) this.getMappedProducts();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getMappedProducts() {
    this.emailTemplateService
      .getEmailTemplateMappings(this.route.snapshot.params["templateId"])
      .subscribe(
        (mappedProduct) => {
          if (mappedProduct != null) this.mappedProduct = mappedProduct;
          else this.mappedProduct = {};
        },
        (err) => {
          console.log(err);
        }
      );
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  handleChangeDefault(event) {
    if (event.checked && !this.templateId)
      this.emailTemplateModel.Product_ID = null;
  }

  clearMapping() {
    this.emailTemplateService
      .clearTemplateMapping(this.mappedProduct)
      .subscribe(
        () => {
          this.getMappedProducts();
        },
        (err) => {
          this.openErrorPopup("Failed to clear template mapping.");
        }
      );
  }
}
