declare var google: any;
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule, FormsModule, FormControl, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, ClientsService, OrganizationService, UsersService, ConfigService,AbstractorService, UsermessageService, OrdersService, SiteSettingService , GroupsService, TagsService} from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { SuccessComponent } from '../../dialog/success/success.component';
import { ClientPasswordResetComponent } from '../../dialog/client-password-reset/client-password-reset.component';
import { LoginHistoryComponent } from '../../dialog/login-history/login-history.component';
import { JoinCompanyComponent } from '../../dialog/join-company/join-company.component';
import { CONSTANTS } from '../../app.constants'
import { Location, DatePipe } from '@angular/common';
import { ChangeCompanyComponent } from '../../dialog/change-company/change-company.component';
import * as momentTimeZone from 'moment-timezone';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss'],
  providers: [DatePipe]
})
export class EditClientComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  registerFrm: UntypedFormGroup;
  error: string = '';
  selectedClientType: any;
  errorMsg: any = {};
  listingType: string;
  errorType: string = '';
  registerModel: any = {};
  flagStatusModel: any = {};
  constants = CONSTANTS;
  organizations = [];
  emailAddres = '';
  gSheetClientInfo: any = {};
  gSheetBillingInfo: any = {};
  isClientInfo: boolean = false;
  isBillingInfo: boolean = false;
  marketingGroupMembers = [];  
  selectedSalesPerson = null;
  allActiveTags = [];
  selectedDefaultTags =[];
  adminFlag: boolean;
  userInfo: any = {};
  isDemo: boolean;
  isNewClient: boolean;
  NewClient: boolean;
  isAutoScreen: boolean;
  invToDeliverables: boolean;
  orgDetails: any = {};
  timer: any = [];
  states = [];
  latitute: any;
  longitude: any;
  clientType = [
    { value: '0', viewValue: 'None' },
    { value: '1', viewValue: 'Qualia' },
    { value: '2', viewValue: 'TrackerPro' },
    { value: '4', viewValue: 'SoftPro' }
  ];
  constructor(private auth: AuthService,
    private fb: UntypedFormBuilder,
    private clientsService: ClientsService,
    private matDialog:MatDialog,
    private frmBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _datePipe: DatePipe,
    private organizationService: OrganizationService,
    private usersService: UsersService,
    private config: ConfigService,
    private userMsg: UsermessageService,
    private orderService: OrdersService,
    private siteSettingService: SiteSettingService,
    private ref: ChangeDetectorRef,
    public groupsService: GroupsService,
    private abstractorService: AbstractorService,
    private tagService: TagsService

  ) {
    this.registerFrm = this.frmBuilder.group({
      'User_First_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'User_Last_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      // 'Company_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'Company_Name': [null],
      'Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      'userActive': [null],
      'User_Creation_Date': [null],
      // "Organization_ID": [null, Validators.required],
      "Organization_Name": [null],
      "AdminFlagStatus": [null],
      "toggleStaus": [null],
      "Client_Type": [null],
      "newClientStatus":[null],
      "newClient":[null],
      "QualaiFlagStatus":[null],  
      "autoScreenStatus":[null], 
      "addInvToDelStatus":[null],
      'Contact_Num': [null],
      'User_Fax_Contact_Number': [null],
      'Address_1': [null, Validators.maxLength(127)],// address line 1
      'Address_2': [null, Validators.maxLength(127)], //address line 2
      'Address_City': [null, Validators.maxLength(38)],
      'Address_ZipCode': [null, Validators.maxLength(11)],
      'Address_State_Abbr': [null, Validators.maxLength(2)],
      'Client_Order_Notes':[null],
      'Billing_Notes': [null]

    })
    this.registerFrm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    this.registerModel.Contact_Num = '';
    this.registerModel.User_Fax_Contact_Number = '';
    this.abstractorService.getAvailableStateList().subscribe((states) => { this.states = states });
    this.getSalesMarketingGrpMembers();
    this.getAllActiveTags();
    this.getClientDetails();
    this.getUserInfo();
    this.registerModel.Organization_ID = "";
    // this.getOrganizationList();
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();
    }, 0));
  }

  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time)
    });
  }

// getOrgDetailsForCompany(organizationID) {
  //   if (organizationID) {
  //     this.organizationService.getOrganizationDetails(organizationID)
  //       .subscribe((res) => {
  //         this.orgDetails = res;
  //         if (this.checkIfOrgClient()) {
  //           if (this.orgDetails.Organization_PrePayment_Status == 1) this.flagStatusModel.adminFlag = true;
  //           else this.flagStatusModel.adminFlag = false;
  //           if (this.orgDetails.Organization_Demo_Flag == this.constants.isDemo.active) this.isDemo = true;
  //           else this.isDemo = false;
  //           if(this.orgDetails.Organization_New_Client_Flag == this.constants.clientType.new) this.isNewClient = true;
  //           else this.isNewClient = false;
  //           if(this.orgDetails.Organization_Auto_Screen_Flag == this.constants.autoScreen.active) this.isAutoScreen = true;
  //           else this.isAutoScreen = false;
  //           if (this.orgDetails.Organization_Sales_Person_ID) {
  //             this.selectedSalesPerson = this.orgDetails.Organization_Sales_Person_ID;
  //           }
  //         }
  //       })
  //   }
  // }

  loadAdminFlags(userId) {
    this.siteSettingService.getAdminFlag()
      .subscribe((items) => {
        items.forEach((data) => {
          if (data.Flag_Type_Details.toLowerCase() == 'prepayment') {
            this.flagStatusModel.prepaymentFlagName = data.Flag_Type_Details;
            this.flagStatusModel.prepaymentFlagId = data.Flag_Type_ID;
          }
          if (data.Flag_Type_Details.toLowerCase() == 'special client') {
            this.flagStatusModel.qualiaFlagName = 'Qualia Client';
            this.flagStatusModel.qualiaFlagId = data.Flag_Type_ID;
            this.flagStatusModel.trackerFlagName = 'TrackerPro Client';
            this.flagStatusModel.trackerFlagId = 2;
          }
        })
        if (!this.checkIfOrgClient()) {
          this.getAdminFlagStatus(userId, this.flagStatusModel.prepaymentFlagId)
        }
      })
  }

  getAdminFlagStatus(userId, flagId) {
    this.siteSettingService.getNotificationFlagValue(userId, flagId)
      .subscribe((data) => {
        if (data && data.Flag_Value == 1) this.flagStatusModel.adminFlag = true
        else this.flagStatusModel.adminFlag = false;
      })
  }

  resetOrderData(event) {
    console.log("event", event)
  }

  updateAdminFlag(event) {
    var data = {
      User_ID: this.route.snapshot.params['id'],
      Flag_Type_ID: this.flagStatusModel.prepaymentFlagId,
    }
    if (event.checked) {
      if (this.checkIfOrgClient()) this.showPopupMessage();
      else {
        let msg = "Prepayment activated successfully.";
        data['Flag_Value'] = CONSTANTS.flagStatus.active
        this.setFlagStatus(msg, data);
      }
    } else {
      if (this.checkIfOrgClient()) this.showPopupMessage();
      else {
        let msg = "Prepayment deactivated successfully.";
        data['Flag_Value'] = CONSTANTS.flagStatus.inActive
        this.setFlagStatus(msg, data);
      }
    }
  }


  checkClientType(event) {
    var data = {
      User_ID: this.route.snapshot.params['id'],
      Flag_Type_ID: this.flagStatusModel.qualiaFlagId,
    }
    if (this.selectedClientType == this.clientType[1].value) {
      if (this.checkIfOrgClient()) {
        this.showQualiaPopupMessage();
        this.selectedClientType = this.listingType;
      }
      else {
        let msg = "Modified as Qualia client.";
        data['Flag_Value'] = CONSTANTS.flagStatus.active
        this.setFlagStatus(msg, data);
      }
    } else if (this.selectedClientType == this.clientType[2].value) {
      if (this.checkIfOrgClient()) {
        this.showQualiaPopupMessage();
        this.selectedClientType == this.clientType[2].value;
      }

      else {
        let msg = "Modified as TrackerPro client.";
        data['Flag_Value'] = 2;
        this.setFlagStatus(msg, data);
      }
    } else if (this.selectedClientType == this.clientType[3].value) {
      if (this.checkIfOrgClient()) {
        this.showQualiaPopupMessage();
        this.selectedClientType == this.clientType[3].value;
      }

      else {
        let msg = "Modified as SoftPro client.";
        data['Flag_Value'] = 4;
        this.setFlagStatus(msg, data);
      }
    } else if (this.selectedClientType == this.clientType[0].value) {
      if (this.checkIfOrgClient()) {
        this.showQualiaPopupMessage();
        this.selectedClientType == this.clientType[0].value
      }
      else {
        let msg = "Modified as Normal client.";
        data['Flag_Value'] = CONSTANTS.flagStatus.inActive;
        this.setFlagStatus(msg, data);
      }
    }
  }

  setFlagStatus(msg, data) {
    this.siteSettingService.setFlagStatus(data)
      .subscribe((data) => {
        this.userMsg.setSuccessMsg(msg);
      })
  }

  showPopupMessage() {
    let userFullName = this.registerModel.User_First_Name + " " + this.registerModel.User_Last_Name;
    let ogranizationName = this.registerModel.Organization_Name;
    let displayMsg = userFullName + " is a company user belonging to " + ogranizationName + ". Please change the settings for the company instead."
    this.openErrorPopup(displayMsg)
    this.ref.detectChanges();
    this.flagStatusModel.adminFlag = !this.flagStatusModel.adminFlag;
  }

  showQualiaPopupMessage() {
    let displayMsg = "This user is a company user. Please modify this setting for the company this user belongs to."
    this.openErrorPopup(displayMsg)
    this.ref.detectChanges();
  }

  checkIfOrgClient() {
    if (this.registerModel.Organization_ID) return true;
    else return false;
  }

  getOrganizationList(emailId) {
    console.log("Email", emailId);
    this.usersService.getDomainName(emailId).then((res) => {
      if (res) {
        this.registerModel.Organization_Domain = res;
        this.organizationService.getOrganizationsForDomainName(res)
          .subscribe((result) => {
            if (result) {
              this.organizations = result;
              console.log("this.organizations", this.organizations);
            }
          }, (err) => {
            console.log("err", err);
            this.errorMsg = err;
          })
      }
    });
  }

  addOrganization() {
    console.log("addOrganization", this.registerModel.Organization_ID)
    console.log("addOrganization1", this.registerModel)
    this.registerModel.Modified_By = this.auth.getUserId();
    this.organizationService.addOrgToAdmin(this.registerModel)
      .subscribe((result) => {
        if (result) {
          console.log("result", result);
        }
      }, (err) => {
        console.log("err", err);
        this.errorMsg = err;
      })
  }

  openCompanyList() {
    this.matDialog.open(JoinCompanyComponent, {data:{
      title: 'Select Company',
      message: this.registerModel
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        this.getUserInfo();
        if (res) this.getClientDetails();
      })
  }

  clearError() {
    this.errorMsg.msg = '';
  }

  isOrgID() {
    if (this.registerModel.Organization_ID == 0) return true;
    else return false;
  }


  getClientDetails() { 
    this.clientsService.getClientDetails(this.route.snapshot.params['id'])
      .subscribe((res) => {
        console.log("Hello:", res);
        this.registerModel = res;
        // this.getOrgDetailsForCompany(this.registerModel.Organization_ID)
        this.loadAdminFlags(this.route.snapshot.params['id'])
        this.getClientInfoFromGsheet(res.Organization_ID, res.User_Login_Name);
        this.getBillingInfoFromGsheet(res.Organization_ID, res.User_Login_Name);
        this.getClientTypeFlag(this.registerModel.Organization_ID, this.route.snapshot.params['id'], 7)
        // this.emailAddres = res.Email_Address;
        //this.getOrganizationList(res.Email_Address);
        // if (!this.registerModel.Organization_ID) this.registerModel.Organization_ID = "";
        this.loadData(res)
        if (res.User_Status == CONSTANTS.userStatus.new) {
          this.router.navigate(['/clients'])
        }
      }, (err) => {
        console.log(err);
      });
  }

  getClientTypeFlag(organizationID, userId, flagId) {
    if (organizationID) {
      this.organizationService.getOrganizationDetails(organizationID)
        .subscribe((res) => {
          this.orgDetails = res;
          if (this.checkIfOrgClient()) {
            if (this.orgDetails.Organization_Type == 1) this.listingType = this.clientType[1].value;
            else if (this.orgDetails.Organization_Type == 2) this.listingType = this.clientType[2].value;
            else this.listingType = this.clientType[0].value;

            if (this.orgDetails.Organization_PrePayment_Status == 1) this.flagStatusModel.adminFlag = true;
            else this.flagStatusModel.adminFlag = false;
            if (this.orgDetails.Organization_Demo_Flag == this.constants.isDemo.active) this.isDemo = true;
            else this.isDemo = false;
            if(this.orgDetails.Organization_New_Client_Flag == this.constants.clientType.new) this.isNewClient = true;
            else this.isNewClient = false;
            if(this.orgDetails.New_Organization == this.constants.clientType.new) this.NewClient = true;
            else this.NewClient = false;
            if(this.orgDetails.Organization_Auto_Screen_Flag == this.constants.autoScreen.active) this.isAutoScreen = true;
            else this.isAutoScreen = false;
            if(this.orgDetails.Org_AddInvoice_Deliverables == this.constants.invToDeliverables.yes) this.invToDeliverables = true;
            else this.invToDeliverables = false;
            if (this.orgDetails.Organization_Sales_Person_ID) {
              this.selectedSalesPerson = this.orgDetails.Organization_Sales_Person_ID;
            }
            if (this.orgDetails.Organization_Default_Tags && this.orgDetails.Organization_Default_Tags.trim()) {
              this.selectedDefaultTags = this.orgDetails.Organization_Default_Tags.split(',')
                .map(item => parseInt(item.trim(), 10)) // Parse each item to an integer
                .filter(item => !isNaN(item)); // Filter out NaN values;              
            }
          }
          else this.listingType = this.clientType[0].value;
        })

    } else {
      this.siteSettingService.getNotificationFlagValue(userId, flagId)
        .subscribe((data) => {
          if (data) {
            if (data.Flag_Value == 1) this.listingType = this.clientType[1].value;
            else if (data.Flag_Value == 2) this.listingType = this.clientType[2].value;
            else if (data.Flag_Value == 3) this.listingType = this.clientType[3].value;
            else this.listingType = this.clientType[0].value;
          }
          else this.listingType = this.clientType[0].value;
        })

    }
  }

  loadData(data) {
    if (this.registerModel.User_First_Name) {
      document.getElementById('User_First_Name').parentElement.classList.remove('is-empty')
    }
    if (this.registerModel.User_Last_Name) {
      document.getElementById('User_Last_Name').parentElement.classList.remove('is-empty')
    }
    if (this.registerModel.Email_Address) {
      document.getElementById('Email_Address').parentElement.classList.remove('is-empty')
    }
    // if (this.registerModel.Company_Name) {
    //   document.getElementById('Company_Name').parentElement.classList.remove('is-empty')
    // }
    if (this.registerModel.User_Creation_Date) {
      document.getElementById('User_Creation_Date').parentElement.classList.remove('is-empty')
    }
    //   if (this.registerModel.User_Creation_Date) this.registerModel.User_Creation_Date = this._datePipe.transform(this.registerModel.User_Creation_Date, 'M/d/yyyy');
    this.registerModel.User_Status = data.User_Status;
    if (data.User_Status == CONSTANTS.userStatus.inActive || this.registerModel.User_Status == this.constants.userStatus.deActivated) {
      this.registerModel.userState = false;
    } else {
      this.registerModel.userState = true;
    }
  }

  userAction(event) {
    console.log("Event", event)
    if (this.registerModel.User_Status == this.constants.userStatus.inActive || this.registerModel.User_Status == this.constants.userStatus.deActivated) {
      this.openConfirm('Activate', 'Would you like to re-activate this client ?').afterClosed()
        .subscribe((res) => {
          if (res == true) {
            console.log("Ok");
            this.activateUsr();
          }
          else {
            console.log("Cancelled")
            this.registerModel.userState = !this.registerModel.userState
          }
        });
    } else {
      this.openConfirm('Deactivate', 'Would you like to de-activate this user ?').afterClosed()
        .subscribe((res) => {
          if (res == true) {
            console.log("Ok");
            this.deactivateUsr();
          }
          else {
            console.log("Cancelled");
            this.registerModel.userState = !this.registerModel.userState
          }
        });
    }
  }

  activateUsr() {
    var data = {
      Admin_ID: this.auth.getUserId(),
      User_ID: this.route.snapshot.params['id']
    }
    this.clientsService.activateUser(data)
      .subscribe((data) => {
        console.log(data);
        let msg = "Client re-activated successfully.";
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/clients']);
      }, (err) => {
        console.log(err);
        this.errorMsg = err
      })
  }

  deactivateUsr() {
    var data = {
      Admin_ID: this.auth.getUserId(),
      User_ID: this.route.snapshot.params['id']
    }
    this.clientsService.deactivateUser(data)
      .subscribe((data) => {
        console.log(data);
        let msg = "Client deactivated successfully.";
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/clients']);
      }, (err) => {
        console.log(err);
        this.errorMsg = err
      })
  }

  sendPasswordLink() {
    var data = {
      Admin_ID: this.auth.getUserId(),
      User_ID: this.route.snapshot.params['id'],
      Email_Address: this.registerModel.Email_Address
    }
    this.clientsService.resetClienPassword(data)
      .subscribe((data) => {
        console.log(data);
        this.openModal('Success', 'Password reset link has been sent to the registered email.').afterClosed().subscribe((res) => {
          this.router.navigate(['/clients']);
        });
      }, (err) => {
        console.log(err);
        this.errorMsg = err
      })
  }

  resetClientPassword() {
    let disposable = this.matDialog.open(ClientPasswordResetComponent, {data:{
      message: this.route.snapshot.params['id']
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if (res) this.openModal('Success', 'Password changed successfully.');
    });
  }

  editClientDetails() {
    this.registerModel.User_Sales_Person_ID = this.selectedSalesPerson;
    this.registerModel.User_Default_Tags = this.selectedDefaultTags;
    this.registerModel.Admin_ID = this.auth.getUserId();
    this.clientsService.editClientDetails(this.registerModel)
      .subscribe((data) => {
        console.log(data);
        // this.openModal('Success', 'Updated successfully.').subscribe((res) => {
        //   this.router.navigate(['/clients']);
        // });
        let msg = "Updated successfully.";
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/clients']);
      }, (err) => {
        console.log(err);
        this.errorMsg = err
      })
  }

  openConfirm(title, msg) {
    return this.matDialog.open(ConfirmComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  openModal(title, msg) {
    return this.matDialog.open(SuccessComponent,{data: {
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  openClientPanel() {
    this.auth.getClientLoginCreds({ Client_ID: this.route.snapshot.params['id'] })
      .subscribe((result) => {
        if (result) {
          window.open(this.config.getClientHomePath() + '/base/' + JSON.stringify(result), "_blank");
        }
      }, (err) => {
        console.log(err);
      })
  }

  loginInfo() {
    this.clientsService.getLoginInfo(this.route.snapshot.params['id'])
      .subscribe((result) => {
        let disposable = this.matDialog.open(LoginHistoryComponent, {data:{
          message: result
        }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => { });
      }, (err) => {
        console.log(err);
      })
  }

  switchToIndividual() {
    this.registerModel.User_Modified_By = this.auth.getUserId();
    this.usersService.changeToIndividual(this.registerModel)
      .subscribe((result) => {
        this.getClientDetails();
        this.getUserInfo();
      }, (err) => {
        console.log(err);
      })
  }

  changeCompany() {
    this.matDialog.open(ChangeCompanyComponent, {data:{
      title: 'Change Company',
      message: this.registerModel
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        this.getUserInfo();
        if (res) this.getClientDetails();
      })
  }

  activateAccount() {
    this.clientsService.activateClientUser({
      User_ID: this.route.snapshot.params['id'],
      Admin_User_ID: this.auth.getUserId()
    })
      .subscribe((data) => {
        let msg = "Updated successfully.";
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/clients']);
        // this.openModal('Success', 'Updated successfully.').subscribe((res) => {
        //   this.router.navigate(['/clients']);
        // });
      }, (err) => {
        this.errorMsg = err
      })
  }

  checkIfSuperAdmin() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) return true;
    else return false;
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  getClientInfoFromGsheet(orgId, email) {
    if (orgId) {
      this.orderService.getClientInfoFromGsheet(orgId)
        .subscribe((clientInfo) => {
          this.isClientInfo = true;
          this.gSheetClientInfo = clientInfo[0] ? clientInfo[0] : {};
        }, (err) => {
          this.isClientInfo = true;
          this.gSheetClientInfo = {};
          // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    } else this.isClientInfo = true;

  }

  getBillingInfoFromGsheet(orgId, email) {
    if (orgId) {
      this.orderService.getBillingInfoFromGsheet(orgId)
        .subscribe((billingInfo) => {
          this.isBillingInfo = true;
          this.gSheetBillingInfo = billingInfo[0] ? billingInfo[0] : {};
        }, (err) => {
          this.isBillingInfo = true;
          this.gSheetBillingInfo = {};
          // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    } else this.isBillingInfo = true;
  }

  checkAdminNUserState() {
    if (this.checkIfSuperAdmin()) {
      if (this.registerModel.userState) return true;
      else return true;
    } else return false
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  getUserInfo() {
    this.clientsService.getUserInfo(this.route.snapshot.params['id'])
      .subscribe((data) => {
        this.userInfo = data;
        if(!data.Organization_ID) this.setToggleStatusForDemoflag(data);
        if(!data.Organization_ID) this.setToggleStatusForNewCliFlag(data);   
        if(!data.Organization_ID) this.setToggleStatusForUpdInvDelFlag(data);     
        if(!data.Organization_ID) this.setToggleStatusForAutoScreenFlag(data); 
        if(!data.Organization_ID) this.setSalesPersonField(data); 
        if(!data.Organization_ID) this.setDefaultTagsField(data);              
      }, (err) => {
        console.log("Error while featching user info");
      })
  } 

  setToggleStatusForDemoflag(data) {
    if (data.User_Demo_Flag == this.constants.isDemo.active) {
      this.isDemo = true;
    } else {
      this.isDemo = false;
    }
  }


  setToggleStatusForClients(event) {
    if (this.userInfo.Organization_ID) {
      this.openErrorPopup(CONSTANTS.apiErrors.compSettingErr);
      this.ref.detectChanges();
      this.isDemo = !this.isDemo;
    } else {
      let toggleStatus;
      if (this.isDemo) toggleStatus = this.constants.isDemo.active;
      else toggleStatus = this.constants.isDemo.inActive;
      this.clientsService.updateDemoStatusForIndCli(toggleStatus, this.route.snapshot.params['id'])
        .subscribe((details) => {
          this.userMsg.setSuccessMsg('Updated successfully.');
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.ref.detectChanges();
          this.isDemo = !this.isDemo;
        })
    }
  }

  setToggleStatusForNewCliFlag(data) {
    if (data.User_New_Client_Flag == this.constants.clientType.new) {
      this.isNewClient = true;
    } else {
      this.isNewClient = false;
    }

    if(data.New_Client == this.constants.clientType.new) this.NewClient = true;
    else this.NewClient = false
  }

  setToggleStatusForUpdInvDelFlag(data) {
    if (data.User_AddInvoice_Deliverables == this.constants.invToDeliverables.yes) {
      this.invToDeliverables = true;
    } else {
      this.invToDeliverables = false;
    }
  }

  updateNewClientStatus(event) {
    if(this.userInfo.Organization_ID) {
      let userFullName = this.registerModel.User_First_Name + " " + this.registerModel.User_Last_Name;
      let ogranizationName = this.registerModel.Organization_Name;
      let displayMsg = userFullName + " is a company user belonging to " + ogranizationName + ". Please change the settings for the company instead."
      this.openErrorPopup(displayMsg);
      this.ref.detectChanges();
      this.isNewClient = !this.isNewClient;
    } else {    
      let toggleStatus;
      if(this.isNewClient) toggleStatus = this.constants.clientType.new;
      else toggleStatus = this.constants.clientType.old;
      this.clientsService.updateNewClientStatusForInd(toggleStatus , this.route.snapshot.params['id'])
        .subscribe((details) => {
          this.userMsg.setSuccessMsg('Updated successfully.');
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.ref.detectChanges();
          this.isNewClient=!this.isNewClient;
      })
    }
  } 

  updInvDelStatus(event) {
    if(this.userInfo.Organization_ID) {
      let userFullName = this.registerModel.User_First_Name + " " + this.registerModel.User_Last_Name;
      let ogranizationName = this.registerModel.Organization_Name;
      let displayMsg = userFullName + " is a company user belonging to " + ogranizationName + ". Please change the settings for the company instead."
      this.openErrorPopup(displayMsg);
      this.ref.detectChanges();
      this.invToDeliverables = !this.invToDeliverables;
    } else {    
      let toggleStatus;
      if(this.invToDeliverables) toggleStatus = this.constants.invToDeliverables.yes;
      else toggleStatus = this.constants.invToDeliverables.no;
      this.clientsService.updInvDelStatus(toggleStatus , this.route.snapshot.params['id'])
        .subscribe((details) => {
          this.userMsg.setSuccessMsg('Updated successfully.');
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.ref.detectChanges();
          this.invToDeliverables=!this.invToDeliverables;
      })
    }
  } 

  updateNewClient(event){
    if(this.userInfo.Organization_ID) {
      let userFullName = this.registerModel.User_First_Name + " " + this.registerModel.User_Last_Name;
      let ogranizationName = this.registerModel.Organization_Name;
      let displayMsg = userFullName + " is a company user belonging to " + ogranizationName + ". Please change the settings for the company instead."
      this.openErrorPopup(displayMsg);
      this.ref.detectChanges();
      this.NewClient = !this.NewClient;
    } else {    
      let toggleStatus;
      if(this.NewClient) toggleStatus = this.constants.clientType.new;
      else toggleStatus = this.constants.clientType.old;
      this.clientsService.updateNewClientForInd(toggleStatus , this.route.snapshot.params['id'])
        .subscribe((details) => {
          this.userMsg.setSuccessMsg('Updated successfully.');
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.ref.detectChanges();
          this.NewClient=!this.NewClient;
      })
    }
  }

  setToggleStatusForAutoScreenFlag(data) {
    if (data.User_Auto_Screen_Flag == this.constants.autoScreen.active) {
      this.isAutoScreen = true;
    } else {
      this.isAutoScreen = false;
    }
  }

  updateAutoScreenStatus(event) {
    if(this.userInfo.Organization_ID) {
      let userFullName = this.registerModel.User_First_Name + " " + this.registerModel.User_Last_Name;
      let ogranizationName = this.registerModel.Organization_Name;
      let displayMsg = userFullName + " is a company user belonging to " + ogranizationName + ". Please change the settings for the company instead."
      this.openErrorPopup(displayMsg);
      this.ref.detectChanges();
      this.isAutoScreen = !this.isAutoScreen;
    } else {    
      let toggleStatus;
      if(this.isAutoScreen) toggleStatus = this.constants.autoScreen.active;
      else toggleStatus = this.constants.autoScreen.inActive;
      this.clientsService.updateAutoScreenStatusForInd(toggleStatus , this.route.snapshot.params['id'])
        .subscribe((details) => {
          this.userMsg.setSuccessMsg('Updated successfully.');
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.ref.detectChanges();
          this.isAutoScreen=!this.isAutoScreen;
      })
    }
  } 

  getSalesMarketingGrpMembers () {
    this.groupsService.getGroupMembers(environment.salesMarketingGrpId)
      .subscribe((users) => {
        this.marketingGroupMembers = users;
      }, (err) => {
        this.openErrorPopup("Error while fetching data.");
      })
  }

  getAllActiveTags() {
    this.tagService.getAllActiveTagsForListing()
      .subscribe((activeTags) => {
        if (activeTags) {
          this.allActiveTags = activeTags.rows;
        }
        },
        (err) => {
          this.openErrorPopup("Error while fetching tags.");
        }
      );
  }

  setSalesPersonField(data) {
    if (data.User_Sales_Person_ID) {
      this.selectedSalesPerson = data.User_Sales_Person_ID;
    }
  }

  setDefaultTagsField(data) {
    if (data.User_Default_Tags && data.User_Default_Tags.trim()) {
      this.selectedDefaultTags = data.User_Default_Tags.split(',')
      .map(item => parseInt(item.trim(), 10)) // Parse each item to an integer
      .filter(item => !isNaN(item)); // Filter out NaN values;
    }
  }

  ngAfterViewInit() {
    this.timer.push(setTimeout(() => {
      let searchBox: any = document.getElementById('Address_search_box');
      let options = {
        types: [
          'geocode',
        ]
      };
      var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
      autocomplete.addListener('place_changed', () => {
        this.getPlaceDetails(autocomplete.getPlace());
        this.ref.detectChanges();
      });
    }, 1000));
  }

  getPlaceDetails(place: any) {
    this.registerModel.Address_1 = '';
    this.registerModel.Address_City = '';
    this.registerModel.Address_State_Abbr = '';
    this.registerModel.Address_ZipCode = '';
    this.latitute = '';
    this.longitude = '';
    if (place.geometry == undefined) {
      return;
    } else {

    }
    this.latitute = place.geometry.location.lat();
    this.longitude = place.geometry.location.lng();
    if (place.address_components.length) {
      for (let i = 0; i < place.address_components.length; i++) {
        console.log("Places==>", place.address_components[i].types[0]);
        switch (place.address_components[i].types[0]) {
          case 'street_number': this.registerModel.Address_1 = place.address_components[i].long_name + ' ';
            break;
          case 'route': this.registerModel.Address_1 = this.registerModel.Address_1 + place.address_components[i].long_name;
            break;
          case 'locality': {
            this.registerModel.Address_City = place.address_components[i].long_name;
            break;
          }
          case 'administrative_area_level_2': {
            break;
          }
          case 'administrative_area_level_1': this.registerModel.Address_State_Abbr = place.address_components[i].short_name;
            break;
          case 'postal_code': this.registerModel.Address_ZipCode = place.address_components[i].short_name;
            break;
          default: break;
        }
      }
    } else {
      this.registerModel.Address_1 = '';
    }
  }

}
