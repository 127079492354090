import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ConfigService, OrdersService } from '../../services';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.scss']
})
export class ErrorReportComponent  {
  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;

  message:any={};
  title: any;
  isAbstractorAssigned:any;
  comment = '';
  reason = '';
  vendorAmt = '';
  ordErrReportedID = ''
  timer: any = [];
  orderId : string;  
  value: any;
  constructor(
    public dialogRef: MatDialogRef<ErrorReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog:MatDialog,
    private orderService: OrdersService,
    private config: ConfigService
    ) {
    
  }

  ngOnInit() {
    this.initDialog();
    this.orderId = this.message['Order_ID'];
    this.isAbstractorAssigned = this.message['isAbstractorAssigned'];
    this.timer.push(setTimeout(() => { this.getOrderReportedError(); }, 0))    
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  getOrderReportedError() {
  if(!this.value){
    this.orderService.getOrderReportedError(this.orderId)
      .subscribe((res) => {
        if (res) {
          this.comment = res.Order_Reported_Error;
          this.ordErrReportedID = res.Order_Reported_Error_ID;
        }
      }, (err) => {
        this.openErrorPopup('Error while fetching data. Please try again.');
      })
  }
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => { });
  }

  errorReport() {
     this.dialogRef.close({
      state: true,
      comment: this.comment,
      ordErrReportedID: this.ordErrReportedID,
      deleteErrorFlag: false
    })
    this.clearTime();
  }

  resoanforApproval(){
    console.log("reason reason",this.reason)
    if(this.reason.length > 0){
       this.dialogRef.close({
        comment: this.reason,
        state: true,
        vendorAmt: this.vendorAmt
      })
    }else{
      this.openErrorPopup('A reason must be entered to request fee approval.');
    }
   
    this.clearTime();

  }
  comments(){
    if (this.reason.length == 0) return true
    else false
  }
  Close() {
     this.dialogRef.close(false);
    this.clearTime();
  }

  clearTime() {
    this.timer.forEach((time) => {
      clearTimeout(time)
    });
  }

  isInValid() {
    return this.comment.trim().length == 0;
  }


  isInValids() {
    console.log("checking comment",this.comment)
    return this.comment.trim().length != 0;
  }


  deleteError() {
     this.dialogRef.close({
      state: true,
      comment: this.comment,
      ordErrReportedID: this.ordErrReportedID,
      deleteErrorFlag: true
    })
    this.clearTime();
  }
}
