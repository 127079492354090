import { Directive, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appEscapePress]'
})
export class EscapePressDirective {

  @Output() escaped = new EventEmitter<boolean>()

  @HostListener('document:keyup', ['$event']) handleKeyUp(event) {
      if (event.keyCode === 27) this.escaped.emit(true)     
  }

}
