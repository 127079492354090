// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rb-comments-removed {
  color: #EE4751;
  opacity: 0.25;
  pointer-events: none;
  text-decoration: line-through;
}

.rb-comments-added {
  color: #0F9D58;
}

.rb-comments-modified {
  color: #4FB2D9;
}`, "",{"version":3,"sources":["webpack://./src/app/report-builder/rb-comments/rb-comments.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,oBAAA;EACA,6BAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".rb-comments-removed {\n  color: #EE4751;\n  opacity: 0.25;\n  pointer-events: none;\n  text-decoration: line-through;\n}\n\n.rb-comments-added {\n  color: #0F9D58;\n}\n\n.rb-comments-modified {\n  color: #4FB2D9;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
