
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SiteSettingService {

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) { }

  editSiteSetting(setting) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/sitesettings/update';
    return this.http.put(url, setting, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  editMailSiteSetting(setting) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/sitesettings/statusmail/update';
    return this.http.put(url, { Admin_User_ID: this.auth.getUserId(), setting: setting }, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getSetting(name) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/sitesettings/fetch/' + name;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAdminFlag(){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url= this.config.getBasePath() + '/notifications/adminflag';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getNotificationFlagValue(userId, flagId){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url= this.config.getBasePath() + '/notifications/admin/'+ userId + '/flag/' + flagId +'/status';
    return this.http.get(url, httpOptions).pipe(
    map((response:any) => {
      
      return response;
    }),
    catchError((error:any) => observableThrowError(error.error)),);
  }

  setFlagStatus(model){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body' //to get full response
    }
    let url= this.config.getBasePath() + '/notifications/admin/setflag';
    return this.http.post(url, model , httpOptions).pipe(
    map((response:any) => {
      
      return response;
    }),
    catchError((error:any) => observableThrowError(error.error)),);
  }

}
