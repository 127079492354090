import { Component, Inject, OnInit } from '@angular/core';
import { DialogService, DialogComponent } from 'ng2-bootstrap-modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { OrdersService } from 'app/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-convert-order',
  templateUrl: './convert-order.component.html',
  styleUrls: ['./convert-order.component.scss']
})
export class ConvertOrderComponent  {

  quoteDetails : any;
  orderModel : any = {};
  productDetails: any;
  convertOrderForm: UntypedFormGroup
  constructor( private orderList: OrdersService, private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ConvertOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    
    this.convertOrderForm = fb.group({
      Property_Order_Number: [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      Order_Type: [null, Validators.required],
    })
  }

  ngOnInit() {
    this.initDialog();
    if(this.quoteDetails && this.quoteDetails.File_ID) this.orderModel.Property_Order_Number = this.quoteDetails.File_ID
  }

  initDialog(){
    this.quoteDetails = this.data.quoteDetails;
    this.productDetails = this.data.productDetails;
  }

  ngAfterViewInit() {

  }

  Close() {
     this.dialogRef.close({ state: false })
  }

  createOrderForQuote() {
    let productFindIndex = this.productDetails.findIndex((product) => product.Product_ID == this.orderModel.selectedProduct);
    this.quoteDetails['selectedProduct'] = this.orderModel.selectedProduct;
    this.quoteDetails['Product_Description'] = this.productDetails[productFindIndex].Product_Description;
    this.quoteDetails['Product_Name'] = this.productDetails[productFindIndex].Product_Name;
    this.quoteDetails['File_ID'] = this.orderModel.Property_Order_Number;
     this.dialogRef.close({
      state : true,
      orderDetails : this.quoteDetails
    })

  }

}
