// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-radio-button {
  margin-top: 0rem;
}

.enclosed {
  border: 2px dashed #60CC70;
  margin-top: 8px;
  padding: 8px;
  background-color: rgba(96, 204, 112, 0.05);
}

.subfont {
  font-size: 75%;
}

.green-font {
  color: #60CC70;
}

textarea {
  max-height: none !important;
  overflow-y: hidden !important;
}

.rb-commitment-removed {
  color: #EE4751;
  opacity: 0.25;
  pointer-events: none;
  text-decoration: line-through;
}

.rb-commitment-added {
  color: #0F9D58;
}

.rb-commitment-modified {
  color: #4FB2D9;
}`, "",{"version":3,"sources":["webpack://./src/app/report-builder/rb-commitments/rb-commitments.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,0BAAA;EACA,eAAA;EACA,YAAA;EACA,0CAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,2BAAA;EACA,6BAAA;AACF;;AAEA;EACE,cAAA;EACA,aAAA;EACA,oBAAA;EACA,6BAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".mat-mdc-radio-button{\n  margin-top: 0rem;\n}\n\n.enclosed{\n  border: 2px dashed #60CC70;\n  margin-top: 8px;\n  padding: 8px;\n  background-color:  rgba(96, 204, 112, 0.05);\n}\n\n.subfont{    \n  font-size: 75%; \n}\n\n.green-font{\n  color: #60CC70\n}\n\ntextarea {\n  max-height: none !important;\n  overflow-y: hidden !important;\n}\n\n.rb-commitment-removed {\n  color: #EE4751;\n  opacity: 0.25;\n  pointer-events: none;\n  text-decoration: line-through;\n}\n\n.rb-commitment-added {\n  color: #0F9D58;\n}\n\n.rb-commitment-modified {\n  color: #4FB2D9;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
