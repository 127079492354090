
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AuthService, PagerService, ConfigService, OrdersService, PricingService, StateService, OrganizationService, ScrollService, UsermessageService } from '../../services';
import { CONSTANTS } from '../../app.constants'
import { Router } from '@angular/router';
import { PreloaderService } from '../../services/preloader/preloader.service'
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { DialogService } from "ng2-bootstrap-modal";
import { DatePipe } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as momentTimeZone from 'moment-timezone';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-completedorders',
  templateUrl: './completedorders.component.html',
  styleUrls: ['./completedorders.component.scss']
})
export class CompletedordersComponent implements OnInit {
  constants = CONSTANTS
  orders: any = []
  orderStatus: any = {};
  listingType = 'my'
  ordersToDisplay: any;
  totalCount: number;
  selectedState = '';
  selectedCounty = '';
  selectedProduct = 0;
  pager: any = {};
  states = [];
  counties = [];
  products = [];
  filterBy: any = [];
  searchString: string;
  searchInProgress: boolean = false;
  sortField: string;
  orderBy: string;
  pageNum: number;
  organizations = [];
  allorganizations = [];
  selectedOrgId = '';
  selectedOrgId2 = '';
  scrollEnable: boolean = true;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  getDuplicateOrder = [];
  searchQuery = new Subject<string>();
  timer: any = [];
  isDemoFlag: boolean = false;
  currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();
  constructor(
    private auth: AuthService,
    private router: Router,
    private pagerService: PagerService,
    private config: ConfigService,
    public orderService: OrdersService,
    private preloaderService: PreloaderService,
    private pricingService: PricingService,
    private stateService: StateService,
    private organizationService: OrganizationService,
    private matDialog:MatDialog,
    private scrollService: ScrollService,
    private _datePipe: DatePipe,
    private userMsg: UsermessageService

  ) {
    this.pager.pages = [];
    if (this.pageNum == undefined)
      this.pageNum = 1;
    this.orderBy = 'DESC';
    this.sortField = 'Order_Completion_Date';
    this.searchString = '';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    // this.getAvailableStateLocal();
    this.pricingService.getAvailableStateList().subscribe((states) => { this.states = states });
    // this.getAvailableproductLocal();
    this.pricingService.getProductList()
    .subscribe((products: any[]) => { 
      this.products = products
     });
     if (this.stateService.getCompletedOrders()) this.initializeComponentData(this.stateService.getCompletedOrders());
     else this.getOrders();
    this.orderStatus = this.orderService.getOrderStatus();
    this.getAdminOrganizationList();
    this.getAllOrganizationList();
    // this.selectedOrgId = '';
    // this.selectedOrgId2 = '';
    this.timer.push(setTimeout(() => this.config.setSideBarNode(10), 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

//   getAvailableproductLocal(){
//     let AllProd = JSON.parse(sessionStorage.getItem('AllProductAvailable'));
//     if(AllProd) this.setProd(AllProd)         
//     else this.getAvailableprodList();
// }
  
// getAvailableprodList(){
//   this.pricingService.getProductList().subscribe((Prod) => { 
//       if(Prod){
//         this.setProd(Prod)         
//         sessionStorage.setItem('AllProductAvailable',JSON.stringify(Prod)); 
//        setTimeout(function(){sessionStorage.removeItem("AllProductAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });

//   }

//   setProd(AllProdData){
//       if(AllProdData) this.products = AllProdData;
//   }

//   getAvailableStateLocal(){
//     let Allstates = JSON.parse(sessionStorage.getItem('AllStateAvailable'));
//     if(Allstates) this.setState(Allstates)         
//     else this.getAvailableStateList();
// }
  
//   getAvailableStateList(){
//     this.pricingService.getAvailableStateList().subscribe((states) => { 
//       if(states){
//         this.setState(states)         
//         sessionStorage.setItem('AllStateAvailable',JSON.stringify(states)); 
//        setTimeout(function(){sessionStorage.removeItem("AllStateAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });

//   }

//   setState(AllstatesData){
//     if(AllstatesData) this.states = AllstatesData;
//   }

  getAdminOrganizationList() {
    this.organizationService.addedOrganizationList(this.auth.getUserId())
      .subscribe((result) => {
        if (result) {
          this.organizations = result;
        }
      }, (err) => {
        console.log("err", err);
        // this.errorMsg = err;
      })
  }

  getAllOrganizationList() {
    this.organizationService.getAllOrganizations()
      .subscribe((result) => {
        if (result) {
          this.allorganizations = result;
        }
      }, (err) => {
        console.log("err", err);
        // this.errorMsg = err;
      })
  }


  // setPage(page: number) {
  //   console.log("Inside set page", page)
  //   if (page < 1 || page > this.pager.totalPages) {
  //     return;
  //   }
  //   this.pageNum = page;
  //   this.setDisplayOrders();
  //   this.getOrders();
  //   this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
  //   console.log("Pager", this.pager)
  // }

  detectTypeChange(event) {
    this.pageNum = 1;
    this.setScrollResetPos()
    this.getOrders();
  }

  getUserRole() {
    return Number(this.auth.getUserRole())
  }


  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getOrders();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.getOrders();
  }

  setDisplayOrders() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    if (this.totalCount != undefined) this.ordersToDisplay = this.orders.slice(start, this.totalCount);

    if (this.ordersToDisplay.length > this.config.getNumRecordsPerPage()) this.ordersToDisplay = this.ordersToDisplay.slice(0, this.config.getNumRecordsPerPage());
  }

  // getOrders() {
  //   this.pageNum = 1;
  //   this.stateService.setCompletedOrders(this);
  //   this.orderService.getCompletedOrders(this.listingType, this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy)
  //     .subscribe((result) => {
  //       if (result) {
  //         this.orders = result.rows;
  //         this.totalCount = result.count;
  //         this.config.resetShowFlag();
  //         this.config.setClearTimeout();
  //         if (this.orders) {
  //           this.setDisplayOrders();
  //           this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
  //           this.searchInProgress = false;
  //           this.config.setDelayRecords();
  //         }
  //       }
  //     }, (err) => {
  //       this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
  //       // this.auth.logout();
  //       // this.router.navigate(['/login']);
  //     })
  // }


  checkDemoFlag(event){
    if(event.checked == true) this.isDemoFlag = true;
    else  this.isDemoFlag = false;
    this.getOrders();
    }

  getOrders() {
    if (this.pageNum == 1) {
      this.stateService.setCompletedOrders(this);
      this.orderService.getCompletedOrders(this.listingType, this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.isDemoFlag)
        .subscribe((result) => {
          if (result) {
            this.orders = result['rows'];
            this.totalCount = result['count'];
            this.getCompletedData(this.totalCount)
            this.getScrollPosData()
          }
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
          // this.auth.logout();
          // this.router.navigate(['/login']);
        })
    } else {
      this.getRetainData()
    }
  }

  getRetainData() {
    var ObsCollection = [];
    let pageSize = this.pageNum * this.config.getnoOrderSearchandComplete();
    ObsCollection.push(this.orderService.getCompletedOrders(this.listingType, 1, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.isDemoFlag, pageSize))
    
    // for (var i = 1; i <= this.pageNum; i++) {
    //   ObsCollection.push(this.orderService.getCompletedOrders(this.listingType, i, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.isDemoFlag))
    // }

    observableForkJoin(ObsCollection)
      .subscribe((retainedComplOrd) => {
        if (retainedComplOrd) {
          var totalOrders = [];
          for (var i = 0; i < retainedComplOrd.length; i++) {
            this.stateService.setCompletedOrders(this);
            totalOrders = totalOrders.concat(retainedComplOrd[i]['rows']);
          }
          this.orders = totalOrders;
          this.totalCount = retainedComplOrd[0]['count'];
          this.getCompletedData(this.totalCount)
          this.getScrollPosData()
        }
      })
  }

  getSearchString() {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }

  getCountyList(event) {
    if (event == 'all') {
      this.selectedCounty = 'all';
      this.applyFilter();
    }
    if (event.length != 0 && event != 'all') {
      //this.selectedCounty = '';
      this.pricingService.getAvailableCountyList(this.selectedState).subscribe((counties) => { this.counties = counties });
      this.applyFilter();
    }
  }

  applyFilter() {
    this.setScrollResetPos()
    this.pageNum = 1;
    this.filterBy = [];
    if (this.selectedOrgId && this.selectedOrgId != 'all') this.filterBy.push({ Organization_ID: this.selectedOrgId })
    if (this.selectedOrgId2 && this.selectedOrgId2 != 'all') this.filterBy.push({ Organization_ID: this.selectedOrgId2 })
    if (this.selectedState && this.selectedState != 'all') this.filterBy.push({ Property_State_Abbr: this.selectedState })
    if (this.selectedCounty && this.selectedCounty != 'all') this.filterBy.push({ Property_County: this.selectedCounty })
    if (this.selectedProduct && this.selectedProduct != -1) this.filterBy.push({ Product_Description: this.selectedProduct })
    this.getOrders();
  }

  searchField(search){
    this.scrollService.setScrollPosCompltdOrd(0)
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if(!format.test(this.searchString)&& this.searchString==" "){
      this.searchString='';
      this.getOrders();
    }else this.findOrders();
  }

  findOrders() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getOrders();
  }

  goToOrderDetails(orderId, customerId) {
    if(this.checkAdminType()){
      var getPos = document.documentElement.scrollTop
      this.scrollService.setScrollPosCompltdOrd(getPos);
      this.router.navigate(['/orders/' + orderId + '/view'])
    }
  }
  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.selectedOrgId = data.selectedOrgId;
    this.selectedOrgId2 = data.selectedOrgId2;
    this.selectedState = data.selectedState;
    this.selectedCounty = data.selectedCounty;
    this.selectedProduct = data.selectedProduct;
    this.filterBy = data.filterBy;
    this.counties = data.counties;
    // this.products = data.products;
    this.totalCount = data.totalCount;
    this.listingType = data.listingType;
    this.ordersToDisplay = data.ordersToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.isDemoFlag = data.isDemoFlag;
    this.getOrders();
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.orderService.getCompletedOrders(this.listingType, this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.isDemoFlag)
        .subscribe((result) => {
          if (result) {
            this.totalCount = result['count'];
            if (this.totalCount > this.orders.length) {
              this.orders = this.orders.concat(result['rows']);
              this.pager = this.pagerService.getPageOrderSearchandComplete(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  getCompletedData(result) {
    this.config.setClearTimeout();
    this.setDisplayOrders();
    this.pager = this.pagerService.getPageOrderSearchandComplete(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }
  
  getScrollPosData() {
    if (this.scrollService.getScrollPosCompltdOrd()) {
      var x = this.scrollService.getScrollPosCompltdOrd()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }));
    }
  }

  setScrollResetPos() {
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosCompltdOrd(x);
  }

  checkIfSearchMaster(){
    if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return true;
    else return false;
  }

  resetFilter(){
    this.selectedOrgId = '';
    this.selectedOrgId2 = '';
    this.selectedState = '';
    this.selectedCounty = '';
    this.selectedProduct = 0;
    this.searchString = '';
    this.filterBy = [];
    this.listingType = 'my';
    this.counties = [];
    this.isDemoFlag = false;
    this.getOrders();
  }

  checkIfFilterPresent(){
    if(this.selectedOrgId == 'all' || this.selectedOrgId2 == 'all' || this.selectedState == 'all' || this.selectedProduct == -1 || this.searchString || 
    (this.filterBy.length > 0) || this.listingType == 'all' || this.isDemoFlag) return true
    else return false
  }

  createExcel() {
    this.orderService.createCompletedOrdsExcelReport(this.filterBy, this.searchString, this.sortField, this.orderBy, this.listingType, this.isDemoFlag)
      .subscribe((blobData) => {
        if (blobData) this.composeAndDwnld(blobData);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  composeAndDwnld(blobData) {
     let file = new Blob([blobData], { type: 'application/octet-stream' });
    var fileUrl = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("download", "Report.xlsx");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }  

  csvExport() {

    const fields = [
      {
        label: 'Sl No',
        value: 'serialNumber'
      },
      {
        label: 'Pippin Order Number',
        value: 'Titles_ID'
      },
      {
        label: 'Client Reference',
        value: 'File_ID'
      },
      {
        label: 'Client',
        value: (row, User_First_Name) => {
          if (row.Organization_Name) return row.User_First_Name + ' ' + row.User_Last_Name + ' - ' + row.Organization_Name;
          else return row.User_First_Name + ' ' + row.User_Last_Name;
        }
      },
      {
        label: 'Qualia Order',
        value: 'qualiaOrder'
      },
      {
        label: 'Owner/Seller',
        value: 'Property_First_Name'
      },
      {
        label: 'Property Address',
        value: (row, Property_Address_1) => {
          var completeAddress = '';
          if (row.Property_Address_1) completeAddress += row.Property_Address_1
          else return completeAddress;
          if (row.Property_Address_2) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_Address_2
          }
          if (row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_City + ', ' + row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          if (!row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          return completeAddress;
        }
      },
      {
        label: 'State',
        value: 'Property_State_Abbr'
      },
      {
        label: 'County',
        value: 'Property_County'
      },
      {
        label: 'Status',
        value: (row, Order_Status) => {
          if (row.Order_Status == CONSTANTS.orderStatus.received) {
            return 'Received';
          } else if (row.Order_Status == CONSTANTS.orderStatus.confirmed) {
            return 'Confirmed';
          } else if (row.Order_Status == CONSTANTS.orderStatus.assigned) {
            return 'Assigned';
          } else if (row.Order_Status == CONSTANTS.orderStatus.cancelled) {
            return 'Cancelled';
          } else if (row.Order_Status == CONSTANTS.orderStatus.processing) {
            return 'Processing';
          } else if (row.Order_Status == CONSTANTS.orderStatus.completed) {
            return 'Completed';
          } else if (row.Order_Status == CONSTANTS.orderStatus.quotecompleted) {
          return 'Completed';
        }
        }
      },
      {
        label: 'Product Type',
        value: 'Product_Description'
      },
      {
        label: 'Submission Date',
        value: (row, Order_Creation_Date) => {
          return this._datePipe.transform(row.Order_Creation_Date_EST, 'MMM d, yyyy');
        }
      },  
      {
        label: 'Submission Time (ET)',
        value: (row, Order_Creation_Date) => {
          return this._datePipe.transform(row.Order_Creation_Date_EST, 'h:mm a');
        }
      },    
      {
        label: 'Completed Date',
        value: (row, Order_Completion_Date) => {
          if (row.Order_Completion_Date) return this._datePipe.transform(row.Order_Completion_Date_EST, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Completed Time (ET)',
        value: (row, Order_Completion_Date) => {
          if (row.Order_Completion_Date) return this._datePipe.transform(row.Order_Completion_Date_EST, 'h:mm a');
          else return '';
        }
      },
      {
        label: 'Handled By',
        value: (row, Order_Admin_ID) => {
          if(row.Order_Admin_ID) return `${row.Admin_User_First_Name} ${row.Admin_User_Last_Name}`;
            else return '';
        }
      },
      {
        label: 'Searcher',
        value: (row, Order_Admin_ID) => {
          if (row.Order_Admin_ID && row.Abstrator_ID) return 'Abstractor';
          else if (row.Order_Admin_ID) return 'Pippin';
          else return '';
        }
      },
      {
        label: 'Search Master Type',
        value: (row, Order_Admin_ID) => {
          if(row.Order_Admin_ID) {
            if(row.Admin_User_Role == CONSTANTS.userRole.searchMaster){
              if(row.Admin_User_Type == CONSTANTS.userType.internalSM || row.Admin_User_Type == null) return "Internal";
              else if(row.Admin_User_Type == CONSTANTS.userType.externalSM) return "External"
            }
          } else return "";
        }
      },
      {
        label: 'Assigned Abstractor',
        value: (row, Abstrator_ID) => {
          if(row.Order_Admin_ID && row.Abstrator_ID) return row.Abstractor_User_First_Name;
          else return '';
        }
      },
      {
        label: 'Priority Order',
        value: (row, Order_Creation_Date) => {
          if(row.Order_Priority == CONSTANTS.orderPriority.level2) return 'Yes';
          else return '';
        }
      },
      // {
      //   label: 'ETA Delay Reason',
      //   value: (row, Order_ETA_Change_Reason) => {
      //     if (row.Order_ETA_Change_Reason) return row.Order_ETA_Change_Reason;
      //     else return '';
      //   }
      // },
      {
        label : 'Latest Note',
        value: (row, Latest_Note) => {
          if (row.Latest_Note) return row.Latest_Note;
          else return '';
        },
      }
    ];  
    if(this.checkForSuperRole()){
      fields.push({
        label: 'Price',
        value: (row, Order_Custom_Price) => {
          if(row.Order_Custom_Price) return row.Order_Custom_Price;
          else return '';
        }
      })
    }
    this.orderService.downloadCSVfile(fields, this.orders, null)
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }
resetAllValue(event){
  this.selectedCounty = '';    
  }

  afterTextCopy() {
    this.userMsg.setSuccessCopyMsg("Text copied successfully");
  }

  getFullAddress(order){
    let completeAddress = '';
    if (order.Property_Address_1) completeAddress += order.Property_Address_1
    if (order.Property_Address_2) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += order.Property_Address_2
    }
    if (order.Property_City) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += order.Property_City
    }
    if (order.Property_State_Abbr) {
      if (completeAddress) completeAddress += ', '
      completeAddress += order.Property_State_Abbr
    }
    if (order.Property_ZipCode) {
      if (completeAddress) completeAddress += ' '
      return completeAddress += order.Property_ZipCode
    }
  }
  
  getClientFullName(order) {
    if(order.Organization_Name) return order.User_First_Name +" "+ order.User_Last_Name + " - "  +order.Organization_Name;
    else return order.User_First_Name +" "+ order.User_Last_Name 
  }
  
  getAbstractorFullName(order) {
    return order.Abstractor_User_First_Name;
  }

  getAdminFullName(order) {
    return this.firstLetterCaps(order.Admin_User_First_Name) + " "+ this.firstLetterCaps(order.Admin_User_Last_Name)
  }
  
  firstLetterCaps(data){
    if (typeof data !== 'string') return ''
    return data.charAt(0).toUpperCase() + data.slice(1)
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }

  checkForSuperRole(){
    if(this.auth.getUserId() == environment.superAdminId) return true;
    else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) return true;
    else return false;
  }
}
