import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import * as momentTimezone from "moment-timezone";
import { CustomMomentDateAdapter } from "./custom-moment-date-adapter";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "L",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-pippin-dates",
  templateUrl: "./pippin-dates.component.html",
  styleUrls: ["./pippin-dates.component.scss"],
  // providers: [
  //   {
  //     provide: DateAdapter,
  //     useClass: CustomMomentDateAdapter,
  //     deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  //   },

  //   { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  // ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},   
  ],
})
export class PippinDatesComponent implements OnInit {
  @Input() dateFormCtrl = new UntypedFormControl({ value: "", disabled: false });
  @Input() dateValue: any = "";
  @Output() eventEmitter = new EventEmitter<any>();
  @Input() placeHolderDate: any;
  @Input() required: any;
  @Input() actionField: any;
  @Output() clearFilterEvn = new EventEmitter<any>();
  @Input() min_Date: any;
  @Input() max_Date: any;
  @Input() eula: boolean = false;

  dateObj = {};
  errors: any = {};
  constructor(private adapter: DateAdapter<any>) {}

  ngOnInit() {}

  validateDateFn(event) {
    // console.log("event",event)
    if (event.value) {
      this.dateObj["Pippin_Date"] = event.value;
      this.eventEmitter.emit(this.dateObj);
      if (this.actionField) this.clearFilterEvn.emit(this.actionField);
    } else {
      this.dateObj["Pippin_Date"] = event.value;
      this.eventEmitter.emit(this.dateObj);
      if (this.actionField) this.clearFilterEvn.emit(this.actionField);
    }
  }

  clearForms(event) {
    // if (typeof event == "string")
    //   this.dateValue = new Date(
    //     new Date(this.dateValue).toLocaleString("en", {
    //       timeZone: "America/New_York",
    //     })
    //   );
    this.errors = {};
  }

  checkErrorType() {
    if (this.required) {
      if (this.required["fromDate"] && !this.required["toDate"])
        return "toDate";
      if (!this.required["fromDate"] && this.required["toDate"])
        return "fromDate";
    } else return false;
  }

  restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[///\d\s]/.test(input);
  }

  checkYear() {
    if (this.dateObj) {
      if (
        this.dateObj["Pippin_Date"] && this.dateObj["Pippin_Date"]._d && 
        this.dateObj["Pippin_Date"]._d.getFullYear() >= "1500"
      )
        return true;
      else return false;
    } else return false;
  }
  
}