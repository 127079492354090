
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable()
export class NewOrderService {

  private step1Data: any;
  // step2Data: any;
  private step3Data: any;
  private selectedCriteria: any = 1;
  private selectedOption: any = null;
  // newStepData: any;
  private quoteData: any;
  private selectedOptionQReq: any;
  private selectedCriteriaQReq: any = 1;
  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) { }

  resetAll() {
    this.step1Data = null;
    // this.step2Data = null;
    this.step3Data = null;
    this.selectedCriteria = 1;
    this.selectedOption = null;
    // this.newStepData = null;
  }

  setStep1data(data) {
    // this.step1Data = data;
    this.step1Data = Object.assign({}, data);
    //console.log("data : 2", this.step1Data);
  }

  // setNewStepdata(data) {
  //   console.log("setting new step data", data);
  //   this.newStepData = data;
  // }

  // getNewStepData() {
  //   console.log("getting new step data", this.newStepData);
  //   return this.newStepData;
  // }

  // setStep2data(data){
  //   this.step2Data=data;
  // }
  setSelectedCriteria(data) {
    this.selectedCriteria = data;
  }
 
  getSelectedCriteria() {
    return this.selectedCriteria;
  }

  setSelectedOption(data) {
    this.selectedOption = data;
  }
  
  getselectedOption() {
    return this.selectedOption;
  }
  

  setStep3data(data) {
    this.step3Data = data;
  }

  getStep1Data() {
    return this.step1Data;
  }

  // getStep2Data(){
  //   return this.step2Data;
  // }

  getStep3Data() {
    return this.step3Data;
  }

  goToNewOrder() {
    this.router.navigate(['/user/' + this.auth.getUserId() + '/orders/new/step1'])
  }

  getProductPricing(productId, state, county) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/product/'
      + productId + '/county/' + county + '/stateabbr/' + state + '/user/' + this.auth.getUserId();
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createNewOrder(customerId) {
    var data = {
      property: this.getStep1Data(),
      pricing: this.getStep3Data(),
      selectedOrderCriteria: this.getSelectedCriteria(),
      Admin_User_ID:this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/user/' + customerId + '/neworder/admin/' + this.auth.getUserId();
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateNewOrder(customerId, orderDoc:Array<any> =[], orderDetails) {
    var data = {
      property: orderDetails,
      orderDocs:orderDoc
    }
    console.log("this.orderDetails data",data)
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/user/' + customerId + '/updateorder/admin/' + this.auth.getUserId();
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }
  updateOrderDocument(orderID, propertyId, docId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    var data = {
      Order_ID: orderID,
      Property_ID: propertyId
    }
    let url = this.config.getBasePath() + '/orders/orderdoc/' + docId + '/user/' + this.auth.getUserId();
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getCompleteProductList(filterBy) {
    var data = {
      filterBy: filterBy
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/products/complete';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getSubProductList() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/products/sub';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getquoteData1(){
    return this.quoteData
  }

  setquoteData1(data: any){
    return this.quoteData = data;
  }

  setSelectedOptionQReq(data) {
    return this.selectedOptionQReq = data;
  }
  
  getselectedOptionQReq() {
    return this.selectedOptionQReq;
  }

  setSelectedCriteriaQReq(data){
    return this.selectedCriteriaQReq = data;
  }

  getSelectedCriteriaQReq(){
    return this.selectedCriteriaQReq;
  }

  resetAllQReq() {
    this.quoteData = null;
    this.selectedCriteriaQReq = 1;
    this.selectedOptionQReq = null;
  }
  
  createQuote() {
    let data = {
      property: this.getquoteData1()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/quotes/admin/'+this.auth.getUserId()+'/create/quote';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  convertToOrderFromQuotes(orderDetails){
    let body = {
      orderDetails:orderDetails
    }
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + this.auth.getToken() }),
    };
    let url = this.config.getBasePath() + '/orders/user/' + this.auth.getUserId() + '/quotes';
    return this.http.post(url, body, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),
    );
  }

  reqUserPrepaymentStatus(orgId) {
    console.log("orgId ",orgId)
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/prepayment/status/' + this.auth.getUserId() + '/'+ orgId+'';
   console.log("url",url)
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        console.log("response: reqUserPrepaymentStatus", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  checkIfQualaiUser(userId) {
		let httpOptions = {
			headers: new HttpHeaders({ 'Authorization': 'Bearer ' +  this.auth.getToken() }),
		}
		let url = this.config.getBasePath() + '/users/client/' + userId + '/checkIfQualiaUser';
		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((error: any) => observableThrowError(error.error)));
	}

}



