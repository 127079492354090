// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fill-gb-svg {
  filter: invert(45%) sepia(99%) saturate(387%) hue-rotate(98deg) brightness(87%) contrast(94%);
}

.rb-no-items {
  border: 2px dashed #0F9D58;
  color: #0F9D58;
}

.rb-non-zero .mat-badge-content {
  background-color: #0F9D58 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/report-builder/rb-global-commitments/rb-global-commitments.component.scss"],"names":[],"mappings":"AAAA;EACE,6FAAA;AACF;;AAEA;EACE,0BAAA;EACA,cAAA;AACF;;AAGE;EACE,oCAAA;AAAJ","sourcesContent":[".fill-gb-svg {\n  filter: invert(45%) sepia(99%) saturate(387%) hue-rotate(98deg) brightness(87%) contrast(94%);\n}\n\n.rb-no-items{\n  border: 2px dashed #0F9D58;\n  color: #0F9D58;\n}\n\n.rb-non-zero{\n  .mat-badge-content {\n    background-color: #0F9D58 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
