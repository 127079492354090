import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService, AuthService, ConfigService, ClientsService, OrganizationService, UsermessageService } from 'app/services';
import { CONSTANTS } from '../../app.constants'
import { DialogService } from 'ng2-bootstrap-modal';
import * as _ from 'underscore';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss']
})

export class CreateProductComponent implements OnInit {
  productForm: UntypedFormGroup
  productModel: any = {};
  productID: any;
  timer: any = [];
  errorMsg: any;
  constants = CONSTANTS
  successMsg: any;
  customers: any = [];
  organizations: any = [];
  parentOrganizations: any = [
    { value: '1', Organization_Name: 'Qualia' },
    { value: '2', Organization_Name: 'TrackerPro' },
    { value: '4', Organization_Name: 'SoftPro' }
  ];;
  selCustomerIds: any = [];
  selOrganizationIds: any = [];
  customerList = {};
  companyList = {};
  checkCompany: boolean = true;
  checkClient: boolean = true;
  allCompanyValue = "all";
  allClientValue = "all";
  loadingClientInfo: boolean = true;
  loadingOrgInfo: boolean = true;
  activeClients: any;
  activeOrganizations: any;
  activeParentOrganizations:any;
  userIds = [];
  organizationIds = [];
  deleteObj: any;
  newClients :  any = [];
  newOgranizations :  any = [];
  newParentOrganizations: any;
  testProductDetails : any;
  productStatusChanged:boolean = false;
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef
  @ViewChild('selectClientID') selClient: ElementRef
  tempClients = [];
  tempOrganizations = [];
  tempParentOrganizations = [];
  prodCategories = [];
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private productService: ProductService,
    private auth: AuthService,
    private router: Router,
    private config: ConfigService,
    private clientsService: ClientsService,
    private matDialog:MatDialog,
    private organizationService: OrganizationService,
    private userMsg: UsermessageService
  ) {
    this.productForm = frmBuilder.group({
      'Product_Name': [null, Validators.compose([Validators.required, Validators.maxLength(512)])],
      'Product_Description': [null, Validators.compose([Validators.required, Validators.maxLength(256)])],
      'Product_Default_Flag':[null],
      'Product_Status':[null],
      'QBO_Acc_ID':[null],

    })
    this.tempParentOrganizations = this.parentOrganizations;
    this.newParentOrganizations = this.parentOrganizations;
  }

  ngOnInit() {
    this.productModel.Product_Status = true;
    this.productModel.Product_Default_Flag = '0';
    this.getIndividualCustomers();
    this.getAllOrganizations();
    this.getQBProdCategory();
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();      
    }, 0));
  }

  createNewProduct(data) {
    let allOrganizationIds = [];
    let allClientIds = [];
    let allParentOrganizationIds = [];
    if(this.newOgranizations && this.newOgranizations.length > 0) this.newOgranizations.forEach((org) => allOrganizationIds.push(org.Organization_ID));
    if(this.newParentOrganizations && this.newParentOrganizations.length > 0) this.newParentOrganizations.forEach((org) => allParentOrganizationIds.push(org.value));
    if(this.newClients && this.newClients.length > 0) this.newClients.forEach((user) => allClientIds.push(user.User_ID));
    if(this.productModel.Product_Default_Flag == 0) data['Users_Ids'] = allClientIds;
    if(this.productModel.Product_Default_Flag == 0) data['Organization_Ids'] = allOrganizationIds;
    if(this.productModel.Product_Default_Flag == 0) data['Parent_Organization_Ids'] = allParentOrganizationIds;
    if (data.Product_Name) data.Product_Name = data.Product_Name.trim();
    if (data.Product_Description) data.Product_Description = data.Product_Description.trim();
    this.productService.createProduct(data)
      .subscribe((productDetails) => {
        // sessionStorage.removeItem('AllProductAvailable');
        let msg = "Product created successfully."
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/product/list'])
      }, (err) => {
        this.errorMsg = err;
        this.productModel.Product_Status = data.Product_Status;
        this.userMsg.setErrorMsg(this.errorMsg.msg)
      })
  }

  getQBProdCategory() {
    this.productService.getQBProdCategory()
    .subscribe((result) => {
      if (result) {
        this.prodCategories = result;
      }
    }, (err) => {
      console.log(err);
    })
  }

  prodCategoryChanged(event) {
    this.productModel.QBO_Acc_ID=event;
  }

  getIndividualCustomers() {
    this.clientsService.getActiveIndividualClients()
      .subscribe((result) => {
        if (result) {
          this.customers = result;
          this.newClients = result;
          this.tempClients = result;
        }
      }, (err) => {
        console.log(err);
      })
  }

  getAllOrganizations() {
    this.organizationService.getAllOrganizations()
      .subscribe((result) => {
        if (result) {
          this.organizations = result;
          this.newOgranizations = result;          
          this.tempOrganizations = result;
        }
      }, (err) => {
        console.log(err);
      })
  }

  addNewClients(event) {
    let temp = []; 
    temp.push(event.value);
    this.activeClients =_.flatten( temp);

  }

  addNewCompanys(event) {
    let temp = []; 
    temp.push(event.value);
    this.activeOrganizations =_.flatten( temp);
  }

  addNewOrgs(event) {
    let temp = []; 
    temp.push(event.value);
    this.activeParentOrganizations =_.flatten( temp);
  }

  changeProductStatus(event) {
    this.productStatusChanged = true
    this.productModel.Product_Status = event.checked
  }

  resetClientData(event) {
    let temp = []; 
    temp.push(event);
    this.tempClients =_.flatten( temp);
  }

  resetOrgsData(event) {
    let temp = []; 
    temp.push(event);
    this.tempOrganizations =_.flatten( temp);
  }

  resetParentOrgsData(event) {
    let temp = []; 
    temp.push(event);
    this.tempParentOrganizations =_.flatten( temp);
  }

  checkforClientLength() {
    if(this.customers && this.newClients && (this.newClients.length == this.customers.length)) return true;
    else return false
  }

  checkForOrgsLength() {
    if(this.organizations && this.newOgranizations && (this.newOgranizations.length == this.organizations.length)) return true;
    else return false
  }

  checkForParentOrgsLength() {
    if(this.parentOrganizations && this.newParentOrganizations && (this.newParentOrganizations.length == this.parentOrganizations.length)) return true;
    else return false
  }
}
