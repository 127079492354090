import { Component, OnDestroy, OnInit } from "@angular/core";
import { OrdersService } from "app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-chk-fees',
  templateUrl: './chk-fees.component.html',
  styleUrls: ['./chk-fees.component.scss']
})
export class ChkFeesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  orderProducts: any = [];
  totPrice = 0;
  orderId: any;

  constructor(
    private ordersService: OrdersService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.ordersService.orderProducts$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.getPriceDetails(data));
    this.ordersService.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.orderId = data.Order_ID);
  }

  getPriceDetails(data){
    this.orderProducts = data;
    this.totPrice = 0;
    this.orderProducts.forEach((prod) => this.totPrice += prod.Order_Product_Price)
  }
  
}
