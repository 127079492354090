import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[limit-to]',
  host: {
    '(keypress)': '_onKeypress($event)',
  }
})
export class LimitToDirective {

  @Input('limit-to') limitTo;
  _onKeypress(e) {
    const limit = +this.limitTo;
    var onlyVal = e.target.value.replace(".", "");
    if ((e.keyCode != 8  && e.key !== "Delete" && e.keyCode != 37 && e.keyCode != 38 && e.keyCode != 39 && e.keyCode != 40)
      && onlyVal.length === limit) e.preventDefault();
  }
}
