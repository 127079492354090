declare var google: any;
import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import { ConfigService, AbstractorService, UsermessageService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../../dialog/success/success.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PhonenumberPipe, SsnFieldPipe, EinFieldPipe } from '../../pipes';
import { CONSTANTS } from '../../app.constants';
import { UUID } from 'angular2-uuid';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-abstractor-new',
  templateUrl: './abstractor-new.component.html',
  providers: [PhonenumberPipe, SsnFieldPipe, EinFieldPipe],
  styleUrls: ['./abstractor-new.component.scss']
})
export class AbstractorNewComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  registerFrm: UntypedFormGroup;
  selectPhoneType : any;
  selectSecPhoneType:any;
  error: string = '';
  errorMsg: any = {};
  errorType: string = '';
  registerModel: any = {};
  domain: string = '';
  latitute: any;
  EnO_Expiration: boolean = true;
  EnO: boolean = true;
  longitude: any;
  states = [];  
  timer: any = [];
  secondaryEmail: any = [] ;
  secondaryPhone: any = [] ;
  getUSDateString = new Date('01/02/1500').toLocaleString("en-US", { timeZone: "America/New_York" });
  min_Date = new Date(this.getUSDateString);
  constructor(
    private config: ConfigService,
    private fb: UntypedFormBuilder,
    private abstractorService: AbstractorService,
    private matDialog:MatDialog,
    private userMsg:UsermessageService,
    private location: Location,
    private ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute, ) {
    this.registerFrm = fb.group({
      'User_First_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],           
      'Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      'Confirm_Email_Address': [null, Validators.required],
      'User_SSN': [null, Validators.maxLength(11)],
      'User_EIN': [null, Validators.maxLength(10)],
      'Contact_Num': [null],
      'Contact_Type':[null],
      'User_Fax_Contact_Number':[null],
      'locality': [null, Validators.maxLength(127)],// address line 1     
      'zipCode': [null, Validators.maxLength(11)],
      'state': [null, Validators.compose([Validators.maxLength(2), Validators.minLength(2)])],
      'city': [null, Validators.maxLength(38)],
      'notes': [null],
      'EnO': [null, Validators.maxLength(5)],
      'Offers_Commercial': [null],
      'EnO_Expiration':[{ disabled: false, value: '' }],
      'secondaryEmailsArr': this.fb.array([]),
      'secondaryPhoneArr': this.fb.array([]),
      'selectPhoneType':[null],
      'selectSecPhoneTypeArr':this.fb.array([])
    })
    this.registerFrm.valueChanges.subscribe((data) => {
      this.error = '';
    });

  }

  get secondaryEmailsArr() {
    return this.registerFrm.controls["secondaryEmailsArr"] as UntypedFormArray;
  }

  get secondaryPhoneArr() {
    return this.registerFrm.controls["secondaryPhoneArr"] as UntypedFormArray;
  }

  ngOnInit() {  
    // this.getAvailableStateLocal();
    this.abstractorService.getAvailableStateList().subscribe((states) => { this.states = states });
    this.registerModel.Contact_Num = '';
    this.registerModel.User_Fax_Contact_Number = '';
    this.registerModel.Contact_Type = 'M';
    this.timer.push(setTimeout(() => { 
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();
    }, 0));
  }  

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

//   getAvailableStateLocal(){
//     let Allstates = JSON.parse(sessionStorage.getItem('Absstates'));
//     if(Allstates) this.setState(Allstates)         
//     else this.getAvailableStateList();
// }
  
//   getAvailableStateList(){
//     this.abstractorService.getAvailableStateList().subscribe((states) => { 
//       if(states){
//         this.setState(states)         
//         sessionStorage.setItem('Absstates',JSON.stringify(states)); 
//        setTimeout(function(){sessionStorage.removeItem("Absstates");}, CONSTANTS.sessiontimeOut.timeOut );
//       }
//     });
//   }

  
//   setState(AllstatesData){
//     this.states = AllstatesData
// }
  
  clearError() {
    this.errorMsg.msg = '';
  }

  ngAfterViewInit() {
    this.timer.push(setTimeout(() => {
      let searchBox: any = document.getElementById('Address_search_box');
      let options = {
        types: [
          // return only geocoding results, rather than business results.
          'geocode',
        ]
      };
      var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
      // Add listener to the place changed event
      autocomplete.addListener('place_changed', () => {
        this.getPlaceDetails(autocomplete.getPlace());
        this.ref.detectChanges();
      });
    }, 1000))
  }

  getPlaceDetails(place: any) {    
    this.registerModel.Address_1 = '';   
    this.registerModel.Address_City = '';
    this.registerModel.Address_State_Abbr = '';    
    this.registerModel.Address_ZipCode = '';
    this.latitute = '';
    this.longitude = '';
    if (place.geometry == undefined) {
      return;
    } else {
      
    }
    this.latitute = place.geometry.location.lat();
    this.longitude = place.geometry.location.lng();
    
    if (place.address_components.length) {
      for (let i = 0; i < place.address_components.length; i++) {
        console.log("Places==>", place.address_components[i].types[0]);
        switch (place.address_components[i].types[0]) {
          case 'street_number': this.registerModel.Address_1 = place.address_components[i].long_name + ' ';
            break;
          case 'route': this.registerModel.Address_1 = this.registerModel.Address_1 + place.address_components[i].long_name;
            break;
          case 'locality': {          
            this.registerModel.Address_City = place.address_components[i].long_name;
            break;
          }
          case 'administrative_area_level_2': {
            // this.registerModel.county = place.address_components[i].short_name;
            break;
          }
          case 'administrative_area_level_1': this.registerModel.Address_State_Abbr = place.address_components[i].short_name;
            break;
          case 'postal_code': this.registerModel.Address_ZipCode = place.address_components[i].short_name;
            break;
          default: break;
        }        
      }      
    } else {
      this.registerModel.Address_1 = '';
    }
  }

  createAbstractor() {
    // sessionStorage.removeItem('AllAbstractors');
    this.clearError();
    if(this.secondaryEmailsArr.value && this.secondaryEmailsArr.value.length > 0){
      this.secondaryEmailsArr.value.forEach((email,i) =>{
        email['Email_index']= i+1
        this.secondaryEmail.push(email)

      })
      this.registerModel.Secondary_Emails= this.secondaryEmail;
    }else this.registerModel.Secondary_Emails= [];
    if(this.secondaryPhoneArr && this.secondaryPhoneArr.value.length > 0){
      this.secondaryPhoneArr.value.forEach((phone,i) =>{
        phone['Phone_Index']=i+1;
      this.secondaryPhone.push(phone)
      }) 
      this.registerModel.Secondary_Phone= this.secondaryPhone
     }else this.registerModel.Secondary_Phone= [];
    this.makeRegisterRequest(this.registerModel);
  }

  makeRegisterRequest(registerModel){
    this.abstractorService.createAbstractor(registerModel)
      .subscribe((res) => {
        
        let msg = "Abstractor created successfully."
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/abstractor/list']);
        // this.openModal();

      }, (err) => {
        console.log("err:", err);
        this.secondaryEmail =[];
        this.secondaryPhone=[];
        this.errorMsg = err;
      });
  }

  openModal() {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: "Success",
      message: "Abstractor created successfully."
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      this.router.navigate(['/abstractor/list']);
    });
  }

  goBack() {
    this.router.navigate(['/abstractor/list']);
  } 

  clearZipcode() {
    if (this.registerFrm.controls['zipCode'].value)
      this.registerFrm.controls['zipCode'].reset();
  }

  addemails() {
    if(!this.registerFrm.controls['Email_Address'].touched) this.registerFrm.controls['Email_Address'].markAsTouched();
    if(!this.registerFrm.controls['Confirm_Email_Address'].touched) this.registerFrm.controls['Confirm_Email_Address'].markAsTouched();
      const emailObj = this.fb.group({
        Email_ID: new UntypedFormControl(UUID.UUID()),
        Email_Priority:new UntypedFormControl(6),
        Email_Address: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.maxLength(255)])),
      });
      this.secondaryEmailsArr.push(emailObj)
  }

  deleteSecondaryEmails(emailIndex: number) {
    this.secondaryEmailsArr.removeAt(emailIndex);
  }

  addphonenmbrs() {
    if(!this.registerFrm.controls['Email_Address'].touched) this.registerFrm.controls['Email_Address'].markAsTouched();
    if(!this.registerFrm.controls['Confirm_Email_Address'].touched) this.registerFrm.controls['Confirm_Email_Address'].markAsTouched();
    const emailObj = this.fb.group({
      Contact_ID: new UntypedFormControl(UUID.UUID()),
      Contact_Priority:new UntypedFormControl(6),
      Contact_Num: new UntypedFormControl(null, Validators.required),
      Contact_Type :new UntypedFormControl("M")
    });
    this.secondaryPhoneArr.push(emailObj)
  }

  deleteSecondaryPhones(emailIndex: number) {
    this.secondaryPhoneArr.removeAt(emailIndex);
  }

  getPippinDate(event) {
    if(event && event.Pippin_Date ) {
      this.registerModel['EnO_Expiration'] = event.Pippin_Date;
    }else this.registerModel['EnO_Expiration'] = event.Pippin_Date;
  }

// Method to adjust date to 5 PM EST
adjustDateTo5PMEST(date: Date): Date {
  const adjustedDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  adjustedDate.setHours(17, 0, 0, 0); // Set time to 5 PM EST
  return adjustedDate;
}

  // Method to calculate expiration text based on conditions
expirationText() {
  if (!this.registerModel.EnO) {
    return 'None';
  }

  if (!this.registerModel.EnO_Expiration) {
    return 'Exp Known';
  }

  const expirationDate = this.adjustDateTo5PMEST(this.registerModel.EnO_Expiration);
  const currentEST = this.adjustDateTo5PMEST(new Date());

  const daysDifference = Math.floor((expirationDate.getTime() - currentEST.getTime()) / (1000 * 3600 * 24));

  if (daysDifference > 30) {
    return 'Valid';
  } else if (daysDifference > 0) {
    if (daysDifference === 1) {
      return `Expires in ${daysDifference} day`;
    } else {
      return `Expires in ${daysDifference} days`;
    }
  } else if (daysDifference === 0) {
    return 'Expires today';
  } else {
    return 'Expired';
  }
}

// Method to calculate expiration style based on conditions
expirationStyle() {
  if (!this.registerModel.EnO) {
    return { color: 'gray' };
  }

  if (!this.registerModel.EnO_Expiration) {
    return { color: 'gray', 'font-weight': 'bold' };
  }

  const expirationDate = this.adjustDateTo5PMEST(this.registerModel.EnO_Expiration);
  const currentEST = this.adjustDateTo5PMEST(new Date());

  const daysDifference = Math.floor((expirationDate.getTime() - currentEST.getTime()) / (1000 * 3600 * 24));

  if (daysDifference > 30) {
    return { color: '#8fd14f', 'font-weight': 'bold' };
  } else if (daysDifference >= 0) {
    return { color: '#fac710', 'font-weight': 'bold' };
  } else {
    return { color: '#f24726', 'font-weight': 'bold' };
  }
}

} 
