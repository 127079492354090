// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-new-widget {
  border-radius: 4px;
  padding: 8px;
  border: 1px solid var(--Pippin-Silver-Dark, #b6b6b6);
}
.add-new-widget .onDim {
  background: var(--Pippin-White-Regular, #fff);
  display: block;
}
.add-new-widget .onHover {
  display: none;
}

.add-new-widget:hover {
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #60CC70;
}
.add-new-widget:hover .onDim {
  display: none;
}
.add-new-widget:hover .onHover {
  background: var(--Pippin-Status-Positive-Lightest, #f6fff8);
  display: block;
}

.onActive {
  border: 1px solid #60CC70;
  border-radius: 4px 0px 0px 4px;
  background: rgb(15, 157, 88);
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/report-builder/rb-add-new-widget/rb-add-new-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,oDAAA;AACF;AAAE;EACE,6CAAA;EACA,cAAA;AAEJ;AAAE;EACE,aAAA;AAEJ;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,yBAAA;AACF;AAAE;EACE,aAAA;AAEJ;AAAE;EACE,2DAAA;EACA,cAAA;AAEJ;;AAEA;EACE,yBAAA;EACA,8BAAA;EACA,4BAAA;EACA,YAAA;AACF","sourcesContent":[".add-new-widget {\n  border-radius: 4px;\n  padding: 8px;\n  border: 1px solid var(--Pippin-Silver-Dark, #b6b6b6);\n  .onDim {\n    background: var(--Pippin-White-Regular, #fff);\n    display: block;\n  }\n  .onHover {\n    display: none;\n  }\n}\n\n.add-new-widget:hover {\n  border-radius: 4px;\n  padding: 8px;\n  border: 1px solid #60CC70;\n  .onDim {\n    display: none;\n  }\n  .onHover {\n    background: var(--Pippin-Status-Positive-Lightest, #f6fff8);\n    display: block;\n  }\n}\n\n.onActive{\n  border: 1px solid #60CC70;\n  border-radius: 4px 0px 0px 4px; \n  background: rgba(15, 157, 88, 1);\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
