
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable()
export class InvoiceService {

  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) { }

  getInvoiceOrders(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/invoice/admin/getinvoice';      
    return this.http.post(url, data ,httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrderProducts(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/invoice/admin/order/'+ orderId +'/orderProducts';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }


  getOrderProductsLists(orderID) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/invoice/admin/order/ListorderId/orderProducts';
    return this.http.post(url, orderID, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getInvoiceSpan(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/invoice/period/span';
    return this.http.post(url,data,httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  generatePDF(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
     responseType:'text' as 'json',
    }
    let url = this.config.getBasePath() + '/invoice/generatePDF';
    return this.http.post(url,data,httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  } 

}
