
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../authentication/auth.service';
import { ConfigService } from '../config/config.service';
import { CONSTANTS } from '../../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable()
export class FileService { 

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) { }  

  upload(file, OrderId, PropertyId) {
    let type = CONSTANTS.orderDocumentType.adminDocs;//Admin Type
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('User_ID', this.auth.getUserId())
    formData.append('Property_ID', PropertyId)
    console.log("file:", file);
    return this.http.put(this.config.getBasePath() + '/orders/order/' + OrderId + '/admin/' + type, formData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => observableThrowError(error.error)),)
  }

  absFileUpload(file, OrderId, PropertyId) {
    let type = CONSTANTS.orderDocumentType.abstractorDocs;//Abstractor Type
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('User_ID', this.auth.getUserId())
    formData.append('Property_ID', PropertyId)
    console.log("file:", file);
    return this.http.put(this.config.getBasePath() + '/abstractors/orders/absUpload/' + OrderId + '/admin/' + type, formData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => observableThrowError(error.error)),)
  }

  download(orderDocId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    return this.http.get(this.config.getBasePath() + '/orders/download/document/' + orderDocId, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => observableThrowError(error.error)),)
  }

  preview(orderDocId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    return this.http.get(this.config.getBasePath() + '/orders/preview/document/' + orderDocId, httpOptions).pipe(
      map((response: any) => response),
      catchError(error => observableThrowError(error.error)),)
  }

  delete(orderDocId, userId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    return this.http.delete(this.config.getBasePath() + '/orders/deldocument/user/' + userId + '/orderdocument/' + orderDocId, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => observableThrowError(error.error)),)
  }
    
  getTempleteUrls() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/get/template/urls';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => observableThrowError(error.error)),)
  }

}
