// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legal-pad {
  padding: 8px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/order-checklist/chk-legal/chk-legal.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF","sourcesContent":[".legal-pad{\n  padding: 8px 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
