// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-form-field-label {
  padding-left: 20px; /* Adjust the padding value as needed */
  /* Adjust the padding value as needed */
}`, "",{"version":3,"sources":["webpack://./src/app/custom-pricing/edit-custom-pricing/edit-custom-pricing.component.scss"],"names":[],"mappings":"AACA;EAEI,kBAAA,EAAA,uCAAA;EACD,uCAAA;AADH","sourcesContent":["\n.mat-form-field-label {\n   \n    padding-left: 20px; /* Adjust the padding value as needed */\n   /* Adjust the padding value as needed */\n  }\n\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
