// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verticalClass {
  vertical-align: baseline !important;
}

.marginClass {
  margin-right: 2px;
}

.verticalAlignSub {
  vertical-align: sub;
}

.centerButtonFlexEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/product/product.component.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;AACJ;;AAEE;EACE,iBAAA;AACJ;;AAEE;EACE,mBAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AACJ","sourcesContent":[".verticalClass {\n    vertical-align: baseline !important;\n  }\n\n  .marginClass {\n    margin-right: 2px;\n  }\n\n  .verticalAlignSub{\n    vertical-align: sub;\n  }\n  \n  .centerButtonFlexEnd{\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    margin-bottom: 13px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
