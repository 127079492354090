// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addclass {
  color: white !important;
  border-radius: 9px !important;
  font-size: 20px !important;
  font-weight: bold !important;
  background-color: #60cc70 !important;
  border-color: #0F9D58 !important;
  padding: 0px 0px !important;
  scale: 0.8;
  min-width: 30px !important;
  line-height: 30px !important;
}

.pl-1, .px-1 {
  padding-left: 0.6rem !important;
}

.pr-1, .px-1 {
  padding-right: 0.6rem !important;
}

.fa-lg {
  font-size: 1.2rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/abstractor/abstractor-new/abstractor-new.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,6BAAA;EACA,0BAAA;EACA,4BAAA;EACA,oCAAA;EACA,gCAAA;EACA,2BAAA;EACA,UAAA;EACA,0BAAA;EACA,4BAAA;AACJ;;AAEA;EACI,+BAAA;AACJ;;AACA;EACI,gCAAA;AAEJ;;AACA;EACI,4BAAA;AAEJ","sourcesContent":[".addclass {\n    color: white !important;\n    border-radius: 9px !important;\n    font-size: 20px !important;\n    font-weight: bold !important;\n    background-color: #60cc70 !important;\n    border-color: #0F9D58 !important;\n    padding: 0px 0px !important;\n    scale: 0.8;\n    min-width: 30px !important;\n    line-height: 30px !important;\n}\n\n.pl-1, .px-1 {\n    padding-left: 0.6rem !important;\n}\n.pr-1, .px-1 {\n    padding-right: 0.6rem !important;\n}\n\n.fa-lg {\n    font-size: 1.2rem  !important;\n   \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
