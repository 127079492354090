// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eta-error {
  font-size: 75%;
  margin-top: -16px;
}

.subtitle {
  font-size: 14px;
  color: #666;
  position: relative;
  top: -16px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  /* Additional styling properties */
}`, "",{"version":3,"sources":["webpack://./src/app/orders/edit-order-details/edit-order-details.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,iBAAA;AACJ;;AAGA;EACI,eAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;EACA,iDAAA;EACA,kCAAA;AAAJ","sourcesContent":[".eta-error{\n    font-size: 75%;\n    margin-top: -16px;\n}\n\n\n.subtitle {\n    font-size: 14px;\n    color: #666;\n    position: relative;\n    top:-16px;\n    font-family: Roboto, \"Helvetica Neue\", sans-serif;\n    /* Additional styling properties */\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
