import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { CONSTANTS } from "app/app.constants";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { OrdersService } from "../../services";
import * as momentTimeZone from "moment-timezone";
import * as moment from "moment";
import { take } from "rxjs/operators";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-order-checklist",
  templateUrl: "./order-checklist.component.html",
  styleUrls: ["./order-checklist.component.scss"],
})
export class OrderChecklistComponent  {
  orderDetails: any;
  title = "";
  maxDate: any;
  constants = CONSTANTS;

  message: any = [];
  orderCheckList = [];
  companyCheckList = [];
  companyName = "";
  errorMsg: any;
  getUSDateString = new Date("01/02/1500").toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  min_Date = new Date(this.getUSDateString);
  constructor(
    
    private ordersService: OrdersService,
    private builder: RptBuilderService,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<OrderChecklistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    
  }

  ngOnInit() {
    // let dateTest = new Date(this.orderDetails.Order_ETA).toLocaleString(
    // "en-US",
    //   { timeZone: "America/New_York" }
    // );
    // this.maxDate = new Date(dateTest);
    this.initDialog();
    this.maxDate = new Date();
    this.ordersService.checklistData$.pipe(take(1)).subscribe((chkLst: any) => {
      this.orderCheckList = []
      // this.orderCheckList = chkLst;
      this.compressChecklist();
    });
    this.ordersService.cmpChecklistData$
      .pipe(take(1))
      .subscribe((chkLst: any) => (this.companyCheckList = chkLst));
    this.ordersService.orderCmpName$
      .pipe(take(1))
      .subscribe((cname: any) => (this.companyName = cname));
    setTimeout(() => {
      this.getChecklist();
    }, 0);
    this.getSearchPackageDetails();
  }

  initDialog(){
    this.title = this.data.title;
    this.orderDetails = this.data.orderDetails;
  }

  getSearchPackageDetails() {
    this.ordersService.orderDetails$
      .pipe(take(1))
      .subscribe((order: any) =>
        this.builder
          .getReportDetails(order.Report_Version)
          .pipe(take(1))
          .subscribe()
      );
  }

  confirm(isPartial) {
    let retObj = {
      checkListObj: this.orderCheckList,
      checkListCmp: this.companyCheckList,
      isPartial: isPartial,
    };
    this.ordersService.setChecklistData(this.orderCheckList);
    this.ordersService.setCmpChecklistData(this.companyCheckList);
    this.dialogRef.close({
      state: true,
      retObj: retObj,
    })
  }

  goBack() {
    this.ordersService.setChecklistData(this.orderCheckList);
    this.ordersService.setCmpChecklistData(this.companyCheckList);
    this.dialogRef.close({ state: false });
  }

  close(){
    this.dialogRef.close({ state: false });
  }

  getChecklist() {
    if (this.orderCheckList.length == 0)
      this.ordersService.getChecklistTypes().subscribe(
        (data) => (this.orderCheckList = data),
        (err) => (this.errorMsg = err)
      );
    this.ordersService.orderCmpId$.pipe(take(1)).subscribe((cmpId: any) =>
      this.ordersService.getQCCompanyChecklistTypes(cmpId).subscribe(
        (data) => (this.companyCheckList = data),
        (err) => (this.errorMsg = err)
      )
    );
  }

  additionalData(event, checklist) {
    checklist.Additional_Data = event;
  }

  statusChange(event, checklist) {
    checklist.Order_Checklist_Value = event.checked;
    if (!checklist.isExpanded) this.toggleExpand(checklist);
    // this.cdr.detectChanges();
  }

  toggleStatus = (checklist) => {
    checklist.Order_Checklist_Value = !checklist.Order_Checklist_Value;
    if (!checklist.isExpanded) this.toggleExpand(checklist);
    // this.cdr.detectChanges();
  };

  // sortOrderChecklist = (a, b) => {
  //   let retVal = b.Order_Checklist_Value !== undefined && b.Order_Checklist_Value  ? 1 : 0;
  //   return retVal
  // }

  // trackItem (index, item) {
  //   return item.Order_Checklist_Value;
  // }

  checkForPartStatus() {
    return (
      this.orderCheckList.some(
        (checkList) =>
          (checkList.Order_Checklist_Value == undefined ||
            !checkList.Order_Checklist_Value) &&
          checkList.Is_Mandatory == 1
      ) ||
      this.companyCheckList.some(
        (checkList) =>
          (checkList.Value == undefined || !checkList.Value) &&
          checkList.Is_Mandatory == 1
      )
    );
  }

  checkForStatus() {
    return (
      this.orderCheckList.some(
        (checkList) =>
          checkList.Order_Checklist_Value == undefined ||
          !checkList.Order_Checklist_Value
      ) ||
      this.companyCheckList.some(
        (checkList) => checkList.Value == undefined || !checkList.Value
      )
    );
  }

  getPippinDate(event, checklist) {
    if (event && event.Pippin_Date) {
      let estShift = momentTimeZone
        .tz(event.Pippin_Date, "America/New_York")
        .isDST()
        ? 4
        : 5;
      checklist.Additional_Data = moment(event.Pippin_Date)
        .utcOffset(moment(event.Pippin_Date).utcOffset())
        .add(estShift, "hours")
        .add(330, "minutes")
        .toDate();
    }
  }

  isItemInvalid(checkList) {
    return (
      checkList.Order_Checklist_Value == undefined ||
      (checkList.Order_Checklist_Value == 0 && checkList.Is_Mandatory == 1)
    );
  }

  isLoaded = (checkList) => checkList.Order_Checklist_Value !== undefined;

  isDataInvalid(checkList) {
    return (
      checkList.Additional_Data == undefined ||
      checkList.Additional_Data == "" ||
      (checkList.Additional_Data == 0 &&
        checkList.Order_Checklist_Category == "Number")
    );
  }

  statusChangeCmp(event, checklist) {
    checklist.Value = event.checked;
    // this.cdr.detectChanges();
  }

  toggleStatusCmp = (checklist) => {
    checklist.Value = !checklist.Value;
    // this.cdr.detectChanges();
  };

  isItemInvalidCmp(checkList) {
    return (
      checkList.Value == undefined ||
      (checkList.Value == 0 && checkList.Is_Mandatory == 1)
    );
  }

  isLoadedCmp = (checkList) => checkList.Value !== undefined;

  compressChecklist = () =>
    this.orderCheckList.forEach((item) => (item.isExpanded = false));

  toggleExpand = (checklist) => {
    this.orderCheckList.forEach((item) => {
      if (item.Order_Checklist_Type_ID !== checklist.Order_Checklist_Type_ID)
        item.isExpanded = false;
    });
    checklist.isExpanded = !checklist.isExpanded;
  };
}
