import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
  AuthService,
  RoutingStateService,
  ConfigService,
  StateService,
} from "../services";
import { CONSTANTS } from "app/app.constants";
import { SwPush } from "@angular/service-worker";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"],
})
export class BaseComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private routingState: RoutingStateService,
    private config: ConfigService,
    private stateService: StateService,
    private swPush: SwPush
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.auth.isServerReachable$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((stat) => {
        console.log("In Base", stat);
        if (stat) this.routeToPage();
      });
    // this.auth.serverHealthCheck()
    // .subscribe((res)=>{
    // if(res.statusText == 'OK'){
    // if(this.auth.isLoggedIn()) {
    //   this.stateService.setUploadTypesData(null);
    //   this.routingState.clearHistory()
    //   // this.config.setMySidebar(true);
    //   if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) {
    //   if(this.auth.getRedirectURL() && this.auth.getRedirectURL().length > 0) {
    //     this.router.navigateByUrl(this.auth.getRedirectURL());
    //     this.auth.resetRedirectURL();
    //   } else this.router.navigate(['orders/inprocess']);
    // } else {
    //     if(this.auth.getRedirectURL() && this.auth.getRedirectURL().length > 0) {
    //       this.router.navigateByUrl(this.auth.getRedirectURL());
    //       this.auth.resetRedirectURL();
    //     }
    //     else this.router.navigate(['orders/new']);
    //   }

    // }
    // else this.router.navigate(['/login']);
    // }

    // })
  }

  routeToPage() {
    if (this.auth.isLoggedIn()) {
      this.stateService.setUploadTypesData(null);
      this.routingState.clearHistory();
      // this.config.setMySidebar(true);
      if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) {
        if (
          this.auth.getRedirectURL() &&
          this.auth.getRedirectURL().length > 0
        ) {
          this.router.navigateByUrl(this.auth.getRedirectURL());
          this.auth.resetRedirectURL();
        } else this.router.navigate(["orders/inprocess"]);
      } else {
        if (
          this.auth.getRedirectURL() &&
          this.auth.getRedirectURL().length > 0
        ) {
          this.router.navigateByUrl(this.auth.getRedirectURL());
          this.auth.resetRedirectURL();
        } else this.router.navigate(["orders/new"]);
      }
    } else this.router.navigate(["/login"]);
  }
}
