import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { UntypedFormGroup, FormsModule, UntypedFormBuilder, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, EqualValidator, PasswordValidator, PricingService, UsersService, ConfigService, UsermessageService, OrdersService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { Observable } from 'rxjs';
import { SuccessComponent } from '../../dialog/success/success.component';
import { CONSTANTS } from '../../app.constants';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil,take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';



export interface Customer {
  customer_ID: number;
    Product_ID: string;
  User_Login_Name: string;
 
}

@Component({
  selector: 'app-edit-custom-pricing',
  templateUrl: './edit-custom-pricing.component.html',
  styleUrls: ['./edit-custom-pricing.component.scss']
})
export class EditCustomPricingComponent implements OnInit {
  @ViewChildren('myFirstFocus') myFirstFocus: QueryList<MatSelect>;
  @ViewChildren('myFirstEditFocus') myFirstEditFocus: QueryList<ElementRef>;
   @ViewChild('customerSelect') customerSelect: MatSelect;
  


  editPricingFrm: UntypedFormGroup;
  pricingModel: any = {};
  pricingData: any = {};
  states: any[];
  counties: any[];
  products: any[];
  customers : any[];
  selectedStates = [];
  error: string = '';
  costId='';
  userId='';
  errorType: string = '';
  errorMsg: any = {};
  constants=CONSTANTS;
  input = "";
  focused: boolean = false;
  editFocused: boolean = false; 
  filteredCustomers: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  customerFilterCtrl: UntypedFormControl = new UntypedFormControl();
  protected _onDestroy = new Subject<void>();
  timer: any = [];
  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    public pricingService: PricingService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog:MatDialog,
    private userService: UsersService,
    private config: ConfigService,
    private userMsg: UsermessageService,
    private orderService:OrdersService
  ) {
    this.editPricingFrm = fb.group({
      'Customer_ID': [null, Validators.required],
      'State_Short_Name': [null, Validators.required],
      'County_Name': [null, Validators.required],
      'Product_Description': [null, Validators.required],
      'Custom_Cost_Amount': [null, Validators.compose([Validators.required, Validators.maxLength(14)])],
      'Original_Cost_Amount': [null, Validators.required],
      'Custom_Cost_Status':[null]
    })
    this.editPricingFrm.valueChanges.subscribe(data => this.error = '');
  }

  ngOnInit() {
    if (this.route.snapshot.params['costId'] && this.route.snapshot.params['userId']) {
      this.costId = this.route.snapshot.params['costId'];
      this.userId = this.route.snapshot.params['userId'];
      this.getPricingDetails()
    }
    else {
      this.pricingModel.Customer_ID = '';
      this.pricingModel.State_Short_Name = '';
      this.pricingModel.County_Name = '';
      this.pricingModel.Original_Cost_Amount = null;
      this.pricingModel.Custom_Cost_Amount = null;
      this.resetFormFields();
       this.pricingService.getCustomerListForCustomPricing()
       .subscribe((customers)=>{
         this.customers =customers;
         this.filteredCustomers.next(this.customers.slice());
       })
      //  this.getAvailableStateLocal();
      this.pricingService.getAvailableStateList().subscribe((states)=> {
        if(states)
        this.states = states
        })
       }
    
    this.customerFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(()=>{
      this.filtercustomer();
    });

    this.timer.push(setTimeout(() => { this.config.setSideBarNode(0)}, 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

//   getAvailableStateLocal(){
//     let Allstates = JSON.parse(sessionStorage.getItem('AllStateAvailable'));
//     if(Allstates) this.setState(Allstates)         
//     else this.getAvailableStateList();
// }
  
//   getAvailableStateList(){
//     this.pricingService.getAvailableStateList().subscribe((states) => { 
//       if(states){
//         this.setState(states)         
//         sessionStorage.setItem('AllStateAvailable',JSON.stringify(states)); 
//        setTimeout(function(){sessionStorage.removeItem("AllStateAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });

//   }

//   setState(AllstatesData){
//     if(AllstatesData) this.states = AllstatesData;
//   }

  protected filtercustomer() {
    if (!this.customers) {
      return;
    }
    
    let search = this.customerFilterCtrl.value;
    //const countygroupsCopy = this.copyCountyGroups(this.counties);
    if (!search) {
      this.filteredCustomers.next(this.customers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCustomers.next(
      this.customers.filter(customer => (customer && (customer.User_Full_Name && customer.User_Full_Name.toLowerCase().indexOf(search) > -1) || (customer.Email_Address && customer.Email_Address.toLowerCase().indexOf(search) > -1))
      )
    );
  }





  ngAfterViewInit(){
    if(this.costId){
        if(this.myFirstEditFocus && this.myFirstEditFocus.changes) {      
        this.myFirstEditFocus.changes.subscribe((input) => {
          if(input.first && !this.editFocused) {
            this.editFocused = true;
            this.timer.push(setTimeout(() => { input.first.nativeElement.focus() }, 0));
          }
        });
       // this.setIinitialvalue();
      }
    }
    else {
      if(this.myFirstFocus && this.myFirstFocus.changes) {      
        this.myFirstFocus.changes.subscribe((select) => {
          if(select.first && !this.focused) {
            this.focused = true;
            this.timer.push(setTimeout(() => { select.first.focus() }, 0))
          };
        });
      }
    }
  }

  clearError() {
    this.errorMsg.msg = '';
  }

  getCountyList(event) {
    this.editPricingFrm.controls['County_Name'].reset();
    this.resetFormFields();
    if (event.length != 0) {
       this.pricingService.getAvailableCountyList(this.pricingModel.State_Short_Name).subscribe((counties)=>{
         if (counties)
         this.counties = counties
       });
      //this.counties = this.pricingService.getCountyList(this.pricingModel.State_Short_Name);
      this.getProductList();
    }
  }

  customerChange(event) {
    console.log("Event", event);
    this.getProductList();
  }

  getProductList() {
    this.resetFormFields()
    if (this.pricingModel.State_Short_Name && this.pricingModel.County_Name)
      this.getOrderProductsForClient(this.pricingModel.Customer_ID);
  }

  getOrderProductsForClient(customerId) {
    let customerIndex = this.customers.findIndex((customer) => (customer.ID == customerId) );
    if(customerIndex > -1) {
      let userId; 
      if(this.customers[customerIndex].Organization_ID) userId = this.customers[customerIndex].Organization_ID;
      else userId = this.customers[customerIndex].ID
      this.orderService.getSubNBaseProductforCustom(userId)
      .subscribe((data) => {
          const ids = data.map(o => o.Product_ID)
          const filtered = data.filter(({Product_ID}, index) => !ids.includes(Product_ID, index + 1))
          if (filtered) this.products = filtered
      })
    }
  }

  getPricingDetails() {
    this.pricingService.getCustomPricingCost(this.route.snapshot.params['costId'],this.route.snapshot.params['userId'])
      .subscribe((details) => {
        if(details){
          this.loadData(details);
        }        
      }, (err) => {
        console.log(err);
      })
  }

  findPricing() {
    this.input = "";
    if (this.pricingModel.State_Short_Name && this.pricingModel.County_Name && this.pricingModel.Product_Description && this.pricingModel.Customer_ID) {
      this.pricingService.getCustomAndOrginalPrice(this.pricingModel.State_Short_Name,
        this.pricingModel.County_Name, this.pricingModel.Product_Description, this.pricingModel.Customer_ID)
        .subscribe((data) => {
          if (data) {
            this.pricingModel.Original_Cost_Amount = data.Original_Cost_Amount;
            this.pricingModel.Custom_Cost_Amount = data.Custom_Cost_Amount;
          }
        })
    }
  }

  openDialog(msg) {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: 'Success',
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {        
        this.router.navigate(['/custom-pricing/view']);
      });
  }

  loadData(data) {
    this.pricingModel.State_Short_Name = data.State_Short_Name;
    this.pricingModel.County_Name = data.County_Name;
    this.pricingModel.Product_Description = data.Product_Description;
    this.pricingModel.Custom_Cost_Amount = data.Custom_Cost_Amount;
    this.pricingModel.Customer_ID = data.Email_Address;
    this.pricingModel.Customer_User_ID = data.Customer_ID;
    this.pricingModel.Organization_ID = data.Organization_ID;
    this.pricingModel.Organization_Name = data.Organization_Name;
    this.pricingModel.Original_Cost_Amount = data.Cost_Amount;
    this.loadCostStatus(data);
  }

  updatePricing() {
    var data = {
      Custom_Price: this.pricingModel.Custom_Cost_Amount,
      User_ID: this.auth.getUserId(),
      Customer_ID: this.pricingModel.Organization_ID ? this.pricingModel.Organization_ID : this.pricingModel.Customer_User_ID 
    }
    this.pricingService.updateCustomPricing(this.route.snapshot.params['costId'], data)
      .subscribe((result) => {
        this.successMsgFunc('Updated successfully.')
        console.log("result", result);
      }, (err) => {
        this.errorMsg = err;
        this.errorType = err.type;
        console.log(err);
      })
  }

  userAction() {
    if (this.costId) this.updatePricing()
    else this.createPricing();
  }
  createPricing() {
    this.pricingModel.Custom_Cost_Amount = this.pricingModel.Custom_Cost_Amount.toString();
    this.pricingModel.Product_ID = this.pricingModel.Product_Description;
    this.pricingService.createSingleCustomPricing(this.pricingModel)
      .subscribe((result) => {
        this.successMsgFunc('Created successfully.')
      }, (err) => {
        this.errorMsg = err;
        this.errorType = err.type;
      })
  }

  loadCostStatus(data){
    this.pricingModel.Custom_Cost_Status=data.Custom_Cost_Status;
    if(data.Custom_Cost_Status == CONSTANTS.customCostStatus.active) this.pricingModel.Custom_Cost_State=true;
    else this.pricingModel.Custom_Cost_State=false;
  }
  
  editCustomCostStatus(event){
    var data={
      Customer_ID: this.pricingModel.Organization_ID ? this.pricingModel.Organization_ID : this.pricingModel.Customer_User_ID ,
      Cost_ID:this.route.snapshot.params['costId'],
      Admin_ID:this.auth.getUserId()
    }
    this.pricingService.updateCustomCostStatus(data,event.checked)
    .subscribe((result) => {
      if(event.checked) this.pricingModel.Custom_Cost_Status=true;
       else  this.pricingModel.Custom_Cost_Status=false;
    }, (err) => {
      console.log(err);
      this.errorMsg = err;
      this.errorType = err.type;
    })
  }
  
  resetFormFields(){
    this.editPricingFrm.controls['Product_Description'].reset();
    this.editPricingFrm.controls['Original_Cost_Amount'].reset();
    this.editPricingFrm.controls['Custom_Cost_Amount'].reset();
  }

  // getPricingFormat(e, pricingModel) {
  //   if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
  //   // if ((this.input.length == 0 && e.key == "0")) e.preventDefault();
  //   else {
  //     if ((e.keyCode == 8 || e.keyCode == 46) && (this.input.length > 0 || this.input.length < this.config.getLimitPriceMaxLength())) {
  //       this.input = this.input.slice(0, this.input.length - 1);
  //       e.target.value = this.numberFormat(this.input);
  //       pricingModel.Custom_Cost_Amount = e.target.value;
  //     }
  //     else if (this.input.length < this.config.getLimitPriceMaxLength()) {
  //       var key = this.keyValue(e.keyCode);
  //       if (key) {
  //         this.input = this.input+ key;
  //         e.target.value = this.numberFormat(this.input);
  //         pricingModel.Custom_Cost_Amount = e.target.value;
  //       }
  //     }
  //     return false;
  //   }
  // }

  getPricingFormat(e, pricingModel) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true; // Allow left/right arrow keys and tab key
  
    // Allow negative sign only if it's at the start and there are no other negative signs
    if (e.key === "-" && this.input.length === 0) {
      this.input = e.key + this.input;
      e.target.value = this.numberFormat(this.input);
      pricingModel.Custom_Cost_Amount = e.target.value;
      return false;
    }
  
    // Prevent leading zeros
    // if (this.input.length == 0 && e.key == "0") e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.input.length > 0 || this.input.length < this.config.getLimitPriceMaxLength())) {
        this.input = this.input.slice(0, this.input.length - 1);
        e.target.value = this.numberFormat(this.input);
        pricingModel.Custom_Cost_Amount = e.target.value;
      }
      else if (this.input.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.input = this.input + key;
          e.target.value = this.numberFormat(this.input);
          pricingModel.Custom_Cost_Amount = e.target.value;
        }
      }
      return false;
    }
  }
  

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  successMsgFunc(msg){
    this.userMsg.setSuccessMsg(msg);
    this.router.navigate(['/custom-pricing/view']);
  }

  checkForFormValid() {
    // console.log("Checkking Form Validation",this.editPricingFrm);
    if(this.editPricingFrm.valid) return false;
    else return true;
  }
}
