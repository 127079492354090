import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

@Component({
  selector: 'app-order-change-reason',
  templateUrl: './order-change-reason.component.html',
  styleUrls: ['./order-change-reason.component.scss']
})
export class OrderChangeReasonComponent  {
  messageText = '';
  title = '';

  constructor(
    public dialogRef: MatDialogRef<OrderChangeReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.initDialog();
  }

  initDialog(){
    this.title = this.data.title;
    // this.messageText = this.data.msgSendType;
  }

  confirm() {
    this.dialogRef.close({
      state: true,
      Message_Text: this.messageText.trim(),
    })
  }

  isMessageEmpty() {
    return this.messageText.length == 0;
  }

  Close() {
    this.dialogRef.close(false);
  }

}
