// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chkl-sop-lst {
  padding-inline-start: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/order-checklist/chk-delivery/chk-delivery.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF","sourcesContent":[".chkl-sop-lst{\n  padding-inline-start: 16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
