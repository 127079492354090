import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { debounce } from "lodash";
import { DataService } from "./customer-dropdown.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Component({
  selector: "app-customer-dropdown",
  templateUrl: "./customer-dropdown.component.html",
  styleUrls: ["./customer-dropdown.component.scss"],
})
export class CustomerDropdownComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  options: string[];
  inputControl = new FormControl();
  @Input() parentFormGroup: FormGroup;
  @Input() parentFormFieldToUpdate: string;
  @Output() optionSelected = new EventEmitter<any>();
  @Input() value: any;

  previouslySelectedOption: any = { displayName: "" };

  constructor(private dataService: DataService) {}

  getDisplayValue(e) {
    if (!e) return "";
    if (e.User_ID) {
      const name =
        `${e.User_First_Name ? e.User_First_Name : ""} ` +
        ` ${e.User_Last_Name ? e.User_Last_Name : ""}`;
      const companyName = e.Organization_Name ? `${e.Organization_Name}` : "";
      const emailAddress = e.Email_Address ? e.Email_Address : "";
      return [companyName, name, emailAddress].filter((e) => e).join(" - ");
    }
    if (typeof e === "string") {
      console.log(e);
      return e;
    }
    console.error("Unkown type getDisplayvalue()", e);
    return "";
  }

  emitOptionSelected(event: MatAutocompleteSelectedEvent): void {
    console.log("Option selected:", event.option.value);
    this.optionSelected.emit(event.option.value);
    this.previouslySelectedOption = event.option.value;
  }

  ngOnInit(): void {
    const debouncedFetch = debounce((value: string) => {
      this.dataService.getOptions(value).subscribe((options) => {
        this.options = options.map((e) => ({
          ...e,
          displayName: this.getDisplayValue(e),
        }));
      });
    }, 500);

    this.inputControl.valueChanges.subscribe((value) => {
      if (typeof value === "string") debouncedFetch(value);
      else {
        const customerId =
          `${value.User_ID}:` +
          `${value.Organization_ID ? value.Organization_ID : ""}`;
        if (this.parentFormGroup)
          this.parentFormGroup
            .get(this.parentFormFieldToUpdate)
            .setValue(customerId);
      }
    });
    if (this.value) {
      this.previouslySelectedOption = this.value;
      setTimeout(() => {
        this.inputControl.setValue(this.value);
      }, 50);
    }
    debouncedFetch("");
  }

  openDropdown() {
    this.autocomplete.openPanel();
  }
}
