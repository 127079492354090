// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errMsg {
  margin-top: -1.3em;
  font-size: 75%;
}

.dottedLine {
  cursor: not-allowed;
}

.subtitle {
  font-size: 14px;
  color: #666;
  position: relative;
  top: -16px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  /* Additional styling properties */
}`, "",{"version":3,"sources":["webpack://./src/app/orders/edit-quote-details/edit-quote-details.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;AACF;;AACA;EAOE,mBAAA;AAJF;;AAOA;EACE,eAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;EACA,iDAAA;EACA,kCAAA;AAJF","sourcesContent":[".errMsg {\n  margin-top: -1.3em;\n  font-size: 75%;\n}\n.dottedLine {\n  // background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);\n  // background-size: 4px 1px;\n  // background-repeat: repeat-x;\n  // position: relative;\n  // background-position-y: 24px;\n  // padding-bottom: 8px;\n  cursor: not-allowed;    \n}\n\n.subtitle {\n  font-size: 14px;\n  color: #666;\n  position: relative;\n  top:-16px;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  /* Additional styling properties */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
