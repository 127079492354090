import { Component, OnInit ,OnDestroy ,Input, Output, EventEmitter} from '@angular/core';
import { ProgressBarService} from '../../services';
import { CONSTANTS } from '../../app.constants';
import * as momentTimeZone from 'moment-timezone';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnDestroy { 
  constants=CONSTANTS;  
  @Input() quoteFlag: boolean = false;
  @Input() order: any;  
  @Output() changeState= new EventEmitter<any>();
  @Input() quoterequestFlag: boolean = false;
  constructor(
    private progressServ:ProgressBarService    
  ) { }
  ngOnInit() {
   
  }

  getProgress(){   
    return this.progressServ.getProgressState().toString() + '%';
  }

  getPrimaryColor(){    
    return this.progressServ.getPrimaryColor();   
  }

  ngOnDestroy(){
    this.progressServ.resetAll();
  }

  getStage(){
    return this.progressServ.getStage();
  }

  getTimeline(){
    return this.progressServ.getTimeline();
  }

  getHeight(){
    return this.progressServ.getHeight() + 'px';
  }

  getTotalStages(){
    return this.progressServ.getTotalStages();
  }

  getBarType(){
    return this.progressServ.getBarType();
  }

  changeOrderState(state){
    this.changeState.emit(state);
  }

  fetchCompStatus(){
    if(this.getBarType() == 1) return this.progressServ.getPrimaryColor();
    else {  
      if(this.order.Order_Status == CONSTANTS.orderStatus.completed) return this.progressServ.getPrimaryColor();
    }
  }

  fetchQuoteCompStatus() {
    if(this.getBarType() == 1) return this.progressServ.getPrimaryColor();
    else {  
      if(this.order.Order_Status == CONSTANTS.orderStatus.quotecompleted) return this.progressServ.getPrimaryColor();
    }
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }
}
