import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root'
})
export class UsermessageService {
    timer1: any;
    queueTimer: any;
    private successMsg: string;
    private errorMsg: string;
    private queueMsg: string;
    constructor( private config:ConfigService) { }

    setSuccessMsg(successMsg) {
        this.successMsg = successMsg;
        this.timer1 = setTimeout(() => {
            this.setSuccessMsgNull()
        }, this.config.successTimeout)
    }

    setQueueMsg(queueMsg) {
        this.queueMsg = queueMsg;
        this.queueTimer = setTimeout(() => {
            this.setQueueMsgNull()
        }, this.config.successTimeout)
    }

    setClearSuccessTimeout() {
        window.clearTimeout(this.timer1);
    }


    setClearQueueTimeout() {
        window.clearTimeout(this.queueTimer);
    }

    setSuccessMsgNull() {
        this.successMsg = '';
    }

    setQueueMsgNull() {
        this.queueMsg = '';
    }

    getSuccessMsgData() {
        return this.successMsg;
    }

    getQueueMsgData() {
        return this.queueMsg;
    }

    setSuccessCopyMsg(successMsg) {
        this.successMsg = successMsg;
        this.timer1 = setTimeout(() => {
            this.setSuccessMsgNull()
        }, 3000)
    }

    setErrorMsgNull() {
        this.errorMsg = "";
    }


    getErrorMsgData() {
        return this.errorMsg;
    }

    setErrorMsg(errorMsg) {
        this.errorMsg = errorMsg;
        this.timer1 = setTimeout(() => {
            this.setErrorMsgNull()
        }, this.config.successTimeout)
    }

}
