import { Component, OnInit, ViewChild, ElementRef, Inject, OnDestroy } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { AuthService, OrdersService, ConfigService, AwsS3Service } from '../../services';
import { FileService } from '../../services';
import { UUID } from 'angular2-uuid';
import * as _ from "lodash";
import { Observable } from 'rxjs';
import { CONSTANTS } from '../../app.constants';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
// import { AuthService, OrdersService, ProgressBarService, UsersService, MessagesService, ConfigService } from '../../services';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import * as momentTimeZone from 'moment-timezone';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EtaAlertComponent } from '../eta-alert/eta-alert.component';
import { SuccessComponent } from '../success/success.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocCategoryComponent } from 'app/doc-category/doc-category.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-drag-drop-docs',
  templateUrl: './drag-drop-docs.component.html',
  styleUrls: ['./drag-drop-docs.component.scss']
})
export class DragDropDocsComponent implements OnInit, OnDestroy  {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  @ViewChild('otherDocTagInp') otherDocTagInp: ElementRef;
  private fileList: any = [];
  message = '';
  title = '';
  // currentUpload: Upload;
  dropzoneActive: boolean = false;
  newDocs: any = [];
  constants = CONSTANTS;
  tagDocumentName: any;
  isOtherDocumentTag:boolean = false;
  otherDocsTag:boolean = false;
  timer: any = [];
  otherDocTagInput= new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]);
  documentCategories:any = [];
  private ngUnsubscribe = new Subject();

  constructor( private auth: AuthService,
    private fileService: FileService, private orderService: OrdersService, private router: Router, private config: ConfigService, private s3Service: AwsS3Service,
    public dialogRef: MatDialogRef<DragDropDocsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private matDialog:MatDialog, 
    public dialog: MatDialog) {
    
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.initDialog();
    this.fetchUploadProgress();
    this.orderService.getDocumentCategories().subscribe();
    this.timer.push(setTimeout(()=> { this.myFirstFocus.nativeElement.focus() }, 0));
    this.orderService.documentCategories$.pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data) => {
      this.documentCategories = data;
    });
    this.message['docs'] = this.message['docs'].map((item) => {
      if(item.Doc_Cat_ID){
        let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
        item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
      }
      else item.Category = 'Click to categorize'
      return item;
    });
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  confirm() {    
    if(this.message['isTrackerProOrder']) {
      if(this.message['docs'].length == 0) this.openModal('Submit document to TrackerPro', 'Please add a deliverable to submit.');
      else {
         this.dialogRef.close({ value: 'next' }); 
        this.clearTime();
      }
    }
    else{
       this.dialogRef.close({ value: 'next' }); 
      this.clearTime();
    }
  }

  openModal(title, msg) {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => { });
  }

  // orderConfirm(){
  //   console.log("this.resut",this.result) 
  //   let disposable = this.matDialog.open(EtaAlertComponent, {
  //     title: 'Confirm order',
  //     message: 'Grand total is $0. Do you want to confirm order?',
  //     value: 'Confirm Order'
  //   }, this.config.getDialogOptions()) 
  // }

  Close() {
     this.dialogRef.close({ value : false });
    this.clearTime();
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  handleDrop(fileList: FileList) {
    if (this.checksize(fileList)) {
      let filesIndex = _.range(fileList.length)
      var fileUploads = [];
      _.each(filesIndex, (idx) => {
        var tempDocId = UUID.UUID();
        this.newDocs.push({ docId: tempDocId, name: fileList[idx].name, completed: 0 })
        fileUploads.push(this.uploadToS3AndUpdatePath(tempDocId, fileList[idx]))
      })
      Promise.all(fileUploads)
        .then((files) => {
          this.removeCompletedDocs(files);
          var succesFiles = files.filter((file) => file !== undefined);
          if (succesFiles.length > 0) this.getDocs(this.message['orderId']);
        })
        .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload) })
    }
    else {
      this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
    }

  }

  getDocs(orderId) {
    // this.orderService.getOrderRelatedDocs(orderId)
    this.orderService.getOrderDocuments(orderId)
      .subscribe((data) => {
        let documents = data.filter((docType) => docType.Document_Type == CONSTANTS.orderDocumentType.adminDocs)
        this.message['docs'] = documents.map((item) => {
          if(item.Doc_Cat_ID){
            let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
            item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
          }
          else item.Category = 'Click to categorize'
          return item;
        });
      }, (err) => {
        console.log(err);
      })
  }

  openFileSelector() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
    // this.fileInput.nativeElement.value = null;
  }

  uploadDocument(event) {
    if (this.validateFilenames(event)) {
      var fileUploads = [];
      if (this.checksize(event.target.files)) {
        for (var i = 0; i < event.target.files.length; i++) {
          var tempDocId = UUID.UUID();
          this.newDocs.push({ docId: tempDocId, name: event.target.files[i].name, completed: 0 })
          // fileUploads.push(this.uploadTagDocToS3AndUpdatePath(tempDocId, event.target.files[i], this.tagDocumentName, this.otherDocsTag))
          fileUploads.push(this.uploadToS3AndUpdatePath(tempDocId, event.target.files[i]))
        }
        Promise.all(fileUploads)
          .then((files) => {
            this.removeCompletedDocs(files);
            var succesFiles = files.filter((file) => file !== undefined);
            if (succesFiles.length > 0) this.getDocs(this.message['orderId']);
          })
          .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload) })
      }
      else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    }
    else {
      // this.openErrorPopup(CONSTANTS.apiErrors.validateFile)
    }

  }

  removeCompletedDocs(files) {
    for (var i = 0, len = files.length; i < len; i++) {
      for (var j = 0, len2 = this.newDocs.length; j < len2; j++) {
        if (files[i] && files[i].docId === this.newDocs[j].docId) {
          this.newDocs.splice(j, 1);
          len2 = this.newDocs.length;
        }
      }
    }
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(files, (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024);
    if (largeFiles.length > 0) return false;
    else return true;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => { });
  }

  validateFilenames(event) {
    var flag = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      var filename = event.target.files[i].name;
      if (filename.indexOf(',') > -1) flag++;
    }
    if (flag == 0) return true;
    else return false;
  }


  deleteFile(filename, orderDocId) {
    this.matDialog.open(ConfirmComponent, {data:{
      title: 'Delete Document',
      message: 'Are you sure you want to delete ' + filename + ' ?'
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.fileService.delete(orderDocId, this.auth.getUserId())
            .subscribe(
              (data) => {
                this.getDocs(this.message['orderId']);
              },
              (err) => {
                console.log(err);
                // this.openErrorPopup(CONSTANTS.apiErrors.docdelete);
              })
        } else {
        }
      });
  }

  fetchUploadProgress() {
    this.s3Service.progress
      .subscribe((res) => {
        var matchedIndex = this.newDocs.findIndex((doc) => doc.name == res.name);
        if (this.newDocs[matchedIndex]) {
          this.newDocs[matchedIndex].completed = Math.round(((res.progress.loaded / res.progress.total) * 100) * 100) / 100;
          this.newDocs[matchedIndex].completed = Math.round(this.newDocs[matchedIndex].completed);
        }
      });
  }


  extractFilename(filePath) {
    var parts = filePath.split('/');
    var filename = parts[parts.length - 1];
    return filename;
  }

  openDocCategory(doctype, info){
    if(doctype == this.constants.orderDocumentType.adminDocs){      
      return this.dialog.open(DocCategoryComponent, {data: info}).afterClosed()
      .toPromise()
      .then((result) => result)
    }
    else return Promise.resolve(null);
  }

  uploadToS3AndUpdatePath(docId, file) {
    return this.s3Service.onManagedUpload(file, this.message['orderId'])
      .then((data) => {
        return this.openDocCategory(this.constants.orderDocumentType.adminDocs, {
          list:  this.documentCategories,
          file: file
        })
        .then((category) => {
          let cat = category ? category : this.getDefaultCategory();
          return this.s3Service.updateDocumentPath(this.message['orderId'],
            this.message['propertyId'],
            file,
            data.Key,
            this.extractFilename(data.Key),
            CONSTANTS.orderDocumentType.adminDocs,
            cat
          )
            .then(() => { return { docId: docId, name: file.name, completed: 100 } })
            .catch((err) => { throw err; })
        })
        
      })
      .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload); })
  }

  uploadTagDocToS3AndUpdatePath(tempDocId, file, documentTagName, otherDocsTag) {
    let otherDocsListName
    if (otherDocsTag) {
      let otherDocTagIndex = this.message['documentTags'].findIndex((x) => x.Document_Tag_ID == CONSTANTS.orderDocumentsTags.otherTagDocs)
      otherDocsListName = this.message['documentTags'][otherDocTagIndex].Document_Tag_Name;
    }
    return this.s3Service.onManagedDocTagUpload(file, this.message['orderId'], documentTagName, otherDocsTag, otherDocsListName)
      .then((data) => {
        this.otherDocsTag = false;
        return this.s3Service.updateDocumentTagPath(this.message['orderId'],
          this.message['propertyId'],
          file, data.Key, this.extractFilename(data.Key),
          CONSTANTS.orderDocumentType.adminDocs,
          documentTagName, otherDocsListName)
          .then(() => { return { docId: tempDocId, name: file.name, completed: 100 } })
          .catch((err) => { throw err; })
      })
      .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload); })
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  getOrdDocumentTagName(document) {
    if (document.Document_Tag_ID == CONSTANTS.orderDocumentsTags.otherTagDocs) {
      let otherTagDocs = this.checkDocumentTags(document)
      return otherTagDocs + "-" + document.Document_Tag_Other;
    } else return this.checkDocumentTags(document);
  }

  checkDocumentTags(document) {
    let orderDocsTagIndex: any
    if (document && document.Document_Tag_ID && this.message['documentTags'].length > 0) {
      orderDocsTagIndex = this.message['documentTags'].findIndex((x) => x.Document_Tag_ID == document.Document_Tag_ID);
      return this.message['documentTags'][orderDocsTagIndex].Document_Tag_Name;
    } else return null;
  }

  uploadDocumentTags(documentTagName, documentTagID, event) {
    this.tagDocumentName = documentTagName;
    if (documentTagID == CONSTANTS.orderDocumentsTags.otherTagDocs) {
      this.isOtherDocumentTag = true;
      this.otherDocsTag = true;
      this.timer.push(setTimeout(() => {  this.otherDocTagInp.nativeElement.focus();  }, 0));  
      event.stopPropagation();
    } else {
      this.isOtherDocumentTag = false;
      this.otherDocsTag = false;
      // this.checkStatus();
      this.openFileSelector();
    }
  }

  clearMenuItem() {
    if (this.isOtherDocumentTag) {
      this.otherDocsTag = false
      this.isOtherDocumentTag = false;
      this.tagDocumentName = null;
    }
  }

  stopEventPropagation(event) {
    if (this.isOtherDocumentTag) {
      this.timer.push(setTimeout(() => { this.otherDocTagInp.nativeElement.blur(); }, 0));
      event.stopPropagation();
    }
  }

  setOtherTagDocument() {
    this.isOtherDocumentTag = false;
    this.openFileSelector();
  }

  handleDocCategoryChange(doc){ 
    this.dialog.open(DocCategoryComponent, { data:  {
      list: this.documentCategories,
      file: { name: doc.Document_Original_Name, doc_cat_id: doc.Doc_Cat_ID }
    }})
    .afterClosed()
    .subscribe((category) => {
      let cat = category ? category : this.getDefaultCategory();
      if(category && cat != doc.Doc_Cat_ID){
        this.orderService.updateDocumentCategory(doc.Order_Document_ID, cat).subscribe(() => {
          this.getDocs(this.message['orderId']);
        }, ()=> {
          this.openErrorPopup("Error updating the document category");
        })
      }            
    }) 
  }

  getDefaultCategory(){
    let defItem = this.documentCategories.find((item) => item.Category.toLowerCase() == 'misc')
    return defItem ? defItem.Id: null;
  }
}
