export const CONSTANTS = {
  userRole: {
    client: 1,
    abstractor: 2,
    admin: 3,
    superAdmin: 4, 
    compAdmin: 5, 
    searchMaster: 6
  },
  orderDocumentType:{
    adminDocs:1,
    clientDocs:2,
    abstractorDocs:3,
    internalDocs: 4
  },
  //   contactType:{
  //     mobile:'M',
  //     fax:'F'               
  //   },
  //   jwtOptions:{
  //     expiresIn: '1h'  //expires in an hour
  //   },
  //   propertyStatus:{
  //     active:1,
  //     inActive:0
  //   },
  orderStatus: {
    received: 1,
    // requestedPricing:5,
    cancelled: 10,
    assigned: 15,
    clientconf: 18,  
    confirmed: 20,  
    processing: 30,
    quotecompleted:40,
    completed: 50,
    forwarded: 60,
    // accepted:90	,
    inActive: 0
  },
  orderState:{
     paused: 1,
     unpaused: 1
  },
  // categoryStatus:{
  //   active:1,
  //   inActive:0
  // },
  // orderDocumentStatus:{
  //   active:1,
  //   inActive:0
  // },
  // orderProductStatus:{
  //   active:1,
  //   inActive:0
  // },
  // orderProductCostStatus:{
  //   active:1,
  //   inActive:0
  // },
  // regionStatus:{
  //   active:1,
  //   inActive:0
  // },
  // stateTaxStatus:{
  //   active:1,
  //   inActive:0
  // },
  // stateStatus:{
  //   active:1,
  //   inActive:0
  // },
  userStatus: {
    new: -1,
    approved: 2,
    active: 4,
    inActive: 0,
    deActivated: 99
  },
  orderPriority: {
    level1:0,
    level2:1
  },
  orderCreationType: {
    AutoSearch:0,
    manualEntry:1
  },
  mailsToAllCmpCli:{
    active:1,
    inActive:0,
    delete:-1
  },
  insertRecordInReport:{
   active:1,
   inActive:0,
   delete:-1
 },
 isDemo:{
   active:1,
   inActive:0,
   delete:-1
 },
 srchPkgItmTypes : {
   srchPrty : 1,
   assmts : 2,
   taxes : 3,
   vstDeed : 4,
   chnOfTtl : 5,
   secInsts : 6,
   lnJdg : 7,
   era : 8,
   propData : 9,
   reportData : 10,
   addInfo:11,
   glblCommit: 12
 },
 emailMembers:{
   yes:1,
   no:0,
 },
 clientType:{
   new:1,
   old:0,
 },
 autoScreen:{
   active:1,
   inActive:0,
 },
 invToDeliverables:{
   yes:1,
   no:0,
 },
 msgNotify:{
   active:1,
   inActive:0,
 },
  // emailPriority:{
  //   active:1,
  //   inActive:0
  // },
  // contactNumberPriority:{
  //   active:1,
  //   inActive:0
  // },
  // faxPriority:{
  //   active:1,
  //   inActive:0
  // },
  // passwordPriority:{
  //    active:1,
  //    second:2,
  //    third:3,
  //    fourth:4,
  //   inActive:0
  // },
  // verficationType:{
  //   emailCode:"Email_Code",
  //   smsCode:"SMS_Code",
  //   twofaCode:"2FA"
  // },
  // flagStatus:{
  //   active:1,
  //   inActive:0
  // },
  // orderDetailsStatus:{
  //   orderNotes:1,
  //   orderDesc:2,
  //   orderSI:4
  // },
  // orderInstructionStatus:{
  //   active:1,
  //   inActive:0
  // },
  selectProductStatus: {
    baseProduct: 1,
    subproduct:2,
    defaultProduct:4,
    inActive: 0
  },
  instructionType: {
    orderNotes: 1,
    orderDesc: 2,
    orderIns: 4
  },
  messageType: {
    orderMessage: 1,
    internalMessage:4,
    internalNotes:8
  },
  orderNoteType: {
   internalNote: 1,
   additionalNote:2,
 },
  messageStatus: {
    unread: 1,
    read: 2
  },
  organizationStatus: {
    active: 1,
    inActive: 0
  },
  organizationType:{
    qualia:1,
    trackerpro:2,
    resware:8,
    softpro:4
  },
  orderSourceType:{
   qualia:4,
   direct:7,
   resware:2,
   softpro:3
 },
  userType: {
    individual: 1,
    company: 2,
    internalSM: 4,
    externalSM: 8,
    Admin: 16,
    ReadOnlyAdmin: 32,
  },
  managerType: {
   searchManager: 1,
 },
  customCostStatus: {
    active: 1,
    inactive: 0
  },
  siteSettingName:{
    ALLOW_REGISTERED_MAIL_ORDER:"ARMO",
    PRESET_STATUS_EMAIL_ADDRESS:"PSEA"
  },
  SMCompleteMsgType:{
    active:1,
    inactive:0,
    unassign:-1
  },
  OrderStatusMsgType:{
    isInternalMsg:1,
    isOrderMsg:0
  },
  tagStatus:{
    active:1,
    inActive: 0,
  },
  subscriptionStatus:{
    active:1,
    inActive:0
  },
  flagStatus:{
    active:1,
    inActive:0
  },  
  checkListStatus:{
   zipCode:1,
   effectiveDate:2,
   noOfComments:3,
   noOfReports:4,
   specialInstruction:5
 },
 sessiontimeOut:{
   timeOut:900000
 },
  apiErrors: {
    docupload: 'Document upload has failed. Please try again.',
    docuploadsize:'Document size is too large.',
    absDocUploadSize:'File size is too large.',
    docUploadMaxSize:'File size is too large.',
    QBODocUploadMaxSize:'Total file size for a bill cannot exceed 100MB.',
    docdelete: 'Unable to delete document. Please try again.',
    sendmessage: 'Messaging unsuccessful. Please try again.',
    docdownload: 'Unable to download document. Please try again.',
    datafetch: 'Error while fetching data. Please login and try again.',
    multiupload: 'Error while uploading the documents. Please check and try again.',
    orderassign: 'Order assignment failed. Please try again.',
    ordercomplete: 'Order completion failed. Please try again.',
    orderCanNotComplete:'Cannot complete the order without sending to the abstractor.',
    changestatus: 'Unable to change order status. Please try again.',
    orderaccept: 'Unable to accept order. Please try again.',
    ordercancel: 'Unable to cancel order. Please try again.',
    errorReport: 'Unable to report error. Please try again.',
    deleteReport: 'Unable to delete error. Please try again.',
    editorder: 'Order update has failed. Please try again.',
    reqAction: 'Unable to approve/decline the client request. Please try again.',    //for registration request   
    createPricng: 'Unable to create pricing. Please try again.',
    editPricing: 'Unable to edit pricing. Please try again.',
    validateFile: 'It seems like one/many files have invalid(filenames with comma) filenames. Please check and try again.',
    newAbsMail: 'New abstractor created and mail has been sent successfully.',
    changeAbs: 'Abstractor changed and mail has been sent successfully.',
    absAssignedMail: 'Order is assigned to new abstractor and mail has been sent successfully.',
    absMailSuccess: 'Mail has been sent to the abstractor successfully.',
    absMailFail: 'Mail not sent.',
    absAssgndMailNotSent:"Abstractor assigned successfully but mail not sent.",
    docUploadFail:'Cannot upload documents when an order is completed / cancelled. Please change the status to upload documents.',
    docDeleteFail:'Cannot delete documents when an order is completed / cancelled. Please change the status to delete documents.',
    selectCust:'Please select any one customer.',
    selectOrg:'Please select any one organization.',
    quickbookAuth: 'Access to quickbooks resource failed!',
    absAssigned: 'Order is assigned to abstractor successfully.',
    newAbsAssigned: 'New abstractor created and order has been assigned successfully.',
    orderPriority: 'Unable to update order priority. Please try again',
    orderEscalation: 'Unable to manage order escalation. Please try again',
    subscribeOrder:'Failed to subscribe order for notification mail.',
    unSubscribeOrder: 'Failed to unsubscribe order.',
    delDeliveryGroup: 'Unable to delete delivery group. Please try again',
    getDeliveryGroups: 'Error while fetching delivery groups. Please try again.',
    getDeliveryGroupsUsers: 'Error while fetching delivery group members. Please try again.',
    updateOrgMailStatus: 'Unable to update organization mail status. Please try again.',
    updateInsertRecordStatus: 'Unable to change toggle status. Please try again.',
    compSettingErr: 'This is a company user. Please modify this setting for the company.',
    orderTagging: 'Order tagging failed. Please try again.',
    orderAbsDelivery: 'Failed to update!',
    backToScreener:'Failed to send back for screening. Please try again.',
    docQuoteUploadFail:'Cannot upload documents when an quote is completed / cancelled.',
    docQuoteDeleteFail:'Cannot delete documents when an quote is completed / cancelled.',
    encryptedFileError: 'The files listed below are encrypted. Please decrypt the files by opening them and printing them to unencrypted files and then reupload. Thank you.',
    companyWatchStatus: 'Failed to fetch watch status for the company',
    companyWatchStatusToggle: 'Failed to change the toggle status for the company'
  },
  orderProductFlag: {
    active: 1,
    inActive: 0
  },
  messageSendStatus:{
    directMessage:1,
    clientSendMessage:2,
    orderMessage:3
  },
  orderDocumentTabs:{
    adminTab:0,
    internalTab:1
  },
  documentFileTypes:{
    fileFormat:".pdf,.doc,.docx,.rtf,.txt,.xlsx,.xls,.pptx,.ppt,.rar,.zip,.jpe,.jpg,.bmp,.gif,.png,.tif,.tiff,.gz"
  },
  orderExpensePaidFlag:{
   paid: 1,
   unpaid: 0
  },
  orderExpenseStatus:{
   active: 1,
   inActive: 0
  },
  orderBillItemStatus:{
   active: 1,
   inActive: 0
  },
  orderBillDocumentStatus:{
   active: 1,
   inActive: 0
  },
  QBOBillStatus:{
   active:1,
   inActive:0
  },
  orderDocumentsTags:{
    otherTagDocs:99
  },
  orderAutomated:{
   active :1,
   inActive:0
  },
  orderPredictionResult:{
   active :1,
   inActive:0
  },
  orderSentBack:{
     default:0
  },
  defaultProductFlag: {
     active: 1,
     inActive:0
  },
  defaultIns : 'Abstractor shall comply with all state laws, regulations, guidelines and industry standards. Abstracts of title must satisfy the requirements as outlined below or meet state-specific customary and/or statutory requirements, whichever is less.<br>' +
    '1. List all deeds through the chain of title showing 100% ownership for the statutory period for marketable title or to the developer. Please provide a copy of the current vesting deed. If the current vesting deed is within family, please also provide a copy of the full vesting deed out of family in a bona fide arms-length transaction.<br>' +
    '2. Pertinent pages of all open mortgages, along with listing any assignments, subordination agreements and modifications for open mortgages. Please list the purchase money mortgage even if satisfied. All open-ended mortgages must be noted.<br>' +
    '3. Full judgment, lien and encumbrance information for all vested owners, sellers and buyers. This should include but is not limited to Federal, State and Municipal tax liens, contractor liens, bankruptcy and civil whether final or pending. Please include copies of all judgments/liens.<br>' +
    '4. List all exceptions on the property including easements, covenants, conditions and restrictions.<br>' +
    '5. List all marriage licenses, divorces, death certificates and/or probate records needed to complete the chain of title when available.<br>' +
    '6. Copy of the Plat Map where available.<br>' +
    '7. Current tax/assessment information including parcel # where available. Also, include any delinquent tax information for the subject property.<br>',    
    defaultSpeReqs : ''+
    '1. Copy of all Deeds (Need one out-of-family deed)<br>'+
    '2. 24-month chain criteria need to be covered<br>'+
    '3. Full copies of Mortgage and related documents.<br>'+
    '4. Full Copies of any unreleased judgements/liens (need actual copies of the Judgments and not just the Dockets)<br>'+
    '5. Full Copy of Modification, if applicable<br>'+
    'If No open mortgage – need PMM release & latest release documents.<br>'+
    'Provide 2-year title information 3. Identify all owners within last 2 years<br>'+
    'A search of the land records is performed from the execution date of the current deed of record for the subject property forward****(MINIMUM 24 MONTH CHAIN REQUIRED), complete information from that deed, including the legal description as reported, along with reporting complete information regarding open mortgages/deeds of trust, judgments, federal tax liens, pending suits, real estate taxes, UCC’s encumbering the subject real estate, outsales and any other matters affecting the subject real estate must be reported.<br>'+
    '<p>If an open or satisfied mortgage/deed of trust to an Institutional Lender is evidenced in your research, you must report complete information regarding that instrument, including the satisfaction date if applicable, and can conclude your report. In addition, if an open or satisfied mortgage/deed of trust to an Institutional Lender is evidenced in your research by the PRIOR OWNER, please provide the corresponding chains back to when the parties obtained title.</p>'+
    '<p>NOTE: Open assumed mortgages must show all deeds back to the original loan.</p>'+
    '<p>If the property is a mobile home, please find Affidavit of Affixture and scan the copy along with the search, this should be available at-least in the name of prior owner of the property.</p>',
  saintArray: [
    'St. Clair',
    'St. Francis',
    'St. Joseph',
    'St. Bernard',
    'St. Charles',
    'St. Helena',
    'St. Landry',
    'St. Martin',
    'St. Tammany',
    'St. Francois',
    'St. Genevieve',
    'St. Louis City',
    'St. Louis County',
    'St. Lawrence',
    'St. Croix'
  ],
  countyArray: [
    "Adair County"
 ,

    "Allen County"
 ,

    "Allen County"
 ,

    "Anderson County"
 ,

    "Anderson County"
 ,

    "Atchison County"
 ,

    "Ballard County"
 ,

    "Baltimore County"
 ,

    "Barber County"
 ,

    "Barren County"
 ,

    "Barton County"
 ,

    "Bath County"
 ,

    "Bell County"
 ,

    "Boone County"
 ,

    "Bourbon County"
 ,

    "Bourbon County"
 ,

    "Boyd County"
 ,

    "Boyle County"
 ,

    "Bracken County"
 ,

    "Breathitt County"
 ,

    "Breckenridge County"
 ,

    "Brown County"
 ,

    "Bullitt County"
 ,

    "Butler County"
 ,

    "Butler County"
 ,

    "Caldwell County"
 ,

    "Calloway County"
 ,

    "Campbell County"
 ,

    "Carlisle County"
 ,

    "Carroll County"
 ,

    "Carter County"
 ,

    "Casey County"
 ,

    "Chase County"
 ,

    "Chautauqua County"
 ,

    "Cherokee County"
 ,

    "Cheyenne County"
 ,

    "Christian County"
 ,

    "Clark County"
 ,

    "Clark County"
 ,

    "Clay County"
 ,

    "Clay County"
 ,

    "Clinton County"
 ,

    "Cloud County"
 ,

    "Coffey County"
 ,

    "Comanche County"
 ,

    "Cowley County"
 ,

    "Crawford County"
 ,

    "Crittenden County"
 ,

    "Cumberland County"
 ,

    "Daviess County"
 ,

    "Decatur County"
 ,

    "Dickinson County"
 ,

    "Doniphan County"
 ,

    "Douglas County"
 ,

    "Edmonson County"
 ,

    "Edwards County"
 ,

    "Elk County"
 ,

    "Elliott County"
 ,

    "Ellis County"
 ,

    "Ellsworth County"
 ,

    "Estill County"
 ,

    "Fayette County"
 ,

    "Finney County"
 ,

    "Fleming County"
 ,

    "Floyd County"
 ,

    "Ford County"
 ,

    "Franklin County"
 ,

    "Franklin County"
 ,

    "Fulton County"
 ,

    "Gallatin County"
 ,

    "Garrard County"
 ,

    "Geary County"
 ,

    "Gove County"
 ,

    "Graham County"
 ,

    "Grant County"
 ,

    "Grant County"
 ,

    "Graves County"
 ,

    "Gray County"
 ,

    "Grayson County"
 ,

    "Greeley County"
 ,

    "Green County"
 ,

    "Greenup County"
 ,

    "Greenwood County"
 ,

    "Hamilton County"
 ,

    "Hancock County"
 ,

    "Hardin County"
 ,

    "Harlan County"
 ,

    "Harper County"
 ,

    "Harrison County"
 ,

    "Hart County"
 ,

    "Harvey County"
 ,

    "Haskell County"
 ,

    "Hawaii County"
 ,

    "Henderson County"
 ,

    "Henry County"
 ,

    "Hickman County"
 ,

    "Hodgeman County"
 ,

    "Honolulu County"
 ,

    "Hopkins County"
 ,

    "Jackson County"
 ,

    "Jackson County"
 ,

    "Jefferson County"
 ,

    "Jefferson County"
 ,

    "Jessamine County"
 ,

    "Jewell County"
 ,

    "Johnson County"
 ,

    "Johnson County"
 ,

    "Kauai County"
 ,

    "Kearny County"
 ,

    "Kenton County"
 ,

    "Kingman County"
 ,

    "Kiowa County"
 ,

    "Knott County"
 ,

    "Knox County"
 ,

    "Labette County"
 ,

    "Lane County"
 ,

    "Larue County"
 ,

    "Laurel County"
 ,

    "Lawrence County"
 ,

    "Leavenworth County"
 ,

    "Lee County"
 ,

    "Leslie County"
 ,

    "Letcher County"
 ,

    "Lewis County"
 ,

    "Lincoln County"
 ,

    "Lincoln County"
 ,

    "Linn County"
 ,

    "Livingston County"
 ,

    "Logan County"
 ,

    "Logan County"
 ,

    "Lyon County"
 ,

    "Lyon County"
 ,

    "Madison County"
 ,

    "Magoffin County"
 ,

    "Marion County"
 ,

    "Marion County"
 ,

    "Marshall County"
 ,

    "Marshall County"
 ,

    "Martin County"
 ,

    "Mason County"
 ,

    "Maui County"
 ,

    "McCracken County"
 ,

    "McCreary County"
 ,

    "McLean County"
 ,

    "McPherson County"
 ,

    "Meade County"
 ,

    "Meade County"
 ,

    "Menifee County"
 ,

    "Mercer County"
 ,

    "Metcalfe County"
 ,

    "Miami County"
 ,

    "Mitchell County"
 ,

    "Monroe County"
 ,

    "Montgomery County"
 ,

    "Montgomery County"
 ,

    "Morgan County"
 ,

    "Morris County"
 ,

    "Morton County"
 ,

    "Muhlenberg County"
 ,

    "Nelson County"
 ,

    "Nemaha County"
 ,

    "Neosho County"
 ,

    "Ness County"
 ,

    "Nicholas County"
 ,

    "Norton County"
 ,

    "Ohio County"
 ,

    "Oldham County"
 ,

    "Osage County"
 ,

    "Osborne County"
 ,

    "Ottawa County"
 ,

    "Owen County"
 ,

    "Owsley County"
 ,

    "Pawnee County"
 ,

    "Pendleton County"
 ,

    "Perry County"
 ,

    "Phillips County"
 ,

    "Pike County"
 ,

    "Pottawatomie County"
 ,

    "Powell County"
 ,

    "Pratt County"
 ,

    "Pulaski County"
 ,

    "Rawlins County"
 ,

    "Reno County"
 ,

    "Republic County"
 ,

    "Rice County"
 ,

    "Riley County"
 ,

    "Robertson County"
 ,

    "Rockcastle County"
 ,

    "Rooks County"
 ,

    "Rowan County"
 ,

    "Rush County"
 ,

    "Russell County"
 ,

    "Russell County"
 ,

    "Saline County"
 ,

    "Scott County"
 ,

    "Scott County"
 ,

    "Sedgwick County"
 ,

    "Seward County"
 ,

    "Shawnee County"
 ,

    "Shelby County"
 ,

    "Sheridan County"
 ,

    "Sherman County"
 ,

    "Simpson County"
 ,

    "Smith County"
 ,

    "Smyth County"
 ,

    "Spencer County"
 ,

    "St. Louis County"
 ,

    "Stafford County"
 ,

    "Stanton County"
 ,

    "Stevens County"
 ,

    "Sumner County"
 ,

    "Taylor County"
 ,

    "Thomas County"
 ,

    "Todd County"
 ,

    "Trego County"
 ,

    "Trigg County"
 ,

    "Trimble County"
 ,

    "Union County"
 ,

    "Wallace County"
 ,

    "Warren County"
 ,

    "Washington County"
 ,

    "Washington County"
 ,

    "Wayne County"
 ,

    "Webster County"
 ,

    "Whitely County"
 ,

    "Whitley County"
 ,

    "Wichita County"
 ,

    "Wilson County"
 ,

    "Wolfe County"
 ,

    "Woodford County"
 ,

    "Woodson County"
 ,

    "Wyandotte County"

  ]
}