import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef, OnDestroy } from '@angular/core';
import { UntypedFormGroup, FormsModule, UntypedFormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, EqualValidator, PasswordValidator, PricingService, ConfigService, UsermessageService, OrdersService } from '../services';
import { DialogService } from "ng2-bootstrap-modal";
import { Observable } from 'rxjs';
import { SuccessComponent } from '../dialog/success/success.component';
import { ErrorDialogComponent } from '../dialog/error-dialog/error-dialog.component';
import { CONSTANTS } from '../app.constants';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil, filter, tap } from 'rxjs/operators';
// import { state } from '@angular/animations';
import * as _ from 'underscore';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pricing-edit',
  templateUrl: './pricing-edit.component.html',
  styleUrls: ['./pricing-edit.component.scss']
})
export class PricingEditComponent implements OnInit, OnDestroy {
  @ViewChildren('myFirstFocus') myFirstFocus: QueryList<MatSelect>;
  @ViewChild('myFirstEditFocus') myFirstEditFocus: ElementRef;
  editPricingFrm: UntypedFormGroup;
  pricingModel: any = {};
  pricingData: any = {};
  error: string = '';
  costId: '';
  errorType: string = '';
  errorMsg: any = {};
  states: any[] = []; 
  counties: any = [];
  selectedStates =[];
  products: any = [];
  input = "";
  focused: boolean = false;
  timer: any = [];
  protected _onDestroy = new Subject<void>();
  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    public pricingService: PricingService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog:MatDialog,
    private config: ConfigService,
    private userMsg: UsermessageService,
    private orderService: OrdersService
    //private appsProcessService:AppsProcessService,
  ) {
    this.editPricingFrm = fb.group({
      'State_Short_Name': [null, Validators.required],
      'County_Name': [null, Validators.required],
      'Product_Description': [null, Validators.required],
      'Cost_Amount': [null, Validators.compose([Validators.required, Validators.maxLength(14)])],
    })
    this.editPricingFrm.valueChanges.subscribe(data => this.error = '');
  }
  ngOnInit() {
    this.pricingModel.State_Short_Name = '';
    this.pricingModel.County_Name = '';
    this.pricingModel.Product_Description = '';
    if (this.route.snapshot.params['costId']) {
      this.costId = this.route.snapshot.params['costId'];
      this.getPricingDetails()
    } else {
      this.pricingService.getStateList().subscribe((states) => {
        if (states) {
          var tempStates = [];
          states.forEach((state) => tempStates.push({ State_Short_Name: state[0].toUpperCase() }));
          this.states = tempStates;
          this.selectedStates = tempStates;
        }
      });
      // this.getAvailableproductLocal();
      this.products = this.orderService.getAllOrderProducts()
      .subscribe((products) => {
        if (products) {          
          this.products = products;
        }
      });
    }
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      if (this.costId) this.myFirstEditFocus.nativeElement.focus();
    }, 0));
  }

//   getAvailableproductLocal(){
//     let AllProd = JSON.parse(sessionStorage.getItem('AllProductAvailable'));
//     if(AllProd) this.setProd(AllProd)         
//     else this.getAvailableprodList();
// }
  
// getAvailableprodList(){
//   this.pricingService.getProductList().subscribe((Prod) => { 
//       if(Prod){
//         this.setProd(Prod)         
//         sessionStorage.setItem('AllProductAvailable',JSON.stringify(Prod)); 
//        setTimeout(function(){sessionStorage.removeItem("AllProductAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });

//   }

//   setProd(AllProdData){
//       if(AllProdData) this.products = AllProdData;
//   }
  ngAfterViewInit() {
    if (this.myFirstFocus && this.myFirstFocus.changes) {
      this.myFirstFocus.changes.subscribe((select) => {
        if (select.first && !this.focused) {
          this.focused = true;
          this.timer.push(setTimeout(() => { select.first.focus() }, 0))
        };

      });
    }
  }

  clearError() {
    this.errorMsg.msg = '';
  }
  getCountyList(event) {
    console.log("Event", event);
    if (event.length != 0) {
      this.pricingModel.County_Name = '';
      this.counties = this.pricingService.getCountyList(this.pricingModel.State_Short_Name).subscribe((counties) => {
        if (counties) {
          this.counties = counties;
          }
      });
    }
  }
  getPricingDetails() {
    this.pricingService.getProductCost(this.route.snapshot.params['costId'])
      .subscribe((details) => {
        console.log("details", details);
        this.loadData(details);
      }, (err) => {
        console.log(err);
      })
  }
  openDialog(msg) {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: 'Success',
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        this.router.navigate(['/product-pricing/view'])
      });
  }
  loadData(data) {
    this.pricingModel.State_Short_Name = data.State_Short_Name;
    if (this.pricingModel.State_Short_Name) {
      document.getElementById('State_Short_Name').parentElement.classList.remove('is-empty');
    }
    this.pricingModel.County_Name = data.County_Name;
    if (this.pricingModel.County_Name) {
      document.getElementById('County_Name').parentElement.classList.remove('is-empty')
    }
    this.pricingModel.Product_Description = data.Product_Description;
    if (this.pricingModel.Product_Description) {
      document.getElementById('Product_Description').parentElement.classList.remove('is-empty')
    }
    this.pricingModel.Cost_Amount = data.Cost_Amount;
    if (this.pricingModel.Cost_Amount) {
      document.getElementById('Cost_Amount').parentElement.classList.remove('is-empty')
    }
  }

  updatePricing() {
    var data = {
      Cost_Amount: this.pricingModel.Cost_Amount,
      User_ID: this.auth.getUserId()
    }
    this.pricingService.updateProductCost(this.route.snapshot.params['costId'], data)
      .subscribe((result) => {
        let msg = "Updated successfully."
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/product-pricing/view'])
        // console.log("result",result);
        // this.openDialog('Updated successfully.');
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.editPricing);
      })
  }

  userAction() {
    if (this.costId) this.updatePricing()
    else this.createPricing();
  }
  createPricing() {
    this.pricingModel.Product_ID = this.pricingModel.Product_Description;
    this.pricingService.createSinglePricing(this.pricingModel)
      .subscribe((result) => {
        let msg = "Created successfully."
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/product-pricing/view'])
        // console.log("result",result);
        // this.openDialog('Created successfully.');
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.createPricng);
      })
  }

  // openCustomDialog(){
  //   let disposable = this.matDialog.open(CreateCustomPricingsComponent,{      
  //     message:this.pricingModel
  //   })
  //   .subscribe((res)=>{
  //     console.log("Closed",res)
  //   });
  // }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  getPricingFormat(e, pricingModel) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true; // Allow left/right arrow keys and tab key
  
    // Allow negative sign only if it's at the start and there are no other negative signs
    if (e.key === "-" && this.input.length === 0) {
      this.input = e.key + this.input;
      e.target.value = this.numberFormat(this.input);
      pricingModel.Cost_Amount = e.target.value;
      return false;
    }
  
    // Prevent leading zeros
    // if (this.input.length == 0 && e.key == "0") e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.input.length > 0 || this.input.length < this.config.getLimitPriceMaxLength())) {
        this.input = this.input.slice(0, this.input.length - 1);
        e.target.value = this.numberFormat(this.input);
        pricingModel.Cost_Amount = e.target.value;
      }
      else if (this.input.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.input = this.input + key;
          e.target.value = this.numberFormat(this.input);
          pricingModel.Cost_Amount = e.target.value;
        }
      }
      return false;
    }
  }
  

  
  numberFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
}




