import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie";
import { AuthService } from "../authentication/auth.service";
import { ConfigService } from "../config/config.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { PreloaderService } from "../preloader/preloader.service";

@Injectable({
  providedIn: "root",
})
export class CountiesService {
  private _dataDepthInfo$: BehaviorSubject<any> = new BehaviorSubject([]);
  public dataDepthInfo$: Observable<any> = this._dataDepthInfo$.asObservable();
  private _validatedDepthInfo$: BehaviorSubject<any> = new BehaviorSubject([]);
  public validatedDepthInfo$: Observable<any> = this._validatedDepthInfo$.asObservable();
  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient,
    private preloaderService: PreloaderService
  ) {}

  resetDataDepth(){
    this._dataDepthInfo$.next([]);
  }

  resetValidatedData(){
    this._validatedDepthInfo$.next([]);
  }

  getStateDataDepth(stateName) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/counties/datadepth/" + stateName;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        this._dataDepthInfo$.next(response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error))
    );
  }

  getSteAndCountyInfo(stateName) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/counties/stateInfo/" + stateName;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error))
    );
  }

  updateDataDepth(data) {
    this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/counties/update/dataDepth";
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        this._dataDepthInfo$.next(response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error))
    );
  }

  updateSOPInfo(data, state) {
    let toBeUpdated = {...data };
    toBeUpdated.State_Name = state;
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/counties/update/noteInfo";
    return this.http.post(url, toBeUpdated, httpOptions).pipe(
      map((response: any) =>response),
      catchError((error: any) => observableThrowError(error.error))
    );
  }


  validateBulkUpldData(data){
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/counties/datadepth/bulk/validate";
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) =>{ 
        this._validatedDepthInfo$.next(response);
        return response 
      }),
      catchError((error: any) => observableThrowError(error.error))
    );
  }


  postBulkUpldData(data){
    let body = [];
    data.forEach((stateGrp) => {
      body.push({ key : stateGrp.key, rowData: stateGrp.rowData })
    })
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/counties/datadepth/bulk/upload";
    return this.http.post(url, body, httpOptions)
    .pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error))
    );
  }
}
