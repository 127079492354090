import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ssnval',
    pure: false
})
export class SsnFieldPipe implements PipeTransform {

    transform(ssn) {
    if (!ssn) { return ''; }
    var value = ssn.toString().trim().replace(/^\+/, '');
    value = value.replace(/[^\d]/g, '');
  var cleaned = ('' + value).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/)
    if (match) {
        return match[1] + '-' + match[2] + '-' + match[3]
    }

    var city, groupNum, serialNum;
    switch (value.length) {
        case 9:    
            city = value.slice(0, 2);
            groupNum = value.slice(2, 3);
            serialNum = value.slice(3);
            break;

        default:
            return ssn;
    }

    serialNum = serialNum.slice(0, 2) + '-' + serialNum.slice(2);

    return (city + + groupNum + '-' + serialNum).trim();
}
}