// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: white;
}

footer a {
  text-decoration: none;
  color: #333333;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/common/footer/footer.component.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAEA;EACI,uBCDe;ADAnB;;AAIA;EACI,qBAAA;EACA,cCUiB;EDTjB,cAAA;AADJ","sourcesContent":["@import '../../../scss/variables';\n\nfooter{\n    background-color: $background-color;\n}\n\nfooter a{\n    text-decoration: none; \n    color: $primary-font-color;\n    display:block;\n}","$fa-font-path : '../../node_modules/font-awesome/fonts';\n\n$background-color: white;\n$selected-metal: white;\n$disabled-color: #777777;\n$primary-color: #428bca;\n$primary-hover: #357ebd;\n$secondary-color: #f0ad4e;\n$secondary-hover: #eea236;\n$accept-color: #60cc70;\n$accept-hover: #0F9D58;\n$reject-color: #f44336;\n$reject-hover: #d43f3a;\n$reject-color2: #e45d53;\n\n$body-font-family:'Karla', sans-serif;\n$button-font-family:'Roboto';\n$error-font-family:'Roboto';\n$primary-font-color: #333333;\n$secondary-font-color: #777777;\n$title-font-size: 3rem;\n$title-font-weight: 400;\n$sub-title-font-size: 1.5rem;\n$sub-title-font-weight: 400;\n$heading-font-size: 2.25rem;\n$body-font-size: 1rem;\n$star-font-size:25px;\n$table-sidebar-font-size:0.875rem;\n//bootstrap\n$spacer: 1rem !default;\n$spacers: (\n  0: 0,\n  1: $spacer,\n  2: calc($spacer * 2),\n  3: calc($spacer * 3),\n  4: calc($spacer * 4),\n  5: calc($spacer * 5),\n  6: calc($spacer * 6),\n  7: calc($spacer * 7),\n  8: calc($spacer * 8),\n  9: calc($spacer * 9),\n  10: calc($spacer * 10),\n  11: calc($spacer / 5),\n  12: calc($spacer / 2),\n  dc75: calc($spacer *12 / 16),\n  dc625: calc($spacer *10 / 16),\n  dc375: calc($spacer *6 / 16),\n  dc25: calc($spacer * 4 / 16),\n  dc125: calc($spacer * 2 / 16),\n  13: calc($spacer * -2),\n  14: calc($spacer * -6 / 16),\n  15: calc($spacer / 4),\n  16: calc($spacer * -10 / 16),\n  17: calc($spacer / 3),\n  18: calc($spacer * 1.5),\n  19: calc($spacer * 3 / 4),\n) !default;"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
