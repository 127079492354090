import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmComponent } from "app/dialog/confirm/confirm.component";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { ConfigService } from "app/services";
import { DialogService } from "ng2-bootstrap-modal";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";

@Component({
  selector: "app-rb-assessment-section",
  templateUrl: "./rb-assessment-section.component.html",
  styleUrls: ["./rb-assessment-section.component.scss"],
})
export class RbAssessmentSectionComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() dbData: any;
  @Input() itemData: any;
  @Input() itemIndex: any;
  @Output() dragEnable: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('firstInput') firstInput: MatButton;
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionName = "assessment";
  sectionType = "assessments";
  sectionDocs: any = [];
  isToggleChanged=false;
  reportDetails = {
    Derived_From: null,
  };
  orderDetails: any = {};

  constructor(
    private frmBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private config: ConfigService,
    private cdr: ChangeDetectorRef,
    private builder: RptBuilderService
  ) {
    this.dataForm = this.frmBuilder.group({
      Year: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(4)]),
      ],
      Land: [null],
      Building: [null],
      Extras: [null],
      Total: [null],
      Comments: [null, [Validators.maxLength(65535)]],
      Internal_Comments: [null, [Validators.maxLength(65535)]],
      Applies: [false],
      Parcel: [null, [Validators.maxLength(255)]],
      Language: ["good", Validators.required],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveItem(this.dataForm));
  }

  ngAfterViewInit(): void {
    this.dataForm.controls["Applies"].patchValue(this.itemData.Applies == 1, {
      emitEvent: false,
    });
    if (!this.itemData.Status) this.dataForm.disable();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderDetails = data));
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData && data.dbData.Id !== undefined) {
          this.reportDetails = data.dbData;
          if (
            this.orderDetails &&
            this.orderDetails.Order_ID !== undefined &&
            this.orderDetails.Order_ID !== ""
          ) {
            this.builder.manageError(
              `Assessments_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
              this.dataForm.status !== "VALID",
              this.dataForm.controls
            );
            this.cdr.detectChanges();
            this.manageAppliesTgleChange(this.dataForm.controls['Year'],
            [Validators.compose([Validators.required, Validators.maxLength(4)])] , 
            [Validators.maxLength(4)])
          }
        }
      });
    this.cdr.detectChanges();
    this.setFieldAsMarked("Year");
  }

  enableDrag(event) {
    this.dragEnable.emit(true);
  }

  diasbleDrag() {
    this.dragEnable.emit(false);
  }

  toggleApplies(event) {
    this.itemData.Applies = event ? 1 : 0;
    this.manageAppliesTgleChange(
      this.dataForm.controls['Year'],
      [Validators.compose([Validators.required, Validators.maxLength(4)])] , 
      [Validators.maxLength(4)]
    )
    this.isToggleChanged=true;
  }

  manageAppliesTgleChange(control, onValidators, offValidators){
    if(!this.itemData.Applies) {
      control.setValidators(offValidators);  
      control.setErrors(null);  
      this.builder.manageError(
        `Assessments_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        false,
        {}
      );          
    }   
    else {
      control.setValidators(onValidators)
      this.builder.manageError(
        `Assessments_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        this.dataForm.status !== "VALID",
        this.dataForm.controls
      );
    };    
    control.updateValueAndValidity();
  }

  saveItem(event) {
    if (
      this.orderDetails &&
      this.orderDetails.Order_ID !== undefined &&
      this.itemData.Status
    )
      this.builder.manageError(
        `Assessments_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine) {
      let payload = {
        curData: this.builder.trimInput(event, this.itemData),
        dbData: this.dbData,
        Sp_Id: this.reportDetails["Id"],
        Derived_From: this.reportDetails["Derived_From"],
        toggleControl:{changed:this.isToggleChanged,state:this.itemData.Applies}
      };
      this.isToggleChanged=false
      this.builder.saveAssesments(payload, false).pipe(take(1)).subscribe();
    }
  }

  deleteItem() {
    this.matDialog
      .open(
        ConfirmComponent,
        {data:{
          title: "Delete assessment",
          message: "Are you sure you want to delete this assessment ?",
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.itemData.Status = 0;
          let payload = {
            curData: this.itemData,
            dbData: this.dbData,
            Sp_Id: this.reportDetails["Id"],
            Derived_From: this.reportDetails["Derived_From"],
          };
          this.builder.manageError(
            `Assessments_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
            false,
            {}
          );
          this.builder.saveAssesments(payload, true).pipe(take(1)).subscribe();
        } else {
          console.log("Closed", res);
        }
      });
  }

  handleCommitmentError(isValid) {
    this.dataForm.controls["Language"].setValue(isValid ? "good" : null);
  }

  setFieldAsMarked(fieldKey) {
    var keyIndex = Object.keys(this.dataForm.controls).findIndex(
      (key) => key == fieldKey
    );
    if (keyIndex > -1) {
      let key = Object.keys(this.dataForm.controls)[keyIndex];
      this.dataForm.get(key).markAsTouched();
    }
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "ASS_NS",
      this.itemData.Id,
      fieldName
    );
  }
}
