import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { ConfigService, AuthService, OrdersService, TagsService } from '../../services';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-tag',
  templateUrl: './new-tag.component.html',
  styleUrls: ['./new-tag.component.scss']
})
export class NewTagComponent  {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  title = '';
  message: any;
  tagForm: any = {}
  tagModel: any = {}
  error: any = {}
  errorMsg: any;
  tagColors = [];
  timer: any = [];

  constructor(
    
    private fb: UntypedFormBuilder,
    private orderService: OrdersService,
    private auth: AuthService,
    private tagService: TagsService,
    public dialogRef: MatDialogRef<NewTagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    
    this.tagForm = this.fb.group({
      'Tag_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'Tag_Description': [null, Validators.maxLength(255)],
      'Tag_Color': [null],
    })
    this.tagForm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => { this.myFirstFocus.nativeElement.focus() }, 0));
    if (!this.tagModel.Tag_Color) this.tagModel.Tag_Color = "#e0e0e0";
    this.tagColors = Object.assign([], this.tagService.getTagPickerColors());
    this.tagColors.map((tag) => tag.selected = false);
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    this.clearTime();
  }

  confirm() {    
    this.tagModel.Admin_User_ID = this.auth.getUserId();
    if(this.message['Order_ID']){      
      this.tagModel.Order_ID = this.message['Order_ID'];
      this.orderService.createTagOnFly(this.tagModel)
        .subscribe((res) => {
          this.dialogRef.close(true);
          // this.result = true;
          this.clearTime();
          // sessionStorage.removeItem("AllTagsAvailable");
        }, (err) => {
          this.errorMsg = err;
        });
    }
    else{
      this.tagService.createTag(this.tagModel)
      .subscribe((res) => {
        this.dialogRef.close(res);
        // this.result = res;
        this.clearTime();      
      }, (err) => {
        this.errorMsg = err;
      });
    }
  }

  tagColorSelect(tag){
    this.tagModel.Tag_Color = tag.value;    
    this.tagColors.map((tag) => tag.selected = false);
    tag.selected = true;
  }

  clearTime(){
    // this.close();
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
