import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { CONSTANTS } from '../../app.constants';
import { AuthService, OrganizationService, UsersService } from '../../services';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelect } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-join-company',
  templateUrl: './join-company.component.html',
  styleUrls: ['./join-company.component.scss']
})
export class JoinCompanyComponent   {

  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('selectBtn') focusedElement1: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: MatSelect

  constants = CONSTANTS
  message: any;
  title = '';
  listingType = '1';
  organizations = [];
  selectedOrgId = '';
  domainName = '';
  errorMsg: any = {};
  user : any = {};
  changeModel: any = {};
  changeCompFrm: UntypedFormGroup
  organizationName=''
  timer: any = [];
  constructor(
    private organizationService: OrganizationService,
    private usersService: UsersService,
    private auth: AuthService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<JoinCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    
    this.changeCompFrm = fb.group({	
      'organizationName': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],	
    })
  }

  ngOnInit() {
    this.initDialog();
    this.user = Object.assign({},this.message);  
    if(this.user && this.user.Company_Name && this.user.Company_Name.trim().length > 0)  this.changeModel.organizationName = this.user.Company_Name.trim();
    this.usersService.getDomainName(this.user['Email_Address']).then((res) => {
      this.domainName = res + '';
      if (this.domainName) {
        this.organizationService.getOrganizationsForDomainName(this.domainName)
          .subscribe((domainRes) => {
            if (domainRes) {
              if (domainRes && domainRes[0] && domainRes[0].Organization_Domain == this.domainName) this.selectedOrgId = domainRes[0].Organization_ID
              this.organizations = domainRes;
            }
          }, (err) => {
            console.log("err", err);
          })
      }
    });
    this.timer.push(setTimeout(() => { this.myFirstFocus.focus() }, 0));
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  confirm() {
    this.errorMsg = '';
    if (this.listingType == "0") this.user['Organization_ID'] = this.selectedOrgId;
    else this.user['Organization_Name'] = this.changeModel.organizationName;
    this.user['Organization_Domain']= this.domainName;
    this.user['User_Modified_By']= this.auth.getUserId();
    this.user['Admin_User_ID']= this.auth.getUserId();
    this.usersService.changeToCompany(this.user)
      .subscribe(res => {
        this.dialogRef.close(true);
        this.clearTime();
      }, (err) => {
        this.errorMsg = err;
      });

  }

  isDisabled() {
    if (this.listingType == '0') {
      this.changeCompFrm.controls['organizationName'].markAsUntouched();	
      // this.changeModel.organizationName = null;
      return this.selectedOrgId == '';
    }
    // else return this.changeModel.organizationName == '';
  }

  Close() {
    this.dialogRef.close(false);
    this.clearTime()
  }
  
  isNewCompany() {
    return this.listingType;
  }

  validateCompany() {	
    if (this.listingType == "1") {	
      if (this.changeCompFrm.controls['organizationName'].errors)	
        return true;	
      } else {
        this.errorMsg = '';
        return false;
      }
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
