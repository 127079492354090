import { Component, OnDestroy, OnInit } from "@angular/core";
import { OrdersService } from "app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-chk-sop',
  templateUrl: './chk-sop.component.html',
  styleUrls: ['./chk-sop.component.scss']
})
export class ChkSopComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  countyInfo: any;
  clientSOP: any;
  clientSopLink : any;


  constructor(
    private ordersService: OrdersService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.ordersService.countyInfo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.countyInfo = {};
        if(data) this.countyInfo = data;
      });
    this.ordersService.clientSOP$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.clientSOP = null;
        if(data && data[0] && data[0].Note_Text !== undefined)
          this.clientSOP = data[0].Note_Text;
      });
  }

  validURL(str) {
    const urlRegex = /(https?:\/\/[^\s]+)/;
    const match = str.match(urlRegex);

    if (match) {
      const extractedURL = match[0];
      if(!this.clientSopLink) this.clientSopLink = extractedURL
      return extractedURL;
    } else {
      return false;
    }
  }

  validURLState(str) {
    const urlRegex = /(https?:\/\/[^\s]+)/;
    const match = str.match(urlRegex);

    if (match) {
      const extractedURL = match[0];
      return extractedURL;
    } else {
      return false;
    }
  }

//   validURL(str) {
//     var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
//     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
//     '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
//     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
//     '(\\?[;&a-z\\d%_.~+=-]*)?','i'); // query string
//     // '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
//     // '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
//       let result = !!pattern.test(str);
//       if(result) return true;
//       else {
//         let booleanVal = false;
//         try{
//           let urlObj = new URL(str);
//           if(urlObj && (str.includes('https') || str.includes('http'))) {booleanVal = true;}
//         }
//         catch (e){}
//         return booleanVal;
//       }
//   }
  
}
