import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(array: any[], field: string): any {
    if (!array) return [];

    if (field) array.sort((a, b) => a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1);

    return array;
  }

}
