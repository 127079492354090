import { Component, OnInit } from '@angular/core';
import {AuthService, SchedulingService ,  ConfigService} from '../../services'
import * as momentTimeZone from 'moment-timezone';
import * as moment from 'moment';
import { CONSTANTS } from '../../app.constants';

@Component({
  selector: 'app-holiday-config',
  templateUrl: './holiday-config.component.html',
  styleUrls: ['./holiday-config.component.scss']
})
export class HolidayConfigComponent implements OnInit {
  createLinkObj: any = {};
  isCreateBtnClicked: boolean = false;
  holidaylist: [];
  timer: any = [];

  constructor(
    public holidayScheduling: SchedulingService,
    public auth: AuthService,
    private config: ConfigService,
  ) { }

  ngOnInit() {
    this.getholidaylist()
    this.timer.push(setTimeout(() => this.config.setSideBarNode(29), 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getholidaylist(){
    this.holidayScheduling.getAllHolidays()
    .subscribe((holidays)=>{
      // holidays.forEach((holiday, i) => {
      //   if(holiday.Holiday_Date){
      //     var estShift = momentTimeZone.tz(holiday.Holiday_Date, 'America/New_York').isDST() ? 4 : 5; 
      //     var etaInEstTimezone = moment(holiday.Holiday_Date).add(estShift, 'hours').toDate();
      //     holidays[i].Holiday_Date = etaInEstTimezone;
      //   }       
      // });
      this.holidaylist = holidays;
    }, (err) =>{
      console.log("Error",err)
    })
  }

  createQuickLink() {
    this.isCreateBtnClicked = true;
    this.createLinkObj.Holiday_Name = null;
    // this.createLinkObj.Holiday_Date = null;
    this.createLinkObj.Holiday_Date = new Date();
    this.createLinkObj.Create = true;
    this.createLinkObj.Cancel = false;
  }

  cancelCallback() {
    this.isCreateBtnClicked = false;
  }

  deleteCallback() {
    this.getholidaylist();
  }

  savedCallback() {
    this.getholidaylist();
  }
  
  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  } 

}
