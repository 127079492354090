import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDigitOnly]'
})
export class DigitOnlyDirective {

  constructor(private el: ElementRef) { }

  @Input() appDigitOnly: boolean;

  @HostListener('keypress', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent> event;
    if (this.appDigitOnly) {
      if(e.keyCode < 48 || e.keyCode > 57) e.preventDefault();
    }
  }

}
