import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { OrganizationService } from '../../services/organization/organization.service';
import { AuthService } from '../../services/authentication/auth.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelect } from '@angular/material/select';
import { MatSelectSearchComponent } from 'app/common/mat-select-search/mat-select-search.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-admin2company',
  templateUrl: './add-admin2company.component.html',
  styleUrls: ['./add-admin2company.component.scss']
})
export class AddAdmin2companyComponent{
  @ViewChild('matSearchFocus') matSearchFocus:MatSelectSearchComponent
  message: any = {};
  errorMsg: any = {};
  adminList: any = [];
  selectedAdmin :string = '';
  timer: any = [];
  constructor(
    private organizationService: OrganizationService,
    private auth: AuthService,public dialogRef: MatDialogRef<AddAdmin2companyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.message=this.data.message;
  }

  ngOnInit() {
    this.initDialog();
    if(this.message['Admins'])this.adminList = this.message['Admins'];  
    this.timer.push(setTimeout(() => { this.matSearchFocus.matSelSearch.focus()}))
  }

  initDialog(){
    this.message = this.data.message;
  }

  addAdmin(adminUserId) {
    var data = {};
    data['Admin_ID'] = adminUserId;
    data['Organization_ID'] = this.message['Organization_ID'];
    data['Modified_By'] = this.auth.getUserId();
    this.organizationService.addOrgToAdmin(data)
      .subscribe((result) => {
        this.dialogRef.close(true);
        // this.result=true;  
        this.clearTime();
      }, (err) => {
        console.log("err", err);
        this.errorMsg = err;
      })
  }

  checkIfAdminAvailable(admin) {
    if (this.message['Assigned_Admins']) {
      if (this.message['Assigned_Admins'].findIndex(x => x.Admin_User_ID == admin.User_ID) > -1) {
        return false;
      }
      return true;
    }
    else return true;
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    this.clearTime();
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
